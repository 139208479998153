import React, { useContext, useCallback, useMemo } from 'react';
import { HomeService } from '../../../services/home.service';
import LoadDataSection from '../../../shared/components/LoadDataSection/LoadDataSection';
import AppContext from '../../../shared/context/app/AppContext';
import CardPeopleList from '../../../shared/components/CardPeople/CardPeopleList';
import ProvidersListLoad from '../../../shared/components/ShimmerComponent/ProvidersListLoad';
import { BoxShimmer } from '../../../shared/components/ShimmerComponent/styles';
import { Spinner } from '../../../shared/components/Spinner/spinner';
import HeaderAlternative from './HeaderAlternative';
import { WrapAternativeDiv } from './styled';


const AuthorsAlternative = () => {
  const { state } = useContext(AppContext);
  const { authenticationToken, filterContent, userData } = state;

  const actionPeople = useCallback(async (
    page: number, total: number, filterBy?: string, filter?: string) => {
    const result = await HomeService.getAuthors(
      { authenticationToken, page, limit: total, name: filter }
    );
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, filterContent]);

  return useMemo(() => (
    <WrapAternativeDiv>
      <HeaderAlternative type='desktop' />
      <LoadDataSection
        type={undefined}
        typeButton="outline"
        onAction={actionPeople}
        component={CardPeopleList}
        shimmerComponent={<ProvidersListLoad total={6} />}
        shimmerInfinity={(
          <BoxShimmer type='center'>
            <Spinner className='spinner' type='xs' css={{ padding: '$spaceSmall' }} />
          </BoxShimmer>
        )}
        textProp="Show more"
        filterByProp={filterContent?.type}
        size={10}
        saveData
        isInfinite
        propsComponent={{ userId: userData?.userId }}
      />
    </WrapAternativeDiv>
  ), [actionPeople, userData?.userId, filterContent]
  );
};

export default React.memo(AuthorsAlternative);