/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';

const DEFAULT_NAME = 'User People';
interface usernameModel {
  name?: string | undefined;
  username?: string | undefined;
  first_name?: string;
  last_name?: string;
}

export const getInitials = (name: string) => {
  if (name) {
    let initials = '';
    const hasTokens = name.indexOf(' ') !== -1;
    const names = name.split(' ');
    if (names.length > 2) {
      initials = names[0].charAt(0);
      initials += names[1].charAt(0);
    } else {
      initials =
        name.substring(0, hasTokens ? 1 : 2) +
        (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '');
    }

    return initials.toUpperCase();
  }

  return '';
};

export const getUserName = (dataName: usernameModel) => {
  const { name, username, first_name, last_name } = dataName;
  const concatName = `${first_name || ''} ${last_name || ''}`;
  const alternativeName = concatName.length > 1 ? concatName : null;
  const finalName = name || alternativeName || username || DEFAULT_NAME;

  return finalName;
};

export const getAuthorById = (data: Array<any> | undefined, id: string | number) => {
  const filterData =
    (data &&
      data.map((i: any) => {
        const dataAuthors = i?.attributes?.authors?.data || [];
        dataAuthors.forEach((d: any, index: number) => {
          if (d.attributes?.user_id === id) {
            dataAuthors.splice(index, 1);
            dataAuthors.unshift(d);
          }
        });

        return {
          ...i,
          attributes: {
            ...i?.attributes,
            authors: {
              data: dataAuthors,
            },
          },
        };
      })) ||
    [];
  return filterData;
};

export const keyExtractor = () => `${Math.random()}`;

export const formatTooltipText = async (
  data: Array<any>,
  type: string,
  name: string,
  relation: string,
) => {
  let lower_relation = '';
  switch (relation) {
    case 'AUTHOR':
      lower_relation = 'Authored by';
      break;
    case 'MENTIONED':
      lower_relation = 'Mentions';
      break;
    default:
      lower_relation = 'Authored by';
      break;
  }

  let tooltipText = `${lower_relation} ${name}`;
  await data.forEach(async (i: any, index: number) => {
    if (i?.attributes?.name !== name) {
      const nameCollaborator = await getUserName({ name: i?.attributes?.name });
      if (index === 1 && data.length < 3) {
        tooltipText += ` and ${nameCollaborator}`;
      } else if (data.length - 1 === index) {
        tooltipText += ` and ${nameCollaborator}`;
      } else {
        tooltipText += `, ${nameCollaborator}`;
      }
    }
  });
  tooltipText += await `.`;

  return tooltipText;
};

export const formatTooltipMention = async (data: Array<any>) => {
  let tooltipText = '';
  await data.forEach(async (element: any, index: number) => {
    if (index !== 0) {
      if (data.length > 2 && data.length - 1 === index) {
        tooltipText += ` and ${element}`;
      } else {
        tooltipText += `${element}`;
      }
    }
  });
  tooltipText += await `.`;

  return tooltipText;
};

export const getKnowledge = (data: Array<any> | undefined, key: string) => {
  const filterData =
    data &&
    data.map((item: any) => {
      if (key === 'content') {
        return item.attributes.content.data;
      }

      return item.attributes.author.data;
    });

  return filterData || [];
};

export const getKnowledgeContent = (data: Array<any> | undefined, key: string) => {
  const filterData =
    data &&
    data.map((item: any) => {
      if (key === 'content') {
        return item.attributes.content.data;
      }
      const dataContnet = item.attributes.content.data;

      return dataContnet?.attributes?.authors?.data[0];
    });

  return filterData || [];
};

export const getKnowledgeType = (data: Array<any> | undefined, key: string) => {
  const filterData =
    (data &&
      data
        .map((i: any) => {
          const bookData = i?.attributes.map((obj: any) => {
            if (obj?.kind === key) {
              return { ...obj[key]?.data?.attributes };
            }
            return { ...obj };
          });
          return {
            ...i,
            attributes: bookData[0],
          };
        })
        .filter((item) => item.attributes)) ||
    [];

  return filterData;
};

export const formatDate = (date: any) => {
  if (date) {
    return new Date(date).toLocaleString('en-US', {
      day: 'numeric',
      year: 'numeric',
      month: 'long',
    });
  }

  return '';
};

export const returnSameName = (name: string | undefined, username: string | undefined) => {
  if (name && username) {
    return name === username ? 'by me' : `by ${name}`;
  }

  return '';
};

export const capitalizeFirstLetter = (formatText: string | undefined) => {
  if (formatText) {
    const textCapitalize = `${formatText?.charAt(0)?.toUpperCase()}${formatText.slice(1)}`;

    return textCapitalize;
  }

  return '';
};

export const limiterText = (text: string | undefined, limit: number) => {
  if (limit) {
    const textLength = text ? text?.length : 0;
    const newText = textLength > limit ? `${text?.slice(0, limit)}...` : text;

    return newText;
  }

  return text || '';
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getTopicsTags = (data: Array<any>) => {
  const filterData =
    data && data.map((item: any) => item.attributes?.tag?.data?.id).filter((i) => i);

  return filterData || [];
};

export const getExperiencesFormat = (dataInitial: Array<any>, data: Array<any>) => {
  const dataFormat = data.map((e: any) => ({ ...e, id: e.id }));
  const dataInitialFormat = dataInitial.map((i: any) => ({ ...i, id: i.id }));

  const formatData = dataInitial
    .map((i: any) => {
      const dataFind = data.find((item) => item?.title === i.title || item?.id === i.id);
      if (dataFind) {
        return {
          ...dataFind,
          id: i.id,
        };
      }

      return { ...i, _destroy: true };
    })
    .filter((val: any) => val);

  const combineData = _uniqWith(
    [...dataFormat, ...formatData],
    (a, b) => a?.title === b?.title && a.id === b.id && a?._destroy === b?._destroy,
  );

  const finalArray = combineData.filter((item: any) => {
    const dataFind = dataInitialFormat.find((opt: any) => _isEqual(item, opt));

    if (dataFind) {
      return false;
    }
    return true;
  });

  return finalArray;
};

export const getLanguagesFormat = (dataInitial: Array<any>, data: Array<any>) => {
  const dataFormat = data.map((e: any) => ({ id: e.id, level: e.level, language: e.name }));
  const dataInitialFormat = dataInitial.map((i: any) => ({
    id: i.id,
    language: i.name,
    level: i.level,
  }));

  const formatData = dataInitial
    .map((i: any) => {
      const dataFind = data.find((item) => item?.name === i.language || item?.id === i.id);
      if (dataFind) {
        return {
          id: i.id,
          level: dataFind.level,
          language: dataFind.name,
        };
      }
      const languageDel = { ...i, level: i.level, language: i.name, _destroy: true };
      delete languageDel.name;
      return languageDel;
    })
    .filter((val: any) => val);

  const combineData = _uniqWith(
    [...dataFormat, ...formatData],
    (a, b) => a?.language === b?.language && a.id === b.id && a?._destroy === b?._destroy,
  );

  const finalArray = combineData.filter((item: any) => {
    const dataFind = dataInitialFormat.find((opt: any) => _isEqual(item, opt));

    if (dataFind) {
      return false;
    }
    return true;
  });

  return finalArray;
};

export const isDeviceTouch = () => 'ontouchstart' in window || navigator?.maxTouchPoints > 0;

export const validateAlternativeKey = (key: string, alternative: any) => {
  if (_isEmpty(alternative)) {
    return false;
  }

  return key && `${key}` in alternative && alternative[key];
};

export const replaceSocialUrl = (url: string | undefined, urlBase: string) => {
  if (url) {
    const arrayValues = url.split('/');
    const username = arrayValues[arrayValues.length - 1];

    return `${urlBase}${username}`;
  }

  return urlBase;
};

export const removeContentsAdded = (contents: Array<any>, dataAdded: Array<any>) => {
  try {
    const idsAdded = dataAdded.map((o: { id: any }) => o?.id);
    const filtered = contents.filter(({ id }: any) => id && !idsAdded.includes(id));

    return filtered;
  } catch (error) {
    return contents;
  }
};

export const removeContent = (contents: Array<any>, dataToDelete: Array<any>) => {
  try {
    const filtered = contents.filter(({ id }: any) => id && !dataToDelete.includes(id));

    return filtered;
  } catch (error) {
    return contents;
  }
};

export const calculateYModal = (y_: any) => {
  let y = y_;

  if (y === 0) {
    y = 403;
  }

  let valueY = Number(y) + 42;
  const yDimension = Number(window?.screen?.height) - valueY;

  if (yDimension < 442) {
    valueY -= 442;
    return valueY <= 0 ? 16 : valueY;
  }

  return valueY;
};

export const replaceDashTitle = (title: string | undefined) => {
  const formatTitle = title || '';

  return formatTitle.replaceAll('-', ' ');
};

export const maskAndFormatCreditCardNumber = (cardNumber: string): string => {
  const lastFourDigits = cardNumber.slice(-4);
  const maskedDigits = 'X'.repeat(Math.max(0, cardNumber.length - 7));
  const maskedCardNumber = maskedDigits + lastFourDigits;
  const formattedCardNumber = maskedCardNumber.replace(/(.{4})/g, '$1 ').trim();

  return formattedCardNumber;
};

export const getRandomWidth = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const generateRegEx = (word: string): RegExp => {
  const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regexString = `^${escapedWord.split('').join('.*')}.*$`;
  return new RegExp(regexString);
};

export const isLandscapeMobile = (width: number | null, height: number | null) => {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  if (width && height) {
    // phoneLandscape: '(min-width: 30rem) and (max-width: 56rem) and (orientation: landscape)',
    if (
      width > 480 &&
      width <= 960 &&
      height <= width &&
      height >= 320 &&
      height <= 480 &&
      (isAndroid || isIOS)
    ) {
      return true;
    }
  }
  return false;
};

export const isButtonBrowserNavigatorBar = () => {
  const ua = navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
};
