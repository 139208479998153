import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PageContainer from '../../../shared/components/Container/PageContainer';
import { WhatIsExpert } from './WhatIsExpert';
import { HowDoesItWork } from './HowDoesItWork';
import { FAQ } from './FAQ';
import { OtherQuestion } from './OtherQuestion';
import { Introduction } from './Introduction';
import { ExpertFooter } from '../components/ExpertFooter/ExpertFooter';

export const Experts = () => (
  <PageContainer layout="approvedExpert">
    <Introduction />
    <WhatIsExpert />
    <HowDoesItWork />
    <FAQ />
    <OtherQuestion />
    <ExpertFooter />
  </PageContainer>
);
