import { useCallback, useContext } from 'react';
import ExpertContext from '../context/expert/ExpertContext';
import { ExpertData } from '../interfaces/interfaces';
import { ExpertService } from '../../services/expert.service';
import ProjectsContext from '../context/projects/ProjectsContext';
import useGetToken from './useGetToken';

export const useExpert = () => {
  const authenticationToken = useGetToken();
  const { expert, setExpert } = useContext(ExpertContext);
  const { locationsList, setLocationsList, rolesList, setRolesList } = useContext(ProjectsContext);

  const handleChangeExpert = async (
    value: any,
    key: 'projectName' | 'description' | 'locations' | 'roles',
  ) => {
    const newExpert: ExpertData = { ...expert };
    newExpert[key] = value;
    await setExpert(newExpert);
  };

  const getLocations = async () => {
    const locationsData = (await ExpertService.getLocations()).map((location: any) => ({
      id: location.id,
      label: location.attributes.name,
    }));
    await setLocationsList(locationsData);
  };

  const getRoles = async () => {
    const rolesData = (await ExpertService.getRoles()).map((role: any) => ({
      id: role.id,
      label: role.attributes.title,
    }));
    setRolesList(rolesData);
  };

  const listExperts = (projectId: string, page: number, perPage: number) =>
    ExpertService.listExperts(authenticationToken, projectId, page, perPage);

  const loadLocationsAndRoles = useCallback(async () => {
    try {
      if (!locationsList.length) {
        await getLocations();
      }
      if (!rolesList.length) {
        await getRoles();
      }
    } catch (error) {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleChangeExpert,
    expert,
    ...expert,
    setExpert,
    loadLocationsAndRoles,
    getRoles,
    getLocations,
    rolesList,
    locationsList,
    listExperts,
  };
};
