import { useContext } from 'react';
import AppContext from '../context/app/AppContext';

const useGetUserSetting = (): string => {
  const { state } = useContext(AppContext);
  const { userData } = state as any | undefined;

  if (userData.userId === undefined) return '';
  if (userData.experiences.length === 0) return 'expertise';

  return 'done';
};

export default useGetUserSetting;
