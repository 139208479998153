import React from 'react';
import {
  HowDoesItWorkContainer,
  HowDoesItWorkImg,
  SectionContainer,
  TextContainer,
} from './styled';
import sectionImage from '../../../../assets/images/experts/SecondaryPhoto.png';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TypographyH2 } from '../../styled';

export const HowDoesItWork = () => (
  <ExpertContainer section="work">
    <TypographyH2 type="displaySmall" align="center" lineHeight="normal">
      <b> I’ve been invited to record an Expert Opinion…</b> <br />
      How does it work?
    </TypographyH2>
    <HowDoesItWorkContainer>
      <SectionContainer>
        <TextContainer>
          <HowDoesItWorkImg src={sectionImage} alt="expert project page" />
        </TextContainer>
      </SectionContainer>
      <SectionContainer>
        <TextContainer>
          <h3>1. Review your questions</h3>
          <p>
            Click on the link in the email we sent to you and review the questions you’ve been
            invited to answer. You’ll also see the fee you’ll receive for the recording and the
            delivery deadline.
          </p>
        </TextContainer>
        <TextContainer>
          <h3>2. Login & make your recording</h3>
          <p>
            If you haven’t used Gathersight before you’ll be asked to create a login. You’ll then
            see a project page showing you the questions again and the video interface. Simply click
            record on the interface and provide your answers to the questions.
          </p>
        </TextContainer>
        <TextContainer noMarginBottom>
          <h3>3. Submit your recording</h3>
          <p>
            When you’re finished recording, if you’re happy with your answers, simply click submit.
            That’s it. You’ll receive your payment 3 - 5 days later.
          </p>
        </TextContainer>
      </SectionContainer>
    </HowDoesItWorkContainer>
  </ExpertContainer>
);
