import React from 'react';
import { Expandable } from '../../components/Expandable/Expandable';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TypographyH2 } from '../../styled';

const faqData = [
  {
    title: 'How much will I receive for an Expert Opinion recording?',
    content:
      'Your fee will differ depending on the project. You’ll see your specific fee on the project detail page, which is linked from the invitation email we sent to you.',
  },
  {
    title: 'How quickly will I be paid?',
    content:
      'You’ll receive payment 3 - 5 days after providing your recording. Once you’ve completed your first recording you’ll be prompted to enter your bank information.',
  },
  {
    title: 'Will my name be used publicly in any way?',
    content: 'No. Our clients are not allowed to use your name publicly.',
  },
  {
    title: 'What kind of organizations use Expert Opinions?',
    content:
      'A wide variety of clients use Expert Opinions including academic institutions, research organizations, biotech and pharmaceutical companies, consultancies, investment firms, medical device manufacturers and non-profit agencies.',
  },
  {
    title: 'What about confidential information?',
    content:
      'As part of our terms of engagement both the expert and the client are not allowed to request or pass any confidential information. You should obviously avoid mentioning anything confidential in your recording.',
  },
  {
    title: 'How do I update my expertise on the Gathersight network?',
    content:
      'Keeping your skills and expertise up to date is a great way to increase the Expert Opinion invitations you receive. Simply login, visit your profile page and add or edit to your expertise.',
  },
  {
    title: 'Do I need to pay tax on my earnings?',
    content: (
      <>
        Your tax obligations will depend on the jurisdiction you live in and will often depend on
        the amount you earn in a particular period. If you are a US citizen and earn more than $600
        in a single tax year from Gathersight we will send you a{' '}
        <a href="https://turbotax.intuit.com/tax-tips/irs-tax-forms/what-is-form-1099-nec/L5fbwIFSn">
          form 1099NEC
        </a>{' '}
        to include with your tax return.
      </>
    ),
  },
];

export const FAQ = () => (
  <ExpertContainer section="work" bg="monochromeWhite25">
    <TypographyH2 type="displaySmall" align="center" weight="700" spacing="opinionTitleSection">
      FAQ
    </TypographyH2>
    {faqData.map((item) => (
      <Expandable key={item.title} title={item.title}>
        {item.content}
      </Expandable>
    ))}
  </ExpertContainer>
);
