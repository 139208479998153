import React from "react";
import NoContentWidget from "../../../shared/components/NoContentWidget/NoContentWidget";
import SetupProfileWidget from "./SetupProfileWidget/SetupProfileWidget";
import StartSharingWidgetProfile from "./StartSharingWidgetProfile/StartSharingWidgetProfile";

interface ProfileContentEmptyProps {
  myProfile?: boolean;
}

const ProfileContentEmpty: React.FC<ProfileContentEmptyProps> = ({myProfile}) => {
  if (myProfile) {
    return(
      <>
        <StartSharingWidgetProfile />
        <SetupProfileWidget />
      </>
    );
  }
  return(
    <NoContentWidget title={`Hmm, that's a blow. We can't find any long-form content . Sorry!`} hideButton />
  );
};

ProfileContentEmpty.defaultProps = {
  myProfile: false,
};

export default ProfileContentEmpty;