import { styled } from '../../../config/theme';

export const VideoStyled = styled('video', {
  minWidth: '16rem',
  objectFit: 'cover',

  transition: 'all 0.9s ease-in-out',
  '@bp2': { borderRadius: '$radius05' },
  variants: {
    isLandscape: {
      true: {
        '@bp2': { borderRadius: 0 },
      },
    },
  },
});

export const VideoContainer = styled('div', {
  position: 'relative',
  width: '100%',
  minWidth: '100%',
  height: '100%',
});

