import React, { ReactNode } from 'react';
import { RoundedIconContainer } from './styled';

interface RoundedIconProps {
  icon: ReactNode;
}

export const RoundedIcon = ({ icon }: RoundedIconProps) => (
  <RoundedIconContainer> {icon}</RoundedIconContainer>
);
