import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PageContainer from '../../shared/components/Container/PageContainer';
import useGetCurrentUserID from '../../shared/hooks/getCurrentUserID';
import AppContext from '../../shared/context/app/AppContext';
import PlaylistDetail from './PlaylistDetail/PlaylistDetail';
import Playlists from './Playlists/Playlists';
import ProfileHeader from '../Profile/ProfileHeader/ProfileHeader';
import useGetUser from '../../shared/hooks/useGetUser';
import BackButton from './BackButton/BackButton';
import PlaylistMetatags from './playlistMetatags';
import { User } from '../../shared/interfaces/interfaces';

const Playlist = () => {
  const { slug, playlist_slug: KBId } = useParams();
  const currentUserId = useGetCurrentUserID();
  const { state } = useContext(AppContext);
  const { authenticationToken } = state;
  const data = {authenticationToken, slug};
  const { userData, loadingUser, setLoadingUser } = useGetUser(data);
  const myProfile = Number(userData?.id) === Number(currentUserId);

  const hasUserData = !isEmpty(userData) && !loadingUser;

  return (
    <PageContainer layout="profile">
      {userData && !KBId && <PlaylistMetatags userData={userData}/>}
      <ProfileHeader userData={userData as User} loading={loadingUser} myProfile={myProfile} />
      {slug && KBId && hasUserData && (<BackButton userData={userData} myProfile={myProfile} />)}
      {!KBId && slug && (
        <Playlists userSlug={slug} myPlaylist={myProfile} />
      )}
      {KBId && (<PlaylistDetail setLoading={setLoadingUser} loading={loadingUser}
      />)}
    </PageContainer>
  );
};

export default Playlist;
