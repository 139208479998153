import { Dispatch, SetStateAction, createContext } from 'react';
import { ProjectOpinion, RecordingStatus } from '../../interfaces/projectInterfaces';

export interface ContextProps {
  status: RecordingStatus;
  blob?: Blob;
  width?: number;
  recordedChunks: any[];
  videoWidth?: number;
  videoHeight?: number;
  videoTrack?: { label: string; value: string };
  audioTrack?: { label: string; value: string };
  videoInputDevices: MediaDeviceInfo[];
  audioInputDevices: MediaDeviceInfo[];
  newVideoTrack?: { label: string; value: string };
  newAudioTrack?: { label: string; value: string };
  error?: string;
  openModal: boolean;
  projectOpinion?: ProjectOpinion;
  listDevices: () => void;
  fullscreen: boolean;
  webcamRef: any;
  stopCapturing: boolean;
  setStopCapturing: Dispatch<SetStateAction<boolean>>;
  setFullscreen: Dispatch<SetStateAction<boolean>>;
  setProjectOpinion: Dispatch<SetStateAction<ProjectOpinion | undefined>>;
  setBlob: Dispatch<SetStateAction<Blob | undefined>>;
  setStatus: Dispatch<SetStateAction<RecordingStatus>>;
  setRecordedChunks: Dispatch<SetStateAction<never[]>>;
  setVideoTrack: Dispatch<SetStateAction<{ label: string; value: string } | undefined>>;
  setAudioTrack: Dispatch<SetStateAction<{ label: string; value: string } | undefined>>;
  setNewVideoTrack: Dispatch<SetStateAction<{ label: string; value: string } | undefined>>;
  setNewAudioTrack: Dispatch<SetStateAction<{ label: string; value: string } | undefined>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
  setVideoHeight: Dispatch<SetStateAction<number>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

const initialState: ContextProps = {
  status: 'init',
  recordedChunks: [],
  blob: undefined,
  videoHeight: 0,
  videoTrack: undefined,
  audioTrack: undefined,
  videoInputDevices: [],
  audioInputDevices: [],
  newVideoTrack: undefined,
  newAudioTrack: undefined,
  error: undefined,
  openModal: false,
  projectOpinion: undefined,
  fullscreen: false,
  webcamRef: undefined,
  stopCapturing: false,
  setStopCapturing: () => {},
  setFullscreen: () => {},
  listDevices: () => {},
  setProjectOpinion: () => {},
  setAudioTrack: () => {},
  setVideoTrack: () => {},
  setBlob: () => {},
  setStatus: () => {},
  setRecordedChunks: () => {},
  setNewVideoTrack: () => {},
  setNewAudioTrack: () => {},
  setError: () => {},
  setVideoHeight: () => {},
  setOpenModal: () => {},
};

const VideoRecordingContext = createContext<ContextProps>(initialState as ContextProps);

export default VideoRecordingContext;
