import React from 'react';
import Picture from './Steps/Picture';
import Done from './Steps/Done';
import Expertise from './Steps/Expertise';
import SocialMedia from './Steps/SocialMedia';

export const levelFinish = 'done';

export const levelsSteps: any = {
  expertise: {
    required: true,
    noPadding: true,
    component: (props: any) => <Expertise {...props} />,
    footer: true,
    step: '1/3',
  },

  socialMedia: {
    required: true,
    noPadding: true,
    component: (props: any) => <SocialMedia {...props} />,
    footer: true,
    step: '2/3',
  },

  picture: {
    required: false,
    component: (props: any) => <Picture {...props} />,
    footer: true,
    step: '3/3',
  },
  [levelFinish]: { required: false, component: (props: any) => <Done {...props} /> },
};

export const levelsOrder = ['expertise', 'socialMedia', 'picture', levelFinish];
