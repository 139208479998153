import React, { useRef } from 'react';
import Button from '../../../../shared/components/Button/Button';
import editIcon from '../../../../assets/images/icons/edit.svg';

interface FileInputButtonProps {
  // eslint-disable-next-line no-unused-vars
  onFileSelect: (file: File) => void;
}

export const FileInputButton = ({ onFileSelect }: FileInputButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      onFileSelect(selectedFile);
    }
  };

  return (
    <>
      <Button
        title="Change profile photo"
        size="medium"
        color="label"
        kind="file"
        iconCustom={editIcon}
        handleClick={handleButtonClick}
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  );
};
