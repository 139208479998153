import { styled } from '../../config/theme';

export const ExpertStepperContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignContent: 'center',
  backgroundColor: '$offWhite',
  padding: '$spaceSmall',
  '@bp2': {
    width: '50%',
    overflowY: 'auto',
    minHeight: 'calc(100vh - 70px)',
    maxHeight: 'calc(100vh - 70px)',
    justifyContent: 'center',
  },
  '@bp5': {
    width: '50%',
    padding: '$spaceMedium',
  },
});

export const ExpertInvitationImageSubContainer = styled('div', {
  display: 'flex',
  width: '100%',
  maxWidth: 700,
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingY: '$spaceSmall',
});

export const ExpertStepperImageContainer = styled('div', {
  width: '100%',
  position: 'relative',
  padding: '20px 0',
  minHeight: '40vh',
  background: '$imageOverlay -88.637px -42.503px / 148.276% 125.79% no-repeat',
  '@bp2': {
    width: '50%',
    minHeight: 'calc(100vh - 70px)',
  },
});

export const CenteredText = styled('div', {
  textAlign: 'center',
});

export const Image = styled('img', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const TextOverlay = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80%',
  transform: 'translate(-50%, -50%)',
  color: '#fff',
  fontSize: '2rem',
  borderRadius: '$1',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  maxWidth: '32rem',
  variants: {
    landing: {
      invitation: {
        '@bp2': {
          top: '50%',
        },
        '@bp4': {
          bottom: 'auto',
          top: '50%',
        },
      },
    },
  },
});

export const BigText = styled('h3', {
  fontFamily: '$poppins',
  fontSize: '2rem',
  color: '$secondaryP3',
  fontWeight: '$normal',
  maxWidth: '31rem',
  '@bp2': {
    fontSize: '$displaySmall',
  },
  variants: {
    type: {
      'new-project': {
        fontSize: '$displaySmall',
        '@bp3': {
          fontSize: '$displayMedium',
        },
        '@bp6': {
          fontSize: '$displayLarge',
        },
      },
      title: {
        fontSize: '1.8rem',
      },
    },
  },
});
export const InvitationTitle = styled('h3', {
  fontFamily: '$poppins',
  fontSize: '$bodySmall',
  color: '$secondaryP3',
  fontWeight: '$normal',
  lineHeight: '2.5rem',
});

export const SmallText = styled('p', {
  fontSize: '$bodySmall',
  lineHeight: '2rem',
  letterSpacing: '0.0625rem',
  marginTop: '$spaceSmall',
  span: {
    textDecoration: 'underline',
  },
  a: {
    textDecoration: 'underline',
    color: 'inherit',
  },
  variants: {
    landing: {
      invitation: {
        lineHeight: '1.5rem',
        color: '$monochromeWhite',
        maxWidth: 700,
      },
      steps: {
        maxWidth: 600,
        fontSize: '$bodyMedium',
      },
    },
    font: {
      squarePeg: {
        fontFamily: '$squarePeg',
        fontSize: '$displaySmall',
      },
    },
  },
});

export const Section = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  minHeight: 'calc(100vh - 70px)',
  '@bp3': {
    flexDirection: 'row',
  },
});

const TypographyProps: any = {
  fontFamily: '$poppins',
  color: '$offBlack',
  fontWeight: '$normal',
  span: {
    color: '#4E4B66',
    fontWeight: '$normal',
    lineHeight: '$lineHeight3',
    letterSpacing: '$letterSpacing3',
  },
  b: {
    fontWeight: '$weight700',
  },
  variants: {
    type: {
      bodyLarge: {
        fontSize: '$bodyLarge',
        lineHeight: '$lineHeight3',
        letterSpacing: '$letterSpacing3',
      },
      default: {
        fontSize: '$displayXXSmall',
      },
      expertOpinionLandingSection: {
        fontSize: '$bodyLarge',
        '@bp2': {
          fontSize: '$bodySmall',
        },
        '@bp4': {
          fontSize: '$bodyMedium',
        },
      },
      bodySmall: {
        fontSize: '$bodySmall',
      },
      bodyXSmall: {
        fontSize: '$bodyXSmall',
      },
      displayXSmall2: {
        fontSize: '$bodyLarge',
        fontWeight: '$weight700',
        lineHeight: '$lineHeight3',
        letterSpacing: '$letterSpacing3',
      },
      displayXSmall: {
        fontSize: '$displayXSmall',
      },
      displayXXSmall: {
        fontSize: '$displayXSmall2',
        fontWeight: '$weight700',
        lineHeight: '$lineHeight3',
        letterSpacing: '$letterSpacing3',
      },
      displayMedium: {
        fontSize: '$displayMedium',
        fontWeight: '$weight700',
        letterSpacing: '$letterSpacing3',
        marginBottom: 0,
      },
      displaySmall: {
        fontSize: '$displaySmall', // 2.25rem
        letterSpacing: '$letterSpacing3',
      },
      displaySmall_industries: {
        fontSize: 'calc($displaySmall / 1.3)',
        '@bp2': {
          fontSize: 'calc($displaySmall / 1.2)',
          letterSpacing: '$letterSpacing3',
        },
        '@bp3': {
          fontSize: '$displaySmall', // 2.25rem
          letterSpacing: '$letterSpacing3',
        },
      },
    },
    color: {
      bluerplePrimary: {
        color: '$blurple',
      },
      monochromeBlack: {
        color: '$monochromeBlack',
      },
      gray: {
        color: '$grey',
      },
      monochromeWhite: {
        color: '$monochromeWhite',
      },
      monochromeBlack400: {
        color: '$monochromeBlack400',
      },
      secondaryP3: {
        color: '$secondaryP3',
      },
    },
    weight: {
      400: {
        fontWeight: '$normal',
      },
      600: {
        fontWeight: '$weight600',
      },
      700: {
        fontWeight: '$weight700',
      },
    },
    align: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      centerAndLeftMobile: {
        textAlign: 'left',
        '@bp2': {
          textAlign: 'center',
        },
      },
    },
    spacing: {
      opinionTitleSection: {
        marginBottom: '$spaceMedium',
      },
      noSpace: {
        margin: 0,
      },
    },
    letterSpacing: {
      letterSpacing3: {
        letterSpacing: '$letterSpacing3',
      },
      letterSpacing6: {
        letterSpacing: '$letterSpacing6',
      },
    },
    lineHeight: {
      normal: {
        lineHeight: 'normal',
      },
      lineHeight1: {
        lineHeight: '$lineHeight1',
      },
    },
    fontStyle: {
      italic: {
        fontStyle: 'italic',
      },
    },
  },
};

export const Typography = styled('h1', TypographyProps);

export const TypographyH2 = styled('h2', { ...TypographyProps, fontWeight: '$normal' });
export const TypographyH3 = styled('h3', TypographyProps);
export const TypographyP = styled('p', TypographyProps);

export const TextDescription = styled('p', {
  color: '$monochromeBlack',
  fontFamily: '$poppins',
  fontSize: 'clamp(1rem, 4vw, 1.1rem)',
  lineHeight: '$lineHeight3',
  fontStyle: 'normal',
  fontWeight: '$normal',
  variants: {
    type: {
      small: {
        color: '$monochromeBlack400',
        fontSize: '$displayXXSmall',
        letterSpacing: '$letterSpacing2',
      },
      label: {
        color: '$labelLight',
        fontSize: '$bodyXSSmall',
        fontWeight: '$weight600',
        lineHeight: '$lineHeight1',
        letterSpacing: '0.5px',
      },
      description: {
        fontSize: '$bodyXSmall',
        color: '$monochromeBlack500',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing6',
      },
      contained: {
        fontSize: '$bodyXSmall',
        color: '$monochromeBlack400',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing6',
        margin: 0,
      },
      inviteSubtitle: {
        textAlign: 'left',
        fontWeight: '$weight600',
        color: '$labelLight',
        lineHeight: '$lineHeight1',
        textTransform: 'uppercase',
        fontSize: '$bodyXSmall',
      },
      expertOpinions: {
        color: '$monochromeBlack',
        fontSize: '$bodySmall',
        letterSpacing: '$letterSpacing1',
      },
      experts: {
        marginBottom: '$spaceSmall',
      },
      landingDescriptionTop: {
        fontSize: 'calc($bodySmall * 1.1)',
      },
      ourExperts: {
        fontSize: '$bodyLarge',
        letterSpacing: '$letterSpacing7',
        a: {
          color: '$secondaryP3',
        },
      },
    },
    align: {
      center: {
        textAlign: 'center',
      },
    },
    spacing: {
      opinion: {
        marginTop: '$spaceXXSmall',
      },
      noSpace: {
        margin: 0,
      },
    },
    color: {
      monochromeWhite: {
        color: '$monochromeWhite',
      },
    },
    darkLink: {
      true: {
        a: {
          color: '$secondaryP3',
          fontWeight: '$weight600',
          textDecorationLine: 'underline',
        },
      },
    },
  },
});
