import { styled } from '../../../config/theme';

export const LayoutContainer = styled('div', {
  width: '100%',
  display: 'grid',
  flexDirection: 'column',
  gap: '$spaceLarge',
  padding: '1.25rem 1rem',
  height: '100%',
  maxWidth: 1465,
  margin: '0 auto',
  gridTemplateColumns: '1fr',

  '@bp2': {
    flexDirection: 'row',
  },
  '@bp3': {
    padding: '1.5rem 1rem 1.5rem 1rem',
    gridTemplateColumns: '.4fr 1fr',
  },
  '@bp4': {
    padding: '2.5rem 5rem 5rem 5rem',
  },
  '@bp6': {
    padding: '2.5rem 5rem',
  },
  '& .who-to-follow': {
    gap: '0px',
  },
  variants: {
    sub: {
      true: {
        '@bp2': {
          flexWrap: 'wrap',
        },
        '@bp4': {
          flexDirection: 'row',
        },
      },
    },
    noGap: {
      true: {
        gap: '0',
      },
    },
    noPadding: {
      true: {
        display: 'flex',
        padding: '0',
      },
    },
    type: {
      normal: {
        '@bp2': {
          padding: '1.5rem 1rem 1.5rem 1rem',
        },
        '@bp3': {
          flexDirection: 'row',
          padding: '1.5rem',
        },
        '@bp4': {
          padding: '1.5rem 5rem',
        },
      },
      followings: {
        '@bp2': {
          flexDirection: 'column',
          padding: '1.5rem 1rem 1.5rem 1rem',
        },
        '@bp3': {
          flexDirection: 'row',
          padding: '1.5rem',
        },
        '@bp4': {
          padding: '.5rem 5rem 5rem 5rem',
        },
      },
      profile: {
        gridTemplateColumns: '1fr',
        '@bp2': {
          padding: '1.5rem 1rem 1.5rem 1rem',
        },
        '@bp3': {
          flexDirection: 'row',
          padding: '1.5rem',
          gap: 0,
        },
        '@bp4': {
          padding: '2.5rem 5rem',
        },
      },
      discover: {
        gap: '1rem',
        '@bp2': {
          padding: '2rem 1rem 1.5rem 1rem',
        },
        '@bp3': {
          gridTemplateColumns: '1fr',
          padding: '5rem 7.125rem',
        },
        '@bp4': {
          padding: '5rem 15.125rem',
        },
      },
      settings: {
        padding: 0,
        gap: '0',
        height: 'auto',
        gridTemplateColumns: '1fr',
        '@bp3': {
          gridTemplateColumns: '1fr 1fr',
          gap: '$spaceSmall',
        },
      },
      authentication: {
        padding: 0,
        gap: '0',
        display: 'grid',
        '@bp3': {
          gap: '$spaceSmall',
          gridTemplateColumns: '1fr 1fr',
        },
      },
      breadcrumbs: {
        padding: '1rem 1rem 0',
        gap: '11px',
        flexDirection: 'column',
        '@bp2': {
          padding: '1rem 1.5rem 0',
        },
        '@bp3': {
          flexDirection: 'row',
        },
        '@bp4': {
          padding: '1rem 5rem 0',
        },
      },
      'breadcrumbs-feed': {
        minHeight: 40,
        padding: '1.5rem  1.5rem  0 1.5rem',
        alignItems: 'baseline',
        justifyContent: 'baseline',
        flexDirection: 'column',
        height: 'fit-content',
        gap: 0,
        '@bp3': {
          alignItems: 'baseline',
          flexDirection: 'row',
          padding: '2.5rem 0.5rem 2rem 0.5rem',
        },
      },
      'knowledge-detail': {
        '@bp0': {
          padding: '1rem 1rem 1rem 1rem',
        },
        '@bp1': {
          flexDirection: 'column',
          padding: '1rem 1.5rem 1.5rem',
        },
        '@bp2': {
          gridTemplateColumns: '.3fr 1fr',
          flexDirection: 'row',
          padding: '1rem 1.5rem 2.5rem 1.5rem',
        },
        '@bp4': {
          flexDirection: 'row',
          padding: '1rem 5rem 5rem',
        },
      },
      'knowledge-detail-empty': {
        padding: '1rem 1rem 1rem 1rem',
        flexDirection: 'column-reverse',

        '@bp0': {
          padding: '1rem 1rem 1rem 1rem',
          flexDirection: 'column',
        },
        '@bp2': {
          gridTemplateColumns: '.4fr 1fr',
          padding: '1rem 1.5rem 1.5rem',
          flexDirection: 'column',
        },
        '@bp3': {
          flexDirection: 'row',
          padding: '1rem 1.5rem 2.5rem 1.5rem',
        },
        '@bp4': {
          flexDirection: 'row',
          padding: '1rem 5rem 5rem',
        },
      },
      login: {
        flexDirection: 'column',
        padding: '0',
        gap: '0',
        display: 'flex',

        '@bp4': {
          padding: '0 25px',
        },
      },
      home: {
        display: 'grid',
        padding: '0 1rem',
        gridTemplateColumns: '1fr',
        '@bp3': {
          gridTemplateColumns: '.4fr 1fr',
          padding: '0 0.5rem',
        },
        '@bp3_3': { padding: '0 4rem' },
      },
      statics: {
        gap: '1rem',
        gridTemplateColumns: '1fr',
        padding: '2rem 1.25rem 1.5rem 1.25rem',
        '@bp2': {
          flexDirection: 'row',
          padding: '5rem 7.125rem',
          gap: 0,
        },
        '@bp4': {
          padding: '5rem 21rem',
        },
      },
    },
    direction: {
      column: {
        flexDirection: 'column !important',
        '.widgets-wrap': {
          flexDirection: 'row !important',
        },
      },
      row: {
        flexDirection: 'row !important',
        '.widgets-wrap': {
          flexDirection: 'column !important',
        },
        '.resource-child-0': {
          justifyContent: 'flex-start',
        },
      },
      columnInverse: {
        flexDirection: 'column-reverse !important',
        '@bp3': { flexDirection: 'row !important' },
      },
      normal: {},
    },
  },
});

export const LayoutCol = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    col: {
      1: {
        flexBasis: 'calc(100% / 12)',
      },
      2: {
        flexBasis: 'calc(100% / 6)',
      },
      3: {
        flexBasis: 'calc(100% / 4)',
      },
      4: {
        flexBasis: 'calc(100% / 3)',
      },
      5: {
        flexBasis: 'calc(100% / 2.5)',
      },
      6: {
        flexBasis: 'calc(100% / 2)',
      },
      7: {
        flexBasis: 'calc(100% / 1.714)',
      },
      8: {
        flexBasis: 'calc(100% / 1.5)',
      },
      9: {
        flexBasis: 'calc(100% / 1.33)',
      },
      10: {
        flexBasis: 'calc(100% / 1.2)',
      },
      11: {
        flexBasis: 'calc(100% / 1.09)',
      },
      12: {
        flexBasis: '100%',
      },
    },
    type: {
      sidebar: {
        paddingTop: '1.25rem',
        '@bp3': {
          paddingTop: '2rem',
        },
        '@bp4': {
          paddingTop: '3rem',
        },
        '@bp6': {
          paddingTop: '2.5rem',
        },
      },
      'sidebar-profile': {
        paddingTop: 0,
        '@bp3': {
          paddingTop: '2rem',
        },
        '@bp4': {
          paddingTop: '3rem',
        },
        '@bp6': {
          paddingTop: '2.5rem',
        },
      },
    },
  },
  '.hideDiv': {
    minWidth: 370,
    '@bp4': {
      minWidth: 395,
    },
  },
});

export const LayoutColEmpty = styled(LayoutCol, {
  flexGrow: 1,
  display: 'none',
  flexBasis: 'calc(100% / 3)',
  '@bp3': { justifyContent: 'initial', display: 'flex' },
  '@bp6': {
    flexBasis: '30.33%',
  },
  variants: {
    kind: {
      'empty-people': {
        width: '100%',
        maxWidth: '39.125rem',
        minHeight: ' 20rem',
        '@bp2': {
          minWidth: '15rem',
          height: ' 23rem',
        },
        '@bp3_5': {
          maxWidth: '38rem',
          minWidth: '22rem',
        },
        '@bp5': {
          minWidth: '25rem',
        },
      },
    },
    visible: {
      hide: {
        display: 'none',
      },
      show: {
        display: 'block',
      },
    },
  },
});
