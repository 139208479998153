import { useContext } from 'react';
import AppContext from '../context/app/AppContext';

const useGetCurrentUserID = (): string => {
  const { state } = useContext(AppContext);
  const { userData } = state as any | undefined;

  if (userData?.userId) return userData?.userId;

  return '';
};

export default useGetCurrentUserID;
