import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import useGetToken from '../../../shared/hooks/useGetToken';
import { Input } from '../../Authentication/components/Email/ForgotPassword/styled';
import { ExpertiseContainer, ExpertiseForm, InputContainer, SubmitButton } from '../styles';
import { Error } from '../../../shared/components/Forms/InputError/styled';
import { Title, Text } from '../../../shared/components/Typography/styled';
import Button from '../../../shared/components/Button/Button';
import AppContext from '../../../shared/context/app/AppContext';
import ArrowRightIcon from '../../../assets/images/icons/arrow-right-white.svg';
import { UserExperiencesService } from '../../../services/UserExperiencesService/UserExperienceService';
import TagSelectorPopover from '../../../shared/components/Tags/TagSelectorPopover/TagSelectorPopover';

interface ExpertiseModel {
  next: () => void;
  loadingBtn?: boolean;
}

const Expertise: React.FC<ExpertiseModel> = ({ next, loadingBtn }) => {
  const authenticationToken = useGetToken();
  const { state, dispatch } = useContext(AppContext);
  const [expertiseError, setExpertiseError] = React.useState(false);
  const { experiences, expertise } = state.userData;
  const experience = experiences?.[0];
  const experienceID = experience?.id;

  const initialValues = {
    title: experience?.attributes?.title,
    company: experience?.attributes?.company,
  };
  const FormSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
  });

  const onSubmit = async (values: any) => {
    if (expertise?.length) {
      try {
        const params = {
          authenticationToken,
          ...values,
        };
        let response;
        if (experienceID) {
          response = await UserExperiencesService.update({ ...params, id: experienceID });
        } else {
          response = await UserExperiencesService.create(params);
        }
        dispatch({ type: 'SET_USER_EXPERIENCES', payload: response });
        next();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error', error);
      }
    } else {
      setExpertiseError(true);
    }
  };

  return (
    <ExpertiseContainer>
      <Title css={{ paddingBottom: '$spaceSmall', textAlign: 'left' }}>
        Welcome to Gathersight!
      </Title>
      <Text css={{ paddingBottom: '$spaceLarge', textAlign: 'left' }}>
        To help us match you with expert opinion requests, please provide us with information about
        your knowledge and experience.
      </Text>

      <ExpertiseForm>
        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <InputContainer>
                <Input
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  placeholder="Job title"
                  css={{ marginTop: 0 }}
                />
                {errors.title && touched.title && <Error>{errors.title as string}</Error>}
                <Input
                  type="text"
                  name="company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company}
                  placeholder="Organization name"
                />
                {errors.company && touched.company && <Error>{errors.company as string}</Error>}
                <TagSelectorPopover expertiseError={expertiseError} />
              </InputContainer>
              <SubmitButton>
                <Button
                  kind="login"
                  title="Next"
                  type="submit"
                  iconCustom={ArrowRightIcon}
                  iconPosition="right"
                  renderProps={{ disabled: isSubmitting }}
                  loading={loadingBtn}
                  marginL
                  css={{ width: '100%', height: '56px', maxWidth: '170px', marginBottom: 0 }}
                />
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </ExpertiseForm>
    </ExpertiseContainer>
  );
};

Expertise.defaultProps = {
  loadingBtn: false,
};

export default Expertise;
