/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { FC, ComponentType, useEffect, useState, useMemo, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _isEqual from 'lodash/isEqual';
import Button from '../Button/Button';
import { ModalDiv } from '../Modal/styled';
import AppContext from '../../context/app/AppContext';
import { WrapperSection } from './styles';
import LoadDataModal from './LoadDataModal';
import { removeDuplicateContents } from '../../utils/removeDuplicated';
import { removeContentsAdded, removeContent } from '../../utils/helper';

const DEFAULT_SIZE = 10;
const DEFAULT_TEXT = 'Load More';

interface LoadDataSectionModel {
  onAction: (
    index: number,
    defaultTotal: number,
    filterBy?: string,
    filter?: string,
    orderBy?: string,
  ) => Promise<Array<any>>;
  onActionModal?: (index: number, defaultTotal: number) => Promise<Array<any>>;
  afterLoad?: (totalContent: number) => void;
  component: ComponentType<any>;
  alternativeComponent?: JSX.Element | JSX.Element[];
  shimmerComponent?: JSX.Element | JSX.Element[];
  shimmerInfinity?: JSX.Element | JSX.Element[];
  filterByProp?: string;
  filterProp?: string;
  textProp?: string;
  size?: number;
  typeButton?: string;
  propsComponent?: any;
  reload?: boolean;
  isInfinite?: boolean;
  withModal?: boolean;
  title?: string;
  description?: string;
  kindModal?: string;
  hideLoad?: boolean;
  removeDuplicated?: boolean;
  isDeleteActive?: boolean;
  orderByProp?: string;
}

type LoadDataSectionProps = LoadDataSectionModel &
  React.ComponentProps<typeof WrapperSection> & {
    type?: string;
    empty?: boolean;
    isProviders?: boolean;
    replace?: boolean;
    limitToShow?: number;
    saveData?: boolean;
    loading?: boolean;
  };

type LoadDataModalProps = React.ComponentProps<typeof ModalDiv> & {
  kind?: string;
};

const LoadDataSection: FC<LoadDataSectionProps> = ({
  onAction,
  component: Component,
  filterByProp,
  filterProp,
  textProp,
  size,
  type,
  typeButton,
  empty,
  propsComponent,
  shimmerComponent,
  reload,
  isProviders,
  afterLoad,
  limitToShow,
  replace,
  saveData,
  isInfinite,
  title,
  description,
  withModal,
  kindModal,
  shimmerInfinity,
  onActionModal,
  alternativeComponent,
  hideLoad,
  removeDuplicated,
  orderByProp,
  loading,
  isDeleteActive,
}) => {
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [filterData, setFilterData] = useState<string | undefined>();
  const [nextPage, setNextPage] = useState<number>(2);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hideData, setHideData] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [reloadRequest, setReloadRequest] = useState<boolean>(false);
  const [emptyContent, setEmptyContent] = useState<boolean>(false);
  const [showNotResults, setShowNotResults] = useState<boolean>(false);
  const [totalRepeatData, setTotalRepeatData] = useState<number>(0);
  const { dispatch, state } = useContext(AppContext);
  const { filterContent, alternative, newPlaylistItems, deleteItems } = state;
  const keyAlternative = filterContent.type || '';
  const actionLoadText = textProp || DEFAULT_TEXT;
  const resultsSize = size || DEFAULT_SIZE;
  const totalSize = data.length + totalRepeatData;
  const limitValidation = Number(limitToShow) ? data.length < Number(limitToShow) : true;
  const isPlaylistSearch = propsComponent?.playlistEmpty && newPlaylistItems?.length > 0;

  let dataToShow = data;

  const actionAfter = (value: number) => {
    if (afterLoad) {
      afterLoad(value);
    }
  };

  const actionSaveData = (info: any) => {
    if (saveData) {
      dispatch({ type: 'SET_RESOURCES', payload: info });
    }
  };

  useEffect(() => {
    const removeDuplicatedResponse = () => {
      const contents = removeDuplicateContents(data) || [];
      if (!_isEqual(contents, data)) {
        setData(contents);
        setTotalRepeatData(data.length - contents.length);
      }
    };
    if (removeDuplicated && data?.length > 0) {
      removeDuplicatedResponse();
    }
  }, [data]);

  useMemo(() => {
    const removeAddeItem = () => {
      const isTypeDelete = isDeleteActive && !isPlaylistSearch;
      const defaultData = isTypeDelete ? deleteItems : newPlaylistItems;
      const contents = isTypeDelete
        ? removeContent(data, defaultData)
        : removeContentsAdded(data, defaultData);

      if (!_isEqual(contents, data)) {
        setData(contents);
        actionAfter(contents?.length || 0);
      }
    };
    if ((isPlaylistSearch || isDeleteActive) && data?.length > 0) {
      removeAddeItem();
    }
  }, [newPlaylistItems, deleteItems, data]);

  useEffect(() => {
    const loadData = async (
      currentPage: number,
      limit: number,
      filterBy?: string,
      filter?: string,
      keyAlt?: string,
      orderBy?: string,
    ) => {
      let response = [];
      try {
        setIsLoading(true);
        response = await onAction(currentPage, limit, filterBy, filter, orderBy);
        setHideData(false);
        setShowNotResults(false);
        if (page === 1 && nextPage === 2) {
          setData(response);
          actionSaveData(response);
          actionAfter(response?.length || 0);
        } else {
          const newData = replace ? [...response] : [...data, ...response];
          actionSaveData(newData);
          setData(newData);
          const newTotal = Number(data?.length) + Number(response?.length);
          actionAfter(newTotal || 0);
          if (currentPage === nextPage) {
            setNextPage(nextPage + 1);
          }
        }

        if (!Number(response?.length) || response?.length < resultsSize) {
          setEmptyContent(true);
        }

        if (
          !showNotResults &&
          response.length > 0 &&
          alternativeComponent &&
          keyAlt &&
          `${keyAlt}` in alternative &&
          alternative[keyAlt]
        ) {
          dispatch({ type: 'SET_ALTERNATIVE', payload: { [keyAlt]: false } });
        }

        setReloadRequest(false);
        setErrorMsg('');
      } catch (error) {
        setErrorMsg('Error while loading data. Try again later.');
      } finally {
        setIsLoading(false);
        if (alternativeComponent && data.length === 0) {
          setShowNotResults(Boolean(response.length === 0));
        }
      }
    };

    if (nextPage === page + 1 || reloadRequest) {
      loadData(page, resultsSize, filterByProp, filterData, keyAlternative, orderByProp);
    }
  }, [page, nextPage, filterByProp, filterData, resultsSize, reloadRequest, orderByProp]);

  useMemo(() => {
    if (reload && !reloadRequest) {
      dispatch({ type: 'SET_RELOAD', payload: false });
      setReloadRequest(true);
    }
  }, [reload]);

  useMemo(() => {
    if (filterProp) {
      setFilterData(filterProp);
    } else {
      setFilterData(undefined);
    }
    setPage(1);
    setNextPage(2);
    setData([]);
    setTotalRepeatData(0);
  }, [filterProp, orderByProp]);

  useMemo(() => {
    setHideData(true);
  }, [filterByProp, filterProp]);

  useMemo(() => {
    if (loading) {
      setHideData(true);
    }
  }, [loading]);

  const loadMore = () => {
    setPage((lastPage) => lastPage + 1);
    setNextPage((lastPageNext) => lastPageNext + 1);
  };

  const actionInfinite = () => {
    setTimeout(() => {
      if (data.length > 0 && totalSize >= resultsSize && limitValidation) {
        loadMore();
      }
    }, 800);
  };

  useEffect(() => {
    if (showNotResults) {
      dispatch({ type: 'SET_ALTERNATIVE', payload: { [keyAlternative]: true } });
    }
  }, [showNotResults]);

  useEffect(
    () => () => {
      dispatch({ type: 'SET_ALTERNATIVE', payload: { [keyAlternative]: false } });
      actionSaveData([]);
      setData([]);
      setPage(1);
      setNextPage(2);
      setEmptyContent(false);
      setFilterData(undefined);
      setTotalRepeatData(0);
    },
    [],
  );

  if (data.length > Number(limitToShow)) {
    dataToShow = dataToShow.slice(0, limitToShow);
  }

  const finalPropsComponent = { data: dataToShow, ...propsComponent };

  return (
    <WrapperSection
      className="load-data-section"
      type={type}
      empty={empty}
      isProviders={isProviders}
    >
      {showNotResults && alternativeComponent}
      <InfiniteScroll
        dataLength={data.length}
        next={actionInfinite}
        hasMore={(isInfinite && !showNotResults) || false}
        loader={!emptyContent && limitValidation && (shimmerInfinity || shimmerComponent || '')}
      >
        {/* @ts-ignore */}
        {!hideData && !empty && <Component {...finalPropsComponent} />}
        {((isLoading && !data.length && !empty) || hideData) && shimmerComponent}
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        {data.length > 0 &&
          totalSize >= resultsSize &&
          limitValidation &&
          !isInfinite &&
          !hideLoad && (
            <div className="load-more">
              <Button
                title={isLoading ? 'Loading...' : actionLoadText}
                kind={typeButton === 'outline' ? 'outline' : 'loadMoreText'}
                handleClick={() => (withModal ? setOpenModal(true) : loadMore())}
              />
            </div>
          )}
      </InfiniteScroll>
      {openModal && (
        <LoadDataModal
          actionClose={() => setOpenModal(false)}
          onAction={onActionModal || onAction}
          title={title}
          description={description}
          component={Component}
          propsComponent={{ ...propsComponent, viewModal: true }}
          shimmerComponent={shimmerComponent}
          shimmerInfinity={shimmerInfinity}
          kind={kindModal as LoadDataModalProps['kind']}
          isOpen={false}
          size={size}
          removeDuplicated
          filterByProp={filterByProp}
        />
      )}
    </WrapperSection>
  );
};

LoadDataSection.defaultProps = {
  type: undefined,
  empty: undefined,
  typeButton: undefined,
  filterByProp: undefined,
  filterProp: undefined,
  textProp: undefined,
  size: undefined,
  reload: false,
  isProviders: false,
  isInfinite: false,
  shimmerComponent: undefined,
  shimmerInfinity: undefined,
  afterLoad: undefined,
  limitToShow: undefined,
  replace: undefined,
  saveData: undefined,
  propsComponent: {},
  title: undefined,
  description: undefined,
  withModal: false,
  kindModal: undefined,
  alternativeComponent: undefined,
  hideLoad: false,
  removeDuplicated: false,
  orderByProp: undefined,
  loading: undefined,
  isDeleteActive: undefined,
  onActionModal: undefined,
};

export default LoadDataSection;
