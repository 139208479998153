import React, { createContext } from 'react';
import { ExpertData } from '../../interfaces/interfaces';

export interface ContextProps {
  expert: ExpertData;
  setExpert: React.Dispatch<React.SetStateAction<ExpertData>>;
}

const ExpertContext = createContext<ContextProps>({} as ContextProps);

export default ExpertContext;
