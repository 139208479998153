/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Select, SelectItem } from './styles';

interface SelectItemType {
  value: number | string;
  label: string;
}
interface SelectModel {
  options: SelectItemType[];
  placeholder?: string;
  onChange?: (event: any) => void;
  value?: number | string;
}

type SelectProps = SelectModel &
  React.ComponentProps<typeof Select> & {
    css?: any;
  };

const SelectComponent = ({
  placeholder,
  onChange,
  options,
  value: valueProp,
  css
}: SelectProps) => {
  const [defaultValue, setDefaultValue] = useState<number | string | undefined>(
    valueProp || undefined);

  const handlerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const findValue = options.find((i: any) => i.value === e?.target?.value);

    setDefaultValue(e.target.value);

    if (onChange && findValue) {
      onChange(findValue);
    }
  };

  return (
    <Select
      css={css}
      onChange={(item: React.ChangeEvent<HTMLSelectElement>) => handlerSelect(item)}
      defaultValue={defaultValue}
    >
      <SelectItem selected disabled>{placeholder}</SelectItem>
      {options && options.map(({ value, label}) => (
        <SelectItem
          key={value}
          id={label}
          value={value}
        >
          {label}
        </SelectItem>
      ))}
    </Select>
  );
};

SelectComponent.defaultProps = {
  css: undefined,
  value: undefined,
  onChange: undefined,
  placeholder: 'Select option',
};

export default SelectComponent;
