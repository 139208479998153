/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../shared/components/Loading/Loading';
import { useProjects } from '../../../../shared/hooks/useProjects';
import useGetToken from '../../../../shared/hooks/useGetToken';
import ProjectsContext from '../../../../shared/context/projects/ProjectsContext';

const LoadingProject = () => {
  const navigate = useNavigate();
  const { getProjectFromSlug } = useProjects();
  const { setProject } = useContext(ProjectsContext);
  const authenticationToken = useGetToken();
  useEffect(() => {
    let timeoutId: any;
    const checkProject = async () => {
      try {
        const project = await getProjectFromSlug();
        if (!project) navigate('/404');
        if (project.attributes.project_questions.length) {
          setProject(project);
          navigate(`/organization/projects/${project.attributes.slug}`);
        } else {
          timeoutId = setTimeout(checkProject, 1000);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    if (authenticationToken) checkProject();

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken]);

  return useMemo(() => <Loading />, []);
};

export default React.memo(LoadingProject);
