import { useContext, useState } from 'react';
import { MemberService } from '../../../services/OrganizationService/MemberService';
import useGetToken from '../useGetToken';
import OrganizationContext from '../../context/organization/OrganizationContext';

export const useMember = () => {
  const { members, setMembers } = useContext(OrganizationContext);
  const authenticationToken = useGetToken();
  const [isMemberLoading, setIsMemberLoading] = useState(true);

  const sendMemberInvitation = async (email: string) => {
    const member = await MemberService.inviteMember(authenticationToken, email);
    setMembers([member, ...members]);
    return member;    
  };

  const getMembers = async () => {
    const resMembers = await MemberService.getMembers(authenticationToken);
    setMembers(resMembers);
    setIsMemberLoading(false);
  };

  return { sendMemberInvitation, members, getMembers, isMemberLoading };
};
