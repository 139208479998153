import React from 'react';
import { styled } from '@stitches/react';

interface StateModel {
  state:
    | 'completed'
    | 'archived'
    | 'draft'
    | 'in_progress'
    | 'inReview'
    | 'sent'
    | 'owner'
    | 'pending'
    | 'done'
    | 'accepted';
}
const stateDisplayNames = {
  accepted: 'Accepted',
  completed: 'Completed',
  archived: 'Archived',
  draft: 'Draft',
  in_progress: 'In Progress',
  inReview: 'In Review',
  sent: 'Pending',
  owner: 'Owner',
  pending: 'In Progress',
  done: 'Done',
};

const StyledStatus = styled('div', {
  textAlign: 'center',
  fontSize: '$bodyXSSmall',
  fontWeight: '$weight500',
  padding: '4px',
  borderRadius: '8px',
  maxWidth: 100,
  variants: {
    state: {
      completed: {
        color: '#03543F',
        backgroundColor: '#DEF7EC',
      },
      accepted: {
        color: '#03543F',
        backgroundColor: '#DEF7EC',
      },
      archived: {
        color: '#9B1C1C',
        backgroundColor: '#FDE8E8',
      },
      draft: {
        color: '#723B13',
        backgroundColor: '#FDF6B2',
      },
      in_progress: {
        color: '#5521B5',
        backgroundColor: '#EDEBFE',
      },
      inReview: {
        color: '#723B13',
        backgroundColor: '#FDF6B2',
      },
    },
  },
});

const Status: React.FC<StateModel> = ({ state }) => {
  const getStatusColor = () => {
    if (state === 'sent') {
      return 'draft';
    }
    if (state === 'owner' || state === 'pending') {
      return 'in_progress';
    }
    if (state === 'done') {
      return 'completed';
    }
    return state;
  };
  return <StyledStatus state={getStatusColor()}>{stateDisplayNames[state]}</StyledStatus>;
};

export default Status;
