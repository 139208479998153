import { styled } from '../../../config/theme';

export const TextDiscover = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$displayXSmall',
  lineHeight: '$lineHeight3',
});

export const NoContentWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column', 
  width: '100%',
  justifyContent: 'center',
  padding: '$spaceSmall',

  '@bp2': {
    padding: '$spaceMedium',
  }
});
