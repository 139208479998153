/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Button from '../../shared/components/Button/Button';
import WhoToFollow from '../../shared/components/WhoToFollow/WhoToFollow';
import PageContainer from '../../shared/components/Container/PageContainer';
// import WidgetNewProvider from '../../shared/components/Widget/WidgetNewProvider';
import WidgetResponsive from '../../shared/components/Widget/WidgetResponsive';
import Contents from './Contents/Contents';
import AppContext from '../../shared/context/app/AppContext';
import { ProfileService } from '../../services/ProfileService/ProfileService';
import BackArrow from '../../assets/images/icons/back-arrow.svg';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
import { Widget, DivWidgets } from '../../shared/components/Widget/styles';
import { LayoutColFollowers, TextTotal } from './styled';

const Followers = () => {
  const [userFollowers, setUserFollowers] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { state } = useContext(AppContext);
  const { authenticationToken, userData } = state;
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { state: stateRoute } = location as any;
  const totalFollowers = stateRoute?.total || userFollowers;
  const userId = id || userData?.userId || '';

  const back = () => {
    navigate(`/profile/${userId}`);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!totalFollowers) {
          const user = await ProfileService.getUser({ authenticationToken, id: userId });
          await setUserFollowers(user?.attributes?.followers);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [authenticationToken, userId]);

  return (
    <PageContainer layout="home">
      <LayoutContainer type="breadcrumbs">
        <LayoutCol col="3" css={{ alignItems: 'flex-start' }}>
          <div style={{ minWidth: '322px', width: '100%' }}>
            <Button
              title="Back to profile"
              kind="back"
              css={{ height: 'auto' }}
              iconCustom={BackArrow}
              handleClick={() => back()}
            />
          </div>
        </LayoutCol>
        <LayoutCol col="9">
          {!loading && typeof totalFollowers !== 'undefined' && (
            <div style={{ width: '100%' }}>
              <TextTotal>{`${totalFollowers} Followers`}</TextTotal>
            </div>
          )}
        </LayoutCol>
      </LayoutContainer>
      <LayoutContainer type="followings">
        <LayoutColFollowers col="3" css={{ gap: '$spaceLarge', '@bp3': { gap: '0' } }}>
          <WidgetResponsive
            breakpoint={980}
            kindResource="author"
            css={{ minHeight: 0, gridTemplateColumns: '1fr', marginBottom: '0' }}
          />
          <DivWidgets className="widgets-wrap">
            <Widget className="who-to-follow">
              <WhoToFollow />
            </Widget>
            {/* <WidgetNewProvider /> */}
          </DivWidgets>
        </LayoutColFollowers>
        <LayoutCol col="9">
          <Contents />
        </LayoutCol>
      </LayoutContainer>
    </PageContainer>
  );
};

export default Followers;
