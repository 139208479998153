import { Link } from 'react-router-dom';
import { styled } from '../../../config/theme';

export const HeaderContainer = styled('div', {
  backgroundColor: '$offWhite',
  display: 'flex',
  width: '100% !important',
  color: '$offBlack',
  padding: '.5rem $spaceLarge',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: '0 0 2rem hsla(0, 0%, 0%, 0.07)',
  height: 60,
  zIndex: 8,
  position: 'fixed',
  '@bp2': {
    height: 70,
    width: 'stretch',
  },
  '@bp3': {
    padding: '$spaceSmall $spaceLarge',
  },
  '@bp4': {
    padding: '$spaceSmall $spaceXLarge',
  },
});

export const Title = styled('span', {
  color: '$offBlack',
  textDecoration: 'none',
  fontWeight: '$weight700',
  letterSpacing: 1,
  fontFamily: '$Poppins',
  '@bp3': {
    fontSize: '$bodyLarge',
  },
  '@bp4': {
    fontSize: '$bodyLarge',
    marginRight: '$sizes$2',
  },
});

export const TitleSM = styled('a', {
  color: '$offBlack',
  textDecoration: 'none',
  display: 'block',
  fontWeight: '$weight700',
  letterSpacing: 1,
  fontFamily: '$Poppins',
  fontSize: '$bodyLarge',
  '@bp2': {
    display: 'none',
  },
});

export const AuthWrapperButton = styled('div', {
  display: 'flex',
  alignItems: 'center',

  variants: {
    type: {
      'not-logged-mobile': {
        display: 'block',

        '@bp2': {
          display: 'none',
        },
      },
      'not-logged': {
        display: 'none',
        '@bp2': {
          display: 'flex',
          minWidth: '150px',
        },
        '@bp3': {
          minWidth: '190px',
        },
      },
    },
  },
});

export const AuthButton = styled('div', {
  display: 'block',
  float: 'left',
  fontFamily: '$Poppins',
  fontWeight: '$weight600',
  letterSpacing: 1,
  textAlign: 'center',
  width: 'auto',
  backgroundColor: '$offWhite',
  color: '$body',

  '@bp2': {
    padding: '$spaceXSmall ',
  },
  '@bp3': {
    padding: '0 $spaceSmall',
  },

  variants: {
    kind: {
      'not-logged': {
        fontSize: '$bodyXSmall',
        width: '100%',
        '@bp3': {
          fontSize: '$bodySmall',
        },
      },
    },
  },
});

export const FeedContainer = styled('div', {
  alignItems: 'center',
  display: 'none',
  marginRight: '0',
  '@bp2': {
    display: 'flex',
    marginLeft: '$spaceXSmall',
  },
  '@bp4': {
    marginRight: '$sizes$2',
  },
  variants: {
    kind: {
      'not-logged': {
        marginRight: '0px',
        '@bp2': {
          display: 'flex',
          marginRight: '$sizes$1',
        },
        '@bp4': {
          marginRight: '$sizes$2',
        },
      },
    },
  },
});

export const FeedButton = styled('button', {
  borderRadius: '$radius03',
  cursor: 'pointer',
  float: 'left',
  fontFamily: '$Poppins',
  fontWeight: '$weight500',
  fontSize: '$bodyXSmall',
  padding: '$spaceXSmall',
  marginX: '$spaceXSmall',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'transparent',
  border: 'none',
  '.gat-icon': {
    width: 'max-content',
  },
  variants: {
    color: {
      active: {
        backgroundColor: '$bodyPale',
        color: '$body',
        opacity: 1,
        fontWeight: '$weight600',
      },
      noActive: {
        color: '$offBlack',
        '&:hover': {
          backgroundColor: '$bodyPale',
        },
      },
    },
    kind: {
      feed: {
        width: 'max-content',
        '& img': {
          margin: 0,
        },
        '&::after': {
          content: '',
          '@bp4': {
            content: 'For you',
          },
        },
        '@bp4': {
          padding: '$spaceXSmall $spaceSmall',
          minWidth: '106px',
          '& img': {
            marginRight: '$spaceXSmall',
          },
        },
      },
      'my-feed': {
        width: 'max-content',
        '& img': {
          margin: 0,
        },
        '&::after': {
          content: '',
          '@bp4': {
            content: 'My Feed',
          },
        },
        '@bp4': {
          padding: '$spaceXSmall calc($spaceXSmall * 2) $spaceXSmall $spaceXSmall',
          minWidth: '119px',
          '& img': {
            marginX: '$spaceXSmall',
          },
        },
      },
      discover: {
        padding: 0,
        '& img': {
          margin: 0,
        },
        '&::after': {
          content: '',
          '@bp4': {
            content: 'Discover',
          },
        },
        '@bp2': {
          padding: '$spaceXSmall',
        },
        '@bp4': {
          padding: '$spaceXSmall calc($spaceXSmall * 2) $spaceXSmall $spaceXSmall',
          '& img': {
            marginX: '$spaceXSmall',
          },
        },
      },
      expert: {
        width: 'max-content',
        padding: 0,
        '& img': {
          margin: 0,
        },
        '&::after': {
          content: '',
          '@bp4': {
            content: 'Expert Opinions',
          },
        },
        '@bp2': {
          padding: '$spaceXSmall',
        },
        '@bp4': {
          padding: '$spaceXSmall calc($spaceXSmall * 2) $spaceXSmall $spaceXSmall',
          '& img': {
            marginX: '$spaceXSmall',
          },
        },
      },
      projects: {
        width: 'max-content',
        padding: 0,
        '& img': {
          margin: 0,
        },
        '&::after': {
          content: '',
          '@bp4': {
            content: 'Projects',
          },
        },
        '@bp2': {
          padding: '$spaceXSmall',
        },
        '@bp4': {
          padding: '$spaceXSmall calc($spaceXSmall * 2) $spaceXSmall $spaceXSmall',
          '& img': {
            marginX: '$spaceXSmall',
          },
        },
      },
      icon: {
        padding: '$spaceXSmall',
        '& img': {
          margin: 0,
        },
      },
    },
  },
});

export const AvatarLink = styled(Link, {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  '& img': {
    width: '2.5rem',
    height: '2.5rem',
  },
});

export const LogoLink = styled('div', {
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'block',
  '& img': {
    marginLeft: '0',
  },
  '@bp3': {
    display: 'none',
  },
});

export const DivLink = styled('div', {
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'none',
  '@bp3': {
    display: 'block',
  },
});

export const KBLink = styled(Link, {
  display: 'none',
  '@bp2': {
    display: 'block',
  },
});
