import * as Collapsible from '@radix-ui/react-collapsible';
import { styled } from '../../../config/theme';


export const CollapsibleRoot = styled(Collapsible.Root, {
  width: '100%',
  paddingTop: '$spaceLarge !important'
});

export const CollapsibleTrigger = styled(Collapsible.Trigger, {
});

export const CollapsibleContent = styled(Collapsible.Content, {
});

export const IconButton = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
});

export const AccordionArrow = styled('div', {
  transition: 'transform 300ms',
  '[data-state=open] &': { transform: 'rotate(180deg)' },
});

export const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '1.75rem'
});

export const Text = styled('span', {
  color: '$body',
  fontWeight: '$weight700',
  lineHeight: '$lineHeight2',
  fontSize: '$bodySmall',
});

export const Block = styled('div', {
});