import { styled } from '../../../config/theme';

export const IconCircleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '1.5rem',
  width: '1.5rem',
  border: 'solid 1px $blurple',
  borderRadius: '100%',
  variants: {
    LinkedIn: {
      true: {
        paddingBottom: '0.1rem',
      },
    },
  },
});

export const Separator = styled('div', {
  width: '100%',
  height: 1,
  background: '#D9DBE9',
  opacity: 0.3
});

export const ImageOrganizationContainer = styled('div', {
  borderRadius: '100%',
  height: '5rem',
  width: '5rem',
  minWidth: '5rem',
  variants: {
    noImage: {
      true: {
        background: '$grayscaleInput2',
      },
    },
    isAvatar: {
      true: {
        height: '2.8rem',
        width: '2.8rem',
        minWidth: '2.8rem',
      },
    },
    isAvatarMember: {
      true: {
        height: '4.25rem',
        width: '4.25rem',
        minWidth: '4.25rem',
      },
    },
  },
});

export const ImgOrganizationAvatar = styled('img', {
  borderRadius: '100%',
  height: '5rem',
  width: '5rem',
  minHeight: '5rem',
  minWidth: '5rem',
});
