import { Field } from 'formik';
import { styled } from '../../../../../config/theme';

export const InputWrapper = styled('div', {
  maxWidth: '647px',
  display: 'flex',
  borderRadius: '$radius05',
  alignItems: 'center',
  position: 'relative',
  minHeight: 48,
  width: '100%',
});

export const FormikInput = styled(Field, {
  background:'transparent',
  width: '100%',
  border: '1px solid $line',
  borderRadius: '10px',
  padding: '$spaceXSmall $spaceSmall',
  margin: '$spaceXSmall 0',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing:'$letterSpacing3',
  fontSize: '$bodySmall'
});