import { ActionType, StateInterface } from './types';
import { initialState } from './AppProvider';

const AppReducer = (state: StateInterface, action: ActionType): any => {
  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        filterContent: action.payload,
      };
    case 'SET_USER': {
      const data = action.payload.attributes;
      const authenticationToken = data.token || state.authenticationToken;
      const tokenExpiration = data.token_expiration;
      const user = {
        userId: action.payload.id,
        firstName: data.first_name,
        lastName: data.last_name,
        biography: data.biography,
        email: data.email,
        researchGate: data.research_gate,
        linkedinLink: data.linkedin_link,
        username: data.username,
        avatarUrl: data.avatar_url,
        twitterHandle: data.twitter_handle,
        userSetting: data.user_setting,
        status: data.status,
        following: data.following,
        expertise: data.expertise?.data,
        languages: data.languages,
        timezone: data.timezone,
        experiences: data.experiences,
        slug: data.slug,
        interests: data.interests,
        userType: data.user_type,
        kind: data.kind,
        organizationAvatarUrl: data.organization_avatar_url || '',
        organizationName: data.organization || '',
        credits: data.credits,
      };
      return {
        ...state,
        authenticationToken,
        tokenExpiration,
        userData: user,
      };
    }
    case 'SET_RELOAD':
      return {
        ...state,
        reload: action.payload,
      };
    case 'SET_TIME_OUT':
      return {
        ...state,
        isTimeOut: action.payload,
      };
    case 'SET_USER_SETTINGS': {
      const user = { ...state.userData, userSetting: action.payload };

      return {
        ...state,
        userData: user,
      };
    }
    case 'SET_USER_EXPERIENCES': {
      const userData = { ...state.userData, experiences: action.payload };
      return { ...state, userData };
    }
    case 'SET_EXPERTISES': {
      const userData = { ...state.userData, expertise: action.payload };
      return { ...state, userData };
    }
    case 'SET_RESOURCES': {
      return {
        ...state,
        resources: action.payload,
      };
    }
    case 'SET_PLAYLIST_ITEMS': {
      return {
        ...state,
        newPlaylistItems: action.payload,
      };
    }
    case 'SET_CONTENT_TO_PLAYLIST': {
      return {
        ...state,
        contentToPlaylist: action.payload,
      };
    }
    case 'SET_ALTERNATIVE': {
      const alternativeData = { ...state.alternative, ...action.payload };

      return {
        ...state,
        alternative: alternativeData,
      };
    }
    case 'SET_FOLLOW': {
      const followData = { ...state.dataFollow, ...action.payload };

      return {
        ...state,
        dataFollow: followData,
      };
    }
    case 'SET_UNFOLLOW': {
      const unfollowData = { ...state.dataUnfollow, ...action.payload };

      return {
        ...state,
        dataUnfollow: unfollowData,
      };
    }
    case 'RESET_FOLLOW_UNFOLLOW': {
      return {
        ...state,
        dataFollow: undefined,
        dataUnfollow: undefined,
      };
    }
    case 'SET_LOCAL_IMAGE': {
      const dataImage = { ...state.images, ...action.payload };

      return {
        ...state,
        images: dataImage,
      };
    }
    case 'RESET_IMAGES': {
      return {
        ...state,
        images: undefined,
      };
    }
    case 'SET_DELETE_ITEMS': {
      return {
        ...state,
        deleteItems: action.payload,
      };
    }
    case 'SET_CREDITS': {
      const user = { ...state.userData, credits: action.payload };
      return {
        ...state,
        userData: user,
      };
    }
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

export default AppReducer;
