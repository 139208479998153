import React from 'react';
import { styled } from '@stitches/react';

interface VideoProgressProps {
  totalVideos: number;
  receivedVideos: number;
  progress: 'draft' | 'completed' | 'in_progress';
}

const StyledStatus = styled('div', {
  padding: '4px',
  borderRadius: '8px',
  maxWidth: 50,
  textAlign: 'center',
  variants: {
    state: {
      completed: {
        color: '#03543F',
        backgroundColor: '#DEF7EC',
      },
      in_progress: {},
      null: {},
    },
  },
});

const mapProgressToState = (progress: string, receivedVideos: number, totalVideos: number) => {
  if (progress === 'completed' && receivedVideos === totalVideos) {
    return 'completed';
  }
  if (progress === 'in_progress') {
    return 'in_progress';
  }
  return 'null';
};

const VideoProgress: React.FC<VideoProgressProps> = ({ totalVideos, receivedVideos, progress }) => {
  const getProgressDisplay = () => {
    if (progress === 'draft') {
      return '-';
    }
    if (progress === 'completed') {
      return `${receivedVideos}/${totalVideos}`;
    }
    if (progress === 'in_progress') {
      return `${receivedVideos}/${totalVideos}`;
    }
    return '-';
  };

  const progressDisplay = getProgressDisplay();

  return (
    <StyledStatus state={mapProgressToState(progress, receivedVideos, totalVideos)}>
      {progressDisplay}
    </StyledStatus>
  );
};

export default VideoProgress;
