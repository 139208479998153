import { TagModelSave } from '../../shared/interfaces/interfaces';
import axiosInstance from '../utils/axiosInstance';

export const TagService = {
  getTags: async (authenticationToken: string, page?: number, perPage?: number, name?: string) => {
    try {
      const query: any = {};
      if (typeof page === 'number' && perPage) {
        query.page = page;
        query.per_page = perPage;
      }
      if (name) {
        query.name = name;
      }
      const results = await axiosInstance(authenticationToken)
        .get(`tags`, { params: query })
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  getTagById: async (authenticationToken: string, id: string | undefined) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`tags/${id}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  saveTagTopic: async (authenticationToken: string, topic: TagModelSave) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .post(`tags`, topic)
        .then((response) => response?.data?.data);
      return results;
    } catch (error) {
      return [];
    }
  },
  saveExpertise: async (
    authenticationToken: string,
    userId: number,
    tagId: string | undefined,
  ) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .post(`users/${userId}/expertises?expertises[]=${tagId}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  deleteExpertise: async (
    authenticationToken: string,
    userId: number,
    tagId: string | undefined,
  ) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .delete(`users/${userId}/expertises/${tagId}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  saveTagContent: async (
    authenticationToken: string,
    contentId: string,
    tagId: string | undefined,
  ) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .post(`contents/${contentId}/content_tag_links?tag_ids[]=${tagId}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  deleteTagContent: async (
    authenticationToken: string,
    contentId: string,
    tagId: string | undefined,
  ) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .delete(`contents/${contentId}/content_tag_links/${tagId}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  getTagsByName : async (name: string) => {
    try {
      const results = await axiosInstance()
        .get('tags', {params: { name, page: 1, per_page: 10}})
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  }
};
