import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FlexContainer, Typography } from '../styled';
import { OpinionInput } from './styled';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import { useProjects } from '../../../shared/hooks/useProjects';
import { Project } from '../../../shared/interfaces/projectInterfaces';

const ProjectOpinions = () => {
  const { opinions, setOpinions } = useContext(ProjectsContext);
  const [hasError, setHasError] = useState(false);
  const [opValue, setOpValue] = useState(opinions);
  const { updateProject, getProjectStatus, project } = useProjects();
  const status = getProjectStatus(project as Project);

  const updateProjectOpinions = async () => {
    const data = { opinions: opValue };
    setOpinions(opValue);
    await updateProject(data, false);
  };

  const saveOpinions = () => {
    if (opValue === '' || opValue === undefined) return;

    if (opValue !== opinions) {
      if (parseInt(opValue, 10) < 1 || parseInt(opValue, 10) > 8) {
        toast.error('The number of opinions must be between 1 and 8');
        setHasError(true);
      } else {
        setHasError(false);
        updateProjectOpinions();
      }
    }
  };

  useEffect(() => {
    setOpValue(opinions);
  }, [opinions]);

  return (
    <FlexContainer align="center" gap="0_75">
      <Typography
        size="displayXXSmall"
        weight="700"
        lineHeight="lineHeight3"
        letterSpacing="letterSpacing3"
        noWrap
      >
        No. of opinions
      </Typography>
      <OpinionInput
        type="number"
        value={opValue || ''}
        isEmpty={!opValue || hasError}
        onChange={(event) => setOpValue(event.target.value)}
        onBlur={saveOpinions}
        disabled={status !== 'draft'}
      />
    </FlexContainer>
  );
};

export default ProjectOpinions;
