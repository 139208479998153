import { styled } from '../../../../config/theme';

export const TypographyContainer = styled('div', {
  maxWidth: '$47',
  paddingX: '$spaceSmall'
});

export const BlueElipseIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$4',
  minWidth: '$4',
  height: '$4',
  borderRadius: '$radius4',
  background: '$blurple',
  marginRight: '$spaceSmall',
  '@bp2': {
    marginRight: '$spaceNone',
    marginBottom: '$spaceSmall',
  },
  svg: {
    width: '$2',
    minWidth: '$2',
  }
});

export const ItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  width: '$20',
  paddingX: '$spaceLarge',
  '@bp2': {
    flexDirection: 'column',
    width: '10rem',
  },
  '@bp4': {
    width: '$20',
  },
});

export const ItemsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  flexWrap: 'wrap',
  gap: '$spaceXLarge',
  maxWidth: 1400,
  '@bp2': {
    gap: '$spaceSmall',
    rowGap: '$spaceMedium',
  },
  paddingX: '$spaceLarge',
  marginTop: '$spaceMedium',
});
