import React from 'react';
import lock from '../../../assets/images/icons/lock-black.svg';
import Icon from '../../../shared/components/Icon/Icon';
import { TextDiscover, CompilingWrapper } from '../styles';

const PlaylistPrivate: React.FC = () => (
  <CompilingWrapper>
    <Icon img={lock} width='20px' height='20px' alt="Private" />
    <TextDiscover>
      This Knowledge Playlist is Private
    </TextDiscover>
  </CompilingWrapper>
);

export default PlaylistPrivate;
