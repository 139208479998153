import { styled } from '../../../config/theme';

export const InputWrapper = styled('div', {
  maxWidth: '647px',
  display: 'flex',
  borderRadius: '$radius05',
  alignItems: 'center',
  position: 'relative',
  minHeight: 48,
  width: '100%',

  variants: {
    type: {
      playlist: {
        width: '100%',
        maxWidth: 'unset',
        backgroundColor: '$input',
        border: '$body 1px solid',
        padding: '0 $spaceSmall',
        marginBottom: '$spaceSmall',
      },
      stepper: {
        border: '1px solid $line',
        marginY: '$spaceSmall',
        '&:focus': {
          border: '1px $blurple solid',
        },
      },
    },
  },
});

export const IconWrapper = styled('div', {
  background: 'transparent',
  borderRadius: '$radius1',
  width: '5%',
  height: '100%',
  minWidth: '$2',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '$spaceXSmall',
});

export const Input = styled('input', {
  background: 'transparent',
  width: '100%',
  border: 'none',
  paddingX: '$spaceXSmall',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  variants: {
    styleType: {
      steps: {
        minHeight: 48,
        borderRadius: '$radius05',
        border: '1px solid $line',
        marginTop: '$spaceXLarge',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $blurple solid',
        },
      },
      errorStep: {
        minHeight: 48,
        borderRadius: '$radius05',
        border: '1px solid $red',
        marginTop: '$spaceXLarge',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $blurple solid',
        },
      },
    },
  },
});

// export const InputForm = styled(FormikInput, {
//   background: 'transparent',
//   width: '100%',
//   border: 'none',
//   paddingX: '$spaceXSmall',
//   outline: 'none',
//   transition: '180ms box-shadow ease-in-out',
//   color: '$label',
//   lineHeight: '$lineHeight2',
//   letterSpacing: '$letterSpacing3',
//   fontSize: '$bodySmall',
//   variants: {
//     styleType: {
//       steps: {
//         minHeight: 48,
//         borderRadius: '$radius05',
//         border: '1px solid $line',
//         marginY: '$spaceSmall',
//         fontFamily: '$poppins',
//         '&:focus': {
//           border: '1px $blurple solid',
//         },
//       },
//     },
//   },
// });

export const ListWrapper = styled('div', {
  position: 'absolute',
  width: '100%',
  top: '100%',
  height: '350px',
  display: 'flex',
  flexDirection: 'column',
  variants: {
    hideResults: {
      true: {
        height: 'auto',
      },
    },
  },
});

export const ListResult = styled('ul', {
  position: 'absolute',
  listStyle: 'none',
  margin: 0,
  paddingLeft: 0,
  backgroundColor: 'white',
  boxShadow: '$shadowBase',
  color: '$offBlack',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1rem',
  marginTop: '.5rem',
  transition: 'all 0.3s ease-in-out',
  opacity: 1,
  zIndex: 1,
  minWidth: 170,
  width: '100%',

  variants: {
    empty: {
      true: {
        zIndex: -1,
        opacity: 0,
      },
    },
  },
});

export const ItemResult = styled('li', {
  display: 'block',
  cursor: 'pointer',
  float: 'left',
  padding: '0.25rem 1rem',
  position: 'relative',
  textDecoration: 'none',
  transitionDuration: '0.5s',
  '&:first-child': {
    paddingTop: '1rem',
  },
  '&:last-child': {
    paddingBottom: '1rem',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
  },
  '&:hover': {
    backgroundColor: '$input',
  },
});

export const ListAutocomplete = styled(ListResult, {
  flex: '0 1 auto',
  overflow: 'auto',
  maxHeight: '30vh',
  overflowY: 'scroll',
  zIndex: '100',

  '@bp3': {
    maxHeight: '20vh',
  },
});
