import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import OrganizationContext from '../../context/organization/OrganizationContext';
import { ProfileService } from '../../../services/ProfileService/ProfileService';
import useGetToken from '../useGetToken';
import { useUser } from '../useUser';

export const useOrganization = () => {
  const authenticationToken = useGetToken();
  const { userId, setUser, userData } = useUser();
  const { organizationInformation, setOrganizationInformation } = useContext(OrganizationContext);

  const handleChangeInformation = async (name: string | undefined, image: File) => {
    if (image) {
      const avatar = new FormData();
      avatar.append('organization_avatar', image);
      const data = { userId: userId as string, authenticationToken, avatar };
      const res = await ProfileService.updateOrganizationAvatar(data);
      setUser(res);
    }
    if (name !== organizationInformation.name) {
      const res = await ProfileService.updateOrganizationName(
        authenticationToken,
        name,
        userId as string,
      );
      if (res.length === 0) {
        // toast.error(`Can't save profile`);
      } else {
        // next line is only temporal to be sure the back return organization attribute
        res.organization = name;
        setUser(res);
      }
    }
    toast.success('Organization updated!');
    setOrganizationInformation({ name, image: URL.createObjectURL(image) });
  };

  useEffect(() => {
    setOrganizationInformation({
      name: userData.organizationName as string,
      image: userData.organizationAvatarUrl as string,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return { organizationInformation, handleChangeInformation };
};
