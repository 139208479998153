import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import { TextDiscover, NoContentWrapper } from './styled';

type NoContentWidgetProps = { title?: string, hideButton?: boolean }

const NoContentWidget: React.FC<NoContentWidgetProps> = ({title, hideButton}) => {
  const navigate = useNavigate();
  return(
    <NoContentWrapper>
      <TextDiscover css={{textAlign: 'center'}}>
        {title}
      </TextDiscover>
      {!hideButton && <Button title='Go back' kind='goBack' handleClick={() => { navigate(-1); }} />}
    </NoContentWrapper>
  );
};

NoContentWidget.defaultProps = {
  title: "Hmm, that's a blow. We can't find any long-form content for this person. Sorry!",
  hideButton: false
};

export default NoContentWidget;