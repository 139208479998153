import { useState, useEffect } from 'react';

const useAutoSave = (isEditing: boolean, saveFunction: () => void, intervalTime = 2000) => {
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    const saveChanges = () => {
      setHasChanges(false);
      saveFunction();
    };

    if (isEditing) {
      intervalId = setInterval(() => {
        if (hasChanges) {
          saveChanges();
        }
      }, intervalTime);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isEditing, hasChanges, saveFunction, intervalTime]);

  return { hasChanges, setHasChanges };
};

export default useAutoSave;
