import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Error } from '../Forms/InputError/styled';
import Button from '../Button/Button';
import { InputWrapperBtn, FormikForm, FormikInput } from './styles';


const FormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

interface FormInvitationModel {
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e:any) => void;
};

const FormInvitation: React.FC<FormInvitationModel> = ({ handleSubmit }) => (
  <Formik
    initialValues={{ email: '' }}
    validationSchema={FormSchema}
    onSubmit={async (values, { setSubmitting }) => {
      await handleSubmit(values?.email);
      await setSubmitting(false);
    }}
  >
    {({
      values,
      errors,
      touched,
      isSubmitting,
    }) => (
      <FormikForm>
        <InputWrapperBtn>
          <FormikInput type="email" name="email" placeholder="contact@email.com" value={values.email} />
          {errors.email && touched.email ? (
            <Error css={{ position: 'absolute', bottom: '-1.2rem', left: '$spaceSmall' }}>
              {errors.email}
            </Error>) : null}
          <Button
            title='Send invite'
            type="submit"
            css={{
              backgroundColor: '$body',
              height: '56px',
              width: '135px',
              fontSize: '$bodyXSmall',
              borderRadius: '5px',
              marginRight: '10px'
            }}
            loading={isSubmitting}
            renderProps={{ disabled: isSubmitting }}
          />
        </InputWrapperBtn>
      </FormikForm>
    )}
  </Formik>
);

export default FormInvitation;
