import { styled } from '../../config/theme';

export const BoxContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  variants: {
    row: {
      true: {
        flexDirection: 'row',
      },
    },
    modals: {
      topics: {
        textAlign: 'initial',
        height: '60vh',
        overflowY: 'scroll',
        scrollbarWidth: 'auto',
        scrollbarColor: '$line',
        padding: 10,
        '&::-webkit-scrollbar': {
          width: 3,
          height: 7,
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '5rem',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '$line',
          borderRadius: '5rem',
          border: '5px line solid',
        },
      },
      follow: {
        width: '100%',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'initial',
        position: 'relative',
        gap: '$spaceSmall',
        flex: 1,
        paddingRight: 10,
        '&:last-child': {
          marginBottom: 0,
        },
        textAlign: 'initial',
        overflowY: 'scroll',
        scrollbarWidth: 'auto',
        scrollbarColor: '$line',
        paddingY: '$spaceXSmall',
        '&::-webkit-scrollbar': {
          width: 3,
          height: 7,
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '5rem',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '$line',
          borderRadius: '5rem',
          border: '5px line solid',
        },
      },
    },
  },
});

export const ExpertiseContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '2.125rem 1rem',
  '@bp1': {
    padding: 'calc($spaceLarge * 2)',
  },
});

export const ExpertiseForm = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '$spaceSmall',
  alignItems: 'flex-start',
  background: '#FCFCFC',
  borderRadius: '8px',
  padding: 'calc($spaceXSmall2 * 2) $spaceXSmall2',
  '@bp1': {
    padding: 'calc($spaceLarge * 2) calc($spaceSmall * 2)',
  },
});

export const SubmitButton = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '2rem',
});

export const BoxFooter = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  gap: 5,
  marginTop: '$spaceXSmall',
});

export const TextTitle = styled('h2', {
  fontWeight: '$bold',
  display: 'flex',
  fontSize: '$bodyLarge',
  alignItems: 'flex-start',
  width: '100%',
  justifyContent: 'flex-start',
  textAlign: 'initial',
  '& img': {
    margin: 0,
  },
  '@bp2': {
    fontSize: '$displayXSmall',
  },
});

export const BoxText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '$spaceSmall',
});

export const Label = styled('label', {
  fontSize: '$bodySmall',
  color: '$label',
  padding: '0 6px',

  variants: {
    sub: {
      true: {
        color: 'inherit',
        fontSize: '$bodySmall',
        fontWeight: '$weight600',
      },
    },
  },
});

export const Span = styled('span', {
  color: '$body',
  fontWeight: '$bold',
  variants: {
    simple: {
      true: {
        color: '$label',
        fontSize: '$bodyXSmall',
        fontWeight: '$normal',
      },
    },
  },
});

export const Ul = styled('ul', {});

export const Li = styled('li', {
  fontSize: '$bodyXSmall',
  color: '$label',
  padding: '0 6px',
});

export const IconWrapper = styled('div', {
  background: 'transparent',
  borderRadius: '$radius1',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '$spaceSmall',
  '& img': {
    margin: 0,
  },
});

export const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

export const SubWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  '@bp2': {
    flexWrap: 'nowrap',
  },
});

export const Container = styled('div', {
  width: '100%',
  height: 'auto',
  padding: '$spaceLarge',
  textAlign: 'center',
  '@bp1': {
    overflow: 'hidden',
    padding: 'calc($spaceLarge * 2)',
  },

  variants: {
    noPadding: {
      true: {
        padding: '0 !important',
      },
    },
  },
});

export const Circle = styled('div', {
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  margin: 'auto',
  backgroundColor: '$placeHold',
  marginBottom: '$spaceLarge',

  variants: {
    kind: {
      big: {
        '@bp2': {
          width: '280px',
          height: '280px',
        },
      },
      small: {
        '@bp2': {
          width: '60px',
          height: '60px',
        },
      },
    },
  },
});

export const DivSelect = styled('div', {
  width: '100%',
  height: 'auto',
  padding: '1.5rem 1rem',
  borderRadius: '$radius075',
  textAlign: 'center',
  border: '1px solid $line',
  cursor: 'pointer',
  transition: 'all .3s ease-in-out',
  '&:hover': {
    boxShadow: '$shadowBase',
  },
  '& .label-sub': {
    cursor: 'pointer',
    wordBreak: ' break-word',
  },
  variants: {
    active: {
      true: {
        borderColor: '$body',
        '& .label-sub': {
          color: '$body !important',
        },
      },
    },
    rect: {
      true: {
        width: '30%',
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '88px',
        overflowY: 'scroll',
        padding: '0.5rem 1rem',
      },
    },
  },
});

export const BoxResults = styled('div', {
  width: '100%',
  height: '50vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'initial',
  ' .infinite-scroll-component__outerdiv': {
    width: '100%',
  },
});

export const Box = styled('div', {
  width: '100%',
  height: '50vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'initial',
  position: 'relative',
  gap: '$spaceSmall',
  flex: 1,
  '&:last-child': {
    marginBottom: 0,
  },
});

export const WrapperButton = styled('div', {
  gridArea: 'button',
});

export const InputWrapper = styled('div', {
  background: '$input',
  width: '100%',
  minHeight: '2.5rem',
  display: 'flex',
  borderRadius: '$radius05',
  alignItems: 'center',
  position: 'relative',
  marginTop: '$spaceSmall',
  marginBottom: '$spaceSmall',
});

export const Input = styled('input', {
  background: 'transparent',
  width: '100%',
  border: 'none',
  outline: 'none',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  padding: '$spaceSmall',
  '-webkit-appearance': 'none !important',
  zIndex: 1,
});

export const EmptyBox = styled('div', {
  flex: '1 1 auto',
  width: '30%',
});

export const RightButtons = styled('div', {
  display: 'inline-flex',
  justifyContent: 'flex-end',
  width: '100%',
});
