import React from 'react';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TextDescription, Typography, CenteredText } from '../../styled';

export const OtherQuestionOpinion = () => (
  <ExpertContainer space="section" bg="monochromeBlack">
    <CenteredText>
      <Typography
        type="displaySmall"
        color="secondaryP3"
        weight="700"
        spacing="opinionTitleSection"
      >
        Any other questions?
      </Typography>
      <TextDescription color="monochromeWhite" align="center" darkLink spacing="noSpace">
        Please email <br />
        <a href="mailto:customerteam@gathersight.com">customerteam@gathersight.com</a>
      </TextDescription>
    </CenteredText>
  </ExpertContainer>
);
