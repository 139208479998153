import React from 'react';
import SettingTabs from './components/SettingsTabs/SettingsTabs';
import PageContainer from '../../shared/components/Container/PageContainer';

const Settings: React.FunctionComponent = () => (
  <PageContainer layout="settings">
         <SettingTabs />
  </PageContainer>
);

export default Settings;