import React from 'react';
import { VideoActionsMobileContainer } from '../styles';
import SoundIndicator from './SoundIndicator';
import FullScreen from './FullScreen';
import Configuration from '../Configuration/Configuration.';

export const VideoActionsMobile = () => (
  <VideoActionsMobileContainer>
    <SoundIndicator isBlock />
    <Configuration isMobile />
    <FullScreen isBlock />
  </VideoActionsMobileContainer>
);
