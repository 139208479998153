import React from "react";
import { TextCaptionContainer, TextCaptionInner } from "./styles";

interface TextCaptionProps {
  text: string;
}

const TextCaption = (props: TextCaptionProps) => {
  const { text } = props;
  return(
    <TextCaptionContainer>
      <TextCaptionInner>
        {text}
      </TextCaptionInner>
    </TextCaptionContainer>
  );
};

export default TextCaption;