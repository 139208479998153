import React, { FC } from 'react';
import WidgetLoad from '../ShimmerComponent/WidgetLoad';
import Accordion from "../Accordion/Accordion";
import useWindowSize from '../../hooks/useWindowSize';
import { Widget, WidgetTitle, WidgetText, Wrapper } from './styles';

interface WidgetTwoColumnsModel {
  data?: any[];
  title: string;
  loading: React.SetStateAction<boolean>;
  isAccordion?: boolean;
}

const WidgetTwoColumns: FC<WidgetTwoColumnsModel> = ({ data, title, loading, isAccordion }) => {
  const { width } = useWindowSize();

  if (loading) {
    return (<WidgetLoad />);
  }

  if (isAccordion && width < 720) {
    return (
      <Accordion
        header={(
          <WidgetTitle noMargin>
            {title}
          </WidgetTitle>
        )}
        type='single'
      >
        <div>
          {data &&
            data.map(item => (
              <Wrapper key={item.name} >
                <WidgetText type='two-columns'>{item.name}</WidgetText>
                <WidgetText css={{ fontWeight: '$weight600' }} >{item.level}</WidgetText>
              </Wrapper>
            ))
          }
        </div>
      </Accordion>
    );
  }

  return (
    <Widget>
      <WidgetTitle>
        {title}
      </WidgetTitle>
      {data &&
        data.map(item => (
          <Wrapper key={item.name} >
            <WidgetText type='two-columns'>{item.name}</WidgetText>
            <WidgetText css={{ fontWeight: '$weight600' }} >{item.level}</WidgetText>
          </Wrapper>
        ))
      }
    </Widget>
  );
};

WidgetTwoColumns.defaultProps = {
  data: undefined,
  isAccordion: false,
};

export default WidgetTwoColumns;
