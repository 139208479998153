/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import Authors from './Authors/Authors';
import Contents from './Contents/Contents';
import { Widget } from '../../shared/components/Widget/styles';
import Playlist from './Playlist/Playlist';
import WidgetResponsive from '../../shared/components/Widget/WidgetResponsive';
import PageContainer from '../../shared/components/Container/PageContainer';
import LinksFooter from '../../shared/components/Footer/LinksFooter';
import Sidebar from '../../shared/components/Sidebar/Sidebar';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
// import { LayoutColPeople } from '../Profile/styled';
import AppContext from '../../shared/context/app/AppContext';
// import useIsLoggedIn from '../../shared/hooks/useIsLoggedIn';
// import NewOnGadget from './NewOnGadget/NewOnGadget';
import useWindowSize from '../../shared/hooks/useWindowSize';
// import Topics from './Topics/Topics';
import { validateAlternativeKey } from '../../shared/utils/helper';
import HeaderAlternative from './Alternative/HeaderAlternative';
import WidgetsBase from './WidgetsBase/WidgetsBase';
import OrderBy from './OrderBy/OrderBy';

const Home = () => {
  const { state } = useContext(AppContext);
  const { width } = useWindowSize();
  // const isLogged = useIsLoggedIn();
  const typeResource = state?.filterContent?.type;
  const keyAlternative = typeResource || '';
  const typePeople = typeResource === 'author';
  const validationSearch =
    width < 720 || width > 980 || !typePeople || (typePeople && state?.resources?.length > 1);
  const isResponsive = width < 980;

  return (
    <PageContainer layout="home">
      <LayoutContainer
        type="breadcrumbs-feed"
        css={{ display: 'flex', '@bp3': { display: 'none' } }}
      >
        <LayoutCol
          col="3"
          css={{ flexDirection: 'column', justifyContent: 'flex-start', gap: '$spaceLarge' }}
        >
          <div className="hideDiv" />
        </LayoutCol>
        <LayoutCol col="9" css={{ justifyContent: 'flex-start' }}>
          <OrderBy/>
        </LayoutCol>
      </LayoutContainer>
      <LayoutContainer
        type="home"
        direction={{
          '@bp2': validationSearch ? 'normal' : 'row',
        }}
      >
        <LayoutCol
          col="3"
          type="sidebar"
          css={{ flexDirection: 'column', justifyContent: 'flex-start', gap: '$spaceLarge' }}
        >
          <Sidebar>
            {isResponsive &&
              typeResource && validateAlternativeKey(keyAlternative, state.alternative)
                && <HeaderAlternative />}
            {isResponsive && (
              <WidgetResponsive
                breakpoint={980}
                kindResource={typeResource}
                limit={5}
                css={{ minHeight: 0, gridTemplateColumns: '1fr',
                  marginBottom: typePeople ? '$spaceLarge' : '0' }}
              />
            )}
            <WidgetsBase
              kind={isResponsive ? "mobile" : "desktop"}
              typePeople={typePeople}
              showPlaylist={false}
            />
            {width > 0 && !isResponsive && <Playlist kind='feed-desktop' />}
            <Widget kind="feed-link">
              <LinksFooter type="light" />
            </Widget>
          </Sidebar>
        </LayoutCol>
        <LayoutCol
          col="9"
          css={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingBottom: '2.5rem',
          }}
        >
          <LayoutContainer
            type="breadcrumbs-feed"
            css={{ display: 'none', '@bp3': { display: 'flex' } }}
          >
            <OrderBy/>
          </LayoutContainer>
          {/* When user reloads, in the first render widht = 0, this causes an aditional request
              and weird behaviors */}
          {typePeople ? ( width > 0 &&
            <Authors isFeedTablet={Boolean(isResponsive)} limitFeed={13} />
          ) : ( width > 0 &&
            <Contents isFeedTablet={Boolean(isResponsive)} limitFeed={14} />
          )}
        </LayoutCol>
      </LayoutContainer>
    </PageContainer>
  );
};

export default Home;
