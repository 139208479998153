import { styled } from '../../../../config/theme';

export const ItemContainer = styled('div', {
  display: 'flex',
  padding: '$spaceXXSmall',
  gap: '$spaceSmall',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    background: '$secondaryP3',
    borderRadius: '$radius05',
  },
  variants: {
    credit: {
      true: {
        width: '100%',
        background: '$secondaryP3',
        borderRadius: '$radius05',
        justifyContent: 'center',
      },
    },
    selected: {
      true: {
        background: '$secondaryP3',
        borderRadius: '$radius05',
      },
    },
    isTablet: {
      true: {
        justifyContent: 'center',
      },
    },
  },
});

export const ItemIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '$1-12',
  height: 'auto',
  maxHeight: '20px',
});

export const ListItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$spaceXXSmall',
});
