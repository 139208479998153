import React from 'react';
import PageContainer from '../../shared/components/Container/PageContainer';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
import { Title, SubTitle, Text, UnderText } from './styled';

const ContentCommunityGuidelines = () => (
  <PageContainer layout='statics'>
    <LayoutContainer type='statics' css={{ flexWrap: 'wrap' }}>
      <LayoutCol col='12' css={{ flexDirection: 'column', alignItems: 'baseline' }}>
        <Title css={{ marginBottom: 0 }} >Content and Community Guidelines</Title>
        <UnderText>Effective date: June 14th 2022</UnderText>

        <Text>
          Gathersight is a place where people’s ideas and opinions can be heard and more fully
          understood. Our hope is that by providing an easier way for people to consume longer form
          written and spoken content we will, in our own small way, enhance the quality of online
          debate and discourse.
        </Text>
        <Text>
          To this end we welcome (celebrate!) content from as diverse a set of contributors as
          possible, and value divergent opinions, even when controversial.
        </Text>
        <Text>
          However, even more important than diversity of opinion is the safety of our users.
          Everyone on Gathersight should have an expectation of safety, privacy and that their
          rights are respected and upheld.
        </Text>
        <Text>
          If we determine that any content is in breach of these guidelines, we may remove it,
          hide it from public view, or impose other restrictions. This is an evolving document and
          we will update these Content and Community Guidelines at our discretion and
          without notice.
        </Text>
        <Text>
          Here are some rules we expect you to follow:
        </Text>

        <SubTitle>Safety</SubTitle>
        <Text>
          <strong>Do not post harassing content:</strong>
          {` We don’t allow bullying or harassment. This includes targeted personal attacks,
          intimidation, shaming, disparagement, and abusive language directed at other users.
          Do not reveal others' personal or sensitive information (aka "doxing"), or incite others
          to do the same.`}
        </Text>
        <Text>
          <strong>Do not threaten, incite, or promote violence:</strong> We don’t allow threatening
          or inciting violence of any kind. We don’t allow individuals that engage in or promote
          violence, property damage, or organized criminal activity. You may not use Gathersight to
          express support for such individuals or groups or to otherwise glorify violence.
        </Text>
        <Text>
          <strong>Do not share content promoting dangerous organizations or individuals:</strong>
          {` We don't allow any terrorist organizations or violent extremist groups on our platform.
          And we don't allow any individuals who affiliate with such organizations or groups to
          have a Gathersight profile. Content that depicts terrorist activity, that is intended
          to recruit for terrorist organizations, or that threatens, promotes, or supports
          terrorism in any manner is not tolerated.`}
        </Text>

        <SubTitle>Trust</SubTitle>
        <Text>
          <strong>Do not share false or misleading content:</strong> Do not share content in a way
          that you know is, or think may be, misleading or inaccurate, including misinformation or
          disinformation. We may prevent you from linking to sites that are known to produce or
          contain misinformation.
        </Text>
      </LayoutCol>
    </LayoutContainer>
  </PageContainer>
);

export default ContentCommunityGuidelines;
