import React, { useContext, useEffect, useState } from "react";
import { ContentsService } from "../../../services/ContentsService/ContentsService";
import CardResources from "../../../shared/components/CardResources/CardResources";
import AppContext from "../../../shared/context/app/AppContext";
import { Resources } from "../../../shared/interfaces/interfaces";

// Called when user clicks on add to playlist without authenticated.
// After the authentication, we insert the content clicked first on the
// feed with the add to playlist modal opened
const ContentToPlaylist = () => {
  const { state, dispatch } = useContext(AppContext);
  const { contentToPlaylist, authenticationToken } = state;
  const [content, setContent] = useState<Resources>();

  useEffect(() => {
    const addContentToFeed = async () => {
      if(contentToPlaylist && authenticationToken){
        const params = { id: contentToPlaylist, authenticationToken };
        const response = await ContentsService.getContent(params);
        if(response) { setContent(response); }
      }
    };
    addContentToFeed();
  }, [contentToPlaylist, authenticationToken, dispatch]);
  return(content ? <CardResources key={`content-${content.id}`}  {...content} modalOpened /> : null);
};

export default ContentToPlaylist;