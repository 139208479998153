import React from 'react';

export const MortarboardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M28.6538 13.5855L27.3205 12.8521L15.3205 6.18547H15.1738C15.0921 6.15091 15.0073 6.12412 14.9205 6.10547H14.6672H14.4272C14.3361 6.12413 14.2468 6.15091 14.1605 6.18547H14.0138L2.01382 12.8521C1.80862 12.9684 1.63795 13.1371 1.5192 13.3409C1.40045 13.5446 1.33789 13.7763 1.33789 14.0121C1.33789 14.248 1.40045 14.4796 1.5192 14.6834C1.63795 14.8872 1.80862 15.0558 2.01382 15.1721L5.33382 17.0121V23.3321C5.33382 24.393 5.75525 25.4104 6.50539 26.1606C7.25554 26.9107 8.27296 27.3321 9.33382 27.3321H20.0005C21.0614 27.3321 22.0788 26.9107 22.8289 26.1606C23.5791 25.4104 24.0005 24.393 24.0005 23.3321V17.0121L26.6672 15.5188V19.3321C26.6672 19.6858 26.8076 20.0249 27.0577 20.2749C27.3077 20.525 27.6469 20.6655 28.0005 20.6655C28.3541 20.6655 28.6933 20.525 28.9433 20.2749C29.1933 20.0249 29.3338 19.6858 29.3338 19.3321V14.7455C29.3334 14.5092 29.2702 14.2773 29.1508 14.0735C29.0313 13.8697 28.8598 13.7012 28.6538 13.5855ZM21.3338 23.3321C21.3338 23.6858 21.1933 24.0249 20.9433 24.2749C20.6932 24.525 20.3541 24.6655 20.0005 24.6655H9.33382C8.9802 24.6655 8.64106 24.525 8.39101 24.2749C8.14096 24.0249 8.00049 23.6858 8.00049 23.3321V18.4921L14.0138 21.8255L14.2138 21.9055H14.3338C14.4445 21.9194 14.5565 21.9194 14.6672 21.9055C14.7778 21.9194 14.8898 21.9194 15.0005 21.9055H15.1205C15.1913 21.8905 15.2589 21.8635 15.3205 21.8255L21.3338 18.4921V23.3321ZM14.6672 19.1455L5.41382 13.9988L14.6672 8.85214L23.9205 13.9988L14.6672 19.1455Z"
      fill="white"
    />
  </svg>
);
