import React, { useState, useContext } from 'react';
import Icon from '../../../../shared/components/Icon/Icon';
import bookmark from '../../../../assets/images/icons/bookmark-gray.svg';
import bookmarkLight from '../../../../assets/images/icons/bookmark.svg';
import ModalKB from '../../../../shared/components/ModalKB/ModalKB';
import Button from '../../../../shared/components/Button/Button';
import AppContext from '../../../../shared/context/app/AppContext';
import { TextIcon } from '../../styles';
import { WrapperText, WrapNoData, TextTitle } from '../../../Profile/styled';

const CreatePlaylistWidget = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { dispatch } = useContext(AppContext);

  return (
    <WrapNoData css={{ flex: 1, height: '100%', '+.load-data-section': { width: '100%' } }}>
      <WrapperText>
        <div style={{ minHeight: '8rem', height: 'auto' }}>
          <TextTitle>Create Knowledge playlist.</TextTitle>
          <TextIcon>
            Simply click the
            <Icon
              img={bookmark}
              alt="Bookmark"
              width="20px"
              height="20px"
              css={{ display: 'inline-flex', verticalAlign: 'middle' }}
            />
            icon on any Gathersight resource or people card to get started.
          </TextIcon>
        </div>
        <Button
          iconCustom={bookmarkLight}
          iconWidth="15px"
          iconHeight="15px"
          title="Add Knowledge Playlist"
          kind="add"
          handleClick={() => setOpen(true)}
          css={{ margin: '0.3rem auto 0' }}
        />
      </WrapperText>
      <ModalKB
        isOpen={open}
        actionContinue={() => dispatch({ type: 'SET_RELOAD', payload: true })}
        actionClose={() => setOpen(false)}
      />
    </WrapNoData>
  );
};

export default CreatePlaylistWidget;
