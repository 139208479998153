import React, { FC, useState, useEffect } from 'react';
import {
  TooltipContainer,
  TooltipContent
} from './styled';

interface TooltipModel {
  content: string | JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[];
  direction?: string;
  type?: string;
  isFlex?: boolean;
  open?: boolean;
}

type TooltipProps = TooltipModel & React.ComponentProps<typeof TooltipContent> & {};

const Tooltip: FC<TooltipProps> = ({
  content,
  children,
  type,
  direction = 'bottom',
  open = false,
  isFlex,
}) => {
  const [show, setShow] = useState<boolean>(open || false);

  const showToolTip = () => {
    setShow(true);
  };

  const hideToolTip = () => {
    if (!open) setShow(false);
  };

  useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <TooltipContainer isFlex={isFlex}
      onMouseEnter={showToolTip}
      onMouseLeave={hideToolTip}
    >
      {children}
      <TooltipContent show={show} direction={direction} type={type}>
        {content}
      </TooltipContent>
    </TooltipContainer>
  );
};

Tooltip.defaultProps = {
  children: undefined,
  direction: 'bottom',
  type: 'type-resource',
  open: false,
  isFlex: false,
};

export default Tooltip;