import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled, fadeIn, fadeInUp } from '../../../config/theme';

export const MaxWidthContainer = styled('div', {
  maxWidth: 1800,
  alignItems: 'center',
  animation: `${fadeIn} 340ms ease-in-out`,
  '@bp4': {
    display: 'flex',
  },
});

export const ExpertOpinionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  width: '100%',
  height: 'auto',
  background: '$monochromeWhite',
  paddingY: '$spaceMedium',
  paddingX: '$spaceXSmall',
  '@bp3': {
    flexDirection: 'row',
  },
});

export const LetterOpinionContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  paddingX: '$spaceSmall',
  marginBottom: '$spaceLarge3',
  gap: '$spaceSmall',
  '@bp4': {
    textAlign: 'left',
    alignItems: 'flex-start',
    width: '80%',
    overflowY: 'auto',
    maxWidth: '40vw',
  },
  h1: {
    animation: `${fadeInUp} 340ms ease-in-out`,
  },
  p: {
    maxWidth: 600
  }
});

export const Section = styled('div', {
  paddingY: '$spaceMedium',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  background: '$offWhite',
  paddingX: '$spaceMBig',
  animation: `${fadeIn} 340ms ease-in-out`,
  width: '100%',
  '@bp4': {
    flexDirection: 'row',
    gap: '$spaceSmall',
  },
  variants: {
    bg: {
      monochromeBlack25: {
        background: '$monochromeBlack25',
      },
    },
    flexDirection: {
      column: {
        flexDirection: 'column',
      },
    },
  },
});

export const MiddleImg = styled(LazyLoadImage, {
  transition: 'all 0.3s ease-in-out',
  maxWidth: '19rem',
  zIndex: 5,
  marginBottom: '8px',
  borderRadius: 5,
  '@bp5': {
    maxWidth: '27rem',
  },
  variants: {
    type: {
      options: {
        maxWidth: '320px',
        '@bp2': {
          maxWidth: '674px',
        },
      },
    },
  },
});

export const ElipseIllustrator = styled('div', {
  width: '40.875rem',
  height: '40.875rem',
  position: 'absolute',
  borderRadius: '40.875rem',
  zIndex: 1,
  background:
    'radial-gradient(50% 50% at 50% 50%, #D7F065 0%, rgba(215, 240, 101, 0.83) 51.05%, rgba(215, 240, 101, 0.00) 100%)',
  '@bp4': {
    width: '46rem',
    height: '46rem',
    borderRadius: '46rem',
  },
});

export const IllustrationContainer = styled('div', {
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  maxWidth: '50rem',
  paddingX: '$spaceSmall',
  '@bp4': {
    flexGrow: 2,
  },
});

export const MentionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '15px',
  '@bp2': {
    flexDirection: 'row',
    gap: '3rem',
    alignItems: 'flex-start',
  },
});