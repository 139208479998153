import React from 'react';
import PageContainer from '../../shared/components/Container/PageContainer';
import { LayoutCol, LayoutContainer } from '../../shared/components/Layouts/styles';
import { Widget, DivWidgets } from '../../shared/components/Widget/styles';
// import WidgetNewProvider from '../../shared/components/Widget/WidgetNewProvider';
import Authors from './Authors/Authors';
import ModalOnboarding from './ModalOnboarding/ModalOnboarding';

const OnBoarding = () => (
  <PageContainer layout="onboarding">
    <LayoutContainer
      css={{ '@bp2': { flexDirection: 'column', gap: '28px' }, '@bp3': { flexDirection: 'row' } }}
    >
      <LayoutCol
        col="3"
        css={{ flexDirection: 'column', justifyContent: 'flex-start', gap: '$spaceLarge' }}
      >
        <DivWidgets kind="onboarding">
          <Widget>
            <Authors />
          </Widget>
          {/* <WidgetNewProvider disable /> */}
        </DivWidgets>
      </LayoutCol>
      <LayoutCol col="9">
        <ModalOnboarding />
      </LayoutCol>
    </LayoutContainer>
  </PageContainer>
);

export default React.memo(OnBoarding);
