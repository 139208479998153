import { styled } from '../../config/theme';

export const CenterWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Title = styled('h1', {
  fontSize: '$bodyMedium',
  fontWeight: '$weight700',
  marginBottom: '$spaceSmall',
  textAlign: 'flex-start',
  '@bp2': {
    fontSize: '$displayXSmall',
  },
});

export const SubTitle = styled('h2', {
  fontSize: '$bodyMedium',
  fontWeight: '$weight700',
  marginBottom: '$spaceSmall',
  textAlign: 'flex-start',
});

export const Text = styled('p', {
  fontSize: '$bodyXSmall',
  marginBottom: '$spaceSmall',
  textAlign: 'flex-start',
  lineHeight: '$lineHeight1',
  ul: {
    marginLeft: '$spaceMedium1',
  },
  '@bp2': {
    fontSize: '$bodySmall',
    lineHeight: '$lineHeight3',
  },
});

export const UnderText = styled('p', {
  fontSize: '$bodyXSmall',
  marginBottom: '$spaceSmall',
  textAlign: 'center',
});

export const ButtonLink = styled('a', {
  textDecoration: 'none',
  width: '100%',
  '@bp2': {
    width: 'auto',
  },
  button: {
    maxWidth: 'inherit',
    width: '100%',
    '@bp2': {
      width: '11.5rem',
    },
  },
});
