import React from 'react';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TypographyH2 } from '../../styled';
import { Expandable } from '../../components/Expandable/Expandable';

const faqData = [
  {
    title: 'How much does an Expert Opinion cost? ',
    content:
      'Costs differ depending on the complexity of your question set, but usually between $150 - 350 each.',
  },
  {
    title: 'How do I pay for Expert Opinions?',
    content: (
      <>
        To request Expert Opinions you will need available credits on Gathersight. These can be
        bought via the payment page in{' '}
        <a href="https://www.gathersight.com/authentication#sign-in">your account.</a>{' '}
      </>
    ),
  },
  {
    title: 'Is there a minimum or maximum number of Expert Opinions I can request?',
    content: 'No, you can request a single Expert Opinion or dozens at a time.',
  },
  {
    title: 'How do you ensure compliance and confidentiality?',
    content: (
      <>
        Before an Expert Opinion can be recorded, the expert is required to agree to our {' '}
        <a href="https://www.gathersight.com/experts-terms">compliance and confidentiality policy.</a>
      </>
    ),
  },
  {
    title: 'What kind of organizations use Expert Opinions?',
    content:
      'A wide variety of clients use Expert Opinions including academic institutes, research organizations, biotech and pharmaceutical companies, consultancies, investment firms, medical device manufacturers and non-profit agencies.',
  },
  {
    title: 'Where do your experts come from?',
    content: (
      <>
        Please take a look at our <a href="https://www.gathersight.com/">knowledge network</a> to
        see the breadth and depth of biotechnology expertise available.
      </>
    ),
  },
];

export const FAQOpinions = () => (
  <ExpertContainer space="section" bg="monochromeBlack25">
    <div>
      <TypographyH2 type="displaySmall" align="center" weight="700" spacing="opinionTitleSection">
        FAQ
      </TypographyH2>
      {faqData.map((item) => (
        <Expandable key={item.title} title={item.title}>
          {item.content}
        </Expandable>
      ))}
    </div>
  </ExpertContainer>
);
