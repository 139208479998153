/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import CardResources from './CardResources';
import Delayed from '../Delayed/Delayed';
import { Resources, TagModel } from '../../interfaces/interfaces';
import { WrapperCardList } from './styles';
import ContentToPlaylist from '../../../screens/Home/ContentToPlaylist/ContentToPlaylist';
import WidgetsBase from '../../../screens/Home/WidgetsBase/WidgetsBase';

interface Props {
  data: Resources[];
  hideFollow?: boolean;
  hideOptions?: boolean;
  playlistEmpty?: boolean;
  limitFeed?: number;
  isLazy?: boolean;
  showPlaylist?: boolean;
  hideSuggestions?: boolean;
  updatePlaylist?: boolean;
  userDataTag?: TagModel[];
  sameUser?: Boolean;
  userId?: number | string;
}

const CardResourcesList: React.FC<Props> = ({
  data,
  hideFollow,
  hideOptions,
  playlistEmpty,
  limitFeed,
  isLazy,
  showPlaylist,
  hideSuggestions,
  updatePlaylist,
  userDataTag,
  sameUser,
  userId,
}) => (
  <WrapperCardList className="resources-list">
    <ContentToPlaylist />
    {data &&
      data?.map((item: Resources, index: number) => {
        if (
          (limitFeed && index === limitFeed) ||
          (limitFeed && data.length <= limitFeed && data.length - 1 === index)
        ) {
          return (
            <Delayed
              key={`${index}-${item.id}`}
              waitBeforeShow={100 + (index + 10)}
              disable={!isLazy || index === 0}
            >
              <CardResources
                className={`${index}`}
                hideFollow={hideFollow}
                hideOptions={hideOptions}
                playlistEmpty={playlistEmpty}
                updatePlaylist={updatePlaylist}
                isLazy={isLazy}
                {...item}
              />
              <WidgetsBase
                kind="mobile"
                showPlaylist={showPlaylist}
                hideSuggestions={hideSuggestions}
                css={{ order: 1, marginBottom: '$spaceLarge', '@bp3': { marginBottom: '0px' } }}
                userDataTag={userDataTag}
                sameUser={sameUser}
                userId={userId}
              />
            </Delayed>
          );
        }

        if (item) {
          return (
            <Delayed
              key={`${index}-${item.id}`}
              waitBeforeShow={100 + (index + 10)}
              disable={!isLazy || index === 0}
            >
              <CardResources
                className={`${
                  index === 0 || (limitFeed && index < limitFeed) ? `resource-child-${index}` : ''
                }`}
                hideFollow={hideFollow}
                hideOptions={hideOptions}
                playlistEmpty={playlistEmpty}
                updatePlaylist={updatePlaylist}
                isLazy={isLazy}
                {...item}
              />
            </Delayed>
          );
        }

        return <div />;
      })}
  </WrapperCardList>
);

CardResourcesList.defaultProps = {
  showPlaylist: false,
  hideFollow: true,
  hideOptions: true,
  playlistEmpty: false,
  limitFeed: undefined,
  isLazy: undefined,
  updatePlaylist: undefined,
  hideSuggestions: undefined,
  userDataTag: [],
  sameUser: false,
  userId: undefined,
};

export default CardResourcesList;
