import { styled } from '../../../../config/theme';

export const ScrollableTable = styled('div', {
  overflowX: 'auto',
  whiteSpace: 'nowrap',
});

export const TableContainer = styled('table', {
  width: '100%',
});

export const TableHead = styled('thead', {
  th: {
    minWidth: '150px',
    color: '#6E7191',
    fontFamily: 'Poppins',
    fontSize: '$bodyXSmall',
    fontWeight: '500',
    lineHeight: '$lineHeight12',
    textTransform: 'uppercase',
    textAlign: 'left',
    padding: '$spaceLarge $spaceSmall',
    wordBreak: 'keep-all',
  },
  'td, th': {
    borderBottom: '1px solid $grayscaleLine',
  },

  variants: {
    isEmpty: {
      true: {
        'td, th': {
          borderBottom: 'none',
        },
      },
    },
  },
});

export const TableBody = styled('tbody', {
  tr: {
    borderBottom: '1px solid $grayscaleLine',
  },
  td: {
    maxWidth: 400,
    minWidth: 50,
    padding: '$spaceSmall',
    fontSize: 14,
    color: '$offBlack',
    whiteSpace: 'noWrap',
    width: 'fit-content',
  },
  'tr td': {
    borderBottom: '1px solid $grayscaleLine',
  },
  'tr td:first-of-type': {
    fontWeight: '$weight500',
    whiteSpace: 'normal',
  },
  'tr:last-child td, tr:last-child th': {
    borderBottom: 'none',
  },
 
});


export const NoContentContainer = styled('div', {
  borderRadius: '$radius05',
  background: '$monochromeBlack25',
  paddingY: '$spaceXLarge',
  paddingX: '$spaceLarge',
});
