import React from 'react';
// import _get from 'lodash/get';
import Icon from '../Icon/Icon';
// import AvatarMultiple from '../Avatar/AvatarMultiple'; // Hide V1
import { Knowledge } from '../../interfaces/interfaces';
// import { ProfileService } from '../../../services/ProfileService/ProfileService';
import { CardHeader, CardContent } from '../CardResources/styles';
import { Box, TextTitleKnowledge, StrongText, WrapperCardPlaylist, TitleLink } from './styles';
// import { defaultsAuthors } from './data';
import lock from '../../../assets/images/icons/lock-gray.svg';
// import { getUserName } from '../../utils/helper';

interface KnowledgeProps extends Knowledge {
  viewModal?: boolean;
}

const PlaylistItem: React.FC<KnowledgeProps> = ({ attributes, viewModal }) => {
  // const [userDefault, setUserDefault] = useState(defaultsAuthors) as Array<any>; // Hide V1
  // let authors = _get(attributes, 'who_access.data');
  //     authors = authors.length ? authors : [];

  const onClick = (event: any) => {
    event?.stopPropagation();
  };

  // useEffect(() => {
  //   (async () => {
  //     if (attributes && authors.length === 0) {
  //       const params = { id: attributes?.created_by_id };
  //       const userResponse = await ProfileService.getUser(params);

  //       if (userResponse) {
  //         const dataName = {
  //           username: userResponse?.attributes?.username,
  //           first_name: userResponse?.attributes?.first_name,
  //           last_name: userResponse?.attributes?.last_name
  //         };
  //         const finalName = getUserName(dataName);
  //         setUserDefault([
  //           { ...userResponse,
  //             attributes: { ...userResponse?.attributes, user_name: finalName } }
  //         ]);
  //       }
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [attributes?.created_by_id]);

  return (
    <WrapperCardPlaylist onClick={onClick} viewModal={viewModal}>
      <CardHeader className="CardHeader" css={{ marginBottom: 0 }}>
        <TextTitleKnowledge isSmall className="TextTitleKnowledge">
          {attributes?.public === false && (
            <Icon img={lock} width="14px" height="14px" alt="Title" />
          )}
          <TitleLink to={`/users/${attributes.created_by_slug}/playlists/${attributes.slug}`}>
            <StrongText>{attributes?.title || ''}</StrongText>
          </TitleLink>
        </TextTitleKnowledge>
      </CardHeader>
      <CardContent
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          minHeight: 0,
          marginBottom: 0,
        }}
      >
        {/* <AvatarMultiple
          data={authors.length > 0 ? authors : userDefault}
          type='little'
          limit={viewModal ? 5 : 3}
        /> */}
        <Box css={{ padding: '0px', maxWidth: '160px' }}>
          <span className="text-ellipsis"> By {attributes?.created_by || ''}</span>
        </Box>
      </CardContent>
    </WrapperCardPlaylist>
  );
};

PlaylistItem.defaultProps = {
  viewModal: false,
};

export default PlaylistItem;
