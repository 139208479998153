import React from "react";
import { StartVideoButton } from "../styles";
import Icon from "../../../shared/components/Icon/Icon";
import Recording from '../../../assets/images/icons/start-recording.svg';

interface VideoActionsProps {
  handleStartCaptureClick: () => void;
}

const StartRecording = (props: VideoActionsProps) => {
  const { handleStartCaptureClick } = props;

  return (
    <StartVideoButton onClick={handleStartCaptureClick}>
      <span>
        <Icon img={Recording} alt="Start recording" margin0 css={{marginRight: '12px'}}/>
        Start Recording
      </span>
  </StartVideoButton>
  );
};

export default StartRecording;