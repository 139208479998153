import React from 'react';
import { toast } from 'react-toastify';
import { ProfileService } from '../../../../services/ProfileService/ProfileService';
import { UserService } from '../../../../services/user.service';
import { KnowledgeService } from '../../../../services/knowledge.service';
import { ProjectsService } from '../../../../services/ProjectsService/ProjectsService';
import { Project, EditProject } from '../../../../shared/interfaces/projectInterfaces';
import { formatExpertData } from '../../../Expert/utils';
import { MemberService } from '../../../../services/OrganizationService/MemberService';
import { ProjectInvitationsService } from '../../../../services/ProjectInvitationsService/ProjectInvitationsService';

interface SetUser {
  type: string;
  payload: any;
}

interface Authentication {
  res: any;
  dispatch: React.Dispatch<SetUser>;
  navigate: any;
  type?: string;
  expert?: EditProject;
}

const FollowUser = async (res: any) => {
  const authenticationToken = res.data.attributes.token;
  const followUser = localStorage.getItem('followUser');

  if (followUser) {
    // check if user is already followed
    const userToFollow = await ProfileService.getUser({ authenticationToken, id: followUser });
    if (!userToFollow.attributes.relationship_id) {
      UserService.follow({ authenticationToken, id: followUser });
    }
    localStorage.removeItem('followUser');
  }
};

const AcceptPlaylistCollaboration = (res: any) => {
  const authenticationToken = res.data.attributes.token;
  const token = localStorage.getItem('playlistToken');
  const playlistId = localStorage.getItem('playlistId');

  if (token && playlistId && authenticationToken) {
    KnowledgeService.updateKBCollaborators({ token, playlistId, authenticationToken });
    localStorage.removeItem('playlistToken');
    localStorage.removeItem('playlistId');
  }
};

const AcceptInvitation = async (res: any, navigate: any) => {
  const authenticationToken = res.data.attributes.token;
  const token = localStorage.getItem('tokenInvitation');
  const organizationId = localStorage.getItem('organizationId');
  const projectId = localStorage.getItem('projectId');

  if (token && authenticationToken) {
    if (organizationId) {
      await MemberService.acceptMemberInvitation(authenticationToken, token, organizationId);
      navigate('/');
    } else if (projectId) {
      await ProjectInvitationsService.acceptInvitation(authenticationToken, token, projectId);
      navigate(`/experts/projects/${projectId}`);
      sessionStorage.setItem('redirectAfterOnboarding', `/experts/projects/${projectId}`);
    }
    localStorage.removeItem('tokenInvitation');
    localStorage.removeItem('organizationId');
    localStorage.removeItem('projectId');
  }
};

const CreateProject = async (res: any, navigate: any, expert: any) => {
  const data = formatExpertData(expert);
  const authenticationToken = res.data.attributes.token;
  if (res.data.attributes.user_type === 'expert') {
    toast.error('You must be an organization in order to create a project');
    navigate('/experts/projects');
  } else {
    const project = await ProjectsService.createProject(authenticationToken, data);
    if (project) {
      const url = `/organization/projects`;
      navigate(url);
    } else {
      toast.error('Error creating project. Please try again');
    }
  }
};

const RedirectAfterSignUp = async (res: any, navigate: any) => {
  const authenticationToken = res.data.attributes.token;
  const projects = await ProjectsService.getProjects(authenticationToken);
  const project = projects.data[0] as Project;
  let url = `/onboarding`;
  if (projects.data.length > 0 && project?.attributes?.slug) {
    url = `/organization/projects/${project.attributes.slug}`;
  }
  navigate(url);
};

// eslint-disable-next-line consistent-return
const ProcessAuthentication = async (props: Authentication) => {
  const { res, dispatch, navigate, type, expert } = props;
  const { error, data } = res;
  if (error) toast.error(error);
  else {
    await FollowUser(res);
    await AcceptPlaylistCollaboration(res);
    await dispatch({ type: 'SET_USER', payload: { ...data } });
    await dispatch({
      type: 'SET_FILTER',
      payload: {
        filter: undefined,
        category: 'feed',
        type: 'content',
        page: 1,
        orderBy: 'relevant',
      },
    });
    if (localStorage.getItem('organizationId') || localStorage.getItem('projectId')) {
      return AcceptInvitation(res, navigate);
    }
    if (
      expert &&
      res.data.attributes.status !== 'registered' &&
      expert.name !== '' &&
      expert.description !== ''
    ) {
      await CreateProject(res, navigate, expert);
      // eslint-disable-next-line consistent-return
      return;
    }
    if (type === 'sign-up' && res.data.attributes.status !== 'registered') {
      await toast.success(
        'Hey! Looks like you already have an account on Gathersight, we have logged you in',
      );
    } else if (data.attributes.status === 'registered') {
      RedirectAfterSignUp(res, navigate);
    } else if (data.attributes.user_type === 'expert') {
      await navigate('/experts/projects');
    } else {
      await navigate('/organization/projects');
    }
  }
};

export default ProcessAuthentication;
