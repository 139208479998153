/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { IconContainer, QuestionContainer, QuestionsContainer } from '../styled';
import { FlexContainer, Typography } from '../../styled';
import { PlusIcon } from '../icons/PlusIcon';
import { TrashIcon } from '../icons/TrashIcon';
import { ResizableTextArea } from '../ResizableTextArea';
import { CheckIcon } from '../icons/CheckIcon';
import ProjectsContext from '../../../../shared/context/projects/ProjectsContext';
import DoneEditProjectButton from '../DoneEditProjectButton';
import { useProjects } from '../../../../shared/hooks/useProjects';
import { EditProject, Project } from '../../../../shared/interfaces/projectInterfaces';
import useAutoSave from '../../../../shared/hooks/useAutoSave';
import { SavedLabel } from './styled';
import { SavedLabelIcon } from './SavedLabelIcon';
import Button from '../../../../shared/components/Button/Button';
import { CloseIconModal } from '../../../../shared/components/Icon/CloseIconModal';
import { LoadingQuestions } from './LoadingQuestions';

interface QuestionsProps {
  editable: boolean;
  handleEditToggle?: () => void;
  isShowSaved?: boolean;
  setIsShowSaved?: () => void;
  isDescriptionChanged?: boolean;
}

export const Questions = ({
  editable,
  handleEditToggle,
  isShowSaved,
  setIsShowSaved,
  isDescriptionChanged,
}: QuestionsProps) => {
  const [newQuestion, setNewQuestion] = useState('');
  const [showInput, setShowInput] = useState<boolean>(false);
  const {
    updateProject,
    recreateProjectQuestions,
    isChangingQuestion,
    getProjectStatus,
    getProject,
  } = useProjects();
  const { projectQuestions, setProjectQuestions, setProject, project } =
    useContext(ProjectsContext);
  const status = getProjectStatus(project as Project);

  useEffect(() => {
    if (!project) return;

    if (project?.attributes?.project_questions?.length) {
      const questions = project.attributes?.project_questions?.map((question) => ({
        id: question.id,
        text: question.attributes.text,
      }));
      setProjectQuestions(questions);
    } else {
      const timeoutId = setTimeout(() => {
        getProject(true);
      }, 1000);
      // eslint-disable-next-line consistent-return
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const handleSaveChanges = async () => {
    const projectChanges: EditProject = {
      project_questions_attributes: [...projectQuestions, { text: newQuestion }],
    };
    const response = await updateProject(projectChanges, false);
    if (response && setIsShowSaved) setIsShowSaved();
  };

  const { setHasChanges } = useAutoSave(editable, handleSaveChanges, 2000);

  const handleShowInput = () => {
    setShowInput(!showInput);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewQuestion(e.target.value);
  };

  const handleInputIconClick = () => {
    setNewQuestion('');
    setShowInput(true);
  };

  const handleCancelQuestion = () => {
    setShowInput(false);
  };

  const handleDeleteQuestion = (index: number, questionId?: string | number) => {
    const updatedQuestions = [...projectQuestions];
    updatedQuestions[index] = { id: questionId, _destroy: true, text: '' };
    setProjectQuestions(updatedQuestions);
  };

  const handleAddQuestion = async () => {
    if (newQuestion !== '') {
      setProjectQuestions([...projectQuestions, { text: newQuestion }]);
      setNewQuestion('');
      handleShowInput();
      const data = {
        project_questions_attributes: [...projectQuestions, { text: newQuestion }],
      };
      const updatedProject = await updateProject(data);
      setProject(updatedProject);
    }
  };

  const handleTextAreaChange =
    (index: number, questionId?: string | number) =>
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const updatedTextAreas = [...projectQuestions];
      updatedTextAreas[index] = { id: questionId, text: event.target.value };
      setProjectQuestions(updatedTextAreas);
      setHasChanges(true);
    };

  const handleGenerateNewQuestions = async () => {
    await recreateProjectQuestions();
  };

  return (
    <QuestionsContainer>
      <FlexContainer  justify="between" direction="onlyMobileColumn">
        <FlexContainer align="center" justify="between">
          <FlexContainer direction="column">
            <Typography
              size="displayXXSmall"
              weight="700"
              lineHeight="lineHeight3"
              letterSpacing="letterSpacing3"
            >
              Questions
            </Typography>
            <Typography
              size="bodyXSmall"
              weight="500"
              lineHeight="lineHeight1"
              letterSpacing="letterSpacing8"
              color="monochromeBlack300"
            >
              These are the questions the experts will answer in their Expert Opinion.
            </Typography>
          </FlexContainer>
        </FlexContainer>
        <div style={{marginTop: '0.5rem'}}>
          {isDescriptionChanged && !editable && status === 'draft' && (
            <Button
              title="Regenerate"
              size="small"
              width="auto"
              loading={isChangingQuestion}
              renderProps={{
                disabled: isChangingQuestion,
                onClick: handleGenerateNewQuestions,
              }}
            />
          )}
        </div>
      </FlexContainer>

      {projectQuestions.length > 0 ? (
        projectQuestions.map(
          (question, index) =>
            !question._destroy && (
              <QuestionContainer editable={editable} key={`question-${question.id}`}>
                {editable ? (
                  <ResizableTextArea
                    value={question.text}
                    onChange={handleTextAreaChange(index, question.id)}
                  />
                ) : (
                  <Typography
                    size="bodyXSmall"
                    lineHeight="lineHeight1"
                    letterSpacing="letterSpacing6"
                    color="monochromeBlack400"
                  >
                    {question.text}
                  </Typography>
                )}
                {editable && (
                  <IconContainer
                    kind="trashIcon"
                    onClick={() => handleDeleteQuestion(index, question.id)}
                  >
                    <TrashIcon fillColor="#4E4B66" />
                  </IconContainer>
                )}
              </QuestionContainer>
            ),
        )
      ) : (
        <LoadingQuestions />
      )}
      {showInput && (
        <QuestionContainer editable>
          <ResizableTextArea value={newQuestion} onChange={handleInputChange} />

          <CloseIconModal onClick={handleCancelQuestion} />
        </QuestionContainer>
      )}
      <FlexContainer
        align="center"
        justify={projectQuestions.length < 5 ? 'between' : 'end'}
        gap="0_75"
      >
        {projectQuestions.length < 5 && handleEditToggle && status === 'draft' && (
          <IconContainer onClick={showInput ? handleAddQuestion : handleInputIconClick} kind="add">
            {showInput ? <CheckIcon /> : <PlusIcon />}
          </IconContainer>
        )}
        {editable && (
          <FlexContainer align="center" gap="0_75">
            {isShowSaved && (
              <SavedLabel>
                <SavedLabelIcon />
              </SavedLabel>
            )}
            {handleEditToggle && <DoneEditProjectButton handleEditToggle={handleEditToggle} />}{' '}
          </FlexContainer>
        )}
      </FlexContainer>
    </QuestionsContainer>
  );
};

Questions.defaultProps = {
  handleEditToggle: undefined,
  isShowSaved: false,
  setIsShowSaved: undefined,
  isDescriptionChanged: false,
};
