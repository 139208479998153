/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import Button from '../../../../shared/components/Button/Button';
import Avatar from '../../../../shared/components/Avatar/Avatar';
import Icon from '../../../../shared/components/Icon/Icon';
import PeopleFollow from '../../../../shared/components/People/PeopleFollow';
import { User } from '../../../../shared/interfaces/interfaces';
import { getUserName } from '../../../../shared/utils/helper';
import editWhite from '../../../../assets/images/icons/edit-white.svg';
import verifyIcon from '../../../../assets/images/icons/verify.svg';
import {
  Box,
  BoxAvatar,
  UserInfo,
  UserName,
  UserData,
  ButtonLink,
  BoxShare,
} from '../style';
import UserLoad from '../../../../shared/components/ShimmerComponent/UserLoad';
import useIsLoggedIn from '../../../../shared/hooks/useIsLoggedIn';
import { OrcidLogoIcon } from '../OrcidLogoIcon';
import Tooltip from '../../../../shared/components/Tooltip/Tooltip';
// import OptionsProfile from '../../OptionsProfile/OptionsProfile';

interface UserHeaderPropsModel {
  data: User | null;
  isFollow?: boolean;
  loading?: boolean;
}

const UserHeader = (props: UserHeaderPropsModel) => {
  const { data, isFollow, loading } = props;
  const dataName = {
    username: data?.attributes?.username,
    first_name: data?.attributes?.first_name,
    last_name: data?.attributes?.last_name,
  };
  const finalName = getUserName(dataName);
  const userLogged = useIsLoggedIn();
  const userAvatar = data?.attributes?.avatar_url;
  const email = data?.attributes?.email;
  // const isKBP = window?.location?.pathname.split('/')[1] === 'knowledge-playlists-user';
  const isOrcidUser = data?.attributes?.kind === 'orcid';

  const ButtonEditProfile = useMemo(
    () => (
      <ButtonLink to="/settings">
        <Button
          title=""
          iconCustom={editWhite}
          kind="user-profile"
          css={{
            maxWidth: 'inherit',
            width: 'auto',
            height: '40px',
            padding: '0.5rem',
            '@bp2': {
              width: '164px',
              height: '56px',
              padding: '1rem',
            },
            '&::after': {
              content: 'Edit profile',
              '@bp2': {
                content: 'Edit my profile',
              },
            },
          }}
        />
      </ButtonLink>
    ),
    [],
  );

  if (loading) {
    return <UserLoad />;
  }

  return (
    <>
      <Box css={{ width: '100%' }}>
        <BoxAvatar>
          <Avatar img={userAvatar} type="big" name={finalName} />
          <Box kind="wrapper-user">
            <Box kind="follow-share">
              {isFollow && (
                <>
                  <PeopleFollow
                    css={{ display: 'flex', '@bp1': { display: 'none' } }}
                    isProfile
                    follow={data?.attributes?.relationship_id}
                    id={data?.id}
                    username={data?.attributes?.username}
                    size="big"
                  />
                  {/* <OptionsProfile userData={data} kind='options-mobile' /> */}
                </>
              )}
              <BoxShare kind="mobile">
                {userLogged && !isFollow && <Box kind="btn-profile">{ButtonEditProfile}</Box>}
              </BoxShare>
            </Box>
          </Box>
        </BoxAvatar>
        <UserInfo>
          <Box column>
            <Box row kind="name-share">
              <UserName css={{ display: 'inline-flex', img: { margin: '6px 4px' } }}>
                {finalName}
                {isOrcidUser ? (
                  <Tooltip content="Verified by ORCID" type="orcid-verified" direction="top">
                    <OrcidLogoIcon />
                  </Tooltip>
                ) : (
                  email && (
                    <sup>
                      <Icon img={verifyIcon} width="16" height="16" alt="Verified" />
                    </sup>
                  )
                )}
              </UserName>
            </Box>
            <UserData>{`${data?.attributes?.biography || ''}`}</UserData>
          </Box>
        </UserInfo>
      </Box>
      <Box>
        {isFollow && (
          <>
            {/* <OptionsProfile userData={data} kind='options' /> */}
            <PeopleFollow
              css={{ display: 'none', '@bp1': { display: 'flex' } }}
              isProfile
              follow={data?.attributes?.relationship_id}
              id={data?.id}
              username={data?.attributes?.username}
              size="big"
            />
          </>
        )}
        {userLogged && !isFollow && <Box kind="btn-profile-desktop">{ButtonEditProfile}</Box>}
      </Box>
    </>
  );
};

UserHeader.defaultProps = {
  isFollow: false,
  loading: false,
};

export default UserHeader;
