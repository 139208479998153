import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { routes, pathsOnboarding } from './routesData';
import useGetToken from '../shared/hooks/useGetToken';
import useIsLoggedIn from '../shared/hooks/useIsLoggedIn';
import getUserSetting from '../shared/hooks/getUserSetting';
import { IRoute } from '../shared/interfaces/interfaces';
import GlobalContainer from '../shared/components/Container/GlobalContainer';
import CustomToastNotifications from '../shared/components/ToastAlerts/ToastContainer';
import AuthExpirationVerify from '../shared/utils/authExpirationVerify';
import NotFound from '../screens/NotFound/NotFound';
import getUserType from '../shared/hooks/getUserType';
import { RouteGuard } from './RouteGard';

const SwitchRoutes = () => {
  const token = useGetToken();
  const isLogged = useIsLoggedIn();
  const level = getUserSetting();
  const userType = getUserType();
  const valueLevel = level !== 'done';
  let routesCurrent = routes.filter((r) =>
    isLogged ? r.privacy?.includes('private') : r.privacy?.includes('public'),
  );

  if (valueLevel && userType === 'expert') {
    routesCurrent = routesCurrent.filter((route) => {
      const simplePath = route?.path ? route.path.split('/:')[0] : '';
      return pathsOnboarding.includes(simplePath);
    });
  }

  routesCurrent = routesCurrent.filter((r: IRoute) =>
    RouteGuard(r?.roles ? r.roles : [], r?.excludes ? r.excludes : []),
  );
  const defaultRoute = () => {
    const route = valueLevel ? '/onboarding' : '/404';
    return userType === 'expert' ? route : '404';
  };

  const renderSubRoutes = (subRoutes: IRoute[]) =>
    subRoutes.map((route: IRoute) => (
      <Route key={route.path} path={route?.path} element={route?.component()}>
        {route?.subRoutes ? renderSubRoutes(route.subRoutes) : null}
      </Route>
    ));

  return (
    <BrowserRouter>
      <CustomToastNotifications />
      <GlobalContainer>
        <Routes>
          {routesCurrent.map((item: IRoute) => (
            <Route key={item.path} path={item?.path} element={item?.component(token)}>
              {item?.subRoute ? (
                <Route
                  key={item?.subRoute.path}
                  path={item?.subRoute?.path}
                  element={item?.subRoute?.component()}
                />
              ) : null}
              {item?.subRoutes ? renderSubRoutes(item?.subRoutes) : null}
            </Route>
          ))}
          <Route path="/index" element={<Navigate to="/" />} />
          <Route
            path="*"
            element={defaultRoute() === '/404' ? <NotFound /> : <Navigate to={defaultRoute()} />}
          />
        </Routes>
        <AuthExpirationVerify />
      </GlobalContainer>
    </BrowserRouter>
  );
};

export default SwitchRoutes;
