const dataTemp = [
  // {
  //   icon: 'star',
  //   rate: '9.4',
  // },
  // {
  //   icon: 'call',
  //   rate: '41',
  // },
  {
    icon: 'bookmark',
    rate: '24',
    text: 'Knowledge Playlists',
  },
  {
    icon: 'share',
    rate: '18',
    text: 'Shares',
  },
];

export default dataTemp;