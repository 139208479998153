import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../../../shared/interfaces/projectInterfaces';
import { ProjectItemContainer } from './styles';
import { EmptyLabel, FlexContainer, Typography, EmptyContainer } from '../../styled';
import { StatusIndicator } from './StatusIndicator';
import { Tag, TextTag } from '../../../../shared/components/Tags/styles';
import { ImageOrganizationContainer } from '../styled';
import { AvatarList } from './AvatarList';
import { useProjects } from '../../../../shared/hooks/useProjects';

interface ProjectItemProps {
  project: Project;
}

export const ProjectItem = ({ project }: ProjectItemProps) => {
  const navigate = useNavigate();
  const getAvatares = () => {
    if (project.attributes.project_experts.length <= 0) {
      return [];
    }
    return project.attributes.project_experts.map((expert) => ({
      name: expert.attributes.user_name,
      link: expert.attributes.user_avatar,
    }));
  };
  const { getProjectStatus } = useProjects();
  return (
    <ProjectItemContainer
      clickable
      onClick={() => navigate(`/organization/projects/${project.attributes.slug}`)}
    >
      <FlexContainer direction="column" gap="1_25">
        <FlexContainer justify="between" align="center">
          <Typography size="bodyMedium" weight="600">
            {project.attributes.name}
          </Typography>
        </FlexContainer>
        {/*   use this when the logic be ready */}
        {getAvatares().length > 0 ? (
          <AvatarList avatars={getAvatares()} />
        ) : (
          <FlexContainer align="center" gap="0_75">
            <ImageOrganizationContainer isAvatar noImage />
            <Typography size="bodyTextXSmall" color="monochromeBlack300">
              Not experts yet.
            </Typography>
          </FlexContainer>
        )}
        <StatusIndicator
          opinions={project.attributes.opinions}
          projectOpinions={project.attributes.project_opinions}
          status={getProjectStatus(project) as 'completed' | 'in_progress' | 'draft' | 'archived'}
        />
        <FlexContainer wrap>
          {project.attributes.project_tags.length > 0 ? (
            project.attributes.project_tags.slice(0, 3).map((tag) => (
              <Tag type="projectList">
                <TextTag>{tag.attributes.tag.attributes.name}</TextTag>
              </Tag>
            ))
          ) : (
            <EmptyContainer>
              <EmptyLabel bg="50">No related topics available.</EmptyLabel>
            </EmptyContainer>
          )}
        </FlexContainer>
      </FlexContainer>
    </ProjectItemContainer>
  );
};
