import { Link } from 'react-router-dom';
import { styled } from '../../../config/theme';

export const WrapperStats = styled('div', {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  borderRadius: '0.625rem',
  padding: '0.45rem',
  background: '$input',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '@bp4': {
    flexDirection: 'row',
  },
  variants: {
    isWidget: {
      true: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0',
      }
    }
  }
});

export const Stat = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: '0',
  padding: '0.75rem',
  fontSize: '$bodySmall',
  color: '$offBlack',
  fontWeight: '$weight700',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  '&:first-child': {
    marginLeft: '0'
  },
  '&:last-child': {
    marginRight: '0'
  },
  '& img': {
    margin: 0,
    marginRight: '0.5rem'
  },
  variants: {
    isWidget: {
      true: {
        padding: '0.5rem 0',
      }
    }
  }
});

export const StatText = styled('span', {
  fontSize: '$bodyXSmall',
  fontWeight: '$normal',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  paddingLeft: '0.5rem'
});

export const StatTextLittle = styled('span', {
  fontSize: '$bodyXSmall',
  marginLeft: '0.25rem',
  fontWeight: '$normal',
});

export const LinkStat = styled(Link, {
  textDecoration: 'none'
});
