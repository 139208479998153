import React from 'react';
import _ from 'lodash';
import { ExpertListContainer } from './styled';
import { keyExtractor } from '../../../../shared/utils/helper';
import { BreathingComponents } from '../../../../shared/components/ShimmerComponent/styles';
import { FlexContainer } from '../../styled';

export const LoadingExpert = () => (
  <ExpertListContainer>
    {_.range(1, 7).map(() => (
      <FlexContainer direction="column" gap="0_5" justify="center" align="center" key={keyExtractor()}>
        <BreathingComponents
          isExpertCard
          key={keyExtractor()}
          className="circle"
          height={70}
          width={70}
        />
        <BreathingComponents
          isExpertCard
          key={keyExtractor()}
          className="tab"
          height={15}
          width={150}
        />
        <BreathingComponents
          isExpertCard
          key={keyExtractor()}
          className="tab"
          height={15}
          width={75}
        />
      </FlexContainer>
    ))}
  </ExpertListContainer>
);
