import React, { useContext } from "react";
import VideoRecordingContext from "../../../shared/context/videoRecording/videoRecordingContext";
import { Typography } from "../styled";
import { QuestionContainer } from "../default/styled";

const OpinionNotes = () => {
  const { projectOpinion } = useContext(VideoRecordingContext);
  if(!projectOpinion?.attributes?.notes) return null;

  return(
    <div>
      <Typography
        size="displayXXSmall"
        weight="700"
        lineHeight="lineHeight3"
        letterSpacing="letterSpacing3"
      >
        Comment
      </Typography>
      <QuestionContainer editable={false}>
        <Typography
          size="bodyXSmall"
          lineHeight="lineHeight1"
          letterSpacing="letterSpacing6"
          color="monochromeBlack400"
          css={{ maxWidth: '100%' }}
        >
          {projectOpinion.attributes.notes}
        </Typography>
      </QuestionContainer>
    </div>
  );
};

export default OpinionNotes;