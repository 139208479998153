import { Form } from 'formik';
import { Link } from 'react-router-dom';
import { styled } from '../../../../config/theme';

export const Row = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'space-around',
  width: '100%',
  height: '4rem',
  marginTop: 0,
  borderRadius: '$radius1 $radius1',
  transition: 'all 0.3s ease-in-out',
  variants: {
    color: {
      active: {
        backgroundColor: '$offWhite',
      },
      inActive: { backgroundColor: '$input' },
    },
  },

  '@bp4': {
    height: '5.5rem',
  }
});

export const TabButton = styled('button', {
  variants: {
    color: {
      activeLeft: {
        background: '$offWhite',
        color: '$offBlack',
        fontWeight: '$bold',

        '&::after': {
          content: '',
          position: 'absolute',
          bottom: '-1rem',
          left: '$spaceSmall',
          height: '$sizes$2',
          width: '$sizes$2',
          borderTopLeftRadius: '$radius1',
          background: 'none',
          boxShadow: '-1rem 0 0 0 hsl(0, 0%, 98%, 1)',
        },
      },
      activeRight: {
        backgroundColor: '$offWhite',
        color: '$offBlack',
        fontWeight: 'bold',

        '&::after': {
          content: '',
          position: 'absolute',
          bottom: '-2rem',
          right: '0px',
          height: '$sizes$2',
          width: '$sizes$2',
          borderTopRightRadius: '$radius1',
          background: 'none',
          boxShadow: '0 -1rem 0 0 hsl(0, 0%, 98%, 1)',
        },
      },
      inActiveRight: {
        backgroundColor: '$input',
        color: '$label',

        '&::after': {
          content: '',
          position: 'absolute',
          bottom: '-2rem',
          right: '0px',
          height: '$sizes$2',
          width: '$sizes$2',
          borderTopRightRadius: '$radius1',
          background: 'none',
          boxShadow: '0 -1rem 0 0 hsl(232, 33%, 95%)',
        },
        '&::before': {
          content: '',
          position: 'absolute',
          bottom: '0px',
          left: '0',
          height: '$sizes$2',
          width: '$sizes$2',
          borderBottomLeftRadius: '$radius1',
          background: 'none',
          boxShadow: '-1rem 0 0 0 hsl(0, 0%, 98%, 1)',
        },
      },
      inActiveLeft: {
        backgroundColor: '$input',
        color: '$label',

        '&::after': {
          content: '',
          position: 'absolute',
          bottom: '0px',
          right: '0px',
          height: '$sizes$2',
          width: '$sizes$2',
          borderBottomRightRadius: '$radius1',
          background: 'none',
          boxShadow: '$sizes$1 0 0 0 hsl(0, 0%, 98%, 1)',
        },
      },
    },
  },
  textAlign: 'center',
  color: '$offWhite',
  fontSize: '$bodyMedium',
  fontFamily: '$poppins',
  fontWeight: '$weight500',
  width: '50%',
  height: '100%',
  borderRadius: '$radius1 $radius1 0 0',
  cursor: 'pointer',
  border: 'none',
  float: 'left',
  position: 'relative',
  letterSpacing: '$letterSpacing3',

  '@bp4': {
    fontSize: '$bodyLarge',
  }
});

export const ForgotPasswordLink = styled(Link, {
  display: 'block',
  textAlign: 'right',
  color: '$body',
  textDecoration: 'none',
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  width: '100%',
  '&:hover': {
    color: '$hoverBtn',
  }
});

export const FormikForm = styled(Form, {
  width: '100%',
  maxWidth: '400px',
});