import React from 'react';
import _ from 'lodash';
import { FlexContainer } from '../../styled';
import { SkeletonBox, SkeletonContainer } from './styled';
import { keyExtractor } from '../../../../shared/utils/helper';

export const LoadingQuestions = () => (
  <FlexContainer direction="column" gap="0_75">
    <SkeletonContainer>
      {_.range(1, 4).map(() => (
        <SkeletonBox key={keyExtractor()}/>
      ))}
    </SkeletonContainer>
  </FlexContainer>
);
