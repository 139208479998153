import React, { useMemo, ReactElement, ReactNode, useState } from 'react';
import ExpertContext from './ExpertContext';
import { ExpertData } from '../../interfaces/interfaces';

const initialExpertData: ExpertData = {
  projectName: '',
  description: '',
  locations: [],
  roles: [],
};

const ExpertProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [expert, setExpert] = useState<ExpertData>(initialExpertData);

  const value = useMemo(() => ({ expert, setExpert }), [expert]);

  return <ExpertContext.Provider value={value}>{children}</ExpertContext.Provider>;
};

export default ExpertProvider;
