import React from 'react';
import { FlexContainer } from '../../styled';
import { BreathingComponents } from '../../../../shared/components/ShimmerComponent/styles';
import { keyExtractor } from '../../../../shared/utils/helper';

export const ExpertInformationLoading = () => (
  <FlexContainer gap="0_5" align="center">
    <BreathingComponents
      isExpertCard
      key={keyExtractor()}
      className="circle"
      height={70}
      width={70}
    />
    <FlexContainer direction="column" gap="0_5">
      <BreathingComponents
        isExpertCard
        key={keyExtractor()}
        className="tab"
        height={20}
        width={250}
      />
      <BreathingComponents
        isExpertCard
        key={keyExtractor()}
        className="tab"
        height={15}
        width={200}
      />
    </FlexContainer>
  </FlexContainer>
);
