import { useContext, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik, FormikHelpers } from 'formik';
import useGetToken from '../../../../shared/hooks/useGetToken';
import { ProfileService } from '../../../../services/ProfileService/ProfileService';
import { UserModel } from '../../../../shared/context/app/types';
import AppContext from '../../../../shared/context/app/AppContext';
import { getExperiencesFormat, getLanguagesFormat } from '../../../../shared/utils/helper';
import { UserExperiencesService } from '../../../../services/UserExperiencesService/UserExperienceService';
import { useUser } from '../../../../shared/hooks/useUser';

const defaultExperience = {
  id: '',
  title: '',
  company: '',
  start_year: '',
  end_year: '',
  description: '',
};

const defaultLanguage = {
  id: '',
  name: '',
  level: '',
  edit: true,
};

const updateUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  biography: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
});

const updateExperienceSchema = Yup.object({
  experience: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Required'),
      company: Yup.string().required('Required'),
      start_year: Yup.string(),
      end_year: Yup.string(),
      description: Yup.string(),
    }),
  ),
});

const updateLanguageschema = Yup.object({
  languages: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Required'),
      level: Yup.string().required('Required'),
      edit: Yup.boolean(),
    }),
  ),
});

const updateTimezoneschema = Yup.object().shape({
  timezone: Yup.string(),
});

const updateSocialMediaschema = Yup.object().shape({
  researchGate: Yup.string(),
  linkedinLink: Yup.string(),
});

const usePublicProfileForm = (): any => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const { state, dispatch } = useContext(AppContext);
  const token = useGetToken();
  const {
    userId,
    firstName,
    lastName,
    email,
    experiences,
    languages,
    timezone,
    linkedinLink,
    researchGate,
  } = state.userData;
  const { isExpert } = useUser();

  const publicProfileForm = useFormik({
    initialValues: {
      userId,
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      organizationName: '',
      organizationAvatarUrl: '',
      authorOccupation: experiences && experiences.length > 0 ? experiences[0]?.title : '',
    },
    validationSchema: updateUserSchema,
    onSubmit: (values: UserModel, { setSubmitting }: FormikHelpers<UserModel>) => {
      // console.log(JSON.stringify(values, null, 2));
      setSubmitting(false);
    },
  });

  const handleSubmit = useCallback(
    async (data: UserModel) => {
      try {
        if (data.firstName === '' || data.lastName === '' || data.email === '') {
          toast.error(`First name, last name and email are required.`);
        } else {
          setLoadingBtn(true);
          const experienceId: any =
            experiences && experiences.length > 0 ? experiences[0].id : undefined;
          const params: any = {
            authenticationToken: token,
            title: data.authorOccupation,
          };
          let response;
          if (isExpert) {
            if (experienceId) {
              response = await UserExperiencesService.update({ ...params, id: experienceId });
            } else {
              response = await UserExperiencesService.create(params);
            }
          }
          const res = await ProfileService.updateUser({
            authenticationToken: token,
            dataUser: data,
          });

          if (res.length === 0 || response.length === 0) {
            toast.error(`Can't save profile`);
          } else {
            dispatch({ type: 'SET_USER', payload: res });
            toast.success('User updated!');
          }
        }
      } catch (error) {
        toast.error('Error request. Try again later.');
      } finally {
        setLoadingBtn(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, dispatch],
  );

  const professionalExperienceForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      experience: experiences || [defaultExperience],
    },
    validationSchema: updateExperienceSchema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      // console.log(JSON.stringify(values, null, 2));
      setSubmitting(false);
    },
  });

  const languagesForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      languages: languages || [defaultLanguage],
    },
    validationSchema: updateLanguageschema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      // console.log(JSON.stringify(values, null, 2));
      setSubmitting(false);
    },
  });

  const timezoneForm = useFormik({
    initialValues: {
      timezone: timezone || '',
    },
    validationSchema: updateTimezoneschema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      // console.log(JSON.stringify(values, null, 2));
      setSubmitting(false);
    },
  });

  const socialMediaForm = useFormik({
    initialValues: {
      researchGate: researchGate || '',
      linkedinLink: linkedinLink || '',
    },
    validationSchema: updateSocialMediaschema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(false);
    },
  });

  const submitForm = () => {
    const initialExperience = professionalExperienceForm.initialValues.experience;
    const newExperience = professionalExperienceForm.values.experience;
    const initialLanguages = languagesForm.initialValues.languages;
    const newLanguages = languagesForm.values.languages;
    const experienceData = getExperiencesFormat(initialExperience, newExperience);
    const languagesData = getLanguagesFormat(initialLanguages, newLanguages);
    const sendData = {
      experiences: experienceData,
      languages: languagesData,
      ...publicProfileForm.values,
      ...timezoneForm.values,
      ...socialMediaForm.values,
    };

    handleSubmit(sendData);
  };

  return {
    submitForm,
    publicProfileForm,
    professionalExperienceForm,
    languagesForm,
    timezoneForm,
    socialMediaForm,
    loadingBtn,
  };
};

export default usePublicProfileForm;
