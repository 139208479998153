/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useState, useRef, useEffect } from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import { TooltipContent, HoverCardTrigger } from './styled';
import useWindowSize from '../../hooks/useWindowSize';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';
import Button from '../Button/Button';
import closeIcon from '../../../assets/images/icons/close.svg';

interface TooltipCardModel {
  content: string | JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[];
  className?: string;
  isModal?: boolean;
  isControlled?: boolean;
  isTag?: boolean;
  isShare?: boolean;
  isAvatar?: boolean;
  isOpen?: boolean;
  // eslint-disable-next-line react/require-default-props, no-unused-vars
  setOpenState?: (state: boolean) => void;
}

const TooltipCard: FC<TooltipCardModel> = ({
  content,
  children,
  className,
  isModal,
  isControlled,
  isTag,
  isShare,
  isAvatar,
  isOpen,
  setOpenState,
}) => {
  const { width } = useWindowSize();
  const [show, setShow] = useState<boolean>(false);
  const refContent = useRef<HTMLDivElement | null>(null);
  const { pathname } = window.location;

  useOnClickOutside(refContent, () => setShow(false));

  useEffect(() => {
    const hideTooltipOnScroll = () => {
      if (show) setShow(false);
    };

    if (setOpenState) {
      setOpenState(show);
    }

    window.addEventListener('scroll', hideTooltipOnScroll);

    return () => window.removeEventListener('scroll', hideTooltipOnScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setShow(!!isOpen);
  }, [isOpen]);

  const showTooltipCard = () => {
    setShow((prev) => !prev);
  };

  const HoverCardControlled = ({ open }: { open: boolean }) => (
    <HoverCard.Root open={open}>
      <HoverCardTrigger className={className} onClick={showTooltipCard}>
        {children}
      </HoverCardTrigger>
      <HoverCard.Portal>
        <TooltipContent tag={isTag} share={isShare} avatar={isAvatar} ref={refContent}>
          {content}
        </TooltipContent>
      </HoverCard.Portal>
    </HoverCard.Root>
  );

  const HoverCardModel = ({ open }: { open: boolean }) => (
    <>
      <div className={className} onClick={showTooltipCard}>
        {children}
      </div>
      <Modal kindOverlay="gat-modal-overlay--light" isOpen={open} onClose={() => setShow(false)}>
        <Button
          title=""
          iconCustom={closeIcon}
          iconWidth="15px"
          iconHeight="15px"
          kind="transparent"
          handleClick={() => setShow(false)}
          css={{
            position: 'absolute',
            padding: '1.5rem 1.5rem 0 0',
            top: '0',
            right: '0',
            '& img': {
              margin: '0',
            },
          }}
        />
        <Card css={{ padding: '1em', width: '90vw' }}>{content}</Card>
      </Modal>
    </>
  );

  return !pathname.includes('profile') && width > 980 ? (
    isControlled ? (
      <HoverCardControlled open={show} />
    ) : (
      <HoverCard.Root>
        <HoverCardTrigger className={className}>{children}</HoverCardTrigger>
        <HoverCard.Portal>
          <TooltipContent tag={isTag} share={isShare} avatar={isAvatar}>
            {content}
          </TooltipContent>
        </HoverCard.Portal>
      </HoverCard.Root>
    )
  ) : width < 600 && isModal ? (
    <HoverCardModel open={show} />
  ) : (
    <HoverCardControlled open={show} />
  );
};

TooltipCard.defaultProps = {
  className: '',
  children: undefined,
  isModal: false,
  isControlled: false,
  isTag: false,
  isShare: false,
  isAvatar: false,
  isOpen: false,
  setOpenState: undefined,
};

export default TooltipCard;
