import React from 'react';
import { keyExtractor } from '../../utils/helper';
import { WrapperShimmer, BreathingComponents } from './styles';

const TagsLoad: React.FC<any> = ({ total }) => {
  const itemsTotal = total || 1;
  const arrayData = Array(itemsTotal).fill('items');

  return (
    <WrapperShimmer>
      {arrayData &&
        arrayData.map(() => (
          <BreathingComponents key={keyExtractor()} className="tab" height={30} />
        ))}
    </WrapperShimmer>
  );
};

export default TagsLoad;
