import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import LoadingComponent from '../../../shared/components/Loading/Loading';
import useGetToken from '../../../shared/hooks/useGetToken';
import { useProjects } from '../../../shared/hooks/useProjects';

export const AcceptInvitation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { organizationId, token, registered, email } = params;
  const authenticationToken = useGetToken();
  const { acceptInvitation } = useProjects();

  const saveParams = () => {
    localStorage.setItem('organizationId', organizationId as string);
    localStorage.setItem('tokenInvitation', token as string);
  };

  useEffect(() => {
    if (token && email) {
      saveParams();
      if (authenticationToken) {
        if (organizationId) {
          acceptInvitation(authenticationToken, token as string, organizationId as string);
        }
      } else if (organizationId) {
        if(registered === 'true') {
          navigate(`/authentication?invite=seeker&email=${email}`);
        } else {
          navigate(`/authentication?sign-up=true&invite=seeker&email=${email}`);
        }
      }
    } else {
      navigate('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingComponent />;
};
