import { styled } from '../../../../config/theme';

export const CreditsContainer = styled('div', {
  display: 'flex',
  width: '100%',
  maxWidth: 250,
  paddingRight: '$spaceXXSmall',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '$spaceLarge3',
  marginTop: 'calc($spaceSmall * 2.5)',
});

export const CreditsCounterContainer = styled('div', {
  display: 'flex',
  paddingX: '$spaceXXSmall',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$radius03',
  border: '1px solid $error',
});

export const Separator = styled('div', {
  width: '100%',
  height: 1,
  background: '#D9DBE9',
  opacity: 0.3,
  marginTop: '$spaceLarge3',
  marginBottom: '$spaceLarge',
  variants: {
    space: {
      0: {
        margin: 0,
      },
      1: {
        marginTop: `calc(1.25rem * 2)`,
        marginBottom: '$spaceLarge',
      }
    },
  },
});
