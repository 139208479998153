import React from 'react';

export const DeleteIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.25008 6.99935L11.2501 10.9993M7.25008 10.9993L11.2501 6.99935M16.5834 8.99935C16.5834 13.0494 13.3002 16.3327 9.25008 16.3327C5.19999 16.3327 1.91675 13.0494 1.91675 8.99935C1.91675 4.94926 5.19999 1.66602 9.25008 1.66602C13.3002 1.66602 16.5834 4.94926 16.5834 8.99935Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
