import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useProjects } from '../useProjects';
import { Project } from '../../interfaces/projectInterfaces';
import useGetUserType from '../getUserType';

export const useProjectList = () => {
  const { projects, getUserProjects, loadingProjects, setLoadingProjects, getProjectStatus } =
    useProjects();
  const userType = useGetUserType();
  const baseUrl = userType === 'seeker' ? '/organization/projects' : '/experts/projects';
  const getListMapped = () => projects
    .map((project: Project) => ({
      id: project.id,
      name: (
        <Link
          to={`${baseUrl}/${project.attributes.slug}`}
          style={{ textDecoration: 'none', color: 'hsl(240, 37%, 12%, 1)' }}
        >
          {project.attributes.name}
        </Link>
      ),
      createdAt: project.attributes.created_at,
      status: getProjectStatus(project),
      progress: {
        totalVideos: project.attributes.opinions,
        receivedVideos: project.attributes.project_opinions,
        progress: project.attributes.status,
      },
      credits: project.attributes.credits || '-',
      slug: project.attributes.slug || '-',
    }))
    .sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime());

  const getMockList = () =>
    _.range(1, 4).map(() => ({
      id: 'project.id',
      name: 'test',
      status: '-',
      progress: {
        totalVideos: '-',
        receivedVideos: '-',
        progress: '-',
      },
      credits: '0',
      slug: '-',
    }));

  useEffect(() => {
    if (projects.length <= 0) {
      getUserProjects();
    } else {
      setLoadingProjects(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { projects, getListMapped, loadingProjects, getMockList };
};
