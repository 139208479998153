import React, { useContext, useEffect, useState } from 'react';
import { Providers, User, TagModel } from '../../interfaces/interfaces';
import { getUserName } from '../../utils/helper';
import {
  WrapperCard,
  CardHeader,
  CardContent,
  CardFooter,
  TagsContainer,
} from '../CardResources/styles';
import { WrapperActions, TextContent, BoxShare } from './styles';
// import { KnowledgeService } from '../../../services/knowledge.service';
// import useGetToken from '../../hooks/useGetToken';
import PeopleData from '../People/PeopleData';
import PeopleFollow from '../People/PeopleFollow';
import KeyStats from '../KeyStats/KeyStats';
import Button from '../Button/Button';
import Share from '../Share/Share';
import { ProfileService } from '../../../services/ProfileService/ProfileService';
import Tags from '../Tags/Tags';
import AppContext from '../../context/app/AppContext';
import useWindowSize from '../../hooks/useWindowSize';

const DEFAULT_BIO = `Find my long-form content.`;
const TEXT_KB = 'Knowledge Playlists';
const TEXT_CONTENTS = 'Pieces';
const TEXT_BTN_SAME_USER = `It's You`;

interface PeopleProps extends Providers {
  isSlider?: boolean;
  userId?: string | number;
  className?: string;
}

const CardPeople: React.FC<PeopleProps> = ({
  attributes,
  isSlider,
  userId,
  id,
  className,
}) => {
  // const RenTag = useMemo(() => Tags, []);
  const [userData, setUserData] = useState<User | undefined>();
  const [sameUser, setSameUser] = useState(false);
  const { state } = useContext(AppContext);
  const { width } = useWindowSize();
  const isResponsive = width < 640;
  const { userData: actualUserData } = state;
  const dataName = {
    name: attributes?.name,
    first_name: attributes?.first_name,
    last_name: attributes?.last_name,
  };
  const playlists = attributes?.knowledge_books_included || 0;
  const avatarUser = attributes?.avatar_url;
  const finalName = getUserName(dataName);

  const slug = attributes?.slug || id;
  const stats = [
    {
      icon: 'article',
      rate: attributes?.total_contents || 0,
      text: TEXT_CONTENTS,
      link: `/profile/${slug}`,
    },
    {
      icon: 'bookmark',
      rate: playlists,
      text: TEXT_KB,
      link: `/users/${attributes?.slug}/playlists`,
    },
  ];

  useEffect(() => {
    (async () => {
      if (attributes && id) {
        const params = { id };
        const user = await ProfileService.getUser(params);
        const newTags = await user?.attributes?.expertise?.data.map((item: any) => ({
          ...item?.attributes?.tag?.data,
          expertise_id: item?.id,
        }));

        await setUserData({ ...user, attributes: { ...user?.attributes, expertise: newTags } });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes?.user_id]);

  // useEffect(
  //   () => () => {
  //     setUserData(undefined);
  //   },
  //   [],
  // );

  useEffect(() => {
    setSameUser(attributes?.user_id === Number(actualUserData.userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualUserData]);

  return (
    <WrapperCard little isSlider={isSlider} className={`peope-container ${className}`}>
      <CardHeader className="people-header" css={{ flexDirection: 'row' }}>
        <div>
          <PeopleData
            name={finalName}
            text={`${attributes?.followers} Followers`}
            img={avatarUser}
            slug={slug}
            type="mid"
          />
        </div>
        <BoxShare>
          <Share isProfile name={attributes?.name} text={attributes?.twitter_handle} slug={slug} />
        </BoxShare>
      </CardHeader>
      <CardContent className="people-content" css={{ width: '100%' }}>
        <TextContent>{userData?.attributes?.biography || DEFAULT_BIO}</TextContent>
        <KeyStats data={stats} />
        <TagsContainer>
          {userData?.attributes?.expertise && (
            <Tags
              isCardPeople
              data={userData?.attributes?.expertise as TagModel[]}
              // eslint-disable-next-line no-console
              actionTag={() => console.log('test')}
              limit={2}
              sameUser={sameUser}
              userId={userId}
            >
              {isResponsive && sameUser && (
                <Button
                  title={TEXT_BTN_SAME_USER}
                  kind="following"
                  size="medium"
                  renderProps={{
                    disabled: true,
                  }}
                />
              )}
              {isResponsive && userData?.attributes?.expertise.length > 2 && !sameUser && (
                <PeopleFollow
                  follow={attributes?.relationship_id}
                  id={id}
                  username={attributes?.twitter_handle}
                  size="medium"
                />
              )}
            </Tags>
          )}
        </TagsContainer>
      </CardContent>
      <CardFooter>
        {isResponsive && (userData?.attributes?.expertise.length < 3 || sameUser) && (
          <WrapperActions>
            <div style={{ width: '25%' }}>
              {!sameUser && (
                <PeopleFollow
                  follow={attributes?.relationship_id}
                  id={id}
                  username={attributes?.twitter_handle}
                  size="medium"
                />
              )}
            </div>
            {/* <div style={{ width: '75%', paddingLeft: '8px' }}>
            <RenTag
              data={userData?.attributes?.expertise || []}
              css={{ justifyContent: 'flex-end' }}
              cssTag={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              actionTag={() => { }}
            />
          </div> */}
          </WrapperActions>
        )}
        {!isResponsive && (
          <WrapperActions>
            <div style={{ width: '25%' }}>
              {sameUser && (
                <Button
                  title={TEXT_BTN_SAME_USER}
                  kind="following"
                  size="medium"
                  renderProps={{
                    disabled: true,
                  }}
                />
              )}
              {!sameUser && (
                <PeopleFollow
                  follow={attributes?.relationship_id}
                  id={id}
                  username={attributes?.twitter_handle}
                  size="medium"
                />
              )}
            </div>
            {/* <div style={{ width: '75%', paddingLeft: '8px' }}>
            <RenTag
              data={userData?.attributes?.expertise || []}
              css={{ justifyContent: 'flex-end' }}
              cssTag={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              actionTag={() => { }}
            />
          </div> */}
          </WrapperActions>
        )}
      </CardFooter>
    </WrapperCard>
  );
};

CardPeople.defaultProps = {
  isSlider: false,
  userId: undefined,
  className: '',
};

export default CardPeople;
