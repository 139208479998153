import React, { useContext } from 'react';
import Button from '../../../../shared/components/Button/Button';
import { ButtonWrapper } from '../../styled';
import { OrcidAuthService } from '../../../../services/AuthService/OrcidAuthService';
import AuthenticationContext from '../../../../shared/context/authentication/authenticationContext';

const Orcid = () => {
  const { login } = useContext(AuthenticationContext);
  const title = login ? 'Sign in with Orcid' : 'Sign up with Orcid';

  const handleClick = () => {
    OrcidAuthService.getOrcidURL(login);
  };

  return (
    <ButtonWrapper css={{width: '100%'}}>
      <Button
        title={title}
        orcidIcon
        iconWidth="24px"
        iconHeight="24px"
        size="small"
        handleClick={handleClick}
        css={{
          backgroundColor: 'transparent',
          height: '2.5rem',
          maxWidth: '25rem',
          color: 'hsla(206, 4%, 31%, 1)',
          border: '1px solid hsla(216, 8%, 88%, 1)',
          marginBottom: '0.62rem',
          '&:hover': {
            color: '$offWhite'
          },
          'span': {
            fontFamily: '$poppins',
            fontSize: '$bodySmall',
            fontWeight: '$weight600',
            lineHeight: '$lineHeight2',
            letterSpacing: '$letterSpacing2',
            textAlign: 'center',
          }
        }}
      />
    </ButtonWrapper>
  );
};

export default Orcid;
