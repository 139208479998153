import React, { useContext, useState } from 'react';
import { ExpertListContainer } from './styled';
import { ExpertItem } from './ExpertItem';
import ProjectsContext from '../../../../shared/context/projects/ProjectsContext';
import { FlexContainer } from '../../styled';
import Button from '../../../../shared/components/Button/Button';

interface ExpertListProps {
  experts: any[];
}
const itemsPerPage = 9;
export const ExpertList = ({ experts }: ExpertListProps) => {
  const { project } = useContext(ProjectsContext);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = experts.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(experts.length / itemsPerPage);

  const handleListExperts = async (nextPage?: boolean, prevPage?: boolean) => {
    let pageValue = currentPage;
    if (nextPage) {
      pageValue += 1;
    }
    if (prevPage) {
      pageValue -= 1;
    }
    await setCurrentPage(pageValue);
  };
  // const getExpertRole = (expert: any) => {
  //   if (expert.attributes.experiences && expert.attributes.experiences.length) {
  //     return expert.attributes.experiences[0].title;
  //   }
  //   if (expert.attributes.user_experiences && expert.attributes.user_experiences.length) {
  //     return expert.attributes.user_experiences[0].title;
  //   }
  //   return 'Expert';
  // };
  return (
    <>
      <ExpertListContainer>
        {currentItems.map((expert) => (
          <ExpertItem
            image={expert.attributes.user_avatar || expert.attributes.avatar_url}
            expertName={expert.attributes.user_name || expert.attributes.name}
            expertRole={expert.attributes.biography || "Expert"}
            expertEmail={expert.attributes.email}
            expertOpinionUrl={`/organization/projects/${project?.attributes.slug}/opinion/${expert.attributes.user_slug}`}
            hasOpinion={!!expert.attributes.user_opinion_id}
            key={expert.id}
          />
        ))}
      </ExpertListContainer>
      {experts.length > itemsPerPage && (
        <FlexContainer justify="center" gap="1_25">
          <Button
            kind="outline"
            title="Prev"
            size="small"
            width="auto"
            isHidden={currentPage === 1}
            renderProps={{
              disabled: currentPage === 1,
              onClick: () => handleListExperts(false, true),
            }}
          />
          <Button
            kind="outline"
            title="Next"
            size="small"
            width="auto"
            isHidden={currentPage === totalPages}
            renderProps={{
              disabled: currentPage === totalPages,
              onClick: () => handleListExperts(true, false),
            }}
          />
        </FlexContainer>
      )}
    </>
  );
};
