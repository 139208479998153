import React, { useState, useEffect } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import iconDefault from '../../../assets/images/icons/icon-default.svg';
import { WrapperLinkPreview, BoxTooltip } from './styles';
import { getMetaDataLink } from './FindMetadata';

interface LinkPreviewModel {
  text: string | undefined;
  link: string | undefined;
  // eslint-disable-next-line no-unused-vars
  actionResource?: (e: any) => void;
}

const LinkPreview: React.FunctionComponent<LinkPreviewModel> = ({ text, link, actionResource }) => {
  const [favicon, setFavicon] = useState('');
  const [resource, setResource] = useState('');
  const existLink = Boolean(link);

  const actionLink = async () => {
    if (!favicon && !resource) {
      const data = await getMetaDataLink(link || '');

      if ((data?.images || data?.videos) && actionResource) {
        if (data?.images.length) {
          actionResource(data?.images[0]);
          setResource(data?.images[0]);
        } else if (data?.videos.length) {
          actionResource(data?.videos[0]);
          setResource(data?.videos[0]);
        }
      }

      if (data?.favicons.length > 0) {
        setFavicon(data?.favicons[0]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      actionLink();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  useEffect(() => () => {
    setResource('');
    setFavicon('');
  }, []);

  return (
    <WrapperLinkPreview>
      {existLink && (
        <Tooltip
          type='link-preview'
          // @ts-ignore
          content={(
            <BoxTooltip>
              <Icon img={favicon || iconDefault} width='16px' height='16px' alt="Preview" />
              <span>{link}</span>
            </BoxTooltip>
          )}
        >
          <div onMouseEnter={actionLink}>
            <a href={link} rel='noreferrer' target='_blank' >
              <p>
                <strong>{text}</strong>
              </p>
            </a>
          </div>
        </Tooltip>
      )}
      {!existLink && (<p><strong>{text}</strong></p>)}
    </WrapperLinkPreview>
  );
};

LinkPreview.defaultProps = {
  actionResource: undefined
};

export default LinkPreview;
