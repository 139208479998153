import React from 'react';
import { IndicatorContainer, IndicatorDot } from './styles';
import { keyExtractor } from '../../../shared/utils/helper';

interface IndicatorProps {
  count: number;
  selected: number;
}
const Indicator: React.FC<IndicatorProps> = ({ count, selected }) => (
  <IndicatorContainer>
    {[...Array(count)].map((_, index) => (
      <IndicatorDot key={keyExtractor()} selected={index === selected} />
    ))}
  </IndicatorContainer>
);

export default Indicator;
