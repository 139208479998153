import React, { useContext, useEffect, useState } from 'react';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import { SoundIndicatorContainer } from '../styles';
import { SoundIcon } from './icons/SoundIcon';

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

function initializeAnalyser(stream: MediaStream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;
  analyser.minDecibels = -127;
  analyser.maxDecibels = 0;
  analyser.smoothingTimeConstant = 0.4;
  audioSource.connect(analyser);
  return analyser;
}

const SoundIndicator = ({ isBlock }: { isBlock?: boolean }) => {
  const { webcamRef } = useContext(VideoRecordingContext);
  const [percentage, setPercentage] = useState(0);
  const [analyser, setAnalyser] = useState<AnalyserNode>();

  useEffect(
    () => {
      let newAnalyser: AnalyserNode | undefined;

      const initializeAnalyserIfNeeded = () => {
        if (webcamRef.current?.stream) {
          newAnalyser = initializeAnalyser(webcamRef.current.stream);
          setAnalyser(newAnalyser);
        }
      };

      initializeAnalyserIfNeeded();

      return () => {
        if (newAnalyser) {
          newAnalyser.disconnect();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [webcamRef.current?.stream],
  );

  const volumeCallback = () => {
    if (!analyser) return;

    const volumeArray = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(volumeArray);
    const volumeSum = volumeArray.reduce((sum, volume) => sum + volume, 0);
    const averageVolume = volumeSum / volumeArray.length;
    setPercentage((averageVolume * 100) / 127);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    const initializeIndicator = () => {
      interval = setInterval(volumeCallback, 100);
    };

    if (analyser) {
      initializeIndicator();
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyser]);

  return (
    <SoundIndicatorContainer
      isBlock={isBlock}
      css={{
        maxHeight: '60px',
        maxWidth: '60px',
        backgroundColor: 'hsla(227, 78%, 60%, 0.7)',
        svg: { paddingRight: '2.5px' },
      }}
    >
      <SoundIcon height={16} width={16} percentage={percentage} />
    </SoundIndicatorContainer>
  );
};

SoundIndicator.defaultProps = {
  isBlock: false,
};

export default SoundIndicator;
