import React from 'react';
import { toast } from 'react-toastify';
import successIcon from '../../../assets/images/icons/success-icon.svg';
import trashIcon from '../../../assets/images/icons/trashCircle.svg';
import Icon from '../Icon/Icon';

export const clearWaitingQueue = () => {
  toast.clearWaitingQueue();
};

export const success = (message: any) => {
  toast.success(message, { icon: <Icon img={successIcon} alt="Info" /> });
};

export const remove = (message: any) => {
  toast.dismiss();
  toast.clearWaitingQueue();
  toast.info(message, {
    icon: <Icon img={trashIcon} alt="Info" />,
  });
};
