/* eslint-disable no-unused-vars */
import React from 'react';
import { StylesConfig } from 'react-select';

import { menuStyles } from './styles';
import SelectItem from '../../settings/components/PublicProfile/LanguagesForm/SelectItem';

interface ChangeDeviceSelectProps {
  options: Array<any>;
  value?: { value: string; label: string } | undefined;
  onChange: (value: any) => void;
}

const ChangeDeviceSelect = (props: ChangeDeviceSelectProps) => {
  const { options, onChange, value } = props;

  const customStyles: StylesConfig<any> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '$grayscaleLabel',
      border: '1px solid $grayscaleLabel',
      boxShadow: 'none',
      height: '3rem',
    }),
    input: (styles) => ({ ...styles, minWidth: 100 }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: '0 4px 0 0',
    }),
    menu: (styles) => ({ ...styles, ...menuStyles }),
    option: (styles, { isDisabled, isSelected, isFocused }) => ({
      ...styles,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: 'hsla(227, 78%, 60%, 1)',
    },
  });

  return (
    <SelectItem
      options={options}
      customStyles={customStyles}
      customTheme={customTheme}
      onChange={onChange}
      value={value}
      menuPortalTarget={document.body}
    />
  );
};

ChangeDeviceSelect.defaultProps = {
  value: undefined,
};

export default ChangeDeviceSelect;
