import React from 'react';
import AuthorLoad from './AuthorLoad';
import { WrapperListShimmer } from '../Authors/styles';
import { keyExtractor } from '../../utils/helper';

const AuthorsListLoad: React.FC<any> = ({ total }) => {
  const itemsTotal = total || 1;
  const arrayData = Array(itemsTotal).fill('items');

  return (
    <WrapperListShimmer
      className="authors-list"
      css={{ ' .title': { marginBottom: '$spaceSmall' } }}
    >
      {arrayData && arrayData?.map(() => <AuthorLoad key={keyExtractor()} />)}
    </WrapperListShimmer>
  );
};

export default AuthorsListLoad;
