import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import avatarIcon from '../../../assets/images/icons/avatar.svg';
import { Wrapper, Initials } from './styles';
import NameInitials from './NameInitials/NameInitials';
import MentionIcon from '../../../assets/images/icons/mention.svg';

interface AvatarModel {
  img?: string;
  name: string | undefined;
  type?: string;
  pattern?: boolean;
  subIcon?: boolean;
}

type AvatarProps = AvatarModel &
  React.ComponentProps<typeof Initials> & {
    type?: string;
    css?: any;
  };

const resizeAvatar = (img: string | undefined, type: string | undefined): string | undefined => {
  let imgSized = img;
  if (img?.includes('twimg.com/profile_images')){
    if (type === 'xs') {imgSized = img.replace('normal', 'mini');}
    else if (type === 'mid') {imgSized = img.replace('normal', 'bigger');}
    else if (type === 'big' || type === 'big-xl') {imgSized = img.replace('_normal', '');}
  }
  else if (img?.includes('googleusercontent.com')){
    imgSized = img.substring(0, img.indexOf('=') + 1);
    if (type === 'xs') {imgSized += 's24-c';}
    else if (type === 'header' || type === 'little') {imgSized += 's48-c';}
    else if (type === 'mid') {imgSized += 's73-c';}
    else if (type === 'big' || type === 'big-xl') {imgSized += 's149-c';}
    else {imgSized += 's32-c';}
  }
  else if (img?.includes('mentions')) {
    imgSized = MentionIcon;
  }
  return imgSized;
};

const Avatar: React.FunctionComponent<AvatarProps> = ({img, type, name, css, pattern, subIcon}) => {
  const provider = img?.includes('googleusercontent.com') ? 'Google' : '';
  const imgSized = resizeAvatar(img, type);
  const [loadAvatarError, setLoadAvatarError] = useState<boolean>(false);
  const validateName = name && (typeof name === 'string');

  return(
    <Wrapper type={type} css={css}>
      {imgSized && !loadAvatarError &&
        <Icon img={imgSized}
              setLoadAvatarError={setLoadAvatarError}
              alt={name}
              provider={provider}/>}
      {!imgSized && !validateName && <Icon img={avatarIcon} />}
      {!imgSized && validateName && (<NameInitials name={name} type={type} pattern={pattern} />)}
      {imgSized && loadAvatarError && validateName && ((
        <NameInitials name={name} type={type} pattern={pattern} />
      ))}
      {subIcon && (<Icon img={MentionIcon} className='sub-icon'
              setLoadAvatarError={setLoadAvatarError}
              alt='sub icon'
              />)}
    </Wrapper>
  );
};

Avatar.defaultProps = {
  img: undefined,
  type: undefined,
  css: undefined,
  pattern: undefined,
  subIcon: false,
};

export default Avatar;
