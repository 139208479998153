import { Link } from 'react-router-dom';
import { styled } from '../../../config/theme';

export const FooterContainer = styled('div', {
  backgroundColor: '$offBlack',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  color: '$offWhite',
  padding: '1rem 2rem',
  zIndex: 1,
  '@bp2': {
    padding: '8px 27px',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  '@bp3': {
    padding: '.5rem 80px',
  },
  '.TitleContainer': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '60px',
    marginBottom: '$spaceLarge',
    '@bp2': {
      width: 'auto',
      marginBottom: '0',
    },
  }
});

export const ListContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2,1fr)',
  width: '100%',
  justifyItems: 'center',

  '@bp2': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  '@bp3': {
    paddingX: '$spaceMedium',
    justifyItems: 'center', 
  },
  '@bp4': {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
});

export const TextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '$offWhite',
});

export const PageLinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  color: '$offWhite',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@bp2': {
    width: 'auto',
    justifyContent: 'center',
  },
  variants: {
    type: {
      light: {
        justifyContent: 'space-between',
        '.link-item': {
          color: '$label',
          textDecoration: 'none',
          fontSize: '$bodyXSSmall',
          fontWeight: '$normal'
        }
      }
    }
  }
});

export const SocialMedia = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '-0.6rem',
  marginX: '$spaceXSmall',
  marginY: '$spaceSmall',
});

export const Title = styled('a', {
  color: '$offWhite',
  textDecoration: 'none',
  fontSize: '$bodyMedium',
  fontWeight: '$weight700',
  letterSpacing: 1,
  fontFamily: '$Poppins',
  marginY: '$spaceXSmall',
  '@bp2': {
    fontSize: '$displayXSmall',
  },
  '@bp4': {
    marginBottom: 0,
  },


});

export const Text = styled('p', {
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodyXSmall',
  color: '$offWhite',
  fontWeight: '$weight600'
});

export const LinkFooter = styled(Link, {
  display:'block',
  color: '$offWhite',
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  marginX: '$spaceXSmall',
  'img': {
    margin: 0
  }
});

export const LinkFooterA = styled('a', {
  display:'block',
  color: '$offWhite',
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  marginX: '$spaceXSmall',
  'img': {
    margin: 0
  }
});
