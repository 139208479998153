import React, { useContext } from "react";
import { PauseRecordingContainer } from "../styles";
import PauseRecordingIcon from "../../../assets/images/icons/pause-recording.svg";
import ResumeRecordingIcon from "../../../assets/images/icons/resume-recording.svg";
import Timer from "./Timer/Timer";
import Icon from "../../../shared/components/Icon/Icon";
import VideoRecordingContext from "../../../shared/context/videoRecording/videoRecordingContext";

interface VideoActionsProps {
  handlePauseCaptureClick: () => void;
  handleResumeCaptureClick: () => void;
}

const PauseRecording =
  ({ handlePauseCaptureClick, handleResumeCaptureClick }: VideoActionsProps) => {
  const { status } = useContext(VideoRecordingContext);

  const handleClick = () => {
    if (status === 'paused') {
      handleResumeCaptureClick();
    } else {
      handlePauseCaptureClick();
    }
  };

  return(
    <PauseRecordingContainer onClick={handleClick}>
    <span>
      <Icon img={status === 'paused' ? ResumeRecordingIcon : PauseRecordingIcon }
            alt={`${status === 'paused' ? 'Continue' : 'Pause'} recording`}
            margin0
            css={{marginRight: '12px'}}/>
      <Timer isRunning={status !== 'paused'} />
    </span>
  </PauseRecordingContainer>
  );
};

export default PauseRecording;