import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileService } from '../../services/ProfileService/ProfileService';
import AppContext from '../context/app/AppContext';
import { User } from '../interfaces/interfaces';

type getUserParameters = {
  authenticationToken?: string;
  slug?: string;
  isProfile?: boolean;
};

const useGetUser = (parameters: getUserParameters) => {
  const { authenticationToken, slug, isProfile } = parameters;
  const [userData, setUserData] = useState<User | null>(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [getUser, setGetUser] = useState<Boolean>(false);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { reload, isTimeOut } = state;

  useEffect(() => {
    const fetchUser = async () => {
      setLoadingUser(true);
      const response = await ProfileService.getUserAxiosResponse({
        authenticationToken,
        id: slug,
      });
      dispatch({ type: 'SET_RELOAD', payload: false });

      if (response.status === 404 && isProfile) {
        navigate('/404');
      } else if (response.code === 'ECONNABORTED') {
        dispatch({ type: 'SET_TIME_OUT', payload: true });
        dispatch({ type: 'SET_RELOAD', payload: true });
      } else {
        if (isTimeOut) dispatch({ type: 'SET_TIME_OUT', payload: false });
        const dataResponse = response?.data?.data || [];
        setUserData(dataResponse);
        setLoadingUser(false);
        setGetUser(false);
      }
    };
    if ((reload || getUser) && slug) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, getUser]);

  useEffect(() => {
    setUserData(null);
    setGetUser(true);
  }, [slug]);

  return { userData, loadingUser, setLoadingUser, setUserData };
};

export default useGetUser;
