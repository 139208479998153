import React, { useState, useRef, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, TabsContent, BoxTabs, BoxFloat } from './styled';
import PublicProfile from '../PublicProfile/PublicProfile';
import AccountSettings from '../AccountSettings/AccountSettings';
import { LayoutContainer, LayoutCol } from '../../../../shared/components/Layouts/styles';
import Accordion from '../../../../shared/components/Accordion/Accordion';
import { WidgetBody, WidgetTitle } from '../../../../shared/components/Widget/styles';
import AppContext from '../../../../shared/context/app/AppContext';
import SettingsTabsHeader from './SettingsTabsHeader';

const SettingTabs: React.FunctionComponent = () => {
  const activatorRef = useRef<HTMLButtonElement | null>(null);
  const [currentKeyTab, setCurrentKeyTab] = useState<string>('profile');
  const [dataTabs, setDataTabs] = useState<{ key: string; name: string }[]>();
  const location = useLocation();
  const { state } = useContext(AppContext);
  const { kind } = state.userData;
  const currentTab = dataTabs?.find((i) => i?.key === currentKeyTab);

  useEffect(() => {
    if (!kind) return;

    const data =  [
      { key: 'profile', name: 'Your profile' }
    ];
    if (kind === "gathersight") {
      data.push({ key: 'account', name: 'Password settings' });
    }
    setDataTabs(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kind]);

  const handleClick = (e: any) => {
    setCurrentKeyTab(e);
    activatorRef?.current?.click();
  };

  useEffect(() => {
    if (location.hash === '#feed') {
      handleClick('feed');
    }
  }, [location]);

  return (
    <Tabs
      defaultValue="profile"
      orientation="vertical"
      value={currentKeyTab}
      onValueChange={(e: string) => handleClick(e)}
    >
      <LayoutContainer
        css={{
          paddingBottom: '0',
          paddingTop: '1.5rem',
          display: 'flex',
        }}
      >
        <LayoutCol
          col="12"
          css={{ flexDirection: 'row', justifyContent: 'flex-start', gap: '$spaceLarge' }}
        >
          <BoxFloat>
            <Accordion
              innerRef={activatorRef}
              header={
                <WidgetTitle
                  css={{ width: '100%', color: '$body', margin: 0, textAlign: 'center' }}
                >
                  {currentTab?.name || ''}
                </WidgetTitle>
              }
              type="single"
              kind="tabsMobile"
            >
              <WidgetBody>
                { dataTabs && <SettingsTabsHeader dataTabs={dataTabs} />}
              </WidgetBody>
            </Accordion>
          </BoxFloat>
          <BoxTabs>
            { dataTabs && <SettingsTabsHeader dataTabs={dataTabs} />}
          </BoxTabs>
        </LayoutCol>
      </LayoutContainer>
      <TabsContent value="profile">
        <PublicProfile />
      </TabsContent>
      { kind === "gathersight" && (
        <TabsContent value="account">
          <AccountSettings />
        </TabsContent>
      )}
    </Tabs>
  );
};

export default SettingTabs;
