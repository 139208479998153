/* eslint-disable max-len */
import React from 'react';
import PageContainer from '../../shared/components/Container/PageContainer';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
import Button from '../../shared/components/Button/Button';
import { Title, Text, ButtonLink, CenterWrapper } from './styled';
import MetaTags from '../../shared/components/MetaTags/MetaTags';

const About = () => (
  <PageContainer layout="statics">
    <MetaTags
      title="Gathersight: About Us"
      description="Gathersight is a place to find people with ideas and opinions. Read, listen and connect with the world’s most interesting experts and creators."
      canonical="https://www.gathersight.com/terms/"
    />
    <LayoutContainer type="statics" css={{ flexWrap: 'wrap' }}>
      <LayoutCol col="12" css={{ flexDirection: 'column', alignItems: 'baseline' }}>
        <Title>A million biotech experts on your team</Title>
        <Text>
          We started Gathersight to create a better way to share expertise and knowledge in the life
          sciences and biotech industries.
        </Text>
        <Text>
          In our view, too much knowledge is locked away in protective institutions and
          over-expensive systems. Knowledge is created by people, not institutions. Our ambition is
          to create the most effective way to connect directly with individual scientists,
          researchers and industry specialists.
        </Text>
        <Text>
          Using our{' '}
          <a href="https://www.gathersight.com/expert-opinions">Expert Opinions platform</a>,
          organizations can request bespoke insights from highly specialised experts across 2,000 topics.
          Answers and opinions are delivered in short 15 - 30 minute video recordings,
          greatly reducing the cost and time needed to acquire specialist insights.
          It’s like having a million experts on your team! A virtually limitless pool of on-demand subject-matter
          experts ready to answer your most important questions and provide unique inputs for your research.
        </Text>
        <Text>
          As an expert, Expert Opinions are a way to unlock the value of your personal experience
          while sharing knowledge with the people and projects that will benefit most. You’ll be
          joining a network of scientists, researchers and industry specialists engaging in
          stimulating projects while earning additional income.
        </Text>
        <Text>
          Supporting the network is the <a href="https://www.gathersight.com/">Gathersight index</a>
          , a searchable database of global expertise in the life sciences and biotech industry. The
          index aims to simplify the process of finding subject-matter experts in highly specialized
          topics.
        </Text>
        <Text>Welcome to Gathersight. We hope you like it!</Text>

        <CenterWrapper>
          <ButtonLink
            href="https://gathersight.notion.site/Hello-and-thank-you-for-coming-15f8fba060dd4541a5bce70293da8cb0"
            target="_blank"
          >
            <Button title="How to use Gathersight" kind="user-profile" />
          </ButtonLink>
        </CenterWrapper>
      </LayoutCol>
    </LayoutContainer>
  </PageContainer>
);

export default About;
