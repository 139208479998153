import React, { useState, RefObject } from 'react';
import Button from '../Button/Button';
import {
  WrapperShare,
  BoxText,
  TextTitle,
  BoxContent,
  InputWrapper,
  Input,
  SocialWrapper,
} from './styles';
import { keyExtractor } from '../../utils/helper';
import shareWhiteLogo from '../../../assets/images/icons/share-white.svg';
import shareLogo from '../../../assets/images/icons/share.svg';
import linkedInIcon from '../../../assets/images/icons/linkedin-blue-2.svg';
import twitterIcon from '../../../assets/images/icons/twitter-blue.svg';
import mailIcon from '../../../assets/images/icons/mail-blue.svg';
import TooltipCard from '../TooltipCard';

interface ShareModel {
  name?: string;
  text?: string;
  email?: string;
  slug?: string;
  light?: boolean;
  isProfile?: boolean;
  actionShare?: () => void;
  innerRef?: RefObject<HTMLButtonElement> | any;
}

type ShareProps = ShareModel &
  React.ComponentProps<typeof WrapperShare> & {
    css?: any;
    kind?: string;
  };

const Share: React.FunctionComponent<ShareProps> = ({
  light,
  actionShare,
  isProfile,
  name,
  text,
  email,
  css,
  kind,
  innerRef,
  slug,
}) => {
  const [copy, setCopy] = useState<boolean>(false);
  const urlShareCard = window?.location?.origin || '';
  const urlShare = slug ? `${urlShareCard}/profile/${slug}` : window?.location?.href || '';
  const title = name || '';

  const shareData = [
    {
      name: 'twitter',
      url: `https://twitter.com/intent/tweet?url=${urlShare}${text ? `&text=${text}` : ''}`,
      icon: twitterIcon,
    },
    {
      name: 'linkedin',
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${urlShare}`,
      icon: linkedInIcon,
    },
    {
      name: 'email',
      url: `mailto:${email}?subject='${title}${text ? `&body=${text}` : ''}`,
      icon: mailIcon,
    },
  ];

  const handleShare = () => {
    if (actionShare) {
      actionShare();
    } else {
      setCopy(false);
    }
  };

  const handleCopy = () => {
    if (urlShare) {
      navigator.clipboard.writeText(urlShare);
      setCopy(true);
    }
  };

  const handleShareLink = (item: any) => {
    if (item?.url) {
      window.open(item?.url, '_blank');
    }
  };

  return (
    <WrapperShare kind={kind} css={css}>
      <TooltipCard
        isModal
        isShare
        content={
          <>
            <BoxText css={{ padding: '1rem 2rem 0' }}>
              <TextTitle>{`Share this ${isProfile ? 'profile' : 'resource'}`}</TextTitle>
            </BoxText>
            <BoxContent css={{ padding: '0 2rem', marginBottom: '2rem' }}>
              <SocialWrapper css={{ marginBottom: '$spaceXSmall' }}>Share link via</SocialWrapper>
              <SocialWrapper>
                {shareData &&
                  shareData.map((item: any) => (
                    <Button
                      key={keyExtractor()}
                      title=""
                      iconCustom={item.icon}
                      iconWidth="24px"
                      iconHeight="24px"
                      kind="circle-social"
                      handleClick={() => handleShareLink(item)}
                    />
                  ))}
              </SocialWrapper>
              <SocialWrapper css={{ marginTop: '$spaceLarge' }}>Or copy link</SocialWrapper>
              <InputWrapper
                css={{ backgroundColor: 'transparent', border: '1px solid $colors$line' }}
              >
                <Input type="text" placeholder="Tittle" value={urlShare} disabled />
                <Button
                  title={copy ? 'Copied' : 'Copy'}
                  css={{
                    backgroundColor: '$body',
                    height: '40px',
                    width: '63px',
                    fontSize: '$bodyXSmall',
                    borderRadius: '5px',
                    marginRight: '10px',
                  }}
                  renderProps={{ onClick: handleCopy }}
                />
              </InputWrapper>
            </BoxContent>
          </>
        }
      >
        <Button
          ref={innerRef}
          title=""
          iconCustom={light ? shareWhiteLogo : shareLogo}
          kind="transparent"
          renderProps={{ onClick: handleShare }}
        />
      </TooltipCard>
    </WrapperShare>
  );
};

Share.defaultProps = {
  css: undefined,
  kind: undefined,
  innerRef: undefined,
  name: undefined,
  text: undefined,
  slug: undefined,
  email: undefined,
  actionShare: undefined,
  light: false,
  isProfile: false,
};

export default Share;
