import React, { SVGProps } from 'react';

interface SoundIconProp {
  percentage: number;
}

type SoundIconProps = SoundIconProp & SVGProps<SVGSVGElement>;

export const SoundIcon = ({ percentage, ...props }: SoundIconProps) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="5.9893"
      y={19 - (percentage * 34) / 200}
      width="2.5"
      height={(percentage * 34) / 100}
      fill="white"
      rx="1.25"
    />
    <rect
      x="12.9893"
      y={19 - (percentage * 26) / 200}
      width="2.5"
      height={(percentage * 26) / 100}
      fill="white"
      rx="1.25"
    />
    <rect
      x="19.9893"
      y={19 - (percentage * 54) / 200}
      width="2.5"
      height={(percentage * 54) / 100}
      fill="white"
      rx="1.25"
    />
    <rect
      x="26.9893"
      y={19 - (percentage * 26) / 200}
      width="2.5"
      height={(percentage * 26) / 100}
      fill="white"
      rx="1.25"
    />
    <rect
      x="33.9893"
      y={19 - (percentage * 34) / 200}
      width="2.5"
      height={(percentage * 34) / 100}
      fill="white"
      rx="1.25"
    />
  </svg>
);
