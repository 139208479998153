import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexContainer, Typography } from '../../styled';
import { ProjectItem } from './ProjectItem';
import { Project } from '../../../../shared/interfaces/projectInterfaces';
import Button from '../../../../shared/components/Button/Button';
import useWindowSize from '../../../../shared/hooks/useWindowSize';
import { useProjectList } from '../../../../shared/hooks/Organization/useProjectList';

export const ProjectList = () => {
  const navigate = useNavigate();
  const { projects } = useProjectList();
  const { width } = useWindowSize();
  const isTablet = width < 769;
  const handleRedirect = () => {
    navigate('/organization/projects');
  };
  return (
    <FlexContainer justify="start" direction="column" spacing="marginYSpaceLarge" gap="2_5">
      <Typography
        size={isTablet ? 'bodyLarge' : 'displayXSmall'}
        weight="700"
        lineHeight="lineHeight3"
        letterSpacing="letterSpacing3"
      >
        Projects
      </Typography>
      <FlexContainer gap="2_5" wrap>
        {projects.slice(0, 4).map((project: Project) => (
          <ProjectItem project={project} />
        ))}
      </FlexContainer>
      {projects.length > 4 && (
        <FlexContainer justify="center">
          <Button title="View All Projects" kind="outline" handleClick={handleRedirect} />
        </FlexContainer>
      )}
    </FlexContainer>
  );
};
