import { styled } from '../../../../config/theme';

export const MemberContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'start',
  background: '$monochromeBlack25',
  borderRadius: '$radius075',
  gap: '1rem',
  padding: '$spaceLarge',
  '@bp2': {
    maxWidth: '$10',
  },
  '@bp4': {
    maxWidth: '$16-5',
    flexDirection: 'row',
  },
  variants: {
    addMember: {
      true: {
        width: '100%',
        maxWidth: '28rem',
        direction: 'row',
        justifyContent: 'space-between',
        '@bp2': {
          maxWidth: '28rem',
        },
        '@bp4': {
          maxWidth: '28rem',
        },
      },
    },
  },
});

export const Avatar = styled('img', {
  objectFit: 'cover',
  borderRadius: '100%',
  width: '$4-25',
  height: '$4-25',
});
export const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  background: '$blurple',
  height: '1.8rem',
  width: '1.8rem',
  cursor: 'pointer',
});
