import React, { useContext } from 'react';
import { Typography, TypographyH3, TypographyP } from '../../Expert/styled';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import { keyExtractor } from '../../../shared/utils/helper';
import Card from '../../../shared/components/Card/Card';
import { QuestionsContainer } from '../../Organization/default/styled';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import { FlexContainer } from '../../Organization/styled';
import SaveOrRecordButtons from '../SaveOrRecordButtons/SaveOrRecordButtons';

const RecordingOpinions = () => {
  const { projectQuestions } = useContext(ProjectsContext);
  const { status } = useContext(VideoRecordingContext);
  const { width } = useWindowSize();
  const areButtonsVisible =
    status !== 'init' && (status === 'capturing' || status === 'paused' || status === 'captured');
  return (
    <FlexContainer direction="column" justify="between" css={{ height: '100%', maxHeight: 520 }}>
      <div>
        <Typography
          type={width < 1440 ? 'displayXSmall2' : 'displayXSmall'}
          weight="700"
          lineHeight="lineHeight3"
          letterSpacing="letterSpacing3"
          css={{ paddingY: '$spaceLarge' }}
        >
          Recording Opinion
        </Typography>
        <TypographyH3
          size="displayXXSmall"
          weight="700"
          lineHeight="lineHeight3"
          letterSpacing="letterSpacing3"
          css={{ paddingBottom: '$spaceLarge' }}
        >
          Questions
        </TypographyH3>
        <QuestionsContainer recordingModal css={{ maxHeight: areButtonsVisible ? 334 : undefined }}>
          {projectQuestions.map((question) => (
            <Card type="questions" key={keyExtractor()}>
              <TypographyP
                size="bodyXSmall"
                lineHeight="lineHeight1"
                letterSpacing="letterSpacing6"
                color="monochromeBlack400"
                css={{ margin: '12px' }}
              >
                {question.text}
              </TypographyP>
            </Card>
          ))}
        </QuestionsContainer>
      </div>
      {status !== 'init' &&
        (status === 'capturing' || status === 'paused' || status === 'captured') && (
          <SaveOrRecordButtons />
        )}
    </FlexContainer>
  );
};

export default RecordingOpinions;
