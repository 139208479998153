import { styled } from '../../config/theme';

export const TextBreadcrumb = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2 ',

  variants: {
    kind: {
      orderBy: {
        alignSelf: 'center',
        fontFamily: '$poppins',
        fontSize: '13px',
        fontWeight: '$weight600'
      }
    }
  }
});
