import React, { useContext, useRef, useState } from 'react';
import Popover from '../../Popover/Popover';
import TagSelectorContent from './TagSelectorContent';
import { PoppoverTrigger } from './styled';
import Search from '../../../../assets/images/icons/search.svg';
import Icon from '../../Icon/Icon';
import AppContext from '../../../context/app/AppContext';
import TagList from './TagList';
import useWindowSize from '../../../hooks/useWindowSize';

interface Props {
  expertiseError: boolean;
}

const TagSelectorPopover = ({ expertiseError }: Props) => {
  const { state: { userData } } = useContext(AppContext);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const ref = useRef(null);
  const { expertise, userId } = userData;
  const { width } = useWindowSize(ref);

  const disablePopover = expertise && expertise.length >= 3;

  const handleClick = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const triggerInput = (
    <PoppoverTrigger ref={ref} onClick={() => handleClick()}>
      Select your areas of expertise*
      <Icon img={Search} height="16" />
    </PoppoverTrigger>
  );

  const content = <TagSelectorContent handleClosePopover={handleClosePopover} />;

  return (
    <>
      <Popover
        trigger={triggerInput}
        content={content}
        open={popoverOpen}
        width={width}
        withoutArrow
        closePopover={handleClosePopover}
        disabled={disablePopover}
      />
      {userId && (
        <TagList
          tags={expertise || []}
          userId={parseInt(userId, 10)}
          expertiseError={expertiseError}
          sameUser
        />
      )}
    </>
  );
};

export default TagSelectorPopover;
