import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useWindowSize } from '@uidotdev/usehooks';
import useGetToken from '../../../shared/hooks/useGetToken';
import { ExpertiseContainer, ExpertiseForm, InputContainer, SubmitButton } from '../styles';
import { Error } from '../../../shared/components/Forms/InputError/styled';
import { Title, Text } from '../../../shared/components/Typography/styled';
import Button from '../../../shared/components/Button/Button';
import AppContext from '../../../shared/context/app/AppContext';
import ArrowRightIcon from '../../../assets/images/icons/arrow-right-white.svg';
import { ProfileService } from '../../../services/ProfileService/ProfileService';
import { ResearchGate, LinkedinLink } from '../../../shared/utils/globalText';
import { FormikInput, PlaceholderWithIcon } from '../../settings/components/PublicProfile/styled';

interface SocialMediaModel {
  next: () => void;
  loadingBtn?: boolean;
}

const SocialMedia: React.FC<SocialMediaModel> = ({ next, loadingBtn }) => {
  const authenticationToken = useGetToken();
  const { state, dispatch } = useContext(AppContext);
  const { userData } = state;
  const { linkedinLink, researchGate } = state.userData;
  const { width } = useWindowSize();
  const linkedinPlaceholder =
    width && width > 720 ? 'Enter your LinkedIn profile URL' : 'Link Linkedin profile';
  const researchGatePlaceholder =
    width && width > 720 ? 'Enter your ResearchGate profile URL' : 'ResearchGate account';

  const initialValues = {
    researchGate: researchGate || ResearchGate,
    linkedinLink: linkedinLink || LinkedinLink,
  };
  const FormSchema = Yup.object().shape({
    researchGate: Yup.string(),
    linkedinLink: Yup.string(),
  });

  const onSubmit = async (original: any) => {
    const values = { ...original };
    if (values.researchGate === ResearchGate || values.researchGate === '')
      values.researchGate = null;
    if (values.linkedinLink === LinkedinLink || values.researchGate === '')
      values.linkedinLink = null;

    try {
      const params = {
        authenticationToken,
        dataUser: { ...values, userId: userData.userId },
      };
      const response = await ProfileService.updateUser({ ...params });
      dispatch({ type: 'SET_USER', payload: response });
      next();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error', error);
    }
  };

  return (
    <ExpertiseContainer>
      <Title css={{ paddingBottom: '$spaceSmall', textAlign: 'left' }}>Your social links</Title>
      <Text css={{ paddingBottom: '$spaceLarge', textAlign: 'left' }}>
        Please add your LinkedIn and ResearchGate profiles to share your expertise
      </Text>

      <ExpertiseForm>
        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <InputContainer>
                <PlaceholderWithIcon icon="researchGate">
                  <FormikInput
                    id="researchGate"
                    name="researchGate"
                    placeholder={researchGatePlaceholder}
                    onChange={(e: any) => {
                      let { value } = e.target;
                      if (value.length === 1) value = ResearchGate + e.target.value;
                      if (value.length > ResearchGate.length)
                        setFieldValue('researchGate', value.replace(/\s/g, ''));
                      if (value.length === ResearchGate.length) setFieldValue('researchGate', '');
                    }}
                  />
                </PlaceholderWithIcon>
                {errors.researchGate && touched.researchGate && (
                  <Error>{errors.researchGate as string}</Error>
                )}
                <PlaceholderWithIcon icon="linkedin">
                  <FormikInput
                    id="linkedinLink"
                    name="linkedinLink"
                    placeholder={linkedinPlaceholder}
                    onChange={(e: any) => {
                      let { value } = e.target;
                      if (value.length === 1) value = LinkedinLink + e.target.value;
                      if (value.length > LinkedinLink.length)
                        setFieldValue('linkedinLink', value.replace(/\s/g, ''));
                      if (value.length === LinkedinLink.length) setFieldValue('linkedinLink', '');
                    }}
                  />
                </PlaceholderWithIcon>
                {errors.linkedinLink && touched.linkedinLink && (
                  <Error>{errors.linkedinLink as string}</Error>
                )}
              </InputContainer>
              <SubmitButton>
                <Button
                  kind="login"
                  title="Next"
                  type="submit"
                  iconCustom={ArrowRightIcon}
                  iconPosition="right"
                  renderProps={{ disabled: isSubmitting }}
                  loading={loadingBtn}
                  marginL
                  css={{ width: '100%', height: '56px', maxWidth: '170px', marginBottom: 0 }}
                />
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </ExpertiseForm>
    </ExpertiseContainer>
  );
};

SocialMedia.defaultProps = {
  loadingBtn: false,
};

export default SocialMedia;
