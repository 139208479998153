import { styled } from '../../../config/theme';

export const WrapperTag = styled('div', {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '$spaceXSmall',

  variants: {
    kind: {
      'one-line': {
        overflow: 'hidden',
        height: 'px',
        '.gat-tag-one': {
          wordBreak: 'break-all',
        },
      },
      normal: {},
    },
  },
});

export const FileDeleteButton = styled('div', {
  background: 'transparent',
  position: 'absolute',
  padding: '1.5rem 1.5rem 0 0',
  top: '0',
  right: '0',
  cursor: 'pointer',
  '& img': {
    margin: '0',
    marginTop: '5px',
  },
  '&:hover': {
    background: 'transparent',
    stroke: 'rgb(164, 212, 27)',
  },
});
export const Tag = styled('div', {
  placeSelf: 'center',
  margin: '0.25rem 0.5rem 0 0',
  paddingRight: '0.5rem',
  border: '1px solid $bodyPale',
  backgroundColor: '$bodyPale',
  borderRadius: '4px',
  fontSize: '$bodyXSSmall',
  color: '$offBlack',
  cursor: 'pointer',
  fontWeight: '$weight500',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  transition: 'all 0.3s ease-in-out',
  textTransform: 'capitalize',
  maxWidth: 'auto',
  display: 'flex',
  alignItems: 'center',
  '&:last-child': {
    marginRight: '0',
  },
  '&:hover': {
    backgroundColor: '$bodyPale',
    opacity: 0.9,
  },
  variants: {
    approved: {
      false: {
        border: '1px solid #D9DBE9',
        backgroundColor: 'transparent',
      },
    },
    type: {
      selection: {
        backgroundColor: '$offWhite',
        boxShadow: '$tagBase',
        border: 'none',
        padding: '3px 8px',
        color: '$monochromeBlack',
        fontSize: '$bodySmall',
        fontStyle: 'normal',
        fontWeight: '$weight500',
        lineHeight: 'normal',
        letterSpacing: '$letterSpacing1',
        '&:hover': {
          color: '$blurple',
          backgroundColor: '$bodyPale',
        },
      },
      project: {
        backgroundColor: '$monochromeBlack25',
        border: 'none',
        padding: '3px 8px',
        color: '$monochromeBlack',
        fontSize: '$bodySmall',
        fontStyle: 'normal',
        fontWeight: '$weight500',
        lineHeight: 'normal',
        letterSpacing: '$letterSpacing1',
        '&:hover': {
          color: '$blurple',
          backgroundColor: '$bodyPale',
        },
      },
      selected: {
        color: '$blurple',
        backgroundColor: '$bodyPale',
        border: 'none',
        padding: '3px 8px',
        boxShadow: '$tagBase',
        fontSize: '$bodySmall',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '$letterSpacing1',
      },
      projectList: {
        backgroundColor: '$monochromeBlack50',
        border: 'none',
        color: '$monochromeBlack',
        fontSize: '$bodyXSSmall',
        fontStyle: 'normal',
        fontWeight: '$weight500',
        lineHeight: 'normal',
        letterSpacing: '$letterSpacing1',
        cursor: 'default',
        '&:hover': {
          backgroundColor: '$monochromeBlack50',
          opacity: 'unset',
        },
      },
    },
  },
});

export const TextTag = styled('span', {
  paddingLeft: '0.5rem',
  paddingTop: ' 0.188rem',
  paddingBottom: ' 0.188rem',
  color: '#262338',
  variants: {
    isCardPeople: {
      true: {
        display: 'inline-block',
        maxWidth: '8.125rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
});

export const WrapTopics = styled('div', {
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '88px',
  height: 'auto',
  padding: '0.5rem',
  borderRadius: '$radius075',
  textAlign: 'center',
  border: '1px solid $line',
  cursor: 'pointer',
  transition: 'all .3s ease-in-out',
  '&:hover': {
    boxShadow: '$shadowBase',
    borderColor: '$body',
    '& .label-sub': {
      color: '$body !important',
    },
  },
  '& .label-sub': {
    cursor: 'pointer',
    wordBreak: ' break-word',
  },
  '@bp2': {
    padding: '1rem',
  },
  '@bp3': {
    // maxWidth: '167px',
    maxHeight: '88px',
  },
  '@bp4': {
    padding: '1.5rem 1rem',
  },
  variants: {
    active: {
      true: {
        borderColor: '$body',
        '& .label-sub': {
          color: '$body !important',
        },
      },
    },
  },
});

export const WrapTopicsEmpty = styled(WrapTopics, {
  flexGrow: 1,
  display: 'none',
  borderWidth: 0,
  pointerEvents: 'none',
  variants: {
    visible: {
      hide: {
        display: 'none',
      },
      show: {
        display: 'flex',
      },
    },
  },
});

export const SubWrapperTopics = styled('div', {
  width: '100%',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$spaceSmall',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
});

export const Label = styled('label', {
  color: 'inherit',
  fontSize: '$bodyXSmall',
  fontWeight: '$weight600',
  padding: '0 6px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': '2',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
});

export const TopicsContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginBottom: '0.5rem',
  variants: {
    screen: {
      mobile: {
        maxHeight: '100px',
        overflow: 'hidden',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: 3,
          height: 7,
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '5rem',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '$line',
          borderRadius: '5rem',
          border: '5px line solid',
        },
      },
    },
    gap: {
      '0_25': {
        gap: '0_25rem',
      },
    },
  },
});

export const TopicsDropDown = styled('div', {
  position: 'absolute',
  width: '100%',
  right: '0',
  height: 'calc(100vh - 50px)',
  background: '$overlay',
  '@bp2': {
    height: 'auto',
    width: '300px',
    background: 'transparent',
    top: 55,
  },
});

export const TopicsBrowserGrid = styled('div', {
  display: 'inline-flex',
  borderBottom: 'solid',
  borderColor: '#D9DBE9',
  borderBottomWidth: '1px',
  padding: 0,
  width: '100%',
  height: '200px',
  transition: 'all 0.2s ease-in-out',
  variants: {
    addTopic: {
      true: {
        height: 0,
      },
    },
  },
});

export const Section = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '32%',
  overflow: 'hidden',
  overflowY: 'scroll',
  paddingX: '$spaceXSmall',
  '&::-webkit-scrollbar': {
    width: 3,
    height: 7,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '5rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$line',
    borderRadius: '5rem',
    border: '5px line solid',
  },
  '&.w-35p': {
    width: '36%',
  },
  '&.simple': {
    width: '100%',
  },
  '&.line': {
    borderRight: '1px solid #D9DBE9',
  },
});

export const List = styled('ul', {
  listStyleType: 'none',
});

export const ListItemStyled = styled('li', {
  display: 'flex',
  padding: '3px 4px',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: '$poppins',
  fontWeight: 500,
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight2',
  cursor: 'pointer',
  marginBottom: 7,
  '&.selected': {
    background: '#7289DA1A',
    borderRadius: '$radius03',
  },
  '&.used': {
    color: '$blurple',
    fontWeight: '700',
  },
  variants: {
    bold: {
      true: {
        fontWeight: 700,
      },
    },
  },
});

export const SubList = styled('ul', {
  listStyleType: 'none',
});

export const TagNameContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textTransform: 'capitalize',
});

export const TagBackButton = styled('button', {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontFamily: '$poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  color: '$blurple',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  marginBottom: '15px',
  textAlign: 'left',
  padding: 0,
});

export const Line = styled('hr', {
  border: 'none',
  height: 1,
  background: '#D9DBE9',
  width: '100%',
  marginY: '$spaceSmall',
});

export const SameUserEventsContainer = styled('div', {
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const EventsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
});

export const TagsContainer = styled('div', {
  display: 'inline-flex',
  flexWrap: 'wrap',
  width: '100%',
  maxWidth: '300px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: 10,
  '@bp2': {
    maxWidth: '100%',
  },
});

export const NoTopicsContainer = styled('div', {
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  '@bp0': {
    marginBottom: '20px',
  },
  '@bp1': {
    marginBottom: 0,
  },
  '@bp3': {
    marginBottom: '15px',
  },
  '& p': {
    fontFamily: '$poppins',
    fontSize: '11px',
    fontWeight: 500,
    color: '#6E7191',
    marginLeft: 5,
    marginTop: 3,
  },
});

export const TopicsNotApproved = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px',
  p: {
    '&.not-approved': {
      fontFamily: '$poppins',
      fontWeight: 500,
      fontSize: '12px',
      marginBottom: 0,
      marginTop: 0,
    },
  },
  variants: {
    noMargins: {
      true: {
        marginTop: 0,
      },
    },
  },
});

export const InputWrapper = styled('div', {
  background: '$input',
  width: '100%',
  display: 'flex',
  borderRadius: '$radius05',
  alignItems: 'center',
  position: 'relative',
  minHeight: '2.5rem',
  button: {
    background: '$input',
  },
  variants: {
    activeWrite: {
      true: {
        backgroundColor: '$input',
        border: '$body 1px solid',
        '.gat-arrow-icon': {
          display: 'none',
          '@bp2': {
            display: 'flex',
          },
        },
      },
    },
    kind: {
      'not-logged': {
        width: '80%',
        marginLeft: '0px',
        '@bp3': { maxWidth: '100%' },
      },
    },
  },
});

export const AddTopicContainer = styled('div', {
  display: 'inline-flex',
  padding: 0,
  alignItems: 'start',
  marginTop: '20px',
  '& span': {
    '&.add-topic': {
      fontFamily: '$poppins',
      fontWeight: 500,
      fontSize: '14px',
    },
  },
});

export const FlexContainer = styled('div', {
  display: 'flex',
  padding: 0,
  variants: {
    align: {
      center: {
        alignItems: 'center',
      },
      start: {
        alignItems: 'flex-start',
      },
      end: {
        alignItems: 'flex-end',
      },
    },
    justify: {
      center: {
        justifyContent: 'center',
      },
      around: {
        justifyContent: 'space-around',
      },
      between: {
        justifyContent: 'space-between',
      },
      start: {
        justifyContent: 'start',
      },
    },
    fullWidth: {
      true: {
        display: 'inline-flex',
        width: '100%',
      },
    },
  },
});

export const IconButtonContainer = styled('div', {
  stroke: '$blurple',
  display: 'flex',
  width: '18px',
  height: '18px',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '7px, 5px, 7px, 5px',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$blurple',
    stroke: '#FCFCFC',
    padding: '7px, 5px, 7px, 5px',
  },
});
