import React, { useContext, useState } from 'react';
import Button from '../../../shared/components/Button/Button';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import Modal from '../../../shared/components/Modal/Modal';
import Card from '../../../shared/components/Card/Card';
import { TypographyH2, TypographyP } from '../../Expert/styled';
import { SaveOrRecordContainer } from '../styles';
import SaveButton from './SaveButton';
import { useLandscape } from '../../../shared/hooks/useLandscape';

const RecordAgain = () => {
  const { setStatus, setRecordedChunks, blob } = useContext(VideoRecordingContext);
  const [isOpen, setIsOpen] = useState(false);
  const { isLandscapeMobile } = useLandscape();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleRetakeRecording = () => {
    setIsOpen(!isOpen);
    setStatus('init');
    setRecordedChunks([]);
  };

  const handleSaveOpinion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        title="Record Again"
        size="medium"
        kind="outline"
        renderProps={{ onClick: handleOpen, disabled: !blob }}
        css={{
          width: '50%',
          height: '40px',
        }}
      />
      <Modal kindOverlay="gat-modal-overlay--blur" isOpen={isOpen} onClose={handleOpen}>
        <Card
          type={isLandscapeMobile ? 'retakeRecordingModalLandscape' : 'recordingModal'}
          css={{ display: 'flex', flexDirection: 'column' }}
        >
          <TypographyH2
            type="displayXSmall"
            weight="600"
            lineHeight="$lineHeight15"
            letterSpacing="letterSpacing3"
            css={{ marginBottom: '$spaceXSmall2' }}
          >
            Are you sure you want to retake your recording?
          </TypographyH2>
          <TypographyP
            size="bodySmall"
            weight="$normal"
            lineHeight="lineHeight14"
            letterSpacing="letterSpacing3"
            css={{ color: 'hsla(235, 14%, 50%, 1)' }}
          >
            Your current video progress will be lost.
          </TypographyP>
          <SaveOrRecordContainer isModal={!isLandscapeMobile} isLandscapeMobile={isLandscapeMobile}>
            <Button
              title="Retake Recording"
              css={{
                height: '40px',
              }}
              renderProps={{ onClick: handleRetakeRecording }}
            />
            <SaveButton handleSaveModal={handleSaveOpinion} />
          </SaveOrRecordContainer>
        </Card>
      </Modal>
    </>
  );
};

export default RecordAgain;
