import React from 'react';
import { Credits } from './CreditsContainer';
import { Separator } from './styles';
import { TableContainer } from '../Table';

export const YourCreditsSection = () => (
  <>
    <Credits />
    <Separator space='1'/>
    <TableContainer />
  </>
);
