import React from 'react';
import Button from '../../../shared/components/Button/Button';
import { BoxFooter, RightButtons } from '../styles';
import arrowBackIcon from '../../../assets/images/icons/arrow-back-light.svg';
import arrowRightIcon from '../../../assets/images/icons/arrow-right-white.svg';
import { StepOnboarding } from '../../../shared/interfaces/interfaces';

interface FooterButtonsModel {
  actionBack: () => void;
  actionNext: () => void;
  actionSkip?: () => void;
  dataStep: StepOnboarding;
  btnDisabled?: boolean;
  btnDisabledBack?: boolean;
  loadingBtn?: boolean;
  loadingPrevBtn?: boolean;
  saveButton?: boolean;
  saveAction?: () => void;
}

const FooterButtons: React.FunctionComponent<FooterButtonsModel> = ({
  actionBack, actionNext, actionSkip, dataStep, btnDisabled, loadingBtn, loadingPrevBtn,
  btnDisabledBack, saveButton, saveAction
}) => {
  const css = dataStep.step === "3/3" ? { maxWidth: '170px', marginRight: '20px' } : {};

  const handleSkip = () => {
    if (actionSkip) {
      actionSkip();
    }
  };

  const handleNext = () => {
    if (actionNext) {
      actionNext();
    }
  };

  const handleSave = () => {
    if (saveAction) {
      saveAction();
    }
  };

  const loadingDisabled = loadingBtn || loadingPrevBtn;

  return (
    <BoxFooter>
      <Button
        kind='onBoardingModalTransparent'
        title='Previous'
        loading={loadingPrevBtn}
        iconCustom={arrowBackIcon}
        renderProps={{ onClick: actionBack, disabled: loadingDisabled || btnDisabledBack}}
        css={{ borderRadius: '8px', paddingLeft: '0', '& img': { marginLeft: 0 } }}
      />
      {dataStep.required && (
        <Button
          kind='onBoardingModal'
          title='Next'
          loading={loadingBtn}
          iconCustom={arrowRightIcon}
          renderProps={{
            onClick: handleNext, disabled: loadingDisabled || btnDisabled
          }}
        />
      )}
      {!dataStep.required && !saveButton && (
        <Button
          title='Skip for now'
          handleClick={() => handleSkip()}
          kind='onBoardingModalSkip'
        />
      )}
      { saveButton && (
        <RightButtons>
          <Button
            title='Skip for now'
            handleClick={() => handleSkip()}
            kind='onBoardingModalSkip'
            css={css}
          />
          <Button
            title='Save'
            handleClick={() => handleSave()}
            kind='onBoardingModal'
            css={css}/>
        </RightButtons>
      )}
    </BoxFooter>
  );
};

FooterButtons.defaultProps = {
  actionSkip: undefined,
  btnDisabled: true,
  loadingBtn: false,
  loadingPrevBtn: false,
  btnDisabledBack: false,
  saveButton: false,
  saveAction: () => {},
};

export default FooterButtons;
