import { styled } from '../../../../config/theme';

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '$monochromeBlack',
  zIndex: 998,
});

export const ModalContainer = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
});

export const ModalContent = styled('div', {
  backgroundColor: '$monochromeWhite',
  padding: '$spaceGap2_25',
  borderRadius: '$radius1',
  boxShadow: '$shadowBase',
  marginX: '$spaceSmall',
});

export const ModalContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$radius05',
  border: '1px dashed $grayscaleLine',
  alignItems: 'center',
  padding: '$spaceGap2_25',
  gap: '$spaceLarge',
});

export const FileButton = styled('button', {
  appearance: 'none',
  padding: '10px 20px',
  fontSize: '$bodyXSmall',
  fontWeight: '$weight500',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  color: '$monochromeWhite',
  backgroundColor: '$blurple',
  borderRadius: '$radius05',
  cursor: 'pointer',
  border: 'none',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '$hoverBtn',
  },
});

export const FileItemContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '$monochromeBlack25',
  padding: '$spaceSmall',
  minWidth: '20px',
  minHeight: '$1',
  borderRadius: '$radius05',
  gap: '$spaceXSmall',
});

export const FileItemInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '$1',
  gap: '$spaceXSmall',
});

export const FileDeleteButton = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$radius03',
  cursor: 'pointer',
  padding: '0.125rem',
});
