import React, { createContext } from 'react';

export interface ContextProps {
  userType: string;
  login: boolean;
  setUserType: React.Dispatch<React.SetStateAction<string>>;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: ContextProps = {
  userType: '',
  login: false,
  setUserType: () => {},
  setLogin: () => {},
};

const AuthenticationContext = createContext<ContextProps>(initialState as ContextProps);

export default AuthenticationContext;