import React from "react";
import { getInitials } from "../../../utils/helper";
import { Initials, InitialsPattern } from "../styles";

interface AvatarModel {
  name: string;
  type?: string;
  pattern?: boolean;
}

type AvatarProps = AvatarModel &
  React.ComponentProps<typeof Initials> & {
    type?: string;
  };

const NameInitials: React.FunctionComponent<AvatarProps> = ({ name, type, pattern }) => {
  const classInitial = 'gat-avatar-initials';

  if (pattern) {
    return (
      <InitialsPattern
        className={classInitial}
        color='body'
        type={type}
        isCircle
      >
        {getInitials(name)}
      </InitialsPattern>

    );
  }

  return (
    <Initials className={classInitial} type={type} >
      {getInitials(name)}
    </Initials>
  );
};

NameInitials.defaultProps = {
  type: undefined,
  pattern: undefined,
};

export default NameInitials;