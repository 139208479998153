import React, { useState, useContext, useEffect, useRef } from 'react';
import { ItemResult, SelectWrapper, List, WrapText, Span } from '../styled';
import Icon from '../../Icon/Icon';
import RadioButton from '../../RadioButton/RadioButton';
import arrowDownIcon from '../../../../assets/images/icons/arrow-down.svg';
import AppContext from '../../../context/app/AppContext';
import { dataFilter, orderByFilter } from '../dataFilter';

const ResultsFilter = (props: { orderBy?: boolean }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [filter1, setFilter] = useState<any>(null);
  const { state, dispatch } = useContext(AppContext);
  const { filterContent } = state as any;
  const filterAuthor = filter1 && 'author' in filter1;
  const { orderBy } = props;
  const data = orderBy ? orderByFilter : dataFilter;
  const kindFilter = orderBy ? 'orderBy' : undefined;
  const selectWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectWrapperRef.current && !selectWrapperRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (orderBy) {
      const orderByName = filterContent.orderBy;
      setFilter({ [orderByName]: true });
      return;
    }
    const valueFilter = filterContent?.type || 'content';

    setFilter({ [valueFilter]: true });

    if (filterAuthor && filterContent?.page === undefined) {
      setFilter({ content: true });
    }

    if (filterAuthor && filterContent?.category === 'tag' && filterContent?.change) {
      setFilter({ content: true });
      dispatch({
        type: 'SET_FILTER',
        payload: { ...filterContent, type: 'content', change: undefined },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterContent, orderBy]);

  const handleFilter = async (event: any) => {
    event?.stopPropagation();
    const eventValue = event?.target?.checked;
    const key = event?.target?.name;
    let payload;
    setOpen(false);
    setFilter({ [key]: eventValue });
    if (orderBy) {
      payload = { ...filterContent, type: 'content', page: 1, orderBy: key };
    } else {
      payload = { ...filterContent, type: key, page: 1, change: undefined };
    }
    dispatch({
      type: 'SET_FILTER',
      payload,
    });
  };

  const getText = () => {
    if (filter1) {
      const secondKey = Object.keys(filter1)[0];
      const secondWord = data.find((i) => i.value === secondKey);
      return (
        <WrapText kind={kindFilter}>
          <Span kind={kindFilter}>{`${secondWord?.name || ''}`}</Span>
        </WrapText>
      );
    }

    return <div />;
  };

  return (
    <SelectWrapper ref={selectWrapperRef} onClick={() => setOpen(!open)} kind={kindFilter}>
      {getText()}
      <Icon className="gat-arrow-icon" img={arrowDownIcon} width="9" height="6" alt="More" />
      <List empty={!open} kind={kindFilter}>
        {data.map((item) => (
          <ItemResult key={item.value}>
            <RadioButton
              id={item.value}
              label={`${item?.name}`}
              name={`${item.value}`}
              value={filter1 ? filter1[`${item.value}`] : ''}
              checked={filter1 ? filter1[`${item.value}`] : false}
              disabled={false}
              onClick={(e) => handleFilter(e)}
            />
          </ItemResult>
        ))}
      </List>
    </SelectWrapper>
  );
};

ResultsFilter.defaultProps = {
  orderBy: false,
};

export default ResultsFilter;
