import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import * as CompanyEmailValidator from 'company-email-validator';
import { InfoLabel, Step3NameLastName, InfoLabelLink} from './styled';
import useWindowSize from '../../../../shared/hooks/useWindowSize';
import Button from '../../../../shared/components/Button/Button';
import ArrowIcon from '../../../../assets/images/icons/arrow-right-white.svg';
import StepPassword from '../../../../shared/components/passwordWithValidations/StepPassword';
import { OrganizationSignUpModel } from '../../../../shared/context/app/types';
import { RegisterServiceExpert } from '../../../../services/AuthService/AuthService';
import ProcessAuthentication from '../../../Authentication/components/ProcessAuthentication/ProcessAuthentication';
import { useExpert } from '../../../../shared/hooks/useExpert';
import AppContext from '../../../../shared/context/app/AppContext';
import { FormInput } from '../../../../shared/components/Input/FormInput';

export const Step3 = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width < 720;
  const { expert } = useExpert();
  const { dispatch } = useContext(AppContext);

  const handleSubmit = async (data: OrganizationSignUpModel) => {
    const res = await RegisterServiceExpert.register(data, expert);
    ProcessAuthentication({ res, dispatch, navigate });
  };

  const AuthenticationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    organization: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required').test(
      'Corporate email',
      'Please enter a corporate email',
      (value) => {
        if (value) {
          return CompanyEmailValidator.isCompanyEmail(value);
        }
        return false;
      },
    ),
  });

  return (
    <Formik
      initialValues={{ email: '', password: '', firstName: '', lastName: '', organization: '', user_type: 'seeker' }}
      validationSchema={AuthenticationSchema}
      onSubmit={(
        values: OrganizationSignUpModel,
        { setSubmitting }: FormikHelpers<OrganizationSignUpModel>,
      ) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ errors, isValid, touched }) => (
        <Form>
          <InfoLabel>
            Lastly, please create a login or sign-in to get access to your project dashboard.{' '}
          </InfoLabel>

          <Step3NameLastName>
            <FormInput
              containerCss={{ marginRight: !isMobile ? '10px' : '' }}
              css={{ marginTop: '$spaceLarge' }}
              errors={errors}
              touched={touched}
              placeholder="Name"
              name="firstName"
              hideErrorLabel
            />

            <FormInput
              containerCss={{ marginLeft: !isMobile ? '10px' : '' }}
              css={{ marginTop: '$spaceLarge' }}
              errors={errors}
              touched={touched}
              placeholder="Last Name"
              name="lastName"
              hideErrorLabel
            />
          </Step3NameLastName>

          <FormInput
            errors={errors}
            touched={touched}
            placeholder="Organization Name"
            name="organization"
            hideErrorLabel
          />
          <FormInput
            errors={errors}
            touched={touched}
            placeholder="Organization Email"
            name="email"
            hideErrorLabel
          />
          <StepPassword name="password" hasError={!!errors.password && !!touched.password} />
          <InfoLabel
            css={{ fontWeight: '$weight700', color: '$blurple', cursor: 'pointer' }}
            onClick={() => navigate('/authentication?invite=seeker')}
          >
            I already I have a login
          </InfoLabel>
          <Button
            type="submit"
            css={{ width: '100%', maxWidth: '100%', marginBottom: '12px', marginTop: '40px' }}
            title="Next"
            iconCustom={ArrowIcon}
            iconPosition="right"
            renderProps={{ disabled: !isValid || !touched.email }}
          />
          <InfoLabel>
            By registering you accept the{' '}
            <InfoLabelLink target="_blank" href="https://www.gathersight.com/terms">
              Terms of use
            </InfoLabelLink>
          </InfoLabel>
        </Form>
      )}
    </Formik>
  );
};
