/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { keyExtractor } from '../../utils/helper';
import { TagsInput, TagDiv, TagCloseIcon, ListTag, AddTag } from './styles';

interface TagsInputModel {
  onChange?: (event: any) => void;
  dataTags?: Array<number | string>;
}

const TagsInputComponent = ({
  onChange, dataTags
}: TagsInputModel) => {
  const [tags, setTags] = useState<Array<number | string>>(dataTags || []);
  const [currentText, setCurrentText] = useState<string>('');
  const [isWrite, setWrite] = useState<boolean>(false);

  const removeTags = async (indexToRemove: number) => {
    const newTags = [...tags.filter((_, index) => index !== indexToRemove)];
    await setTags(newTags);
    if (onChange) {
      await onChange(newTags);
    }
  };

  const addTags = async (event: any) => {
    if (event?.target?.value !== "") {
      const newTags = [...tags, event?.target?.value];

      await setTags(newTags);
      if (onChange) {
        await onChange(newTags);
      }
      await setCurrentText('');
      await setWrite(false);
    }
  };

  useEffect(() => {
    setTags(dataTags || []);
  }, [dataTags]);

  return (
    <TagsInput className='tags-input'>
      <ListTag>
        {tags.map((tag, index) => (
          <TagDiv key={keyExtractor()}>
            <span className='tag-title'>{tag}</span>
            <TagCloseIcon className='tags-close' onClick={() => removeTags(index)}>
              x
            </TagCloseIcon>
          </TagDiv>
        ))}
      </ListTag>
      {!isWrite && (<AddTag onClick={() => setWrite(true)} >+</AddTag>)}
      {isWrite && (
        <input
          type='text'
          value={currentText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentText(e?.target?.value)}
          onKeyUp={event => event?.key === 'Enter' ? addTags(event) : null}
        />
      )}
    </TagsInput>
  );
};

TagsInputComponent.defaultProps = {
  onChange: undefined,
  dataTags: [],
};

export default TagsInputComponent;

