import { styled } from '../../../config/theme';

export const WrapperTypeResource = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'hsl(232, 33%, 95%, 1)',
  width: '2rem',
  height: '2rem',
  color: '$offBlack',
  cursor: 'pointer',
  borderRadius: '$radius05',
  transition: 'background 0.3s ease-in-out',
  '& img': {
    margin: 0
  },
  '&:hover': {
    backgroundColor: '$hoverBtn'
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$offBlack'
      },
    },
  }
});
