import React from 'react';
import { MenuListProps } from 'react-select';
import { SelectWrapper } from './styled';


type MenuListModel = MenuListProps & any & {};

const MenuListCustom: React.FC<MenuListModel> = (props) => {
  const { children } = props;

  return (
    <SelectWrapper {...props}>
      {children}
    </SelectWrapper>
  );
};

MenuListCustom.defaultProps = {};

export default MenuListCustom;
