import { PropertyValue } from '@stitches/react';
import { styled, keyframes } from '../../../config/theme';

export const hideBlurhash = keyframes({
  '0%': { opacity: '1' },
  '25%, 50%': { opacity: '.5' },
  '75%, 100%': { opacity: '0' },
});

export const revealBackground = keyframes({
  '0%': { opacity: '0', filter: 'blur(14px)' },
  '25%, 50%': { opacity: '.5', filter: 'blur(7px)' },
  '75%, 100%': { opacity: '1', filter: 'blur(0)' },
});

export const delayShow = keyframes({
  '0%': { opacity: '0' },
  '25%, 50%': { opacity: '.5' },
  '75%, 100%': { opacity: '1' },
});

export const WrapperCard = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$offWhite',
  padding: '1rem',
  borderRadius: '$radius1',
  border: '1px solid hsla(228, 33%, 97%, 1)',
  flexWrap: 'wrap',
  height: 'auto',
  order: 1,
  marginBottom: '$spaceLarge',

  '@bp3': {
    padding: '1.5rem',
  },
  variants: {
    little: {
      true: {
        marginBottom: 0,
        height: 'auto',
        minHeight: '24rem',
        width: '100%',
        '@bp2': {
          minHeight: '24rem',
        },
        '@bp3': {
          maxWidth: '38rem',
          minWidth: '22rem',
          minHeight: '26rem',
        },
        '@bp4': {
          minWidth: '25rem',
          minHeight: '23rem',
        },
      },
    },
    middle: {
      true: {
        marginBottom: 0,
        cursor: 'pointer',
        alignSelf: 'baseline',
        height: 'auto',
        margin: '0.7rem',
        maxWidth: 500,
      },
    },
    isSlider: {
      true: {
        maxWidth: '59.75rem',
      },
    },
    isLazy: {
      true: {
        opacity: '1',
        animation: `${delayShow} 2.5s 0s`,
        '@bp3': {
          padding: '1.5rem',
        },
      },
    },
  },
});

export const WrapperCardList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
});

export const CardHeader = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginBottom: '$spaceXSmall',
  ' .icon-read': {
    marginTop: 5,
    display: 'flex',
    alignItems: 'baseline',
    '@bp0': {
      marginTop: 0,
    },
  },
  '@bp0': {
    flexDirection: 'row',
  },
});

export const CardContent = styled('div', {
  fontSize: '$bodyXSmall',
  lineHeight: '1.3rem',
  letterSpacing: '$letterSpacing1',
  color: '$label',
  minHeight: '5.5rem',
  inlineSize: '100%',
  overflowWrap: 'break-word',

  '@bp2': {
    inlineSize: '100%',
  },
  '& h5': {
    margin: '0 0 0.25rem 0',
    fontSize: '$bodyXSmall',
    lineHeight: '1.25rem',
    letterSpacing: '$letterSpacing2',
    color: '$offBlack',
  },
  '@bp4': {
    minHeight: '5.25rem',
    lineHeight: '$lineHeight1',
    '& h5': {
      fontSize: '$bodySmall',
      lineHeight: '$lineHeight2',
    },
  },
  '@bp5': {
    fontSize: '$bodyCard',
  },
});

export const CardFooter = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  flex: 'auto',
  '@bp4': {
    flex: 1,
  },
});

export const WrapperSave = styled('div', {
  marginTop: '$spaceSmall',
  width: '30%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  variants: {
    full: {
      true: {
        width: '100%',
      },
    },
  },
});

export const RowTags = styled('div', {
  width: '70%',
  variants: {
    empty: {
      true: {
        width: '0',
      },
    },
  },
});

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  width: '100%',
  gap: '$spaceXSmall',
  '@bp0': {
    flexDirection: 'row',
  },
  variants: {
    showPlaylist: {
      true: {
        flexDirection: 'column-reverse',
        '@bp3': {
          flexDirection: 'row',
        },
      },
    },
  },
});

export const ContentLeft = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  height: 'auto',
  flex: 'auto',
  variants: {
    full: {
      true: {
        flex: 'auto',
        '@bp4': {
          maxWidth: '630px',
        },
      },
    },
    type: {
      normal: {
        '@bp0': {
          maxWidth: '70%',
          maxHeight: '200px',
          minHeight: '200px',
          flex: 2,
        },
        '@bp1': {
          minHeight: '140px',
        },
      },
      profile: {
        '@bp3': {
          maxWidth: '70%',
          maxHeight: '140px',
          minHeight: '140px',
          flex: 2,
        },
      },
    },
  },
  defaultVariants: {
    type: 'normal',
  },
});

export const ContentRight = styled('div', {
  width: '100%',
  height: '215px',
  minHeight: '215px',
  display: 'none',
  alignItems: 'center',
  position: 'relative',
  flex: 1,
  video: {
    width: '100% !important',
    height: '215px !important',
  },
  '.block': {
    position: 'absolute',
    top: 0,
    zIndex: 0,
  },
  variants: {
    visible: {
      true: {
        display: 'flex',
      },
    },
    type: {
      normal: {
        '@bp0': {
          maxWidth: '230px',
          maxHeight: '140px',
          minHeight: '140px',
          video: {
            width: '230px !important',
            height: '140px !important',
          },
        },
      },
      profile: {
        '@bp3': {
          maxWidth: '230px',
          maxHeight: '140px',
          minHeight: '140px',
          video: {
            width: '230px !important',
            height: '140px !important',
          },
        },
      },
    },
  },
  defaultVariants: {
    type: 'normal',
  },
});

export const BgImage = styled('div', {
  opacity: 0,
  width: '100%',
  height: '100%',
  minHeight: 'inherit',
  '& > div:not(.img-shimer)': {
    position: 'absolute !important',
    'will-change': 'opacity',
    opacity: '1',
    animation: `${hideBlurhash}  5s 2.5s forwards`,
  },
  '.onload-img': {
    display: 'none',
    zIndex: -1,
  },
});

export const ImgContent = styled('img', {
  width: `${(value: PropertyValue<'width'>) => ({ width: value })}`,
  height: `${(value: PropertyValue<'height'>) => ({ height: value })}`,
});

export const VideoContent = styled('video', {
  width: `${(value: PropertyValue<'width'>) => ({ width: value })}`,
  height: `${(value: PropertyValue<'height'>) => ({ height: value })}`,
});

export const TagsContainer = styled('div', {
  marginY: '$spaceSmall',
});
