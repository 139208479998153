import React from "react";
import NoContentWidget from "../../../shared/components/NoContentWidget/NoContentWidget";
import useGetCurrentUserSlug from "../../../shared/hooks/useGetCurrentUserSlug";
import CreatePlaylistWidget from "./CreatePlaylistWidget/CreatePlaylistWidget";

type PlaylistEmptyProps = { userSlug: string }

const PlaylistEmpty: React.FC<PlaylistEmptyProps> = ({userSlug}) => {
  const userLoggedSlug = useGetCurrentUserSlug();
  const myProfile = userSlug === userLoggedSlug;
  const message = "Hmm, that's a blow. We can't find any Knowledge playlist for this person. Sorry!";
  const component = myProfile ? <CreatePlaylistWidget /> : <NoContentWidget title={message}/>;
  return (component);
};

export default PlaylistEmpty;