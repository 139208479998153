import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useTransition, animated, config } from 'react-spring';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import AppContext from '../../../shared/context/app/AppContext';
import WidgetTwoColumns from '../../../shared/components/Widget/WidgetTwoColumns';
import Icon from '../../../shared/components/Icon/Icon';
import { KnowledgeService } from '../../../services/knowledge.service';
import { formatDate, returnSameName } from '../../../shared/utils/helper';
import { WidgetText } from '../../../shared/components/Widget/styles';
import { TextTitleKnowledge, DivWidgets, BoxTitle, TextEmpty, BoxTextEmpty, BoxRelative,
  BoxAbsolute, BoxTextEmptyMid} from '../styles';
import { LayoutContainer, LayoutCol } from '../../../shared/components/Layouts/styles';
import { LayoutColPeople } from '../../Profile/styled';
import { Knowledge, WhoAccess } from '../../../shared/interfaces/interfaces';
import PlaylistPrivate from '../PlaylistInvitation/PlaylistPrivate';
import SearchItems from './SearchItems/SearchItems';
import ItemsTabs from './ItemsTabs/ItemsTabs';
import lock from '../../../assets/images/icons/lock-gray.svg';
import WidgetAccordion from "../../../shared/components/Widget/WidgetAccordion";
import SharePlaylist from './SharePlaylist/SharePlaylist';
import PlaylistDetailMetatags from './PlaylistDetailMetatags';

interface PlaylistDetailProps {
  loading: React.SetStateAction<boolean>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const TEXT_KB_EMPTY = 'This playlist is currently empty';

const PlaylistDetail: React.FC<PlaylistDetailProps> = ({ loading, setLoading }) => {
  const [contentLength, setContentLength] = useState<number>(0);
  const [disableContent, setDisableContent] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(true);
  const [dataDetail, setDataDetail] = useState<Knowledge>();
  const { dispatch, state } = useContext(AppContext);
  const { playlist_slug: playlistSlug, kb_id: KBId } = useParams();
  const { authenticationToken, userData, reload } = state;
  const idKb = playlistSlug || KBId || '';
  const description = dataDetail?.attributes?.description || '';
  const collaborators = _get(dataDetail?.attributes, 'who_access.data');
  const isEmptyContent = Boolean(!contentLength);
  const isEmpty = (isEmptyContent && !description && !dataDetail?.attributes?.created_by &&
    !dataDetail?.attributes?.updated_at && !dataDetail?.attributes?.created_at
    && dataDetail?.attributes?.modified_by);
  const createdById = dataDetail?.attributes?.created_by_id?.toString();
  const KBTitle = dataDetail?.attributes?.title || '';
  const userName = `${userData.firstName} ${userData.lastName}`;
  const myPlaylist = createdById === userData?.userId;
  const kbPblic = dataDetail?.attributes?.public;
  const modifiedBy = `${returnSameName(dataDetail?.attributes?.modified_by,
    `${userName}`)}`;
  // disable playlist when is private, not owner or not invited
  const validationBlock = ((kbPblic === false && !myPlaylist && disableContent));
  const validationBlockLoading = validationBlock && !loading;
  // validation to show search
  const isEmptyPlaylist = isEmptyContent && !loadingItems && myPlaylist &&
    !validationBlockLoading && !reload;
  const styleColRight = {
    flexGrow: 1,
    alignItems: 'flex-start',
    order: isEmptyPlaylist ? '0' : '1',
    '@bp2': { order: '1' }
  };
  const details = [
    {
      name: 'Playlist',
      level: kbPblic === false ? 'Private' : 'Public',
    },
    {
      name: 'Owner',
      level: dataDetail?.attributes?.created_by || '',
    },
    {
      name: 'Created',
      level: formatDate(dataDetail?.attributes?.created_at),
    },
    {
      name: 'Modified',
      level: `${formatDate(dataDetail?.attributes?.modified_at)} ${modifiedBy}`
    },
  ];
  const transitionsSearch = useTransition((isEmptyPlaylist), {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: config.stiff,
  });
  const transitionsTabs = useTransition((!isEmptyPlaylist && !validationBlock && dataDetail), {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: config.stiff,
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await KnowledgeService.showDetail({ authenticationToken }, idKb);
        if(Array.isArray(data)){
          window.location.href = '/404';
        }
        setDataDetail(data);
      } finally {
        dispatch({ type: 'SET_RELOAD', payload: false });
        setLoading(false);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, idKb, setLoading]);

  useEffect(() => {
    (async () => {
      if (reload && contentLength === 1) {
        setContentLength(0);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => () => {
    setContentLength(0);
    dispatch({ type: 'SET_DELETE_ITEMS', payload: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Title = useMemo(() => (
    <TextTitleKnowledge >
      {kbPblic === false && <Icon img={lock} width='14px' height='14px' alt="Private" />}
      <strong>
        {KBTitle}
      </strong>
    </TextTitleKnowledge>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [KBTitle]);

  useEffect(() => {
    (async () => {
      if(dataDetail) {
        if((kbPblic && userData?.userId) || (myPlaylist)) {
          setDisableContent(false);
        }
        else {
          const res = collaborators.filter(
            (user: WhoAccess) =>
              user.attributes.user_id.toString() === userData?.userId &&
              user.attributes.status === 'accepted');
          if (res.length > 0) { setDisableContent(false); }
          else { setDisableContent(true); }
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaborators, createdById, dataDetail, userData?.userId]);

  return (
    <LayoutContainer type={isEmptyPlaylist ? 'knowledge-detail-empty' : 'knowledge-detail'}
                     css={{ minHeight: 300, position: 'relative' }}>
      {dataDetail && <PlaylistDetailMetatags playlist={dataDetail} /> }
      {(!isEmpty || loading) && (
        <LayoutColPeople col="3" css={{ order: isEmptyPlaylist ? '1' : '0', '@bp2': { order: '0' }}}>
          {!isEmptyPlaylist && !validationBlock && dataDetail && (
            <BoxTitle view='sidebar'>
              {Title}
            </BoxTitle>
          )}
          <BoxTextEmpty>
            {!validationBlock && isEmptyContent && !loadingItems && !myPlaylist &&
             dataDetail?.attributes && (
                <TextEmpty>{TEXT_KB_EMPTY}</TextEmpty>)}
          </BoxTextEmpty>
          {description && (<WidgetAccordion
            title='Description'
            loading={loading}
            body={<WidgetText>{description}</WidgetText>}
          />)}
          <DivWidgets>
            {Boolean(authenticationToken && myPlaylist)
              && dataDetail !== undefined && (
              <SharePlaylist loading={loading} playlistData={dataDetail} />
            )}
            {Boolean(dataDetail?.attributes?.created_by?.trim()) && (
              <WidgetTwoColumns loading={loading} title='Details' data={details} isAccordion/>
            )}
          </DivWidgets>
        </LayoutColPeople>
      )}
      <LayoutCol col={`${(isEmptyContent && (!loadingItems) && isEmpty) ? '12' : '9'}`} css={styleColRight}>
        <LayoutColPeople css={{ alignItems: 'baseline', width: '100%', alignSelf: 'flex-start' }} >
          {validationBlock && (<PlaylistPrivate />)}
          {!validationBlock && (<BoxTitle view={isEmptyPlaylist ? 'search': 'search-2'} >{Title}</BoxTitle>)}
          <BoxRelative>
            <BoxAbsolute>
              {transitionsSearch((styles, item) =>
                item ? (
                  <animated.div style={{ width: '100%', transitionProperty: 'ease-in-out', opacity: styles.opacity }}>
                    <SearchItems />
                  </animated.div>
                ) : null
              )}
            </BoxAbsolute>
            <BoxAbsolute>
              <BoxTextEmptyMid className="text-small">
                {!validationBlock && isEmptyContent && !loadingItems && !myPlaylist &&
                  dataDetail?.attributes && (
                    <TextEmpty>{`${TEXT_KB_EMPTY}`}</TextEmpty>
                )}
              </BoxTextEmptyMid>
              {transitionsTabs((styles, item) =>
                item ? (
                  <animated.div style={{ width: '100%', transitionProperty: 'ease-in-out', opacity: styles.opacity }}>
                    <ItemsTabs setContentLength={setContentLength}
                      contentLength={contentLength}
                      setLoadingItems={setLoadingItems}
                      loadingItems={loadingItems}
                      disableContent={disableContent} />
                  </animated.div>
                ) : null
              )}
            </BoxAbsolute>
          </BoxRelative>
        </LayoutColPeople>
      </LayoutCol>
    </LayoutContainer>
  );
};

export default PlaylistDetail;
