import React from 'react';
import { WrapperCard, CardHeader, CardFooter } from '../CardResources/styles';
import { BreathingComponents, CardContentShimmer } from './styles';

const CardProvidersLoad: React.FC<any> = () => (
  <WrapperCard little>
    <CardHeader>
      <BreathingComponents className="circle" width={60} height={60} />
    </CardHeader>
    <CardContentShimmer>
      <BreathingComponents className="tab" height={20} />
      <BreathingComponents className="tab" height={20} />
      <BreathingComponents className="tab" height={20} />
    </CardContentShimmer>
    <CardFooter>
      <BreathingComponents className="tab" height={20} />
    </CardFooter>
  </WrapperCard>
);

export default CardProvidersLoad;
