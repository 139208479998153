import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StatusTag, TagModel } from '../../interfaces/interfaces';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import {
  FlexContainer,
  InputWrapper,
  List,
  Section,
  Tag,
  TagBackButton,
  TextTag,
  TopicsBrowserGrid,
  TopicsContainer,
  TopicsNotApproved,
  FileDeleteButton,
} from './styles';
import { capitalizeFirstLetter, keyExtractor } from '../../utils/helper';
import { XIconWrapper } from '../../../screens/Organization/default/icons/XIcon';
import searchIcon from '../../../assets/images/icons/search.svg';
import arrowLeft from '../../../assets/images/icons/back-arrow.svg';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import InputSearch from '../InputSearch/InputSearch';
import TagListItem from './TagListItem';
import { useTag } from '../../hooks/useTag';
import deleteTagIcon from '../../../assets/images/icons/delete-tag.svg';
import blackSearchIcon from '../../../assets/images/icons/black-search.svg';
import { AddTag } from './AddTag';
import infoIcon from '../../../assets/images/icons/info.svg';
import TagsLoad from '../ShimmerComponent/TagsLoad';
import { remove } from '../ToastAlerts/alerts';

interface TopicSelectorMobileProps {
  open: boolean;
  topics: TagModel[];
  tags: TagModel[];
  onClose: () => void;
  userId?: string | number;
  contentId?: string | number;
  onUpDatedTopics: any;
  sameUser: Boolean | undefined;
}

export const TagSelectorMobile = ({
  open,
  topics,
  onClose,
  userId,
  contentId,
  onUpDatedTopics,
  sameUser,
  tags
}: TopicSelectorMobileProps) => {
  const {
    saveExpertise,
    deleteExpertise,
    saveTagContent,
    deleteTagContent,
    selectedTags,
    addSelectedTag,
    getTagPaged,
    resetSelected,
    deleteSelectedTag,
    filterDuplicated,
    handleTag,
    getTagById,
  } = useTag();
  const [value, setValue] = useState<string>('');
  const [currentTags, setCurrentTags] = useState<TagModel[]>(tags);
  const [tagStack, setTagStack] = useState<TagModel[][]>([]);
  const [showAddTopic, setShowAddTopic] = useState(false);
  const [visibleTags, setVisibleTags] = useState<TagModel[]>([]);
  const [isSearchingTerm, setIsSearchingTerm] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [reset, setReset] = useState(false);

  const handleTagClick = async (item: TagModel) => {
    await setIsSearchingTerm(true);
    const tagTopic = await getTagById(item.id);
    const visibleItems = tagTopic.attributes.children.data.map((tag: any) => tag);
    await addSelectedTag(item);
    await setCurrentTags(visibleItems);
    await setVisibleTags(visibleItems);
    await setTagStack((prevStack) => [...prevStack, currentTags]);
    await setIsSearchingTerm(false);
  };

  const handleBackClick = async () => {
    await setIsSearchingTerm(true);
    deleteSelectedTag();
    const prevTags = tagStack.pop();
    setCurrentTags(prevTags || tags);
    await setVisibleTags(prevTags || tags);
    setTagStack([...tagStack]);
    await setIsSearchingTerm(false);
  };

  const getList = async () => {
    let term;
    if (value.length > 0) {
      term = value.toLowerCase();
    }
    const tagsPaged = await getTagPaged(page + 1, 10, term);
    if (tagsPaged.length > 0) {
      setVisibleTags([...visibleTags, ...tagsPaged]);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
    setIsSearchingTerm(false);
  };

  const addUsedTags = (updatedTopics: TagModel[], item?: TagModel) => {
    const approved = updatedTopics.filter(
      (topic) => topic.attributes.status === StatusTag.Approved,
    );
    const notApproved = updatedTopics.filter(
      (topic) => topic.attributes.status !== StatusTag.Approved,
    );
    if (item) {
      onUpDatedTopics([...approved, item, ...notApproved]);
    } else {
      onUpDatedTopics([...approved, ...notApproved]);
    }
  };

  // use originals when was called from undo action after delete
  const handleTagAction = async (item: TagModel, originals?: TagModel[]) => {
    let updatedTopics: TagModel[] = [];

    // here ask about if exist original to update visually and not duplicate item.
    if (originals) {
      addUsedTags(originals);
    } else {
      addUsedTags(topics, item);
    }
    if (userId !== undefined) {
      updatedTopics = await saveExpertise(item, userId as number);
      addUsedTags(updatedTopics);
    } else if (contentId !== undefined) {
      updatedTopics = await saveTagContent(item, contentId as string);

      // similar to previous, here filter to previous item deleted, and use a new relation.
      if (originals) {
        const topicsWithoutItem = originals.filter((topic) => topic.id !== updatedTopics[0].id);
        addUsedTags([...topicsWithoutItem, ...updatedTopics]);
      } else {
        addUsedTags([...topics, ...updatedTopics]);
      }
    }
  };

  const handleDeleteTagAction = async (item: TagModel) => {
    const updatedTopics = topics.filter((topic) => topic.id !== item.id);
    const originalTopics = [...topics];
    await onUpDatedTopics(updatedTopics);
    if (userId !== undefined) {
      deleteExpertise(item, userId as number);
    } else if (contentId !== undefined) {
      deleteTagContent(item, contentId as string);
    }
    remove(
      <FlexContainer align="center" justify="between">
        <p>Topic removed</p>
        <Button
          title="Undo"
          kind="link"
          handleClick={() => {
            handleTagAction(item, originalTopics);
            toast.dismiss();
          }}
        />
      </FlexContainer>,
    );
  };

  const handleSearch = async (searchValue: string) => {
    setValue(searchValue);
    if (searchValue.length > 0) {
      const term = searchValue.toLowerCase();
      const tagPaged = await getTagPaged(1, 10, term);
      setIsSearchingTerm(false);
      resetSelected();
      setCurrentTags(tagPaged);
      setVisibleTags(tagPaged);
      if (!tagPaged.length) {
        setShowAddTopic(true);
      } else {
        setShowAddTopic(false);
      }
    } else {
      setIsSearchingTerm(false);
      setCurrentTags(tags);
      setShowAddTopic(false);
      setVisibleTags(tags);
    }
  };

  const handleClear = () => {
    setValue('');
    setCurrentTags(tags);
    setShowAddTopic(false);
  };

  const handleAddNewTag = () => {
    const tagName = value
      .split(' ')
      .map((word) => capitalizeFirstLetter(word))
      .join(' ');
    const newTag: TagModel = {
      id: keyExtractor(),
      type: 'tag',
      attributes: {
        name: tagName,
        children: [],
        status: 'waiting_review',
        children_count: 0,
      },
    };
    onUpDatedTopics([...topics, newTag]);
    handleClear();
  };

  useEffect(() => {
    setCurrentTags(tags);
    setVisibleTags(tags);
  }, [tags]);

  useEffect(() => {
    if (reset) {
      resetSelected();
      setReset(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Modal kindOverlay="gat-modal-overlay--light" isOpen={open} onClose={onClose}>
      <Card type="modalTopicSelectorMobile">
        <FileDeleteButton onClick={onClose}>
          <XIconWrapper />
        </FileDeleteButton>
        <p>Add Topic</p>
        <InputWrapper activeWrite={Boolean(value)}>
          {value ? (
            <Icon
              img={blackSearchIcon}
              width="18"
              height="16"
              alt="Search"
              margin0
              css={{ paddingLeft: 15 }}
            />
          ) : (
            <Icon
              img={searchIcon}
              width="18"
              height="16"
              alt="Search"
              margin0
              css={{ paddingLeft: 15 }}
            />
          )}

          <InputSearch
            actionSearch={handleSearch}
            placeholder="Search topic name"
            value={value}
            setValue={setValue}
            setReset={setReset}
            kind="kpModal"
            setIsSearchingTerm={setIsSearchingTerm}
          />
        </InputWrapper>
        <p className="browse-topic subTitle">Browse topic name</p>
        {selectedTags && !!selectedTags.length && (
          <TagBackButton onClick={handleBackClick}>
            <Icon
              img={arrowLeft}
              width="24px"
              height="21px"
              alt="Add content"
              margin0
              css={{
                display: 'inline-flex',
                verticalAlign: 'middle',
                cursor: 'pointer',
                marginLeft: '-3px',
              }}
            />
            {selectedTags.map((prevItem: any) => prevItem.attributes.name).join('/')}
          </TagBackButton>
        )}
        <TopicsBrowserGrid addTopic={showAddTopic}>
          <Section className="simple" id="scrollableDiv">
            <List>
              {isSearchingTerm ? (
                <TagsLoad total={5} />
              ) : (
                <InfiniteScroll
                  dataLength={visibleTags.length}
                  next={getList}
                  hasMore={hasMore}
                  loader={<TagsLoad total={5} />}
                  scrollableTarget="scrollableDiv"
                >
                  {visibleTags.map((item) => (
                    <TagListItem
                      item={item}
                      hasChild={item?.attributes?.children_count > 0}
                      onClick={handleTagClick}
                      handleTagAction={handleTagAction}
                      usedTags={topics.map((topic) => topic.id)}
                      searchTerm={value}
                      handleDeleteTag={handleDeleteTagAction}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </List>
          </Section>
        </TopicsBrowserGrid>
        <p className="subTitle">Live Topics</p>
        <TopicsContainer screen="mobile">
          {topics &&
            filterDuplicated(topics, sameUser).map((item: TagModel) => (
              <Tag
                onClick={(e: any) => {
                  e?.stopPropagation();
                  const tagData = item?.attributes?.name || item;

                  handleTag(tagData || '');
                }}
                key={keyExtractor()}
                approved={item.attributes.status === StatusTag.Approved}
              >
                <TextTag>{item?.attributes?.name || ''} </TextTag>
                <Icon
                  closeToast={() => handleDeleteTagAction(item)}
                  img={deleteTagIcon}
                  margin0
                  css={{ marginLeft: 4 }}
                />
              </Tag>
            ))}
        </TopicsContainer>
        {showAddTopic ? (
          <AddTag value={value} onNewTag={handleAddNewTag} cancel={handleClear} mobile />
        ) : (
          ''
        )}
        {topics &&
        topics.filter((topic) => topic.attributes.status !== StatusTag.Approved).length > 0 ? (
          <TopicsNotApproved>
            <Icon img={infoIcon} margin0 css={{ marginRight: 4 }} />
            {topics.filter((topic) => topic.attributes.status !== StatusTag.Approved).length ===
            1 ? (
              <p className="not-approved">
                {topics
                  .filter((topic) => topic.attributes.status !== StatusTag.Approved)
                  .map((topic) => `“${topic.attributes.name}” `)}{' '}
                topic is under review.
              </p>
            ) : (
              <p className="not-approved">
                {topics
                  .filter((topic) => topic.attributes.status !== StatusTag.Approved)
                  .map((topic) => `“${topic.attributes.name}”`)
                  .join(', ')}{' '}
                topics are under review.
              </p>
            )}
          </TopicsNotApproved>
        ) : (
          !topics.length && (
            <TopicsNotApproved>
              <Icon img={infoIcon} margin0 css={{ marginRight: 4 }} />
              <p className="not-approved">
                This
                {userId !== undefined ? ' profile ' : ' content '}
                has no live topics yet
              </p>
            </TopicsNotApproved>
          )
        )}
      </Card>
    </Modal>
  );
};

TagSelectorMobile.defaultProps = {
  userId: undefined,
  contentId: undefined,
};
