/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { Input } from './styled';
import Button from '../Button/Button';
import closeIcon from '../../../assets/images/icons/close.svg';

interface SearchInputModel {
  actionSearch: (value: string) => void;
  setReset?: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setIsSearchingTerm?: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder: string;
  css?: any;
  kind?: any;
}

const InputSearch = (props: SearchInputModel) => {
  const { actionSearch, setReset, placeholder, value, setValue, setIsSearchingTerm, css, kind } =
    props;
  const debouncedSearchTerm = useDebounce(value, 800);

  const debounceChange = async () => {
    if (setIsSearchingTerm) await setIsSearchingTerm(true);
    actionSearch(debouncedSearchTerm);
  };

  const handleResetInput = () => {
    setValue('');
    actionSearch('');
    if(setReset) setReset(true);
  };

  const onChangeHandler = (e: any) => {
    setValue(e?.target?.value);
  };

  useEffect(() => {
    debounceChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
  return (
    <>
      <Input
        type="text"
        kind={kind}
        value={value || ''}
        onChange={(e) => onChangeHandler(e)}
        placeholder={placeholder}
        css={css}
      />
      {value && (
        <Button
          title=""
          iconCustom={closeIcon}
          kind="close-search"
          handleClick={() => handleResetInput()}
          iconWidth="11px"
          iconHeight="11px"
        />
      )}
    </>
  );
};

InputSearch.defaultProps = {
  css: undefined,
  kind: undefined,
  setIsSearchingTerm: undefined,
  setReset: undefined,
};

export default InputSearch;
