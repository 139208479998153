import { styled } from '../../../config/theme';

export const WrapAternativeDiv = styled('div', {});
export const WrapAternativeHeader = styled('div', {
  variants: {
    type: {
      desktop: {
        display: 'none',
        '@bp3': {
          display: 'block',
        }
      }
    }
  }
});

export const Space = styled('div', {
  backgroundColor: '$line',
  width: '100%',
  height: '1px',
  borderRadius: '$radius075',
  margin: '2.5rem 0'
});

export const TitleAternative = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  marginBottom: '$spaceLarge'
});

export const TextNotResults = styled('span', {
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight3',
  display: 'flex',
  alignItems: 'center',
  width: '95%',
  '@bp2': {
    fontSize: '$bodySmall',

  },
  '@bp4': {
    width: '70%',
  }
});
