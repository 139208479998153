/* eslint-disable react/require-default-props */
import React from 'react';
import PeopleData from './PeopleData';
import PeopleContent from './PeopleContent';
import PeopleFollow from './PeopleFollow';
import PeopleDataContent from './PeopleDataContent';
import PeopleName from './PeopleName';
import { WrapperPeople } from './styles';
import { User } from '../../interfaces/interfaces';
import { getUserName } from '../../utils/helper';

interface PeopleModel {
  name: string;
  text: string;
  img: string;
  follow: number | null;
  actionFollow?: () => void | Promise<any>;
  actionClose?: () => void;
  type: string;
  id?: string;
  hideFollow?: boolean;
  twitterName?: string;
  slug?: string;
  isContent?: boolean;
  typePeople?: string;
  authorsMentions?: any;
  author?: User;
}

type PeopleProps = PeopleModel &
  React.ComponentProps<typeof PeopleData> & {
    type?: string | undefined;
    kind?: string;
  };

type WrapPeopleProps =
  React.ComponentProps<typeof WrapperPeople> & {
  };

const People: React.FunctionComponent<PeopleProps> = ({
  text, name, img, follow, actionFollow, type, id, hideFollow, twitterName, kind, actionClose, slug,
  isContent, typePeople, authorsMentions, author
}) => {
  const isResponsive = Boolean(kind?.includes('responsive'));
  const wrapType = type as any | WrapPeopleProps;
  const peopleName = name || getUserName({ name: author?.attributes?.name });

  const handleClick = () => {
    if (actionClose) {
      actionClose();
    }
  };

  return (
    <>
      <WrapperPeople type={wrapType || ''}>
        {isContent ? <PeopleContent
          img={img}
          name={peopleName}
          text={text}
          type={type}
          slug={slug}
          kind={kind}
          actionClose={handleClick}
          typePeople={typePeople}
          authorsMentions={authorsMentions}
          follow={follow}
          id={id}
          actionFollow={actionFollow}
          twitterName={twitterName}
        />
          :
          <PeopleData
            img={img}
            name={peopleName}
            text={text}
            type={type}
            slug={slug}
            kind={kind}
            follow={follow}
            id={id}
            actionClose={actionClose}
            actionFollow={actionFollow}
            twitterName={twitterName}
            tooltip
          />
        }
        {!hideFollow && (
          <PeopleFollow
            follow={follow}
            actionFollow={actionFollow}
            id={id}
            username={twitterName}
            size='small'
          />
        )}
      </WrapperPeople>
      {isResponsive && isContent && (
        <PeopleDataContent
          name={peopleName}
          text={text}
          slug={slug}
          type={type}
          typePeople={typePeople}
          authorsMentions={authorsMentions}
          actionClose={handleClick}
          kind='people-responsive'
          img={img}
          follow={follow}
          id={id}
          actionFollow={actionFollow}
          twitterName={twitterName}
        />
      )}
      {isResponsive && !isContent && (
        <PeopleName
          kind='people-responsive'
          actionClose={actionClose}
          slug={slug}
          name={peopleName}
          text={text}
          type={type}
        />
      )}
    </>
  );
};

export default People;
