import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadDataSection from '../../../shared/components/LoadDataSection/LoadDataSection';
import CardBooksList from '../../../shared/components/CardBooks/CardBooksList';
import PlaylistsLoad from '../../../shared/components/ShimmerComponent/PlaylistsLoad';
import AppContext from '../../../shared/context/app/AppContext';
import { KnowledgeService } from '../../../services/knowledge.service';
import { LayoutContainer } from '../../../shared/components/Layouts/styles';
import { LayoutColKB } from '../styles';
import PlaylistEmpty from '../PlaylistEmpty/PlaylistEmpty';
import PlaylistHeader from '../PlaylistHeader/PlaylistHeader';
import { BoxShimmer } from '../../../shared/components/ShimmerComponent/styles';
import {Spinner} from '../../../shared/components/Spinner/spinner';

type PlaylistsProps = { userSlug: string, myPlaylist?: boolean };

const Playlists: React.FC<PlaylistsProps> = ({ userSlug, myPlaylist }) => {
  const [fetchUserPlaylists, setFetchUserPlaylists] = useState<boolean>(false);
  const [contentLength, setContentLength] = useState<number>(0);
  const [totalKB, setTotalKB] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { authenticationToken } = state;
  const isEmpty = contentLength === 0;
  const search = window?.location?.search;
  const loadContent = useCallback(async (
    page: number,
    total: number
  ) => {
    if (!loading) await setLoading(true);
    const data = await KnowledgeService.getIndex({
      authenticationToken, page, limit: total, userID: userSlug
    });
    await setContentLength(prevData => prevData + data.length);
    await setLoading(false);
    return data || [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, userSlug, myPlaylist]);

  const viewDetail = useCallback((id: string, createdBySlug: string) => {
    navigate(`/users/${createdBySlug}/playlists/${id}${search || ''}`);
  }, [navigate, search]);

  useEffect(() => {
    if(userSlug && !loading){
      setFetchUserPlaylists(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSlug]);

  useEffect(() => {
    if(fetchUserPlaylists){setFetchUserPlaylists(false);}
  }, [fetchUserPlaylists]);

  const LoadData = useMemo(() => (
    <LoadDataSection
      onAction={loadContent}
      afterLoad={(e) => setTotalKB(e)}
      component={CardBooksList}
      typeButton="outline"
      reload={fetchUserPlaylists}
      empty={isEmpty && !loading}
      propsComponent={{ actionClick: viewDetail }}
      shimmerComponent={<PlaylistsLoad total={6} />}
      shimmerInfinity={(
        <BoxShimmer type='center'>
          <Spinner className='spinner' type='xs' css={{padding: '$spaceSmall' }} />
        </BoxShimmer>
      )}
      isInfinite
      isDeleteActive
      loading={loading}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [isEmpty, loading, loadContent, fetchUserPlaylists]);

  useEffect(() => {
    (async () => {
      if (contentLength && (totalKB === 0)) {
        setContentLength(0);
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalKB]);

  return (
    <LayoutContainer
      css={{ flexDirection: 'column', display: 'flex', minHeight: 300, '@bp3': { padding: '2.5rem 5rem 0rem'} }}
    >
      <PlaylistHeader totalKB={totalKB}
                      isPublic={!authenticationToken}
                      myPlaylist={myPlaylist}
                      visible={!isEmpty && !loading} />
      <LayoutColKB>
        {isEmpty && !loading && (
          <PlaylistEmpty userSlug={userSlug}/>
        )}
        {LoadData}
      </LayoutColKB>
    </LayoutContainer>
  );
};

Playlists.defaultProps = {
  myPlaylist: undefined
};

export default Playlists;

