import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Google from './components/Google/Google';
import Twitter from './components/Twitter/Twitter';
import Email from './components/Email/Email';
import TabsWrapper from './components/TabsWrapper/TabsWrapper';
import Tabs from './components/Tabs/Tabs';
import { TabsContainer, Container, AuthenticationCard } from './styled';
import UserType from './components/UserType/UserType';
import AuthenticationContext from '../../shared/context/authentication/authenticationContext';
import Orcid from './components/Orcid/Orcid';

const Authentication = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { userType, setUserType, login, setLogin } = useContext(AuthenticationContext);

  useEffect(() => {
    if (params['sign-up']) {
      setLogin(false);
    } else {
      setLogin(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (params?.invite) {
      setUserType(params.invite as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Container>
      <TabsContainer>
        <TabsWrapper>
          <Tabs />
          {login || userType === 'expert' || userType === 'seeker' ? (
            <AuthenticationCard>
              {userType !== 'seeker' && (
                <>
                  <Google />
                  <Twitter />
                  <Orcid />
                  <p>or use your email</p>
                </>
              )}
              <Email />
            </AuthenticationCard>
          ) : (
            <UserType />
          )}
        </TabsWrapper>
      </TabsContainer>
    </Container>
  );
};

export default Authentication;
