import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { ContentSidebar, SidebarWrap } from './styled';

interface SidebarModel {
  children: string | JSX.Element | JSX.Element[] | React.ReactNode;
}

type SidebarProps = SidebarModel & React.ComponentProps<typeof SidebarWrap> & {
  type?: string;
};

const Sidebar: FC<SidebarProps> = ({ children, type }) => {
  const refDiv = useRef<HTMLDivElement | null>(null);
  const [currentY, setCurrentY] = useState<number>(0);
  const [stickDiv, setStickDiv] = useState<string>("stickyToTop");
  const { width } = useWindowSize();

  const handleScroll = useCallback(async () => {
    if (refDiv?.current && width > 980) {
      const validateScroll = window.scrollY > currentY;
      const newtop = refDiv.current.offsetHeight - window.innerHeight;
      const isScroll = refDiv.current.offsetHeight > window.innerHeight;
        setCurrentY(window.scrollY);

      if (validateScroll) {
        if (stickDiv === "notSticky" && window.scrollY >= refDiv.current.offsetTop + newtop) {
          setStickDiv('stickyToBottom');
          refDiv.current.style.position = "sticky";
          if (isScroll) {
            refDiv.current.style.top = `${-newtop}px`;
          } else {
            refDiv.current.style.top = "75px";
          }
        }

        if (stickDiv === "stickyToTop") {
          setStickDiv("notSticky");
          refDiv.current.style.position = "relative";
          refDiv.current.style.top = "0px";
          refDiv.current.style.marginTop = `${Math.max(currentY - refDiv.current.offsetTop, 0)}px`;

          if (isScroll) {
            refDiv.current.style.marginTop = `${Math.max(currentY - refDiv.current.offsetTop, 0)}px`;
          }
        }
      } else {
        if (stickDiv === "notSticky" && (window.scrollY <= refDiv.current.offsetTop)) {
          setStickDiv("stickyToTop");
          refDiv.current.style.position = "sticky";
          refDiv.current.style.top = "0px";
          refDiv.current.style.marginTop = "0px";
        }
        if (stickDiv === "stickyToBottom") {
          setStickDiv("notSticky");
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentY, stickDiv, width]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [stickDiv, handleScroll]);

  useEffect(() => {
    if (refDiv?.current && width < 980) {
      refDiv.current.style.position = "sticky";
      refDiv.current.style.top = "0px";
      refDiv.current.style.marginTop = "0px";
    }
  }, [width]);

  return (
    <SidebarWrap type={type}>
      <ContentSidebar ref={refDiv}>
        {children && children}
      </ContentSidebar>
    </SidebarWrap>
  );
};

Sidebar.defaultProps = {
  type: undefined
};

export default Sidebar;
