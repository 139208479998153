import { useState } from "react";

export const UseDevices = () => {
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);

  const listDevices = async (retry = true) => {
    const devices = await navigator.mediaDevices?.enumerateDevices();
    if (devices) {
      const video: MediaDeviceInfo[] = [];
      const audio: MediaDeviceInfo[] = [];
      devices.forEach((device) => {
        if (device.kind === 'videoinput') {
          video.push(device);
        } else if (device.kind === 'audioinput') {
          audio.push(device);
        }
      });
      if (video.length === 0 || audio.length === 0) {
        if (retry) {
          listDevices(false);
          console.warn('No multimedia devices found. Retrying...');
          return;
        };
      }
      setVideoInputDevices(video);
      setAudioInputDevices(audio);
    } else {
        throw new Error('No support for multimedia devices.');
    }
  };

  // eslint-disable-next-line no-unused-vars
  navigator.mediaDevices.ondevicechange = (_event) => {
    listDevices();
  };

  return { videoInputDevices, audioInputDevices, listDevices };
};

export default UseDevices;