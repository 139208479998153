import { styled } from '../../../config/theme';

const commonTextStyles = {
  fontFamily: '$poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  color: '$offBlack',
  marginBottom: 20,
};

export const CardWrapper = styled('div', {
  backgroundColor: '$bg',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  borderRadius: '1rem 1rem 1rem 1rem',
  p: {
    fontSize: '$bodyCard',
  },
  variants: {
    type: {
      modalRequest: {
        display: 'block',
        padding: '$spaceSmall',
        width: '100%',
        minWidth: '$20',
        '@bp2': {
          width: 560,
          padding: '5rem',
        },
        '@bp5': {
          minWidth: '40rem',
        },
      },
      modal: {
        width: '90vw',
        textAlign: 'center',
        height: 'auto',
        marginBottom: '1rem',
        '@bp2': {
          width: '577px',
        },
      },
      login: {
        background: '$offWhite',
      },
      onboarding: {
        width: '90vw',
        textAlign: 'center',
        backgroundColor: '$bg',
        maxHeight: '95vh',
        height: 'auto',
        marginBottom: '1rem',
        overflow: 'hidden',
        '@bp1': {
          height: 'auto',
        },
        '@bp2': {
          width: '700px',
        },
      },
      'onboarding-login': {
        width: '90vw',
        textAlign: 'center',
        backgroundColor: '$bg',
        maxHeight: '95vh',
        height: 'auto',
        marginBottom: '1rem',
        overflow: 'hidden',
        '@bp2': {
          width: '550px',
        },
      },
      modalKB: {
        alignItems: 'start',
        padding: '4rem 2rem',
        '@bp2': {
          padding: '4rem 5rem',
        },
        '& p': {
          ...commonTextStyles,
        },
      },
      modalTopics: {
        alignItems: 'start',
        width: '90vw',
        padding: '2rem 2rem',
        '@bp2': {
          padding: '2rem 2rem',
        },
        '& p': {
          ...commonTextStyles,
        },
      },
      modalTopicSelector: {
        alignItems: 'start',
        width: 'auto',
        minWidth: '22rem',
        padding: '2rem 2rem',
        '@bp1': {
          minWidth: '38rem',
        },
        '@bp2': {
          minWidth: '45rem',
        },
        '@bp3': {
          minWidth: '60rem',
        },
        '@bp4': {
          minWidth: '78rem',
        },
        '& p': {
          fontFamily: '$poppins',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '18px',
          color: '$offBlack',
          marginBottom: '20px',
          marginTop: '20px',
          '&.browse-topic': {
            fontSize: '15px',
            fontWeight: 600,
            marginTop: '20px',
          },
          '&.title': {
            marginBottom: '20px',
            marginTop: '0px',
          },
        },
      },
      modalTopicSelectorMobile: {
        alignItems: 'start',
        width: 'auto',
        minWidth: '22rem',
        padding: '2rem 2rem',
        '@bp1': {
          minWidth: '38rem',
        },
        '@bp2': {
          minWidth: '45rem',
        },
        '@bp3': {
          minWidth: '60rem',
        },
        '@bp4': {
          minWidth: '78rem',
        },
        '& p': {
          fontFamily: '$poppins',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '18px',
          color: '$offBlack',
          marginBottom: '20px',
          '&.browse-topic': {
            fontSize: '15px',
            fontWeight: 600,
          },
          '&.title': {
            marginBottom: '20px',
          },
          '&.subTitle': {
            marginTop: '20px',
          },
        },
      },
      modalResources: {
        height: '95vh',
        width: '90vw',
        padding: '4rem 2rem 0.5rem 2rem',
        marginBottom: '1rem',
        overflowX: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',

        '@bp2': {
          width: 700,
          maxHeight: 815,
          padding: '4rem 5rem 0.5rem 5rem ',
        },
      },
      profile: {
        cursor: 'pointer',
        color: '$offBlack',
        fontSize: '$bodySmall',
        position: 'relative',
        padding: '1rem 2rem 1rem 1rem',
        alignItems: 'baseline',

        '@bp3': {
          padding: '1.75rem 2.5rem 1.75rem 1.75rem',
          width: '352px',
          height: '192px',
        },
      },
      'profile-meet': {
        cursor: 'pointer',
        color: '$offBlack',
        fontSize: '$bodySmall',
        width: '100%',
        height: '205px',
        position: 'relative',
        padding: '1.75rem',
        alignItems: 'baseline',

        '@bp3': {
          width: '394px',
        },
      },
      row: {
        cursor: 'pointer',
        color: '$offBlack',
        fontSize: '$bodySmall',
        height: '90px',
        position: 'relative',
        padding: '$spaceLarge',
        boxShadow: '0 0 32px hsla(0, 0%, 0%, 0.07)',
        alignItems: 'center',
        gap: '$spaceXSmall',
        display: 'grid',
        gridAutoColumns: '1fr',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '2fr 1fr 1fr',
        // '&:hover': {
        //   border: '1px solid $purple'
        // },
      },
      popup: {
        height: 'auto',
        padding: '26px',
        width: 'fit-content',
        maxWidth: 610,
        maxHeight: 300,
        zIndex: 2,
        '& p': {
          ...commonTextStyles,
        },
        alignItems: 'start',
      },
      users: {
        border: '1px solid $line',
        backgroundColor: '$bg',
        cursor: 'pointer',
        color: '$offBlack',
        fontSize: '$bodySmall',
        height: 'auto',
        padding: '1rem',
        alignItems: 'center',
        gap: '$spaceXSmall',
        display: 'grid',
        gridAutoColumns: '2fr .1fr 1.5fr 1fr',
        gridTemplateRows: 'auto',
        gridTemplateAreas: `
        'peopleData peopleData peopleData'
        'description  description button '
        `,
        '& .space': {
          backgroundColor: '$line',
          width: '1px',
          height: '100%',
          borderRadius: '$radius075',
        },
        '& .description': {
          gridArea: 'description',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '$bodyXSSmall',
          '-webkit-line-clamp': '2',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
        },
        '& .gat-people-follow': {
          justifyContent: 'end',
          '& button': {
            width: 'auto',
          },
        },
        '@bp4': {
          gridTemplateAreas: `
          'peopleData space description button'
          `,
        },
      },
      alert: {
        color: '$offBlack',
        fontSize: '$bodySmall',
        width: '394px',
        minHeight: '250px',
        padding: '1.75rem',
      },
      simple: {
        backgroundColor: '$offWhite',
        color: '$offBlack',
        fontSize: '$bodySmall',
        height: 'auto',
        position: 'relative',
        padding: '$spaceLarge',
        alignItems: 'center',
        display: 'flex',
        marginTop: '$spaceSmall',
      },
      questionsModal: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        backgroundColor: '$grayscaleBackground',
        borderRadius: '0',
        '@phoneLandscape': {
          maxWidth: 320,
          height: 'auto',
          borderRadius: '$radius05',
        },
        '@bp2': {
          maxWidth: '85vw',
          height: 'auto',
        },
        '@bp4': {
          maxWidth: '90vw',
        },
        '@bp6': {
          maxWidth: 1200,
        },
      },
      questions: {
        padding: '31px 12px',
        backgroundColor: '$monochromeWhite',
        height: 'unset',
        marginBottom: '1rem',
        fontSize: 'clamp(0.90rem, 2.5vw, 1rem)',
        '@landscape': {},

        '@bp2': {
          padding: '0',
          alignItems: 'flex-start',
          marginBottom: 'unset',
        },
        '@phoneLandscape': {
          padding: '31px 12px',
        },
      },
      recordingModal: {
        gap: '20px',
        padding: '2rem',
        backgroundColor: '$monochromeWhite',
        height: 'unset',
        maxWidth: '350px',
        width: '100%',
        textAlign: 'center',
        '@bp2': {
          padding: '3rem',
          maxWidth: 'fit-content',
        },
      },
      recordingModalLandscape: {
        gap: '12px',
        padding: '2rem',
        backgroundColor: '$monochromeWhite',
        height: 'calc(100vh - 1rem)',
        maxWidth: 'unset',
        width: '100%',
        textAlign: 'center',
      },
      retakeRecordingModalLandscape: {
        gap: '20px',
        padding: '2rem',
        backgroundColor: '$monochromeWhite',
        height: '280px',
        minWidth: '380px',
        width: '100%',
        textAlign: 'center',
      },
      submitOpinion: {
        display: 'inline-flex',
        padding: '1.5rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '32px',
        width: '320px',
        '@bp2': {
          width: '722px',
          padding: '5rem',
          maxWidth: 'fit-content',
        },
      },
      member: {
        display: 'block',
        padding: '$spaceSmall',
        width: '100%',
        minWidth: '$20',
        '@bp2': {
          width: 560,
          padding: '$spaceXLarge',
        },
        '@bp5': {
          maxWidth: '35rem',
          padding: '$spaceXLarge',
        },
      },
      deleteProject: {
        display: 'block',
        padding: '$spaceSmall',
        width: '100%',
        minWidth: '$20',
        '@bp2': {
          width: 560,
          padding: '$spaceMedium1',
        },
        '@bp5': {
          maxWidth: '35rem',
          padding: '$spaceMedium1',
        },
      },
    },
    disabled: {
      true: {
        cursor: 'auto',
        backgroundColor: 'transparent',
        border: '1px solid $line',
        boxShadow: 'none',
      },
    },
    notBio: {
      true: {
        gridAutoColumns: '2fr .1fr 1fr',
        gridTemplateAreas: `
        'peopleData peopleData button'
        `,
        '@bp4': {
          gridTemplateAreas: `
          'peopleData space button'
          `,
        },
      },
    },
  },
});
