import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ExpertOpinionsContainer,
  LetterOpinionContainer,
  MaxWidthContainer,
  ElipseIllustrator,
  IllustrationContainer,
  MentionsContainer,
  MiddleImg,
} from '../styled';
import { TextDescription, Typography } from '../../styled';
import Button from '../../../../shared/components/Button/Button';
import { Mention } from '../../Experts/Mention';
import middleImage from '../../../../assets/images/experts/PrincipalPhoto.jpg';
import ellipse1Image from '../../../../assets/images/experts/EllipsePhoto1ExpertOpinion.jpg';
import ellipse2Image from '../../../../assets/images/experts/EllipsePhoto2ExpertOpinion.jpg';
import ellipse3Image from '../../../../assets/images/experts/EllipsePhoto3ExpertOpinion.jpg';
import ellipse4Image from '../../../../assets/images/experts/EllipsePhoto4ExpertOpinion.jpg';

export const Introduction = () => {
  const navigate = useNavigate();
  return (
    <ExpertOpinionsContainer>
      <MaxWidthContainer>
        <LetterOpinionContainer>
          <Typography type="displayMedium" letterSpacing="letterSpacing6">
            Like Cameo for biotech
          </Typography>
          <TextDescription type="landingDescriptionTop">
          Bespoke on-demand video recordings from biotechnology experts answering
          your specific research and analysis questions.
          </TextDescription>
          <TextDescription type="landingDescriptionTop">
            Use Expert Opinions to connect with subject-matter expertise on-demand. Gather unique
            intelligence, opinion and data to support your decision making.
          </TextDescription>
          <Button
            kind="top"
            title="Get Expert Opinion"
            handleClick={() => navigate('/new-project')}
          />
        </LetterOpinionContainer>
        <IllustrationContainer>
          <ElipseIllustrator />
          <MentionsContainer>
            <Mention image={ellipse1Image} imagePosition="right">
              “How do you decide which point-of-care detection device to buy for wound care and
              monitoring?”
            </Mention>
            <Mention image={ellipse2Image} imagePosition="left">
              “How does the pH-dependency of the CEST signal effect LNTSeNPs?”
            </Mention>
          </MentionsContainer>
          <MiddleImg key="PrincipalExpertPhoto1" src={middleImage} alt="" className="img-lazy" />
          <MentionsContainer>
            <Mention image={ellipse4Image} imagePosition="right">
              “Summarize your experience using Base Editing methods instead of CRIPSPR-Cas9”
            </Mention>
            <Mention image={ellipse3Image} imagePosition="left">
              “What specific advancements in genetic tools and techniques do you find most exciting
              for manipulating microbial communities?”
            </Mention>
          </MentionsContainer>
        </IllustrationContainer>
      </MaxWidthContainer>
    </ExpertOpinionsContainer>
  );
};
