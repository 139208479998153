import React from 'react';
import CardResourcesLoad from './CardResourcesLoad';
import { WrapperCardList } from '../CardResources/styles';
import { keyExtractor } from '../../utils/helper';


const ResourcesListLoad: React.FC<any> = ({ total }) => {
  const itemsTotal = total || 1;
  const arrayData = Array(itemsTotal).fill('items');

  return (
    <WrapperCardList className='resources-list'>
      {arrayData && arrayData?.map(() =>
        <CardResourcesLoad key={keyExtractor()} />
      )}
    </WrapperCardList>
  );
};

export default ResourcesListLoad;
