import { styled } from '../../../config/theme';

export const AvatarContainer = styled('div', {
  minHeight: '6rem',
  minWidth: '6rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  border: '1px solid $grayscaleLine',
  variants: {
    isEmpty: {
      true: {
        background: '$grayscaleInput',
      },
    },
  },
});

export const ImgOrganizationAvatar = styled('img', {
  borderRadius: '100%',
  height: '6rem',
  width: '6rem',
  minHeight: '6rem',
  minWidth: '6rem',
});
