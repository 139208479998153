/* eslint-disable no-unused-vars */
import React from 'react';
import { OptionProps } from 'react-select';
import { OptionCustom } from './styled';
import RadioButton from '../../../../../shared/components/RadioButton/RadioButton';


type OptionsModel = OptionProps & any & {
    id: string;
    name: string;
    value: string | undefined;
  };

const CustomOption: React.FC<OptionsModel> = (props) => {
  const { isFocused, isSelected, isDisabled, label, value } = props;

  return !isDisabled ? (
    <OptionCustom {...props} isFocused={isFocused} isSelected={isSelected}>
      <RadioButton
        id={value || ''}
        label={label}
        value={value}
        name={value || ''}
        checked={isSelected || false}
        disabled={isDisabled || false}
        onClick={(e: any) => { }}
      />
    </OptionCustom>
  ) : null;
};

CustomOption.defaultProps = {};

export default CustomOption;
