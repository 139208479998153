import React, { useContext } from 'react';
import { FormikProvider } from 'formik';
// import { toast } from 'react-toastify';
import AppContext from '../../../../../shared/context/app/AppContext';
// import useGetToken from '../../../../../shared/hooks/useGetToken';
// import { ProfileService } from '../../../../../services/ProfileService/ProfileService';
// import { UserModel } from '../../../../../shared/context/app/types';
import {
  LeftContainer,
  FormikInput,
  FormStyled,
  TextWrapper,
  TitleSection,
  WrapperForm,
  SubTitleSection,
} from '../styled';
import { AvatarContainer } from './styled';
import { LayoutContainer, LayoutCol } from '../../../../../shared/components/Layouts/styles';
import ChangeAvatar from '../../../../../shared/components/Avatar/ChangeAvatar/ChangeAvatar';
import useGetUserType from '../../../../../shared/hooks/getUserType';

interface PublicProfileFormModel {
  form: any;
  save: Boolean;
}

const PublicProfileForm: React.FC<PublicProfileFormModel> = ({ form, save }) => {
  const userType = useGetUserType();
  const { state } = useContext(AppContext);
  const { slug, kind } = state.userData;

  return (
    <WrapperForm>
      <LeftContainer>
        <TitleSection>Personal information</TitleSection>
        <SubTitleSection>
          Profile Settings: Easily update your name and profile picture here.
        </SubTitleSection>
      </LeftContainer>
      <AvatarContainer>
        <ChangeAvatar type="settings" save={save} />
      </AvatarContainer>
      <FormikProvider value={form}>
        <FormStyled onSubmit={form.handleSubmit}>
          <LayoutContainer
            type="settings"
            css={{
              '@bp2': {
                gridTemplateColumns: '1fr',
              },
              '@bp3': {
                gap: '$spaceSmall',
                gridTemplateColumns: '1fr 1fr',
              },
            }}
          >
            <LayoutCol col="6">
              <FormikInput id="firstName" name="firstName" placeholder="Name" />
            </LayoutCol>
            <LayoutCol col="6">
              <FormikInput id="lastName" name="lastName" placeholder="Surnames" />
            </LayoutCol>
          </LayoutContainer>
          {userType !== 'seeker' && (
            <FormikInput id="authorOccupation" name="authorOccupation" placeholder="Job Title" />
          )}

          <FormikInput id="email" name="email" placeholder="Contact Email" type="email" disabled={kind !== "gathersight"} />
          {/* TODO: use this in the future
          <FormikInput id="username" name="username" placeholder="Username" /> 
          {form.errors.username && form.touched.username ? (
            <Error>{form.errors.username}</Error>
          ) : null} */}
          {userType !== 'seeker' && (
            <TextWrapper>Your Gathersight URL: www.gathersight.com/profile/{slug}</TextWrapper>
          )}
        </FormStyled>
      </FormikProvider>
    </WrapperForm>
  );
};
export default PublicProfileForm;
