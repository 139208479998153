import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useAuthenticationError = () => {
  const navigate = useNavigate();

  const redirectAfterError = (error: string) => {
    let newError = error;
    if(error === 'user is not signed up') {
      newError = 'User not found. Please sign up first';
      navigate('/authentication?sign-up=true');
    } else {
      navigate('/authentication');
    }  
    toast.error(newError);
    return null;
  };
  return { redirectAfterError };
};
