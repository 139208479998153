import { styled } from '../../../../config/theme';

export const VideoCaptionContainer = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  color: 'white',
  font: '0/0 a',
  width: '100%',
  height: '100%',
  backgroundColor: 'hsla(241, 37%, 12%, 0.6)',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  '@bp2': { borderRadius: '$radius05' },

  '@before': {
    content: ' ',
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle',
  },
});

export const TextCaptionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '12px',
  backgroundColor: '$blurple',
  padding: '50px 30px',
  '@bp2': {
    padding: '80px',
  },
});

export const TextCaptionInner = styled('div', {
  fontFamily: 'Poppins',
  fontSize: '24px',
  fontWeight: '600',
  lineHeight: '36px',
  letterSpacing: '1px',
  textAlign: 'center',
});

export const CountdownContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  backgroundColor: '$blurple',
  '@bp3': {
    width: '300px',
    height: '300px',
  },
});

export const CountdownValue = styled('span', {
  fontFamily: 'Poppins',
  fontSize: '120px',
  fontWeight: '600',
  lineHeight: '180px',
  letterSpacing: '0em',
  textAlign: 'left',
});
