import React from 'react';
import { SaveOrRecordContainer } from '../styles';
import SaveButton from './SaveButton';
import RecordAgain from './RecordAgain';

const SaveOrRecordButtons = () => (
  <SaveOrRecordContainer>
    <SaveButton />
    <RecordAgain />
  </SaveOrRecordContainer>
);

export default SaveOrRecordButtons;
