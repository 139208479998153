/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import CardPeople from './CardPeople';
import WidgetsBase from '../../../screens/Home/WidgetsBase/WidgetsBase';
import { Resources } from '../../interfaces/interfaces';
import { WrapperCardList } from '../CardResources/styles';
import { LayoutCol, LayoutColEmpty } from '../Layouts/styles';
import { LayoutContainerPeople } from './styles';

interface Props {
  data: Resources[];
  userId?: string | number;
  hideEmpty?: boolean;
  simpleGap?: boolean;
  limitFeed?: number;
  hideSuggestions?: boolean;
  showPlaylist?: boolean;
}

const CardPeopleList: React.FC<Props> = ({
  data,
  userId,
  hideEmpty,
  simpleGap,
  limitFeed,
  hideSuggestions,
  showPlaylist,
}) => {
  const isMultipleTwo = data.length % 2 === 0;
  const isMultiple = data.length % 1 === 0;
  const anyResults = data?.length > 1;
  const oneResource = !anyResults ? { justifyContent: 'flex-start' } : {};

  return (
    <WrapperCardList className="resources-list" css={{ order: 1 }}>
      <LayoutContainerPeople sub noPadding simpleGap={simpleGap} showPlaylist={showPlaylist}>
        {data &&
          data?.map((item: Resources, index) => {
            if (limitFeed && index === limitFeed) {
              return (
                <Fragment key={`${index}-${item.id}`}>
                  <LayoutCol
                    className={`${index}`}
                    col="4"
                    css={{ ...oneResource, order: 1, flexGrow: 1, '@bp6': { flexBasis: '30.33%' } }}
                  >
                    <CardPeople userId={userId} {...item} />
                  </LayoutCol>
                  <WidgetsBase
                    kind="mobile"
                    css={{ order: 1 }}
                    hideSuggestions={hideSuggestions}
                    showPlaylist={showPlaylist}
                  />
                </Fragment>
              );
            }

            if (item) {
              return (
                <LayoutCol
                  key={`${index}-${item.id}`}
                  className={`${
                    index === 0 || (limitFeed && index < limitFeed) ? `resource-child-${index}` : ''
                  }`}
                  col="4"
                  css={{ ...oneResource, order: 1, flexGrow: 1, '@bp6': { flexBasis: '30.33%' } }}
                >
                  <CardPeople userId={userId} {...item} />
                </LayoutCol>
              );
            }
            return '';
          })}
        {!hideEmpty && (
          <>
            <LayoutColEmpty
              kind="empty-people"
              css={{ order: 1 }}
              visible={{
                '@bp2': !anyResults || isMultipleTwo ? 'hide' : 'show',
              }}
            />
            <LayoutColEmpty
              kind="empty-people"
              css={{ order: 1 }}
              visible={{
                '@bp2': !anyResults || isMultipleTwo || isMultiple ? 'hide' : 'show',
              }}
            />
          </>
        )}
      </LayoutContainerPeople>
    </WrapperCardList>
  );
};

CardPeopleList.defaultProps = {
  userId: undefined,
  hideEmpty: false,
  simpleGap: false,
  limitFeed: undefined,
  hideSuggestions: undefined,
  showPlaylist: undefined,
};

export default CardPeopleList;
