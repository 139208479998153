import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../../shared/components/Button/Button';
import Card from '../../../../shared/components/Card/Card';
import Modal from '../../../../shared/components/Modal/Modal';
import { FlexContainer, Typography } from '../../styled';
import VideoRecordingContext from '../../../../shared/context/videoRecording/videoRecordingContext';
import { OpinionContainerArea, TextAreaOpinion } from './styled';
import useGetToken from '../../../../shared/hooks/useGetToken';
import ProjectsContext from '../../../../shared/context/projects/ProjectsContext';
import { OpinionsService } from '../../../../services/OpinionsService/OpinionsService';
import CheckboxComponent from '../../../../shared/components/Checkbox/Checkbox';
import { TextLabel } from '../../../../shared/components/ModalContent/styled';

export const SubmitOpinion = () => {
  const [openModal, setOpenModal] = useState(false);
  const [notes, setNotes] = useState('');
  const [checkbox1, setCheckbox1] = useState(false);
  const { projectOpinion, setProjectOpinion } = useContext(VideoRecordingContext);
  const { project } = useContext(ProjectsContext);
  const authenticationToken = useGetToken();
  const videoUrl = projectOpinion?.attributes.video_url;

  const handleOpen = () => {
    setOpenModal(!openModal);
  };

  const handleSubmit = async () => {
    if (!project || !projectOpinion) return;

    const data = {
      notes,
      authenticationToken,
      projectId: project?.id,
      projectOpinionId: projectOpinion.id,
      status: 'sent',
    };

    const response: any = await OpinionsService.updateOpinion(data);
    if (response.data.data.id) {
      toast.success('Opinion submitted successfully');
      setProjectOpinion(response.data.data);
      setOpenModal(false);
    } else {
      toast.error('Error updating the opinion');
    }
  };

  return (
    <>
      <Button
        title="Submit Opinion"
        size="medium"
        kind="submitOpinion"
        renderProps={{ disabled: !videoUrl, onClick: handleOpen }}
      />
      <Modal
        kindOverlay="gat-modal-overlay--blur"
        isOpen={openModal}
        onClose={handleOpen}
        css={{ borderRadius: '0', '@bp2': { borderRadius: '4px' } }}
      >
        <Card type="submitOpinion">
          <Typography size="displayXSmall" weight="600" letterSpacing="letterSpacing3">
            Submit Opinion
          </Typography>
          <OpinionContainerArea>
            <Typography
              size="bodyXSmall"
              weight="500"
              lineHeight="lineHeight1"
              letterSpacing="letterSpacing8"
            >
              <strong>Notes:</strong>
              You can add written notes to this recording (max. 2000 characters)
            </Typography>
            <TextAreaOpinion
              placeholder=""
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              maxLength={2000}
              rows={5}
            />
          </OpinionContainerArea>
          <CheckboxComponent
            text={
              <TextLabel
                css={{
                  fontSize: '$bodyXSmall',
                  fontWeight: '500',
                  lineHeight: '$lineHeight1',
                  letterSpacing: '0.0156rem',
                  color: '$offBlack',
                }}
              >
                By submitting your opinion you agree to the{' '}
                <a href="/experts-terms" target="_blank" rel="noreferrer">
                  Terms and Conditions
                </a>{' '}
                (experts)
              </TextLabel>
            }
            css={{ display: 'flex', alignItem:'center',borderColor: '$grayscale' }}
            checked={checkbox1}
            onChange={() => setCheckbox1(!checkbox1)}
          />
          <FlexContainer align="center" css={{ width: '100%' }}>
            <Button
              title="Submit Opinion"
              css={{ maxWidth: '100%', width: '100%', marginRight: '$spaceXLarge', height: '40px' }}
              renderProps={{ onClick: handleSubmit, disabled: !checkbox1 }}
            />
            <Button
              title="Cancel"
              kind="outline"
              css={{ maxWidth: '100%', width: '100%', height: '40px' }}
              renderProps={{ onClick: handleOpen }}
            />
          </FlexContainer>
        </Card>
      </Modal>
    </>
  );
};

export default SubmitOpinion;
