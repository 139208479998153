import React from 'react';
import { FlexContainer, Typography } from '../../styled';
import { FileInputButton } from './FileInputButton';
import { AvatarContainer, ImgOrganizationAvatar } from '../styled';
import { useImageVerification } from '../../../../shared/hooks/useImageVerification';
import { NoImgOrganizationAvatar } from '../../Sidebar/styled';
import { getInitials } from '../../../../shared/utils/helper';
import { useOrganization } from '../../../../shared/hooks/Organization/useOrganization';

interface PhotoEditorProps {
  imageSrc: string | undefined;
  onChangeImage: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export const PhotoEditor = ({ imageSrc, onChangeImage }: PhotoEditorProps) => {
  const { isValidImage } = useImageVerification(imageSrc as string);
  const { organizationInformation } = useOrganization();
  const handleFileSelect = (file: File) => {
    onChangeImage(file);
  };
  return (
    <FlexContainer gap="2" wrap>
      <FlexContainer direction="column" gap="0_25" maxWidth="adminInfo">
        <Typography size="displayXXSmall" weight="700" lineHeight="lineHeight3">
          Organization logo
        </Typography>
        <Typography size="bodyXSmall" color="monochromeBlack400" weight="500">
          Update your Organization logo
        </Typography>
      </FlexContainer>
      <FlexContainer align="center" gap="2">
        <AvatarContainer isEmpty={isValidImage}>
          {isValidImage ? (
            <ImgOrganizationAvatar src={imageSrc} alt="" />
          ) : (
            <NoImgOrganizationAvatar isAdminPage>
              {getInitials(organizationInformation.name as string)}
            </NoImgOrganizationAvatar>
          )}
        </AvatarContainer>
        <FileInputButton onFileSelect={handleFileSelect} />
      </FlexContainer>
    </FlexContainer>
  );
};
