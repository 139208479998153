/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PeopleData from '../People/PeopleData';
import Icon from '../Icon/Icon';
import { MenuModel } from '../../interfaces/interfaces';
import { getUserName, keyExtractor } from '../../utils/helper';
import {
  ListWrapper,
  List,
  ItemList,
  Box,
  Text,
  HeaderList,
  ListMobile,
  FeedContainer,
  FeedButton,
  BoxSubList,
  HeaderListContent,
} from './styles';
import { AuthButton } from '../Header/styled';
import ExpertIcon from '../../../assets/images/icons/expert.svg';
import ExpertBlackIcon from '../../../assets/images/icons/expert-black.svg';
import ProjectsIcon from '../../../assets/images/icons/projects.svg';
import ProjectsBlackIcon from '../../../assets/images/icons/projects-black.svg';
import DiscoverIcon from '../../../assets/images/icons/discoverIcon.svg';
import DiscoverIconWhite from '../../../assets/images/icons/discoverIcon-white.svg';
import FeedIcon from '../../../assets/images/icons/feedIcon.svg';
import FeedIconWhite from '../../../assets/images/icons/feedIcon-white.svg';
import BackArrow from '../../../assets/images/icons/back-arrow.svg';
import ArrowDownIcon from '../../../assets/images/icons/arrow-down-blue.svg';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import Button from '../Button/Button';
import ItemSubList from './ItemSubList';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface MenuListModel {
  data?: MenuModel[];
  subData?: MenuModel[];
  dataSublist?: MenuModel | undefined;
  userData?: any;
  open: boolean;
  clickEvent?: (e?: any) => void;
  actionSublist?: (e: MenuModel | undefined) => void;
}

type MenuListProps = MenuListModel &
  React.ComponentProps<typeof ListWrapper> & {
    css?: any;
  };

const MenuList: React.FC<MenuListProps> = ({
  data,
  open,
  css,
  userData,
  clickEvent,
  subData,
  actionSublist,
  dataSublist,
}) => {
  // const [openSubList, setOpenSubList] = useState<MenuModel>();
  const refList = useRef<HTMLUListElement | null>(null);
  const isLogged = useIsLoggedIn();
  const navigate = useNavigate();
  const feed = '/';
  const discover = 'discover';
  // eslint-disable-next-line no-nested-ternary
  const expert = !isLogged
    ? '/expert-opinions'
    : userData?.userType === 'seeker'
    ? '/organization/projects'
    : '/experts/projects';
  const expertIconBlack = isLogged ? ProjectsBlackIcon : ExpertBlackIcon;
  const expertIcon = isLogged ? ProjectsIcon : ExpertIcon;
  const { pathname } = window.location;
  const path = pathname?.slice(1) || '';
  const dataName = {
    username: userData?.username,
    first_name: userData?.firstName,
    last_name: userData?.lastName,
  };
  const finalName = getUserName(dataName);
  const indexHome = [feed, '/'].includes(pathname);

  useOnClickOutside(refList, (e) => {
    if (clickEvent && e?.target?.alt !== 'Menu') {
      clickEvent(e);
    }
  });

  const actionEvent = (item: MenuModel) => {
    if (item?.link) {
      if (item.external) {
        window.location.href = `${item?.link}`;
      } else {
        navigate(`/${item?.link}`);
      }
    }
    if (item?.action) {
      item?.action();
    }
    if (clickEvent) {
      clickEvent();
    }
  };

  const actionEventLink = (link: string) => {
    navigate(link);

    if (clickEvent) {
      clickEvent();
    }
  };

  const handleSublist = (opt: MenuModel | undefined) => {
    if (actionSublist) {
      actionSublist(opt);
    }
  };

  if (dataSublist) {
    return (
      <ListWrapper css={css}>
        <List show={open}>
          <FeedContainer css={{ alignItems: 'flex-start', flexDirection: 'column' }}>
            <Button
              title="Go back to menu"
              kind="back"
              css={{ marginBottom: '1rem', img: { marginLeft: '.5rem' } }}
              iconCustom={BackArrow}
              handleClick={() => handleSublist(undefined)}
            />
            <Box>
              <Icon img={dataSublist?.activeIcon} width="14px" height="14px" alt="More" />
              <Text css={{ color: '$label' }}>{dataSublist?.text}</Text>
            </Box>
          </FeedContainer>
          <ListMobile css={{ paddingY: '$spaceXSmall', borderTop: '0px solid transparent' }}>
            {dataSublist?.subItems?.map((item: any) => (
              <ItemList
                key={`item-list-${keyExtractor()}`}
                kind={item.link && path === item.link ? 'active' : undefined}
              >
                <Box css={{ width: '100%' }} onClick={() => actionEvent(item)}>
                  <Icon
                    img={path === item.link ? item.activeIcon : item.icon}
                    width="14px"
                    height="14px"
                    alt={item.text}
                  />
                  <Text>{item.text}</Text>
                </Box>
              </ItemList>
            ))}
          </ListMobile>
        </List>
      </ListWrapper>
    );
  }

  return (
    <ListWrapper css={css}>
      <List ref={refList} show={open}>
        {!isLogged && (
          <FeedContainer css={{ a: { width: '40%' } }}>
            <Link to="/authentication?sign-up=true">
              <AuthButton kind="not-logged" sign-up="true">
                Sign up
              </AuthButton>
            </Link>
            <Link to="/authentication">
              <AuthButton kind="not-logged">Sign in</AuthButton>
            </Link>
          </FeedContainer>
        )}
        <FeedContainer>
          <FeedButton
            kind="feed"
            onClick={() => actionEventLink(`${feed}`)}
            color={indexHome ? 'active' : 'noActive'}
          >
            <Icon img={indexHome ? FeedIconWhite : FeedIcon} width="20" height="20" alt="Feed" />
          </FeedButton>
          <FeedButton
            onClick={() => actionEventLink(`${discover}`)}
            color={path === discover ? 'active' : 'noActive'}
          >
            <Icon
              img={path === discover ? DiscoverIconWhite : DiscoverIcon}
              width="15"
              height="15"
              alt="Discover"
            />
            Discover
          </FeedButton>
          <FeedButton
            onClick={() => actionEventLink(`${expert}`)}
            color={pathname === expert || pathname.includes(expert) ? 'active' : 'noActive'}
          >
            <Icon
              img={pathname === expert || pathname.includes(expert) ? expertIcon : expertIconBlack}
              alt={isLogged ? 'Projects' : 'Expert Opinions'}
              width="15"
              height="15"
            />
            {isLogged ? 'Projects' : 'Experts'}
          </FeedButton>
        </FeedContainer>
        {isLogged && (
          <HeaderList onClick={() => actionEventLink(`/profile/${userData.slug}`)}>
            <HeaderListContent>
              <PeopleData name={finalName} img={userData?.avatarUrl} text="" type="little" />
            </HeaderListContent>
          </HeaderList>
        )}
        {isLogged && subData && (
          <ListMobile>
            {subData.map((item: MenuModel, index: number) => (
              <ul key={`list-mobile-${index}`}>
                <ItemList
                  onClick={() => actionEvent(item)}
                  kind={item?.link && path === item?.link ? 'active' : undefined}
                >
                  <Box>
                    <Icon
                      img={path === item?.link ? item?.activeIcon : item?.icon}
                      width="14px"
                      height="14px"
                      alt={item?.text}
                    />
                    <Text>{item?.text}</Text>
                  </Box>
                </ItemList>
              </ul>
            ))}
          </ListMobile>
        )}

        <ListMobile className="menu-list-mobile">
          {data &&
            data.map((item: MenuModel, index: number) => {
              const propsSublist = { ...item, path, actionEvent, open };
              if (item?.subItems) {
                return (
                  <BoxSubList key={`sub-list-mobile-${index}`}>
                    <ItemSubList {...propsSublist} />
                    <ul>
                      <ItemList kind="resource-mobile" onClick={() => handleSublist(item)}>
                        <Box css={{ width: '100%' }}>
                          <Icon img={item?.icon} width="14px" height="14px" alt={item?.text} />
                          <Text>{item?.text}</Text>
                        </Box>
                        <Icon
                          img={ArrowDownIcon}
                          css={{ transform: 'rotateZ(270deg)' }}
                          width="15px"
                          height="15px"
                          alt="More"
                        />
                      </ItemList>
                    </ul>
                  </BoxSubList>
                );
              }

              return (
                <ul key={`ul-list-${index}`} className="ul-list">
                  <ItemList
                    onClick={() => actionEvent(item)}
                    kind={item?.link && path === item?.link ? 'active' : undefined}
                  >
                    <Box>
                      <Icon
                        img={path === item?.link ? item?.activeIcon : item?.icon}
                        width="14px"
                        height="14px"
                        alt={item?.text}
                      />
                      <Text>{item?.text}</Text>
                    </Box>
                  </ItemList>
                </ul>
              );
            })}
        </ListMobile>
      </List>
    </ListWrapper>
  );
};

MenuList.defaultProps = {
  css: undefined,
  data: undefined,
  subData: undefined,
  userData: undefined,
  clickEvent: undefined,
  dataSublist: undefined,
  actionSublist: () => {},
};

export default MenuList;
