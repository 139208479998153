import { Field } from 'formik';
import InputMask from 'react-input-mask';
import { styled } from '../../../config/theme';

export const Input = styled(InputMask as any, {
  background: 'transparent',
  width: '100%',
  border: 'none',
  paddingX: '$spaceXSmall',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',

  WebkitAppearance: 'none',
  margin: 0,
  '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '&:hover, &:focus': {
    outline: 'none',
  },

  variants: {
    styleType: {
      steps: {
        minHeight: 48,
        borderRadius: '$radius05',
        border: '1px solid $line',
        marginTop: '$spaceXLarge',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $blurple solid',
        },
      },
      errorStep: {
        minHeight: 48,
        borderRadius: '$radius05',
        border: '1px solid $error',
        marginTop: '$spaceXLarge',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $blurple solid',
        },
      },
      credits: {
        minHeight: '3.4rem',
        borderRadius: '$radius05',
        border: '1px solid $line',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $blurple solid',
        },
        width: 'fit-content',
        minWidth: '5.3rem',
      },
      adminInput: {
        minHeight: '3.4rem',
        borderRadius: '$radius05',
        border: '1px solid $grayscaleLine',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $blurple solid',
        },
        width: '100%',
      },
      filterProjectInput: {
        minHeight: '2.2rem',
        borderRadius: '$radius05',
        border: '1px solid $monochromeBlack100',
        fontFamily: '$poppins',
        fontSize: '$bodyXSmall',
        '&:focus': {
          border: '1px $blurple solid',
        },
        width: '100%',
        '@bp3': {
          maxWidth: '23rem',
        },
      },
    },
    credits: {
      true: {
        width: 'auto',
        margin: 0,
        maxWidth: '5rem',
        '@bp2': {
          maxWidth: '$6_25',
        },
      },
    },
    space: {
      0: {
        margin: 0,
      },
    },
    inputWidth: {
      max6: {
        maxWidth: '$6_25',
      },
      max20: {
        maxWidth: '$20',
      },
    },
    textAlign: {
      center: {
        textAlign: 'center',
      },
    },
    maxWidth: {
      6: {
        maxWidth: '$6_25',
      },
    },
    withIcon: {
      true: {
        paddingLeft: '3.3rem',
      },
    },
  },
});

export const InputIconContainer = styled('div', {
  position: 'relative',
  width: '100%',
});
export const IconContainer = styled('div', {
  position: 'absolute',
  top: '56%',
  left: '1rem',
  transform: 'translateY(-50%)',
});

export const InputForm = styled(Field, {
  minHeight: 48,
  borderRadius: '$radius05',
  border: '1px solid $line',
  marginTop: '$spaceXLarge',
  fontFamily: '$poppins',
  background: 'transparent',
  width: '100%',
  paddingX: '$spaceXSmall',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  '&:focus': {
    border: '1px $blurple solid',
  },
  variants: {
    error: {
      true: {
        borderRadius: '$radius05',
        border: '1px solid $error',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $error solid',
        },
      },
    },
    member: {
      true: {
        height: 46,
        background: '$grayscaleInput',
        border: 'none',
        '@bp3': {
          height: 56,
        },
      },
    },
    space: {
      none: {
        marginTop: 0,
      },
    },
  },
});

export const Textarea = styled('textarea', {
  background: 'transparent',
  width: '100%',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  padding: '$spaceSmall',
  borderRadius: '$radius05',
  border: '1px solid $line',
  fontFamily: '$poppins',
  '&:focus': {
    border: '1px $blurple solid',
  },
  variants: {
    error: {
      true: {
        borderRadius: '$radius05',
        border: '1px solid $error',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $error solid',
        },
      },
    },
  },
});

export const ErrorLabel = styled('div', {
  fontSize: '$bodyXSmall',
  fontWeight: '$weight500',
  marginTop: 'calc($spaceSmall / 2 )',
  color: '$error',
});

export const FormInputContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});
