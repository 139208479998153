import { styled } from '../../../config/theme';

export const InputWrapper = styled('div', {
  background: '$input',
  maxWidth: '647px',
  width: '100%',
  display: 'flex',
  borderRadius: '$radius05',
  alignItems: 'center',
  position: 'relative',
  minHeight: '2.5rem',
  margin: '0 0.5rem',
  button: {
    background: '$input',
  },
  '@bp2': {
    width: '100%',
    marginLeft: '1rem',
  },
  '@bp4': {
    marginLeft: '1.5rem',
  },
  variants: {
    activeWrite: {
      true: {
        '.gat-arrow-icon': {
          display: 'none',
          '@bp2': {
            display: 'flex',
          },
        },
      },
    },
    kind: {
      'not-logged': {
        width: '80%',
        marginLeft: '0px',
        '@bp3': { maxWidth: '100%' },
      },
    },
  },
});

export const Input = styled('input', {
  background: 'transparent',
  width: '100%',
  border: 'none',
  paddingX: '$spaceXSmall',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
});

export const ListWrapper = styled('div', {
  position: 'absolute',
  width: '100%',
  top: '100%',
  height: '350px',
  display: 'flex',
  flexDirection: 'column',
});

export const ListResult = styled('ul', {
  position: 'absolute',
  listStyle: 'none',
  margin: 0,
  paddingLeft: 0,
  backgroundColor: 'white',
  boxShadow: '$shadowBase',
  color: '$offBlack',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1rem',
  marginTop: '.5rem',
  opacity: 1,
  zIndex: 1,
  minWidth: 170,
  width: 'max-content',

  variants: {
    empty: {
      true: {
        zIndex: -1,
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
});

export const ItemResult = styled('li', {
  display: 'block',
  cursor: 'pointer',
  float: 'left',
  padding: '0.25rem 1rem',
  position: 'relative',
  textDecoration: 'none',
  transitionDuration: '0.5s',
  '&:first-child': {
    paddingTop: '1rem',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
  },
  '&:last-child': {
    paddingBottom: '1rem',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
  },
  '&:hover': {
    backgroundColor: '$input',
  },
});

export const Box = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '& img': {
    marginRight: '12px !important',
  },
  '& .gat-avatar-initials': {
    marginRight: '12px !important',
  },
});

export const NamesWrapper = styled('div', {
  '@bp0': {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const Text = styled('p', {
  fontWeight: '$weight500',
  paddingLeft: 10,
});

export const TextSimple = styled('span', {
  fontWeight: '$normal',
});

export const TextGray = styled(TextSimple, {
  color: '$label',
  paddingLeft: 10,
});

export const List = styled(ListResult, {
  top: '100%',
  padding: '0.3rem',
  marginTop: '0.5rem',
  '@bp2': {
    marginTop: '1rem',
  },
  variants: {
    kind: {
      orderBy: {
        '@base': {
          top: '5.5rem',
        },
        '@bp2': {
          top: '100%',
        },
      },
    },
  },
});

export const WrapText = styled('div', {
  display: 'none',
  '@bp2': {
    display: 'flex',
  },
  variants: {
    kind: {
      orderBy: {
        display: 'flex',
      },
    },
  },
});

export const SelectWrapper = styled('div', {
  background: 'transparent',
  borderRadius: '.25rem',
  width: '18%',
  height: '100%',
  display: 'flex',
  color: '$offBlack',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingLeft: '$spaceXSmall',
  fontSize: '$bodyXSSmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  cursor: 'pointer',
  order: '4',
  '& div span': {
    textTransform: 'capitalize',
  },
  '& div #filter-content': {
    display: 'none',
  },
  '@bp1': {
    fontSize: '$bodyXSmall',
  },
  '@bp2': {
    width: '100%',
    position: 'relative',
    borderRadius: '$radius1',
    justifyContent: 'space-between',
    order: '1',
    maxWidth: 150,
    '&::after': {
      width: '1px',
      minHeight: '2.5rem',
      content: '',
      position: 'absolute',
      backgroundColor: 'hsla(233, 26%, 88%, 1)',
      right: 1,
    },
  },
  '@bp3': {
    maxWidth: 200,
  },
  '@bp5': {
    maxWidth: 230,
    width: '45.5%',
    paddingLeft: '$spaceLarge',
    '& div #filter-content': {
      display: 'inline',
      marginRight: '0.25rem',
    },
    '& div span': {
      textTransform: 'initial',
    },
  },
  variants: {
    kind: {
      orderBy: {
        minWidth: '8.125rem',
        justifyContent: 'space-between',
        '&::after': {
          backgroundColor: 'unset',
        },
      },
    },
  },
});

export const Span = styled('span', {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '80px',
  overflow: 'hidden',
  '@bp2': {
    maxWidth: '100px',
  },
  '@bp3': {
    maxWidth: '100%',
  },

  variants: {
    kind: {
      orderBy: {
        fontWeight: ' 400',
      },
    },
  },
});

export const IconWrapper = styled('div', {
  background: 'transparent',
  borderRadius: '$radius1',
  width: '5%',
  height: '100%',
  minWidth: '$2',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '$spaceXSmall',
  order: '1',
  '& img': {
    marginRight: '0',
  },
  '@bp2': {
    order: '2',
  },
});
