import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import ResourcesSearch from '../ResourcesSearch/ResourcesSearch';
import MenuPopover from '../MenuPopover/MenuPopover';
import {
  HeaderContainer,
  AuthButton,
  FeedContainer,
  FeedButton,
  KBLink,
  AuthWrapperButton,
} from './styled';
import ExpertIcon from '../../../assets/images/icons/expert.svg';
import ExpertBlackIcon from '../../../assets/images/icons/expert-black.svg';
import ProjectsIcon from '../../../assets/images/icons/projects.svg';
import ProjectsBlackIcon from '../../../assets/images/icons/projects-black.svg';
import DiscoverIcon from '../../../assets/images/icons/discoverIcon.svg';
import DiscoverIconWhite from '../../../assets/images/icons/discoverIcon-white.svg';
import FeedIcon from '../../../assets/images/icons/feedIcon.svg';
import FeedIconWhite from '../../../assets/images/icons/feedIcon-white.svg';
import KnowledgeIcon from '../../../assets/images/icons/bookmark-black.svg';
import KnowledgeIconWhite from '../../../assets/images/icons/bookmark-saved.svg';
import useGetCurrentUserSlug from '../../hooks/useGetCurrentUserSlug';
import Logo from './Logo/Logo';
import AppContext from '../../context/app/AppContext';

interface HeaderModel {
  logged: boolean;
}

const Header: React.FunctionComponent<HeaderModel> = ({ logged }) => {
  const { state, dispatch } = useContext(AppContext);
  const { userData } = state as any | undefined;
  const { pathname } = window.location;
  const feed = '/';
  const discover = '/discover';
  // eslint-disable-next-line no-nested-ternary
  const expert = !logged
    ? '/expert-opinions'
    : userData?.userType === 'seeker'
    ? '/organization/projects'
    : '/experts/projects';
  const userSlug = useGetCurrentUserSlug();
  const knowledge = `users/${userSlug}/playlists`;
  const indexHome = [feed, '/'].includes(pathname);
  const { filterContent } = state;

  const actionNavigate = () => {
    dispatch({
      type: 'SET_FILTER',
      payload: {
        ...filterContent, filter: undefined, page: 1, category: 'feed', type: 'content'
      }
    });
  };

  // User Logged
  if (logged) {
    return (
      <HeaderContainer className="gat-header">
        <Logo />
        <FeedContainer>
          <Link to={feed} title="For You, on Gathersight" onClick={actionNavigate}>
            <FeedButton
              aria-label="For You, on Gathersight"
              kind="feed"
              color={indexHome ? 'active' : 'noActive'}
            >
              <Icon img={indexHome ? FeedIconWhite : FeedIcon} width="20" height="20" alt="Feed" />
            </FeedButton>
          </Link>
          <Link to={discover} title="Discover, on Gathersight">
            <FeedButton
              aria-label="Discover, on Gathersight"
              kind="discover"
              color={pathname === discover ? 'active' : 'noActive'}
            >
              <Icon
                img={pathname === discover ? DiscoverIconWhite : DiscoverIcon}
                alt="Discover"
                width="20"
                height="20"
              />
            </FeedButton>
          </Link>
          <Link to={expert} title="Expert Opinions, on Gathersight">
            <FeedButton
              aria-label="Expert Opinions, on Gathersight"
              kind="projects"
              color={
                pathname === expert || pathname.includes('organization') ? 'active' : 'noActive'
              }
            >
              <Icon
                img={
                  pathname === expert || pathname.includes('organization')
                    ? ProjectsIcon
                    : ProjectsBlackIcon
                }
                alt="Expert Opinions"
                width="15"
                height="15"
              />
            </FeedButton>
          </Link>
        </FeedContainer>

        <ResourcesSearch />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <KBLink to={knowledge} title="My Knowledge Playtlists, on Gathersight">
            <FeedButton
              aria-label="My Knowledge Playtlists, on Gathersight"
              kind="icon"
              color={pathname.includes('playlists') ? 'active' : 'noActive'}
            >
              <Icon
                img={pathname === knowledge ? KnowledgeIconWhite : KnowledgeIcon}
                width="16"
                height="18"
                alt="Feed"
              />
            </FeedButton>
          </KBLink>
          <MenuPopover />
        </div>
      </HeaderContainer>
    );
  }

  // User NOT logged in
  return (
    <HeaderContainer className="gat-header">
      <Logo />
      <FeedContainer kind="not-logged">
        <Link to={feed} onClick={actionNavigate}>
          <FeedButton name="feed" kind="feed" color={indexHome ? 'active' : 'noActive'}>
            <Icon img={indexHome ? FeedIconWhite : FeedIcon} width="15" height="15" alt="Feed" />
          </FeedButton>
        </Link>
        <Link to={discover}>
          <FeedButton
            name="discover"
            kind="discover"
            color={pathname === discover ? 'active' : 'noActive'}
          >
            <Icon
              img={pathname === discover ? DiscoverIconWhite : DiscoverIcon}
              width="15"
              height="15"
              alt="Discover"
            />
          </FeedButton>
        </Link>
        <Link to={expert} title="Expert Opinions, on Gathersight">
          <FeedButton
            aria-label="Expert Opinions, on Gathersight"
            kind="expert"
            color={pathname === expert ? 'active' : 'noActive'}
          >
            <Icon
              img={pathname === expert ? ExpertIcon : ExpertBlackIcon}
              alt="Expert Opinions"
              width="15"
              height="15"
            />
          </FeedButton>
        </Link>
      </FeedContainer>
      <ResourcesSearch kind="not-logged" />
      <AuthWrapperButton type="not-logged">
        <Link to="/authentication?sign-up=true">
          <AuthButton kind="not-logged" sign-up="true">
            Sign up
          </AuthButton>
        </Link>
        <Link to="/authentication#sign-in">
          <AuthButton kind="not-logged">Sign in</AuthButton>
        </Link>
      </AuthWrapperButton>
      <AuthWrapperButton type="not-logged-mobile">
        <MenuPopover />
      </AuthWrapperButton>
    </HeaderContainer>
  );
};

export default Header;
