import { styled } from '../../../config/theme';

export const WrapperTextLimiterLink = styled('div', {
  'a': {
    paddingLeft: 5,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '$label',
    fontWeight: 700
  }
});
