import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TextDescription, TypographyH2, TypographyH3 } from '../../styled';
import {
  HowToGetContentContainer,
  HowToGetImg,
  HowToGetImgContainer,
  HowToGetStepsContainer,
  HowToGetStepsSubContainer,
  StyledLink
} from './styled';
import Button from '../../../../shared/components/Button/Button';
import ExpertOpinionOrganizationImg from '../../../../assets/images/experts/ExpertOpinionOrganization.png';
import { keyExtractor } from '../../../../shared/utils/helper';

const HowToGetSteps = [
  {
    title: '1. Setup',
    text: [
      'Enter a description of your project or a description of the insights you’re looking for in the ',
      <StyledLink key="new-project-link" to="/new-project">project start page</StyledLink>,
      '. From the information in your text, our language system creates a set of questions for experts to answer. You can customize the question set as much as you want.',
    ],
  },
  {
    title: '2. Expert Matching',
    text: 'Once you’re happy with the question set and enter the number of Expert Opinions you want, our matching sequence automatically identifies the most qualified biotech specialists in our network and sends each expert a request for their recorded opinion.',
  },
  {
    title: '3. Delivery',
    text: 'Experts record their answers to your question set and the videos are posted in your account along with a transcript. ',
  },
];

export const HowToGet = () => {
  const navigate = useNavigate();
  return (
    <ExpertContainer bg="offWhite" space="section">
      <TypographyH2
        type="displaySmall"
        align="center"
        lineHeight="normal"
        spacing="opinionTitleSection"
      >
        How to get your  <br/>
        <b>Expert Opinions</b>
      </TypographyH2>
      <HowToGetContentContainer>
        <HowToGetImgContainer>
          <HowToGetImg src={ExpertOpinionOrganizationImg} shadow="opinion" />
        </HowToGetImgContainer>
        <HowToGetStepsContainer>
          {HowToGetSteps.map((step) => (
            <HowToGetStepsSubContainer>
              <TypographyH3
                key={`title-${keyExtractor()}-${step.title}`}
                align="left"
                color="bluerplePrimary"
                weight="700"
                type="bodyLarge"
              >
                {step.title}
              </TypographyH3>
              <TextDescription key={`description-${keyExtractor()}-${step.title}`}>
                {Array.isArray(step.text) ? (
                  step.text.map((part) => (
                    <span key={`text-part-${keyExtractor()}`}>
                      {part}
                    </span>
                  ))
                ) : (
                  step.text
                )}
              </TextDescription>
            </HowToGetStepsSubContainer>
          ))}
          <Button width="auto"
            title="Get Expert Opinion"
            handleClick={() => navigate('/new-project')}
          />
        </HowToGetStepsContainer>
      </HowToGetContentContainer>
    </ExpertContainer>
  );
};
