import React, { useContext, useState, useEffect } from "react";
import CardResourcesList from '../../../../../shared/components/CardResources/CardResourcesList';
import Collapsible from '../../../../../shared/components/Collapsible/Collapsible';
import AppContext from "../../../../../shared/context/app/AppContext";
import { Resources } from "../../../../../shared/interfaces/interfaces";
import { ItemsAddedWrapper } from "./style";

const ItemsAdded = () => {
  const { state } = useContext(AppContext);
  const [firstData, setFirstData] = useState<Resources[]>([]);
  const [secondData, setSecondData] = useState<Resources[]>([]);
  const { newPlaylistItems } = state;
  const exitData = newPlaylistItems?.length > 0;
  const style = {
    paddingTop: '$spaceLarge',
    '@bp3': {
      paddingTop: '$spaceMedium',
    }
  };
  const styleWrapper = {
    paddingBottom: '2.5rem'
  };

    useEffect(() => {
      const reverseData = newPlaylistItems.reverse();
      const firstChild = exitData ? [reverseData[0]] : [];
      const childContent = (reverseData?.length > 1) ?
        reverseData.filter((i, index) => index > 0) : [];

        setFirstData(firstChild);
        setSecondData(childContent);

      return () => {
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPlaylistItems]);

  return exitData ? (
    <ItemsAddedWrapper css={styleWrapper}>
      <Collapsible
        firstChild={<CardResourcesList data={firstData as Resources[]} updatePlaylist />}
        textButton='See all saved posts'
        css={style}
      >
        {secondData.length > 0 &&
          <CardResourcesList data={secondData as Resources[]} updatePlaylist />}
      </Collapsible>
    </ItemsAddedWrapper>
  )
    :
    <div />
    ;
};

export default ItemsAdded;