import axiosInstance from '../utils/axiosInstance';

export interface SaveVideoInterface {
  video?: String;
  authenticationToken: string;
  projectId: string;
  projectOpinionId?: string;
  notes?: string;
  status?: string;
  userId?: string;
}

export const OpinionsService = {
  userProjectOpinion: async (data: SaveVideoInterface) => {
    const { authenticationToken, projectId, userId } = data;

    try {
      const url = `projects/${projectId}/project_opinions/${userId || 'expert'}`;
      const results = await axiosInstance(authenticationToken).get(url);
      return results;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error occurred while sending video to the API:', error);
      return [];
    }
  },

  createOpinion: async (data: SaveVideoInterface) => {
    const { video, authenticationToken, projectId } = data;

    try {
      const results = await axiosInstance(authenticationToken).post(
        `projects/${projectId}/project_opinions`,
        { video },
      );
      return results;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error occurred while sending video to the API:', error);
      return [];
    }
  },
  updateOpinion: async (data: SaveVideoInterface) => {
    const { video, authenticationToken, projectId, projectOpinionId, notes, status } = data;
    const url = `projects/${projectId}/project_opinions/${projectOpinionId}`;
    try {
      const results = await axiosInstance(authenticationToken).patch(url, { video, notes, status });
      return results;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error occurred while sending video to the API:', error);
      return [];
    }
  },
  deleteOpinion: async (data: SaveVideoInterface) => {
    const { authenticationToken, projectId, projectOpinionId } = data;
    const url = `projects/${projectId}/project_opinions/${projectOpinionId}`;
    try {
      const results = await axiosInstance(authenticationToken).delete(url);
      return results;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error occurred while sending video to the API:', error);
      return [];
    }
  },
};
