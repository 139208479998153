import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '../Icon/Icon';
import './customReactToastify.css';
import closeIcon from '../../../assets/images/icons/close.svg';

const CustomToastNotifications = () => (
  <ToastContainer
    autoClose={5000}
    newestOnTop
    limit={1}
    closeOnClick={false}
    draggable
    position={toast.POSITION.BOTTOM_RIGHT}
    hideProgressBar
    closeButton={
      <Icon alt="Close" img={closeIcon} width="12" height="12" css={{ alignSelf: 'center' }} />
    }
  />
);

export default CustomToastNotifications;
