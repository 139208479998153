import axiosInstance from "./utils/axiosInstance";
import { RequestModel } from '../shared/interfaces/interfaces';

export interface RequestKnowledge extends RequestModel {
  title?: string;
  description?: string;
  by_title?: string;
  userId?: string;
  contentId?: string;
  kind?: string;
  isPublic?: boolean;
};

export interface UpdatePlaylistRequestType {
  playlistId: string | (string | null)[];
  token: string | (string | null)[]; 
}

export interface UpdatePlaylistCollaboratorType {
  playlistId: string | (string | null)[];
  token: string | (string | null)[];
  authenticationToken: string;
}

export const KnowledgeService = {
  createKnowledge: async ( data: RequestKnowledge ) => {
    const { authenticationToken, description, title, isPublic } = data;
    try {
      const results = await axiosInstance(authenticationToken).post(`knowledge_books`, {},
      { params: { description, title, public: isPublic }})
        .then(response => (response?.data?.data || []));
      return results;
    } catch (error) {
      return [];
    }
  },
  createKnowledgeItem: async ( data: RequestKnowledge, kpName:string ) => {
    const { authenticationToken, id, contentId, filter } = data;
    try {
      const results = await axiosInstance(authenticationToken)
      .post(`knowledge_books/${id}/knowledge_book_items`, {}, { params: {
        content_id: contentId, kind: filter
      }}).then(response => (response?.data?.data || {}));
      return results;
    } catch (error: any) {
      return { fail: true, message: `This content is already saved on the ${kpName} Playlist` };
    }
  },
  deleteKnowledgeItem: async ( data: RequestKnowledge, idItem: any ) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken).delete(`knowledge_books/${id}/knowledge_book_items/${idItem}`)
        .then(response => (response?.data || {}));
      return { ...results, fail: false  };
    } catch (error: any) {
      return { fail: true, message: `Can't delete` };
    }
  },
  getIndex: async ( data: RequestKnowledge ) => {
    const { page, limit, authenticationToken, filterBy, publicPlaylist, userID } = data;
    try {
      const params = { page, per_page: limit, by_title: filterBy, public: publicPlaylist,
        user_id: userID };
      const results = await axiosInstance(authenticationToken).get(`knowledge_books`,
        { params })
        .then(response => (response?.data?.data || []));
      return results;
    } catch (error) {
      return [];
    }
  },
  showDetail: async (data: RequestKnowledge, idKb: string) => {
    const { authenticationToken } = data;

    try {
      const results = await axiosInstance(authenticationToken).get(`knowledge_books/${idKb}`)
        .then(response => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  showItems: async (data: RequestKnowledge) => {
    const { authenticationToken, page, limit, filterBy, id } = data;

    try {
      const results = await axiosInstance(authenticationToken).get(`knowledge_books/${id}/knowledge_book_items`,
        { params: { page, per_page: limit, items_by: filterBy }})
        .then(response => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  deleteKB: async ( data: RequestKnowledge ) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken).delete(`knowledge_books/${id}`)
        .then(response => (response?.data?.data || {}));
      return { ...results, fail: false  };
    } catch (error: any) {
      return { fail: true, message: `Can't delete` };
    }
  },
  editKB: async ( data: RequestKnowledge ) => {
    const { authenticationToken, description, title, isPublic, id } = data;

    try {
      const results = await axiosInstance(authenticationToken).put(`knowledge_books/${id}`, {},
        { params: { description, title, public: isPublic }})
        .then(response => (response?.data?.data || {}));
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't edit` };
    }
  },
  createKBCollaborators: async ( data: RequestKnowledge, email:any ) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
      .post(`knowledge_books/${id}/knowledge_book_collaborators`, {}, { params: {
        email
      }}).then(response => (response?.data?.data || {}));
      return { ...results, fail: false  };
    } catch (error: any) {
      return { fail: true, message: `Can't assign` };
    }
  },
  updateKBCollaborators: async (data: UpdatePlaylistCollaboratorType) => {
    const { playlistId, token, authenticationToken } = data;
    const url = `knowledge_books/${playlistId}/knowledge_book_collaborators`;
    const params = { params: { token }};
    try {
      const results = await axiosInstance(authenticationToken).put(url, {}, params).then(
        response => (response?.data?.data || {}));
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't update` };
    }
  },
  deleteKBCollaborators: async ( data: RequestKnowledge, idCollaborator: any ) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .delete(`knowledge_books/${id}/knowledge_book_collaborators/${idCollaborator}`)
        .then(response => (response?.data || {}));
      return { ...results, fail: false  };
    } catch (error: any) {
      return { fail: true, message: `Can't delete` };
    }
  },
  sendRequest: async ( data: RequestKnowledge) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
      .post(`knowledge_books/${id}/knowledge_book_requests`)
      .then(response => (response?.data?.data || {}));
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't send request` };
    }
  },
  acceptRequest: async ( data: UpdatePlaylistRequestType) => {
    const { playlistId, token } = data;
    const url = `knowledge_books/${playlistId}/knowledge_book_requests`;
    try {
      const results = await axiosInstance().put(url, {}, { params: {token} }).then(
        response => (response?.data?.data || {}));
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't update request` };
    }
  },
  getUserPlayList: async (data: RequestKnowledge) => {
    const { authenticationToken, id } = data;

    try {
      const results = await axiosInstance(authenticationToken).get(`users/${id}/knowledge_books`,
        { params: { page: 1, limit: 1 }})
        .then(response => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  showPlaylistTop: async (data: RequestKnowledge) => {
    const { authenticationToken, page, limit, } = data;

    try {
      const results = await axiosInstance(authenticationToken).get('knowledge_books',
        { params: { page, per_page: limit, top: true }})
        .then(response => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
};
