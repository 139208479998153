import Modal from 'react-modal';
import { styled } from '../../../config/theme';

// @ts-ignore
export const ModalDiv = styled(Modal, {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  borderRadius: '4px',
  outline: 'none',
  zIndex: '10',
  '@bp3': {
    height: 'inherit',
    padding: '0',
  },
  variants: {
    kind: {
      resources: {
        '@bp3': {
          minWidth: 700,
        },
      },
      knowledge: {
        maxWidth: '450px',
      },
      share: {
        minWidth: '300px',
        '@bp4': {
          inset: 'auto',
        },
      },
      'share-hide': {
        minWidth: '300px',
      },
      'invitation-block': {
        width: '100%',
        height: '100%',
      },
      infinity: {
        width: '95%',
        overflow: 'hidden',
        '@bp2': {
          width: 'auto',
          minWidth: 700,
        },
        '& .card-infinity': {
          maxHeight: '80vh',
          overflow: 'hidden',
        },
      },
      'infinity-big': {
        width: '95%',
        overflow: 'hidden',
        '@bp2': {
          maxWidth: '1020px',
          minWidth: 700,
        },
        '& .card-infinity': {
          maxHeight: '80vh',
          overflow: 'hidden',
        },
      },
    },
  },
});
