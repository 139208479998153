import React, { useMemo, ReactElement, ReactNode, useState } from 'react';
import UploadVideoContext from './uploadVideoContext';

const UploadVideoProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const value = useMemo(() => ({ uploadProgress, setUploadProgress }), [uploadProgress]);

  return <UploadVideoContext.Provider value={value}>{children}</UploadVideoContext.Provider>;
};

export default UploadVideoProvider;
