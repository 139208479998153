import React from "react";
import { TabsList, TabsTrigger } from "./styled";

const SettingsTabsHeader = ({ dataTabs }: { dataTabs: { key: string; name: string }[] }) => (
  <TabsList aria-label="User settings">
    {dataTabs?.map(({ key, name }) => (
      <TabsTrigger key={key} value={key}>
        {name}
      </TabsTrigger>
    ))}
  </TabsList>
);

export default SettingsTabsHeader;