/* eslint-disable no-unused-vars */
import React from "react";
import { Label, Input, Indicator, BoxLabel } from './style';

interface RadioButtonModel {
  value: undefined | string | number;
  onClick: (event: any) => Promise<any> | void;
  onChange?: (event: any) => void;
  name: string;
  id: string;
  label: string;
  disabled: boolean;
  checked: boolean;
}

const RadioButton: React.FunctionComponent<RadioButtonModel> = ({
  value,
  onClick,
  onChange,
  name,
  id,
  label,
  disabled,
  checked
}) => (
  <Label htmlFor={id}>
    <BoxLabel>
      {label}
    </BoxLabel>
    <Input
      id={id}
      type="radio"
      role="radio"
      name={name}
      value={value}
      disabled={disabled}
      onClick={onClick}
      onChange={onChange}
      checked={checked}
    />
    <Indicator className='check-indicator' />
  </Label>
);

RadioButton.defaultProps = {
  onChange: undefined
};

export default RadioButton;
