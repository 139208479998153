import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BodyContainer, Column, ContentContainer, FlexContainer } from '../styled';
import { Questions } from './Questions';
import HeaderProject from './HeaderProject';
import { ProjectTags } from './ProjectTags';
import { UploadFiles } from './UploadFiles';
import ProjectOpinions from './ProjectOpinions';
import ProjectMembers from './ProjectMembers';
import useGetUserType from '../../../shared/hooks/getUserType';
import ProjectExperts from './ProjectExperts';
import { Line } from './styled';
import { useProjects } from '../../../shared/hooks/useProjects';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';

export const OrganizationDefaultPage = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isDescriptionChanged, setIsDescriptionChanged] = useState<boolean>(false);
  const userType = useGetUserType();
  const { slug } = useParams();
  const { getProject } = useProjects();
  const { setProject } = useContext(ProjectsContext);

  useEffect(() => {
    if (userType === 'expert') {
      const url = window.location.href;
      const newURL = url.replace(/organization/g, 'experts');
      window.location.href = newURL;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (slug) getProject();

    return () => setProject(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleShowSaved = async () => {
    await setIsSaved(true);
    setTimeout(() => {
      setIsSaved(false);
    }, 3000);
  };

  return (
    <BodyContainer>
      <ContentContainer>
        <Column columns="1">
          <FlexContainer direction="column" gap="2" maxWidth="700">
            <HeaderProject
              isEditing={isEditing}
              handleEdit={handleEditToggle}
              setIsShowSaved={handleShowSaved}
              isDescriptionChanged={isDescriptionChanged}
              setIsDescriptionChanged={setIsDescriptionChanged}
            />
            <Line />
            <Questions
              editable={isEditing}
              handleEditToggle={handleEditToggle}
              isShowSaved={isSaved}
              setIsShowSaved={handleShowSaved}
              isDescriptionChanged={isDescriptionChanged}
            />
            <ProjectTags />
            <FlexContainer gap="2" direction="onlyMobileColumn">
              <ProjectOpinions />
            </FlexContainer>
            <UploadFiles />
            <ProjectMembers />
            <Line transparentDesk />
          </FlexContainer>
        </Column>
        <Column columns="2">
          <ProjectExperts />
        </Column>
      </ContentContainer>
    </BodyContainer>
  );
};
