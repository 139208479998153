import { styled } from '../../../../config/theme';

export const AddPicture = styled('div', {
  marginLeft: '$spaceLarge',
  padding: '$spaceXSmall $spaceSmall',
  backgroundColor: 'hsl(233deg 26% 88% / 36%);',
  borderRadius: '5px',
  color: 'hsla(248, 16%, 35%, 1)',
  fontWeight: '$weight500',
  fontSize: '$bodyXSmall',
  display: 'flex',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '$input',
    color: '$body',
    opacity: '0.7',
  },

  'img': {
    width: 14,
    height: 14,
    margin: '0 $spaceXSmall 0 0 ',
  
  },
});

export const InputImg = styled('input', {
  display: 'none',
});

export const WrapperLoading = styled('div', {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  opacity: '0.8',
  top: '0'
});

export const WrapperPicture = styled('div', {
  margin: '1.5rem auto',
  position: 'relative',
  '@bp2': {
    margin: '4rem auto',
  }
});