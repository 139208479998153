import { styled } from '../../../config/theme';

export const Input = styled('input', {
  height: '0',
  width: '0',
  opacity: '0',
  zIndex: '-1',
  variants: {
    checked: {
      true: {
        '& + .check-indicator': {
          backgroundColor: '$blurple',
          '&::after': {
            backgroundColor: '$input',
          },
        },
      },
    },
  },
});

export const Label = styled('label', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: '$weight500',
  fontSize: '$bodySmall',
});

export const Indicator = styled('div', {
  borderRadius: '50%',
  backgroundColor: '$blurpleHover',
  marginRight: '1rem',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@bp2': {
    width: '1.333rem',
    height: '1.333rem',
  },
  '&:hover': {
    backgroundColor: 'hsla(241, 37%, 12%, 1)',
  },
  '&::after': {
    content: '',
    backgroundColor: '$blurpleHover',
    height: '0.667rem',
    width: '0.667rem',
    borderRadius: '50%',
  },
});

export const BoxLabel = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'max-content',
});
