import React from 'react';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TypographyH2, TypographyH3 } from '../../styled';
import {
  BlueElipseIconContainer,
  ItemContainer,
  ItemsContainer,
  TypographyContainer,
} from './styled';
import { TelescopeIcon } from './Icons/TelescopeIcon';
import { PresentationBoardIcon } from './Icons/PresentationBoardIcon';
import { OpenBookIcon } from './Icons/OpenBookIcon';
import { PuzzleIcon } from './Icons/PuzzleIcon';
import { ShowChartIcon } from './Icons/ShowChartIcon';
import { BasketIcon } from './Icons/BasketIcon';
import { MortarboardIcon } from './Icons/MortarboardIcon';
import { keyExtractor } from '../../../../shared/utils/helper';

const ItemsData = [
  {
    icon: <TelescopeIcon />,
    text: 'Market research',
  },
  {
    icon: <PresentationBoardIcon />,
    text: 'Investment due diligence',
  },
  {
    icon: <OpenBookIcon />,
    text: 'R&D',
  },
  {
    icon: <PuzzleIcon />,
    text: 'Product development',
  },
  {
    icon: <ShowChartIcon />,
    text: 'Competitive analysis',
  },
  {
    icon: <BasketIcon />,
    text: 'Market entry assessment',
  },
  {
    icon: <MortarboardIcon />,
    text: 'Academic studies',
  },
];

export const UsedThroughout = () => (
  <ExpertContainer space='section' bg="monochromeWhite">
    <TypographyContainer>
      <TypographyH2 align="center" type="displaySmall_industries" lineHeight="normal">
        Expert Opinions are used throughout <br/>
        <b>industry and research communities</b>
      </TypographyH2>
    </TypographyContainer>
    <ItemsContainer>
      {ItemsData.map((item) => (
        <ItemContainer key={keyExtractor()}>
          <BlueElipseIconContainer>{item.icon}</BlueElipseIconContainer>
          <TypographyH3 align="centerAndLeftMobile" type="expertOpinionLandingSection" weight="600" spacing="noSpace">
            {item.text}
          </TypographyH3>
        </ItemContainer>
      ))}
    </ItemsContainer>
  </ExpertContainer>
);
