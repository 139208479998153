import React, { useMemo } from "react";
import { useTransition, animated, config } from 'react-spring';
import CardResourcesList from '../../../../shared/components/CardResources/CardResourcesList';


const ResultCard = (props: any) => {
  const transitions = useTransition(props?.open, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: config.stiff
  });


  return useMemo(() => transitions((styles, item) => 
  item ? (
    <animated.div style={{ width: '100%', opacity: styles.opacity }}>
      <CardResourcesList {...props} />
    </animated.div>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ) : null), [transitions]);
};

export default React.memo(ResultCard);
