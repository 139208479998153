import { Form, Field } from 'formik';
import { styled } from '../../../config/theme';

export const WrapperShare = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  '& button': {
    padding: 0
  },
  variants: {
    kind: {
      hide: {
        display: 'none'
      }
    },
    isInvite: {
      true: {
        marginTop: 0,
      }
    }
  }
});

export const Social = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '46px',
  height: '46px',
  borderRadius: '50%',
  border: '1px solid $body',
  cursor: 'pointer'
});

export const SocialWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const BoxContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '1.5rem'
});

export const TextTitle = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodyLarge',
  lineHeight: '$lineHeight3',
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  justifyContent: 'space-between',
  '& img': {
    margin: 0
  }
});

export const BoxText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '1rem'
});

export const Label = styled('label', {
  position: 'absolute',
  display: 'inline-block',
  left: '15px',
  top: '16px',
  transition: 'all 150ms ease-in',
  transform: 'translateY(-30px) translateX(-6px)',
  fontSize: '$bodyXSmall',
  color: '$label',
  padding: '0 6px',
  backgroundColor: '$offWhite'
});

export const InputWrapper = styled('div', {
  background:'$input',
  width: '100%',
  minHeight: '2.5rem',
  display: 'flex',
  borderRadius: '$radius05',
  alignItems: 'center',
  position: 'relative',
  marginTop: '$spaceSmall',
  marginBottom: '$spaceSmall'
});

export const InputWrapperBtn = styled('div', {
  position: 'relative',
  width: '100%',
  minHeight: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  ' input': {
    background:'$input',
    borderRadius: '$radius08',
    marginRight: '$spaceXSmall'
  }
});

export const Input = styled('input', {
  background:'transparent',
  width: '100%',
  border: 'none',
  outline: 'none',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing:'$letterSpacing3',
  fontSize: '$bodySmall',
  padding: '$spaceSmall',
  '-webkit-appearance': 'none !important',
  zIndex: 1
});

export const IconWrapper = styled('div', {
  background: 'transparent',
  borderRadius: '$radius1',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '$spaceSmall',
  '& img': {
    margin: 0
  }
});

export const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: '$spaceXSmall'
});

export const List = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100px',
  maxHeight: '288px',
  alignItems: 'baseline',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: 3,
    height: 12,
  },
  '&::-webkit-scrollbar-track': { 
    backgroundClip: 'content-box' 
  },
  '&::-webkit-scrollbar-thumb': {
    background: '$input',
    borderRadius: 5,
  },

  variants: {
    results: {
      true: {
        position: 'absolute',
        backgroundColor: '$bg',
        top: '44px',
        height: '100%',
        minHeight: '240px',
        boxShadow: '$shadowBase',
        zIndex: 2
      }
    },
    empty: {
      true: {
        display: 'none'
      }
    }
  }
});

export const ListItem = styled('div', {
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight1',
  padding: '0.5rem 4px',
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  variants: {
    results: {
      true: {
        cursor: 'pointer',
        '&.selected': {
          backgroundColor: '$line'
        },
        '&:hover': {
          backgroundColor: '$input'
        }
      }
    }
  }
});

export const FormikInput = styled(Field, {
  background:'transparent',
  width: '100%',
  border: 'none',
  outline: 'none',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing:'$letterSpacing3',
  fontSize: '$bodySmall',
  padding: '$spaceSmall',
  '-webkit-appearance': 'none !important',
  zIndex: 1
});

export const FormikForm = styled(Form, {
  width: '100%',
});
