import React, { useMemo, ReactElement, ReactNode, useEffect, useReducer } from 'react';
import AppContext from './AppContext';
import AppReducer from './AppReducer';
import { StateInterface } from './types';

export const initialState: StateInterface = {
  filterContent: {
    type: 'content',
    filter: undefined,
    category: 'feed',
    page: undefined,
    orderBy: 'relevant',
  },
  userData: {
    userId: undefined,
    firstName: undefined,
    lastName: undefined,
    biography: undefined,
    email: undefined,
    researchGate: undefined,
    linkedinLink: undefined,
    slug: undefined,
    avatarUrl: undefined,
    username: undefined,
    twitterHandle: undefined,
    userSetting: undefined,
    status: undefined,
    following: undefined,
    expertise: undefined,
    languages: undefined,
    timezone: undefined,
    experiences: undefined,
    userType: undefined,
    organizationName: '',
    organizationAvatarUrl: '',
    experienceTitle: undefined,
    kind: undefined,
    credits: 0,
  },
  authenticationToken: undefined,
  tokenExpiration: undefined,
  reload: false,
  isTimeOut: false,
  resources: [],
  newPlaylistItems: [],
  contentToPlaylist: undefined,
  alternative: {},
  interests: [],
  dataFollow: undefined,
  dataUnfollow: undefined,
  images: undefined,
  deleteItems: [],
};

function initializeState() {
  const fromLocalStorage = JSON.parse(localStorage.getItem('state') as string);
  return fromLocalStorage || initialState;
}

const AppProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [state, dispatch] = useReducer(AppReducer, initializeState());

  useEffect(() => {
    localStorage.setItem('state', JSON.stringify(state));
  }, [state]);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
