import React, { FC, useState } from 'react';
import { WrapperTypeResource } from './styled';
import Icon from '../Icon/Icon';
import book from '../../../assets/images/icons/book.svg';
import audio from '../../../assets/images/icons/audio-black.svg';
import video from '../../../assets/images/icons/video.svg';
import bookWhite from '../../../assets/images/icons/book-white.svg';
import audioWhite from '../../../assets/images/icons/audio-white.svg';
import videoWhite from '../../../assets/images/icons/video-white.svg';

const DEFAULT_PODCAST = ['https://podcasts.apple.com', 'http://podcasts.apple.com', 'https://anchor.fm', 'http://anchor.fm'];

interface TypeResourceModel {
  type?: string;
  active?: boolean;
  url?: string;
}

const TypeResource: FC<TypeResourceModel> = ({ type, active, url }) => {
  const [hover, setHover] = useState<boolean>(active || false);

  const checkResource = (value?: string) => {
    let defaultValue = value?.toLowerCase();
    const urlText = url?.toLowerCase() || '';
    DEFAULT_PODCAST.forEach((ele:string) => {
      if (urlText.includes(ele)) {
        defaultValue = 'podcast';
      }
    });

    switch (defaultValue) {
      case 'video':
        return hover ? videoWhite : video;
      case 'audio':
      case 'podcast':
        return hover ? audioWhite :audio;
      default:
        return hover ? bookWhite : book;
    }
  };

  const showHover = () => {
    setHover(true);
  };

  const hideHover = () => {
    if (!active) setHover(false);
  };

  return (
    <WrapperTypeResource
      onMouseEnter={showHover}
      onMouseLeave={hideHover}
      active={active}
    >
      <Icon alt={type} img={checkResource(type)} width='16px' height='18px' />
    </WrapperTypeResource>
  );
};

TypeResource.defaultProps = {
  type: undefined,
  active: false,
  url: ''
};

export default TypeResource;
