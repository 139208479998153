import React, { useContext, useState } from 'react';
import { ModalDragAndDrop } from './ModalDragAndDrop';
import { IconContainer, QuestionContainer } from '../styled';
import { EmptyLabel, FlexContainer, Typography } from '../../styled';
import { UploadIcon } from '../icons/UploadIcon';
import { FileItem } from './FileItem';
import ProjectsContext from '../../../../shared/context/projects/ProjectsContext';
import { useProjects } from '../../../../shared/hooks/useProjects';
import { keyExtractor } from '../../../../shared/utils/helper';
import { Project } from '../../../../shared/interfaces/projectInterfaces';

export const UploadFiles = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { documents, setDocuments } = useContext(ProjectsContext);
  const { updateProjectDocument, removeProjectDocument, getProjectStatus, project } = useProjects();
  const status = getProjectStatus(project as Project);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFileDrop = async (files: File[]) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('documents[]', file);
    });
    const response = await updateProjectDocument(formData);
    setDocuments(response.attributes.documents);
    closeModal();
  };

  const handleRemoveFile = async (index: number) => {
    const file = documents[index];
    const response = await removeProjectDocument(file.id);
    setDocuments(response.attributes.documents);
  };

  const renderDocumentsView = () => {
    if (!documents.length) {
      return status === 'draft' ? (
        <QuestionContainer space="paddingY2">
          <FlexContainer align="center" gap="0_75">
            <IconContainer onClick={openModal}>
              <UploadIcon />
            </IconContainer>
            <Typography size="bodyXSmall">Upload documents</Typography>
          </FlexContainer>
        </QuestionContainer>
      ) : (
        <FlexContainer>
          <EmptyLabel>No related documents available.</EmptyLabel>
        </FlexContainer>
      );
    }
    return (
      <>
        <FlexContainer gap="0_75" wrap>
          {documents.map((document, index: number) => (
            <FileItem
              name={document.name}
              onDelete={() => handleRemoveFile(index)}
              key={keyExtractor()}
            />
          ))}
        </FlexContainer>
        {status === 'draft' && (
          <IconContainer onClick={openModal}>
            <UploadIcon />
          </IconContainer>
        )}
      </>
    );
  };

  return (
    <>
      <Typography
        size="displayXXSmall"
        weight="700"
        lineHeight="lineHeight3"
        letterSpacing="letterSpacing3"
      >
        Documents
      </Typography>
      {renderDocumentsView()}
      {isModalOpen && <ModalDragAndDrop onClose={closeModal} onFileDrop={handleFileDrop} />}
    </>
  );
};
