import React, { ReactNode } from 'react';
import { ImgEllipse, MentionContainer, TextContainer, DivTextContainer } from './styled';

interface MentionProps {
  image: string;
  imagePosition: 'left' | 'right';
  children: ReactNode;
}
export const Mention = ({ image, imagePosition, children }: MentionProps) => (
  <MentionContainer position={imagePosition === 'left' ? 'right' : 'left'}>
    <ImgEllipse src={image} alt="experts" />
    <DivTextContainer position={imagePosition === 'left' ? 'right' : 'left'}>
      <TextContainer>
        <p>{children}</p>
      </TextContainer>
    </DivTextContainer>
  </MentionContainer>
);
