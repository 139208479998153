import React, { useContext, useState } from 'react';
import JSZip from 'jszip';
import axios from 'axios';
import { EmptyLabel, FlexContainer, Typography, EmptyContainer } from '../styled';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import { keyExtractor } from '../../../shared/utils/helper';
import { DocumentContainer } from './styled';
import Document from '../../../assets/images/icons/document.svg';
import Icon from '../../../shared/components/Icon/Icon';
import { DownloadIcon } from '../../../assets/images/icons/download';

const ProjectDocuments = () => {
  const { documents, project } = useContext(ProjectsContext);
  const [hover, setHover] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const color = hover ? '#A4D41B' : '#D7F065';

  const handleDownloadDocuments = async () => {
    if (!documents.length || isDownloading) return;
    try {
      setIsDownloading(true);
      const zip = new JSZip();
      // Fetch the documents and add them to the zip file
      await Promise.all(
        documents.map(async (document) => {
          const response = await axios.get(document.url, { responseType: 'arraybuffer' });
          zip.file(document.name, response.data);
        }),
      );
      // Generate the zip file
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(zipBlob);
      link.href = url;
      link.setAttribute('download', `${project?.attributes?.slug}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ error });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <FlexContainer
        justify="start"
        align="center"
        css={{ cursor: 'pointer' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleDownloadDocuments}
      >
        <DownloadIcon color={color} />
        <Typography
          size="bodyLarge"
          weight="700"
          lineHeight="lineHeight3"
          letterSpacing="letterSpacing3"
          css={{ marginLeft: '$spaceSmall' }}
        >
          Documents
        </Typography>
      </FlexContainer>
      <FlexContainer gap="0_75" wrap>
        {documents.length > 0 ? (
          documents.map((document) => (
            <DocumentContainer
              key={keyExtractor()}
              href={document.url}
              download={document.name}
              target="_blank"
            >
              <Icon img={Document} margin0 />
              <Typography
                size="bodyXXSSmall"
                weight="500"
                lineHeight="lineHeight12"
                letterSpacing="letterSpacing2"
                css={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                }}
              >
                {document.name}
              </Typography>
            </DocumentContainer>
          ))
        ) : (
          <EmptyContainer>
            <EmptyLabel>No related documents available.</EmptyLabel>
          </EmptyContainer>
        )}
      </FlexContainer>
    </>
  );
};

export default ProjectDocuments;
