import React, { useContext } from 'react';
import Login from './Login/Login';
import SignUp from './SignUp/SignUp';
import AuthenticationContext from '../../../../shared/context/authentication/authenticationContext';

const Email = () => {
  const { login } = useContext(AuthenticationContext);
  if (login) return(<Login />);
  
  return(<SignUp />);
};

export default Email;