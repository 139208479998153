import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../shared/components/Button/Button';
import NotFoundMetaTags from './MetaTags';
import { Div404, NotFoundDiv, NotFoundMessage, Subtitle404, Title404 } from './styled';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if(window.location.pathname !== '/404') { window.location.pathname = '/404'; }
  }, []);

  return(<NotFoundDiv>
    <NotFoundMessage>
      <NotFoundMetaTags />
      <Div404>404</Div404>
      <Title404>This Page Could Not Be Found.</Title404>
      <Subtitle404>
        The page your are looking for does not exist. It might have been moved or deleted
      </Subtitle404>
      <Button
        title='Go to Feed'
        css={{ height: '40px', width: '155px', fontSize: '13px'}}
        handleClick={() => navigate('/')}
      />
    </NotFoundMessage>    
  </NotFoundDiv>);
};

export default NotFound;