import * as React from 'react';
import { SVGProps } from 'react';

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.5 10.5L6 6L1.5 1.5" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
export default ArrowRightIcon;
