export const SloganText = 'A million biotech experts on your team';

export const TextFeed = 'People. Ideas. Opinions.';

export const TextProfile = 'Longer-form writing, interviews & podcasts.';

export const TextFooter = TextFeed;

export const AboutPageTextFooter = SloganText;

export const TextNotResultsContent = "Hmm, that’s a blow. We can’t find any long-form content for this search. Sorry! Want to try any of these?";

export const ResearchGate = "https://www.researchgate.net/profile/";

export const LinkedinLink = "https://www.linkedin.com/in/";

export const xGatherSightProfile = "https://x.com/gathersight";
