import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexContainer, Typography } from '../styled';
import { IconContainer } from './styled';
import { PlusIcon } from './icons/PlusIcon';

const ProjectMembers = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/organization/members?add_member=true');
  };
  return (
    <FlexContainer gap="2" direction="column">
      <Typography
        size="displayXXSmall"
        weight="700"
        lineHeight="lineHeight3"
        letterSpacing="letterSpacing3"
      >
        Members
      </Typography>
      <IconContainer kind="addMembers" onClick={handleRedirect}>
        <PlusIcon />
      </IconContainer>
    </FlexContainer>
  );
};

export default ProjectMembers;
