import { styled } from '../../../config/theme';

export const TooltipContainer = styled('div', {
  display: 'inline-block',
  position: 'relative',

  variants: {
    type: {
      outline: {
        border: '0.025rem solid hsl(233, 27%, 88%, 1)',
        borderRadius: '$radius1',
        height: 'auto',
        alignSelf: 'baseline',
        '& .load-more': {
          '& button': {
            margin: 0,
          },
        },
      },
    },
    isFlex: {
      true: {
        display: 'flex',
      },
    },
  },
});

export const TooltipContent = styled('div', {
  position: 'absolute',
  borderRadius: '1rem',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '1.5rem',
  color: 'hsla(247, 15%, 35%, 1)',
  backgroundColor: '$bg',
  boxShadow: '$shadowBase',
  fontSize: '$bodyXSmall',
  fontWeight: '$weight600',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing2',
  zIndex: '-1',
  visibility: 'hidden',
  opacity: 0,
  transition: 'all 0.3s ease-in-out',
  '&:before': {
    content: '',
    left: '50%',
    border: 'solid transparent',
    height: '0',
    width: '0',
    position: 'absolute',
    pointerEvents: 'none',
    borderWidth: '0.75rem',
    marginLeft: 'calc(0.75rem * -1)',
  },
  variants: {
    show: {
      true: {
        visibility: 'visible',
        opacity: 1,
        zIndex: '9',
      },
    },
    direction: {
      top: {
        transform: 'translate(-50%, -130%)',
        '&:before': {
          top: '100%',
          borderTopColor: '$bg',
        },
      },
      bottom: {
        bottom: '0.063rem',
        transform: 'translate(-50%, 110%)',
        '&:before': {
          bottom: '100%',
          borderBottomColor: '$bg',
        },
      },
      left: {
        left: 'auto',
        right: 'calc(100% + 0.625rem)',
        top: '50%',
        transform: 'translateX(0) translateY(-50%)',
        '&:before': {
          left: 'auto',
          right: 'calc(0.75rem * -2)',
          top: '50%',
          transform: 'translateX(0) translateY(-50%)',
          borderLeftColor: '$bg',
        },
      },
      right: {
        left: 'calc(100% + 0.625rem)',
        top: '50%',
        transform: 'translateX(0) translateY(-50%)',
        '&:before': {
          left: 'calc(0.75rem * -1)',
          top: '50%',
          transform: 'translateX(0) translateY(-50%)',
          borderRightColor: '$bg',
        },
      },
    },
    type: {
      'type-resource': {
        textAlign: 'center',
        transform: 'translate(-92%, 120%)',
        minWidth: '14.063rem',
        boxShadow: '$shadowBase',
        padding: '1rem',
        '&:before': {
          left: '88%',
        },
      },
      'save-resource': {
        transform: 'translate(-90%, 130%)',
        boxShadow: '$shadowBase',
        minWidth: '14.063rem',
        fontSize: '$bodyXSmall',
        textAlign: 'center',
        padding: '1rem',
        '&:before': {
          left: '88%',
        },
      },
      'orcid-verified': {
        transform: 'translate(-75%, -200%)',
        boxShadow: '$shadowBase',
        minWidth: 'fit-content',
        fontSize: '$bodyXSmall',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        color: '$offWhite',
        padding: '0.75rem',
        backgroundColor: '$burplePass',
        '&:before': {
          borderTopColor: '$burplePass',
          left: '75%',
        },
      },
      'link-preview': {
        fontSize: '$bodyXSmall',
        padding: '1rem',
        transform: 'translate(-10%, 120%)',
        minWidth: '14.063rem',
        right: '100%',
        boxShadow: '$shadowBase',
        width: 'fit-content',
        overflow: 'hidden',
        display: 'none',
        '&:before': {
          left: '10%',
        },
        '@bp3': {
          display: 'block',
        },
      },
      info: {
        left: '50%',
        minWidth: '19rem',
      },
      copied: {
        padding: '1rem',
        transform: 'translate(2%, -15px)',
        color: '$offWhite',
        backgroundColor: '$offBlack',
        '&:before': {
          display: 'none',
        },
      },
    },
  },
});
