import React, { createContext } from 'react';
import { Project, ProjectDocument, ProjectExperts, ProjectLocation, ProjectOccupation, ProjectTag, Question } from '../../interfaces/projectInterfaces';

export interface ContextProps {
  projects: Project[];
  project?: Project;
  projectQuestions: Question[];
  projectTags: ProjectTag[];
  projectLocations: ProjectLocation[];
  projectOccupations: ProjectOccupation[];
  name: string;
  price: string;
  description: string;
  opinions: string;
  documents: ProjectDocument[];
  rolesList: any[];
  locationsList: any[];
  lengthError?: boolean;
  projectExperts: ProjectExperts[];
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  setProjectExperts: React.Dispatch<React.SetStateAction<ProjectExperts[]>>;
  setLengthError: React.Dispatch<React.SetStateAction<boolean>>;
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setProjectQuestions: React.Dispatch<React.SetStateAction<Question[]>>;
  setOpinions: React.Dispatch<React.SetStateAction<string>>;
  setProjectLocations: React.Dispatch<React.SetStateAction<ProjectLocation[]>>;
  setProjectOccupations: React.Dispatch<React.SetStateAction<ProjectOccupation[]>>;
  setDocuments: React.Dispatch<React.SetStateAction<ProjectDocument[]>>;
  setProjectTags: React.Dispatch<React.SetStateAction<ProjectTag[]>>;
  setRolesList: React.Dispatch<React.SetStateAction<any[]>>;
  setLocationsList: React.Dispatch<React.SetStateAction<any[]>>;
}

const initialState: ContextProps = {
  projects: [],
  project: undefined,
  projectQuestions: [],
  projectTags: [],
  projectLocations: [],
  projectOccupations: [],
  name: '',
  price: '',
  description: '',
  opinions: '',
  documents: [],
  rolesList: [],
  locationsList: [],
  lengthError: false,
  projectExperts: [],
  setReset: () => {},
  setProjectExperts: () => {},
  setLengthError: () => {},
  setProjects: () => {},
  setName: () => {},
  setDescription: () => {},
  setProjectQuestions: () => {},
  setProject: () => {},
  setOpinions: () => {},
  setProjectLocations: () => {},
  setProjectOccupations: () => {},
  setDocuments: () => {},
  setProjectTags: () => {},
  setRolesList: () => {},
  setLocationsList: () => {},
};

const ProjectsContext = createContext<ContextProps>(initialState as ContextProps);

export default ProjectsContext;