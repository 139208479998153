import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpertImg, ExpertItemContainer, ExpertNoImg } from './styled';
import { Typography } from '../../styled';
import { getInitials } from '../../../../shared/utils/helper';

interface ExpertItemProps {
  image: string;
  expertName: string;
  expertEmail: string;
  expertRole: string;
  expertOpinionUrl: string | null;
  hasOpinion: boolean;
}

export const ExpertItem = ({
  image,
  expertName,
  expertEmail,
  expertRole,
  expertOpinionUrl,
  hasOpinion,
}: ExpertItemProps) => {
  const [validImage, setValidImage] = useState(true);
  const navigate = useNavigate();

  const navigateUrl = () => {
    if (expertOpinionUrl) {
      navigate(expertOpinionUrl);
    }
  };

  const verifyImage = () => {
    const img = new Image();
    img.src = image as string;

    img.onload = () => {
      setValidImage(true);
    };

    img.onerror = () => {
      setValidImage(false);
    };
  };

  useEffect(() => {
    verifyImage();

    return () => {
      setValidImage(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <ExpertItemContainer hasOpinion={hasOpinion} onClick={hasOpinion ? navigateUrl : () => {}}>
      {validImage ? (
        <ExpertImg hasOpinion={hasOpinion} src={image} />
      ) : (
        <ExpertNoImg hasOpinion={hasOpinion}>{getInitials(expertName)}</ExpertNoImg>
      )}
      <Typography
        size="bodyXSmall"
        weight="600"
        lineHeight="lineHeight2"
        letterSpacing="letterSpacing2"
        align="center"
      >
        {expertName || expertEmail}
      </Typography>
      <Typography truncate size="bodyXSmall" color="labelLight">
        {expertRole}
      </Typography>
    </ExpertItemContainer>
  );
};
