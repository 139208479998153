import axios from 'axios';

const URL_SERVICE = 'https://gathersight-cors.herokuapp.com';

const favicon = [
  'link[rel="apple-touch-icon"]',
  'link[rel="apple-touch-icon-precomposed"]',
  'link[rel="icon" i]',
  'link[rel="fluid-icon"]',
  'link[rel="shortcut icon"]',
  'link[rel="Shortcut Icon"]',
  'link[rel="mask-icon"]',
];

const video = [
  'meta[property="og:video:secure_url"]',
  'meta[name="og:video:secure_url"]',
  'meta[property="og:video:url"]',
  'meta[name="og:video:url"]',
  'meta[property="og:video"]',
  'meta[name="og:video"]'
];

const image = [
  'meta[property="og:image"]',
  'meta[property="og:image:secure_url"]',
  'meta[name="og:image:secure_url"]',
  'meta[property="og:image:url"]',
  'meta[name="og:image:url"]',
  'meta[name="og:image"]',
  'meta[property="twitter:image"]',
  'meta[name="twitter:image"]',
  'meta[property="twitter:image:src"]',
  'meta[name="twitter:image:src"]',
  'meta[property="thumbnail"]',
  'meta[name="thumbnail"]',
  'meta[property="parsely-image-url"]',
  'meta[name="parsely-image-url"]',
  'meta[property="sailthru.image.full"]',
  'meta[name="sailthru.image.full"]'
];

const findFavicon = async (doc:any) => {
  const arrayFavicon: any[] = [];
  await favicon.forEach(i => {
    const dataMeta = doc.querySelector(i);
    const valueIcon = dataMeta && dataMeta.getAttribute('href');
    if (valueIcon) arrayFavicon.push(valueIcon);
  });

  return arrayFavicon;
};

const findImages = async (doc:any) => {
  const arrayImages: any[] = [];
  await image.forEach(async i => {
    const dataMeta = doc.querySelector(i);
    const valueImage = dataMeta && dataMeta.getAttribute('content');

    if (valueImage) arrayImages.push(valueImage);
  });

  return arrayImages;
};

const findVideos = async (doc:any) => {
  const arrayVideos: any[] = [];
  await video.forEach(i => {
    const dataMeta = doc.querySelector(i);
    const valueVideo = dataMeta && dataMeta.getAttribute('content');

    if (valueVideo) arrayVideos.push(valueVideo);
  });

  return arrayVideos;
};
interface metadataModel {
  link: string | undefined;
  favicons: Array<string>;
  images: Array<string>;
  videos: Array<string>;
}

export const getMetaDataLink = async (link:string) : Promise<metadataModel> => {
  const metadata = { link: '', favicons: [], images: [], videos: [] };

  if (link) {
    const response = await axios.get(`${URL_SERVICE}/${link}`).then((res) => res?.data);
    const doc = await new DOMParser().parseFromString(response, 'text/html');
    const favicons = await findFavicon(doc);
    const images = await findImages(doc);
    const videos = await findVideos(doc);

    return { link, favicons, images, videos };
  }

  return metadata;
};

export const validateResource = async (urlresource:string) => {
  if (urlresource) {
    const responseImge = await axios.get(`${URL_SERVICE}/${urlresource}`)
      .then(async (res) => res.status === 200 ? res?.config?.url : '');

    return responseImge || '';
  }
  return '';
};
