import React, { ReactNode } from 'react';
import { OptionUsageContainer, RoundedElipseContainer } from './styled';
import { TextDescription, TypographyH3 } from '../../styled';

interface OptionUsageProps {
  icon: ReactNode;
  title: string;
  text: string;
}

export const OptionsUsage = ({ icon, title, text }: OptionUsageProps) => (
  <OptionUsageContainer>
    <RoundedElipseContainer>{icon}</RoundedElipseContainer>
    <TypographyH3 type="bodyLarge" weight="700" align="center">
      {title}
    </TypographyH3>
    <TextDescription align="center" >
      {text}
    </TextDescription>
  </OptionUsageContainer>
);
