import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import VideoRecordingContext from '../../../../shared/context/videoRecording/videoRecordingContext';
import { FlexContainer, Typography } from '../../styled';
import { OpinionsService } from '../../../../services/OpinionsService/OpinionsService';
import useGetToken from '../../../../shared/hooks/useGetToken';
import ProjectsContext from '../../../../shared/context/projects/ProjectsContext';
import RecordVideoModal from '../../../RecordingVideos/RecordVideoModal';
import Status from '../../../../shared/components/Dashboard/Table/ui/status';
import Video from '../../../../shared/components/Video/Video';
import { OpinionButtonOption, VideoContainer } from './styled';
import { DeleteIcon } from './icons/DeleteIcon';

interface OpinionRecordedProps {
  videoUrl: string;
  withStatusLabel?: boolean;
}

const OpinionRecorded = ({ videoUrl, withStatusLabel }: OpinionRecordedProps) => {
  const { setProjectOpinion, setBlob, setRecordedChunks, projectOpinion } =
    useContext(VideoRecordingContext);
  const { project } = useContext(ProjectsContext);
  const [date, setDate] = useState<string | undefined>(undefined);
  const opinionStatus = projectOpinion?.attributes?.status;
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const authenticationToken = useGetToken();

  useEffect(() => {
    if (!projectOpinion?.attributes?.video_date) return;

    const dateFormatted = new Date(projectOpinion?.attributes?.video_date).toLocaleDateString(
      'en-US',
      {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      },
    );
    setDate(dateFormatted);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectOpinion]);

  const handleDelete = async () => {
    if (!project || !projectOpinion) return;
    const data = {
      authenticationToken,
      projectOpinionId: projectOpinion.id,
      projectId: project.id,
    };

    const response: any = await OpinionsService.deleteOpinion(data);

    if (response.data.message) {
      toast.success(response.data.message);
      setBlob(undefined);
      setRecordedChunks([]);
      setProjectOpinion(undefined);
    } else {
      toast.error('Error deleting the opinion');
    }
  };

  return (
    <VideoContainer>
      <Video
        id="replay-video"
        videoWidth="100%"
        src={videoUrl}
        onCheckVideoStatus={(status: boolean) => setIsVideoLoaded(status)}
      />
      {isVideoLoaded && (
        <>
          <FlexContainer justify="between" align="center" css={{ width: '100%' }}>
            <Typography
              letterSpacing="letterSpacing2"
              size="bodyXSmall"
              weight={400}
              css={{ lineHeight: '1.219rem', color: 'hsla(235, 14%, 50%, 1)' }}
            >
              {`Opinion Date: ${date}`}
            </Typography>
            {opinionStatus && opinionStatus === 'sent' && withStatusLabel && (
              <Status state="inReview" />
            )}
          </FlexContainer>
          {opinionStatus && opinionStatus !== 'sent' && (
            <FlexContainer
              justify="center"
              align="center"
              css={{ width: '100%' }}
              gap="1_25"
              direction="onlyMobileColumn"
            >
              <RecordVideoModal alternative />
              <OpinionButtonOption color="delete" onClick={handleDelete}>
                <DeleteIcon />
                Delete
              </OpinionButtonOption>
            </FlexContainer>
          )}
        </>
      )}
    </VideoContainer>
  );
};

OpinionRecorded.defaultProps = {
  withStatusLabel: true,
};

export default OpinionRecorded;
