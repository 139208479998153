import React from 'react';
import { FileItemContainer, FileItemInfoContainer } from './styled';
import { Typography } from '../../styled';
import { CloseIconModal } from '../../../../shared/components/Icon/CloseIconModal';
import { useProjects } from '../../../../shared/hooks/useProjects';
import { Project } from '../../../../shared/interfaces/projectInterfaces';

interface FileItemProps {
  name: string;
  onDelete?: () => void;
}

export const FileItem = ({ name, onDelete }: FileItemProps) => {
  const { project, getProjectStatus } = useProjects();
  const status = getProjectStatus(project as Project);
  const getDisplayName = () => {
    const fileNameWithoutExtension = name.replace(/\.[^/.]+$/, '').replaceAll('-', ' ');
    return fileNameWithoutExtension.length > 20
      ? `${fileNameWithoutExtension.slice(0, 27)}...`
      : fileNameWithoutExtension;
  };
  return (
    <FileItemContainer>
      {status === 'draft' && <CloseIconModal onClick={onDelete || (() => {})} />}
      <FileItemInfoContainer>
        <Typography size="bodyXSmall" color="monochromeBlack400" weight="500">
          {getDisplayName()}
        </Typography>
      </FileItemInfoContainer>
    </FileItemContainer>
  );
};

FileItem.defaultProps = {
  onDelete: undefined,
};
