import React from 'react';

export const TagPlus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_14191_52308)">
      <path
        d="M15.0575 7.685L8.3075 0.935C8.03 0.6575 7.6475 0.5 7.25 0.5H2C1.60218 0.5 1.22064 0.658035 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2V7.25C0.5 7.6475 0.6575 8.03 0.9425 8.3075L1.25 8.6075C1.925 8.2025 2.705 8 3.5 8C4.69347 8 5.83807 8.47411 6.68198 9.31802C7.52589 10.1619 8 11.3065 8 12.5C8 13.295 7.79 14.0675 7.385 14.75L7.685 15.05C7.9625 15.335 8.3525 15.5 8.75 15.5C9.1475 15.5 9.53 15.3425 9.8075 15.0575L15.0575 9.8075C15.3425 9.53 15.5 9.1475 15.5 8.75C15.5 8.3525 15.3425 7.97 15.0575 7.685ZM3.125 4.25C2.82663 4.25 2.54048 4.13147 2.3295 3.9205C2.11853 3.70952 2 3.42337 2 3.125C2 2.82663 2.11853 2.54048 2.3295 2.3295C2.54048 2.11853 2.82663 2 3.125 2C3.42337 2 3.70952 2.11853 3.9205 2.3295C4.13147 2.54048 4.25 2.82663 4.25 3.125C4.25 3.42337 4.13147 3.70952 3.9205 3.9205C3.70952 4.13147 3.42337 4.25 3.125 4.25ZM6.5 13.25H4.25V15.5H2.75V13.25H0.5V11.75H2.75V9.5H4.25V11.75H6.5V13.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_14191_52308">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
