/* eslint-disable no-unused-vars */
import React from 'react';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import { Tag, TextTag, TopicsContainer } from './styles';
import { keyExtractor } from '../../utils/helper';
import { TagModel } from '../../interfaces/interfaces';
import { ListWrapper } from '../MenuPopover/styles';
import TagsInputComponent from '../TagsInput/TagsInput';
import closeIcon from '../../../assets/images/icons/close.svg';
import Button from '../Button/Button';
import { useTag } from '../../hooks/useTag';

interface TopicsModalProps {
  open: boolean;
  topics: TagModel[];
  onClose: () => void;
  size?: string;
  sameUser: Boolean | undefined;
}

const TagsModal: React.FC<TopicsModalProps> = ({ open, topics, onClose, size, sameUser }) => {
  const { filterDuplicated } = useTag();
  // eslint-disable-next-line no-nested-ternary
  return size === 'desk' ? (
    <Modal kindOverlay="gat-modal-overlay--light" isOpen={open} onClose={onClose}>
      <Card type="popup" css={{ padding: '1em', width: '90vw' }}>
        <p>Content Topics</p>
        <TopicsContainer>
          {topics &&
            filterDuplicated(topics, sameUser).map((item: TagModel) => (
              <Tag key={keyExtractor()}>
                <TextTag>{item?.attributes?.name || ''}</TextTag>
              </Tag>
            ))}
        </TopicsContainer>
      </Card>
    </Modal>
  ) : (
    <Modal kindOverlay="gat-modal-overlay--light" isOpen={open} onClose={onClose}>
      <Card type="modalTopics">
        <Button
          title=""
          iconCustom={closeIcon}
          iconWidth="15px"
          iconHeight="15px"
          kind="transparent"
          handleClick={onClose}
          css={{
            position: 'absolute',
            padding: '1.5rem 1.5rem 0 0',
            top: '0',
            right: '0',
            '& img': {
              margin: '0',
            },
          }}
        />
        <p>Content Topics</p>
        <TopicsContainer>
          {topics &&
            filterDuplicated(topics, sameUser).map((item: TagModel) => (
              <Tag key={keyExtractor()}>
                <TextTag>{item?.attributes?.name || ''}</TextTag>
              </Tag>
            ))}
        </TopicsContainer>
      </Card>
    </Modal>
  );
};
TagsModal.defaultProps = {
  size: 'desk',
};

export default TagsModal;
