import React, { useMemo, ReactElement, ReactNode, useState } from 'react';
import AuthenticationContext from './authenticationContext';

const AuthenticationProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [userType, setUserType] = useState<string>('');
  const [login, setLogin] = useState<boolean>(false);

  const value = useMemo(() => ({ userType, setUserType, login, setLogin }), [userType, login]);

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;