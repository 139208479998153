import { styled } from '../../config/theme';

export const NotFoundDiv = styled('div', {
  position: 'relative',
  height: '100vh',
  width: '100%',
  background: '#262338',
});

export const NotFoundMessage = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Div404 = styled('h1', {
  fontWeight: '700',
  letterSpacing: '1px',
  color: '#A0A3BD',
  '@base': {
    paddingBottom: '20px',
    fontSize: '140px',
    lineHeight: '140px'
  },
  '@bp1': {
    paddingBottom: '40px',
    fontSize: '260px',
    lineHeight: '260px',
  }
});

export const Title404 = styled('h3', {
  fontWeight: 700,
  color: '#FCFCFC',
  maxWidth: '420px',
  '@base': {
    paddingBottom: '20px',
  },
  '@bp1': {
    paddingBottom: '32px',
  }
});

export const Subtitle404 = styled('h3', {
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '24px',
  letterSpacing: '0.75px',
  color: '#FCFCFC',
  maxWidth: '420px',
  '@base': {
    paddingBottom: '20px',
  },
  '@bp1': {
    paddingBottom: '50px',
  }
});