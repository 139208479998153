import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Widget, WidgetTitle } from '../Widget/styles';
import { StatusTag, TagModel } from '../../interfaces/interfaces';
import { keyExtractor } from '../../utils/helper';
import { FlexContainer, NoTopicsContainer, Tag, TextTag, TopicsContainer } from './styles';
import Icon from '../Icon/Icon';
import deleteTagIcon from '../../../assets/images/icons/delete-tag.svg';
import Button from '../Button/Button';
import TagSelector from './TagSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useTag } from '../../hooks/useTag';

import addTagIcon from '../../../assets/images/icons/topic-tags-add.svg';
import infoIcon from '../../../assets/images/icons/info.svg';
import { remove } from '../ToastAlerts/alerts';
import SelectedTagProvider from '../../context/tag/SelectedTagsProvider';
import { LoadingTopics } from './LoadingTopics';

interface TagPropsModel {
  data: TagModel[];
  sameUser: Boolean;
  userId: number | string;
  loading?: boolean;
}

export const TagsKnowledge = ({ data, sameUser, userId, loading }: TagPropsModel) => {
  const [dataTags, setDataTags] = useState<Array<TagModel>>(data || []);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { width } = useWindowSize();
  const isResponsive = width < 980;
  const { saveExpertise, deleteExpertise, orderUsedTags, filterDuplicated, handleTag } = useTag();

  const handleTagAction = async (item: TagModel, originals: TagModel[]) => {
    let updatedTopics: TagModel[] = [];
    setDataTags(orderUsedTags(originals, sameUser));
    if (userId !== undefined) {
      updatedTopics = await saveExpertise(item, userId as number);
      setDataTags(orderUsedTags(updatedTopics, sameUser));
    }
  };

  const handleDeleteTagAction = (item: TagModel) => {
    const updatedTopics = dataTags.filter((topic) => topic.id !== item.id);
    const originalTopics = [...dataTags];
    setDataTags(updatedTopics);
    if (userId !== undefined) {
      deleteExpertise(item, userId as number);
    }
    remove(
      <FlexContainer align="center" justify="between">
        <p>Topic removed</p>
        <Button
          title="Undo"
          kind="link"
          handleClick={() => {
            handleTagAction(item, originalTopics);
            toast.dismiss();
          }}
        />
      </FlexContainer>,
    );
  };

  const handleUpdateTopics = (items: TagModel[]) => {
    setDataTags(items);
  };

  const renderTopics = () => {
    if (loading) {
      return (
        <NoTopicsContainer>
          <TopicsContainer>
            <LoadingTopics />
          </TopicsContainer>
        </NoTopicsContainer>
      );
    }
    if (width < 980 && width > 412) {
      return (
        <NoTopicsContainer>
          <TopicsContainer>
            {dataTags.length ? (
              dataTags.map((item: TagModel) => (
                <Tag key={keyExtractor()} approved={item.attributes.status === StatusTag.Approved}>
                  <TextTag
                    onClick={(e: any) => {
                      e?.stopPropagation();
                      const tagData = item?.attributes?.name || item;

                      handleTag(tagData || '');
                    }}
                  >
                    {item?.attributes?.name || ''}
                  </TextTag>
                  {sameUser && (
                    <Icon
                      closeToast={() => handleDeleteTagAction(item)}
                      img={deleteTagIcon}
                      margin0
                      css={{ marginLeft: 4 }}
                    />
                  )}
                </Tag>
              ))
            ) : (
              <NoTopicsContainer>
                <Icon img={infoIcon} margin0 css={{ marginLeft: 4 }} />
                <p>This profile has no topics added yet</p>
              </NoTopicsContainer>
            )}
          </TopicsContainer>
          {sameUser && (
            <Button
              title=""
              iconCustom={addTagIcon}
              iconWidth="18px"
              iconHeight="18px"
              kind="topic-tag"
              handleClick={() => setIsOpenModal(!isOpenModal)}
            />
          )}
        </NoTopicsContainer>
      );
    }
    return (
      <>
        <TopicsContainer>
          {dataTags.length ? (
            dataTags.map((item: TagModel) => (
              <Tag key={keyExtractor()} approved={item.attributes.status === StatusTag.Approved}>
                <TextTag
                  onClick={(e: any) => {
                    e?.stopPropagation();
                    const tagData = item?.attributes?.name || item;

                    handleTag(tagData || '');
                  }}
                >
                  {item?.attributes?.name || ''}
                </TextTag>
                {sameUser && (
                  <Icon
                    closeToast={() => handleDeleteTagAction(item)}
                    img={deleteTagIcon}
                    margin0
                    css={{ marginLeft: 4 }}
                  />
                )}
              </Tag>
            ))
          ) : (
            <NoTopicsContainer>
              <Icon img={infoIcon} margin0 css={{ marginLeft: 4 }} />
              <p>This profile has no topics added yet</p>
            </NoTopicsContainer>
          )}
        </TopicsContainer>
        {sameUser && (
          <Button
            title=""
            iconCustom={addTagIcon}
            iconWidth="18px"
            iconHeight="18px"
            kind="topic-tag"
            handleClick={() => setIsOpenModal(!isOpenModal)}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (dataTags.length === 0) {
      setDataTags(filterDuplicated(data || [], sameUser));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (loading) {
      setDataTags([]);
    }
  }, [loading]);

  return (
    <SelectedTagProvider>
      <Widget className="knowledge">
        <WidgetTitle>Knowledge</WidgetTitle>
        {renderTopics()}

        {sameUser && dataTags && (
          <TagSelector
            open={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            topics={dataTags}
            size={isResponsive ? 'mobile' : 'desk'}
            userId={userId}
            onUpDatedTopics={handleUpdateTopics}
            sameUser={sameUser}
          />
        )}
      </Widget>
    </SelectedTagProvider>
  );
};

TagsKnowledge.defaultProps = {
  loading: false,
};
