import { Column } from 'react-table';

interface ExpertRow {
  projectName: string;
  status: string;
  dateEnd: string;
}

export const expertColumns: Column<ExpertRow>[] = [
  {
    Header: 'Project Name',
    accessor: 'projectName',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Date End',
    accessor: 'dateEnd',
  },
];
