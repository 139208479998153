/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TwitterAuthService } from '../../../../../services/AuthService/AuthService';
import AppContext from '../../../../../shared/context/app/AppContext';
import ProcessAuthentication from '../../ProcessAuthentication/ProcessAuthentication';
import Loading from '../../../../../shared/components/Loading/Loading';
import { useAuthenticationError } from '../../../../../shared/hooks/Authentications/useAuthenticationError';

const TwitterCallback = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const { redirectAfterError } = useAuthenticationError();

  useEffect(() => {
    const getUserData = async () => {
      const res = await TwitterAuthService.postTokens();
      if(res.error) {
        redirectAfterError(res.error);
      } else {
        ProcessAuthentication({res, dispatch, navigate});
      }
    };
    getUserData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  return useMemo(() => (
    <Loading />
    ), []
  );
};

export default React.memo(TwitterCallback);