import axiosInstance from '../utils/axiosInstance';

export const MemberService = {
  inviteMember: async (authenticationToken: string, email: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .post('/members', { email })
        .then((response) => response?.data?.data || undefined);
      return results;
    } catch (error: any) {
      return {error: error.response.data.message};
    }
  },
  acceptMemberInvitation: async (
    authenticationToken: string,
    token: string,
    organizationId: string,
  ) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .put(`/members?token=${token}&organization_id=${organizationId}`, {})
        .then((response) => response?.data?.data || undefined);
        return results;
      } catch (error: any) {
      return {error: error.response.data.message};
    }
  },
  getMembers: async (authenticationToken: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .get('/members')
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
};
