import { Link } from 'react-router-dom';
import { styled, fadeIn } from '../../../config/theme';

export const WrapperPeople = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  variants: {
    type: {
      content: {
        justifyContent: 'flex-start',
      },
    },
  },
});

export const PeopleInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'baseline',
  padding: '0 $spaceSmall 0 $spaceSmall',
  variants: {
    kind: {
      'people-responsive': {
        padding: '$spaceXSmall 0 0',
        display: 'flex',
        '@bp2': { display: 'none' },
      },
    },
  },
});

export const PeopleName = styled('p', {
  color: '$offBlack',
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  whiteSpace: 'wrap',
  overflow: 'hidden',
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  '@bp2': {
    fontSize: '$bodySmall',
  }
});

export const PeopleDate = styled('span', {
  fontSize: '$bodyXSSmall',
  fontWeight: '$normal',
  letterSpacing: '$letterSpacing1',
  color: '$lightColorSpan',
  whiteSpace: 'pre-line',
  '&.mention-tooltip': {
    ' span': {
      display: 'none',
      '@bp3': {
        display: 'inline',
      },
    },
  },
  '@bp1': {
    whiteSpace: 'normal',
  },
  variants: {
    little: {
      true: {
        fontSize: '$bodyXSmall',
      },
    },
    mentions: {
      true: {
        fontSize: '$bodyXSmall',
        fontWeight: '$weight500',
      },
    },
  },
});

export const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gridArea: 'peopleData',
  '& img': {
    borderRadius: '50%',
    margin: 0,
  },
  '&.gat-people-follow': {
    alignItems: 'center',
  },
});

export const NameLink = styled(Link, {
  color: '$offBlack',
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing2',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  variants: {
    little: {
      true: {
        fontSize: '$bodyXSmall',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
        '@bp2': {
          fontSize: '$bodySmall',
        }
      },
    },
  },
});

export const PendingText = styled('span', {
  color: '$labelLight',
  fontSize: '$bodyXXSmall',
  letterSpacing: '$letterSpacing1',
});

export const TextTooltip = styled('span', {
  display: 'block',
  color: '$body',
  fontWeight: '$weight500',
  marginLeft: '4px',
  fontSize: '$bodyXSmall',
  cursor: 'pointer',
});

export const AuthorText = styled(PendingText, {
  fontWeight: '$normal',
  padding: '2px 6px',
  margin: '0 4px',
  backgroundColor: '#EFF0F7',
});

export const BoxInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '2.5rem',
  gap: '$spaceSmall',
  '&:last-child': {
    marginBottom: '0',
  },
});

export const BoxMembers = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$spaceSmall',
});

export const TextOthers = styled('p', {
  color: '$offBlack',
  fontWeight: '$weight600',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
});

export const WrapDescription = styled('div', {
  marginTop: '$spaceSmall',
  fontSize: '$bodyCard',
  width: '100%',
  animation: `${fadeIn} 1s`,
});
