import React, { FC, useState, useEffect } from 'react';
import { useTransition, animated, config } from 'react-spring';
import Icon from '../Icon/Icon';
import ArrowDownIcon from '../../../assets/images/icons/arrow-down-blue.svg';

import {
  CollapsibleRoot, CollapsibleTrigger, CollapsibleContent,
  Flex, Text, IconButton, Block, AccordionArrow
} from './styled';

interface CollapsibleModel {
  children: string | boolean | JSX.Element | JSX.Element[];
  firstChild: string | JSX.Element | JSX.Element[];
  textButton?: string;
}

type CollapsibleProps = CollapsibleModel &
  React.ComponentProps<typeof CollapsibleRoot> & {
    css?: any;
  };

const CollapsibleComponent: FC<CollapsibleProps> = ({
  children, firstChild, textButton, css
}) => {
  const [open, setOpen] = useState(false);
  const transitionsContent = useTransition(open, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: config.stiff,
  });

  useEffect(() => {
    if (!children) {
      setOpen(false);
    }

    return () => {
      setOpen(false);
    };
  }, [children]);

  return (
    <CollapsibleRoot open={open} onOpenChange={setOpen} css={css} className='collapsible-root'>
      <Block>
        {firstChild}
      </Block>
      {transitionsContent((styles, item) =>
        item ? (
          <animated.div style={{ width: '100%', opacity: styles.opacity }}>
            <CollapsibleContent className='collapsible-content'>
              <Block>
                {children}
              </Block>
            </CollapsibleContent>
          </animated.div>
        ) : null
      )}
      {Boolean(children) && (
        <Flex>
          <CollapsibleTrigger asChild>
            <IconButton>
              {textButton && <Text>{textButton}</Text>}
              <AccordionArrow>
                <Icon img={ArrowDownIcon} width="15px" height="15px" alt="More" />
              </AccordionArrow>
            </IconButton>
          </CollapsibleTrigger>
        </Flex>
      )}
    </CollapsibleRoot>
  );
};

CollapsibleComponent.defaultProps = {
  css: {},
  textButton: undefined
};

export default CollapsibleComponent;
