import { styled } from '../../../config/theme';

export const SidebarWrap = styled('div', {
  width: '100%',
  height: '100%',
  position: "relative",
  display: 'inline-block',
  variants: {
   type: {
     small: {
      '.gat-widget-shimer': { marginBottom: '18px' },
      '@bp3': { '.gat-widget-shimer': { marginBottom: '0px' }}
     }
   }
  }
});

export const ContentSidebar = styled('div', {
  position: 'sticky',
  width: '100%',
  top: '70px',
  minHeight: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  '@bp3': {
    paddingBottom: 10,
  }
});
