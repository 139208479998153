import React from 'react';
import { IconWrapper, Img } from './styled';

interface IconModel {
  width?: string;
  height?: string;
  img: string;
  closeToast?: any;
  alt?: string;
  provider?: string;
  setLoadAvatarError?: React.Dispatch<React.SetStateAction<boolean>>;
}

type IconPropsModel = IconModel &
  React.ComponentProps<typeof IconWrapper> & {
    css?: any;
    type?: string;
    className?: string;
    margin0?: boolean;
    marginL?: boolean;
    marginR?: boolean;
    loadingIconRotate?: boolean;
    fadeIn?: boolean;
    onMouseOver?: any;
    onMouseOut?: any;
  };

const Icon: React.FunctionComponent<IconPropsModel> = ({
  height,
  width,
  img,
  css,
  type,
  className,
  closeToast,
  alt,
  setLoadAvatarError,
  provider,
  margin0,
  marginL,
  marginR,
  loadingIconRotate,
  fadeIn,
  onMouseOver,
  onMouseOut,
}) => {
  const handleError = () => {
    if (setLoadAvatarError) {
      setLoadAvatarError(true);
    }
  };
  const classIcon = className || '';

  return (
    <IconWrapper className={`gat-icon ${classIcon}`} css={css} type={type} onClick={closeToast}>
      <Img
        alt={alt}
        src={img}
        height={height}
        margin0={margin0}
        marginL={marginL}
        marginR={marginR}
        loadingIconRotate={loadingIconRotate}
        fadeIn={fadeIn}
        width={width}
        onError={handleError}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        referrerPolicy={provider === 'Google' ? 'no-referrer' : 'no-referrer-when-downgrade'}
      />
    </IconWrapper>
  );
};

Icon.defaultProps = {
  height: undefined,
  width: undefined,
  css: undefined,
  type: undefined,
  closeToast: undefined,
  className: undefined,
  setLoadAvatarError: undefined,
  alt: undefined,
  provider: undefined,
  margin0: false,
  marginL: false,
  marginR: false,
  loadingIconRotate: false,
  fadeIn: false,
  onMouseOver: undefined,
  onMouseOut: undefined,
};

export default Icon;
