/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext } from 'react';
import { HomeService, dataInterface } from '../../../services/home.service';
import LoadDataSection from '../../../shared/components/LoadDataSection/LoadDataSection';
import CardResourcesList from '../../../shared/components/CardResources/CardResourcesList';
import ResourcesListLoad from '../../../shared/components/ShimmerComponent/ResourcesListLoad';
import AppContext from '../../../shared/context/app/AppContext';
import { BoxShimmer } from '../../../shared/components/ShimmerComponent/styles';
import {Spinner} from '../../../shared/components/Spinner/spinner';
import ContentsAlternative from '../Alternative/ContentsAlternative';


interface ContentModel {
  isFeedTablet?: boolean;
  limitFeed?: number;
};

const Contents: React.FC<ContentModel> = ({ isFeedTablet, limitFeed }) => {
  const { state } = useContext(AppContext);
  const { authenticationToken, filterContent, reload } = state;
  const size = isFeedTablet ? 25 : 10;

  const actionResources = useCallback(async (
    page: number, limit: number, filterBy?: string, filter?: string, orderBy?: string) => {
    let parameters: dataInterface = { authenticationToken, page, limit, filterBy, filter, orderBy };
    let result = [];
    if (filterContent.category === 'feed' && !filter) {
      parameters = { ...parameters, filterBy: 'feed' };
    } else if (filterContent.category === 'tag') {
      parameters = { ...parameters, filterBy: 'tag' };
    }
    result = await HomeService.getContents(parameters);
    return result;
  }, [authenticationToken, filterContent.category, isFeedTablet]);

  return(
    <LoadDataSection
      onAction={actionResources}
      component={CardResourcesList}
      alternativeComponent={<ContentsAlternative />}
      shimmerComponent={<ResourcesListLoad total={3} />}
      shimmerInfinity={(
        <BoxShimmer type='center'>
          <Spinner className='spinner' type='xs' css={{padding: '$spaceSmall' }} />
        </BoxShimmer>
      )}
      filterByProp={filterContent?.type}
      filterProp={filterContent?.filter}
      propsComponent={{
        limitFeed,
        isFeedTablet,
        hideSuggestions: true,
        showPlaylist: isFeedTablet }}
      size={size}
      reload={reload}
      saveData
      isInfinite
      removeDuplicated
      typeButton="outline"
      orderByProp={filterContent?.orderBy}
     />
  );
};

Contents.defaultProps = {
  isFeedTablet: false,
  limitFeed: undefined,
};

export default Contents;
