import axiosInstance from "../utils/axiosInstance";

export interface getContentInterface {
  id: number;
  authenticationToken?: string;
}

export const ContentsService = {
  getContent: async ( data: getContentInterface) => {
    const { id, authenticationToken } = data;
    const url = `contents/${id}`;
    try {
      const results = await axiosInstance(authenticationToken).get(url)
        .then(response => response?.data?.data || []);
      return results;
    } catch (error) {
      return null;
    }
  },
};