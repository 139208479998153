import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../shared/components/Sidebar/Sidebar';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { Widget, DivWidgetsSidebar } from '../../../shared/components/Widget/styles';
import WidgetResponsive from '../../../shared/components/Widget/WidgetResponsive';
import LinksFooter from '../../../shared/components/Footer/LinksFooter';
import Playlist from '../../Home/Playlist/Playlist';
import { TagsKnowledge } from '../../../shared/components/Tags/TagsKnowledge';
import { TagModel } from '../../../shared/interfaces/interfaces';
import { useUser } from '../../../shared/hooks/useUser';

const RESPONSIVE = 980;

interface SidebarProps {
  isEmpty: boolean;
  data: TagModel[];
  userId: number | string;
  loading: boolean;
}

const ProfileSidebar: React.FunctionComponent<SidebarProps> = ({
  isEmpty,
  data,
  userId,
  loading,
}) => {
  const { width } = useWindowSize();
  const [dataTags, setDataTags] = useState<Array<TagModel>>(data || []);
  const { isSameUser } = useUser();
  const { slug } = useParams();
  const theSameUser = isSameUser(slug as string);
  const [showPlaylist, setShowPlaylist] = useState(false);

  useEffect(() => {
    setDataTags(data);
  }, [data]);

  useEffect (() => {
    if(width > RESPONSIVE) setShowPlaylist(true);
  }, [width]);

  return (
    <Sidebar>
      {(width > RESPONSIVE || (width < RESPONSIVE && isEmpty)) && (
        <>
          <WidgetResponsive
            breakpoint={720}
            kindResource="content"
            limit={10}
            css={{ minHeight: 0 }}
          />
          <DivWidgetsSidebar className="widgets-wrap validations-widgets">
            <TagsKnowledge
              data={dataTags}
              sameUser={theSameUser}
              userId={userId}
              loading={loading}
            />
          </DivWidgetsSidebar>
        </>
      )}
      {showPlaylist && <Playlist />}
      <Widget kind="feed-link">
        <LinksFooter type="light" />
      </Widget>
    </Sidebar>
  );
};

export default ProfileSidebar;
