import React, { useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { KnowledgeService } from '../../../services/knowledge.service';
import Loading from '../Loading/Loading';
import useGetToken from '../../hooks/useGetToken';

interface AcceptRequestsProps {
  requestType: 'playlist_invitation' | 'playlist_request';
}

const AcceptRequests: React.FC<AcceptRequestsProps> = ({ requestType }) => {
  const navigate = useNavigate();
  const { token, playlistId }  = queryString.parse(window.location.search);
  const authenticationToken = useGetToken();

  // authenticate user for accept the invitation
  if (token && !authenticationToken && requestType === 'playlist_invitation') {
    localStorage.setItem('playlistToken', `${token}`);
    localStorage.setItem('playlistId', `${playlistId}`);
    navigate('/authentication#sign-in');
  }

  useEffect(() => {
    const accept = async () => {
      if(token && playlistId){
        let res;
        if(requestType === 'playlist_invitation'){
          const data = { token, playlistId, authenticationToken };
          res = await KnowledgeService.updateKBCollaborators(data);
        } else {
          res = await KnowledgeService.acceptRequest({ token, playlistId });
        }
        if(res?.attributes?.status === 'accepted'){
          navigate(`/users/${res.attributes.created_by_slug}/playlists/${playlistId}`);
        }
        else {
          navigate('/');
          toast.error('Unable to find invitation, please try again');
        }
      } else {
        navigate('/');
        toast.error('Token not found, please try again');
      }
    };
    if ((requestType === 'playlist_invitation' && authenticationToken) ||
         requestType !== 'playlist_invitation') { accept(); }

  }, [authenticationToken, navigate, playlistId, requestType, token]);

  return useMemo(() => (
    <Loading />
    ), []
  );
};

export default React.memo(AcceptRequests);