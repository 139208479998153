import React from 'react';
import { Typography } from './styled';

interface HeaderModel {
  title: string;
  subTitle: string;
}

export const Header: React.FunctionComponent<HeaderModel> = ({ title, subTitle }) => (
  <div>
    <Typography
      size="displayXSmall"
      weight="700"
      lineHeight="lineHeight3"
      letterSpacing="letterSpacing3"
      space="marginBottomSpaceLarge"
    >
      {title}
    </Typography>
    <Typography
      size="bodyListItem"
      weight="500"
      lineHeight="lineHeight2"
      letterSpacing="letterSpacing8"
    >
      {subTitle}
    </Typography>
  </div>
);
