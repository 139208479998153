import { styled } from '../../../config/theme';


export const TagsInput = styled('label', {
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  minHeight: '4rem',
  width: '100%',
  marginBottom: '.5rem',
  '& input': {
    border: 'none',
    width: 'auto',
    height: '28px',
    borderRadius: '4px',
    fontSize: '$displayXSSmall',
    backgroundColor: '$bodyPale',
    padding: '0 4px',
    '&:focus': {
      outline: 'transparent',
    }
  }
});

export const TagDiv = styled('li', {
  width: 'auto',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$offBlack',
  padding: '0 .5rem',
  fontSize: '$displayXSSmall',
  listStyle: 'none',
  borderRadius: '$radius03',
  margin: '0 .5rem .5rem 0',
  background: '$bodyPale'
});

export const TagCloseIcon = styled('div', {
  display: 'block',
  width: '1rem',
  height: '1rem',
  lineHeight: '1rem',
  textAlign: 'center',
  fontSize: '$displayXSSmall',
  marginLeft: '.5rem',
  color: '$line',
  borderRadius: '50 %',
  cursor: 'pointer',
});

export const ListTag = styled('ul', {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0',
  margin: '0',
});

export const AddTag = styled('div', {
  cursor: 'pointer',
  width: '2rem',
  height: 'auto',
  borderRadius: '$radius03',
  textAlign: 'center',
  fontSize: '$displayXSSmall',
  backgroundColor: '$bodyPale',
  padding: '2px 4px',
});
