import React from 'react';
import { FormikProvider } from 'formik';
import {
  RightContainer,
  FormikInput,
  PlaceholderWithIcon,
  FormStyled,
  TitleSection,
  WrapperForm,
} from '../styled';
import { LinkedinLink, ResearchGate } from '../../../../../shared/utils/globalText';

interface SocialMediaFormModel {
  form: any;
}

const SocialMediaForm: React.FC<SocialMediaFormModel> = ({ form }) => (
  <WrapperForm>
    <RightContainer>
      <TitleSection>Social links</TitleSection>
    </RightContainer>
    <FormikProvider value={form}>
      <FormStyled>
        <PlaceholderWithIcon icon="researchGate">
          <FormikInput
            id="researchGate"
            name="researchGate"
            placeholder="ResearchGate account"
            onChange={(e: any) => {
              let { value } = e.target;
              if (value.length === 1) value = ResearchGate + e.target.value;
              if (value.length > ResearchGate.length)
                form?.setFieldValue('researchGate', value.replace(/\s/g, ''));
              if (value.length === ResearchGate.length) form?.setFieldValue('researchGate', '');
            }}
          />
        </PlaceholderWithIcon>
        <PlaceholderWithIcon icon="linkedin">
          <FormikInput
            id="linkedinLink"
            name="linkedinLink"
            placeholder="Linkedin account"
            onChange={(e: any) => {
              let { value } = e.target;
              if (value.length === 1) value = LinkedinLink + e.target.value;
              if (value.length > LinkedinLink.length)
                form?.setFieldValue('linkedinLink', value.replace(/\s/g, ''));
              if (value.length === LinkedinLink.length) form?.setFieldValue('linkedinLink', '');
            }}
          />
        </PlaceholderWithIcon>
      </FormStyled>
    </FormikProvider>
  </WrapperForm>
);
export default SocialMediaForm;
