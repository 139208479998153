import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { OptionTitle, OptionText, OptionContainer } from "../styled";
import Icon from "../../../../../shared/components/Icon/Icon";
import organization from "../../../../../assets/images/icons/organizationIcon.svg";
import AuthenticationContext from "../../../../../shared/context/authentication/authenticationContext";

const WantAnExpert = () => {
  const navigate = useNavigate();
  const { setUserType } = useContext(AuthenticationContext);

  const handleOptionClick = () => {
    setUserType('wantAnExpert');
    navigate('/new-project');
  };

  return (
    <OptionContainer onClick={handleOptionClick}>
      <Icon img={organization} alt="Offer my expertise" />
      <OptionTitle>
        I want to get an Expert Opinion
      </OptionTitle>
      <OptionText>
        Bespoke 5-15 min video recordings from biotech experts.
      </OptionText>
    </OptionContainer>
    
  );
};

export default WantAnExpert;