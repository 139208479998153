import { styled } from '../../../../config/theme';

export const HowDoesItWorkImg = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  boxShadow: '0px 0px 72px 0px #00000014',
  borderRadius: '$radius05',
  maxWidth: 650,
  marginTop: '$spaceSmall',
});

export const HowDoesItWorkContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '$spaceLarge',
  '@bp4': {
    flexDirection: 'row',
    paddingX: '$spaceXLarge',
    marginTop: '$spaceMedium',
  },
});

export const SectionContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@bp4': {
    width: '50%',
  },
});

export const TextContainer = styled('div', {
  padding: '0 10px',
  marginBottom: '3.125rem',
  h3: {
    color: '$blurple',
    fontSize: '$displayXSmall',
    fontStyle: 'normal',
    fontWeight: '$weight700',
    lineHeight: '$lineHeight3',
    letterSpacing: 'letterSpacing3',
    marginBottom: '$spaceLarge',
  },
  p: {
    color: '$monochromeBlack',
    fontSize: '$bodySmall',
    letterSpacing: '$letterSpacing1',
    maxWidth: 550,
  },
  variants: {
    noMarginBottom: {
      true: {
        marginBottom: 0,
      },
    },
  },
});
