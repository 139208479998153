import React, { useState, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import { CustomButton } from '../Button/styled';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';
import { UserService } from '../../../services/user.service';
import useGetToken from '../../hooks/useGetToken';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import AppContext from '../../context/app/AppContext';
import { Wrapper, BoxInfo } from './styles';
import { validateAlternativeKey as validateFollow } from '../../utils/helper';

interface FollowStatuses {
  follow: number | null | undefined;
  id?: string;
  isProfile?: boolean;
  username?: string;
  actionFollow?: () => void;
  // eslint-disable-next-line no-unused-vars
  afterFollow?: (i?:any) => void;
}

type PeopleFollowProps = FollowStatuses &
  React.ComponentProps<typeof CustomButton> & {
    size?: string;
    css?: any;
  };

const PeopleFollow: React.FunctionComponent<PeopleFollowProps> = ({
  follow, actionFollow, id, isProfile, username, size, css, afterFollow
}) => {
  const token = useGetToken();
  const userLogged = useIsLoggedIn();
  const { dispatch, state } = useContext(AppContext);
  const [isFollowed, setIsFollowed] = useState(Boolean(follow) && Boolean(id));
  const [relationship, setRelationship] = useState(follow);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [followStatus, setFollowStatus] = useState(isProfile ? 'follow-profile' : "follow") as any;
  const { dataFollow, dataUnfollow } = state;
  const navigate = useNavigate();

  const handleFollow = async () => {
    if (id && userLogged) {
      if (actionFollow) {
        actionFollow();
      } else if (isFollowed) {
        setLoading(true);
        setOpenModal(false);
        const resultUnFollow = await UserService.unFollow(
          { authenticationToken: token, id: `${relationship}` });
        if (resultUnFollow?.attributes?.status) {
          await dispatch({ type: 'SET_UNFOLLOW', payload: { [id]: `${relationship}` }});
          await dispatch({ type: 'SET_FOLLOW', payload: { [id]: undefined }});
          await setRelationship(null);
          await setIsFollowed(false);
          await setLoading(false);

          if (afterFollow) {
            afterFollow(false);
          }
        }
      } else {
        setLoading(true);
        const result = await UserService.follow({ authenticationToken: token, id });
        if (result?.attributes?.status) {
          await dispatch({ type: 'SET_FOLLOW', payload: { [id]: `${result?.id}` }});
          await dispatch({ type: 'SET_UNFOLLOW', payload: { [id]: undefined }});
          await setRelationship(result?.id);
          await setIsFollowed(true);
          await setLoading(false);


          if (afterFollow) {
            afterFollow(true);
          }
        }
      }
    } else if(id) {
      localStorage.setItem('followUser', id);
      navigate('/authentication#sign-in');
    }
  };

  useMemo(() => {
    if (dataFollow && id && validateFollow(id, dataFollow)) {
      setFollowStatus(isProfile ? 'following-profile' : 'following');
      setIsFollowed(true);
      setRelationship(dataFollow[id]);
    }

    if (dataUnfollow && id && validateFollow(id, dataUnfollow) &&
      (`${relationship}` === dataUnfollow[id])) {
      setFollowStatus(isProfile ? 'follow-profile' : "follow");
      setIsFollowed(false);
      setRelationship(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFollow, dataUnfollow]);

  useMemo(() => {
    if (isFollowed) {
      setFollowStatus(isProfile ? 'following-profile' : 'following');
    } else {
      setFollowStatus(isProfile ? 'follow-profile' : "follow");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFollowStatus, isFollowed, isProfile]);

  return (
    <Wrapper className='gat-people-follow' css={css}>
      <Button
        title={isFollowed ? 'Following' : 'Follow'}
        kind={followStatus}
        size={size}
        renderProps={{
          disabled: !id || loading,
          onClick: () => (isFollowed && username) ? setOpenModal(true) : handleFollow()
        }}
      />
      {openModal && <Modal kindOverlay='gat-modal-overlay--light' isOpen={openModal} >
        <Card type='alert' css={{ padding: '3rem', textAlign: 'center' }}>
          <BoxInfo>
            <h2>{`Unfollow @${username}?`}</h2>
          </BoxInfo>
          <BoxInfo>
            <Button
              title='Unfollow'
              kind='continue'
              renderProps={{ onClick: () => handleFollow() }}
            />
            <Button
              title='Cancel'
              kind='cancel'
              renderProps={{ onClick: () => setOpenModal(false) }}
            />
            </BoxInfo>
        </Card>
      </Modal>}
    </Wrapper>
  );
};

PeopleFollow.defaultProps = {
  size: undefined,
  id: undefined,
  isProfile: undefined,
  username: undefined,
  actionFollow: undefined,
  afterFollow: undefined,
  css: undefined,
};

export default PeopleFollow;
