import React from 'react';
import { User } from '../../interfaces/interfaces';
import { getUserName } from '../../utils/helper';
import { WrapperAuthor } from './styles';
import People from '../People/People';

type AuthorsProps = User & {
    actionClose?: () => void;
    viewModal?: boolean;
  };

const Authors: React.FC<AuthorsProps> = ({ id, attributes, actionClose, viewModal }) => (
  <WrapperAuthor key={id} className='author-container'>
    <div className='authors-details'>
      <People
        name={getUserName({ name: attributes.name })}
        text={`${viewModal && attributes?.twitter_handle ? `@${attributes?.twitter_handle}`: ''}`}
        img={attributes?.avatar_url || ''}
        type='little'
        follow={attributes?.relationship_id}
        id={id}
        twitterName={attributes?.twitter_handle}
        actionClose={actionClose}
        slug={attributes?.slug}
      />
    </div>
  </WrapperAuthor>
);

Authors.defaultProps = {
  actionClose: undefined,
  viewModal: undefined
};

export default Authors;
