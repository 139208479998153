import React from 'react';
import { TypographyH2 } from '../../styled';
import { OptionsUsage } from './OptionsUsage';
import { StarShapeIcon } from './StarShapeIcon';
import { BadgeIcon } from './BadgeIcon';
import { MedicalFileIcon } from './MedicalFileIcon';
import { PlayIcon } from './PlayIcon';
import { OptionsUsageContainer } from './styled';
import { keyExtractor } from '../../../../shared/utils/helper';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';

const OptionsData = [
  {
    icon: <StarShapeIcon />,
    title: 'More reliable conclusions',
    text: 'The lower cost of Expert Opinion recordings allows you to gather inputs from multiple experts at a time, increasing the evidence base for your research or analysis. ',
  },
  {
    icon: <BadgeIcon />,
    title: 'Faster insights',
    text: 'You’ll have rapid access to the information you need. Experts typically deliver their recordings within 2 - 3 days.',
  },
  {
    icon: <MedicalFileIcon />,
    title: 'Enhanced research and analysis',
    text: 'Including the opinions and insights of multiple topic specialists in your research can add credibility and value to your findings. ',
  },
  {
    icon: <PlayIcon />,
    title: 'On-demand access',
    text: 'You’ll avoid tedious expert scheduling or contractual processes. Expert Opinions are available on-demand directly from your own project dashboard.',
  },
];

export const WhyUseExpertOpinions = () => (
  <ExpertContainer space="section_fullPadding" >
    <div style={{maxWidth: 600}}>
      <TypographyH2 type="displaySmall" color="bluerplePrimary" align="center" lineHeight="normal">
        Why use Expert Opinions in <b>your next research project?</b>
      </TypographyH2>
    </div>
    <OptionsUsageContainer>
      {OptionsData.map((option) => (
        <OptionsUsage
          key={keyExtractor()}
          icon={option.icon}
          title={option.title}
          text={option.text}
        />
      ))}
    </OptionsUsageContainer>
  </ExpertContainer>
);
