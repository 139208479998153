import { styled } from '../../../../../config/theme';

export const ItemResultWrapper = styled('li', {
  display: 'block',
  cursor: 'pointer',
  float: 'left',
  padding: '0.25rem 1rem',
  position: 'relative',
  textDecoration: 'none',
  transitionDuration: '0.5s',
  '&:first-child': {
    paddingTop: '1rem',
  },
  '&:last-child': {
    paddingBottom: '1rem',
  },
  '&:hover': {
    backgroundColor: '$input',
  }
});

export const Box = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '& img': {
    marginRight: '12px !important'
  },
  '& .gat-avatar-initials': {
    marginRight: '12px !important'
  }
});

export const NamesWrapper = styled('div', {
  '.item-sub-text': {
    display: 'none'
  },
  '@bp0': {
    display: 'flex',
    flexWrap: 'wrap',
    '.item-sub-text': {
      display: 'block'
    }
  }
});

export const Text = styled('p', {
  fontWeight: '$weight500',
  paddingLeft: 10,
});

export const TextSimple = styled('span', {
  fontWeight: '$normal',
});

export const TextGray = styled(TextSimple, {
  color: '$label',
  paddingLeft: 10,
});
