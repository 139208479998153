import React from 'react';

export const ShowChartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M28.9459 9.71862C28.822 9.59365 28.6745 9.49445 28.512 9.42676C28.3496 9.35907 28.1753 9.32422 27.9993 9.32422C27.8233 9.32422 27.649 9.35907 27.4865 9.42676C27.324 9.49445 27.1766 9.59365 27.0526 9.71862L18.6659 18.1186L12.9459 12.3853C12.822 12.2603 12.6745 12.1611 12.512 12.0934C12.3496 12.0257 12.1753 11.9909 11.9993 11.9909C11.8233 11.9909 11.649 12.0257 11.4865 12.0934C11.324 12.1611 11.1766 12.2603 11.0526 12.3853L3.0526 20.3853C2.92763 20.5092 2.82844 20.6567 2.76075 20.8192C2.69305 20.9817 2.6582 21.1559 2.6582 21.332C2.6582 21.508 2.69305 21.6822 2.76075 21.8447C2.82844 22.0072 2.92763 22.1547 3.0526 22.2786C3.17655 22.4036 3.32402 22.5028 3.4865 22.5705C3.64898 22.6382 3.82325 22.673 3.99927 22.673C4.17528 22.673 4.34956 22.6382 4.51204 22.5705C4.67452 22.5028 4.82198 22.4036 4.94593 22.2786L11.9993 15.2119L17.7193 20.9453C17.8432 21.0703 17.9907 21.1694 18.1532 21.2371C18.3156 21.3048 18.4899 21.3397 18.6659 21.3397C18.842 21.3397 19.0162 21.3048 19.1787 21.2371C19.3412 21.1694 19.4887 21.0703 19.6126 20.9453L28.9459 11.6119C29.0709 11.488 29.1701 11.3405 29.2378 11.1781C29.3055 11.0156 29.3403 10.8413 29.3403 10.6653C29.3403 10.4893 29.3055 10.315 29.2378 10.1525C29.1701 9.99004 29.0709 9.84257 28.9459 9.71862Z"
      fill="white"
    />
  </svg>
);
