import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetToken from '../../hooks/useGetToken';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Container } from './styled';
import useScrollTop from '../../hooks/useScrollTop';
import AppContext from '../../context/app/AppContext';

interface ContainerModel {
  children?: JSX.Element | JSX.Element[];
}

const GlobalContainer: React.FunctionComponent<ContainerModel> = ({ children }) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const token = useGetToken();
  const [loggedIn, setLoggedIn] = useState(false);
  useScrollTop();
  const path = window.location.pathname?.toLowerCase();
  const pathsWithoutHeader = ['/authentication', '/forgot-password', '/new-password', '/auth/twitter/callback',
                              '/auth/google/callback'];
  const pathsWithFooter = ['/settings', '/about', '/terms', '/privacy', '/content-community-guidelines', '/unsubscribe'];
  useEffect(() => {
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [navigate, token]);

  useEffect(() => {
    dispatch({ type: 'RESET_FOLLOW_UNFOLLOW' });
    dispatch({ type: 'RESET_IMAGES' });
    dispatch({ type: 'SET_DELETE_ITEMS', payload: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
     {(pathsWithoutHeader.includes(path)) ?  null : <Header logged={loggedIn} /> }
        {children}
     {(pathsWithFooter.includes(path)) ? <Footer /> : null}
    </Container>
  );
};

GlobalContainer.defaultProps = {
  children: undefined,
};

export default GlobalContainer;
