import React from 'react';

export const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M21.3327 13.6946L14.666 9.8413C14.2608 9.60737 13.8012 9.48416 13.3334 9.48404C12.8655 9.48392 12.4058 9.60689 12.0005 9.84062C11.5952 10.0743 11.2586 10.4106 11.0243 10.8156C10.7901 11.2206 10.6665 11.6801 10.666 12.148V19.8546C10.6665 20.3223 10.7899 20.7816 11.024 21.1864C11.258 21.5913 11.5944 21.9275 11.9994 22.1613C12.4047 22.3954 12.8646 22.5186 13.3327 22.5186C13.8008 22.5186 14.2606 22.3954 14.666 22.1613L21.3327 18.308C21.7368 18.0736 22.0722 17.7371 22.3054 17.3323C22.5386 16.9274 22.6613 16.4685 22.6613 16.0013C22.6613 15.5341 22.5386 15.0752 22.3054 14.6703C22.0722 14.2655 21.7368 13.9291 21.3327 13.6946ZM19.9994 16.0013L13.3327 19.8546V12.148L19.9994 16.0013ZM15.9994 2.66797C13.3623 2.66797 10.7844 3.44996 8.59175 4.91504C6.3991 6.38013 4.69013 8.46251 3.68096 10.8989C2.67179 13.3352 2.40775 16.0161 2.92222 18.6025C3.43669 21.1889 4.70656 23.5647 6.57126 25.4294C8.43597 27.2941 10.8117 28.564 13.3982 29.0784C15.9846 29.5929 18.6655 29.3289 21.1018 28.3197C23.5382 27.3105 25.6205 25.6016 27.0856 23.4089C28.5507 21.2162 29.3327 18.6384 29.3327 16.0013C29.3327 14.2503 28.9878 12.5165 28.3177 10.8989C27.6477 9.28118 26.6656 7.81133 25.4274 6.57321C24.1893 5.3351 22.7195 4.35297 21.1018 3.68291C19.4841 3.01285 17.7503 2.66797 15.9994 2.66797ZM15.9994 26.668C13.8897 26.668 11.8274 26.0424 10.0733 24.8703C8.31915 23.6982 6.95198 22.0323 6.14464 20.0833C5.33731 18.1342 5.12607 15.9895 5.53765 13.9203C5.94922 11.8512 6.96512 9.95059 8.45688 8.45883C9.94864 6.96707 11.8493 5.95117 13.9184 5.53959C15.9875 5.12802 18.1322 5.33925 20.0813 6.14659C22.0304 6.95392 23.6963 8.3211 24.8684 10.0752C26.0404 11.8293 26.666 13.8916 26.666 16.0013C26.666 18.8303 25.5422 21.5434 23.5418 23.5438C21.5414 25.5442 18.8283 26.668 15.9994 26.668Z"
      fill="white"
    />
  </svg>
);
