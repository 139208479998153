import { Link } from 'react-router-dom';
import { styled } from '../../../config/theme';

export const TextWrapper = styled('div', {
  textAlign: 'left',
  width: '100%',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 12,
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  color: '$offBlack',
  paddingBottom: '$spaceLarge',
  marginTop: '-12px',

  variants: {
    color: {
      underLined: {
        fontWeight: '$bold',
        textDecoration: 'underline',
      },
    },
  },
});

export const Text = styled(Link, {
  cursor: 'pointer',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 12,
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  color: '$offBlack',
  textDecoration: 'underline',
  variants: {
    color: {
      underLined: {
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
  },
});