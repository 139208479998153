import { styled } from '../../../config/theme';

export const Input = styled('input', {
  background: 'transparent',
  width: '95%',
  border: 'none',
  paddingX: '$spaceXSmall',
  paddingBottom: 1,
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodyXSmall',
  order: '2',

  variants: {
    kind: {
      noPaddings: {
        padding: 0,
      },
      kpModal: {
        fontFamily: '$poppins',
        '&:focus': {
          fontFamily: '$poppins',
          color: '$offBlack',
        },
      },
    },
  },
});
