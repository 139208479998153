import axiosInstance from '../utils/axiosInstance';

export const ProjectInvitationsService = {
  acceptInvitation: async (authenticationToken: string, token: string, projectId: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .put(`/project_invitations?token=${token}&project_id=${projectId}`, {})
        .then((response) => response?.data?.data || undefined);
      return results;
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
};
