import { styled } from "../../../../config/theme";

export const ExpertFooterContainer = styled('div', {
  alignItems: 'center',
  backgroundColor: '$monochromeBlack',
  display: 'flex',
  height: '$4',
  justifyContent: 'center',
  width: '100%',
});

export const ExpertFooterSubContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
