import React, { useContext } from 'react';
import Button from '../../../shared/components/Button/Button';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import useUploadVideo from '../../../shared/hooks/RecordingVideos/useUploadVideo';

interface SaveButtonProps {
  handleSaveModal?: () => void;
}

const SaveButton = ({ handleSaveModal }: SaveButtonProps) => {
  const { status, setStopCapturing } = useContext(VideoRecordingContext);
  const { handleUploadVideo } = useUploadVideo();
  const disabled = !['captured', 'paused'].includes(status);

  const handleSave = () => {
    if (handleSaveModal) handleSaveModal();
    handleUploadVideo();
  };

  const handleClick = () => {
    if (disabled) return;
    if (status === 'paused') setStopCapturing(true);
    else handleSave();
  };

  return (
    <Button
      title="Save Opinion"
      kind={handleSaveModal ? 'outline' : undefined}
      css={{
        opacity: status === 'capturing' ? '0.5' : '1',
        cursor: status === 'capturing' ? 'not-allowed' : 'pointer',
        width: handleSaveModal ? '100%' : '50%',
        height: '40px',
      }}
      handleClick={handleClick}
      disabled={disabled}
    />
  );
};

SaveButton.defaultProps = {
  handleSaveModal: undefined,
};

export default SaveButton;
