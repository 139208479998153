import { RouteProps } from 'react-router-dom';
import { ReactElement, JSXElementConstructor } from 'react';

export interface Token {
  email: string;
  first_name: string;
  last_name: string;
  token: string;
  token_expiration: string;
}

export const StatusTag = {
  WaitingReview: 'waiting_review',
  OnHold: 'on_hold',
  Approved: 'approved',
  Denied: 'denied',
};
interface ChildTagModelSave {
  name: string;
  max_level: string;
  alias1: string;
  alias2: string;
  alias3: string;
  children_attributes?: ChildTagModelSave[];
}

export interface TagModelSave {
  max_level: string;
  name: string;
  alias1: string;
  alias2: string;
  alias3: string;
  children_attributes?: ChildTagModelSave[];
}

export interface TagAttributes {
  name?: string;
  children: any[];
  children_count: number;
  status?: string;
}
export interface TagModel {
  id?: string;
  type?: string;
  attributes: TagAttributes;
  expertise_id?: string;
  contenttaglinks_id?: string;
  project_tag_id?: string;
}

export interface ExpertiseAttributes {
  created_at: string;
  created_by_id: number;
  status: string;
  tag: { data: TagModel };
}
export interface ExpertiseModel {
  id?: string;
  type?: string;
  attributes: ExpertiseAttributes;
}

export interface PlaylistItem {
  id: string | number;
  kb_item_id: string | number;
  title: string;
}

export interface UserAttributes {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  linkedin_link?: string;
  biography?: string;
  avatar_url?: string;
  expertise: [TagModel] | any;
  experiences?: Array<any>;
  following?: string;
  followers?: string;
  relationship_id: number | null | undefined;
  languages?: Array<any>;
  twitter_handle?: string;
  author_notification_id?: string | number | null;
  interests?: any;
  slug?: string | undefined;
  organization?: any;
  kind?: string;
  name?: string;
  aliases?: Array<string>;
  relation_type?: string;
}

export interface ResourcesAttributes {
  content_type?: string;
  title?: string;
  text?: string;
  date?: string;
  url?: string;
  authors: [UserAttributes] | any;
  contenttaglinks: [TagModel] | any;
  tags?: [TagModel] | any;
  save?: boolean;
  knowledge_books: [PlaylistItem] | [any];
}
export interface Resources {
  id: string;
  type?: string;
  attributes: ResourcesAttributes;
}
export interface Providers {
  id: string;
  type?: string;
  attributes: any;
}
export interface IRoute extends RouteProps {
  privacy?: string[];
  // eslint-disable-next-line no-unused-vars
  component(props?: string): ReactElement<any, string | JSXElementConstructor<any>> | null;
  subRoute?: IRoute;
  subRoutes?: IRoute[];
  roles?: string[];
  excludes?: string[];
}

export interface User {
  id: string;
  type: string;
  attributes: UserAttributes;
}

export interface MenuModel {
  text: string;
  link?: string;
  icon: any;
  activeIcon?: any;
  action?: any;
  subItems?: MenuModel[];
  external?: boolean;
}
export interface RequestModel {
  id?: number | string;
  page?: number;
  limit?: number;
  attribute?: string;
  authenticationToken?: string;
  filter?: string;
  filterBy?: string;
  name?: string;
  start?: Date;
  endDate?: Date;
  publicPlaylist?: boolean;
  userID?: string;
}

export interface WhoAccessAttributes {
  status: string;
  user_avatar: string;
  user_name: string;
  user_id: number;
}

export interface WhoAccess {
  id: string;
  type: string;
  attributes: WhoAccessAttributes;
}

export interface KnowledgeAttributes {
  created_by_id: string;
  content?: [Resources] | any;
  users?: [User] | any;
  created_at: string;
  updated_at: string;
  title: string;
  description: string;
  created_by: string;
  created_by_slug: string;
  created_by_avatar: string;
  created_by_username: string;
  created_by_name: string;
  modified_by: string;
  modified_at: Date;
  author_items_amount: number;
  content_items_amount: number;
  podcast_content_amount: number;
  article_content_amount: number;
  video_content_amount: number;
  tags: [TagModel] | any;
  public: boolean;
  whoAccess: WhoAccess;
  slug: string;
}

export interface Knowledge {
  id: string;
  type?: string;
  attributes: KnowledgeAttributes;
}

export interface StepOnboarding {
  step?: string;
  level: string;
  // eslint-disable-next-line no-unused-vars
  component(props?: any): ReactElement<any, string | JSXElementConstructor<any>> | null;
  required?: boolean;
  footer?: boolean;
}

export interface ExpertData {
  projectName: string;
  description: string;
  locations: any[];
  roles: any[];
}

export interface OrganizationInfo {
  name: string | undefined;
  image: string | undefined;
}

export interface PaymentData {
  cardHolderName: string;
  cardNumber: string;
  cvv: string;
  expiry: string;
  isDefault: boolean;
  id?: string;
}

export interface BillingData {
  id?: string;
  companyName: string;
  email: string;
  address: string;
  address2?: string;
  zipCode: string;
  city: string;
  country: string;
  vatNum: string;
  saveData: boolean;
}

export interface BillingPaymentData {
  credits: number;
  paymentInformation: PaymentData[];
  billingInformation: BillingData;
}

interface MemberDataAttributes {
  email: string;
  status: string;
  member_name: string;
  member_job: string;
  member_avatar_url?: string;
  member_user_id: number;
  organization_avatar: string;
  organization_id: number;
  organization_name: string;
  organization_user_name: string;
}
export interface MemberData {
  id?: string;
  type: string;
  attributes: MemberDataAttributes;
}
