import React from 'react';
import { VideoContainerEmpty } from './styled';
import Icon from '../../../../shared/components/Icon/Icon';
import Camera1 from '../../../../assets/images/icons/camera1.svg';
import RecordVideoModal from '../../../RecordingVideos/RecordVideoModal';

interface OpinionEmptyProps {
  allowRecording?: boolean;
}

const OpinionEmpty = ({ allowRecording }: OpinionEmptyProps) => (
  <>
    <VideoContainerEmpty>
      <Icon img={Camera1} />
    </VideoContainerEmpty>
    {allowRecording && <RecordVideoModal />}
  </>
);

OpinionEmpty.defaultProps = {
  allowRecording: false,
};

export default OpinionEmpty;
