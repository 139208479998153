import React from 'react';
import { AvatarListWrapper } from './styles';
import { keyExtractor } from '../../../../shared/utils/helper';
import { FlexContainer, Typography } from '../../styled';
import { Avatar } from './Avatar';

interface AvatarListProps {
  avatars: {
    name: string;
    link: string;
  }[];
}

export const AvatarList = ({ avatars }: AvatarListProps) => {
  const maxAvatars = 5;
  const visibleAvatars = avatars.slice(0, maxAvatars);
  const overflowCount = avatars.length - maxAvatars;
  return (
    <FlexContainer align="center" gap="0_75">
      <AvatarListWrapper>
        {visibleAvatars.map((avatar) => (
          <Avatar key={keyExtractor()} avatar={avatar} />
        ))}
      </AvatarListWrapper>
      {overflowCount > 0 && (
        <Typography size="bodySmall" weight="600">
          +{overflowCount}
        </Typography>
      )}
    </FlexContainer>
  );
};
