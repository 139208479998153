/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContext from '../../../../shared/context/app/AppContext';
import ProcessAuthentication from '../ProcessAuthentication/ProcessAuthentication';
import Loading from '../../../../shared/components/Loading/Loading';
import { OrcidAuthService } from '../../../../services/AuthService/OrcidAuthService';
import { useAuthenticationError } from '../../../../shared/hooks/Authentications/useAuthenticationError';

const OrcidCallback = () => {
  const navigate = useNavigate();
  const { login } = useParams();
  const { dispatch } = useContext(AppContext);
  const { redirectAfterError } = useAuthenticationError();

  useEffect(() => {
    const getUserData = async () => {
      if (login) {
        const res = await OrcidAuthService.postTokens(login);
        if(res.error) {
          redirectAfterError(res.error);
        } else {
          ProcessAuthentication({res, dispatch, navigate});
        }
      }
    };

    getUserData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);

  return useMemo(() => (
    <Loading />
    ), []
  );
};

export default React.memo(OrcidCallback);