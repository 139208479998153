/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import PeopleData from './PeopleData';
import PeopleFollow from './PeopleFollow';
import PeopleName from './PeopleName';
import { WrapperPeople } from './styles';
import AppContext from '../../context/app/AppContext';
import Button from '../Button/Button';

const TEXT_BTN_SAME_USER = `It's You`;
interface PeopleModel {
  name: string;
  text: string;
  img: string;
  follow: number | null | undefined;
  actionFollow?: () => void | Promise<any>;
  actionClose?: () => void;
  type: string;
  id?: string;
  hideFollow?: boolean;
  twitterName?: string;
  slug?: string;
  tooltip?: boolean;
}

type PeopleProps = PeopleModel &
  React.ComponentProps<typeof PeopleData> & {
    type?: string | undefined;
    kind?: string;
  };

const People: React.FunctionComponent<PeopleProps> = ({
  text,
  name,
  img,
  follow,
  actionFollow,
  type,
  id,
  hideFollow,
  twitterName,
  kind,
  actionClose,
  slug,
  tooltip,
}) => {
  const isResponsive = Boolean(kind?.includes('responsive'));
  const { state } = useContext(AppContext);
  const { userData } = state;
  const sameUser = id?.toString() === userData.userId;

  return (
    <>
      <WrapperPeople>
        <PeopleData
          img={img}
          name={name}
          text={text}
          type={type}
          slug={slug}
          kind={kind}
          actionClose={actionClose}
          follow={follow}
          id={id}
          tooltip={tooltip}
        />
        {!hideFollow &&
          (sameUser ? (
            <Button
              title={TEXT_BTN_SAME_USER}
              kind="following"
              size="medium"
              renderProps={{
                disabled: true,
              }}
            />
          ) : (
            <PeopleFollow
              follow={follow}
              actionFollow={actionFollow}
              id={id}
              username={twitterName}
              size="small"
            />
          ))}
      </WrapperPeople>
      {isResponsive && (
        <PeopleName
          kind="people-responsive"
          actionClose={actionClose}
          name={name}
          slug={slug}
          text={text}
        />
      )}
    </>
  );
};

export default People;
