/* eslint-disable no-unused-vars */
import React from "react";
import Card from '../../Card/Card';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import PlaylistForm from '../../ModalKB/PlaylistForm';
import closeIcon from '../../../../assets/images/icons/close.svg';

interface ModalCreatePlaylistProps {
  open: boolean;
  loading: boolean;
  errorMessage: string;
  onClose: () => void;
  actionForm: (event: any) => void;
};

const styleButton = {
  top: '0',
  right: '0',
  position: 'absolute',
  padding: '1.5rem 1.5rem 0 0',
  '& img': { margin: '0' }
};

const ModalCreatePlaylist: React.FC<ModalCreatePlaylistProps> = ({
  open, loading, errorMessage, onClose, actionForm
}) => (
  <Modal
    kindOverlay='gat-modal-overlay--light'
    isOpen={open}
    onClose={onClose}
    css={{ width: '85%', '@bp4': { width: '50%' } }}
  >
    <Card type='modalKB'>
      <Button
        title=''
        iconCustom={closeIcon}
        iconWidth='16px'
        iconHeight='16px'
        kind='transparent'
        handleClick={onClose}
        css={styleButton}
      />
      <PlaylistForm
        isEdit={false}
        loading={loading || false}
        errorMessage={errorMessage}
        actionContinue={actionForm}
      />
    </Card>
  </Modal>
);

ModalCreatePlaylist.defaultProps = {
};

export default ModalCreatePlaylist;
