import { styled } from '../../../config/theme';

export const EditableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '$spaceXSmall2',
  borderRadius: '$radius05',
  background: '$monochromeBlack25',
  border: '1px solid $grayscaleLabel',
});

export const Line = styled('div', {
  height: '0.0625rem',
  background: '$monochromeBlack50',
  variants: {
    transparentDesk: {
      true: {
        '@bp5': {
          background: 'transparent',
        },
      },
    },
  },
});

export const QuestionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$spaceLarge',
  variants: {
    recordingModal: {
      true: {
        paddingRight: '1rem',
        '@bp2': {
          '&::-webkit-scrollbar': {
            width: 5,
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '5rem',
            backgroundColor: '$grayscaleInput',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '$blurple',
            borderRadius: '0.563rem',
            border: '5px line solid',
          },
          overflowY: 'auto',
          maxHeight: 200,
          '@tabletLandscape': {
            maxHeight: 400,
          },
        },
        '@bp4': {
          maxHeight: 396,
        },
      },
    },
  },
});

export const QuestionContainer = styled('div', {
  display: 'flex',
  padding: '$spaceXSmall2',
  borderRadius: '$radius05',
  background: '$monochromeBlack25',
  justifyContent: 'space-between',
  width: '100%',
  minWidth: '100%',
  '@bp5': {
    width: '95%',
    minWidth: '350px',
  },
  variants: {
    space: {
      paddingY1: {
        paddingY: '$spaceSmall',
      },
      paddingY2: {
        paddingY: '$spaceXLarge',
      },
    },
    editable: {
      true: {
        border: '1px solid $grayscaleLabel',
      },
    },
  },
});

export const QuestionInputContainer = styled('div', {
  display: 'flex',
  position: 'relative',
});

export const QuestionIconContainer = styled('div', {
  display: 'flex',
  position: 'absolute',
  cursor: 'pointer',
  right: '0.75rem',
  top: '0.75rem',
});

export const QuestionInput = styled('input', {
  width: '100%',
  display: 'flex',
  padding: '$spaceXSmall2',
  borderRadius: '$radius05',
  background: '$monochromeBlack25',
  border: '1px solid $grayscaleLabel',
  fontFamily: '$poppins',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing6',
  color: '$monochromeBlack400',
});

export const TitleInput = styled('input', {
  all: 'unset',
  fontFamily: '$poppins',
  fontSize: '$displayXSmall',
  fontWeight: '$weight700',
  color: '$monochromeBlack',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing3',
  width: '100%',
  '&:focus': {
    outline: 'none',
    border: 'none',
  },
});

export const IconContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$secondaryP3',
  borderRadius: '$radius05',
  height: '$2_625',
  width: '$2_625',
  cursor: 'pointer',
  '&:hover': {
    background: '#A4D41B',
  },
  variants: {
    kind: {
      topic: {
        background: '$blurple',
        borderRadius: '$radius03',
        height: 30,
        minWidth: '$1_75',
        '&:hover': {
          background: ' #4160D0',
        },
      },
      add: {
        borderRadius: '$radius03',
        height: '$1_75',
        width: '$1_75',
        svg: {
          height: 24,
          width: 24,
        },
      },
      addMembers: {
        borderRadius: '$radius03',
        svg: {
          height: '$1_75',
          width: '$1_75',
        },
      },
      deleteIcon: {
        background: 'transparent',
        height: 'auto',
        width: 'auto',
        alignItems: 'start',
        marginLeft: '$spaceXSmall',
        '&:hover': {
          background: 'transparent',
          stroke: '#A4D41B',
        },
      },
      trashIcon: {
        background: 'transparent',
        height: '20px',
        width: '20px',
        alignItems: 'start',
        marginLeft: '$spaceXSmall',
        borderRadius: '$radius03',

        '&:hover': {
          background: '#D7F065',
        },
      },
    },
  },
});

export const InviteMemberContainer = styled('div', {
  display: 'inline-flex',
  gap: '$spaceXXSmall',
  padding: '$spaceXXSmall $spacePadding1_38',
  alignContent: 'center',
  justifyContent: 'center',
  background: '$secondaryP3',
  borderRadius: '$radius05',
  marginTop: '$spaceLarge',
});

export const TextAreaDescription = styled('textarea', {
  all: 'unset',
  overflow: 'hidden',
  fontSize: '$bodyTextXSmall',
  lineHeight: '$lineHeight11',
  letterSpacing: '$letterSpacing8',
  marginY: '$spaceLarge',
});

export const OpinionInput = styled('input', {
  width: '3.125rem',
  textAlign: 'center',
  padding: '$spaceXXSmall $spaceXSmall3',
  background: '$monochromeBlack25',
  borderRadius: '$radius05',
  fontFamily: '$poppins',
  fontSize: '$bodyXSmall',
  color: '$monochromeBlack',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  transition: 'all 0.3s ease-in-out',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    display: 'none',
  },

  variants: {
    isEmpty: {
      true: {
        border: '1px solid #DA7272',
      },
      false: {
        border: '1px solid $monochromeBlack25',
      },
    },
  },
});

export const LabelContainer = styled('div', {
  textAlign: 'center',
  padding: '$spaceXXSmall $spaceXSmall3',
  background: '$monochromeBlack25',
  borderRadius: '$radius05',
  border: '1px solid $error',
  fontSize: '$bodyXSmall',
  color: '$monochromeBlack200',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  transition: 'all 0.3s ease-in-out',
  variants: {
    editable: {
      true: {
        color: '$monochromeBlack',
        border: 'none',
      },
    },
    isEmpty: {
      true: {
        border: '1px solid #DA7272',
      },
      false: {
        border: '1px solid $monochromeBlack25',
      },
    },
  },
});

export const StyledResizableTextArea = styled('textarea', {
  all: 'unset',
  width: '100%',
  overflow: 'hidden',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight11',
  letterSpacing: '$letterSpacing8',
  color: '$monochromeBlack400',
  variants: {
    isTitle: {
      true: {
        all: 'unset',
        fontFamily: '$poppins',
        fontSize: '$displayXSmall',
        fontWeight: '$weight700',
        color: '$monochromeBlack',
        lineHeight: '$lineHeight3',
        letterSpacing: '$letterSpacing3',
        width: '100%',
        marginBottom: '$spaceLarge',
      },
    },
  },
});

export const ReadMoreButton = styled('div', {
  display: 'flex',
  gap: '$spaceXXSmall',
  alignItems: 'center',
  color: '$blurple',
  fontFamily: '$poppins',
  fontSize: '$bodyXSmall',
  fontWeight: '$weight600',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  '& svg': {
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover': {
    color: '$hoverBtn',
  },
  '&:hover svg': {
    fill: '$hoverBtn',
  },
  variants: {
    isExpanded: {
      true: {
        '& svg': {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
});
