import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { Outlet } from 'react-router-dom';
import { styled, fadeIn } from '../../../config/theme';
import { BillingHistory } from './BillingHistory';

export const Tabs = styled(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxShadow: `0 2px 10px '$blue'`,
  maxWidth: 1700,
  margin: 'auto',
  paddingBottom: '5rem',
  '@bp2': {
    paddingBottom: '2rem',
  },

});

export const TabsList = styled(TabsPrimitive.List, {
  flexShrink: 0,
  display: 'flex',
  borderBottom: `1px solid '$blue'`,
  marginY: '$spaceLarge',
  width: '100%',
  paddingBottom: '0',
  flexDirection: 'row',
  '@bp2': {
    flexDirection: 'row',
  },
});
export const TabsTrigger = styled(TabsPrimitive.Trigger, {
  all: 'unset',
  color: '$label',
  padding: '0 5px',
  height: 45,
  flex: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$bodyCard',
  letterSpacing: '0.25px',
  fontWeight: '$weight500',
  userSelect: 'none',
  borderBottom: '1px solid $colors$line',
  cursor: 'pointer',
  '&:first-child': { borderTopLeftRadius: 6, borderBottom: 0 },
  '&:last-child': { borderTopRightRadius: 6, borderBottom: 0 },
  '&[data-state="active"]': {
    color: '$body',
    boxShadow: '$active',
  },
  '&:focus': { position: 'relative' },
  '@bp2': {
    whiteSpace: 'nowrap',
    fontWeight: '$weight600',
    boxShadow: '$noActive',
    borderBottom: 'none',
    '&[data-state="active"]': {
      display: 'flex',
    },
  },
  '@bp3': {
    flex: 1,
    fontSize: '$bodySmall',
    letterSpacing: '$letterSpacing3',
  },
});
export const TabsContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  outline: 'none',
  animation: `${fadeIn} 340ms`,
});

const dataTabs = [
  { key: 'credits', name: 'Credits' },
  { key: 'history', name: 'Billing History' },
];

const TabsListContainer = () => (
  <TabsList aria-label="User settings">
    {dataTabs.map((tab) => (
      <TabsTrigger key={tab?.key} value={tab.key}>
        {tab.name}
      </TabsTrigger>
    ))}
  </TabsList>
);

const TabsComponent = () => (
  <Tabs defaultValue="credits" orientation="vertical">
    <TabsListContainer />
    <TabsContent value="credits">
      <Outlet />
    </TabsContent>
    <TabsContent value="history">
      <BillingHistory />
    </TabsContent>
  </Tabs>
);
export default TabsComponent;
