import { styled } from '../../../config/theme';

export const BoxContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '2.5rem'
});

export const TextTitle = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight3',
  alignSelf: 'baseline',
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  justifyContent: 'center',
  '& img': {
    margin: 0
  }
});

export const BoxText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '1rem'
});

export const ErrorText = styled('p', {
  color: '$red',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodyXSmall !important',
});
