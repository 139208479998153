import { styled } from '../../../../config/theme';

export const ProjectInformationContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$spaceLarge',
  width: '100%',
  maxWidth: '550px',
});

export const ProjectOpinionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$spaceLarge',
  minWidth: '100%',
  '@bp6_6': {
    maxWidth: '620px',
    minWidth: '620px',
  },
});

export const OpinionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '0.7rem',
  alignItems: 'flex-start',
  gap: '$spaceXLarge',
  borderRadius: '$radius05',
  background: '$monochromeBlack25',
  '@bp0': {
    padding: '$spaceXLarge',
  },
});

export const VideoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.625rem',
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: 0,
});

export const VideoContainerEmpty = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.625rem',
  flex: '1 0 0',
  alignSelf: 'stretch',
  borderRadius: '$radius05',
  border: '1px dashed $placeHold',
  background: '$input',
  paddingY: '$spaceMedium2',
  paddingX: '$spaceMedium',
});

export const TextAreaOpinion = styled('textarea', {
  width: '100%',
  borderRadius: '$radius05',
  backgroundColor: '$monochromeWhite',
  color: '$monochromeBlack400',
  padding: '$spaceXSmall2',
  border: 'none',
  fontFamily: '$poppins',
  fontSize: '$bodyXSmall',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'lineHeight1',
  letterSpacing: '0.033rem',
});

export const OpinionContainerArea = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$spaceXSmall2',
});

export const QuestionsContainer = styled('div', {
  width: '100%',
  maxWidth: '550px',
  '@bp6': {
    minWidth: '550px',
  },
});

export const OpinionButtonOption = styled('button', {
  padding: '10px 20px',
  borderRadius: '$radius03',
  backgroundColor: 'transparent',
  fontFamily: '$poppins',
  fontSize: '$bodyXSmall',
  fontWeight: '$weight600',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '.75rem',
  transition: 'all 0.3s ease',
  width: '100%',
  height: '2.625rem',
  '& svg': {
    transition: 'all 0.3s ease',
  },
  variants: {
    color: {
      body: {
        color: '$body',
        border: '2px solid $body',
        '& svg': {
          stroke: '$body',
        },
        '&:hover': {
          backgroundColor: '$body',
          color: 'white',
          '& svg': {
            stroke: 'white',
          },
        },
      },
      delete: {
        color: '$redGoogle',
        border: '2px solid $redGoogle',
        '& svg': {
          stroke: '$redGoogle',
        },
        '&:hover': {
          backgroundColor: '$redGoogle',
          color: 'white',
          '& svg': {
            stroke: 'white',
          },
        },
      },
    },
  },
});
