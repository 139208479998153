import React, { useRef, useState } from 'react';
import { VideoContainer, VideoStyled } from './styled';
import { BreathingComponents } from '../ShimmerComponent/styles';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { keyExtractor } from '../../utils/helper';

interface Props {
  id: string;
  videoRef?: React.RefObject<HTMLVideoElement>;
  videoWidth?: number | string;
  videoHeight?: number | string;
  // eslint-disable-next-line no-unused-vars
  onCheckVideoStatus?: (status: boolean) => void;
  src?: string;
  isLandscape?: boolean;
}

const Video = ({
  videoWidth,
  videoHeight,
  videoRef,
  id,
  src,
  isLandscape,
  onCheckVideoStatus,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const videoInternalRef = useRef<HTMLVideoElement | null>(videoRef ? videoRef.current : null);
  const { isMobile, isTablet } = useDeviceSize();
  let defaultVideoHeight = 380;

  if (isMobile) {
    defaultVideoHeight = 320;
  } else if (isTablet) {
    defaultVideoHeight = 400;
  }

  const handleVideoLoaded = () => {
    setIsLoaded(true);
    if (onCheckVideoStatus) onCheckVideoStatus(true);
    if (videoInternalRef.current) videoInternalRef.current.currentTime = 0;
  };

  return (
    <VideoContainer css={{ height: videoHeight || defaultVideoHeight }}>
      <VideoStyled
        id={id}
        css={{
          width: videoWidth,
          height: (videoHeight as number) || defaultVideoHeight,
          display: isLoaded ? 'block' : 'none',
        }}
        ref={videoInternalRef}
        src={src}
        controls
        onLoadedMetadata={handleVideoLoaded}
        isLandscape={isLandscape}
        preload='metadata'
        playsInline
      >
        <track kind="captions" srcLang="en" label="English" />
      </VideoStyled>
      {!isLoaded && (
        <BreathingComponents
          key={keyExtractor()}
          className="tab"
          height={(videoHeight as number) || defaultVideoHeight}
        />
      )}
    </VideoContainer>
  );
};

Video.defaultProps = {
  videoWidth: undefined,
  videoHeight: undefined,
  videoRef: null,
  src: undefined,
  onCheckVideoStatus: undefined,
  isLandscape: false,
};

export default Video;
