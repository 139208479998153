import { Link } from 'react-router-dom';
import { styled } from '../../../../config/theme';

export const HowToGetContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$spaceMedium',
  alignItems: 'center',
  justifyContent: 'center',
  '@bp2': {
    paddingX: '$spaceMBig',
  },
  '@bp4': {
    flexDirection: 'row',
    paddingX: '$spaceMedium',
  },
});

export const Subtitle = styled('h3', {
  padding: '0 0.3125rem',
  color: '$secondaryP3',
  textAlign: 'center',
  fontFamily: '$poppins',
  fontSize: '$displaySmall',
  fontStyle: 'normal',
  fontWeight: '$normal',
  lineHeight: 'normal',
  letterSpacing: '$letterSpacing3',
  span: {
    fontWeight: '$weight700',
  },
});

export const HowToGetStepsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  paddingX: '$spaceLarge',
  gap: '$spaceMedium',
  maxWidth: '550px',
  order: 1,
  "@bp3": {
    order: 2,
    width: '100%',
    maxWidth: '650px'
  }
});
export const HowToGetStepsSubContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$spaceSmall',

});
export const HowToGetImgContainer = styled('div', {
  display: 'flex',
  order: 2,
  padding: '$spaceSmall',
  "@bp3": {
    order: 1,
    width:'50%'
  }
});

export const HowToGetImg = styled('img', {
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  '@bp3': {
  },
  variants: {
    shadow: {
      opinion: {
        boxShadow: '0px 0px 72px 0px #00000014',
        borderRadius: '$radius05',
      },
    },
    dimensions: {
      maxWidth: {
        maxWidth: 650,
      }
    }
  },
});

export const StyledLink = styled(Link, {
  color: '$link',
  textDecoration: 'none'
});
