import React from 'react';
import { WrapperTextLimiterLink } from './styles';

interface TextLimiterLinkModel {
  text: string | undefined;
  link: string | undefined;
  title: string | undefined;
}
const LIMIT_TEXT = 200;

// eslint-disable-next-line no-unused-vars
const TextLimiterLink: React.FunctionComponent<TextLimiterLinkModel> = ({ text, link, title }) => {
  const textLength = text ? text.length : 0;
  const textShow = textLength > LIMIT_TEXT ? text?.slice(0, LIMIT_TEXT) : text;

  return (
    <WrapperTextLimiterLink>
      <p>
        {textShow}...
        {/* <a href={link} rel='noreferrer' target='_blank' 
        aria-label={`Read More about ${title}`} >Read more</a> */}
      </p>
    </WrapperTextLimiterLink>
  );
};

export default TextLimiterLink;
