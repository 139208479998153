import { styled } from '../../../config/theme';
import deskImage from '../../../assets/images/experts/ExpertInvitation1.png';

export const InvitationContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'auto',
  '@bp4': {
    flexDirection: 'row',
  },
});

export const LetterContainer = styled('div', {
  width: '100%',
  height: '100%',
  paddingX: '$spaceLarge',
  paddingTop: 'calc($spaceMedium * 1.7)',
  paddingBottom: '$spaceMedium',
  background: '$monochromeWhite',
  '@bp2': {
    paddingTop: 'calc($spaceMedium * 2)',
    paddingBottom: 'calc($spaceMedium * 2)',
    paddingX: '$spaceMedium',
  },
  '@bp4': {
    width: '50%',
    maxHeight: '100vh',
    overflowY: 'auto',
    padding: '$spaceMedium',
    paddingTop: 'calc($spaceMedium * 2.2)',
    paddingBottom: 'calc($spaceMedium * 2)',
    paddingRight: 'calc($spaceMedium * 2.2)',
    '&::-webkit-scrollbar': {
      width: 1,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
  },
});

export const LetterSubContainer = styled('div', {
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});

export const ExpertInvitationImageContainer = styled('div', {
  width: '100%',
  backgroundImage: `url(${deskImage})`,
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  padding: 'calc($spaceLarge * 2)',
  alignItems: 'center',
  justifyContent: 'start',
  '@bp2': {
    paddingTop: 'calc($spaceLarge * 2.2)',
  },
  '@bp4': {
    width: '50%',
    // paddingRight: 'calc($spaceMedium * 2.2)',
    justifyContent: 'center',
    paddingTop: 'calc($spaceMedium * 2.2 - 30px)',
    maxHeight: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 1,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
  },
});

export const BoxContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginY: '$spaceGap1_56',
});

export const TextDescriptionDecoration = styled('div', {
  padding: '$spaceXSmall2',
  borderRadius: '$radius05',
  background: '$monochromeWhite25',
});

export const TextOverlay = styled('div', {
  width: '80%',
  color: '#fff',
  fontSize: '2rem',
  borderRadius: '$1',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const UnderneathText = styled('h4', {
  fontSize: '$bodyTextXSmall',
  fontWeight: '$normal',
  lineHeight: '$lineHeight10',
  color: '$grayscaleLabel',
});

export const QuestionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
});
