import { styled } from '../../../../config/theme';

export const TimerContainer = styled('p', {
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '30px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '$monochromeWhite',
});
