import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetToken from './useGetToken';
import { TagService } from '../../services/TagService/TagService';
import TagContext from '../context/tag/TagContext';
import { ExpertiseModel, StatusTag, TagModel, TagModelSave } from '../interfaces/interfaces';
import SelectedTagContext from '../context/tag/SelectedTagsContext';
import AppContext from '../context/app/AppContext';

export const useTag = () => {
  const { tags, setTags } = useContext(TagContext);
  const { selectedTags, setSelectedTags } = useContext(SelectedTagContext);
  const token = useGetToken();
  const { state, dispatch } = useContext(AppContext);
  const { filterContent } = state as any;
  const navigate = useNavigate();

  const saveTagTopic = async (item: TagModelSave) => {
    const expertise = await TagService.saveTagTopic(token, item);
    return expertise;
  };

  const saveExpertise = async (item: TagModel, userId: number) => {
    const expertise = await TagService.saveExpertise(token, userId, item.id);
    dispatch({ type: 'SET_EXPERTISES', payload: expertise });
    return expertise.map((expertiseItem: any) => ({
      ...expertiseItem?.attributes?.tag?.data,
      expertise_id: expertiseItem?.id,
    }));
  };

  const deleteExpertise = async (item: TagModel | ExpertiseModel, userId: number) => {
    const expertiseId = 'expertise_id' in item ? item.expertise_id : item.id;
    const response = await TagService.deleteExpertise(token, userId, expertiseId);
    dispatch({ type: 'SET_EXPERTISES', payload: response });
  };

  const saveTagContent = async (item: TagModel, contentId: string) => {
    const tagContents = await TagService.saveTagContent(token, contentId, item.id);
    return tagContents.map((tagContent: any) => ({
      ...tagContent?.attributes?.tag?.data,
      contenttaglinks_id: tagContent?.id,
    }));
  };

  const deleteTagContent = async (item: TagModel, contentId: string) => {
    await TagService.deleteTagContent(token, contentId, item.contenttaglinks_id);
  };

  const addSelectedTag = (item: TagModel) => {
    setSelectedTags([...selectedTags, item]);
  };

  const deleteSelectedTag = () => {
    selectedTags.pop();
    setSelectedTags([...selectedTags]);
  };

  const isSelectedTag = (item: TagModel) =>
    selectedTags
      ? !!selectedTags.filter((selectedTag) => selectedTag?.id === item?.id).length
      : false;

  const resetSelected = (item?: TagModel) => {
    if (item) {
      setSelectedTags([item]);
    } else {
      setSelectedTags([]);
    }
  };

  const changeSelectedTag = (item: TagModel) => {
    if (selectedTags.length === 2) {
      setSelectedTags([selectedTags[0], item]);
    } else {
      addSelectedTag(item);
    }
  };
  const compare = (a: TagModel, b: TagModel) => {
    if (a.attributes.name && b?.attributes?.name && a.attributes.name < b.attributes.name) {
      return -1;
    }
    if (a?.attributes.name && b?.attributes?.name && a.attributes.name > b.attributes.name) {
      return 1;
    }
    return 0;
  };

  const orderUsedTags = (updatedTopics: any, sameUser: Boolean | undefined, item?: TagModel) => {
    // TODO: change this when backend part will add the logical to get if is approved
    const approved = updatedTopics
      .filter((topic: any) => topic.attributes.status === StatusTag.Approved)
      .sort(compare);
    // TODO: for the moment added only different no approved
    // because in the future may be want to use the other states.
    const notApproved = sameUser
      ? updatedTopics
          .filter((topic: any) => topic.attributes.status !== StatusTag.Approved)
          .sort(compare)
      : [];
    if (item) {
      return [...approved, item, ...notApproved];
    }
    return [...approved, ...notApproved];
  };

  const filterDuplicated = (topics: any, sameUser: Boolean | undefined) => {
    const uniqueIds: any[] = [];
    return orderUsedTags(
      topics.filter((topic: any) => {
        const isDuplicate = uniqueIds.includes(topic.id);

        if (!isDuplicate) {
          uniqueIds.push(topic.id);

          return true;
        }

        return false;
      }),
      sameUser,
    );
  };

  const handleTag = (tag: string | TagModel) => {
    if (tag) {
      dispatch({
        type: 'SET_FILTER',
        payload: { ...filterContent, filter: tag, category: 'tag', page: 1, change: true },
      });

      navigate('/');
    }
  };

  const getTagById = async (id: string | undefined) => {
    const response = await TagService.getTagById(token, id);
    return response;
  };

  const getTagPaged = (page: number, perPage: number, name?: string) =>
    TagService.getTags(token, page, perPage, name);

  const fetchTag = async () => {
    const newTags = await TagService.getTags(token);
    setTags(newTags);
  };

  const getTagByName = async (name: string) => {
    const response = await TagService.getTagsByName(name);
    return response;
  };

  return {
    tags,
    saveTagTopic,
    saveExpertise,
    getTagById,
    getTagPaged,
    deleteExpertise,
    saveTagContent,
    deleteTagContent,
    selectedTags,
    addSelectedTag,
    isSelectedTag,
    resetSelected,
    changeSelectedTag,
    deleteSelectedTag,
    orderUsedTags,
    filterDuplicated,
    handleTag,
    fetchTag,
    getTagByName,
  };
};
