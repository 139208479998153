import React from 'react';
import CardProviderLoad from './CardProviderLoad';
import { WrapperCardList } from '../CardResources/styles';
import { LayoutContainer, LayoutCol } from '../Layouts/styles';
import { keyExtractor } from '../../utils/helper';


const ProvidersListLoad: React.FC<any> = ({ total }) => {
  const itemsTotal = total || 1;
  const arrayData = Array(itemsTotal).fill('items');

  return (
    <WrapperCardList className='people-list'>
      <LayoutContainer sub noPadding>
        {arrayData && arrayData?.map(() => (
          <LayoutCol
            key={keyExtractor()}
            col='4'
            css={{
              flexGrow: 1,
              '@bp3_5': { justifyContent: 'initial' },
              '@bp5': { flexBasis: '30.33%' } }}
          >
            <CardProviderLoad />
          </LayoutCol>
        ))}
      </LayoutContainer>
    </WrapperCardList>
  );
};

export default ProvidersListLoad;
