import { Breathing } from 'react-shimmer';
import { styled } from '../../../config/theme';
import { CardContent } from '../CardResources/styles';

export const BreathingComponents = styled(Breathing, {
  transition: 'all 0.9s ease-in-out',
  '&.circle': {
    borderRadius: '50%',
  },
  '&.tab': {
    borderRadius: '10px',
  },
  variants: {
    isExpertCard: {
      true: {
        maxWidth: '15rem',
      },
    },
    fullWidth: {
      true: {
        width: '100%',
        minWidth: '100%',
      },
    },
    fullHeight: {
      true: {
        minHeight: '100%',
      },
    },
  },
});

export const WrapperShimmer = styled('div', {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  gap: '.5rem',
});

export const WrapperShimmerUser = styled(WrapperShimmer, {
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  marginLeft: '0',
  '@bp3': {
    marginLeft: '1rem',
  },
});

export const WrapperShimmerUserContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

export const CardContentShimmer = styled(CardContent, {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  minHeight: '4rem',
  marginBottom: '0',
  '& .shimmer-text': {
    marginBottom: '0px',
    '@bp3': {
      marginBottom: '$spaceXSmall',
    },
  },
  '@bp3': {
    minHeight: '5.5rem',
    marginBottom: '1.5rem',
  },
});

export const WrapperAuthor = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '$spaceLarge',
  flexWrap: 'wrap',
  '&:last-child': {
    marginBottom: 0,
  },
});

export const TitleShimmer = styled('div', {
  width: '100%',
  marginBottom: '$spaceLarge',
  flexWrap: 'wrap',
});

export const BoxShimmer = styled('div', {
  marginTop: '$spaceSmall',
  width: '100%',
  variants: {
    type: {
      center: {
        '.spinner': {
          margin: '0 auto',
        },
      },
    },
  },
});

export const WrapperImageLoad = styled('div', {
  width: '100%',
});

export const BoxImgShimmer = styled(Breathing, {
  width: '100%',
  height: '100%',
  variants: {
    hide: {
      true: {
        width: 0,
      },
    },
  },
});
