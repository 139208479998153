import React, { useCallback } from 'react';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
import PageContainer from '../../shared/components/Container/PageContainer';
import LoadDataSection from '../../shared/components/LoadDataSection/LoadDataSection';
import ProvidersListLoad from '../../shared/components/ShimmerComponent/ProvidersListLoad';
import ResourcesListLoad from '../../shared/components/ShimmerComponent/ResourcesListLoad';
// import TagsLoad from '../../shared/components/ShimmerComponent/TagsLoad'; // Hide V1
// import Tags from '../../shared/components/Tags/Tags'; // Hide V1
import { HomeService } from '../../services/home.service';

import { BoxInfo, BoxCard, TextDiscover } from './styled';
import { BoxShimmer } from '../../shared/components/ShimmerComponent/styles';
import CardResourcesList from '../../shared/components/CardResources/CardResourcesList';
import CardPeopleList from '../../shared/components/CardPeople/CardPeopleList';
import MetaTags from '../../shared/components/MetaTags/MetaTags';

const Discover = (props: any) => {
  const { token } = props;

  const actionPeople = useCallback(async (
    page: number, total: number) => {
    const resultPeople = await HomeService.getAuthors({
      authenticationToken: token, page, limit: total, whoToFollow: true
    });
    return resultPeople;
  }, [token]);

  const actionResources = useCallback(async (
    page: number,
    total: number,
    filterBy?: string,
    filter?: string
  ) => {
    const resultResources = await HomeService.getContents({
      authenticationToken: token, page, limit: total, filterBy, filter
    });
    return resultResources;
  }, [token]);

  // const actionTopics = useCallback(async (
  //   page: number,
  //   total: number
  // ) => {
  //   const resultTopics = await HomeService.getTags({
  //     authenticationToken: token, page, limit: total
  //   });
  //   return resultTopics;
  // }, [token]);

  return (
    <PageContainer layout='discover'>
      <MetaTags
        title='Discover Interesting People and Content | Gathersight'
        description="Discover thinkers, writers, podcasters and creators sharing new knowledge. Dig for fascinating new articles, essays and expertise on Gathersight."
        canonical='https://www.gathersight.com/discover/' />
      <LayoutContainer type='discover' css={{ flexWrap: 'wrap' }}>
        <LayoutCol col='12' css={{ flexGrow: 1, flexDirection: 'column' }}>
          <BoxInfo>
            <TextDiscover>People</TextDiscover>
          </BoxInfo>
          <BoxCard>
            <LoadDataSection
              onAction={actionPeople}
              component={CardPeopleList}
              shimmerComponent={<ProvidersListLoad total={6} />}
              shimmerInfinity={(<BoxShimmer><ProvidersListLoad total={2} /></BoxShimmer>)}
              propsComponent={{ simpleGap: true }}
              typeButton="outline"
              isProviders
              size={6}
              withModal
              title='People'
              description=''
              kindModal='infinity-big'
            />
          </BoxCard>
          {/* <BoxInfo css={{ marginTop: '$spaceLarge' }} >
            <TextDiscover>Topics</TextDiscover>
          </BoxInfo>
          <BoxCard>
            <LoadDataSection
              onAction={actionTopics}
              component={Tags}
              propsComponent={{ kind: '' }}
              shimmerComponent={<TagsLoad total={20} />}
              shimmerInfinity={(<BoxShimmer><TagsLoad total={10} /></BoxShimmer>)}
              typeButton="outline"
              size={20}
              withModal
              title='Topics'
              description=''
              kindModal='infinity-big'
            />
          </BoxCard> */}
          <BoxInfo css={{ marginTop: '$spaceLarge' }} >
            <TextDiscover>Long-Form Content</TextDiscover>
          </BoxInfo>
          <BoxCard>
            <LoadDataSection
              onAction={actionResources}
              component={CardResourcesList}
              shimmerComponent={<ResourcesListLoad total={4} />}
              shimmerInfinity={(<BoxShimmer><ResourcesListLoad total={2} /></BoxShimmer>)}
              typeButton="outline"
              size={8}
              withModal
              title='Long-Form Content'
              description=''
              kindModal='infinity-big'
              filterByProp="discover"
              filterProp="discover"
            />
          </BoxCard>
        </LayoutCol>
      </LayoutContainer>
    </PageContainer>
  );
};

export default Discover;
