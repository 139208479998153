import { Field, Form } from 'formik';
import { styled } from '../../../config/theme';

export const BoxContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '2.5rem',
});

export const TextTitle = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight3',
  alignSelf: 'baseline',
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  justifyContent: 'space-between',
  '& img': {
    margin: 0,
  },
});

export const BoxText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '1rem',
});

export const InputWrapper = styled('div', {
  width: '100%',
  textAlign: 'left',
  position: 'relative',
  flexDirection: 'column',
  '> div': {
    paddingLeft: '1rem',
    position: 'absolute',
    bottom: 0,
  },
});

export const TextAreaWrapper = styled('div', {
  background: 'transparent',
  width: '100%',
  minHeight: '3.5rem',
  display: 'flex',
  borderRadius: '$radius05',
  alignItems: 'center',
  position: 'relative',
  border: '1px solid $line',
  marginBottom: '$spaceSmall',
});

export const Input = styled('input', {
  background: 'transparent',
  width: '100%',
  border: 'none',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  padding: '$spaceSmall',
});

export const Textarea = styled('textarea', {
  fontFamily: 'Poppins',
  background: 'transparent',
  width: '100%',
  border: 'none',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  padding: '$spaceSmall',
  variants: {
    styleType: {
      steps: {
        borderRadius: '$radius05',
        border: '1px solid $line',
        marginY: '$spaceSmall',
        fontFamily: '$poppins',
        '&:focus': {
          border: '1px $blurple solid',
        },
      },
    },
  },
});

export const ErrorText = styled('p', {
  color: '$red',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodyXSmall !important',
});

export const FormStyled = styled(Form, {
  width: '100%',
});

export const FormikInput = styled(Field, {
  fontFamily: 'Poppins',
  background: 'transparent',
  width: '100%',
  minHeight: '3.5rem',
  borderRadius: '$radius05',
  border: '1px solid $line',
  padding: '$spaceXSmall $spaceSmall',
  marginBottom: '1.5rem',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
});
