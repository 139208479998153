import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OpinionContainer } from './styled';
import VideoRecordingContext from '../../../../shared/context/videoRecording/videoRecordingContext';
import OpinionEmpty from './OpinionEmpty';
import OpinionRecorded from './OpinionRecorded';
import ProjectsContext from '../../../../shared/context/projects/ProjectsContext';
import useGetToken from '../../../../shared/hooks/useGetToken';
import { OpinionsService } from '../../../../services/OpinionsService/OpinionsService';

interface Props {
  userId?: string;
}

const Opinion = ({ userId }: Props) => {
  const { setProjectOpinion, projectOpinion } = useContext(VideoRecordingContext);
  const { project } = useContext(ProjectsContext);
  const authenticationToken = useGetToken();
  const [videoUrl, setVideoUrl] = React.useState<string | undefined>(undefined);
  const { slug } = useParams();

  useEffect(() => {
    setVideoUrl(projectOpinion?.attributes?.video_url);
  }, [projectOpinion]);

  useEffect(() => {
    const searchOpinion = async () => {
      if (!project) return;

      const data = {
        authenticationToken,
        projectId: project.id,
        userId,
      };

      const response: any = await OpinionsService.userProjectOpinion(data);
      setProjectOpinion(response.data.data);
    };
    if (project?.attributes.slug === slug) searchOpinion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <OpinionContainer>
      {videoUrl ? (
        <OpinionRecorded videoUrl={videoUrl} withStatusLabel={!userId} />
      ) : (
        <OpinionEmpty allowRecording={!userId} />
      )}
    </OpinionContainer>
  );
};

Opinion.defaultProps = {
  userId: undefined,
};

export default Opinion;
