/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Cell, HeaderGroup, Row, useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { expertColumns } from './columns/expertColumns';
import { organizationColumns } from './columns/organizationColumns';
import {
  TableContainer,
  TableBody,
  TableHead,
  ScrollableTable,
  NoContentContainer,
} from './styled';
import Button from '../../Button/Button';
import { FlexContainer, Typography } from '../../../../screens/Organization/styled';
import TrashWite from '../../../../assets/images/icons/trash-wite.svg';
import LockDarkGray from '../../../../assets/images/icons/lock-dark-gray.svg';
import { LoadingCell } from './LoadingCell';

interface TableProps {
  data: any[];
  loadingData: any[];
  type: 'Expert' | 'Organization';
  buttonTitle?: string;
  customColumns?: any[];
  showButton?: boolean;
  // eslint-disable-next-line no-unused-vars
  onDeleteRow?: (item: any) => void;
  emptyMessage?: string;
  isLoading?: boolean;
}

const TableComponent: React.FC<TableProps> = ({
  data,
  loadingData,
  type,
  buttonTitle,
  customColumns,
  showButton,
  onDeleteRow,
  emptyMessage,
  isLoading,
}) => {
  const defaultColumns = type === 'Expert' ? expertColumns : organizationColumns;
  const columns = customColumns || defaultColumns;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  const { rows: loadingRows } = useTable({
    columns,
    data: loadingData,
  });
  const navigate = useNavigate();

  return (
    <ScrollableTable>
      {(rows.length > 0 || isLoading) && (
        <TableContainer {...getTableProps()}>
          <TableHead isEmpty={rows.length <= 0}>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup<any>) => (
                  <th {...column.getHeaderProps()} style={{ minWidth: column.minWidth }}>
                    {column.render('Header')}
                  </th>
                ))}
                {showButton && <th />}
              </tr>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {isLoading
              ? loadingRows.map((row: Row<any>) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: Cell) => (
                        <td {...cell.getCellProps()} style={{ minWidth: cell.column.minWidth }}>
                          <LoadingCell id={cell.column.id} />
                        </td>
                      ))}
                      {showButton && (
                        <td>
                          <FlexContainer justify="start" gap="0_5">
                            {onDeleteRow && type === 'Organization' ? (
                              <LoadingCell id="button" />
                            ) : (
                              <LoadingCell id="otherButton" />
                            )}

                            {type === 'Organization' && buttonTitle !== 'View' && (
                              <LoadingCell id="delButton" />
                            )}
                          </FlexContainer>
                        </td>
                      )}
                    </tr>
                  );
                })
              : rows.map((row: Row<any>) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: Cell) => (
                        <td {...cell.getCellProps()} style={{ minWidth: cell.column.minWidth }}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                      {showButton && buttonTitle && (
                        <td>
                          <FlexContainer justify="start" gap="0_5">
                            <Button
                              kind="add-dashboards-blue"
                              title={buttonTitle}
                              size="small"
                              width="auto"
                              margin={0}
                              handleClick={() =>
                                navigate(
                                  type === 'Expert'
                                    ? `/experts/projects/${row.original.slug}`
                                    : `/organization/projects/${row.original.slug}`,
                                )
                              }
                            />
                            {row.original.status === 'draft' &&
                              onDeleteRow &&
                              type === 'Organization' && (
                                <Button
                                  kind="delete"
                                  iconMargin0
                                  title=""
                                  iconCustom={TrashWite}
                                  size="small"
                                  width="auto"
                                  margin={0}
                                  handleClick={() => onDeleteRow(row.original)}
                                />
                              )}
                            {row.original.status !== 'draft' &&
                              type === 'Organization' &&
                              buttonTitle !== 'View' && (
                                <Button
                                  kind="blocked"
                                  iconMargin0
                                  title=""
                                  iconCustom={LockDarkGray}
                                  size="small"
                                  width="auto"
                                  margin={0}
                                  renderProps={{ disabled: true }}
                                />
                              )}
                          </FlexContainer>
                        </td>
                      )}
                    </tr>
                  );
                })}
          </TableBody>
        </TableContainer>
      )}
      {rows.length <= 0 && !isLoading && (
        <NoContentContainer>
          <Typography size="bodyXSmall" color="monochromeBlack300" weight="500">
            {emptyMessage}
          </Typography>
        </NoContentContainer>
      )}
    </ScrollableTable>
  );
};

export default TableComponent;

TableComponent.defaultProps = {
  customColumns: [],
  buttonTitle: undefined,
  showButton: true,
  onDeleteRow: undefined,
  emptyMessage: 'No data to show.',
  isLoading: false,
};
