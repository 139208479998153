import React from 'react';
import CardPlaylistLoad from './CardPlaylistLoad';
import { WrapperCardList } from '../CardResources/styles';
import { LayoutContainer, LayoutCol } from '../Layouts/styles';
import { keyExtractor } from '../../utils/helper';


const PlaylistsLoad: React.FC<any> = ({ total }) => {
  const itemsTotal = total || 1;
  const arrayData = Array(itemsTotal).fill('items');

  return (
    <WrapperCardList>
      <LayoutContainer sub noPadding css={{ gap: '$spaceLarge' }}>
        {arrayData && arrayData?.map(() => (
          <LayoutCol
            key={keyExtractor()}
            col='4'
            css={{
              flexGrow: 1,
              '@bp3': { justifyContent: 'initial' },
              '@bp5': { flexBasis: '30.33%' },
              '@bp6': { flexBasis: '33.33%' }
            }}
          >
            <CardPlaylistLoad />
          </LayoutCol>
        ))}
      </LayoutContainer>
    </WrapperCardList>
  );
};

export default PlaylistsLoad;
