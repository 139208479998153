import React from 'react';
import PublicProfileForm from './PublicProfileForm/PublicProfileForm';
// import ProfessionalExperience from './ProfessionalExperience/ProfessionalExperience';
import SocialMediaForm from './SocialMediaForm/SocialMediaForm';
// import LanguagesForm from './LanguagesForm/LanguagesForm';
// import TimeZoneProfileForm from './TimeZoneProfileForm/TimeZoneProfileForm';
import Button from '../../../../shared/components/Button/Button';
import { LayoutContainer } from '../../../../shared/components/Layouts/styles';
import { ButtonWrapper, LayoutColForm, PublicProfileContainer } from './styled';
import usePublicProfileForm from './usePublicProfileForm';
import { useUser } from '../../../../shared/hooks/useUser';

const PublicProfile: React.FunctionComponent = () => {
  const { submitForm, publicProfileForm, loadingBtn, socialMediaForm } = usePublicProfileForm();
  const { isExpert } = useUser();
  return (
    <PublicProfileContainer>
      <LayoutContainer
        css={{
          paddingBottom: '0',
          display: 'flex',
          '@bp2': {
            flexDirection: 'column',
          },
          '@bp3': {
            flexDirection: 'row',
          },
        }}
      >
        <LayoutColForm col="6" css={{ padding: '0 1rem' }}>
          <PublicProfileForm form={publicProfileForm} save={loadingBtn} />
          {isExpert && <SocialMediaForm form={socialMediaForm} />}
          {/* <ProfessionalExperience form={professionalExperienceForm} /> */}
        </LayoutColForm>

        {/* <LayoutColForm col="7">
          <LanguagesForm form={languagesForm} />
          <TimeZoneProfileForm form={timezoneForm} />
        </LayoutColForm> */}
      </LayoutContainer>

      <ButtonWrapper isFooter>
        <LayoutContainer
          css={{
            paddingTop: 48,
            paddingBottom: 48,
            '@bp3': {
              gridTemplateColumns: '1fr',
            },
          }}
        >
          <LayoutColForm col="12" css={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Button
              kind="user-profile"
              title="Save"
              loading={loadingBtn}
              renderProps={{
                onClick: submitForm,
                disabled: loadingBtn,
              }}
              css={{ letterSpacing: '$letterSpacing3' }}
            />
          </LayoutColForm>
        </LayoutContainer>
      </ButtonWrapper>
    </PublicProfileContainer>
  );
};

export default PublicProfile;
