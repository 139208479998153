import React from 'react';
import { AddTopicContainer, FlexContainer } from './styles';
import Button from '../Button/Button';

interface AddTagProps {
  value: string;
  cancel: () => void;
  onNewTag: () => void;
  mobile?: boolean;
}

export const AddTag = ({ value, cancel, onNewTag, mobile }: AddTagProps) => (
  <AddTopicContainer>
    {mobile ? (
      <div>
        <FlexContainer align="center" justify="start" fullWidth>
          <span className="add-topic">
            {' '}
            “{value}” does not exist,
            <br />
            Would you like to add this topic?
          </span>
        </FlexContainer>
        <FlexContainer align="center" justify="between" fullWidth css={{ marginTop: '20px' }}>
          <Button
            title="Add"
            kind="addTopicOutline"
            color="blurple"
            handleClick={() => onNewTag()}
          />
          <Button title="Cancel" kind="cancelTopicOutline" color="blurple" handleClick={cancel} />
        </FlexContainer>
      </div>
    ) : (
      <FlexContainer align="center">
        <span className="add-topic">
          {' '}
          “{value}” does not exist,
          <br />
          Would you like to add this topic?
        </span>
        <Button
          css={{ marginLeft: '20px' }}
          title="Add"
          kind="addTopicOutline"
          color="blurple"
          handleClick={() => onNewTag()}
        />
        <Button title="Cancel" kind="cancelTopicOutline" color="blurple" handleClick={cancel} />
      </FlexContainer>
    )}
  </AddTopicContainer>
);

AddTag.defaultProps = {
  mobile: false,
};
