import { createStitches, ScaleValue } from '@stitches/react';

export const { styled, theme, keyframes } = createStitches({
  // New Theme Configuration goes here
  theme: {
    colors: {
      offBlack: 'hsl(240, 37%, 12%, 1)',
      monochromeBlack: 'hsla(240, 18%, 13%, 1)',
      monochromeBlack25: 'hsla(240, 25%, 98%, 1)',
      monochromeBlack50: 'hsla(240, 14%, 93%, 1)',
      monochromeBlack100: 'hsla(240, 15%, 91%, 1)',
      monochromeBlack200: 'hsla(233, 19%, 82%, 1)',
      monochromeBlack300: 'hsla(237, 11%, 61%, 1)',
      monochromeBlack400: 'hsla(236, 14%, 39%, 1)',
      monochromeWhite: 'hsla(0, 0%, 100%, 1)',
      monochromeWhite25: '#f6f7f8',
      grayscaleLine: 'hsla(233, 26%, 88%, 1)',
      grayscaleLabel: 'hsla(235, 14%, 50%, 1)',
      grayscaleOffWhite: 'hsla(0, 0%, 99%, 1)',
      grayscaleInput: 'hsla(233, 30%, 95%, 1)',
      grayscaleInput2: 'hsla(231, 28%, 95%, 1)',
      grayscalePlaceholder: 'hsla(234, 18%, 69%, 1)',
      grayscale: 'hsla(234, 14%, 29%, 1)',
      grayscaleBackground: 'hsla(233, 45%, 98%, 1)',
      grayscaleBody: 'hsla(248, 16%, 35%, 1)',
      grayscaleAsh: 'hsla(249, 23%, 18%, 1)',
      secondLabel: 'hsla(215, 18%, 34%, 1)',
      ash: 'hsl(248, 23%, 17%, 1)',
      // body: 'hsl(248, 16%, 35%, 1)',
      body: 'hsla(227, 75%, 58%, 1)',
      bodyPale: 'hsla(237, 90%, 96%, 1)',
      label: 'rgba(64, 66, 84, 1)',
      labelLight: '#6E7191',
      placeHold: 'hsl(234, 18%, 69%, 1)',
      line: 'hsl(233, 26%, 88%, 1)',
      input: 'hsl(233, 30%, 95%, 1)',
      bg: 'hsl(233, 45%, 98%, 1)',
      offWhite: 'hsl(0, 0%, 99%, 1)',
      green: 'hsl(148,60%,60%)',
      green100: 'hsla(154, 61%, 92%, 1)',
      green800: 'hsla(164, 93%, 17%, 1)',
      red: 'hsl(7,100%,45%)',
      redGoogle: 'hsla(0, 73%, 61%, 1)',
      redDelete: 'hsla(0, 79%, 63%, 1)',
      error: '#DA7272',
      blue: 'hsl(202,89%,53%)',
      purple: 'hsl(263, 91%, 49%, 1)',
      purple100: 'hsla(246, 90%, 96%, 1)',
      purple800: 'hsla(261, 69%, 42%, 1)',
      overlay: 'hsl(248, 23%, 17%, 0.8)',
      hoverBtn: 'hsl(227, 92%, 44%)',
      followingBtn: 'rgba(217, 219, 233, 1)',
      lightColorSpan: 'hsl(235, 14%, 50%, 100)',
      blurple: 'hsla(227, 78%, 60%, 1)',
      blurpleLight: 'hsla(232, 100%, 88%, 1)',
      blurpleDark: 'hsla(227, 61%, 54%, 1)',
      blurpleHover: 'hsla(227, 58%, 65%, 0.1)',
      burplePass: 'hsla(227, 58%, 65%, 1)',
      imageOverlay: 'hsl(244, 47%, 20%, 100)',
      primaryP3: 'hsla(245, 80%, 68%, 1)',
      secondaryP3: '#D7F065',
      link: '#496CE9',
      grey: '#4E4B66',
      red100: 'hsla(0, 84%, 95%, 1)',
      red800: 'hsla(0, 69%, 36%, 1)',
      greenRecording: 'hsla(101, 38%, 51%, 1)',
      yellow100: 'hsla(54, 95%, 85%, 1)',
      yellow800: 'hsla(25, 71%, 26%, 1)',
    },
    backgroundColor: {},
    borderWidths: {},
    borderStyles: {},
    fonts: {
      poppins: 'Poppins',
      inter: 'Inter',
      squarePeg: 'Square Peg',
      chivoMono: 'Chivo Mono',
    },
    fontWeights: {
      normal: 'normal',
      bold: 'bold',
      weight500: '500',
      weight600: '600',
      weight700: '700',
    },
    fontSizes: {
      displayXXSmall: '1rem',
      displayXSmall: '1.5rem',
      displaySmall: '2.25rem',
      displayMedium: '3rem',
      displayLarge: '3.5rem',
      displayHuge: '4rem',
      displayHugeXL: '6rem',
      bodyXXSSmall: '0.5rem',
      bodyXSSmall: '0.7rem',
      bodyTextXSmall: '0.75rem',
      bodyXSmall: '0.813rem',
      bodyXXSmall: '0.6875rem',
      bodyCard: '0.86rem',
      bodyListItem: '0.875rem',
      bodySmall: '0.938rem',
      bodyMedium: '1.125rem',
      bodyLarge: '1.25rem',
      bodyXLarge: '1.875rem',
    },
    letterSpacings: {
      letterSpacing1: '0.016rem',
      letterSpacing2: '0.047rem',
      letterSpacing3: '0.063rem',
      letterSpacing4: '0.009rem',
      letterSpacing5: '0.008rem',
      letterSpacing6: '0.0325rem',
      letterSpacing7: '0.025rem',
      letterSpacing8: '0.03rem',
      letterSpacing9: '0.005rem',
    },
    lineHeights: {
      lineHeight1: '1.375rem',
      lineHeight2: '1.5rem',
      lineHeight3: '2rem',
      lineHeight4: '2.375rem',
      lineHeight5: '3rem',
      lineHeight6: '3.5rem',
      lineHeight7: '4.5rem',
      lineHeight8: '5.5rem',
      lineHeight9: '1.75rem',
      lineHeight10: '1.25rem',
      lineHeight11: '1.3125rem',
      lineHeight12: '0.625rem',
      lineHeight13: '1.875rem',
      lineHeight14: '1.406rem',
      lineHeight15: '2.25rem',
    },
    radii: {
      radius03: '.3rem',
      radius05: '.5rem',
      radius075: '.75rem',
      radius1: '1rem',
      radius4: '4rem',
    },
    shadows: {
      shadowBase: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      noActive: 'inset 0 -1px 0 0 hsl(233, 26%, 88%, 1), 0 1px 0 0 hsl(233, 26%, 88%, 1)',
      active: 'inset 0 -1px 0 0 hsla(227, 78%, 60%, 1), 0 1px 0 0 hsla(227, 78%, 60%, 1)',
      tagBase: '0px 0px 72px 0px rgba(0, 0, 0, 0.08)',
    },
    space: {
      spaceGap0_25: '0.25rem',
      spaceXXXSmall: '0.3rem',
      spaceXXSmall: '0.5rem',
      spaceXSmall: '0.62rem',
      spaceXSmall2: '0.75rem',
      spaceXSmall3: '0.78rem',
      spaceSmall: '1rem',
      spaceMedium: '3.75rem',
      spaceMedium1: '3rem',
      spaceMedium2: '5.62rem',
      spaceMBig: '6.25rem',
      spaceBig: '7.5rem',
      spaceLarge: '1.25rem',
      spaceLarge2: '1.5rem',
      spaceLarge3: '1.75rem',
      spaceXLarge: '2rem',
      spaceXXLarge: '2.5rem',
      spaceNone: '0rem',
      spaceGap2_25: '2.25rem',
      spaceGap1_56: '1.56rem',
      spaceBody2_38: '2.38rem',
      spacePadding1_38: '1.38rem',
    },
    sizes: {
      '0-06': '0.06rem',
      '0-5': '0.5rem',
      1: '1rem',
      '1-12': '1.12rem',
      '1_75': '1.75rem',
      2: '2rem',
      '2_625': '2.625rem',
      3: '3rem',
      4: '4rem',
      '4-25': '4.25rem',
      '6_25': '6.25rem',
      10: '10rem',
      12: '12rem',
      '16-5': '16.5rem',
      18: '18rem',
      20: '20rem',
      24: '24rem',
      32: '32rem',
      '36-5': '36.5rem',
      47: '47rem',
    },
    zIndices: {},
    transitions: {},
  },
  utils: {
    marginX: (value: ScaleValue<'space'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: ScaleValue<'space'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingX: (value: ScaleValue<'space'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: ScaleValue<'space'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
  media: {
    base: '(min-width: 1rem)',
    bp0: '(min-width: 26.25rem)', // breakpoint  mobile
    bp1: '(min-width: 40rem )',
    bp2: '(min-width: 45rem)', // breakpoint tablet
    bp3: '(min-width: 61.25rem)',
    bp3_3: '(min-width: 66rem)',
    bp3_5: '(min-width: 70rem)',
    bp4: '(min-width: 80rem)',
    bp4_2: '(min-width: 82rem)',
    bp5: '(min-width: 90rem)',
    bp5_5: '(min-width: 90.5rem)',
    bp6: '(min-width: 91rem)',
    bp6_6: '(min-width: 96.875rem)',
    bp7: '(min-width: 117.5rem)',
    landscape: '(orientation: landscape)',
    tabletPortrait: '(min-width: 48rem) and (max-width: 64rem) and (orientation: portrait)',
    tabletLandscape: '(min-width: 64rem) and (max-width: 85.375rem) and (orientation: landscape)',
    phonePortrait: '(min-width: 20rem) and (max-width: 30rem) and (orientation: portrait)',
    phoneLandscape:
      '(min-width: 30rem) and (max-width: 60rem) and (orientation: landscape) and (min-height: 20rem) and (max-height: 30rem)',
  },
});

export const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

export const fadeInUp = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(20%)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
});
