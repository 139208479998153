/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserService } from '../../../services/user.service';
import LoadDataSection from '../../../shared/components/LoadDataSection/LoadDataSection';
import CardPeopleList from '../../../shared/components/CardPeople/CardPeopleList';
import AppContext from '../../../shared/context/app/AppContext';
import { BoxShimmer } from '../../../shared/components/ShimmerComponent/styles';
import { Spinner } from '../../../shared/components/Spinner/spinner';

const Contents = () => {
  const { state } = useContext(AppContext);
  const { id } = useParams();
  const { authenticationToken, userData } = state;

  const actionResources = useCallback(async (
    page: number, limit: number) => {      
    const result = await UserService.followers(
      {authenticationToken, page, limit, id: Number(id || userData?.userId) });
    return result;
  }, [authenticationToken]);

  return(
    <LoadDataSection
      onAction={actionResources}
      component={CardPeopleList}
      shimmerInfinity = {(
        <BoxShimmer type='center'>
          <Spinner className='spinner' type='xs' css={{ padding: '$spaceSmall' }} />
        </BoxShimmer>
      )}
      filterByProp={state?.filterContent?.type}
      filterProp={state?.filterContent?.filter}
      typeButton="outline"
      saveData
      isInfinite
      propsComponent={{
        simpleGap: true
      }}
    />
  );
};

export default Contents;