import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { success } from '../../ToastAlerts/alerts';
import Button from '../../Button/Button';
import Tooltip from '../../Tooltip/Tooltip';
import { KnowledgeService } from '../../../../services/knowledge.service';
import { WrapperSaveButton } from '../../SaveButton/styled';
import useGetToken from '../../../hooks/useGetToken';
import AppContext from '../../../context/app/AppContext';
import { getKnowledge } from '../../../utils/helper';

interface AddtoPlaylistButtonModel {
  resourceID: string;
  title: string;
}

const AddtoPlaylistButton: FC<AddtoPlaylistButtonModel> = ({ resourceID, title }) => {
  const authenticationToken = useGetToken();
  const [added, setAdded] = useState<boolean>(false);
  const { playlist_slug: id } = useParams();
  const { state, dispatch } = useContext(AppContext);
  const { newPlaylistItems } = state;
  
  const createKnowledgeItem = useCallback(async () => {
    const params = { authenticationToken, id, filter: 'content', contentId: resourceID, };

    const result = await KnowledgeService.createKnowledgeItem(params,title);
    if (result?.id) {
      const item = getKnowledge([result], result.attributes.kind);
      dispatch({ type: 'SET_PLAYLIST_ITEMS', payload: newPlaylistItems.concat(item) });
      setAdded(true);
      success('Saved!');
    } else {
      toast.error("Error adding resource to playlist, please refresh and try again!");
    }

  }, [authenticationToken, id, resourceID, newPlaylistItems, title, dispatch]);

  useEffect(() => () => {
    setAdded(false);
  }, []);

  return (
    <WrapperSaveButton>
      <Tooltip content='Save to Knowledge Playlist' type='save-resource'>
        <Button
          title=''
          kind='save'
          iconWidth='16px'
          iconHeight='18px'
          iconSave={!added}
          iconSaved={added}
          renderProps={{ disabled: added, onClick: createKnowledgeItem }}
        />
      </Tooltip>
    </WrapperSaveButton>
  );
};

export default AddtoPlaylistButton;
