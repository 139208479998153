import React,{ useEffect, useState } from 'react';
import { ExpertiseModel } from '../../../interfaces/interfaces';
import { useTag } from '../../../hooks/useTag';
import TagLabel from './TagLabel';
import { TextSmall } from '../../Typography/styled';
import { TagListContainer } from './styled';

interface TaglistModel {
  tags: ExpertiseModel[];
  userId: number;
  sameUser: Boolean;
  expertiseError: boolean;
}

const Taglist = ({ tags, userId, sameUser, expertiseError }: TaglistModel) => {
  const [expertises, setExpertises] = useState<Array<ExpertiseModel>>(tags || []);
  const { deleteExpertise, filterDuplicated } = useTag();

  useEffect(() => {
    setExpertises(filterDuplicated(tags || [], sameUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const handleDeleteTagAction = (item: ExpertiseModel) => {
    const updatedTopics = expertises.filter((topic: ExpertiseModel) => topic.id !== item.id);
    setExpertises(updatedTopics);
    deleteExpertise(item, userId as number);
  };

  return (
    <TagListContainer withTags={expertises.length > 0}>
      {expertises.length === 0 && (
        <TextSmall css={{ textAlign: 'left', color: expertiseError ? '$red' : undefined }}>
          Select max.3 topics
        </TextSmall>
      )}
      {expertises?.map((item: ExpertiseModel) => (
        <TagLabel
          key={item.id}
          item={item}
          handleDeleteTagAction={() => handleDeleteTagAction(item)}
        />
      ))}
    </TagListContainer>
  );
};

export default Taglist;
