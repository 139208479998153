import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import './styles.css';

interface RadixPopoverProps {
  trigger: JSX.Element;
  content: JSX.Element;
  width: number;
  open: boolean;
  withoutArrow?: boolean;
  closePopover?: () => void;
  disabled?: boolean;
}

const RadixPopover: React.FC<RadixPopoverProps> = ({
  trigger,
  content,
  width,
  open,
  withoutArrow,
  closePopover,
  disabled,
}) => (
  <Popover.Root open={open && !disabled}>
    {disabled ? (
      <div className="DisabledTrigger">{trigger}</div>
    ) : (
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
    )}
    <Popover.Portal>
      <Popover.Content
        onInteractOutside={closePopover}
        className="PopoverContent"
        sideOffset={5}
        side="bottom"
        align="start"
        style={{ width }}
      >
        {content}
        {/* <Popover.Close className="PopoverClose" aria-label="Close"> */}
        {/* <Cross2Icon /> */}
        {/* </Popover.Close> */}
        {!withoutArrow && <Popover.Arrow className="PopoverArrow" />}
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
);

export default RadixPopover;

RadixPopover.defaultProps = {
  withoutArrow: false,
  closePopover: undefined,
  disabled: false,
};
