import { Form } from 'formik';
import { styled } from '../../../../../config/theme';
import { FormikInput } from '../../../styled';

export const SignUpInput = styled(FormikInput, {
  width: '100%',
  fontSize: 13,
  variants: {
    isMember: {
      true: {
        border: '1px solid $grayscalePlaceholder',
      },
    },
  },
});

export const SignUpForm = styled(Form, {
  display: 'flex',
  flexDirection: 'column',
  background: '$offWhite',
  width: '100%',
  maxWidth: '400px',
});

// When show error, show below the input
export const LayoutColBlock = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  '@bp2': {
    gridTemplateColumns: '1fr 1fr',
    gap: '$spaceXSmall',
  },
});
