import { styled } from '../../../../config/theme';

export const PoppoverTrigger = styled('button', {
  width: '100%',
  height: '2.81rem',
  border: '1px solid #D9DBE9',
  borderRadius: '$radius05',
  backgroundColor: '$offWhite',
  fontWeight: '$weight400',
  fontSize: '$bodyTextXSmall',
  fontFamily: '$poppins',
  color: 'hsl(0, 0%, 46%)',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  textAlign: 'left',
  paddingLeft: '$spaceLarge',
  paddingRight: '0.25rem',
  marginTop: '0.625rem',
  marginBottom: '0.25rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@bp2': {
    fontSize: '$bodyXSmall',
  },
});

export const TagItemsContainer = styled('div', {
  maxHeight: '12.5rem',
  minHeight: '12.5rem',
  overflowY: 'scroll',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.1rem',
  alignItems: 'left',
  justifyContent: 'initial',
  position: 'relative',
  flex: 1,
  '&:last-child': {
    marginBottom: 0,
  },
  '&::-webkit-scrollbar': {
    width: 5,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '5rem',
    backgroundColor: '$grayscaleInput',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$grayscaleAsh',
    borderRadius: '0.563rem',
    border: '5px line solid',
  },
});

export const TagSelectorContentContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'initial',
  position: 'relative',
  flex: 1,
  '&:last-child': {
    marginBottom: 0,
  },
});

export const TagItemLink = styled('a', {
  color: '$grayscaleAsh',
  padding: '$spaceXSmall2 $spaceSmall',
  cursor: 'pointer',
  borderRadius: '$radius05',
  marginRight: '.5rem',
  '&:hover': {
    background: '$blurpleHover',
  },
  variants: {
    selected: {
      true: {
        color: '$blurpleDark',
        background: '$grayscaleInput',
      },
    },
  },
});

export const TagListContainer = styled('div', {
  display: 'inline-flex',
  alignItems: 'left',
  width: '100%',
  justifyContent: 'initial',
  flexGrow: 1,
  flexWrap: 'wrap',
  '&:last-child': {
    marginBottom: 0,
  },
  variants: {
    withTags: {
      true: {
        marginTop: '$spaceLarge',
      },
    },
  },
});
