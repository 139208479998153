import React, { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { Container } from '../../Experts/styled';

interface ExpertContainerProps {
  section?: 'work' | undefined;
  bg?:
    | 'monochromeBlack'
    | 'monochromeBlack25'
    | 'offWhite'
    | 'monochromeWhite25'
    | 'monochromeWhite'
    | undefined;
  space?: 'section'| 'section_fullPadding' | undefined;
  children: ReactNode;
}

export const ExpertContainer = ({ children, ...props }: ExpertContainerProps) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <Container isVisible={inView} ref={ref} {...props}>
      {children}
    </Container>
  );
};

ExpertContainer.defaultProps = {
  section: undefined,
  bg: undefined,
  space: undefined,
};
