import { styled, keyframes } from '../../../config/theme';

const dotFlashing = keyframes({
  "0%": { opacity: 0 },
  "50%": { opacity: 1 },
  "60%": { opacity: 1 },
  "100%": { opacity: 0 }
});

export const Loading = styled('div', {
  width: '100%',
  height: 'calc(100vh - 24px)',
  display: 'flex',
  alignItems:'center',
  justifyContent: 'center',
  fontWeight: '$weight700',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing3',
  color: '$offBlack', 

  '& .dot': {
    fontSize: '$bodyMedium',
    opacity: 0,
    animation: `${dotFlashing} 2.5s ease-in-out infinite`,
  },
  
  "& .one": { animationDelay: "0.2s" },
  "& .two": { animationDelay: "0.4s" },
  "& .three": { animationDelay: "0.6s" }
});
