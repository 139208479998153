/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BodyContainer, FlexContainer, Typography } from '../../styled';
import { DescriptionContainer } from './styled';

export const Earnings = () => (
  <BodyContainer>
    <FlexContainer direction="column" gap="2_5">
      <Typography size="displayXSmall" weight="700" lineHeight="lineHeight3">
        Earnings
      </Typography>
      <DescriptionContainer>
       <p>If you have made a recording and would like to provide your bank details, please send an
        email to  <a href="payments@gathersight.com"> payments@gathersight.com</a></p>  
      </DescriptionContainer>
    </FlexContainer>
  </BodyContainer>
);
