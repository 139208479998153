import axiosInstance from '../utils/axiosInstance';

export interface UnsubscribeServiceInterface {
  unsubscribeToken: string;
}

export const UnsubscribeService = {
  unsubscribe: async (data: UnsubscribeServiceInterface) => {
    const { unsubscribeToken } = data;
    try {
      const results = await axiosInstance().put(`/user_notification`, { unsubscribe_token: unsubscribeToken});
      return results?.data.data || [];
    } catch (error) {
      return 'error';
    }
  }
};
