import axiosInstance from "./utils/axiosInstance";

export interface dataInterface {
  page: number;
  limit?: number;
  attribute?: string;
  authenticationToken?: string;
  filter?: string;
  filterBy?: string;
  name?: string;
  start?: Date;
  endDate?: Date;
  whoToFollow?: boolean;
  orderBy?: string;
  topAuthors?: boolean;
  withContents?: string;
}

export const HomeService = {
  getAuthors: async ( data: dataInterface ) => {
    const { page, limit, name, start, endDate, authenticationToken, whoToFollow,
      topAuthors, withContents } = data;
    const params = { page, per_page: limit, start, end_date: endDate,
                     who_to_follow: whoToFollow, top_authors: topAuthors,
                     with_contents: withContents, name_or_username: name };
    try {
      const results = await axiosInstance(authenticationToken).get('users', { params })
        .then(response => (response?.data?.data || []));
      return results;
    } catch (error) {
      return [];
    }
  },

  getTags: async (data: dataInterface) => {
    const { page, limit, start, endDate, authenticationToken } = data;
    try {
      const results = await axiosInstance(authenticationToken)('tags',
      { params: { page, per_page: limit, start, end_date: endDate }})
      .then(response => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  getContents: async ( data: dataInterface) => {
    const { page, limit, authenticationToken, filter, orderBy } = data;
    let { filterBy } = data;
    if(filter === undefined && filterBy !== 'feed') filterBy = undefined;
    try {
      const results = await axiosInstance(authenticationToken)('contents',
        { params: { page, per_page: limit, filter, filter_by: filterBy, order_by: orderBy }})
      .then(response => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
};
