import { styled } from '../../../../config/theme';

export const ExpandableContainer = styled('div', {
  borderBottom: '1px solid $monochromeBlack200',
  padding: '$spaceSmall',
  width: '100%',
  paddingX: '$spaceLarge',
  '@bp2': {
    maxWidth: '40rem', // 572px
  },
});

export const ExpandableHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
});

export const ExpandableTitle = styled('h3', {
  color: '$monochromeBlack',
  fontSize: '$bodySmall',
  fontStyle: 'normal',
  fontWeight: '$weight600',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing4',
  maxWidth: '16.375rem', // 262px
  '@bp2': {
    maxWidth: '32.938rem', // 527px
  },
});

export const ExpandableContent = styled('p', {
  display: 'none',
  marginTop: '$spaceSmall',
  paddingRight: '10px',
  color: '$monochromeBlack400',
  fontSize: '$bodySmall',
  fontStyle: 'normal',
  fontWeight: '$normal',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing4',
  maxWidth: '16.375rem', // 262px
  '@bp2': {
    maxWidth: '32.938rem', // 527px
  },
  a: {
    color: '$link',
    fontWeight: '$weight600',
  },
});
export const ArrowImg = styled('img', {});
