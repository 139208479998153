import React, { useContext, useEffect, useState } from 'react';
import { ErrorContainer, NoDeviceErrorContainer, NoDeviceErrorText } from '../styles';
import RecordingIconError from '../../../assets/images/icons/recording-error-icon.svg';
import Button from '../../../shared/components/Button/Button';
import Icon from '../../../shared/components/Icon/Icon';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import Reload from '../../../assets/images/icons/reload.svg';

const NoDeviceError = ({ webcamHeight }: { webcamHeight: number }) => {
  const { listDevices, error } = useContext(VideoRecordingContext);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(true);

  const handleReload = async () => {
    setLoading(true);
    try {
      await listDevices();
    } catch (e) {
      throw new Error(`Error getting devices:  ${e}`);
    } finally {
      if (mounted) setLoading(false);
    }
  };

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <ErrorContainer css={{ height: webcamHeight }}>
      <NoDeviceErrorContainer css={{ height: webcamHeight - 100 }}>
        <Icon img={RecordingIconError} alt="error" />
        <NoDeviceErrorText>{error}</NoDeviceErrorText>
        <Button
          css={{ height: '2.5rem' }}
          loadingIconRotate={loading}
          iconCustom={Reload}
          width="auto"
          marginR
          renderProps={{ onClick: () => handleReload(), disabled: loading }}
          title="Try again"
        />
        <a href="/technical" target="_blank" rel="noopener noreferrer">
          How to change site’s permissions?
        </a>
      </NoDeviceErrorContainer>
    </ErrorContainer>
  );
};

export default NoDeviceError;
