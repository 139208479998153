import { styled } from '../../../config/theme';

export const WrapperSaveButton = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '4px',
});

export const TextSave = styled('div', {
  fontWeight: '$weight600',
  fontSize: '10px',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  marginRight: '$spaceSmall',
  color: '$offBlack',
  display: 'none',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: 180,
  whiteSpace: 'nowrap',
  '@bp2': {
    display: 'block',
  },
  '@bp4': {
    fontSize: '$bodySmall',
  },
});

export const BoxContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '2.5rem',
});

export const BoxFooter = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const TextTitle = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight3',
  alignSelf: 'baseline',
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  justifyContent: 'space-between',
  '& img': {
    margin: 0,
  },
});

export const BoxText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '1rem',
});

export const List = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'baseline',
  marginTop: '1rem',
  overflowY: 'scroll',
  maxHeight: '170px',
  scrollbarWidth: 'thin',
  scrollbarColor: '$ash',
  paddingRight: 5,
  '&::-webkit-scrollbar': {
    width: 3,
  },
  '&::-webkit-scrollbar-track-piece': {
    background: '$input',
    borderRadius: '5rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$ash',
    borderRadius: '.5rem',
  },
});

export const ListItem = styled('div', {
  fontWeight: '$weight500',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight1',
  padding: '0.5rem 12px',
  cursor: 'pointer',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '$radius05',
  marginBottom: '3px',
  transition: '.4s all ease-in',
  '&.selected': {
    backgroundColor: '$input',
    color: '$offBlack',
    fontWeight: '$weight700',
    '&:hover': {
      backgroundColor: '$input',
      opacity: '0.7',
    },
  },
  '&.disabled': {
    opacity: '0.5',
    cursor: 'not-allowed',
  },
  '&:hover': {
    backgroundColor: '$input',
  },
  '& img': {
    marginRight: 0,
  },
});

export const TextItem = styled('div', {
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  maxWidth: 180,
});

export const InputWrapper = styled('div', {
  background: 'transparent',
  width: '100%',
  minHeight: '3.5rem',
  display: 'flex',
  borderRadius: '$radius05',
  alignItems: 'center',
  position: 'relative',
  border: '1px solid $line',
  marginBottom: '$spaceSmall',
});

export const Input = styled('input', {
  background: 'transparent',
  width: '100%',
  border: 'none',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  padding: '$spaceSmall',
});

export const Textarea = styled('textarea', {
  fontFamily: 'Poppins',
  background: 'transparent',
  width: '100%',
  border: 'none',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  padding: '$spaceSmall',
});

export const ErrorText = styled('p', {
  color: '$red',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodyXSmall !important',
});

export const MoreText = styled('span', {
  fontSize: '$bodyXSSmall',
});
