import { styled } from '../../../../config/theme';

export const ProjectItemContainer = styled('div', {
  background: '$monochromeBlack25',
  border: '1px solid $monochromeBlack25',
  borderRadius: '$radius075',
  padding: '$spaceLarge2',
  width: '100%',
  '@bp3': {
    maxWidth: '29rem',
  },
  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
        '&:hover': {
          background: '$monochromeBlack50',
        },
      },
    },
  },
});

export const AvatarListWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '$spaceSmall',
});

export const AvatarImage = styled('img', {
  objectFit: 'cover',
  borderRadius: '100%',
  border: '1px solid $grayscaleOffWhite',
  background: '$grayscaleOffWhite',
  width: '$2_625',
  height: '$2_625',
  marginLeft: '-$spaceSmall',
  position: 'relative',
  zIndex: 1,
});

export const AvatarNoImage = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  objectFit: 'cover',
  borderRadius: '100%',
  border: '2px solid $grayscaleOffWhite',
  color: '$blurpleDark',
  background: '$bodyPale',
  width: '$2_625',
  height: '$2_625',
  paddingTop: '0.35rem',
  fontFamily: '$poppins',
  fontSize: '$bodyMedium',
  fontWeight: '$weight500',
  marginLeft: '-$spaceSmall',
  position: 'relative',
  zIndex: 1,
});

export const Label = styled('div', {
  marginLeft: '5px',
  fontWeight: 'bold',
  color: '#333',
});

export const CameraIconContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$grayscaleInput',
  borderRadius: '$radius03',
  padding: '$spaceXXXSmall',
  height: '1.38rem',
  width: '1.38rem',
});

export const StatusLabel = styled('div', {
  borderRadius: '$radius03',
  fontSize: '$bodyTextXSmall',
  fontWeight: '$weight500',
  padding: '0.13rem 0.63rem',
  variants: {
    status: {
      completed: {
        background: '$green100',
        color: '$green800',
      },
      in_progress: {
        background: '$purple100',
        color: '$purple800',
      },
      draft: {
        background: '$yellow100',
        color: '$yellow800',
      },
      archived: {
        background: '$red100',
        color: '$red800',
      },
    },
  },
});
