import React, { FC, Ref, useState } from 'react';
import Icon from '../Icon/Icon';
import {
  AccordionDiv,
  AccordionItem,
  AccordionTrigger,
  AccordionHeader,
  AccordionContent,
  AccordionArrow,
} from './styled';
import ArrowDownIcon from '../../../assets/images/icons/arrow-down-blue.svg';

interface AccordionModel {
  header: string | JSX.Element | JSX.Element[];
  children: string | JSX.Element | JSX.Element[];
  innerRef?: Ref<HTMLButtonElement> | null;
  kind?: string;
  value?: string;
}

type AccordionProps = AccordionModel &
  React.ComponentProps<typeof AccordionDiv> & {
    css?: any;
  };

const Accordion: FC<AccordionProps> = ({ header, children, css, kind, innerRef, value }) => {
  const [dataState, setDataState] = useState<boolean>(false);
  return (
    <AccordionDiv
      kind={kind}
      css={css}
      type="single"
      collapsible
      defaultValue={undefined}
      value={value}
    >
      <AccordionItem value="item-1">
        <AccordionHeader>
          <AccordionTrigger
            className="btn-event"
            ref={innerRef}
            onClick={() => setDataState(!dataState)}
          >
            <>
              {header}
              <AccordionArrow aria-hidden>
                <Icon
                  img={ArrowDownIcon}
                  css={{
                    transform: dataState ? 'rotate(180deg)' : 'rotate(0deg)',
                    cursor: 'pointer',
                  }}
                  width="15px"
                  height="15px"
                  alt="More"
                />
              </AccordionArrow>
            </>
          </AccordionTrigger>
        </AccordionHeader>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </AccordionDiv>
  );
};

Accordion.defaultProps = {
  css: {},
  kind: undefined,
  innerRef: undefined,
  value: undefined,
};

export default Accordion;
