import axios from 'axios';
import { apiUrl } from '../../constants/authConstants';
import axiosInstance from '../utils/axiosInstance';

export const ProjectsService = {
  getProjects: async (authenticationToken: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .get('projects')
        .then((response) => response?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  getProject: async (authenticationToken: string, projectId: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`projects/${projectId}`)
        .then((response) => response?.data || undefined);
      return results;
    } catch (error) {
      return undefined;
    }
  },
  getProjectByToken: async (projectId: string, token: string) => {
    try {
      const results = await axiosInstance()
        .get(`projects/${projectId}?token=${token}`)
        .then((response) => response?.data?.data || undefined);
      return results;
    } catch (error) {
      return undefined;
    }
  },
  getProjectQuestions: async (authenticationToken: string, projectId: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`projects/${projectId}/project_questions`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  createProject: async (authenticationToken: string, projectData: any) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .post('projects', projectData)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  updateProject: async (authenticationToken: string, projectId: string, projectData: any) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .patch(`projects/${projectId}`, projectData)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  addProjectDocuments: async (authenticationToken: string, projectId: string, projectData: any) => {
    const config = {
      baseURL: `${apiUrl.BASE_API}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        processData: 'false',
        Authorization: authenticationToken,
      },
    };
    try {
      const results = await axios
        .create(config)
        .patch(`projects/${projectId}`, projectData)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  removeProjectDocument: async (
    authenticationToken: string,
    projectId: string,
    documentId: string,
  ) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .delete(`projects/${projectId}/destroy_project_document/${documentId}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  removeProject: async (authenticationToken: string, projectId: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .delete(`projects/${projectId}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  recreateProjectQuestions: async (authenticationToken: string, slugProject: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .put(`projects/${slugProject}?recreate=true`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  inviteExperts: async (authenticationToken: string, projectId: string, experts: any[]) => {
    try {
      const invitationsId = experts.map((expert) => `user_emails[]=${expert.attributes.email}`).join('&');
      const results = await axiosInstance(authenticationToken)
        .post(`projects/${projectId}/project_invitations?${invitationsId}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
};
