import _uniqBy from 'lodash/uniqBy';

export const removeDuplicateContents = (contents1: any) => {
  try {
    const notNullData = contents1?.filter((i: any) => i) || [];
    const filtered = _uniqBy(notNullData, (o: { id: any }) => o?.id);

    return filtered;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ file: removeDuplicated.tsx ~ line 17 ~ error', error);
    return contents1;
  }
};
