import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextBreadcrumb } from "../styled";
import { EmptyDiv, FilterWrapper, OrderByFilter, OrderByWrapper } from "./styled";
import { SloganText } from '../../../shared/utils/globalText';
import ResultsFilter from "../../../shared/components/ResourcesSearch/ResultsFilter/ResultsFilter";
import AppContext from "../../../shared/context/app/AppContext";
import Button from "../../../shared/components/Button/Button";

const OrderBy: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [showOrderBy, setShowOrderBy] = useState(false);
  const { state } = useContext(AppContext);
  const { filterContent, alternative } = state;

  useEffect(() => {
    if(filterContent.type === 'content' && filterContent.filter && filterContent.filter !== '' && !alternative[filterContent.type]) {
      setShowOrderBy(true);
    } else { setShowOrderBy(false); }

  }, [filterContent.type, filterContent.filter, alternative]);

  const handleClick = () => {
    navigate('/expert-opinions');
  };

  return(
    <OrderByWrapper>
      <TextBreadcrumb css={{marginBottom: 10,   '@bp0': {
           marginBottom: 0,
          }}}>{SloganText}</TextBreadcrumb>
      <Button
        size="small"
        width="auto"
        title="Get Expert Opinion"
        handleClick={handleClick}
      />
      {showOrderBy
        ? <FilterWrapper>
            <TextBreadcrumb kind='orderBy'>Order By</TextBreadcrumb>
            <OrderByFilter>
              <ResultsFilter orderBy />
            </OrderByFilter>
          </FilterWrapper>
        : <EmptyDiv />
      }
    </OrderByWrapper>
  );
};
export default OrderBy;