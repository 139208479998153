import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { BodyContainer, BtnContainer } from '../styled';
import { Header } from '../../../shared/components/Dashboard/Header';
import Table from '../../../shared/components/Dashboard/Table/table';
import { organizationColumns } from '../../../shared/components/Dashboard/Table/columns/organizationColumns';

import Icon from '../../../assets/images/icons/Actions Plus.svg';
import { InviteMemberModal } from './InviteMemberModal';
import Button from '../../../shared/components/Button/Button';
import { useMember } from '../../../shared/hooks/Organization/useMember';
import { useUser } from '../../../shared/hooks/useUser';

const customColumns = organizationColumns.filter(
  (column) =>
    typeof column.accessor === 'string' && ['memberName', 'status'].includes(column.accessor),
);

export const MembersDashboard = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [isOpen, setIsOpen] = useState(false);
  const { sendMemberInvitation } = useMember();
  const { members, isMemberLoading, getMembers } = useMember();
  const { userData } = useUser();

  const getMappedMembers = () => {
    let mainMember: any = {};
    if (members.length > 0) {
      mainMember = {
        memberJobTitle: members[0].attributes.member_job,
        status: '',
        memberName: {
          avatar:
            members[0].attributes?.organization_avatar ||
            members[0].attributes.organization_user_name,
          alt: members[0].attributes.organization_user_name,
          name: members[0].attributes.organization_user_name,
        },
      };
    }
    return [
      ...members
        .filter(
          (member) =>
            member.attributes.email !== userData.email ||
            member.attributes.organization_user_name !== member.attributes.member_name,
        )
        .map((member) => ({
          memberJobTitle: member.attributes.member_job,
          status: member.attributes.status || 'completed',
          memberName: {
            avatar:
              member.attributes?.member_avatar_url ||
              member.attributes.member_name ||
              member.attributes.email,
            alt: member.attributes.member_name || member.attributes.email,
            name: member.attributes.member_name || member.attributes.email,
          },
        })),
      mainMember,
    ];
  };

  const getMockMembers = () =>
    _.range(1, 4).map(() => ({
      memberJobTitle: 'test',
      status: 'test',
      memberName: {
        avatar: 'test',
        alt: 'test',
        name: 'test',
      },
    }));

  const handleSendInvitation = async (data: any) => {
    const memberData = await sendMemberInvitation(data.email);
    if (memberData.error) {
      toast.error(memberData.error);
      toast.clearWaitingQueue();
    } else {
      toast.success('Invitation sent successfully');
      setIsOpen(false);
    }
  };

  const verifyNewMember = () => {
    if (params.add_member) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    verifyNewMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BodyContainer>
      <Header title="Members dashboard" subTitle="Here is a list of current members." />
      <BtnContainer>
        <Button
          iconCustom={Icon}
          title="Invite Member"
          kind="add-dashboards"
          handleClick={() => setIsOpen(true)}
        />
      </BtnContainer>
      <Table
        type="Organization"
        data={getMappedMembers()}
        loadingData={getMockMembers()}
        buttonTitle=""
        customColumns={customColumns}
        showButton={false}
        emptyMessage="Start by adding members"
        isLoading={isMemberLoading}
      />
      <InviteMemberModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        onConfirm={handleSendInvitation}
      />
    </BodyContainer>
  );
};
