import React, { useMemo, ReactElement, ReactNode, useEffect, useState, useRef } from 'react';
import Webcam from 'react-webcam';
import VideoRecordingContext from './videoRecordingContext';
import useWindowSize from '../../hooks/useWindowSize';
import UseDevices from '../../hooks/useDevices';
import { ProjectOpinion, RecordingStatus } from '../../interfaces/projectInterfaces';

const VideoRecordingProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const webcamRef = useRef<Webcam>(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoHeight, setVideoHeight] = useState(0);
  const [ratio, setRatio] = useState(0);
  const { width, height } = useWindowSize();
  const [videoWidth, setVideoWidth] = useState(0);
  const [status, setStatus] = useState<RecordingStatus>('init');
  const [blob, setBlob] = useState<Blob | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [projectOpinion, setProjectOpinion] = useState<ProjectOpinion | undefined>(undefined);
  const [fullscreen, setFullscreen] = useState(false);
  const [stopCapturing, setStopCapturing] = useState(false);
  const [newVideoTrack, setNewVideoTrack] = useState<{ label: string; value: string } | undefined>(
    undefined,
  );
  const [newAudioTrack, setNewAudioTrack] = useState<{ label: string; value: string } | undefined>(
    undefined,
  );
  const [videoTrack, setVideoTrack] = useState<{ label: string; value: string } | undefined>(
    undefined,
  );
  const [audioTrack, setAudioTrack] = useState<{ label: string; value: string } | undefined>(
    undefined,
  );
  const { videoInputDevices, audioInputDevices, listDevices } = UseDevices();

  useEffect(() => {
    if (width && height) {
      if (fullscreen) {
        setVideoHeight(height);
        setVideoWidth(width);
      } else if (width >= 1440) {
        setVideoWidth(600);
      } else if (width > 720) {
        setVideoWidth(600);
      } else {
        setVideoWidth(width);
      }
      const isLandscape = height <= width;
      setRatio(isLandscape ? width / height : height / width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, fullscreen]);

  useEffect(() => {
    const setDefaultValue = () => {
      setVideoTrack({ label: videoInputDevices[0].label, value: videoInputDevices[0].deviceId });
    };

    if (videoInputDevices.length > 0) {
      setDefaultValue();
    } else {
      setError(
        'We were unable to automatically detect a camera. Please ensure your camera device is connected and enabled in your settings.',
      );
      setStatus('error');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoInputDevices]);

  useEffect(() => {
    const setDefaultValue = () => {
      const track = audioInputDevices.find((device: any) => device.deviceId === 'default');
      if (track) setAudioTrack({ label: track.label, value: track.deviceId });
    };

    if (audioInputDevices.length > 0) {
      setDefaultValue();
    } else {
      setError(
        'We were unable to automatically detect an audio device. Please ensure your audio device is connected and enabled in your settings.',
      );
      setStatus('error');
    }
  }, [audioInputDevices, openModal]);

  useEffect(() => {
    if (audioInputDevices.length > 0 && videoInputDevices.length > 0) {
      setError(undefined);
      setStatus('init');
    }
  }, [audioInputDevices, videoInputDevices]);

  const value = useMemo(
    () => ({
      recordedChunks,
      ratio,
      videoWidth,
      videoHeight,
      width,
      status,
      blob,
      videoTrack,
      audioTrack,
      videoInputDevices,
      audioInputDevices,
      newVideoTrack,
      newAudioTrack,
      error,
      openModal,
      projectOpinion,
      listDevices,
      fullscreen,
      stopCapturing,
      setStopCapturing,
      setFullscreen,
      setAudioTrack,
      setVideoTrack,
      setRecordedChunks,
      setStatus,
      setBlob,
      setNewVideoTrack,
      setNewAudioTrack,
      setError,
      setVideoHeight,
      setOpenModal,
      setProjectOpinion,
      webcamRef,
    }),
    [
      recordedChunks,
      ratio,
      videoWidth,
      videoHeight,
      width,
      status,
      blob,
      videoTrack,
      audioTrack,
      videoInputDevices,
      audioInputDevices,
      newVideoTrack,
      newAudioTrack,
      error,
      openModal,
      projectOpinion,
      fullscreen,
      listDevices,
      stopCapturing,
    ],
  );

  return <VideoRecordingContext.Provider value={value}>{children}</VideoRecordingContext.Provider>;
};

export default VideoRecordingProvider;
