import React from 'react';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TextDescription, TypographyH2, TypographyH3 } from '../../styled';
import {  ExpertCardDescriptionContainer, Line } from './styled';

export const WhoAreOurExperts = () => (
  <ExpertContainer space="section_fullPadding" bg="monochromeBlack">
    <TypographyH2 align="center" color="monochromeWhite" type="displaySmall">
      Who are our experts?
    </TypographyH2>
    <ExpertCardDescriptionContainer>
      <TextDescription type="ourExperts" align="center" color="monochromeWhite">
        We have 60,000 scientists, physicians, academics, researchers and biotech specialists in our{' '}
        <a href="https://www.gathersight.com/">knowledge network.</a> Between them, these experts
        have published 500,000 papers, articles and opinion pieces relating to the advancement of
        biotechnology.
      </TextDescription>
      <Line />
      <TypographyH3 align="center" type="displayXSmall" weight="700" color="secondaryP3">
        Do you have biotech expertise?
      </TypographyH3>
      <TextDescription type="ourExperts" align="center" color="monochromeWhite">
        <a href="https://www.gathersight.com/expert">Join as an expert</a> and receive paid Expert
        Opinion requests matching your skills.
      </TextDescription>
    </ExpertCardDescriptionContainer>
  </ExpertContainer>
);
