import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexContainer, Typography } from '../../styled';
import { Avatar, ButtonContainer, MemberContainer } from './styled';
import Button from '../../../../shared/components/Button/Button';
import useWindowSize from '../../../../shared/hooks/useWindowSize';
import { ImageOrganizationContainer } from '../styled';
import { AddIcon } from './AddIcon';
import { useMember } from '../../../../shared/hooks/Organization/useMember';

export const MemberList = () => {
  const navigate = useNavigate();
  const { members, getMembers } = useMember();
  const { width } = useWindowSize();
  const isTablet = width < 769;
  const handleRedirect = () => {
    navigate('/organization/members?add_member=true');
  };

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexContainer gap="2_5" direction="column" spacing='container'>
      <Typography
        size={isTablet ? 'bodyLarge' : 'displayXSmall'}
        weight="700"
        lineHeight="lineHeight3"
        letterSpacing="letterSpacing3"
      >
        Members
      </Typography>
      <FlexContainer gap="1_25" wrap>
        {members.filter((member) => !member.attributes.status).length > 0 ? (
          members
            .filter((member) => !member.attributes.status)
            .map((member: any) => (
              <MemberContainer>
                {member.attributes.member_avatar_url ? (
                  <Avatar src={member.attributes.member_avatar_url} />
                ) : (
                  <ImageOrganizationContainer isAvatarMember noImage />
                )}
                <Typography size="bodySmall" weight="600">
                  {member.attributes.member_name}
                </Typography>
              </MemberContainer>
            ))
        ) : (
          <MemberContainer addMember>
            <FlexContainer align="center" justify="between" gap="0_75" width="100">
              <FlexContainer align="center" gap="0_75">
                <ImageOrganizationContainer isAvatarMember noImage />
                <Typography size="bodyTextXSmall" color="monochromeBlack400">
                  Invite members
                </Typography>
              </FlexContainer>
              <ButtonContainer onClick={handleRedirect}>
                <AddIcon />
              </ButtonContainer>
            </FlexContainer>
          </MemberContainer>
        )}
      </FlexContainer>
      {members.length > 8 && (
        <FlexContainer justify="center">
          <Button title="View All Members" kind="outline" handleClick={handleRedirect} />
        </FlexContainer>
      )}
    </FlexContainer>
  );
};
