import axios from 'axios';
import queryString from 'query-string';
import { apiUrl, ProdOrDevUrl } from '../../constants/authConstants';

export const axiosInstance = axios.create({
  baseURL: `${apiUrl.BASE_API}`,
});

export const OrcidAuthService = {
  getOrcidURL: async (login: boolean) => {
    const url = '/auth/orcid';
    const loginText = login ? 'login' : 'signup';
    const callbackUrl = `${ProdOrDevUrl().BASE_API}/auth/orcid/callback/${loginText}`;
    try {
      const res = await axiosInstance.get(url, { params: { callback_url: callbackUrl } });
      window.location.href = res.data.url;
    } catch {
      return { error: 'There was a problem logging in. Please try again' };
    }
    return null;
  },

  postTokens: async (login: string) => {
    const { code } = queryString.parse(window.location.search);
    const callbackUrl = `${ProdOrDevUrl().BASE_API}/auth/orcid/callback/${login}`;
    const params = { code, callback_url: callbackUrl, login: login === 'login' };

    try {
      const res = await axiosInstance.post('/auth/orcid/callback', params);
      return res.data;
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
};
