import React from 'react';

export const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.875 15C2.35938 15 1.91781 14.8162 1.55031 14.4487C1.18281 14.0812 0.999377 13.64 1 13.125V10.3125H2.875V13.125H14.125V10.3125H16V13.125C16 13.6406 15.8163 14.0822 15.4488 14.4497C15.0813 14.8172 14.64 15.0006 14.125 15H2.875ZM7.5625 11.25V3.60937L5.125 6.04687L3.8125 4.6875L8.5 0L13.1875 4.6875L11.875 6.04687L9.4375 3.60937V11.25H7.5625Z"
      fill="#14142B"
    />
  </svg>
);
