import React, { useMemo, ReactElement, ReactNode, useState } from 'react';
import TagContext from './TagContext';
import { TagModel } from '../../interfaces/interfaces'

const TagProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [tags, setTags] = useState<TagModel[]>([]);

  const value = useMemo(() => ({ tags, setTags }), [tags]);

  return <TagContext.Provider value={value}>{children}</TagContext.Provider>;
};

export default TagProvider;
