import React from 'react';
import { ItemContainer, ItemsContainers, TextContainer } from './styled';
import { RoundedIcon } from './RoundedIcon';
import { ChatIcon } from './ChatIcon';
import { DNAIcon } from './DNAIcon';
import { PhotoIcon } from './PhotoIcon';
import useWindowSize from '../../../../shared/hooks/useWindowSize';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TypographyH2 } from '../../styled';

export const WhatIsExpert = () => {
  const { width } = useWindowSize();

  const isTablet = () => {
    if (width <= 768 && width > 425) {
      return true;
    }
    return false;
  };
  return (
    <ExpertContainer section="work" bg="monochromeBlack">
      <TypographyH2 type="displaySmall" color="secondaryP3" align="center">
        What is an <b>Expert Opinion?</b>
      </TypographyH2>
      <ItemsContainers>
        <ItemContainer>
          <RoundedIcon
            icon={
              <ChatIcon
                height={isTablet() ? '35px' : '24px'}
                width={isTablet() ? '35px' : '24px'}
              />
            }
          />
          <TextContainer>
          Imagine Cameo for biotech experts! An Expert Opinion is a short video recording
          featuring a biotech specialist like you answering a short, focused set of questions
          carefully matched to their experience and expertise.
          </TextContainer>
        </ItemContainer>
        <ItemContainer>
          <RoundedIcon
            icon={
              <DNAIcon height={isTablet() ? '42px' : '24px'} width={isTablet() ? '42px' : '24px'} />
            }
          />
          <TextContainer>
            Expert Opinions are used by a wide variety of organizations aiming to enhance their
            understanding of the science, technology and business of biotechnology.
          </TextContainer>
        </ItemContainer>
        <ItemContainer>
          <RoundedIcon
            icon={
              <PhotoIcon
                height={isTablet() ? '42px' : '24px'}
                width={isTablet() ? '42px' : '24px'}
              />
            }
          />
          <TextContainer>
            Recording an Expert Opinion is quick and easy, simply open our recording interface on
            your phone or computer and away you go.
          </TextContainer>
        </ItemContainer>
      </ItemsContainers>
    </ExpertContainer>
  );
};
