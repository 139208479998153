import { UserModel } from '../../shared/context/app/types';
import axiosInstance from '../utils/axiosInstance';

export interface dataInterface {
  id?: number | string;
  dataUser?: UserModel;
  authenticationToken?: string;
}

interface dataAvatarInterface {
  userId: number | string;
  authenticationToken: string;
  avatar: FormData;
}

export const ProfileService = {
  getUser: async (data: dataInterface) => {
    const { id, authenticationToken } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`users/${id}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  getUserAxiosResponse: async (data: dataInterface) => {
    const { id, authenticationToken } = data;
    try {
      const response = await axiosInstance(authenticationToken).get(`users/${id}`);
      return response;
    } catch (error: any) {
      return { ...error.response, code: error.code };
    }
  },

  updateUser: async (data: dataInterface) => {
    const { authenticationToken, dataUser } = data;
    const {
      email,
      firstName,
      lastName,
      biography,
      username,
      researchGate,
      linkedinLink,
      userId,
      experiences,
      languages,
      timezone,
      oldPassword,
      newPassword,
      authorOccupation,
    } = dataUser as UserModel;
    try {
      const results = await axiosInstance(authenticationToken)
        .put(`users/${userId}`, {
          email,
          first_name: firstName,
          last_name: lastName,
          biography,
          username,
          research_gate: researchGate,
          linkedin_link: linkedinLink,
          user_experiences_attributes: experiences,
          user_languages_attributes: languages,
          timezone,
          current_password: oldPassword,
          password: newPassword,
          author_occupation: authorOccupation,
        })
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  updateUserAvatar: async (data: dataAvatarInterface) => {
    const { authenticationToken, userId, avatar } = data;

    try {
      const results = await axiosInstance(authenticationToken)
        .put(`users/${userId}`, avatar)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  updateOrganizationName: async (
    authenticationToken: string,
    organizationName: string | undefined,
    userId: string,
  ) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .put(`users/${userId}`, {
          organization: organizationName,
        })
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  updateOrganizationAvatar: async (data: dataAvatarInterface) => {
    const { authenticationToken, userId, avatar } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .put(`users/${userId}`, avatar)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
};
