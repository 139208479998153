import React from 'react';

export const RefreshIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.9078 6.34618C3.25705 5.12819 3.98293 4.05203 4.9814 3.27194C5.97987 2.49185 7.19967 2.04788 8.46597 2.00366C9.73227 1.95944 10.9801 2.31724 12.0305 3.02578C13.081 3.73432 13.8802 4.75722 14.3135 5.94788M14.2034 10.3325C13.7521 11.4022 13 12.318 12.0385 12.9688C11.077 13.6196 9.94738 13.9775 8.78653 13.999C7.62569 14.0205 6.48354 13.7047 5.49859 13.09C4.51364 12.4753 3.7282 11.588 3.23752 10.5357M4.55923 9.9566L2.84793 10.2104L2.02417 11.7317M12.8389 6.48659L14.5502 6.23282L15.3739 4.71152"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
