import { styled } from '../../../../config/theme';

export const DescriptionContainer = styled('div', {
  marginTop: '$spaceLarge',
  width: 'fit-content',
  height: 'auto',
  background: '$monochromeBlack25',
  padding: '$spaceLarge',
  p: {
    color: '$monochromeBlack400',
    fontWeight: '$weight500',
    fontSize: '$bodyCard',
  },
  a: {
    color: '$blurple',
    fontWeight: '$weight500',
    textDecoration: 'none',
  },
});
