import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ItemContainer, ItemIconContainer } from './styled';
import { Typography } from '../../styled';
import { BackIcon } from '../icons/BackIcon';
import useWindowSize from '../../../../shared/hooks/useWindowSize';

interface ItemContainerProps {
  icon: ReactNode;
  label: string;
  route: string;
  credit?: boolean;
  isExpanded: boolean;
  isExternal?: boolean;
  onSelect: () => void;
}

export const Item = ({
  icon,
  label,
  route,
  credit,
  isExpanded,
  isExternal,
  onSelect,
}: ItemContainerProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(false);
  const [isSubRoute, setIsSubRoute] = useState(false);
  const { width } = useWindowSize();
  const isTablet = width >= 720 && width < 980;

  const handleNavigate = () => {
  
    if (isExternal) {
      window.open(route, '_blank');
    } else if (selected && isSubRoute) {
      navigate(route);
      setSelected(false);
      setIsSubRoute(false);
    } else {
      navigate(route);
      onSelect();
    }
  };

  const isRouteSelected = () => {
    if (pathname === route) {
      setSelected(true);  
      setIsSubRoute(false);
    } else if (pathname !== route && pathname.includes(route)) {
      setSelected(true);
      setIsSubRoute(true);
    } else {
      setSelected(false);
      setIsSubRoute(false);
    }
  };

  useEffect(() => {
    isRouteSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <ItemContainer
      credit={credit}
      onClick={handleNavigate}
      selected={selected}
      isTablet={isTablet && !isExpanded}
    >
      <ItemIconContainer>{selected && isSubRoute ? <BackIcon /> : icon}</ItemIconContainer>
      {(!isTablet || isExpanded) && (
        <Typography size="bodyXSmall" weight="500">
          {label}
        </Typography>
      )}
    </ItemContainer>
  );
};

Item.defaultProps = {
  credit: false,
  isExternal: false,
};
