import React from 'react';
import { BodyContainer, FlexContainer } from '../styled';
import { OrganizationInformation } from './OrganizationInformation';
import { Separator } from './styled';
import { ProjectList } from './ProjectList';
import { MemberList } from './MemberList';

export const ProfileOpinions = () => (
  <BodyContainer>
    <FlexContainer direction="column" gap="2_5">
      <OrganizationInformation />
      <Separator />
      <ProjectList />
      <Separator />
      <MemberList />
    </FlexContainer>
  </BodyContainer>
);
