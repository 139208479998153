import React, { useContext } from 'react';
import { Row, TabButton } from './styled';
import AuthenticationContext from '../../../../shared/context/authentication/authenticationContext';

const Tabs: React.FunctionComponent = () => {
  const { login, setLogin } = useContext(AuthenticationContext);

  return(
    <>
      {login && (
        <Row color="inActive">
          <TabButton onClick={() => setLogin(true)} color="activeLeft">
            Sign in
          </TabButton>

          <TabButton onClick={() => setLogin(false)} color="inActiveRight">
            Sign up
          </TabButton>
        </Row>
      )}
      {!login && (
        <Row color="inActive">
          <TabButton onClick={() => setLogin(true)} color="inActiveLeft">
            Sign in
          </TabButton>

          <TabButton onClick={() => setLogin(false)} color="activeRight">
            Sign up
          </TabButton>
        </Row>
      )}
    </>
  );
};

export default Tabs;