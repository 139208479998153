import React from 'react';
import TooltipCard from '../TooltipCard';
import People from './People';
import PeopleDescription from './PeopleDescription';
import { PeopleInfo, PeopleName, NameLink, PeopleDate, AuthorText, TextTooltip, BoxMembers, TextOthers } from './styles';

interface PeopleDataContentModel {
  name: string;
  text: string;
  type?: string;
  slug?: string;
  typePeople?: string;
  authorsMentions?: any;
  actionClose?: () => void;
  actionFollow?: () => void;
  follow?: number | null;
  id?: string;
  twitterName?: string;
  img: string;
}

type PeopleDataContentProps = PeopleDataContentModel &
  React.ComponentProps<typeof PeopleInfo> & {
    css?: any;
    kind?: string;
  };

const PeopleDataContent: React.FC<PeopleDataContentProps> = ({
  text, name, css, slug, actionClose, typePeople, kind, type, authorsMentions,
  img, follow, twitterName, id, actionFollow
}) => {
  const isAuthor = typePeople === 'author';
  const isMention = typePeople === 'mention';
  const isMentions = typePeople === 'mentions';
  const anyMentions = isMention || isMentions;
  const isAuthorMention = typePeople === 'author-mention';
  const isAuthorMentions = typePeople === 'authors-mentions';
  const anyAuthorMentions = isAuthorMention || isAuthorMentions;
  const mentionsLength = authorsMentions?.length || 0;
  const subText = anyAuthorMentions ? `${authorsMentions[0].attributes?.name}` : text;
  const isLittle = Boolean(type?.includes('little'));

  // eslint-disable-next-line react/no-unstable-nested-components
  const TooltipMentions = () => (
    <BoxMembers>
      <TextOthers>Other mentions</TextOthers>
      {authorsMentions.map((user: any) => (
        <People
          key={`people-${user.id}`}
          img={user.attributes?.avatar_url}
          name={user.attributes?.name}
          text=''
          follow={user.attributes?.relationship_id}
          id={user.attributes?.user_id}
          type='header'
          twitterName={user.attributes?.twitter_handle}
          slug={user.attributes?.user_slug}
        />
      ))}
    </BoxMembers>
  );

  return (
    <PeopleInfo css={css} kind={kind}>
      <div style={{ display: 'inherit', alignItems: 'center', flexWrap: 'wrap' }}>
        <TooltipCard content={(
          <>
            <People
              img={img}
              name={name}
              text=''
              follow={follow}
              id={id}
              type='content'
              twitterName={twitterName}
              actionFollow={actionFollow}
              slug={slug}
            />
            <PeopleDescription userId={id} />
          </>
        )}>
          <PeopleName onClick={actionClose}>
            {anyMentions && <PeopleDate mentions>{'Mentions '}</PeopleDate>}
            {slug ?
            (<NameLink little={isLittle} to={`/profile/${slug}`}>{name}</NameLink>)
            :
            name
          }
          </PeopleName>
        </TooltipCard>
        
        {(isAuthor || anyAuthorMentions) && <AuthorText>AUTHOR</AuthorText>}
        {anyAuthorMentions && (
          <TooltipCard className='mention' content={(
            <>
              <People
                img={authorsMentions[0].attributes?.avatar_url}
                name={authorsMentions[0].attributes?.name}
                text=''
                follow={authorsMentions[0].attributes?.relationship_id}
                id={authorsMentions[0].attributes?.user_id}
                type='content'
                twitterName={authorsMentions[0].attributes?.twitter_handle}
                slug={authorsMentions[0].attributes?.user_slug}
              />
              <PeopleDescription userId={authorsMentions[0].attributes?.user_id} />
            </>
          )}>
            <PeopleDate className='mention-tooltip' mentions little={isLittle}> <span>&#8226;</span> {`mentions `}
              <NameLink little to={`/profile/${authorsMentions[0].attributes?.user_slug}`}><b>{`${subText}`}</b></NameLink>
            </PeopleDate>
          </TooltipCard>
        )}
        {anyMentions && (mentionsLength > 1) &&
          (<TooltipCard isModal content={(<TooltipMentions />)}>
          <TextTooltip>+ others</TextTooltip>
        </TooltipCard>)}
      </div>
      <div style={{ display: 'inherit' }}>
        <PeopleDate little={isLittle}>{text || ''}</PeopleDate>
      </div>
    </PeopleInfo>
  );
};

PeopleDataContent.defaultProps = {
  slug: undefined,
  css: undefined,
  kind: undefined,
  type: undefined,
  actionClose: () => { },
  authorsMentions: undefined,
  typePeople: '',
  actionFollow: undefined,
  follow: undefined,
  id: undefined,
  twitterName: undefined
};

export default PeopleDataContent;
