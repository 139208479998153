import React from 'react';
import { SoundIndicatorContainer } from '../styles';
import { ConfigIcon } from './icons/ConfigIcon';

export const ConfigButton = ({ onClick }: { onClick?: () => void }) => (
  <SoundIndicatorContainer isBlock onClick={onClick}>
    <ConfigIcon height={16} width={16} />
  </SoundIndicatorContainer>
);

ConfigButton.defaultProps = {
  onClick: undefined,
};
