import React, { useState } from 'react';
import { BodyContainer, FlexContainer } from '../styled';
import { Header } from '../../../shared/components/Dashboard/Header';
import { MiddleSection } from '../../../shared/components/Dashboard/MiddleSection';
import Table from '../../../shared/components/Dashboard/Table/table';
import { organizationColumns } from '../../../shared/components/Dashboard/Table/columns/organizationColumns';

import Icon from '../../../assets/images/icons/Actions Plus.svg';
import { useProjects } from '../../../shared/hooks/useProjects';
import useGetUserType from '../../../shared/hooks/getUserType';
import { ConfirmDeleteProjectModal } from './ConfirmDeleteProjectModal';
import { useProjectList } from '../../../shared/hooks/Organization/useProjectList';
// import { TableFilter } from './TableFilter';

export const OrganizationDashboard = () => {
  const { removeProject } = useProjects();
  const { getListMapped, loadingProjects, getMockList } = useProjectList();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<any>();
  const userType = useGetUserType();

  const getCustomColumns = () => {
    const seekerColumns = ['name', 'status', 'progress'];
    const expertsColumns = ['name', 'status'];
    const columns = userType === 'seeker' ? seekerColumns : expertsColumns;
    const customColumns = organizationColumns.filter(
      (column) => typeof column.accessor === 'string' && columns.includes(column.accessor),
    );
    return customColumns;
  };

  const handleConfirmDelete = async () => {
    setIsOpenModal(false);
    await removeProject(itemToDelete?.id);
    setItemToDelete(undefined);
  };

  const openModal = async (item: any) => {
    setIsOpenModal(true);
    await setItemToDelete(item);
  };

  const cancelModal = () => {
    setIsOpenModal(false);
    setItemToDelete(undefined);
  };

  const getMappedWithoutLinks = () => getListMapped().map((project) => ({
    ...project,
    name: project.name.props ? project.name.props.children : project.name,
  }));

  return (
    <BodyContainer>
      <FlexContainer direction="column" gap="2_5" spacing='container'>
        <Header title="Projects dashboard" subTitle="Here is a list of current projects." />
        {userType === 'seeker' && (
          <MiddleSection iconCustom={Icon} title="Create Project" navigateTo="/new-project" />
        )}
        {/* {userType === 'expert' && <TableFilter />} */}
        <Table
          type={userType === 'seeker' ? 'Organization' : 'Expert'}
          data={getMappedWithoutLinks()}
          loadingData={getMockList()}
          buttonTitle={userType === 'seeker' ? 'View Opinions' : 'View'}
          customColumns={getCustomColumns()}
          onDeleteRow={(item) => openModal(item)}
          emptyMessage={
            userType === 'Expert' ? 'There are no invitations yet.' : 'Start by creating a project'
          }
          isLoading={loadingProjects}
        />
        <ConfirmDeleteProjectModal
          isOpen={isOpenModal}
          onConfirm={handleConfirmDelete}
          onCancel={cancelModal}
        />
      </FlexContainer>
    </BodyContainer>
  );
};
