import React, { useMemo, ReactElement, ReactNode, useState, useEffect } from 'react';
import ProjectsContext from './ProjectsContext';
import {
  Project,
  ProjectDocument,
  ProjectExperts,
  ProjectLocation,
  ProjectOccupation,
  ProjectQuestion,
  ProjectTag,
  Question,
} from '../../interfaces/projectInterfaces';

const ProjectsProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<Project | undefined>(undefined);
  const [projectQuestions, setProjectQuestions] = useState<Question[]>([]);
  const [projectTags, setProjectTags] = useState<ProjectTag[]>([]);
  const [projectLocations, setProjectLocations] = useState<ProjectLocation[]>([]);
  const [projectOccupations, setProjectOccupations] = useState<ProjectOccupation[]>([]);
  const [projectExperts, setProjectExperts] = useState<ProjectExperts[]>([]);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [opinions, setOpinions] = useState<string>('');
  const [documents, setDocuments] = useState<ProjectDocument[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [rolesList, setRolesList] = useState<any[]>([]);
  const [lengthError, setLengthError] = React.useState(false);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (project?.id) {
      const questions = project.attributes?.project_questions?.map((question: ProjectQuestion) => ({
        id: question.id,
        text: question.attributes.text,
      }));
      setProjectQuestions(questions);
      setProjectTags(project.attributes.project_tags);
      setProjectLocations(project.attributes.project_locations);
      setProjectOccupations(project.attributes.project_occupations);
      setName(project.attributes.name);
      setPrice(project.attributes.price);
      setDescription(project.attributes.description);
      setOpinions(project.attributes.opinions);
      setDocuments(project.attributes.documents);
      setProjectExperts(project.attributes.project_experts);
    }
  }, [project]);

  useEffect(() => {
    if (reset) {
      setProjects([]);
      setProject(undefined);
      setProjectQuestions([]);
      setProjectTags([]);
      setProjectLocations([]);
      setProjectOccupations([]);
      setName('');
      setPrice('');
      setDescription('');
      setOpinions('');
      setDocuments([]);
      setProjectExperts([]);
      setReset(false);
    }
  }, [reset]);

  const value = useMemo(
    () => ({
      projects,
      project,
      projectQuestions,
      projectTags,
      projectLocations,
      projectOccupations,
      name,
      price,
      opinions,
      description,
      documents,
      locationsList,
      rolesList,
      lengthError,
      projectExperts,
      setProjectExperts,
      setLengthError,
      setProjects,
      setProject,
      setName,
      setDescription,
      setProjectQuestions,
      setOpinions,
      setProjectLocations,
      setProjectOccupations,
      setDocuments,
      setProjectTags,
      setLocationsList,
      setRolesList,
      setReset,
    }),
    [
      projects,
      project,
      projectQuestions,
      projectTags,
      projectLocations,
      projectOccupations,
      name,
      price,
      description,
      opinions,
      documents,
      locationsList,
      rolesList,
      projectExperts,
      lengthError,
    ],
  );

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};

export default ProjectsProvider;
