import React, { FC, useMemo, useEffect, useState } from 'react';
import { DivWidgets } from './styles';
import useWindowSize from '../../hooks/useWindowSize';
import useGetResourcesResponsive from '../../hooks/useGetResourcesResponsive';
import CardResourcesList from '../CardResources/CardResourcesList';
import CardPeopleList from '../CardPeople/CardPeopleList';
import ResourcesListLoad from '../ShimmerComponent/ResourcesListLoad';
import ProvidersListLoad from '../ShimmerComponent/ProvidersListLoad';

interface WidgetResponsiveModel {
  kindResource?: string;
  breakpoint: number;
  limit?: number;
};

type WidgetResponsiveProps = WidgetResponsiveModel &
  React.ComponentProps<typeof DivWidgets> & {
    css?: any;
  };

const WidgetResponsive: FC<WidgetResponsiveProps> = ({ kindResource, css, breakpoint, limit }) => {
  const [hide, setHide] = useState<boolean>(false);
  const { width } = useWindowSize();
  const data = useGetResourcesResponsive({ breakpoint, kind: kindResource, limit: Number(limit) });
  const isContent = kindResource === 'content';
  const isEmpty = data?.length === 0;

  const Contents = useMemo(() =>
    data?.length > 0 ? <CardResourcesList data={data} /> : <ResourcesListLoad total={2} />
, [data]);

  const Providers = useMemo(() => (
    <DivWidgets css={css}>
      {data?.length > 0 ?
        <CardPeopleList data={data} hideEmpty /> : <ProvidersListLoad total={4} />}
    </DivWidgets>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isEmpty) {
        setHide(true);
      } else {
        setHide(false);
      }
    }, 2500);

    return () => clearTimeout(timer);
  }, [isEmpty]);

  if (width < breakpoint && !hide) {
    return isContent ? Contents : Providers;
  }

  return null;
};

WidgetResponsive.defaultProps = {
  css: undefined,
  kindResource: 'content',
  limit: undefined
};

export default WidgetResponsive;
