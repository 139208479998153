import { styled } from '../../../../config/theme';

export const BackButton = styled('div', {
  display: 'flex',
  color: '$grayscale',
  fontFamily: '$poppins',
  fontWeight: '$normal',
  fontSize: '$bodyXSmall',
  cursor: 'pointer',
  alignItems: 'center',
  gap: '0.5rem',
  "div": {
    padding: '0.3rem',
  },
  '&:hover': {
    "div": { 
      backgroundColor: '$input',
      padding: '0.3rem',
      borderRadius: '$radius03'
      
    },
  },
});
