import React from 'react';
import { StatusDot, ErrorTitle, SuccessTitle, ErrorBoxWrapper } from './styled';

const ErrorBox = (props: { valid: Boolean; title: String }) => {
  const { valid, title } = props;

  return (
    <ErrorBoxWrapper>
      <StatusDot css={valid ? { backgroundColor: '$green' } : { backgroundColor: '$error' }} />
      {valid ? <SuccessTitle>{title}</SuccessTitle> : <ErrorTitle>{title}</ErrorTitle>}
    </ErrorBoxWrapper>
  );
};

export default ErrorBox;
