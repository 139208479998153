import React from 'react';

export const StarShapeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M29.334 12.8916C29.2498 12.6477 29.0966 12.4335 28.8931 12.2749C28.6895 12.1163 28.4444 12.0202 28.1873 11.9982L20.6006 10.8916L17.2006 3.99824C17.0914 3.77281 16.921 3.5827 16.7087 3.44967C16.4965 3.31665 16.2511 3.24609 16.0006 3.24609C15.7501 3.24609 15.5047 3.31665 15.2925 3.44967C15.0803 3.5827 14.9098 3.77281 14.8006 3.99824L11.4006 10.8782L3.81395 11.9982C3.56718 12.0333 3.33519 12.1369 3.14429 12.2971C2.95339 12.4574 2.81123 12.6679 2.73395 12.9049C2.66321 13.1365 2.65687 13.3829 2.71559 13.6178C2.77432 13.8527 2.8959 14.0672 3.06729 14.2382L8.57395 19.5716L7.24062 27.1449C7.19302 27.3949 7.21795 27.6532 7.31245 27.8895C7.40695 28.1258 7.56709 28.3301 7.77395 28.4782C7.97558 28.6224 8.21339 28.7075 8.46068 28.724C8.70798 28.7404 8.95498 28.6877 9.17395 28.5716L16.0006 25.0116L22.8006 28.5849C22.9878 28.6905 23.1991 28.7456 23.414 28.7449C23.6964 28.7459 23.9718 28.6572 24.2006 28.4916C24.4075 28.3434 24.5676 28.1391 24.6621 27.9028C24.7566 27.6666 24.7816 27.4082 24.734 27.1582L23.4006 19.5849L28.9073 14.2516C29.0998 14.0885 29.2421 13.8741 29.3176 13.6334C29.3931 13.3926 29.3988 13.1354 29.334 12.8916ZM21.134 18.2249C20.9776 18.3762 20.8606 18.5634 20.7932 18.7702C20.7258 18.977 20.71 19.1972 20.7473 19.4116L21.7073 24.9982L16.694 22.3316C16.501 22.2289 16.2858 22.1751 16.0673 22.1751C15.8487 22.1751 15.6335 22.2289 15.4406 22.3316L10.4273 24.9982L11.3873 19.4116C11.4245 19.1972 11.4088 18.977 11.3414 18.7702C11.274 18.5634 11.157 18.3762 11.0006 18.2249L7.00062 14.2249L12.614 13.4116C12.83 13.3815 13.0353 13.299 13.212 13.1711C13.3886 13.0433 13.5312 12.874 13.6273 12.6782L16.0006 7.59824L18.5073 12.6916C18.6033 12.8874 18.746 13.0566 18.9226 13.1844C19.0993 13.3123 19.3046 13.3949 19.5206 13.4249L25.134 14.2382L21.134 18.2249Z"
      fill="white"
    />
  </svg>
);
