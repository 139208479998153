import React, { ReactNode } from 'react';
import {
  StepContainer,
  StepperContainer,
  StepperIndicator,
  StepperIndicatorContainer,
  StepperTitle,
} from './styled';
import useIsLoggedIn from '../../../../shared/hooks/useIsLoggedIn';

type StepperProps = {
  children: ReactNode;
  currentStep: number;
};

export const Stepper = ({ children, currentStep }: StepperProps) => {
  const userLogged = useIsLoggedIn();
  return (
    <StepperContainer>
      <StepperTitle>
        {/* @ts-ignore */}
        {userLogged ? 'Project Information' : `Step ${currentStep + 1} of ${children.length}`}
      </StepperTitle>
      <StepperIndicatorContainer>
        {React.Children.map(children, (child, index) => (
          <StepperIndicator onStep={currentStep >= index} />
        ))}
      </StepperIndicatorContainer>
      {React.Children.map(children, (child, index) => (
        <StepContainer style={{ display: index === currentStep ? 'block' : 'none' }}>
          {child}
        </StepContainer>
      ))}
    </StepperContainer>
  );
};
