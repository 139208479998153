import React, { useState, useRef, ChangeEvent, useEffect, useContext } from 'react';
import { BoxContent } from '../../../../screens/OnBoarding/styles';
import { AddPicture, InputImg, WrapperLoading, WrapperPicture } from './styled';
import editIcon from '../../../../assets/images/icons/edit.svg';
import Icon from '../../Icon/Icon';
import avatarIcon from '../../../../assets/images/icons/avatar.svg';
import useGetToken from '../../../hooks/useGetToken';
import useGetCurrentUserID from '../../../hooks/getCurrentUserID';
import AppContext from '../../../context/app/AppContext';
import { ProfileService } from '../../../../services/ProfileService/ProfileService';
import { Spinner } from '../../Spinner/spinner';
import Avatar from '../Avatar';
import { getUserName } from '../../../utils/helper';

interface ChangeAvatarModel {
  type: string;
  loading?: React.SetStateAction<Boolean>;
  save?: React.SetStateAction<Boolean>;
}

const ChangeAvatar: React.FC<ChangeAvatarModel> = ({ type, loading, save }) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [newImage, setNewImage] = useState<File>();
  const [imgProfile, setImgProfile] = useState<string>('');
  const authenticationToken = useGetToken();
  const userId = useGetCurrentUserID();
  const { state, dispatch } = useContext(AppContext);
  const { avatarUrl, username, firstName, lastName } = state.userData;
  const dataName = {
    username,
    first_name: firstName,
    last_name: lastName,
  };
  const finalName = getUserName(dataName);

  useEffect(() => {
    if (avatarUrl) {
      setImgProfile(avatarUrl);
    }
  }, [avatarUrl]);

  useEffect(() => {
    const saveImage = async () => {
      if (imgProfile && newImage) {
        const avatar = new FormData();
        avatar.append('avatar', newImage);
        const data = { userId, authenticationToken, avatar };
        const response = await ProfileService.updateUserAvatar(data);
        dispatch({ type: 'SET_USER', payload: response });
      }
    };
    if (save) {
      saveImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  const handleSetImage = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files?.length) {
      setImgProfile(URL.createObjectURL(files[0]));
      setNewImage(files[0]);
    }
  };

  const handleOpenImage = () => {
    if (inputFile?.current && !loading) {
      inputFile?.current?.click();
    }
  };

  return (
    <>
      {type === 'onBoarding' && (
        <BoxContent css={{ textAlign: 'initial' }}>
          <WrapperPicture onClick={handleOpenImage}>
            <Icon
              type="icon-profile"
              img={imgProfile || avatarIcon}
              onClick={handleOpenImage}
              alt=""
            />
            {loading && (
              <WrapperLoading>
                <Spinner
                  className="spinner"
                  css={{ alignSelf: 'center', margin: 'auto 0' }}
                  type="xs"
                />
              </WrapperLoading>
            )}
          </WrapperPicture>
          <InputImg ref={inputFile} type="file" accept="image/*" onChange={handleSetImage} />
        </BoxContent>
      )}
      {type === 'settings' && (
        <>
          <Avatar type="mid" img={imgProfile || avatarUrl} name={finalName} />
          <AddPicture onClick={handleOpenImage}>
            <InputImg
              ref={inputFile}
              type="file"
              accept="image/*"
              onChange={handleSetImage}
              id="avatarUrl"
              name="avatarUrl"
              placeholder="avatarUrl"
            />
            <img src={editIcon} alt="edit-icon" />
            <p>{`${avatarUrl ? 'Change' : 'Add'} profile photo`}</p>
          </AddPicture>
        </>
      )}
    </>
  );
};

ChangeAvatar.defaultProps = {
  loading: false,
  save: false,
};

export default ChangeAvatar;
