import React, { useCallback, useState } from 'react';
import { FileButton, ModalContainer, ModalContent, ModalContentContainer } from './styled';
import { FlexContainer, Typography } from '../../styled';
import { FileItem } from './FileItem';
import { IconContainer } from '../styled';
import { UploadIcon } from '../icons/UploadIcon';
import Button from '../../../../shared/components/Button/Button';

interface ModalDragAndDropProps {
  onClose: any;
  // eslint-disable-next-line no-unused-vars
  onFileDrop: (files: any) => void;
}

export const ModalDragAndDrop = ({ onClose, onFileDrop }: ModalDragAndDropProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const handleDragEnter = useCallback((e: any) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e: any) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  }, []);

  const handleDragOver = useCallback((e: any) => {
    e.preventDefault();
  }, []);

  const handleFileInput = (e: any) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (index: any) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  const handleConfirmUpload = () => {
    onFileDrop(selectedFiles);
    onClose();
  };

  const handleContainerClick = (e: any) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalContainer onClick={handleContainerClick}>
      <ModalContent
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{ border: isDragging ? '2px dashed red' : '1px solid transparent' }}
      >
        <ModalContentContainer>
          {!selectedFiles.length && (
            <>
              <IconContainer>
                <UploadIcon />
              </IconContainer>
              <Typography size="displayXSmall" weight="500" color="labelLight" position="center">
                Drag your Document here <br />
                to start uploading
              </Typography>
              <Typography size="displayXSmall" weight="500" color="labelLight" position="center">
                OR
              </Typography>
              <label htmlFor="fileInput">
                <input
                  type="file"
                  id="fileInput"
                  accept=".pdf, .doc, .docx"
                  style={{ display: 'none' }}
                  onChange={handleFileInput}
                  multiple
                />
                <FileButton as="span">Browse Files</FileButton>
              </label>
            </>
          )}
          {selectedFiles.length > 0 && (
            <>
              <Typography weight="500" color="labelLight" position="center">
                The files will be saved in the project for experts to see
              </Typography>
              <FlexContainer gap="0_75" wrap justify="center">
                {selectedFiles.map((file: any, index) => (
                  <FileItem name={file.name} onDelete={() => handleRemoveFile(index)} />
                ))}
              </FlexContainer>
              <Button
                kind="organization"
                title="Save Files"
                handleClick={handleConfirmUpload}
                width="auto"
                size="medium"
              />
            </>
          )}
        </ModalContentContainer>
      </ModalContent>
    </ModalContainer>
  );
};
