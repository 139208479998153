import { useContext } from 'react';
import AppContext from '../context/app/AppContext';

const useGetUserType = (): string => {
  const { state } = useContext(AppContext);
  const { userData } = state as any | undefined;
  const { userType } = userData;

  if (userType) return userType;

  return '';
};

export default useGetUserType;
