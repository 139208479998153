import React, { useState, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import Icon from '../Icon/Icon';
import OptionsList from './OptionsList';
import { MenuModel, Resources, Knowledge } from '../../interfaces/interfaces';
import editIcon from '../../../assets/images/icons/edit-light.svg';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import optionsIcon from '../../../assets/images/icons/options.svg';
import { Wrapper, BoxHover } from './styles';
import { UserService } from '../../../services/user.service';
import { KnowledgeService } from '../../../services/knowledge.service';
import AppContext from '../../context/app/AppContext';
import ModalContent from '../ModalContent/ModalContent';
import ModalKB from '../ModalKB/ModalKB';
import ModalDeleteConfirmation from '../ModalDeleteConfirmation/ModalDeleteConfirmation';

interface OptionsPopoverModel {
  actionOptions?: () => void;
  actionModalClose?: () => void;
  dataResource?: Resources;
  dataKnowledge?: Knowledge;
};

type OptionsPopoverProps = OptionsPopoverModel &
  React.ComponentProps<typeof Wrapper> & {
  };

const OptionsPopover: React.FunctionComponent<OptionsPopoverProps> = (
  { dataResource, dataKnowledge, actionOptions, actionModalClose }) => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const { authenticationToken, deleteItems } = state as any;
  const isResource = Boolean(dataResource) && !dataKnowledge;
  const isKB = Boolean(dataKnowledge) && !dataResource;

  const handleHoverOpen = () => {
    setOpen(true);
  };

  const handleHoverClose = () => {
    setOpen(false);
  };

  const actionDelete = useCallback(async (idResource: any, idKnowledge: any) => {
    const params = { authenticationToken, id: idResource };
    let results;
    if (idResource && !idKnowledge) {
      results = await UserService.deleteContent(params);
    }

    if (!idResource && idKnowledge) {
      results = await KnowledgeService.deleteKB({ ...params, id: idKnowledge });
    }

    if (!results?.fail) {
      await toast.success('Delete success');

      if (idKnowledge) {
        await dispatch({ type: 'SET_DELETE_ITEMS', payload: deleteItems.concat(idKnowledge) });
        await setOpenDelete(false);
        await dispatch({ type: 'SET_RELOAD', payload: true });
      }

      if (actionOptions) {
        actionOptions();
      }
    } else {
      toast.error(results?.message);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, actionOptions]);

  const options: MenuModel[] = [
    {
      text: 'Edit',
      icon: editIcon,
      action: () => setOpenModal(true),
    },
    {
      text: 'Delete',
      icon: deleteIcon,
      action: () => setOpenDelete(true),
    }
  ];

  const handleOptions = (event: any) => {
    event?.stopPropagation();

    setOpen(false);

    if (actionOptions) {
      actionOptions();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenDelete(false);

    if (actionModalClose) {
      actionModalClose();
    }
  };

  return (
    <Wrapper className='wrapper-menu'>
      <BoxHover
        className='menu-hover'
        onClick={(event:any) => event?.stopPropagation()}
        onMouseEnter={handleHoverOpen}
        onMouseLeave={handleHoverClose}
      >
        <Icon
          type={dataResource ? 'options-resource' : 'options-kb'}
          img={optionsIcon}
          width='4px'
          height='16px'
          alt="Option"
        />
        <OptionsList
          data={options}
          open={!open}
          clickEvent={(e:any) => handleOptions(e)}
        />
      </BoxHover>
      <ModalContent
        isEdit
        isOpen={openModal && isResource}
        dataResource={dataResource}
        actionContinue={actionOptions}
        actionClose={() => handleCloseModal()}
      />
      <ModalKB
        isEdit
        isOpen={openModal && isKB}
        dataKB={dataKnowledge}
        actionContinue={actionOptions}
        actionClose={() => handleCloseModal()}
      />
      <ModalDeleteConfirmation
        title='Delete Knowledge Playlist?'
        buttonText='Delete'
        isOpen={openDelete}
        actionContinue={() => actionDelete(dataResource?.id, dataKnowledge?.id)}
        actionClose={() => handleCloseModal()}
      />
    </Wrapper>
  );
};

OptionsPopover.defaultProps = {
  dataKnowledge: undefined,
  dataResource: undefined,
  actionOptions: undefined,
  actionModalClose: undefined
};

export default OptionsPopover;
