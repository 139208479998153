import * as Accordion from '@radix-ui/react-accordion';
import { styled, keyframes } from '../../../config/theme';

export const AccordionArrow = styled('div', {
  transition: 'transform 300ms',
});

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const close = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

export const AccordionDiv = styled(Accordion.Root, {
  width: '100%',
  backgroundColor: '$bg',
  border: '0.025rem solid hsl(233, 27%, 88%, 1)',
  borderRadius: '$radius03',
  position: 'relative',
  padding: '1rem 1.25rem',
  height: 'fit-content',
  marginBottom: '$spaceSmall',
  '&:last-child': {
    marginBottom: '0px',
  },

  '@bp3': {
    minWidth: 259,
  },
  variants: {
    kind: {
      tabsMobile: {
        position: 'absolute',
        top: 0,
        padding: '0',
        backgroundColor: 'white',
        h3: {
          padding: '0 $spaceSmall',
          fontSize: '$bodyCard',
          fontWeight: '$weight500',
        },
        '[role=tablist]': {
          borderTop: '1px solid $colors$line',
          margin: 0,
        },
      },
      menuList: {
        padding: '0',
        backgroundColor: 'white',
        border: '0',
        display: 'none',
        marginY: '$spaceNone',
        h3: {
          fontSize: '$bodyCard',
        },
        '& .btn-event': {
          padding: '0 $spaceSmall',
        },
        '@bp2': {
          display: 'block',
        },
      },
    },
  },
  // boxShadow: '0 2px 10px $line',
});

export const AccordionItem = styled(Accordion.Item, {
  overflow: 'hidden',
  marginTop: 1,
});

export const AccordionTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  backgroundColor: 'transparent',
  padding: '0',
  height: 45,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  img: {
    margin: 0,
  },
  // boxShadow: '0 1px 0 gray',
  // '&[data-state="closed"]': { backgroundColor: 'white' },
  // '&[data-state="open"]': { backgroundColor: 'white' },
});

export const AccordionHeader = styled(Accordion.Header, {
  all: 'unset',
  display: 'flex',
  height: 45,
  'button.btn-event': {
    margin: 0,
  },
  ':hover': {
    backgroundColor: '$input',
  },
  button: {
    height: 'auto',
  },
});

export const AccordionContent = styled(Accordion.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': { animation: `${open} 300ms ease-out forwards` },
  '&[data-state="closed"]': { animation: `${close} 300ms ease-out forwards` },
});
