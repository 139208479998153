export const dataFilter = [
  {
    value: 'content',
    name: 'Content',
  },
  {
    value: 'author',
    name: 'People',
  }
];

export const orderByFilter = [
  {
    value: 'date',
    name: 'Latest',
  },
  {
    value: 'relevant',
    name: 'Relevance',
  }
];

export const dataFilterContent = [
  {
    value: 'all',
    name: 'All',
  },
  {
    value: 'knowledge',
    name: 'Knowledge',
  },
  {
    value: 'text',
    name: 'Text',
  },
  {
    value: 'video',
    name: 'Video',
  },
  {
    value: 'images',
    name: 'Images',
  },
  {
    value: 'audio',
    name: 'Audios',
  },
  {
    value: 'others',
    name: 'Others',
  },
];
