import React, { FC } from 'react';
import WidgetLoad from '../ShimmerComponent/WidgetLoad';
import Accordion from "../Accordion/Accordion";
import { Widget, WidgetTitle, WidgetBody, WidgetFooter } from './styles';
import useWindowSize from '../../hooks/useWindowSize';

interface WidgetAccordionModel {
  title: string;
  body?: JSX.Element | JSX.Element[];
  footer?: JSX.Element | JSX.Element[];
  loading?: React.SetStateAction<boolean>;
};

type WidgetAccordionProps = WidgetAccordionModel &
  React.ComponentProps<typeof Widget> & {
    css?: any;
  };

const WidgetAccordion: FC<WidgetAccordionProps> = ({ title, body, footer, css, loading }) => {
  const { width } = useWindowSize();
  if (loading) {
    return (<WidgetLoad width={width} />);
  }

  if (width < 720) {
    return (
      <Accordion
        header={(
          <WidgetTitle noMargin>
            {title}
          </WidgetTitle>
        )}
        type='single'
      >
        <WidgetBody>
          {body}
        </WidgetBody>
        <WidgetFooter>
          {footer}
        </WidgetFooter>
      </Accordion>
    );
  }

  return (
    <Widget css={css}>
      <WidgetTitle>
        {title}
      </WidgetTitle>
      <WidgetBody>
        {body}
      </WidgetBody>
      <WidgetFooter>
        {footer}
      </WidgetFooter>
    </Widget>
  );
};

WidgetAccordion.defaultProps = {
  css: undefined,
  body: undefined,
  footer: undefined,
  loading: false,
};

export default WidgetAccordion;
