import React from 'react';
import { BreathingComponents } from '../../ShimmerComponent/styles';
import { getRandomWidth, keyExtractor } from '../../../utils/helper';
import { FlexContainer } from '../../../../screens/Organization/styled';

interface LoadingCellProps {
  id: string;
}

export const LoadingCell = ({ id }: LoadingCellProps) => {
  const getLoadingCell = () => {
    if (id === 'memberName') {
      return (
        <FlexContainer align="center" gap="0_25">
          <BreathingComponents key={keyExtractor()} className="circle" height={35} width={35} />
          <BreathingComponents
            key={keyExtractor()}
            className="tab"
            height={15}
            width={getRandomWidth(200, 300)}
          />
        </FlexContainer>
      );
    }
    if (id === 'name') {
      return (
        <BreathingComponents
          key={keyExtractor()}
          className="tab"
          height={15}
          width={getRandomWidth(350, 450)}
        />
      );
    }
    if (id === 'progress') {
      return <BreathingComponents key={keyExtractor()} className="tab" height={15} width={60} />;
    }
    if (id === 'status') {
      return <BreathingComponents key={keyExtractor()} className="tab" height={24} width={95} />;
    }
    if (id === 'credits') {
      return (
        <BreathingComponents
          key={keyExtractor()}
          className="tab"
          height={15}
          width={getRandomWidth(20, 30)}
        />
      );
    }
    if (id === 'otherButton') {
      return <BreathingComponents key={keyExtractor()} className="tab" height={32} width={60} />;
    }
    if (id === 'button') {
      return <BreathingComponents key={keyExtractor()} className="tab" height={32} width={120} />;
    }
    if (id === 'delButton') {
      return <BreathingComponents key={keyExtractor()} className="tab" height={32} width={32} />;
    }
    return (
      <BreathingComponents
        key={keyExtractor()}
        className="tab"
        height={15}
        width={getRandomWidth(100, 150)}
      />
    );
  };
  return getLoadingCell();
};
