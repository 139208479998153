import { styled } from '../../../config/theme';

export const Widget = styled('div', {
  width: '100%',
  height: 'auto',
  flexDirection: 'column',
  padding: '$spaceLarge',
  border: '0.025rem solid hsl(233, 27%, 88%, 1)',
  borderRadius: '$radius1',
  position: 'relative',
  marginBottom: '$spaceLarge',

  '@bp2': {
    padding: '1.5rem',
  },
  '@bp3': {
    padding: '$sizes$2 1.5rem',
    marginTop: 0,
  },
  '@bp4': {
    padding: '$sizes$2',
  },
  '&.who-to-follow': {
    marginBottom: '$spaceLarge',
    '@bp2': { marginBottom: '0px' },
    '@bp3': { marginBottom: '$spaceLarge' }
  },
  '&.knowledge': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '$spaceLarge',
    '@bp2': { marginBottom: '0px' },
    '@bp3': { marginBottom: '$spaceLarge' }
  },
  '& .load-data-section .load-more': {
    marginTop: '$spaceLarge',
  },
  variants: {
    kind: {
      'new-people': {
        fontSize: '$displayXXSmall',
        justifyContent: 'center',
        textAlign: 'initial',
        display: 'flex',
        marginBottom: '0px',
  
        '@bp2': {
          textAlign: 'center'
        },
        '@bp3': {
          textAlign: 'initial',
          marginBottom: '$spaceLarge',
        },
      },
      'feed-desktop': {
        display: 'none',
        marginTop: 0,
        '@bp3': { display: 'flex'}
      },
      'feed-home': {
        display: 'flex',
        marginBottom: '0px',
        '@bp3': { display: 'none'}
      },
      'feed-kb-margin': {
        display: 'flex',
        marginBottom: '0px',
        marginTop: '$spaceLarge',
        '@bp3': { display: 'none'}
      },
      'feed-link': {
        borderRadius: '12px',
        padding: '0.6rem $sizes$2',
        display: 'none',
        order: 100,
        '@bp3': { display: 'flex'}
      }
    }
  }
});

export const WidgetTitle = styled('h3', {
  fontWeight: '$weight700',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing3',
  color: '$offBlack',
  marginBottom: '$spaceLarge',
  '@bp3': {
    fontSize: '$displayXSmall',
  },
  variants: {
    noMargin: {
      true: {
        margin: 0
      }
    }
  }
});

export const WidgetText = styled('p', {
  fontWeight: 'normal',
  fontSize: '$bodyCard',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  color: '$offBlack',
  marginBottom: '$spaceLarge',
  variants: {
    type: {
      language: {
        fontSize: '$bodySmall',
        marginRight: '2rem',
        marginBottom: '$spaceSmall',
      },
      'two-columns': {
        fontSize: '$bodySmall',
        marginRight: '$spaceSmall',
        marginBottom: '$spaceSmall',
        minWidth: '4.375rem'
      }
    }
  }
});

export const Wrapper = styled('div', {

  '@bp3': {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

export const WidgetBody = styled('div', {
});

export const WidgetFooter = styled('div', {
});

export const DivWidgets = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',

  '@bp2': {
    gridTemplateColumns: '1fr 1fr',
    gap: 10,
  },
  '@bp3': {
    gridTemplateColumns: '1fr',
    gap: 0,
  },

  variants: {
    kind: {
      onboarding: {
        minHeight: 500,
        '@bp6': {
          minHeight: '80vh',
        },
      },
      normal: {
        display: 'grid',
      },
      desktop: {
        display: 'none',
        '@bp3': {
          display: 'grid',
        }
      },
      mobile: {
        display: 'grid',
        '@bp3': {
          display: 'none',
          gap: '$spaceSmall',
        }
      },
      'mobile-kb': {
        display: 'grid',
        '@bp2': {
          gridTemplateColumns: '1fr',
          gap: 0
        },
        '@bp3': {
          display: 'none !important',
        }
      }
    },
    showPlaylist: {
      true: {
        display: 'grid',
        '@bp3': {
          gridTemplateColumns: '1fr 1fr',
        },
      }
    }
  }
});

export const DivWidgetsSidebar = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '0px',
  '@bp2': {
    gridTemplateColumns: '1fr 1fr',
    gap: '$spaceSmall',
  },
  '@bp3': {
    gridTemplateColumns: '1fr',
    gap: '0px'
  }
});
