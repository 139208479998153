import { styled } from '../../../config/theme';

export const WrapperLinkPreview = styled('div', {
  width: '100%',
  'a': {
    color: '$offBlack',
    cursor: 'pointer',
    
    fontWeight: 700,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
});

export const BoxTooltip = styled('div', {
  display: 'flex',
  alignItems: 'center',
  'img': {
    margin: '0 0.625rem 0 0',
    borderRadius: '50%'
  },
  '& span': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 300
  },

});
