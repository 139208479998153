import React, { createContext } from 'react';
import {
  PaymentData,
  BillingData,
  OrganizationInfo,
  MemberData,
} from '../../interfaces/interfaces';

export interface ContextProps {
  credits: number;
  setCredits: React.Dispatch<React.SetStateAction<number>>;
  paymentsInformation: PaymentData[];
  setPaymentsInformation: React.Dispatch<React.SetStateAction<PaymentData[]>>;
  paymentInformation: PaymentData;
  setPaymentInformation: React.Dispatch<React.SetStateAction<PaymentData>>;
  billingInformation: BillingData;
  setBillingInformation: React.Dispatch<React.SetStateAction<BillingData>>;
  organizationInformation: OrganizationInfo;
  setOrganizationInformation: React.Dispatch<React.SetStateAction<OrganizationInfo>>;
  members: MemberData[];
  setMembers: React.Dispatch<React.SetStateAction<MemberData[]>>;
}

const OrganizationContext = createContext<ContextProps>({} as ContextProps);

export default OrganizationContext;
