import React from 'react';
import { CardWrapper } from './styled';

interface CardModel {};

type CardModelProps = CardModel &
  React.ComponentProps<typeof CardWrapper> & {
  className?: string;
  type?: string;
  disabled?: boolean;
  notBio?: boolean;
  css?: any;
};

const Card: React.FunctionComponent<CardModelProps> = ({
  children, type, disabled, css, notBio, className
}) => (
  <CardWrapper className={className} css={css} type={type} disabled={disabled} notBio={notBio}>
    {children}
  </CardWrapper>
);

Card.defaultProps = {
  type: undefined,
  disabled: false,
  notBio: false,
  css: undefined,
  className: undefined,
};

export default Card;
