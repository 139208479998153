import React, {useState} from 'react';

interface XIconProps {
  color?: string;
}

const XIcon: React.FC<XIconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M4 5.00002L12.5161 13.5161M4 13.5161L12.5161 5"
      stroke={color}
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const XIconWrapper = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{cursor:'pointer'}}
    >
      <XIcon color={isHovered ? '#A4D41B' : '#6E7191'} />
    </div>
  );
};

XIcon.defaultProps = {
  color: '#6E7191',
};
