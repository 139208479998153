import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { InfoLabel, SubTitles } from './styled';
import Button from '../../../../shared/components/Button/Button';
import ArrowIcon from '../../../../assets/images/icons/arrow-right-white.svg';
import { useExpert } from '../../../../shared/hooks/useExpert';
import { InputForm } from '../../../../shared/components/Input/style';
import { TextareaForm } from '../../../../shared/components/Input/TextareaForm';
import { Error } from '../../../../shared/components/Forms/InputError/styled';

const Step1Schema = Yup.object().shape({
  projectName: Yup.string().required('Project Name is required'),
  description: Yup.string()
    .required('Description is required')
    .min(200, 'Description must be at least 200 characters'),
});

export const Step1 = ({ nextStep }: any) => {
  const { expert, handleChangeExpert } = useExpert();

  const handleSubmit = async () => {
    nextStep();
  };

  return (
    <Formik
      initialValues={{ projectName: '', description: '' }}
      validationSchema={Step1Schema}
      validateOnMount
      onSubmit={(values: any, { setSubmitting }: FormikHelpers<any>) => {
        handleSubmit();
        setSubmitting(false);
      }}
    >
      {({ isValid, errors, touched, setFieldValue }) => (
        <Form>
          <SubTitles>Let’s give your project a name</SubTitles>
          <InputForm
            css={{
              marginTop: '$spaceLarge',
              marginBottom: '$spaceXLarge',
            }}
            value={expert.projectName}
            id="projectName"
            name="projectName"
            placeholder="Project Name"
            onChange={(e: any) => {
              handleChangeExpert(e.target.value, 'projectName');
              setFieldValue('projectName', e.target.value);
            }}
          />
          <SubTitles>What insights are you looking to acquire? </SubTitles>
          <InfoLabel>Add as much information as you can. (min. 200 characters)</InfoLabel>
          <TextareaForm
            css={{
              marginBottom: '$spaceXXLarge',
              marginTop: '$spaceLarge',
            }}
            value={expert.description}
            id="description"
            name="description"
            placeholder="Description"
            minlength="200"
            errors={errors}
            onChange={(e: any) => {
              handleChangeExpert(e.target.value, 'description');
              setFieldValue('description', e.target.value);
            }}
          />
          {errors.description && touched.description ? (
            <Error css={{ marginTop: '-$spaceXXLarge', marginBottom: '$spaceXXLarge' }}>
              {errors.description}
            </Error>
          ) : (
            <Error
              css={{
                color: 'transparent',
              }}
            >
              space
            </Error>
          )}
          <Button
            css={{ width: '100%', maxWidth: '100%', marginBottom: '$spaceLarge' }}
            title="Next"
            iconCustom={ArrowIcon}
            iconPosition="right"
            type="submit"
            renderProps={{
              disabled: !isValid,
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
