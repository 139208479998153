import React from 'react';

export const AddIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8.13867 2.44531V13.8342" stroke="#FCFCFC" strokeWidth="3" strokeLinecap="round" />
    <path
      d="M2.44434 8.13965L13.8332 8.13965"
      stroke="#FCFCFC"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
