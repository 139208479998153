import React from "react";
import MetaTags from "../../../shared/components/MetaTags/MetaTags";
import { Knowledge } from "../../../shared/interfaces/interfaces";

interface MetaTagsProps {
  playlist: Knowledge;
}

const PlaylistDetailMetatags: React.FunctionComponent<MetaTagsProps> = ({ playlist }) => {
  const { title, created_by_name: createdByName, created_by_avatar: createdByAvatar,
          created_by_slug: createdBySlug, slug } = playlist.attributes;
  const pageTitle = `Gathersight: ${title} from ${createdByName}`;
  const description = `${title}: a unique knowledge playlist containing a collection
    of articles and podcasts. More on Gathersight, the knowledge network.`;
  const canonical = `https://www.gathersight.com/playlists/${createdBySlug}/playlists/${slug}`;

  return (
    <MetaTags
      title={pageTitle}
      description={description}
      canonical={canonical}
      image={createdByAvatar}
      name={title}
    />
  );
};

export default PlaylistDetailMetatags;