import React, { useContext } from 'react';
import { RecordingActionsContainer, StopRecordingButton } from '../styles';
import PauseRecording from './PauseRecording';
import { StopRecordingIcon } from './icons/StopRecordingIcon';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';

interface VideoActionsProps {
  handleStopCaptureClick: () => void;
  handlePauseCaptureClick: () => void;
  handleResumeCaptureClick: () => void;
}

const StopRecording = ({
  handleStopCaptureClick,
  handlePauseCaptureClick,
  handleResumeCaptureClick,
}: VideoActionsProps) => {
  const { status } = useContext(VideoRecordingContext);
  return (
    <RecordingActionsContainer>
      <PauseRecording
        handlePauseCaptureClick={handlePauseCaptureClick}
        handleResumeCaptureClick={handleResumeCaptureClick}
      />
      <StopRecordingButton onClick={handleStopCaptureClick} isPaused={status === 'paused'}>
        <StopRecordingIcon width={20} height={20} />
      </StopRecordingButton>
    </RecordingActionsContainer>
  );
};

export default StopRecording;
