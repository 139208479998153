import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  PageLinkContainer,
  LinkFooter,
  LinkFooterA
} from './styled';
import { xGatherSightProfile } from '../../utils/globalText';
import xLogo from '../../../assets/images/icons/x-icon.svg';
import Icon from '../Icon/Icon';

interface LinksFooterModel { }

type LinksProps = LinksFooterModel &
  React.ComponentProps<typeof PageLinkContainer> & {
    css?: any;
    type?: string;
  };

const LinksFooter: React.FC<LinksProps> = ({ type, css }) => {
  const { pathname } = useLocation();

  const isAbout = pathname === '/about';

  return (
  <PageLinkContainer css={css} type={type} >
    <LinkFooter className='link-item' to='/about'>About</LinkFooter>

    <LinkFooterA className='link-item' href="https://gathersight.notion.site/Hello-and-thank-you-for-coming-15f8fba060dd4541a5bce70293da8cb0">
      Help
    </LinkFooterA>
    <LinkFooter className='link-item' to='/terms'>Terms</LinkFooter>
    <LinkFooter className='link-item' to='/privacy'>Privacy</LinkFooter>
    {isAbout && (<a href={xGatherSightProfile} target="_blank" rel="noopener noreferrer">
      <Icon img={xLogo} width='16' height='16' alt='Footer Link' />
    </a>)}
  </PageLinkContainer>
  );
};

LinksFooter.defaultProps = {
  type: undefined,
  css: undefined
};

export default LinksFooter;
