import { keyframes, styled } from '../../../../config/theme';

export const DatePickerContainer = styled('div', {
  '.react-datepicker-wrapper>.react-datepicker__input-container': {
    width: 'auto',
  },
  '.react-datepicker-wrapper>.react-datepicker__input-container>input': {
    background: 'transparent',
    width: '5.1rem',
    fontFamily: '$poppins',
    border: 'none',
    fontSize: '$bodyXSmall',
    color: '$monochromeBlack',
    lineHeight: '$lineHeight2',
    letterSpacing: '$letterSpacing2',
    fill: 'none',
    '&:focus': {
      outline: 'none',
    },
  },

  // Triangle color
  '.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after':
    {
      display: 'none',
    },

  // Container
  '.react-datepicker': {
    padding: '$spaceLarge $spaceLarge2',
    border: 'none',
    borderRadius: '$radius05',
    boxShadow: '$shadowBase',
  },

  // Header
  '.react-datepicker__header': {
    background: 'none',
    border: 'none',
  },
  // Navigation buttons
  '.react-datepicker__navigation': {
    top: '$spaceLarge2',
  },
  '.react-datepicker__navigation--previous ': {
    left: '$spaceLarge2',
  },
  '.react-datepicker__navigation--next ': {
    right: '$spaceLarge2',
  },
  // Text  description
  '.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header':
    {
      fontFamily: '$poppins',
      fontSize: '$displayXXSmall',
      fontWeight: '$weight600',
      lineHeight: '$lineHeight2',
      color: '$monochromeBlack400',
      marginBottom: '$spaceSmall',
    },
  '.react-datepicker__day-name': {
    fontFamily: '$poppins',
    fontSize: '$bodyListItem',
    fontWeight: '$weight500',
    width: '2rem',
  },
  '.react-datepicker__day': {
    fontFamily: '$poppins',
    fontSize: '$bodyListItem',
    color: '$monochromeBlack400',
    width: '2rem',
    height: '2rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.react-datepicker__day.react-datepicker__day--outside-month': {
    color: '$monochromeBlack200',
  },
  '.react-datepicker__day:hover': {
    background: '$monochromeWhite25',
    borderRadius: '100%',
  },
  '.react-datepicker__day--selected, .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today':
    {
      color: '$monochromeWhite',
      background: '$blurple',
      width: '2rem',
      height: '2rem',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
    },
});

export const SavedLabel = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.3rem 0.6rem',
  borderRadius: '$radius03',
  backgroundColor: '$green100',
});

const aniVertical = keyframes({
  '0%': {
    opacity: 0,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
});

// Definir la animación horizontal
const aniHorizontal = keyframes({
  '0%': {
    backgroundPosition: '-100% 0',
  },
  '100%': {
    backgroundPosition: '100% 0',
  },
});

export const SkeletonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const SkeletonBox = styled('div', {
  width: '100%',
  height: '70px',
  marginBottom: '20px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '$spaceXSmall2',
  borderRadius: '$radius05',
  background: '$monochromeBlack25',
  justifyContent: 'space-between',
  animation: `${aniVertical} 3s ease infinite, ${aniHorizontal} 3.5s linear infinite`,

  '&:nth-child(2)': {
    animationDelay: '0.5s',
  },

  '&:nth-child(3)': {
    animationDelay: '1s',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    padding: '$spaceXSmall2',
    borderRadius: '$radius075',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    animation: `${aniHorizontal} 3.5s linear infinite`,
    background: 'linear-gradient(to right, black 2%, #666666 18%, black 33%)',
    backgroundSize: '50%',
    mixBlendMode: 'overlay',
  },
});
