import React, { useCallback, useMemo, useState, useContext } from "react";
import { useTransition, animated, config } from 'react-spring';
import { useParams } from "react-router-dom";
// import file from '../../../../assets/images/icons/file-black.svg';
import fileActive from '../../../../assets/images/icons/file-blue.svg';
import stack from '../../../../assets/images/icons/stack.svg';
import stackActive from '../../../../assets/images/icons/stack-blue.svg';
import users from '../../../../assets/images/icons/users.svg';
import usersActive from '../../../../assets/images/icons/users-blue.svg';
import { Box, Tabs, TabsContent, TabsList, TabsTrigger } from "../../styles";
import Icon from "../../../../shared/components/Icon/Icon";
import LoadDataSection from "../../../../shared/components/LoadDataSection/LoadDataSection";
import { KnowledgeService } from "../../../../services/knowledge.service";
import useGetToken from "../../../../shared/hooks/useGetToken";
import { getKnowledgeContent } from "../../../../shared/utils/helper";
import CardResourcesList from '../../../../shared/components/CardResources/CardResourcesList';
import CardPeopleList from '../../../../shared/components/CardPeople/CardPeopleList';
import ResourcesListLoad from "../../../../shared/components/ShimmerComponent/ResourcesListLoad";
import ProvidersListLoad from "../../../../shared/components/ShimmerComponent/ProvidersListLoad";
import { BoxShimmer } from '../../../../shared/components/ShimmerComponent/styles';
import { Spinner } from '../../../../shared/components/Spinner/spinner';
import AppContext from "../../../../shared/context/app/AppContext";

const TAB_CONTENT = 'Content';
const TAB_AUTHOR = 'People';

interface SearchItemsProps {
  disableContent: boolean;
  setContentLength: React.Dispatch<React.SetStateAction<number>>;
  contentLength: React.SetStateAction<number>;
  setLoadingItems: React.Dispatch<React.SetStateAction<boolean>>;
  loadingItems: React.SetStateAction<boolean>;
}

const ItemsTabs = (props: SearchItemsProps) => {
  const { disableContent, setContentLength, contentLength,
          setLoadingItems, loadingItems } = props;
  const [typeData, setTypeData] = useState<string>('content');
  const { state } = useContext(AppContext);
  const { id, playlist_slug: KBId } = useParams();
  const idKb = id || KBId || '';
  const authenticationToken = useGetToken();
  const { reload } = state;
  const isContent = typeData === 'content';
  const isEmptyContent = Boolean(!contentLength);
  const transitions = useTransition(!isEmptyContent, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: config.stiff,
  });
  const getActiveIcon = (type:string, iconDefault:any) => {
    switch(type) {
      case 'content':
        return typeData === type ? stackActive : iconDefault;
      case 'author':
        return typeData === type ? usersActive : iconDefault;
      case 'transcripts':
        return typeData === type ? fileActive : iconDefault;
      default:
        return iconDefault;
    }
  };

  const loadContent = useCallback(async (page: number, total: number) => {
    if (idKb) {
      const data = await KnowledgeService.showItems({
        authenticationToken, page, limit: total, id: idKb, filterBy: 'content'
      });
      const result = await getKnowledgeContent(data, typeData);
      await setLoadingItems(false);

      return result;
    }

    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, typeData, idKb]);

  const LoadData = useMemo(() => (
    <LoadDataSection
      onAction={loadContent}
      component={isContent ? CardResourcesList : CardPeopleList}
      shimmerComponent={isContent ?
        <ResourcesListLoad total={3} /> : <ProvidersListLoad total={2} />}
      shimmerInfinity = {(
        <BoxShimmer type='center'>
          <Spinner className='spinner' type='xs' css={{ padding: '$spaceSmall' }} />
        </BoxShimmer>
      )}
      typeButton="outline"
      empty={isEmptyContent && !loadingItems}
      propsComponent={{ simpleGap: true }}
      removeDuplicated
      afterLoad={(e) => setContentLength(e)}
      reload={reload}
      isInfinite
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [isEmptyContent, loadingItems, isContent, disableContent, loadContent, reload]);

  return (
    <Tabs defaultValue='content' orientation='vertical' onValueChange={(e: string) => setTypeData(e)}>
      {transitions((styles, item) => item ? (
        <animated.div style={{ width: '100%', opacity: styles.opacity }}>
          <TabsList aria-label='User Settings'>
            <TabsTrigger value='content'>
              <Box direction='column'>
                <Icon
                  alt="Content"
                  img={getActiveIcon('content', stack)}
                  width='20px'
                  height='20px'
                />
                <span>{TAB_CONTENT}</span>
              </Box>
            </TabsTrigger>
            <TabsTrigger value='author'>
              <Box direction='column'>
                <Icon
                  alt="Author"
                  img={getActiveIcon('author', users)}
                  width='20px'
                  height='20px'
                />
                <span>{TAB_AUTHOR}</span>
              </Box>
            </TabsTrigger>
            {/* <TabsTrigger value='transcripts'>
                <Box direction='column'>
                  <Icon
                    img={getActiveIcon('transcripts', file)}
                    width='20px'
                    height='20px'
                  />
                  <span>Transcripts</span>
                </Box>
              </TabsTrigger> */}
          </TabsList>
        </animated.div>
      ) : null)}
      <TabsContent value='content'>{typeData === 'content' && LoadData}</TabsContent>
      <TabsContent value='author'>{typeData === 'author' && LoadData}</TabsContent>
    </Tabs>
  );
};

export default ItemsTabs;