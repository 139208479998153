import React, { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import ForgotPasswordSent from './PasswordEmailSent/PasswordEmailSent';
import PageContainer from '../../../../../shared/components/Container/PageContainer';
import Card from '../../../../../shared/components/Card/Card';
import { TabsContainer } from '../../../styled';
import { WrapperCard, BackButton, BackText, Container, Icon } from './styled';
import backArrow from '../../../../../assets/images/icons/arrow-back.svg';

const ForgotPassword = () => {
  const [sent, setSent] = useState<boolean>(false);

  return (
    <PageContainer layout='recoverPassword'>
      <Container>
        <TabsContainer>
          <WrapperCard css={sent ? { height: '19rem' } : { height: '29rem' }}>
            <Card>
              {sent ? <ForgotPasswordSent /> : <ForgotPasswordForm setSent={setSent}/>}
            </Card>
            <BackButton to='/authentication'>
              <Icon src={backArrow} alt="back" />
              <BackText>Go back to Login</BackText>
            </BackButton>
          </WrapperCard>
        </TabsContainer>
      </Container>
    </PageContainer>
  );
};

export default ForgotPassword;