import React, { useContext, useState } from 'react';
import ConfigurationGear from '../../../assets/images/icons/configuration-gear.svg';
import Icon from '../../../shared/components/Icon/Icon';
import { LinkText, SaveOrRecordContainer } from '../styles';
import Modal from '../../../shared/components/Modal/Modal';
import Card from '../../../shared/components/Card/Card';
import { TypographyH2 } from '../../Expert/styled';
import AudioInput from './AudioInput';
import VideoInput from './VideoInput';
import Button from '../../../shared/components/Button/Button';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import { ConfigButton } from '../RecordVideo/ConfigButton';
import { useLandscape } from '../../../shared/hooks/useLandscape';

const Configuration = ({ isMobile }: { isMobile?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLandscapeMobile } = useLandscape();

  const {
    width,
    setNewAudioTrack,
    setNewVideoTrack,
    newAudioTrack,
    setAudioTrack,
    newVideoTrack,
    setVideoTrack,
  } = useContext(VideoRecordingContext);

  const handleOpen = () => {
    setNewAudioTrack(undefined);
    setNewVideoTrack(undefined);
    setIsOpen(!isOpen);
  };

  const handleSave = () => {
    if(newAudioTrack) {
      setAudioTrack(newAudioTrack);
      setNewAudioTrack(undefined);
    }
    if(newVideoTrack) {
      setVideoTrack(newVideoTrack);
      setNewVideoTrack(undefined);
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      {width && width > 720 && !isMobile ? (
        <LinkText onClick={handleOpen}>
          <Icon
            img={ConfigurationGear}
            width="20"
            height="20"
            margin0
            alt="Recording configuration"
            css={{ marginRight: '8px' }}
          />
          <p>Recording Configuration</p>
        </LinkText>
      ) : (
        <ConfigButton onClick={handleOpen} />
      )}
      <Modal kindOverlay="gat-modal-overlay--blur" isOpen={isOpen} onClose={handleOpen}>
        <Card
          type={isLandscapeMobile ? 'recordingModalLandscape' : 'recordingModal'}
          css={{ display: 'flex', flexDirection: 'column' }}
        >
          <TypographyH2 lineHeight="$lineHeight15" letterSpacing="letterSpacing3" weight="600">
            Recording Configuration
          </TypographyH2>
          <AudioInput />
          <VideoInput />
          <SaveOrRecordContainer
            css={{ marginTop: 0, minWidth: isLandscapeMobile ? '380px' : undefined }}
            isLandscapeMobile={isLandscapeMobile}
          >
            <Button
              title="Save"
              css={{
                width: '265px',
                height: '40px',
              }}
              width={isMobile && !isLandscapeMobile ? 'full' : undefined}
              handleClick={handleSave}
            />
            <Button
              title="Cancel"
              kind="outline"
              css={{
                width: '265px',
                height: '40px',
              }}
              width={isMobile && !isLandscapeMobile ? 'full' : undefined}
              renderProps={{ onClick: handleOpen }}
            />
          </SaveOrRecordContainer>
        </Card>
      </Modal>
    </>
  );
};
Configuration.defaultProps = {
  isMobile: false,
};
export default Configuration;
