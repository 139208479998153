import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import ProjectsContext from "../../context/projects/ProjectsContext";
import AppContext from "../../context/app/AppContext";


export const useCloseSession = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const { setReset } = useContext(ProjectsContext);

  const closeSession = async () => {
    await dispatch({ type: 'RESET_STATE' });
    await dispatch({ type: 'SET_RELOAD', payload: true });
    await navigate('/');
    setReset(true);
  };

  return { closeSession };
};
