import React, { useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LayoutContainer } from '../../shared/components/Layouts/styles';
import PageContainer from '../../shared/components/Container/PageContainer';
import LoadDataSection from '../../shared/components/LoadDataSection/LoadDataSection';
import CardResourcesList from '../../shared/components/CardResources/CardResourcesList';
import ResourcesListLoad from '../../shared/components/ShimmerComponent/ResourcesListLoad';
import useGetCurrentUserID from '../../shared/hooks/getCurrentUserID';
import { HomeService } from '../../services/home.service';
import { getAuthorById } from '../../shared/utils/helper';
import { LayoutColEmptyProfile, LayoutColPeople, TimeOutBanner } from './styled';
import ProfileHeader from './ProfileHeader/ProfileHeader';
import ProfileContentEmpty from './ProfileContentEmpty/ProfileContentEmpty';
import { BoxShimmer } from '../../shared/components/ShimmerComponent/styles';
import { Spinner } from '../../shared/components/Spinner/spinner';
import AppContext from '../../shared/context/app/AppContext';
import useGetUser from '../../shared/hooks/useGetUser';
import useGetToken from '../../shared/hooks/useGetToken';
import ProfileMetaData from './MetaData/ProfileMetaData';
import ProfileSidebar from './ProfileSidebar/ProfileSidebar';
import useWindowSize from '../../shared/hooks/useWindowSize';

const COUNT_CONTENT = 4;
const RESPONSIVE = 980;

const Profile = () => {
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const [contentLength, setContentLength] = useState<number>(0);
  const [currentID, setCurrentID] = useState<any>(null);
  const userId = useGetCurrentUserID();
  const token = useGetToken();
  const { state } = useContext(AppContext);
  const { width } = useWindowSize();
  const { reload, isTimeOut } = state;
  const { slug } = useParams();
  const { userData, loadingUser: loading } = useGetUser({
    authenticationToken: token,
    slug,
    isProfile: true,
  });
  const isEmpty = Boolean(!contentLength);
  const isEmptyLoad = isEmpty && !loadingContent;
  const myProfile = userData?.id === userId;
  const paddingEmpty = isEmptyLoad ? { padding: '3rem 5rem' } : {};
  const validationWidgetInfo = userData ? Boolean(loading && token) : false;
  const directionEmpty = isEmptyLoad
    ? { '@bp2': { flexDirection: 'row' } }
    : {
        paddingTop: isEmpty || loadingContent || loading ? '3rem' : '0px',
        '@bp3': {
          gridTemplateColumns:
            validationWidgetInfo && contentLength > COUNT_CONTENT ? '1fr 1fr' : '0.5fr 1fr',
        },
        '@bp3_5': { gridTemplateColumns: '0.4fr 1fr' },
      };
  const orderEmpty = isEmptyLoad ? { order: 0, '@bp3': { order: 1 } } : {};
  const orderEmptySidebar = isEmptyLoad ? { order: 1, '@bp3': { order: 0 } } : {};
  const newTags = userData?.attributes?.expertise?.data.map((item: any) => ({
    ...item?.attributes?.tag?.data,
    expertise_id: item?.id,
  }));

  const loadPeople = useCallback(
    async (page: number, total: number, filterBy?: string, filter?: string) => {
      if (userData?.id) {
        const data = await HomeService.getContents({
          authenticationToken: token,
          page,
          limit: total,
          filterBy,
          filter,
        });
        const result = getAuthorById(data, Number(userData?.id));
        setContentLength((prevData) =>
          currentID === userData?.id ? prevData + data.length : data.length,
        );
        setLoadingContent(false);
        setCurrentID(userData?.id);
        return result;
      }

      return [];
    },
    [token, userData, currentID],
  );

  // const LoadData = useMemo(() => (
  //   <LoadDataSection
  //     onAction={loadPeople}
  //     component={CardResourcesList}
  //     shimmerComponent={<ResourcesListLoad total={3} />}
  //     shimmerInfinity = {(
  //       <BoxShimmer type='center'>
  //         <Spinner className='spinner' type='xs' css={{ padding: '$spaceSmall' }} />
  //       </BoxShimmer>
  //     )}
  //     filterByProp="user_id"
  //     filterProp={userData?.id}
  //     typeButton="outline"
  //     empty={isEmpty && !loading}
  //     propsComponent={{
  //       hideFollow: true,
  //       isLazy: true,
  //       limitFeed: COUNT_CONTENT,
  //       hideSuggestions: width > RESPONSIVE,
  //     }}
  //     reload={reload}
  //     size={20}
  //     saveData
  //     isInfinite
  //     removeDuplicated
  //   />
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // ), [loadPeople, userData?.id, isEmpty, loading]);
  return (
    <PageContainer layout="profile">
      {userData && <ProfileMetaData user={userData} />}
      {isTimeOut && (
        <TimeOutBanner>
          <p>The service is temporarily unavailable. Sorry for the inconvenience. </p>
        </TimeOutBanner>
      )}
      <ProfileHeader
        userData={userData}
        loading={!userData?.id && loading}
        myProfile={myProfile}
      />
      <LayoutContainer
        direction="column"
        css={{
          minHeight: 'calc(100vh - 360px)',
          ...directionEmpty,
          '@bp4': {
            ...paddingEmpty,
          },
        }}
      >
        <LayoutColPeople
          col="3"
          type="sidebar-profile"
          isEmpty={isEmpty}
          css={{ ...orderEmptySidebar }}
        >
          <ProfileSidebar
            isEmpty={isEmptyLoad}
            data={newTags}
            loading={!userData?.id && loading}
            userId={userData?.id as string}
          />
        </LayoutColPeople>

        <LayoutColEmptyProfile
          col={`${isEmptyLoad && myProfile ? '12' : '9'}`}
          css={{
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignContent: 'baseline',
            paddingTop: myProfile ? '0rem' : '2.5rem',
            ...orderEmpty,
          }}
        >
          {isEmpty && myProfile && !loadingContent && (
            <ProfileContentEmpty myProfile={myProfile} />
          )}
          <LoadDataSection
            onAction={loadPeople}
            component={CardResourcesList}
            shimmerComponent={<ResourcesListLoad total={3} />}
            shimmerInfinity={
              <BoxShimmer type="center">
                <Spinner className="spinner" type="xs" />
              </BoxShimmer>
            }
            filterByProp="id"
            filterProp={userData?.id}
            typeButton="outline"
            empty={isEmpty && !loading}
            propsComponent={{
              hideFollow: true,
              isLazy: true,
              limitFeed: COUNT_CONTENT,
              hideSuggestions: width > RESPONSIVE,
              userDataTag: newTags,
              sameUser: myProfile,
              userId: userData?.id,
            }}
            reload={reload}
            size={20}
            saveData
            isInfinite
            removeDuplicated
          />
        </LayoutColEmptyProfile>
      </LayoutContainer>
    </PageContainer>
  );
};

export default Profile;
