import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageContainer from '../../../../../../shared/components/Container/PageContainer';
import Card from '../../../../../../shared/components/Card/Card';
import { TabsContainer, Title } from '../../../../styled';
import { WidgetTitle, Container, FormikForm } from '../styled';
import { WrapperCard } from './styled';
import { ForgotPasswordService } from '../../../../../../services/AuthService/AuthService';
import Button from '../../../../../../shared/components/Button/Button';
import ProcessAuthentication from '../../../ProcessAuthentication/ProcessAuthentication';
import AppContext from '../../../../../../shared/context/app/AppContext';
import PasswordWithValidations from '../../../../../../shared/components/passwordWithValidations/passwordWithValidations';

const FormSchema = Yup.object({
  newPassword: Yup.string().required('Required')
});

const ChangePassword = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);

  const handleSubmit = async (newPassword: string) => {
    const res = await ForgotPasswordService.updatePassword(newPassword);
    if (res.error) {
      navigate('/authentication');
      toast.error(res.error.message, {autoClose: 5000});
    } else {
      ProcessAuthentication({res, dispatch, navigate});
      toast('Password updated!', {autoClose: 5000});
    }
  };

  return (
    <PageContainer layout='recoverPassword'>
      <Container>
        <Title>Gathersight</Title>
        <TabsContainer>
          <WrapperCard css={{height: '29rem'}}>
            <Card>
              <WidgetTitle>Change your password</WidgetTitle>
              <Formik
                initialValues={{ newPassword: '' }}
                validationSchema={FormSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  await handleSubmit(values.newPassword);
                  setSubmitting(false);
                }}
              >
                {({
                  isSubmitting,
                }) => (
                  <FormikForm>
                    <PasswordWithValidations />
                    <Button kind="login" title="Send" type="submit"
                            renderProps={{disabled: isSubmitting}} loading={isSubmitting}
                            css={{width: '100%', marginTop: '$spaceMedium', maxWidth: '360px',
                                  height: '56px'}}/>
                  </FormikForm>
                )}
              </Formik>
            </Card>
          </WrapperCard>
        </TabsContainer>
      </Container>
    </PageContainer>
  );
};

export default ChangePassword;