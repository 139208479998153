import React from "react";
import MetaTags from "../../../shared/components/MetaTags/MetaTags";
import { replaceDashTitle, getUserName } from '../../../shared/utils/helper';
import { User } from "../../../shared/interfaces/interfaces";

interface MetaTagsProps {
  user: User;
}

const ProfileMetaData: React.FunctionComponent<MetaTagsProps> = ({ user }) => {
  const { username, biography, first_name: firstName, slug,
          last_name: lastName, avatar_url: avatarUrl } = user.attributes;
  const dataName = { username, first_name: firstName, last_name: lastName };
  const finalName = getUserName(dataName);
  const metaTitle = replaceDashTitle(`Opinion and Ideas from ${username} | Gathersight`);
  const canonical = `https://www.gathersight.com/profile/${slug}`;

  return (
    <MetaTags
      title={metaTitle}
      description={biography}
      canonical={canonical}
      name={finalName}
      image={avatarUrl} />
  );
};

export default ProfileMetaData;