import { PropertyValue } from '@stitches/react';
import { styled, fadeIn, keyframes } from '../../../config/theme';
import cameraIcon from '../../../assets/images/icons/camera.svg';

export const IconContainer = styled('div', {});

const rotate = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },

  to: {
    transform: 'rotate(360deg)',
  },
});

export const IconWrapper = styled('div', {
  display: 'flex',
  variants: {
    type: {
      inline: {
        display: 'inline-flex',
        verticalAlign: 'middle',
      },
      'options-kb': {
        cursor: 'pointer',
        padding: '0.625rem 1rem 0.625rem',
      },
      'options-resource': {
        marginLeft: 16,
        cursor: 'pointer',
      },
      'icon-profile': {
        cursor: 'pointer',
        position: 'relative',
        '& img': {
          width: '217px',
          height: '217px',
          borderRadius: '50%',
          margin: 0,
          backgroundColor: '$offWhite',
          verticalAlign: 'middle',
          objectFit: 'cover',
        },
        '&::before': {
          content: '',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: `url(${cameraIcon}) center / contain no-repeat`,
          backgroundColor: 'rgb(20, 19, 42)',
          backgroundSize: 'auto',
          borderRadius: '50%',
          transition: 'opacity .3s ease-in',
          opacity: '0.6',
        },
        '&:hover::before': {
          opacity: '0.3',
        },
      },
    },
  },
});
export const Img = styled('img', {
  fill: 'white',
  width: `${(value: PropertyValue<'width'>) => ({ width: value })}`,
  height: `${(value: PropertyValue<'height'>) => ({ height: value })}`,
  marginX: '$spaceXSmall',
  '@bp2': {
    marginX: '$spaceSmall',
  },
  variants: {
    margin0: {
      true: {
        marginX: '$spaceNone',
      },
    },
    marginL: {
      true: {
        marginLeft: '$spaceSmall',
        marginRight: '$spaceNone',
      },
    },
    marginR: {
      true: {
        marginLeft: '$spaceNone',
        marginRight: '$spaceSmall',
      },
    },
    button: {
      true: {
        '&:hover': {
          backgroundColor: '$blurple',
        },
      },
    },
    fadeIn: {
      true: {
        animation: `${fadeIn} 600ms`,
      },
    },
    loadingIconRotate: {
      true: {
        animation: `${rotate} 1s infinite`,
        animationTimingFunction: ' cubic-bezier(0.42, 0.2, 0.58, 1)',
      },
    },
  },
});
