import { styled } from '../../../../config/theme';

export const OptionUsageContainer = styled('div', {
  widows: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$spaceSmall',
  margin: '$spaceSmall',
  width: '$20',
  maxWidth: 400,
  '@bp4': {
    width: '$32',
  },
});

export const RoundedElipseContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$blurple',
  borderRadius: '$radius4',
  width: '$4',
  height: '$4',
});

export const OptionsUsageContainer = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto',
  justifyContent: 'center',
  justifyItems: 'center',
  alignContent: 'start',
  alignItems: 'start',
  '@bp2': {
    gridTemplateColumns: '1fr 1fr',
  },
});
