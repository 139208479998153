import React from 'react';
import { Column } from 'react-table';
import Status from '../ui/status';
import Avatar from '../ui/memberAvatar';

import VideoProgress from '../ui/progress';

interface OrganizationRow {
  name: string;
  memberName: {
    name: string;
    alt: string;
    avatar: string;
  };
  memberJobTitle: string;
  status: 'completed' | 'archived' | 'draft' | 'in_progress';
  progress?: {
    totalVideos: number;
    receivedVideos: number;
    progress: 'draft' | 'completed' | 'in_progress';
  };
  credits?: number;
}

export const organizationColumns: Column<OrganizationRow>[] = [
  {
    Header: 'Project',
    accessor: 'name',
    minWidth: 350,
  },
  {
    Header: 'Name',
    accessor: 'memberName',
    Cell: ({ value }: { value: any }) => (
      <Avatar name={value?.name} alt={value?.alt} avatar={value?.avatar} />
    ),
  },
  {
    Header: 'Job Title',
    accessor: 'memberJobTitle',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }: { value: any }) => <Status state={value} />,
  },
  {
    Header: 'Credits',
    accessor: 'credits',
  },
  {
    Header: 'Progress',
    accessor: 'progress',
    Cell: ({ value }: { value: any }) => {
      if (!value) {
        return <div>No progress data</div>;
      }
      return (
        <VideoProgress
          totalVideos={value.totalVideos}
          receivedVideos={value.receivedVideos}
          progress={value.progress}
        />
      );
    },
  },
];
