import React, { useContext, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { VideoRecorderContainer } from '../styles';
import VideoActions from './VideoActions';
import UpperBanner from './UpperBanner';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import VideoCaption from './VideoCaption/VideoCaption';
import Video from '../../../shared/components/Video/Video';
import { useRecordingVideos } from '../../../shared/hooks/useRecordingVideos';
import { BreathingComponents } from '../../../shared/components/ShimmerComponent/styles';
import { keyExtractor } from '../../../shared/utils/helper';
import NoDeviceError from './NoDeviceError';
import useUploadVideo from '../../../shared/hooks/RecordingVideos/useUploadVideo';
import { useDeviceSize } from '../../../shared/hooks/useDeviceSize';
import { useLandscape } from '../../../shared/hooks/useLandscape';

const RecordingVideos = () => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { mimeType } = useRecordingVideos();
  const [isWebcamActive, setIsWebcamActive] = useState(false);
  const { isMobile, isTablet } = useDeviceSize();
  const { isLandscapeMobile } = useLandscape();

  const {
    status,
    setStatus,
    recordedChunks,
    setRecordedChunks,
    setVideoHeight,
    fullscreen,
    blob,
    setBlob,
    videoTrack,
    audioTrack,
    error,
    setFullscreen,
    setError,
    webcamRef,
    stopCapturing,
    setStopCapturing,
    audioInputDevices,
    videoInputDevices,
    listDevices,
  } = useContext(VideoRecordingContext);
  const { handleUploadVideo, setVideoUrl } = useUploadVideo();
  const recordingStatus = ['init', 'countdown', 'capturing', 'timeLimit', 'paused'].includes(
    status,
  );

  useEffect(() => {
    if(audioInputDevices.length === 0 || videoInputDevices.length === 0){
      listDevices();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const VideoConstraints: MediaTrackConstraints = {
    deviceId: videoTrack?.value || 'default',
  };

  const AudioConstraints: MediaTrackConstraints = {
    deviceId: audioTrack?.value || 'default',
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
  };

  const handleUserMedia = () => {
    setStatus('init');
    setIsWebcamActive(true);
    const newVideoHeight: number = webcamRef.current?.video?.clientHeight || 0;
    setVideoHeight(newVideoHeight);
    if (videoInputDevices[0].label === '' || audioInputDevices[0].label === '') {
      listDevices();
    }
  };

  useEffect(() => {
    if (blob) {
      setVideoUrl('video-replay');
      // when click save button from paused video
      if (stopCapturing) {
        handleUploadVideo();
        setStopCapturing(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  const getBlob = () => new Blob(recordedChunks, { type: mimeType });

  const handleStopCaptureClick = React.useCallback(() => {
    if (status !== 'capturing' && status !== 'paused') {
      throw new Error('MediaRecorder is not recording');
    }
    mediaRecorderRef?.current?.stop();
    setStatus('captured');
    setFullscreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks, status]);

  const handlePauseCaptureClick = React.useCallback(() => {
    if (status !== 'capturing') {
      throw new Error('MediaRecorder is not recording');
    }
    mediaRecorderRef?.current?.pause();
    setStatus('paused');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks, status]);

  const handleResumeCaptureClick = React.useCallback(() => {
    mediaRecorderRef?.current?.resume();
    setStatus('capturing');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks]);

  useEffect(() => {
    if (status === 'timeLimit') {
      mediaRecorderRef?.current?.stop();
      setTimeout(() => {
        setStatus('captured');
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks],
  );

  const handleStartCaptureClick = React.useCallback(() => {
    if (webcamRef.current?.stream) {
      setStatus('capturing');
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, { mimeType });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, mediaRecorderRef]);

  const handleStartClick = React.useCallback(() => {
    setStatus('countdown');
    setTimeout(() => {
      handleStartCaptureClick();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === 'captured' && recordedChunks.length) {
      setBlob(getBlob());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, recordedChunks]);

  useEffect(
    () => {
      if (stopCapturing) handleStopCaptureClick();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stopCapturing],
  );

  const handleError = (e: string | DOMException) => {
    setError('Please allow access to your webcam and microphone');
    setStatus('error');
    setIsWebcamActive(false);
    if (videoRef.current) videoRef?.current.remove();
    throw new Error(`Error loading webcam ${e}`);
  };

  let webcamHeight = 550;

  if (isMobile) {
    webcamHeight = 350;
  } else if (isTablet) {
    webcamHeight = 400;
  }

  const getWebcamHeight = () => {
    if (isLandscapeMobile) {
      return '100%';
    }
    return webcamHeight;
  };

  const getDeviseStyleVideoContainer = (isDevice: boolean) => {
    if (fullscreen && isDevice && !isLandscapeMobile) {
      return 'fullscreen';
    }
    if (!fullscreen && isDevice && !isLandscapeMobile) {
      return true;
    }
    return false;
  };

  return (
    <VideoRecorderContainer
      id="video-recorder-container"
      isMobile={getDeviseStyleVideoContainer(isMobile)}
      isTablet={getDeviseStyleVideoContainer(isTablet)}
      fullscreen={fullscreen}
      isLandscape={isLandscapeMobile}
    >
      <UpperBanner isLandscape={isLandscapeMobile} />
      {recordingStatus && !error && (
        <>
          {!isWebcamActive && (
            <BreathingComponents
              isExpertCard
              key={keyExtractor()}
              className="tab"
              height={fullscreen ? undefined : webcamHeight}
              fullHeight
              fullWidth
            />
          )}
          <Webcam
            audio
            muted
            ref={webcamRef}
            width="100%"
            height={fullscreen ? '100%' : getWebcamHeight()}
            videoConstraints={VideoConstraints}
            audioConstraints={AudioConstraints}
            onUserMedia={handleUserMedia}
            onUserMediaError={(e) => handleError(e)}
            className={`webcam ${isWebcamActive ? 'active' : 'disabled'} ${
              fullscreen || isLandscapeMobile ? 'fullscreen' : ''
            }`}
          />
          {status !== 'countdown' && (
            <VideoActions
              handleStartCaptureClick={handleStartClick}
              handleStopCaptureClick={handleStopCaptureClick}
              handlePauseCaptureClick={handlePauseCaptureClick}
              handleResumeCaptureClick={handleResumeCaptureClick}
              isLandscape={isLandscapeMobile}
            />
          )}
          <VideoCaption />
        </>
      )}
      {error && <NoDeviceError webcamHeight={webcamHeight} />}
      {!error && !recordingStatus && blob && (
        <Video
          id="video-replay"
          videoRef={videoRef}
          videoWidth="100%"
          videoHeight={getWebcamHeight()}
          isLandscape={isLandscapeMobile}
        />
      )}
    </VideoRecorderContainer>
  );
};

export default RecordingVideos;
