import React from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Error } from '../../../../../shared/components/Forms/InputError/styled';
import { WidgetTitle } from '../../../../../shared/components/Widget/styles';
import { LeftContainer, FormStyled, TextWrapper, ButtonWrapper } from '../../PublicProfile/styled';
import Button from '../../../../../shared/components/Button/Button';
import { ForgotPasswordLink } from '../../../../Authentication/components/Tabs/styled';
import PasswordWithValidations from '../../../../../shared/components/passwordWithValidations/passwordWithValidations';
import PasswordInput from '../../../../Authentication/components/Email/PasswordInput/PasswordInput';
import { ProfileService } from '../../../../../services/ProfileService/ProfileService';
import useGetToken from '../../../../../shared/hooks/useGetToken';
import { UpdatePasswordModel } from '../../../../../shared/context/app/types';
import useGetCurrentUserID from '../../../../../shared/hooks/getCurrentUserID';

const passwordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Password is required')
  .required('Required')
});

const Password: React.FC = () => {
  const authenticationToken = useGetToken();
  const userId = useGetCurrentUserID();
  const handleSubmit = async (formData: UpdatePasswordModel) => {
    const data = { authenticationToken, dataUser: formData };
    const user = await ProfileService.updateUser(data);
    if (user.length === 0) {
      toast.error(`Error updating your password. Please try again`);
    } else {
      toast.info('Password updated successfully');
    }
  };

  return (
    <>
      <LeftContainer>
        <WidgetTitle>Password</WidgetTitle>
        <TextWrapper>
          Change the password for your account
        </TextWrapper>
      </LeftContainer>

      <Formik
        initialValues={{
          userId,
          oldPassword: '',
          newPassword: ''
        }}
        validationSchema={passwordSchema}
        onSubmit={(formData, { resetForm }) => {
          handleSubmit(formData);
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <FormStyled>
            <PasswordInput name='oldPassword' placeholder='Old password' autocomplete='old-password' />
              {errors.oldPassword && touched.oldPassword ? (<Error>{errors.oldPassword}</Error>)
                                                         : null}
            <ForgotPasswordLink to="/forgot-password" css={{paddingRight: 0, maxWidth: '640px'}}>Forgot your password?</ForgotPasswordLink>
            <PasswordWithValidations />
            <ButtonWrapper>
              <Button kind='user-profile' title='Save' type='submit' css={{ letterSpacing: '$letterSpacing3' }} />
            </ButtonWrapper>
          </FormStyled>
        )}
      </Formik>
    </>
  );
};

export default Password;
