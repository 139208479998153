import axiosInstance from './utils/axiosInstance';

export const ExpertService = {
  getLocations: async () => {
    try {
      const results = await axiosInstance()
        .get('locations')
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  getRoles: async () => {
    try {
      const results = await axiosInstance()
        .get('occupations')
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  listExperts: async (
    authenticationToken: string,
    projectId: string,
    page: number,
    perPage: number,
  ) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`/users?page=${page}&per_page=${perPage}&project_id=${projectId}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  acceptExpertInvitation: async (authenticationToken: string, token: string, projecId: string) => {
    try {
      const results = await axiosInstance(authenticationToken)
        .put(`/project_invitations?token=${token}&project_id=${projecId}`, {})
        .then((response) => response?.data?.data || undefined);
      return results;
    } catch (error) {
      return [];
    }
  },
};
