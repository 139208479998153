import { styled } from '../../../../config/theme';

export const SimpleText = styled('p', {
  marginTop: '$spaceMedium',
  textAlign: 'center',
  color: '$monochromeWhite',
  fontSize: '$displayXXSmall',
  fontStyle: 'normal',
  fontWeight: '$normal',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing3',
  a: {
    color: '$secondaryP3',
    fontWeight: '$weight600',
    textDecorationLine: 'underline',
  },
});
