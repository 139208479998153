import React, { useState, useEffect, ReactNode } from 'react';
import { toast } from 'react-toastify';
import {
  WrapperTag,
  Tag,
  SameUserEventsContainer,
  TagNameContainer,
  TagsContainer,
  EventsContainer,
  TopicsContainer,
  FlexContainer,
  TextTag,
} from './styles';
import { StatusTag, TagModel } from '../../interfaces/interfaces';
import { keyExtractor } from '../../utils/helper';
import TagsTopics from './TagsTopics';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Button/Button';
import tagIcon from '../../../assets/images/icons/topic-tags.svg';
import addTagIcon from '../../../assets/images/icons/topic-tags-add.svg';
import deleteTagIcon from '../../../assets/images/icons/delete-tag.svg';
import TagsModal from './TagsModal';
import TagSelector from './TagSelector';
import { useTag } from '../../hooks/useTag';
import Icon from '../Icon/Icon';
import SelectedTagProvider from '../../context/tag/SelectedTagsProvider';
import TooltipCard from '../TooltipCard';
import { remove } from '../ToastAlerts/alerts';

interface TagPropsModel {
  data: TagModel[];
  actionTag(): void;
  viewModal?: Boolean;
  limit?: number;
  sameUser?: Boolean;
  userId?: string | number;
  contentId?: string;
  children?: ReactNode;
  all?: boolean;
  isCardPeople?: boolean;
}

type TagProps = TagPropsModel &
  React.ComponentProps<typeof WrapperTag> & {
    css?: any;
    kind?: string;
    cssTag?: any;
  };

const Tags: React.FC<TagProps> = ({
  data,
  // eslint-disable-next-line no-unused-vars
  actionTag,
  css,
  kind,
  cssTag,
  viewModal,
  limit,
  sameUser,
  userId,
  contentId,
  children,
  all,
  isCardPeople,
}) => {
  const [dataTags, setDataTags] = useState<Array<TagModel>>(data || []);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { width } = useWindowSize();
  const customLimit = width && width < 640 ? 1 : 2;
  const isResponsive = width < 640;
  const newLimit = limit || customLimit;
  const {
    saveExpertise,
    deleteExpertise,
    saveTagContent,
    deleteTagContent,
    filterDuplicated,
    orderUsedTags,
    handleTag,
  } = useTag();
  const [filterData, setFilterData] = useState(
    kind === 'one-line' && dataTags?.length > 0 && !all
      ? dataTags.filter((i, index: number) => index < newLimit)
      : dataTags,
  );

  const handleTagAction = async (item: TagModel, originals: TagModel[]) => {
    let updatedTopics: TagModel[] = [];
    setDataTags(orderUsedTags(originals, sameUser));
    if (userId !== undefined) {
      updatedTopics = await saveExpertise(item, userId as number);
      setDataTags(orderUsedTags(updatedTopics, sameUser));
    } else if (contentId !== undefined) {
      updatedTopics = await saveTagContent(item, contentId);
      const topicsWithoutItem = originals.filter((topic) => topic.id !== updatedTopics[0].id);
      setDataTags(orderUsedTags([...topicsWithoutItem, ...updatedTopics], sameUser));
    }
  };

  const handleDeleteTagAction = (item: TagModel) => {
    const updatedTopics = dataTags.filter((topic) => topic.id !== item.id);
    const originalTopics = [...dataTags];
    setDataTags(updatedTopics);
    if (userId !== undefined) {
      deleteExpertise(item, userId as number);
    } else if (contentId !== undefined) {
      deleteTagContent(item, contentId);
    }
    remove(
      <FlexContainer align="center" justify="between">
        <p>Topic removed</p>
        <Button
          title="Undo"
          kind="link"
          handleClick={() => {
            handleTagAction(item, originalTopics);
            toast.dismiss();
          }}
        />
      </FlexContainer>,
    );
  };

  const handleUpdateTopics = async (items: TagModel[]) => {
    await setDataTags(items);
  };

  useEffect(() => {
    setDataTags(filterDuplicated(data, sameUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setFilterData(
      kind === 'one-line' && dataTags?.length > 0 && !all
        ? dataTags.filter((i, index: number) => index < newLimit)
        : dataTags,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTags]);

  if (viewModal) {
    return <TagsTopics data={filterData} actionTag={handleTag} />;
  }

  return (
    <SelectedTagProvider>
      <WrapperTag css={css} kind={kind}>
        {/* MOBILE VERSION */}
        {isResponsive ? (
          <>
            <TagsContainer>
              {filterData &&
                filterData?.map((item: any) => (
                  <Tag
                    key={keyExtractor()}
                    approved={item.attributes.status === StatusTag.Approved}
                  >
                    <TextTag
                      isCardPeople={isCardPeople}
                      onClick={(e: any) => {
                        e?.stopPropagation();
                        const tagData = item?.attributes?.name || item;

                        handleTag(tagData || '');
                      }}
                    >
                      {item?.attributes?.name || item || ''}{' '}
                    </TextTag>
                    {sameUser && (
                      <Icon
                        closeToast={() => handleDeleteTagAction(item)}
                        img={deleteTagIcon}
                        margin0
                        css={{ marginLeft: 4 }}
                      />
                    )}
                  </Tag>
                ))}
            </TagsContainer>
            {!sameUser && dataTags && dataTags?.length > 2 ? (
              <>
                <SameUserEventsContainer>
                  <Button
                    title=""
                    iconCustom={tagIcon}
                    iconWidth="18px"
                    iconHeight="18px"
                    kind="topic-tag"
                    color="blurple"
                    handleClick={() => setIsOpenModal(!isOpenModal)}
                  />
                  {children}
                </SameUserEventsContainer>
                {dataTags && (
                  <TagsModal
                    open={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    topics={dataTags}
                    size={isResponsive ? 'mobile' : 'desk'}
                    sameUser={sameUser}
                  />
                )}
              </>
            ) : (
              !sameUser && <EventsContainer>{children}</EventsContainer>
            )}
            {sameUser && (
              <SameUserEventsContainer>
                <Button
                  title=""
                  iconCustom={addTagIcon}
                  iconWidth="18px"
                  iconHeight="18px"
                  kind="topic-tag"
                  color="blurple"
                  handleClick={() => setIsOpenModal(!isOpenModal)}
                />
                {children}
              </SameUserEventsContainer>
            )}
            {sameUser && dataTags && (
              <TagSelector
                open={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                topics={dataTags}
                size={isResponsive ? 'mobile' : 'desk'}
                userId={userId}
                contentId={contentId}
                onUpDatedTopics={handleUpdateTopics}
                sameUser={sameUser}
              />
            )}
          </>
        ) : (
          <SameUserEventsContainer>
            {/* DESK VERSION */}
            <TagNameContainer>
              {filterData &&
                filterDuplicated(filterData, sameUser)?.map((item: any) => (
                  <Tag
                    className={`gat-tag-${filterData?.length === 1 ? 'one' : 'multiple'}`}
                    css={cssTag}
                    approved={item.attributes.status === StatusTag.Approved}
                    key={keyExtractor()}
                  >
                    <TextTag
                      isCardPeople={isCardPeople}
                      onClick={(e: any) => {
                        e?.stopPropagation();
                        const tagData = item?.attributes?.name || item;

                        handleTag(tagData || '');
                      }}
                    >
                      {item?.attributes?.name || item || ''}{' '}
                    </TextTag>
                    {sameUser && (
                      <Icon
                        closeToast={() => handleDeleteTagAction(item)}
                        img={deleteTagIcon}
                        margin0
                        css={{ marginLeft: 4 }}
                      />
                    )}
                  </Tag>
                ))}
              {!sameUser && dataTags && dataTags?.length > 2 ? (
                <TooltipCard
                  isTag
                  content={
                    dataTags ? (
                      <>
                        <p>Content Topics</p>
                        <TopicsContainer>
                          {dataTags &&
                            dataTags.map((item: TagModel) => (
                              <Tag key={keyExtractor()}>
                                <TextTag
                                  isCardPeople={isCardPeople}
                                  onClick={(e: any) => {
                                    e?.stopPropagation();
                                    const tagData = item?.attributes?.name || item;

                                    handleTag(tagData || '');
                                  }}
                                >
                                  {item?.attributes?.name || ''}
                                </TextTag>
                              </Tag>
                            ))}
                        </TopicsContainer>
                      </>
                    ) : (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <></>
                    )
                  }
                >
                  <Button
                    title=""
                    iconCustom={tagIcon}
                    iconWidth="18px"
                    iconHeight="18px"
                    kind="topic-tag"
                    color="blurple"
                    handleClick={() => setIsOpenModal(!isOpenModal)}
                  />
                </TooltipCard>
              ) : (
                ''
              )}
              {sameUser && (
                <Button
                  title=""
                  iconCustom={addTagIcon}
                  iconWidth="18px"
                  iconHeight="18px"
                  kind="topic-tag"
                  color="blurple"
                  handleClick={() => setIsOpenModal(!isOpenModal)}
                />
              )}
              {sameUser && dataTags && (
                <TagSelector
                  open={isOpenModal}
                  onClose={() => setIsOpenModal(false)}
                  topics={dataTags}
                  size={isResponsive ? 'mobile' : 'desk'}
                  userId={userId}
                  contentId={contentId}
                  onUpDatedTopics={handleUpdateTopics}
                  sameUser={sameUser}
                />
              )}
            </TagNameContainer>
            {children}
          </SameUserEventsContainer>
        )}
      </WrapperTag>
    </SelectedTagProvider>
  );
};

Tags.defaultProps = {
  css: undefined,
  cssTag: undefined,
  kind: 'one-line',
  viewModal: false,
  limit: undefined,
  sameUser: false,
  userId: undefined,
  contentId: undefined,
  children: undefined,
  all: false,
  isCardPeople: false,
};

export default Tags;
