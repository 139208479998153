import React from 'react';
import CardPlaylistSidebar from './CardPlaylistSidebar';
import { WrapperCardList } from '../CardResources/styles';
import { LayoutContainer, LayoutCol } from '../Layouts/styles';
import { keyExtractor } from '../../utils/helper';


const PlaylistsSidebar: React.FC<any> = ({ total }) => {
  const itemsTotal = total || 1;
  const arrayData = Array(itemsTotal).fill('items');

  return (
    <WrapperCardList>
      <LayoutContainer sub noPadding
        css={{
          gap: '0', flexDirection: 'column',
          '@bp2': { flexDirection: 'row', gap: '$spaceLarge' },
          '@bp3': { flexDirection: 'column', gap: '0' }
        }}
      >
        {arrayData && arrayData?.map(() => (
          <LayoutCol
            key={keyExtractor()}
            col='4'
            css={{
              flexGrow: 1,
              ':first-child': {
                marginBottom: '$spaceXSmall'
              },
              '@bp3': { justifyContent: 'initial' },
              '@bp6': { flexBasis: '30.33%' }
            }}
          >
            <CardPlaylistSidebar />
          </LayoutCol>
        ))}
      </LayoutContainer>
    </WrapperCardList>
  );
};

export default PlaylistsSidebar;
