import React, { useEffect, useState } from 'react';
import { BodyContainer, FlexContainer } from '../styled';
import { HeaderAdmin } from './HeaderAdmin';
import { Separator } from '../Profile/styled';
import { PhotoEditor } from './PhotoEditor';
import { OrganizationNameSection } from './OrganizationNameSection';
// import { OrganizationSocialsSection } from './OrganizationSocialsSection';
import { FooterButtons } from './FooterButtons';
import { useOrganization } from '../../../shared/hooks/Organization/useOrganization';

export const AdminView = () => {
  const { organizationInformation, handleChangeInformation } = useOrganization();
  const [imageSrc, setImageSrc] = useState<string | undefined>(organizationInformation.image);
  const [newImage, setNewImage] = useState<File>();
  const [organizationName, setOrganizationName] = useState<string | undefined>(
    organizationInformation.name,
  );

  const handleSave = () => {
    handleChangeInformation(organizationName, newImage as File);
  };

  const handleChangeImage = () => {
    setImageSrc(URL.createObjectURL(newImage as File));
  };

  const handleCancel = () => {
    setImageSrc(organizationInformation.image);
    setOrganizationName(organizationInformation.name);
  };

  useEffect(() => {
    if (newImage) {
      handleChangeImage();
    }
    return () => handleCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newImage]);

  return (
    <BodyContainer admin>
      <FlexContainer direction="column" gap="2_5">
        <HeaderAdmin />
        <Separator />
        <PhotoEditor imageSrc={imageSrc} onChangeImage={setNewImage} />
        <Separator />
        <OrganizationNameSection name={organizationName} onChangeName={setOrganizationName} />
        {/* <OrganizationSocialsSection />
        <Separator /> */}
      </FlexContainer>
      <FooterButtons onSave={handleSave} />
    </BodyContainer>
  );
};
