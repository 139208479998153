import { useEffect, useState } from 'react';

export const useImageVerification = (imageSrc: string) => {
  const [isValidImage, setIsValidImage] = useState(true);

  useEffect(() => {
    const verifyImage = () => {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        setIsValidImage(true);
      };

      img.onerror = () => {
        setIsValidImage(false);
      };
    };

    verifyImage();
  }, [imageSrc]);

  return { isValidImage };
};
