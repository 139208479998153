import { Form } from 'formik';
import { Link } from 'react-router-dom';
import { Wrapper } from '../../TabsWrapper/styled';
import { styled } from '../../../../../config/theme';
import { FormikInput } from '../../../styled';

export const Icon = styled('img', {
  marginLeft: '0',
  marginRight: '$spaceXSmall',
  transition: 'margin 0.7s ease',
});

export const Container = styled('div', {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '1rem',
});

export const WrapperCard = styled(Wrapper, {
  paddingTop: '$spaceMedium',
  maxWidth: '480px',
  backgroundColor: '$bg',
  width: '100%',
  padding: '2rem',
  '@bp2': {
    padding: '$spaceMedium',
  }
});

export const WidgetTitle = styled('h3', {
  fontWeight: '$weight700',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing3',
  color: '$offBlack',
  marginBottom: '$spaceLarge',

  '@bp2': {
    fontSize: '$displayXSmall',
  }
});

export const WidgetText = styled('p', {
  marginBottom: '$spaceLarge',
  textAlign: 'center',
});

export const Input = styled(FormikInput, {
  paddingLeft: '$spaceLarge',
  fontSize: '$bodyTextXSmall',
  '@bp2': {
    fontSize: '$bodyXSmall',
  },
});

export const FormikForm = styled(Form, {
  width: '100%',
});

export const BackButton = styled(Link, {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  textDecoration: 'none',
  transition: 'all 0.7s ease',
  '&:hover': {
    [`${Icon}`]: {
      transform: 'translateX(-2px)',
    }
  },
});

export const BackText = styled('p', {
  color: '$body',
  fontWeight: '$weight600',
  fontSize: '$bodySmall',
  letterSpacing: '$letterSpacing2',
  lineHeight: '$lineHeight2',
  '&:hover': {
    color: '$hoverBtn',
  }
});