import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';
import { KnowledgeService } from '../../../services/knowledge.service';
import AppContext from '../../context/app/AppContext';
import closeIcon from '../../../assets/images/icons/close.svg';
import { Knowledge } from '../../interfaces/interfaces';
import PlaylistForm from './PlaylistForm';

interface ModalKBModel {
  isEdit?: boolean;
  isOpen?: boolean;
  dataKB?: Knowledge;
  actionClose?: () => void;
  actionContinue?: () => void;
}

const ModalKB: React.FC<ModalKBModel> = ({
  actionClose, actionContinue, isEdit, isOpen, dataKB
}) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { state, dispatch } = useContext(AppContext);
  const { authenticationToken } = state as any;
  const navigate = useNavigate();

  const handleClose = useCallback(async () => {
    if (actionClose) {
      actionClose();
    }

  }, [actionClose]);

  const actionClear = () => {
    setTitle('');
    setDescription('');
    setErrorMessage('');
    setErrorMessage('');
    setLoading(false);
  };

  const actionCreate = useCallback(async (values: any) => {
    try {
      const params = {
        authenticationToken,
        title: values.title,
        description: values.description,
        isPublic: values.isPublic,
      };
      const results = await KnowledgeService.createKnowledge(params);

      if (results?.attributes) {
        await handleClose();
        await navigate(`/users/${results.attributes.created_by_slug}/playlists/${results?.attributes.slug}`);
      } else {
        setErrorMessage(results?.message);
      }
    } catch (error) {
      setErrorMessage('Error while loading data. Try again later.');
    } finally {
      await setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, title, description, isPublic, handleClose]);

  const actionEdit = useCallback(async (values: any) => {
    try {
      const params = {
        authenticationToken,
        id: dataKB?.id,
        title: values.title,
        description: values.description,
        isPublic: values.isPublic,
      };

      const results = await KnowledgeService.editKB(params);
      if (results?.attributes) {
        await handleClose();
        await dispatch({ type: 'SET_RELOAD', payload: true });
      } else {
        setErrorMessage(results?.message);
      }
    } catch (error) {
      setErrorMessage('Error while loading data. Try again later.');
    } finally {
      await setLoading(false);
    }

  }, [authenticationToken, dataKB?.id, handleClose, dispatch]);

  const handleContinue = (values: any) => {
    setLoading(true);

    if (!isEdit) {
      actionCreate(values);
      if (actionContinue) {
        actionContinue();
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (isEdit && dataKB?.attributes) {
        setTitle(dataKB?.attributes?.title || '');
        setDescription(dataKB?.attributes?.description || '');
        setIsPublic(Boolean(dataKB?.attributes?.public));
      } else {
        actionClear();
      }
    }

    return () => {
      setTitle('');
      setDescription('');
      setIsPublic(true);
    };
  }, [isOpen, isEdit, dataKB?.attributes]);

  useEffect(() => () => {
    setTitle('');
    setDescription('');
    setIsPublic(true);
  }, []);

  return isOpen ? (
    <Modal
      kindOverlay='gat-modal-overlay--light'
      isOpen={isOpen || false}
      onClose={() => handleClose()}
      css={{ width: '85%', '@bp4': { width: '50%' } }}
    >
      <Card type='modalKB'>
        <Button
          title=''
          iconCustom={closeIcon}
          iconWidth='16px'
          iconHeight='16px'
          kind='transparent'
          handleClick={() => handleClose()}
          css={{
            position: 'absolute',
            padding: '1.5rem 1.5rem 0 0',
            top: '0',
            right: '0',
            '& img': {
              margin: '0'
            }
          }}
        />
        <PlaylistForm
          title={title}
          description={description}
          isEdit={isEdit || false}
          loading={loading || false}
          publicKB={isPublic}
          errorMessage={errorMessage}
          actionEdit={actionEdit}
          actionContinue={handleContinue}
        />
      </Card>
    </Modal>
  ) : <div style={{display:'none'}} />;
};

ModalKB.defaultProps = {
  isEdit: false,
  isOpen: false,
  actionClose: undefined,
  dataKB: undefined,
  actionContinue: undefined,
};

export default ModalKB;
