import React, { useContext, useEffect, useState } from 'react';
import { FlexContainer, Typography } from '../styled';
import { ListItem } from './ListItem';
import { ProfileIcons } from './icons/ProfileIcons';
import { ProjectsIcon } from './icons/ProjectsIcon';
import { MembersIcon } from './icons/MembersIcon';
import { BillingIcon } from './icons/BillingIcon';
import { SupportIcon } from './icons/SupportIcon';
import {
  CreditsContainer,
  CreditsCounterContainer,
  ExpandImg,
  ExpandableContainer,
  MobileNavImg,
  Sidebar,
  ImgOrganizationAvatar,
  NoImgOrganizationAvatar,
} from './styled';
import { ManageCreditIcon } from './icons/ManageCreditIcon';
import { Item } from './ListItem/Item';
import MobileNavArrow from '../../../assets/images/organization/MobileNavArrow.svg';
import ExpandArrow from '../../../assets/images/organization/ExpandArrow.svg';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import Avatar from '../../../shared/components/Avatar/Avatar';
import AppContext from '../../../shared/context/app/AppContext';
import { usePayment } from '../../../shared/hooks/Organization/usePayment';
import { SettingIcons } from './icons/SettingIcons';
import { useOrganization } from '../../../shared/hooks/Organization/useOrganization';
import { useImageVerification } from '../../../shared/hooks/useImageVerification';
import { getInitials } from '../../../shared/utils/helper';

const NavItemsOrganization = [
  {
    icon: <ProfileIcons />,
    label: 'Profile',
    route: '/organization/profile',
  },
  {
    icon: <ProjectsIcon width="24" height="24" />,
    label: 'Projects',
    route: '/organization/projects',
  },
  {
    icon: <MembersIcon width="24" height="24" />,
    label: 'Members',
    route: '/organization/members',
  },
  {
    icon: <BillingIcon />,
    label: 'Billing',
    route: '/organization/billing',
  },
  {
    icon: <SettingIcons />,
    label: 'Admin',
    route: '/organization/admin',
  },
  {
    icon: <SupportIcon />,
    label: 'Support',
    isExternal: true,
    route:
      'https://gathersight.notion.site/gathersight/Hello-and-thank-you-for-looking-15f8fba060dd4541a5bce70293da8cb0',
  },
];
// eslint-disable-next-line no-unused-vars
const NavItemsExpert = [
  {
    icon: <ProjectsIcon width="24" height="24" />,
    label: 'Projects',
    route: '/experts/projects',
  },
  {
    icon: <BillingIcon />,
    label: 'Earnings',
    route: '/experts/earnings',
  },
  {
    icon: <SupportIcon />,
    label: 'Support',
    isExternal: true,
    route:
      'https://gathersight.notion.site/gathersight/Hello-and-thank-you-for-looking-15f8fba060dd4541a5bce70293da8cb0',
  },
];
const ManageCredits = {
  icon: <ManageCreditIcon />,
  label: 'Manage Credits',
};
interface SideNavbarProps {
  // eslint-disable-next-line no-unused-vars
  onUpdateExpanded: (isExpanded: boolean) => void;
}
export const SideNavbar = ({ onUpdateExpanded }: SideNavbarProps) => {
  const { width } = useWindowSize();
  const isTablet = width >= 720 && width < 980;
  const isMobile = width < 720;

  const [isExpanded, setIsExpanded] = useState(false);
  const { state } = useContext(AppContext);
  const { userData } = state as any | undefined;
  const [isOrganization, setIsOrganization] = useState(userData.userType === 'seeker');
  const NavItems = isOrganization ? NavItemsOrganization : NavItemsExpert;
  const { credits, setCredits } = usePayment();
  const { organizationInformation } = useOrganization();
  const { isValidImage } = useImageVerification(organizationInformation.image as string);

  useEffect(() => {
    setIsOrganization(userData.userType !== 'expert');
  }, [userData]);

  const handleItemSelect = () => {
    setIsExpanded(false);
    onUpdateExpanded(false); 
  };

  const handleUpdateExpanded = () => {
    onUpdateExpanded(!isExpanded);
    setIsExpanded(!isExpanded);
  };

  const renderAvatar = () => {
    if (isOrganization) {
      if (isValidImage) {
        return <ImgOrganizationAvatar src={organizationInformation.image} />;
      }
      return (
        <NoImgOrganizationAvatar>
          {getInitials(organizationInformation.name as string)}
        </NoImgOrganizationAvatar>
      );
    }
    return <Avatar img={userData?.avatarUrl} name={userData?.firstName} type="header" />;
  };

  useEffect(() => {
    setIsOrganization(userData.userType !== 'expert');
    setCredits(userData.credits);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  useEffect(() => {
    setIsExpanded(false);
    onUpdateExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <Sidebar
      isMobile={isMobile}
      isExpanded={isExpanded}
      isTablet={isTablet}>
      {isTablet && (
        <FlexContainer align="center" justify="between">
          {isExpanded && (
            <Typography weight="700">{isOrganization ? 'Organization' : 'Expert'}</Typography>
          )}
          <ExpandImg
            src={ExpandArrow}
            alt=""
            css={{ transform: isExpanded ? 'rotate(180deg)' : '' }}
            onClick={handleUpdateExpanded}
          />
        </FlexContainer>
      )}
      {!isTablet && (
        <FlexContainer align="center" justify="between">
          <Typography weight="700">{isOrganization ? 'Organization' : 'Expert'}</Typography>
          <MobileNavImg
            src={MobileNavArrow}
            alt=""
            css={{ transform: isExpanded ? 'rotate(180deg)' : '' }}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </FlexContainer>
      )}

      <ExpandableContainer isExpanded={isExpanded}>
        <FlexContainer
          spacing="marginYSpaceLarge"
          justify={isTablet && !isExpanded ? 'center' : 'start'}
          align="center"
        >
          {renderAvatar()}
          {(!isTablet || isExpanded) && (
            <Typography
              size="bodyXSmall"
              weight="600"
              lineHeight="lineHeight10"
              css={{ marginLeft: '1rem' }}
            >
              {isOrganization
                ? organizationInformation.name
                : `${userData?.firstName} ${userData?.lastName}`}
            </Typography>
          )}
        </FlexContainer>
        <ListItem items={NavItems} isExpanded={isExpanded} onSelect={handleItemSelect} />
        {isOrganization && (
          <FlexContainer
            spacing="marginPaddingYSpaceLarge"
            justify="center"
            align="center"
            direction="column"
            line="top"
          >
            <CreditsContainer>
              {(!isTablet || isExpanded) && (
                <Typography size="bodyListItem" weight="500">
                  Credits Available
                </Typography>
              )}
              <CreditsCounterContainer>
                <Typography size="bodySmall" weight="500">
                  {credits}
                </Typography>
              </CreditsCounterContainer>
            </CreditsContainer>
            <Item
              icon={ManageCredits.icon}
              label={ManageCredits.label}
              credit
              route="/organization/billing/add-credits"
              isExpanded={false}
              onSelect={handleItemSelect}
            />
          </FlexContainer>
        )}
      </ExpandableContainer>
    </Sidebar>
  );
};
