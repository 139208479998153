/* eslint-disable no-restricted-globals */
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { BigText, SmallText, Typography, ExpertInvitationImageSubContainer } from '../styled';
import { Container, TextDescription } from '../Experts/styled';
import Button from '../../../shared/components/Button/Button';
import {
  InvitationContainer,
  LetterContainer,
  LetterSubContainer,
  ExpertInvitationImageContainer,
  BoxContainer,
  TextDescriptionDecoration,
  UnderneathText,
  QuestionsContainer,
} from './styled';
import { useProjects } from '../../../shared/hooks/useProjects';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import useGetToken from '../../../shared/hooks/useGetToken';
import { BreathingComponents } from '../../../shared/components/ShimmerComponent/styles';
import { keyExtractor } from '../../../shared/utils/helper';

export const ExpertInvitation = () => {
  const [token, setToken] = React.useState<string>('');
  const [projectId, setProjectId] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [registered, setRegistered] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const { getProjectByToken, acceptInvitation } = useProjects();
  const { setProject, project } = useContext(ProjectsContext);
  // eslint-disable-next-line no-restricted-globals
  const params = queryString.parse(location.search);
  const authenticationToken = useGetToken();

  const IncomeMessageData = [
    `Hello,`,
    "I'm delighted to tell you that you have been recognized as having expertise matching the knowledge needs of an organization in the Gathersight network. I would like to invite you to record a paid Expert Opinion video.",
    "An Expert Opinion is a short recorded video message (5 - 15 minutes) from you answering the organization's questions, listed on the left side of this page. Once recorded and accepted, you'll receive payment - it's as simple as that!",
    'Gathersight is a knowledge network for the life science and biotech sector. We match scientists, researchers and industry specialists with organisations seeking specific insights into a wide variety of topics. I hope you enjoy the platform.',
    'Thank you and best wishes,',
  ];

  useEffect(() => {
    if (params.token && params.projectId) {
      setToken(params.token as string);
      setProjectId(params.projectId as string);
      setEmail(params.email as string);
      setRegistered(params.registered === 'true');
      localStorage.setItem('projectId', params.projectId as string);
      localStorage.setItem('tokenInvitation', params.token as string);
      localStorage.setItem('email', params.email as string);
      localStorage.setItem('registered', params.registered as string);
    } else {
      const storedProjectId = localStorage.getItem('projectId');
      const storedToken = localStorage.getItem('tokenInvitation');
      const storedEmail = localStorage.getItem('email');
      const storedRegistered = localStorage.getItem('registered');
      if (storedProjectId && storedToken) {
        setToken(storedToken as string);
        setProjectId(storedProjectId as string);
        setEmail(storedEmail as string);
        setRegistered(storedRegistered === 'true');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const findProject = async () => {
      if (!projectId) return;
      const response = await getProjectByToken(projectId as string, token as string);
      await setProject(response);
      setIsLoading(false);
    };
    findProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const redirectToAuthentication = async () => {
    if (email) {
      if (registered === true) {
        navigate(`/authentication?invite=expert&email=${encodeURIComponent(email)}`);
      } else {
        navigate(`/authentication?sign-up=true&invite=expert&email=${encodeURIComponent(email)}`);
      }
    }
  };

  const AcceptInvitation = () => {
    if (token && projectId) {
      if (authenticationToken) {
        acceptInvitation(authenticationToken, token, undefined, projectId);
      } else {
        redirectToAuthentication();
      }
    } else navigate('/404');
  };

  return (
    <Container isVisible>
      <InvitationContainer>
        <LetterContainer>
          <LetterSubContainer>
            <TextDescription type="inviteSubtitle">Summary</TextDescription>
            <BoxContainer>
              <Typography type="displayXXSmall">
                Payment to you:
                <span>
                  ${parseFloat(project?.attributes?.price as string).toFixed(2)} (5 - 15 minutes)
                  </span>
              </Typography>
            </BoxContainer>
            {isLoading ? (
              <>
                <BreathingComponents key={keyExtractor()} className="tab" height={60} fullWidth />
                <BreathingComponents
                  key={keyExtractor()}
                  className="tab"
                  height={30}
                  fullWidth
                  css={{ marginTop: '$spaceLarge' }}
                />
              </>
            ) : (
              <>
                <h2>{project?.attributes?.name}</h2>
                <UnderneathText css={{ marginTop: '$spaceLarge' }}>
                  (This is the information entered by the requesting user.)
                </UnderneathText>
              </>
            )}
            {isLoading ? (
              <>
                <BreathingComponents
                  key={keyExtractor()}
                  className="tab"
                  height={15}
                  fullWidth
                  css={{ marginTop: '$spaceLarge' }}
                />
                <BreathingComponents
                  key={keyExtractor()}
                  className="tab"
                  height={15}
                  fullWidth
                  css={{ marginTop: '$spaceLarge' }}
                />
                <BreathingComponents
                  key={keyExtractor()}
                  className="tab"
                  height={15}
                  fullWidth
                  css={{ marginTop: '$spaceLarge' }}
                />
                <BreathingComponents
                  key={keyExtractor()}
                  className="tab"
                  height={15}
                  width={220}
                  css={{ marginTop: '$spaceLarge', marginBottom: '$spaceXXLarge' }}
                />
              </>
            ) : (
              <TextDescription type="description" css={{ textAlign: 'left' }}>
                {project?.attributes?.description}
              </TextDescription>
            )}
            <QuestionsContainer css={{ marginBottom: '$spaceXXLarge' }}>
              <h3>Questions</h3>
              <UnderneathText>
                (These are the questions you should answer in your recording.)
              </UnderneathText>
              {isLoading
                ? _.range(1, 4).map(() => (
                    <BreathingComponents
                      key={keyExtractor()}
                      className="tab"
                      height={100}
                      fullWidth
                      css={{ marginTop: '$spaceLarge' }}
                    />
                  ))
                : project?.attributes.project_questions.map((question) => (
                    <TextDescriptionDecoration>
                      <TextDescription type="contained">{question.attributes.text}</TextDescription>
                    </TextDescriptionDecoration>
                  ))}
            </QuestionsContainer>
            <Button
              css={{ width: '100%', maxWidth: '100%' }}
              title="Get started"
              handleClick={() => AcceptInvitation()}
            />
          </LetterSubContainer>
        </LetterContainer>
        <ExpertInvitationImageContainer>
          <ExpertInvitationImageSubContainer>
            <BigText css={{ marginBottom: '0.3rem' }} type="title">
              <BigText type="title" css={{ fontWeight: '$weight700' }}>
                Share your knowledge,
              </BigText>{' '}
              boost your income.
            </BigText>
            {IncomeMessageData.map((paraph) => (
              <SmallText landing="invitation">{paraph}</SmallText>
            ))}
            <SmallText landing="invitation" font="squarePeg" style={{ marginTop: '2rem' }}>
              Richard
            </SmallText>
            <SmallText landing="invitation">
              Richard Barker <br /> Founder, Gathersight
            </SmallText>
            <SmallText landing="invitation">
              P.S. If you need more information about recording an Expert Opinion please visit our{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://gathersight.notion.site/Hello-and-thank-you-for-coming-15f8fba060dd4541a5bce70293da8cb0"
              >
                help pages
              </a>{' '}
              or email <span>customerteam@gathersight.com</span>.
            </SmallText>
          </ExpertInvitationImageSubContainer>
        </ExpertInvitationImageContainer>
      </InvitationContainer>
    </Container>
  );
};
