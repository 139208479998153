import { styled, keyframes } from '../../../config/theme';

export const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' }
});

export const Spinner = styled('div', {
  border: '1rem solid $line',
  borderRadius: '60%',
  borderTop: '1rem solid transparent',
  width: '3rem',
  height: '3rem',
  borderWidth: '0.2rem',
  animation: `${spin} 2s linear infinite`,

  variants: {
    color: {
      ash: {
        border: '1rem solid $ash',
      },
    },
    complete: {
      true: {
        animation: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '0.2rem solid',
        '& img': {
          margin: 0
        }
      },
    },
    type: {
      xs: {
        width: '1rem',
        height: '1rem',
        borderWidth: '0.17rem'
      },
      lg: {
        width: '5.5rem',
        height: '5.5rem'
      },
      xxs: {
        width: '.8rem',
        height: '.8rem',
        borderWidth: '0.15rem'
      },
    }
    
  }
});
