import { useState, useContext } from 'react';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';
import useWindowSize from './useWindowSize';
import AppContext from '../context/app/AppContext';

interface ResourcesResponsiveModel {
  breakpoint: number;
  kind:string | undefined;
  limit: number;
}

function useGetResourcesResponsive(data: ResourcesResponsiveModel ) {
  const { breakpoint, kind, limit } = data;
  const { width } = useWindowSize();
  const { state } = useContext(AppContext);
  const { resources } = state as any | undefined;
  const itemsTotal = kind === 'content' ? limit || 1 : limit-1 || 2;
  const arrayItems = Array(itemsTotal).fill('items');
  const mobileCondition = arrayItems.length > 1 ? 720 : 0;
  const [resourcesData, setResourcesData] = useState<Array<any>>([]);

  const removeAttributeRoot = () => {
    arrayItems.forEach((_data, index: number) => {
      document.getElementById('root')?.removeAttribute(`hide-child-${index}`);
    });
  };
  
  useIsomorphicLayoutEffect(() => {
    if (width && (width >= mobileCondition && width < breakpoint)) {
      setResourcesData(resources?.slice(0, arrayItems.length));
      arrayItems.forEach((_data, index: number) => {
        document.getElementById('root')?.setAttribute(`hide-child-${index}`, '');
      });
    }

    if (width && width < mobileCondition) {
      setResourcesData(resources?.slice(0, arrayItems.length || 1));
      if(limit) {
        arrayItems.forEach((_data, index: number) => {
          document.getElementById('root')?.setAttribute(`hide-child-${index}`, '');
        });
      } else {
        document.getElementById('root')?.setAttribute('hide-child-0', '');
        document.getElementById('root')?.removeAttribute('hide-child-1');
      }
    }

    if (width && width >= breakpoint && resourcesData.length > 0) {
      setResourcesData([]);
      removeAttributeRoot();
    }

    return () => removeAttributeRoot();
  }, [width, resources]);

  return resourcesData;
};

export default useGetResourcesResponsive;
