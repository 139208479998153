import React, { SVGProps } from 'react';

export const ChatIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="chat bubble / 24 / Outline">
      <path
        id="Vector"
        d="M33.25 3.5H8.75C7.35761 3.5 6.02226 4.05312 5.03769 5.03769C4.05312 6.02226 3.5 7.35761 3.5 8.75V26.25C3.5 27.6424 4.05312 28.9777 5.03769 29.9623C6.02226 30.9469 7.35761 31.5 8.75 31.5H29.0325L35.5075 37.9925C35.671 38.1547 35.8649 38.283 36.0782 38.3701C36.2914 38.4572 36.5197 38.5013 36.75 38.5C36.9796 38.5059 37.2073 38.458 37.415 38.36C37.7346 38.2287 38.0082 38.0058 38.2012 37.7193C38.3943 37.4327 38.4983 37.0955 38.5 36.75V8.75C38.5 7.35761 37.9469 6.02226 36.9623 5.03769C35.9777 4.05312 34.6424 3.5 33.25 3.5ZM35 32.5325L30.9925 28.5075C30.829 28.3453 30.6351 28.217 30.4218 28.1299C30.2086 28.0428 29.9803 27.9987 29.75 28H8.75C8.28587 28 7.84075 27.8156 7.51256 27.4874C7.18437 27.1592 7 26.7141 7 26.25V8.75C7 8.28587 7.18437 7.84075 7.51256 7.51256C7.84075 7.18437 8.28587 7 8.75 7H33.25C33.7141 7 34.1593 7.18437 34.4874 7.51256C34.8156 7.84075 35 8.28587 35 8.75V32.5325Z"
        fill="#1C1C28"
      />
    </g>
  </svg>
);
