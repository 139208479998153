import { isDev, isProd, isStaging} from '../shared/utils/environments';

// console.log('isDev()', isDev(), process.env.NODE_ENV);

export const googleAuth = { CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID };
export const apiStaging = { BASE_API: 'https://gathersight-staging.herokuapp.com/api/v1' };
export const apiProduction = { BASE_API: 'https://gathersight-production.herokuapp.com/api/v1' };
// export const apiUrl = { BASE_API: 'http://localhost:3001/api/v1' };
export const apiUrl = isDev() || isStaging() ? apiStaging : apiProduction;
export const clientUrlDev = { BASE_API: 'http://localhost:3000' };
export const clientURL = { BASE_API: 'https://gathersight-staging-frontend.herokuapp.com' };
export const clientURLProd = { BASE_API: 'https://www.gathersight.com' };
export const apiAuth = { BASE_API: `${apiUrl.BASE_API}/auth` };
export const twitterAuth = { TWITTER_API: 'https://api.twitter.com/oauth' };

export const ProdOrDevUrl = () => {
  if (isDev()){ return clientUrlDev; }
  if (isProd()){ return clientURLProd; }

  return clientURL;
};

