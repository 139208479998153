/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { InputWrapper, IconWrapper } from './styled';
import InputSearch from '../InputSearch/InputSearch';
import AppContext from '../../context/app/AppContext';
import ResultsFilter from './ResultsFilter/ResultsFilter';
import Icon from '../Icon/Icon';
import searchIcon from '../../../assets/images/icons/search.svg';

interface ResourcesSearchModel {
  actionSearch?: (name: string) => void;
}

type ResourcesSearchProps = ResourcesSearchModel &
  React.ComponentProps<typeof InputWrapper> & {
    css?: any;
    kind?: any;
  };

const ResourcesSearch = ({ actionSearch, css, kind }: ResourcesSearchProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [reset, setReset] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const { filterContent } = state as any;
  const filterPlaceholder =
    filterContent?.type === 'content' ? 'e.g. keyword, title, topic' : '@twittername';

  const handleEvent = async (name: string) => {
    if (actionSearch && name) {
      await actionSearch(name);
    } else {
      await dispatch({ type: 'SET_FILTER', payload: { ...filterContent, filter: name, page: 1 } });
    }
  };

  useEffect(() => {
    const addParamsToURL = async () => {
      const { filter, category } = filterContent;
      let { type } = filterContent;
      if (category === 'tag') {
        type = 'topic';
      }
      if (filter) {
        if (!(location.pathname === '/')) {
          navigate(`/?${[type]}=${filter}`);
        } else {
          setSearchParams({ [type]: filter });
        }
      }
    };

    setValue(filterContent?.filter);
    if (filterContent?.filter) {
      addParamsToURL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterContent]);

  useEffect(() => {
    let filter = null;
    let filterBy = 'content';
    let category = 'feed';

    if (searchParams.has('content')) {
      filter = searchParams.get('content');
      filterBy = 'content';
      category = 'feed';
    } else if (searchParams.has('author')) {
      filter = searchParams.get('author');
      filterBy = 'author';
      category = 'author';
    } else if (searchParams.has('topic')) {
      filter = searchParams.get('topic');
      filterBy = 'content';
      category = 'tag';
    }

    const dataSearch = { ...filterContent, filter, page: 1, type: filterBy, category };
    dispatch({ type: 'SET_FILTER', payload: dataSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const { pathname } = window.location;
      if (reset) {
        const newType = filterContent?.type;
        const category = filterContent?.type === 'author' ? undefined : 'feed';
        await dispatch({
          type: 'SET_FILTER',
          payload: {
            ...filterContent,
            type: newType,
            filter: undefined,
            page: 1,
            category,
          },
        });
        navigate({ pathname, search: '' });
        setReset(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <InputWrapper activeWrite={Boolean(value)} kind={kind} css={css}>
      <IconWrapper>
        <Icon img={searchIcon} width="18" height="16" alt="Search" />
      </IconWrapper>
      <ResultsFilter />
      <InputSearch
        actionSearch={handleEvent}
        placeholder={filterPlaceholder}
        setReset={setReset}
        value={value}
        setValue={setValue}
      />
    </InputWrapper>
  );
};

ResourcesSearch.defaultProps = {
  actionSearch: undefined,
  css: undefined,
  kind: undefined,
};

export default ResourcesSearch;
