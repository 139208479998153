import React from "react";
import MetaTags from "../../shared/components/MetaTags/MetaTags";
import { User } from "../../shared/interfaces/interfaces";
import { getUserName } from "../../shared/utils/helper";

interface MetaTagsProps {
  userData: User;
}

const PlaylistMetatags: React.FunctionComponent<MetaTagsProps> = ({ userData }) => {
    const dataName = {
    username: userData?.attributes?.username,
    first_name: userData?.attributes?.first_name,
    last_name: userData?.attributes?.last_name
  };
  const finalName = getUserName(dataName);
  const title = `Gathersight: Knowledge playlists from ${finalName}`;
  const description = `A collection of knowledge playlists from ${finalName}.
    Discover this and 100s of other playlists packed full of opinion,
    ideas and insight on Gathersight.`;
  const canonical = `https://gathersight.com/users/${userData.attributes.slug}/playlists`;

  return (
    <MetaTags
      title={title}
      description={description}
      canonical={canonical}
      name={finalName}
      image={userData?.attributes?.avatar_url}
    />
  );
};

export default PlaylistMetatags;