import React, { useState } from 'react';
import { IconButtonContainer, ListItemStyled, TagNameContainer } from './styles';
import Icon from '../Icon/Icon';
import tags from '../../../assets/images/icons/tags.svg';
import usedTag from '../../../assets/images/icons/use-tag.svg';
import prevUsedTag from '../../../assets/images/icons/prev-used-tag.svg';
import { TagModel } from '../../interfaces/interfaces';
import { useTag } from '../../hooks/useTag';
import ArrowRightIcon from '../Icons/ArrowRightIcon';

interface TagListItemProps {
  item: TagModel;
  hasChild?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick: any;
  usedTags?: (string | undefined)[];
  handleTagAction: any;
  handleDeleteTag: any;
  searchTerm?: string;
}

const TagListItem = ({
  item,
  hasChild,
  onClick,
  usedTags,
  handleTagAction,
  handleDeleteTag,
  searchTerm,
}: TagListItemProps) => {
  const [tagStateIcon, setTagStateIcon] = useState(tags);
  const { isSelectedTag } = useTag();
  const handleClick = () => {
    onClick(item);
  };

  const handleMouseOver = () => {
    setTagStateIcon(prevUsedTag);
  };

  const handleMouseOut = () => {
    setTagStateIcon(tags);
  };

  const handleCloseToast = () => {
    handleTagAction(item);
    handleMouseOut();
  };

  const handleCloseAndDelete = () => {
    handleDeleteTag(item);
    handleMouseOut();
  };

  const isSameToSearchTerm = () => {
    if (searchTerm && searchTerm.length) {
      return searchTerm.toLowerCase() === item?.attributes?.name?.toLowerCase();
    }
    return false;
  };

  const isUsedTag = () => usedTags?.length && usedTags?.includes(item?.id);
  return (
    <ListItemStyled
      key={item.id}
      className={`${
        isSelectedTag(item) || isUsedTag() || prevUsedTag === tagStateIcon ? 'selected' : ''
      } ${isUsedTag() ? 'used' : ''} `}
      bold={isSameToSearchTerm()}
    >
      <TagNameContainer>
        {isUsedTag() ? (
          <Icon
            img={usedTag}
            width="15px"
            height="15px"
            alt="Add content"
            margin0
            fadeIn
            css={{
              display: 'inline-flex',
              verticalAlign: 'middle',
              cursor: 'pointer',
              marginRight: 5,
            }}
            closeToast={handleCloseAndDelete}
          />
        ) : (
          <Icon
            img={tagStateIcon}
            width="15px"
            height="15px"
            alt="Add content"
            margin0
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            css={{
              display: 'inline-flex',
              verticalAlign: 'middle',
              cursor: 'pointer',
              marginRight: 5,
            }}
            closeToast={handleCloseToast}
          />
        )}

        {item.attributes?.name}
      </TagNameContainer>

      {hasChild && (
        <IconButtonContainer onClick={handleClick}>
          <ArrowRightIcon />
        </IconButtonContainer>
      )}
    </ListItemStyled>
  );
};

TagListItem.defaultProps = {
  hasChild: false,
  usedTags: [],
  searchTerm: undefined,
};

export default TagListItem;
