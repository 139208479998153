import { styled } from '../../../config/theme';

export const WrapperAuthor = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '$spaceLarge',
  flexWrap: 'wrap',
  '&:last-child': {
    marginBottom: 0
  }
});

export const WrapperListAuthor = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
});

export const WrapperListShimmer = styled(WrapperListAuthor, {
  '@bp3': {
    minWidth: '200px',
  },
  '@bp3_5': {
    minWidth: '300px',
  },
  '@bp4': {
    minWidth: '320px',
  },
  '@bp5': {
    minWidth: '390px',
  }
});

export const TittleList = styled('h3', {
  fontWeight: '$weight700',
  fontSize: '$bodyLarge',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing3',
  color: '$offBlack',
  marginBottom: '$spaceLarge',

  '@bp3': {
    fontSize: '$displayXSmall',
  }
});
