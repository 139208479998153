import React from 'react';
import { FlexContainer, Typography } from '../../styled';
import { CameraIcon } from './CameraIcon';
import { CameraIconContainer, StatusLabel } from './styles';
import { capitalizeFirstLetter } from '../../../../shared/utils/helper';

interface StatusIndicatorProps {
  projectOpinions: string;
  opinions: string;
  status: 'completed' | 'in_progress' | 'draft' | 'archived';
}

export const StatusIndicator = ({ projectOpinions, opinions, status }: StatusIndicatorProps) => (
  <FlexContainer gap="0_5" align="center">
    <CameraIconContainer>
      <CameraIcon />
    </CameraIconContainer>
    <Typography size="bodyTextXSmall" weight="500">
      {opinions ? `${projectOpinions}/${opinions}` : '-/-'}
    </Typography>
    <StatusLabel status={status}>
      {status === 'in_progress' ? 'In Progress' : capitalizeFirstLetter(status)}
    </StatusLabel>
  </FlexContainer>
);
