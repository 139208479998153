// import { PropertyValue } from '@stitches/react';
import { styled } from '../../../config/theme';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const PageWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  marginTop: 60,
  minHeight: 'calc(100vh - 70px)',
  alignItems: 'baseline',
  '@bp2': {
    marginTop: 70,
  },
  variants: {
    layout: {
      recoverPassword: {
        backgroundColor: '$offBlack',
        flexDirection: 'row',
        justifyContent: 'center',
        overflowX: 'hidden',
        justifyItems: 'center',
        marginTop: 0,
        minHeight: '100vh',
      },
      home: {
        backgroundColor: '$bg',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        fontSize: '$displayXSmall',
        color: '$offBlack',
        position: 'relative',
      },
      settings: {
        backgroundColor: '$bg',
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
      },
      landing: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '$offWhite',
        height: '100vh',
        padding: '10px 20px',
        position: 'relative',
        zIndex: 2,

        '@bp2': {
          height: '95vh',
        },
        '@bp3': {
          height: '90vh',
        },
        '@bp4': {
          padding: 10,
        },
      },
      profile: {
        backgroundColor: '$bg',
        flexDirection: 'column',
        fontSize: '$displayXSmall',
        color: '$offBlack',
        position: 'relative',
      },
      discover: {
        backgroundColor: '$bg',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
      },
      expert: {
        backgroundColor: '$bg',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        '@bp2': {
          flexDirection: 'row',
          alignItems: 'start',
        },
      },
      approvedExpert: {
        backgroundColor: '$bg',
        flexDirection: 'column',
      },
      statics: {
        backgroundColor: '$bg',
        flexDirection: 'column',
        justifyContent: 'initial',
        overflow: 'hidden',
      },
      onboarding: {
        backgroundColor: '$bg',
        // marginTop: '0',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '100%',
      },
      followings: {
        backgroundColor: '$bg',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '$displayXSmall',
        color: '$offBlack',
        position: 'relative',
      },
      unsubscribe: {
        backgroundColor: '$bg',
        flexDirection: 'column',
        justifyContent: 'initial',
        minHeight: 'calc(100vh - 156px)',
        '@bp2': {
          minHeight: 'calc(100vh - 110px)',
        },
      },
    },
  },
});
