import React, { useState, useCallback, useEffect } from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';
import {
  BoxText, TextTitle, BoxContent, ErrorText
} from './styled';
import closeIcon from '../../../assets/images/icons/close.svg';

interface ModalDeleteConfirmationModel {
  isOpen?: boolean;
  data?: any;
  title?: string;
  buttonText?: string;
  loading?: boolean;
  description?: string;
  actionClose?: () => void;
  actionContinue?: () => void;
}

const ModalDeleteConfirmation: React.FC<ModalDeleteConfirmationModel> = ({
  actionClose, actionContinue, isOpen, data, title, description, loading, buttonText
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleClose = useCallback(async () => {
    if (actionClose) {
      actionClose();
    }

  }, [actionClose]);

  const actionClear = () => {
    setErrorMessage('');
  };

  const handleContinue = () => {
    if (actionContinue) {
      actionContinue();
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      actionClear();
    }
  }, [isOpen, data]);

  return isOpen ? (
    <Modal
      kindOverlay='gat-modal-overlay--light'
      isOpen={isOpen || false}
      onClose={() => handleClose()}
    >
      <Card css={{ padding: '2.5rem' }}>
        <Button
          title=''
          iconCustom={closeIcon}
          iconWidth='16px'
          iconHeight='16px'
          kind='transparent'
          handleClick={() => handleClose()}
          css={{
            position: 'absolute',
            padding: '1rem 1rem 0 0',
            top: '0',
            right: '0',
            '& img': {
              margin: '0'
            }
          }}
        />
        <BoxText>
          <TextTitle css={{ fontSize: '$displayXSmall' }}>
            {title || ''}
          </TextTitle>
        </BoxText>
        <BoxContent css={{ textAlign: 'center', marginBottom: '2rem' }}>
          {description || ''}
          {errorMessage && (<ErrorText>
            {errorMessage}
          </ErrorText>)}
        </BoxContent>
        <Button
          title={buttonText || ''}
          loading={loading}
          css={{ width: 211, borderRadius: '$radius05' }}
          renderProps={{ onClick: handleContinue }}
        />
      </Card>
    </Modal>
  ) : <div />;
};

ModalDeleteConfirmation.defaultProps = {
  isOpen: false,
  actionClose: undefined,
  data: undefined,
  title: '',
  buttonText: 'Continue',
  description: '',
  actionContinue: undefined,
  loading: false,
};

export default ModalDeleteConfirmation;
