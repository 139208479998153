import React from 'react';
import { FlexContainer, Typography } from '../styled';
import { Input } from '../../../shared/components/Input/style';

interface OrganizationNameSectionProps {
  name: string | undefined;
  onChangeName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const OrganizationNameSection = ({ name, onChangeName }: OrganizationNameSectionProps) => (
  <FlexContainer gap="2" align="center" wrapOnlyTable>
    <FlexContainer direction="column" gap="0_25" maxWidth="adminInfo">
      <Typography size="displayXXSmall" weight="700" lineHeight="lineHeight3">
        Organization name
      </Typography>
      <Typography size="bodyXSmall" color="monochromeBlack400" weight="500">
        This will be displayed on the profile
      </Typography>
    </FlexContainer>
    <Input
      value={name}
      onChange={(e: any) => onChangeName(e.target.value)}
      placeholder="Global Wellness Foundation"
      styleType="adminInput"
    />
  </FlexContainer>
);
