import { Field, Form } from 'formik';
import { styled } from '../../../../config/theme';
import { LayoutCol } from '../../../../shared/components/Layouts/styles';
import { WidgetText } from '../../../../shared/components/Widget/styles';
import twitterIcon from '../../../../assets/images/icons/twitterInput.svg';
import linkedinIcon from '../../../../assets/images/icons/linkedinGray.svg';
import researchGateIcon from '../../../../assets/images/icons/research-gate.svg';

export const LeftContainer = styled('div', {
  width: '100%',
  textAlign: 'left',
});
export const RightContainer = styled('div', {
  width: '100%',
  textAlign: 'left',
});

export const FormStyled = styled(Form, {
  width: '100%',
});

export const FormikInput = styled(Field, {
  background: 'transparent',
  width: '100%',
  border: '1px solid $line',
  borderRadius: '5px',
  padding: '$spaceXSmall $spaceSmall',
  marginY: '$spaceXSmall',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
});

export const ButtonWrapper = styled('div', {
  margin: '2.5rem 0',
  variants: {
    isFooter: {
      true: {
        margin: '0 auto',
        width: '80%',
        // padding: '2.5rem 0',
        borderTop: '1px solid $colors$line',
        alignContent: 'flex-end'
      },
    },
  },
});

export const PlaceholderWithIcon = styled('label', {
  position: 'relative',
  display: 'inline-block',
  width: '100%',

  '&::before': {
    content: '""',
    position: 'absolute',
    left: '10px',
    top: '0',
    bottom: '0',
    width: '20px',
  },
  input: {
    padding: '10px 50px',
  },
  variants: {
    icon: {
      twitter: {
        '&::before': {
          background: `url(${twitterIcon}) center / contain no-repeat`,
          marginLeft: '$spaceXSmall',
        },
      },
      linkedin: {
        '&::before': {
          background: `url(${linkedinIcon}) center / contain no-repeat`,
          marginLeft: '$spaceXSmall',
        },
      },
      researchGate: {
        '&::before': {
          background: `url(${researchGateIcon}) center / contain no-repeat`,
          marginLeft: '$spaceXSmall',
        }
      },
    }
  }
});

export const TextWrapper = styled(WidgetText, {
  color: '$label',
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight3',
  fontWeight: '$weight500',
  variants: {
    kind: {
      header: {
        width: '100%',
        fontSize: '$bodySmall',
        marginBottom: '$spaceSmall',
        padding: '0 $spaceSmall',
      },
    },
  },
});

export const TitleSection = styled('h3', {
  fontWeight: '$weight700',
  fontSize: '$displayXSmall',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing3',
  color: '$offBlack',
  marginBottom: '$spaceSmall',
});

export const SubTitleSection = styled('h4', {
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing3',
  color: '$grayscaleLabel',
  marginBottom: '$spaceSmall',
  fontWeight: '$normal',
});

export const LayoutColForm = styled(LayoutCol, {
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '$spaceLarge',
  alignItems: 'start',
});

export const WrapperForm = styled('h3', {
  width: '100%',
  marginBottom: '2.5rem',
});

export const PublicProfileContainer = styled('div', {
  display: 'flex',
  height: '100%',
  minHeight: 'calc(100vh - 170px)',
  flexDirection: 'column',
  justifyContent: 'space-between',
});
