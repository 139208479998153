import React, { useState } from 'react';
import { ErrorTitleBox } from './styled';
import ErrorBox from '../Forms/InputError/ErrorBox';
import { InputForm } from '../Input/style';

type PasswordInputProps = {
  name?: string;
  hasError: boolean;
};

const StepPassword = (props: PasswordInputProps) => {
  const { name, hasError } = props;
  const [lengthError, setLengthError] = useState<boolean>(true);
  const [capitalError, setCapitalError] = useState<boolean>(true);
  const [numberError, setNumberError] = useState<boolean>(true);

  const checkValidation = (errors?: string) => {
    setLengthError(errors?.includes('Minimun') || false);
    setCapitalError(errors?.includes('Uppercase') || false);
    setNumberError(errors?.includes('Number') || false);
  };

  const validatePassword = (value: string) => {
    let errors = '';
    if (!value || value?.length < 6) {
      errors += 'Minimun';
    }
    if (!value?.match(/^(?=.*[A-Z])/)) {
      errors += 'Uppercase';
    }
    if (!value?.match(/(?=.*[0-9])/)) {
      errors += 'Number';
    }

    checkValidation(errors);
    return lengthError || capitalError || numberError;
  };

  return (
    <>
      <InputForm
        css={{ marginBottom: '$spaceXSmall' }}
        type="password"
        error={hasError}
        validate={validatePassword}
        name={name}
        id={name}
        placeholder="Password"
        autocomplete="new-password"
      />
      <ErrorTitleBox type="step">
        <ErrorBox title="6 characters" valid={!lengthError} />
        <ErrorBox title="1 capital letter" valid={!capitalError} />
        <ErrorBox title="1 number" valid={!numberError} />
      </ErrorTitleBox>
    </>
  );
};

StepPassword.defaultProps = {
  name: 'newPassword',
};

export default StepPassword;
