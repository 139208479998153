import React from 'react';
// import Landing from '../screens/Landing/Landing';
import { Navigate } from 'react-router-dom';
import TwitterCallback from '../screens/Authentication/components/Twitter/TwitterCallback/TwitterCallback';
import Authentication from '../screens/Authentication/Authentication';
import Home from '../screens/Home/Home';
import Profile from '../screens/Profile/Profile';
import Settings from '../screens/settings/settings';
import Discover from '../screens/discover/discover';
import Followers from '../screens/Followers/Followers';
import Followings from '../screens/Followings/Followings';
import ForgotPassword from '../screens/Authentication/components/Email/ForgotPassword/ForgotPassword';
import ChangePassword from '../screens/Authentication/components/Email/ForgotPassword/ChangePassword/ChangePassword';
import Playlist from '../screens/Playlists/playlist';
import OnBoarding from '../screens/OnBoarding/OnBoarding';
import About from '../screens/StaticsPages/About';
import Terms from '../screens/StaticsPages/Terms';
import PrivacyPolicy from '../screens/StaticsPages/PrivacyPolicy';
import ContentCommunityGuidelines from '../screens/StaticsPages/ContentCommunityGuidelines';
import AcceptRequests from '../shared/components/AcceptRequests/AcceptRequests';
import { IRoute } from '../shared/interfaces/interfaces';
import NotFound from '../screens/NotFound/NotFound';
import Unsubscribe from '../screens/Unsubscribe/Unsubscribe';
import { ExpertSeeker } from '../screens/Expert/ExpertSeeker/ExpertSeeker';
import { ExpertOpinions } from '../screens/Expert/ExpertOpinions';
import { ExpertInvitation } from '../screens/Expert/ExpertInvitation';
import { Experts } from '../screens/Expert/Experts';
import OrcidCallback from '../screens/Authentication/components/Orcid/OrcidCallback';
import { Organization } from '../screens/Organization';
import { OrganizationDefaultPage } from '../screens/Organization/default';
import { OrganizationDashboard } from '../screens/Organization/Dashboard';
import { MembersDashboard } from '../screens/Organization/Members/dashboard';
import LoadingProject from '../screens/Organization/default/Loading/LoadingProject';
import ProjectView from '../screens/Organization/Expert/ProjectView';
import { OrganizationBillingDashboard } from '../screens/Organization/Billing/dashboard';
import { ProfileOpinions } from '../screens/Organization/Profile';
import { YourCreditsSection } from '../shared/components/Billing/Credits/YourCreditsSection';
import { AdminView } from '../screens/Organization/Admin';
import ExpertOpinion from '../screens/Organization/default/ExpertOpinion/ExpertOpinion';
import { AcceptInvitation } from '../screens/Organization/AccepInvitation';
import { Earnings } from '../screens/Organization/Expert/Earnings';
import { AddCreditsSectionMaintain } from '../shared/components/Billing/Credits/AddCreditsSectionMaintain';
import ExpertTerms from '../screens/StaticsPages/ExpertTerms';
import TechnicalPage from '../screens/StaticsPages/TechnicalPage';

interface IRoutesConfig extends Array<IRoute> {}

export const routes: IRoutesConfig = [
  {
    index: true,
    path: '/',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <Home />,
  },
  {
    index: false,
    path: '/authentication',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <Authentication />,
  },
  {
    index: false,
    path: '/profile/:slug',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <Profile />,
  },
  {
    path: '/settings',
    caseSensitive: true,
    privacy: ['private'],
    component: () => <Settings />,
  },
  {
    index: false,
    path: '/discover',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: (token: string) => <Discover token={token} />,
  },
  {
    index: false,
    path: '/expert',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <Experts />,
  },
  {
    index: false,
    path: '/new-project',
    caseSensitive: true,
    privacy: ['public', 'private'],
    excludes: ['expert'],
    component: () => <ExpertSeeker />,
  },
  {
    index: false,
    path: '/expert-opinions',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <ExpertOpinions />,
  },
  {
    index: false,
    path: '/auth/twitter/callback',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <TwitterCallback />,
  },
  {
    index: false,
    path: '/auth/orcid/callback/:login',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <OrcidCallback />,
  },
  {
    index: false,
    path: '/forgot-password',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <ForgotPassword />,
  },
  {
    index: false,
    path: '/new-password',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <ChangePassword />,
  },
  {
    path: '/followings',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <Followings />,
    subRoute: {
      path: '/followings/:id',
      caseSensitive: true,
      privacy: ['private'],
      component: () => <Followings />,
    },
  },
  {
    index: false,
    path: '/followers',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <Followers />,
    subRoute: {
      path: '/followers/:id',
      caseSensitive: true,
      privacy: ['private'],
      component: () => <Followers />,
    },
  },
  {
    index: false,
    path: '/onboarding',
    caseSensitive: true,
    privacy: ['public', 'private'],
    component: () => <OnBoarding />,
  },
  {
    index: false,
    path: '/users/:slug/playlists/:playlist_slug',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <Playlist />,
  },
  {
    index: false,
    path: '/users/:slug/playlists',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <Playlist />,
  },
  {
    index: false,
    path: '/playlist/accept-invitation',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <AcceptRequests requestType="playlist_invitation" />,
  },
  {
    index: false,
    path: '/playlist/accept-request',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <AcceptRequests requestType="playlist_request" />,
  },
  {
    index: false,
    path: '/about',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <About />,
  },
  {
    index: false,
    path: '/terms',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <Terms />,
  },
  {
    index: false,
    path: '/experts-terms',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <ExpertTerms />,
  },
  {
    index: false,
    path: '/privacy',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <PrivacyPolicy />,
  },
  {
    index: false,
    path: '/technical',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <TechnicalPage />,
  },
  {
    index: false,
    path: '/content-community-guidelines',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <ContentCommunityGuidelines />,
  },
  {
    index: false,
    path: '/404',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <NotFound />,
  },
  {
    index: false,
    path: '/unsubscribe',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <Unsubscribe />,
  },
  {
    path: '/organization/accept-invitation',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <AcceptInvitation />,
  },
  {
    path: '/project-invitation/accept-invitation',
    caseSensitive: true,
    privacy: ['private', 'public'],
    component: () => <ExpertInvitation />,
  },

  {
    index: false,
    path: '/organization',
    caseSensitive: true,
    privacy: ['private'],
    roles: ['seeker'],
    component: () => <Organization />,
    subRoutes: [
      {
        path: '/organization',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <Navigate to="/organization/profile" replace />,
      },
      {
        path: '/organization/profile',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <ProfileOpinions />,
      },
      {
        path: '/organization/admin',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <AdminView />,
      },
      {
        path: '/organization/projects',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <OrganizationDashboard />,
      },
      {
        path: '/organization/projects/:slug',
        caseSensitive: true,

        privacy: ['private'],
        component: () => <OrganizationDefaultPage />,
      },
      {
        path: '/organization/projects/:slug/loading',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <LoadingProject />,
      },
      {
        path: '/organization/projects/:slug/opinion/:expert_slug',
        caseSensitive: true,
        privacy: ['public', 'private'],
        component: () => <ExpertOpinion />,
      },
      {
        path: '/organization/billing/',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <OrganizationBillingDashboard />,
        subRoutes: [
          {
            path: '/organization/billing/',
            caseSensitive: true,
            privacy: ['private'],
            component: () => <YourCreditsSection />,
          },
          {
            path: '/organization/billing/add-credits',
            caseSensitive: true,
            privacy: ['private'],
            component: () => <AddCreditsSectionMaintain />,
            // component: () => <AddCreditsSection />,
          },
        ],
      },
      {
        path: '/organization/members',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <MembersDashboard />,
      },
    ],
  },
  {
    index: false,
    path: '/experts',
    caseSensitive: true,
    privacy: ['private'],
    roles: ['expert'],
    component: () => <Organization />,
    subRoutes: [
      {
        path: '/experts',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <Navigate to="/experts/projects" replace />,
      },
      {
        path: '/experts/projects',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <OrganizationDashboard />,
      },
      {
        path: '/experts/earnings',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <Earnings />,
      },
      {
        path: '/experts/projects/:slug',
        caseSensitive: true,
        privacy: ['private'],
        component: () => <ProjectView />,
      },
    ],
  },
];

export const pathsOnboarding = ['/onboarding'];
