import React from 'react';
import { PeopleInfo, PeopleName as PeopleStyle, PeopleDate, NameLink, PendingText } from './styles';

interface PeopleNameModel {
  name: string;
  text: string;
  type?: string;
  slug?: string;
  status?: string;
  actionClose?: () => void;
}

type PeopleNameProps = PeopleNameModel &
  React.ComponentProps<typeof PeopleInfo> & {
    kind?: string;
    css?: any;
  };

const PeopleName: React.FC<PeopleNameProps> = ({
  text, name, type, slug, kind, actionClose, status, css
}) => {
  const isLittle = Boolean(type?.includes('little'));

  const handleClick = () => {
    if (actionClose) {
      actionClose();
    }
  };

  return (
    <PeopleInfo kind={kind} css={css}>
      <PeopleStyle onClick={() => handleClick()}>
        {slug ?
          (<NameLink little={isLittle} to={`/profile/${slug}`}>{name}</NameLink>)
          :
          name
        }
        {status === 'sent' ? (<PendingText> (Pending)</PendingText>) : ''}
      </PeopleStyle>
      <PeopleDate little={isLittle}>{text || ''}</PeopleDate>
    </PeopleInfo>
  );
};

PeopleName.defaultProps = {
  slug: undefined,
  type: undefined,
  kind: undefined,
  css: undefined,
  actionClose: () => { },
  status: '',
};

export default PeopleName;
