import { styled } from '../../config/theme';

export const BoxInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '2.5rem'
});

export const Box = styled('div', {});

export const BoxCard = styled('div', {
  width: '100%',
  paddingX: '$spaceLarge',
  '&:last-child': {
    marginBottom: 0,
  }
});

export const TextDiscover = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodyLarge',
  lineHeight: '$lineHeight5',

  '@bp2': {
    fontSize: '$displayXSmall',
    lineHeight: '$lineHeight3',

  }
});

export const TextDiscoverSmall = styled('h4', {
  fontWeight: '$bold',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight3',
});
