import React, { useContext } from 'react';
import { UpperBannerContainer } from '../styles';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import UploadVideoContext from '../../../shared/context/uploadVideoRecording/uploadVideoContext';

const UpperBanner = ({ isLandscape }: { isLandscape?: boolean }) => {
  const { status, error, fullscreen } = useContext(VideoRecordingContext);
  const { uploadProgress } = useContext(UploadVideoContext);

  let text;
  if (error === 'timeLimit') {
    text = 'The recording time should not exceed 45 min';
  } else if (status === 'init') {
    text = 'You can retake this video at anytime';
  } else if (status === 'captured') {
    text = 'We are almost ready, you can check your video here';
  } else if (status === 'uploading') {
    text = `${uploadProgress}%`;
  }

  const backgroundColor = () => {
    let color;
    if (error === 'timeLimit') {
      color = '$redGoogle';
    } else if (status === 'captured') {
      color = '$greenRecording';
    } else if (status === 'capturing') {
      color = '$blurple';
    }
    return color;
  };

  if (!text) return null;

  return (
    <UpperBannerContainer
      css={{
        backgroundColor: backgroundColor(),
        borderRadius: fullscreen || isLandscape ? 0 : undefined,
        height: 'fit-content',
        padding: 0,
      }}
    >
      <p>{text}</p>
    </UpperBannerContainer>
  );
};
UpperBanner.defaultProps = {
  isLandscape: false,
};
export default UpperBanner;
