import { styled } from '../../../../../config/theme';

export const AvatarContainer = styled('div', {
  width: '100%',
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
  marginY: '$spaceSmall'
});

