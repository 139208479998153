import React, { createContext } from 'react';
import { TagModel } from '../../interfaces/interfaces';

export interface ContextProps {
  selectedTags: TagModel[];
  setSelectedTags: React.Dispatch<React.SetStateAction<TagModel[]>>;
}

const SelectedTagContext = createContext<ContextProps>({} as ContextProps);

export default SelectedTagContext;
