import { styled } from '../../../config/theme';
import { Pattern } from '../PatternBackground/PatternBackground';

const iconDefault = '2rem';
const iconXs = '24px';
const iconLittle = '36px';
const iconHeader = '36px';
const iconContent = '45px';
const iconMid = '73px';
const iconBig = '149px';
const iconBigXl = '280px';

export const Wrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  '& img': {
    width: iconDefault,
    height: iconDefault,
    borderRadius: '50%',
    margin: 0,
    backgroundColor: '$offWhite',
    objectFit: 'cover'
  },
  variants: {
    type: {
      xs: {
        '& img': {
          width: iconXs,
          height: iconXs,
        },
      },
      little: {
        '& img': {
          '@bp3': {
            width: iconLittle,
            height: iconLittle,
          }
        },
      },
      header: {
        '& img': {
          width: iconHeader,
          height: iconHeader,
        },
      },
      content: {
        '& img': {
          width: iconContent,
          height: iconContent,
        },
      },
      mid: {
        '& img': {
          width: iconMid,
          height: iconMid,
        },
      },
      big: {
        '& img': {
          width: '100px',
          height: '100px',
          '@bp2': {
            width: '118px',
            height: '118px',
          },
          '@bp3': {
            width: iconBig,
            height: iconBig,
          }
        },
      },
      'big-xl': {
        '& img': {
          width: iconBigXl,
          height: iconBigXl,
        },
      }
    }
  }
});

export const Initials = styled('div', {
  display: 'flex',
  width: iconDefault,
  height: iconDefault,
  alignItems: 'center',
  borderRadius: '50%',
  justifyContent: 'center',
  backgroundColor: '$placeHold',
  fontSize: '$bodyXSmall',
  color: '$offBlack',
  fontWeight: '$weight500',
  variants: {
    type: {
      xs: {
        fontSize: '$bodyXSmall',
        width: iconXs,
        height: iconXs,
      },
      little: {
        '@bp3': {
          fontSize: '$bodySmall',
          width: iconLittle,
          height: iconLittle,
        }
      },
      header: {
        fontSize: '$bodySmall',
        width: iconHeader,
        height: iconHeader,
      },
      content: {
        fontSize: '$bodySmall',
        width: iconContent,
        height: iconContent,
      },
      mid: {
        fontSize: '$displayXSmall',
        width: iconMid,
        height: iconMid,
      },
      big: {
        fontSize: '$displaySmall',
        width: '100px',
        height: '100px',
        '@bp2': {
          width: '118px',
          height: '118px',
        },
        '@bp3': {
          width: iconBig,
          height: iconBig,
        }
      },
      'big-xl': {
        fontSize: '$displayHuge',
        width: iconBigXl,
        height: iconBigXl,
      }
    }
  }
});

export const InitialsPattern = styled(Pattern, {
  display: 'flex',
  width: iconDefault,
  height: iconDefault,
  alignItems: 'center',
  borderRadius: '50%',
  justifyContent: 'center',
  fontSize: '$bodyXSmall',
  color: '$offWhite',
  textShadow: '1px 1px 1px hsla(227, 78%, 60%, 1)',
  variants: {
    type: {
      xs: {
        fontSize: '$bodyXSmall',
        width: iconXs,
        height: iconXs,
      },
      little: {
        '@bp3': {
          fontSize: '$bodySmall',
          width: iconLittle,
          height: iconLittle,
        }
      },
      header: {
        fontSize: '$bodySmall',
        width: iconHeader,
        height: iconHeader,
      },
      content: {
        fontSize: '$bodySmall',
        width: iconContent,
        height: iconContent,
      },
      mid: {
        fontSize: '$bodyMedium',
        width: iconMid,
        height: iconMid,
      },
      big: {
        fontSize: '$displaySmall',
        width: '100px',
        height: '100px',
        '@bp2': {
          width: '118px',
          height: '118px',
        },
        '@bp3': {
          width: iconBig,
          height: iconBig,
        }
      },
      'big-xl': {
        fontSize: '$displayHugeXL',
        width: iconBigXl,
        height: iconBigXl,
      }
    }
  }
});

export const AvatarsWrapper = styled('div', {
  display: 'inline-flex',
  marginRight: '22px'
});

export const AvatarCircle = styled('span', {
  position: 'relative',
  border: '1px solid #fff',
  borderRadius: '50%',
  overflow: 'hidden',
  width: '40px',
  marginRight: '-23px',
  '& img': {
    margin: 0,
    display: 'block'
  },

  variants: {
    type: {
      little: {
        width: '36px',
        marginRight: '-21px',
        '@bp3': {
          width: '40px'
        }
      },
      header: {
        '@bp3': {
          width: '53px',
          marginRight: '-27px',
        }
      },
      mid: {
        '@bp3': {
          width: '53px',
          marginRight: '-27px',
        }
      },
      big: {
        '@bp3': {
          width: '53px',
          marginRight: '-27px',
        }
      },
    }
  }
});

export const WrapperList = styled('div', {
  display: 'flex',
  alignItems: 'center'
});

export const TextPlus = styled('div', {
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  fontWeight: '$weight600',
  color: '$offBlack',
  marginLeft: '$spaceXSmall'
});
