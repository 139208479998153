/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon/Icon';
import { MenuModel } from '../../interfaces/interfaces';
import { ListWrapper, List, ItemList, Box, Text } from './styles';

interface OptionsListModel {
  data?: MenuModel[];
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  clickEvent?: (e?: any) => void;
}

type OptionsListProps = OptionsListModel &
  React.ComponentProps<typeof ListWrapper> & {
    css?: any;
  };

const OptionsList: React.FC<OptionsListProps> = ({ data, open, css, clickEvent }) => {
  const navigate = useNavigate();

  const actionEvent = (event: any, item: MenuModel) => {
    event?.stopPropagation();

    if (clickEvent) {
      clickEvent();
    }

    if (item?.link) {
      navigate(`/${item?.link}`);
    }
    if (item?.action) {
      item?.action();
    }
  };

  return (
    <ListWrapper css={css}>
      <List show={open}>
        {data &&
          data.map((item: MenuModel, index) => (
            <ItemList key={`item-list-${index}`}>
              <Box onClick={(e: any) => actionEvent(e, item)}>
                <Icon img={item?.icon} width="14px" height="14px" alt="Option" />
                <Text>{item?.text}</Text>
              </Box>
            </ItemList>
          ))}
      </List>
    </ListWrapper>
  );
};

OptionsList.defaultProps = {
  data: undefined,
  css: undefined,
  clickEvent: undefined,
};

export default OptionsList;
