import { useContext } from 'react';
import { toast } from "react-toastify";
import { DirectUpload } from "activestorage";
import VideoRecordingContext from '../../context/videoRecording/videoRecordingContext';
import UploadVideoContext from '../../context/uploadVideoRecording/uploadVideoContext';
import ProjectsContext from '../../context/projects/ProjectsContext';
import useGetToken from '../useGetToken';
import { apiUrl } from "../../../constants/authConstants";
import { OpinionsService } from "../../../services/OpinionsService/OpinionsService";
import useGetCurrentUserID from '../getCurrentUserID';

const useUploadVideo = () => {
  const { setUploadProgress } = useContext(UploadVideoContext);
  const { project } = useContext(ProjectsContext);
  const authenticationToken = useGetToken();
  const userId = useGetCurrentUserID();
  const { blob,
          setStatus,
          setOpenModal,
          setProjectOpinion,
          projectOpinion, } = useContext(VideoRecordingContext);

  const createFileFromBlob = (): File | void => {
    if (!blob) return undefined;

    const filetype = blob.type.includes('webm') ? 'webm' : 'mp4';
    const file = new File([blob], `${project?.attributes?.slug}_${userId}.${filetype}`, {
      lastModified: new Date().getTime(), // Provide a timestamp as lastModified
      type: blob.type, // Provide a MIME type
    });
    return file;
  };

  const calculateProgress = (event: any) => {
    const percent = Math.round((event.loaded / event.total) * 100);
    setUploadProgress(percent);
  };

  const directUploadWillStoreFileWithXHR = (request: any) => {
    request.upload.addEventListener("progress", (event: any) => calculateProgress(event));
  };

  const handleUploadVideo = async () => {
    setStatus('uploading');
    const file = createFileFromBlob();
    if (file) {
      const upload = new DirectUpload(file, `${apiUrl.BASE_API}/project_opinions/upload_video`,{directUploadWillStoreFileWithXHR});
      upload.create(async(error, _blob) => {
        if (error) {
          toast.error(error);
        } else if (project) {
          let response: any;
          const data: any = {
            authenticationToken,
            video: _blob.signed_id,
            projectId: project?.id,
          };
          if(projectOpinion) {
            data.projectOpinionId = projectOpinion.id;
            response = await OpinionsService.updateOpinion(data);
          } else {
            response = await OpinionsService.createOpinion(data);
          }
          setProjectOpinion(response.data.data);
          setOpenModal(false);
          toast.success('Video uploaded successfully');
          setStatus('uploaded');
          setUploadProgress(0);
        }
      });
    }
  };

  const setVideoUrl = (videoSelector: string) => {
    if (!blob) return;

    const url = URL.createObjectURL(blob);
    const video = document.getElementById(videoSelector) as HTMLVideoElement;
    if (video) video.src = url;
  };

  return { handleUploadVideo, setVideoUrl };
};

export default useUploadVideo;
