import React, { SVGProps } from 'react';

export const StopRecordingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-3.9375 0C-3.9375 -1.06087 -3.51607 -2.07828 -2.76593 -2.82843C-2.01578 -3.57857 -0.998366 -4 0.0625 -4H24.0625C25.1234 -4 26.1408 -3.57857 26.8909 -2.82843C27.6411 -2.07828 28.0625 -1.06087 28.0625 0V24C28.0625 25.0609 27.6411 26.0783 26.8909 26.8284C26.1408 27.5786 25.1234 28 24.0625 28H0.0625C-0.998366 28 -2.01578 27.5786 -2.76593 26.8284C-3.51607 26.0783 -3.9375 25.0609 -3.9375 24V0Z"
    />
  </svg>
);
