import React from 'react';
// import AddButton from '../AddButton/AddButton'; hide in v1
import { PageWrapper } from './styled';

interface ContainerModel {
  layout: any;
  children: React.ReactNode;
}

const PageContainer: React.FunctionComponent<ContainerModel> = ({ children, layout }) => (
  <PageWrapper layout={layout}>
    {children}
    {/* <AddButton isFloat /> */}
  </PageWrapper>
);
export default PageContainer;

