import React from 'react';
import PageContainer from '../../shared/components/Container/PageContainer';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
import { Title, SubTitle, Text } from './styled';

const TechnicalPage = () => (
  <PageContainer layout="statics">
    <LayoutContainer type="statics" css={{ flexWrap: 'wrap' }}>
      <LayoutCol col="12" css={{ flexDirection: 'column', alignItems: 'baseline' }}>
        <Title>Technical Description</Title>

        <SubTitle>Chrome</SubTitle>
        <Text>
          <b>1.</b> Open Chrome.
        </Text>
        <Text>
          <b>2.</b> At the top right, click More → Settings.
        </Text>
        <Text>
          <b>3.</b> Click Privacy and security → Site settings → Camera or Microphone.
        </Text>
        <Text>
          <b>4.</b> Select the option you want as your default setting.
          <ul>
            <li>Review your blocked and allowed sites.</li>
            <li>
              To remove an existing exception or permission: To the right of the site, click Delete
              .
            </li>
            <li>
              <b>To allow a site that you already blocked:</b>{' '}
              {`Under "Not allowed," select the
              site's name and change the camera or microphone permission to "Allow."`}
            </li>
          </ul>
        </Text>
        <SubTitle>Safari</SubTitle>
        <Text>
          <b>1.</b> Right click on the search/URL bar.2.
        </Text>
        <Text>
          <b>2.</b> Then select Website Settings.
        </Text>
        <Text>
          <b>3.</b> Ensure your camera and microphone are set to Allow.
        </Text>
        <SubTitle>Firefox</SubTitle>
        <Text>
          <b>1.</b> In the Menu bar at the top of the screen, click Firefox and then select
          Preferences or Settings, depending on your macOS version.
        </Text>
        <Text>
          <b>2.</b> Click Privacy & Security on the left.
        </Text>
        <Text>
          <b>3.</b> Scroll down to the Permissions section.
        </Text>
        <Text>
          <b>4.</b> Click the <b>Settings…</b> button for the Camera or Microphone option. Firefox
          displays the websites with saved Allow or Block permission.
        </Text>
        <Text>
          <b>5.</b> Use the <b>Allow/Block</b> selector to change permission for the website. You
          can remove the site by selecting it from the list and clicking the <b>Remove Website</b>.
        </Text>
        <Text>
          <b>6.</b> Select the <b>Block new requests asking to access your camera</b> checkbox if
          you do not want to give any other sites access to your camera.
        </Text>
        <Text>
          <b>7.</b> Click the <b>Save Changes</b> button.
        </Text>

        <SubTitle>Edge</SubTitle>
        <Text>
          <b>1.</b> On your browser tab, select the icon located near the left side of your search
          bar.
        </Text>
        <Text>
          <b>2.</b> When the menu appears, make sure to set Allow for both Camera and Microphone.
        </Text>
      </LayoutCol>
    </LayoutContainer>
  </PageContainer>
);

export default TechnicalPage;
