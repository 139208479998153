import React, { useState, useContext } from 'react';
import Button from "../../../shared/components/Button/Button";
import ModalKB from "../../../shared/components/ModalKB/ModalKB";
import AppContext from '../../../shared/context/app/AppContext';
import { WrapHeaderOptions, TextTotal } from '../styles';
import plusWhite from '../../../assets/images/icons/plus-white.svg';
import { LayoutContainer, LayoutCol } from '../../../shared/components/Layouts/styles';
import useGetToken from '../../../shared/hooks/useGetToken';

interface PlaylistHeaderProps {
  totalKB: Number;
  isPublic: Boolean;
  myPlaylist?: Boolean;
  visible: Boolean;
}

const PlaylistHeader: React.FC<PlaylistHeaderProps> = ({
  totalKB, isPublic, myPlaylist, visible
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { dispatch } = useContext(AppContext);
  const authenticationToken = useGetToken();

  return visible && (
    <WrapHeaderOptions>
      <LayoutContainer css={{
        minHeight: '64px',
        '@bp2': {
          gridTemplateColumns: visible ? '0.4fr 1fr' : 'none'
        },
        '@bp3': {
          padding: '0',
        }
      }}>
        {visible && (
          <>
            <LayoutCol col="5" css={{ justifyContent: 'flex-start' }} >
              <TextTotal>{`${totalKB} Featured in the following playlists`}</TextTotal>
            </LayoutCol>
            <LayoutCol
              col="9"
              css={{ justifyContent: 'center', '@bp2': { justifyContent: 'flex-end' } }}>
              {authenticationToken && myPlaylist && (
                <Button
                  title='Knowledge playlist'
                  kind='add'
                  iconCustom={plusWhite}
                  iconWidth='15px'
                  iconHeight='15px'
                  renderProps={{ onClick: () => setOpen(true), disabled: isPublic }}
                  css={{ maxWidth: '100%', margin: '0', '@bp3': { maxWidth: '100%' } }}
                />
              )}
            </LayoutCol>
            <ModalKB
              isOpen={open}
              actionContinue={() => dispatch({ type: 'SET_RELOAD', payload: true })}
              actionClose={() => setOpen(false)}
            />
          </>
        )}
      </LayoutContainer>
    </WrapHeaderOptions>
  );
};

PlaylistHeader.defaultProps = {
  myPlaylist: undefined
};

export default PlaylistHeader;