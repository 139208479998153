import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '../../../../../../shared/components/Button/Button';
import { Error } from '../../../../../../shared/components/Forms/InputError/styled';
import { ForgotPasswordService } from '../../../../../../services/AuthService/AuthService';
import { Input, WidgetTitle, FormikForm, WidgetText } from '../styled';

const FormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ForgotPasswordForm = (props: { setSent: any }) => {
  const { setSent } = props;
  const handleSubmit = async (email: string) => {
    await ForgotPasswordService.sendEmail(email);
    setSent(true);
  };
  return (
    <>
      <WidgetTitle>Recover your password</WidgetTitle>
      <WidgetText>
        Enter your email and we will send you the instructions to change your password
      </WidgetText>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={FormSchema}
        onSubmit={ async (values, { setSubmitting }) => {
          await handleSubmit(values.email);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <FormikForm>
            <Input
              type="email"
              name="email"
              value={values.email}
              placeholder="Email"
            />
            {errors.email && touched.email ? (<Error>{errors.email}</Error>) : null}
            <Button kind="login" title="Send instructions" type="submit"
                    renderProps={{disabled: isSubmitting}} loading={isSubmitting}
                    css={{width: '100%', height: '50px', marginTop: '1rem',
                          marginBottom: '$spaceLarge', maxWidth: '360px' }} />
          </FormikForm>
        )}
      </Formik> 
    </>
  );
};

export default ForgotPasswordForm;