import { styled } from '../../../config/theme';
import { LayoutContainer } from '../Layouts/styles';

export const WrapperActions = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
});

export const BoxShare = styled('div', {
  alignSelf: 'baseline',
  '& img': {
    marginRight: 0,
  },
});

export const TextContent = styled('p', {
  marginBottom: '1rem',  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  textOverflow: 'ellipsis',
  minHeight: 45,
  overflowWrap: 'anywhere',
});

export const LayoutContainerPeople = styled(LayoutContainer, {
  gap: '$spaceLarge',
  variants: {
    simpleGap: {
      true: {
        '@bp3_3': {
          gridTemplateColumns: '1fr',
        },
        '@bp2': {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))',
        },
        '@bp3': {
          display: 'grid',
          gap: '$spaceLarge',
        },
      },
    },

    showPlaylist: {
      true: {
        '@bp2': {
          display: 'flex',
        },
        '@bp3': {
          display: 'grid',
        },
      },
    },
  },
});
