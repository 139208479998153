import axiosInstance from './utils/axiosInstance';

interface dataInterface {
  id?: number | string;
  page?: number;
  limit?: number;
  attribute?: string;
  authenticationToken?: string;
  filter?: string;
  filterBy?: string;
  name?: string;
  start?: Date;
  endDate?: Date;
  contentType?: string;
  date?: string;
  text?: string;
  title?: string;
  url?: string;
  tagIds?: Array<any>;
}

export const UserService = {
  followings: async (data: dataInterface) => {
    const { page, limit, authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`users/${id}/following`, { params: { page, per_page: limit } })
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  followers: async (data: dataInterface) => {
    const { page, limit, authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`users/${id}/followers`, { params: { page, per_page: limit } })
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
  follow: async (data: dataInterface) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .post(`relationships?followed_id=${id}`, {})
        .then((response) => response?.data?.data || {});
      return { ...results, fail: false };
    } catch (error) {
      return { fail: true };
    }
  },

  unFollow: async (data: dataInterface) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .delete(`relationships/${id}`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  createContent: async (data: dataInterface) => {
    const { authenticationToken, contentType, date, text, title, url } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .post(
          'contents/',
          {},
          { params: { content_type: contentType, date, text, title, url, tag_ids: 1 } },
        )
        .then((response) => response?.data?.data || {});
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't create` };
    }
  },

  editContent: async (data: dataInterface) => {
    const { authenticationToken, id, contentType, date, text, title, url } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .put(
          `contents/${id}`,
          {},
          { params: { content_type: contentType, date, text, title, url, tag_ids: 1 } },
        )
        .then((response) => response?.data?.data || {});
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't edit` };
    }
  },

  deleteContent: async (data: dataInterface) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .delete(`contents/${id}`)
        .then((response) => response?.data?.data || {});
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't delete` };
    }
  },

  searchUser: async (data: dataInterface) => {
    const { page, limit, authenticationToken, name } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .get('users', { params: { name_or_username: name, page, per_page: limit } })
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  updateUserSettings: async (data: dataInterface) => {
    const { authenticationToken, id } = data;

    try {
      const results = await axiosInstance(authenticationToken)
        .put(`users/${id}`, {}, { params: { forward: true } })
        .then((response) => response?.data || {});
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't edit` };
    }
  },

  reverseUserSettings: async (data: dataInterface) => {
    const { authenticationToken, id } = data;

    try {
      const results = await axiosInstance(authenticationToken)
        .put(`users/${id}`, {}, { params: { reverse: true } })
        .then((response) => response?.data || {});
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't edit` };
    }
  },

  createInterests: async (data: dataInterface) => {
    const { authenticationToken, tagIds } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .post('interests', {}, { params: { interests: tagIds } })
        .then((response) => response?.data?.data || {});
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't create` };
    }
  },

  createExpertices: async (data: dataInterface) => {
    const { authenticationToken, tagIds, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .post(`users/${id}/expertises`, {}, { params: { expertises: tagIds } })
        .then((response) => response?.data?.data || {});
      return results;
    } catch (error: any) {
      return { fail: true, message: `Can't create` };
    }
  },

  getTopicsInterests: async (data: dataInterface) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`users/${id}/interests`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },

  getTopicsExpertice: async (data: dataInterface) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken)
        .get(`users/${id}/expertises`)
        .then((response) => response?.data?.data || []);
      return results;
    } catch (error) {
      return [];
    }
  },
};
