import React, { useCallback, useMemo } from 'react';
import LoadDataSection from '../../../shared/components/LoadDataSection/LoadDataSection';
import AuthorsList from '../../../shared/components/Authors/AuthorsList';
import authorsList from './__mocks__/Authors';

const Authors = () => {

  const actionProviders = useCallback(async () => authorsList, []);

  return useMemo(() => (
    <LoadDataSection
      type="outline"
      onAction={actionProviders}
      component={AuthorsList}
      textProp="See more"
      size={3}
    />
  ), [actionProviders]
  );
};

export default React.memo(Authors);