import React from 'react';
import Modal from 'react-modal';
import { ModalDiv } from './styled';

Modal.setAppElement("#root");

interface ModalModel {
  isOpen?: Boolean;
  title?: string;
  kindOverlay?: string;
  closeTime?: number;
  children?: JSX.Element | JSX.Element[] | any;
  shouldCloseOnOverlayClick?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose?: (option?:any) => void;
};

type ModalProps = ModalModel &
  React.ComponentProps<typeof ModalDiv> & {
    css?: any;
    kind?: string;
  };

const ModalComponent: React.FC<ModalProps> = ({
  isOpen, children, title, closeTime, onClose, kind, kindOverlay, css, shouldCloseOnOverlayClick,
}) => (
  <ModalDiv
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel={title}
    overlayClassName={kindOverlay}
    closeTimeoutMS={closeTime}
    kind={kind}
    css={css}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
  >
    {children}
  </ModalDiv>
);

ModalComponent.defaultProps = {
  isOpen: false,
  children: undefined,
  closeTime: 500,
  css: undefined,
  kind: undefined,
  kindOverlay: 'gat-modal-overlay',
  onClose: undefined,
  title: '',
  shouldCloseOnOverlayClick: true,
};

export default ModalComponent;
