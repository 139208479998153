/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React from 'react';
import CardBooks from './CardBooks';
import { Knowledge } from '../../interfaces/interfaces';
import { LayoutContainer, LayoutCol, LayoutColEmpty } from '../Layouts/styles';
import { WrapperCardList } from '../CardResources/styles';

interface Props {
  data: Knowledge[];
  hideFollow?: boolean;
  actionClick: (id: any) => void;
}

const CardBooksList: React.FC<Props> = ({ data, actionClick }) => (
  <WrapperCardList className='books-list'>
    <LayoutContainer sub noPadding css={{ gap: 0 }} >
      {data && data?.map((item: Knowledge, index: number) => (
        <LayoutCol
          key={`books-list-${index}-${item.id}`}
          col='4'
          css={{
            flexGrow: 1,
            '@bp3': { justifyContent: 'initial', flexBasis: '50%'  },
            '@bp6': { flexBasis: '31.33%' }
          }}
        >
          <CardBooks actionClick={actionClick} {...item} />
        </LayoutCol>
      ))}
      <LayoutColEmpty />
      <LayoutColEmpty />
    </LayoutContainer>
  </WrapperCardList>
);

CardBooksList.defaultProps = {
  hideFollow: false
};

export default CardBooksList;
