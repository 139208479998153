import React from 'react';

export const SupportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M10.9699 8.25523C10.7604 8.39926 10.5924 8.59583 10.4829 8.82523C10.3899 8.99588 10.2344 9.12379 10.049 9.18202C9.86359 9.24025 9.66284 9.22426 9.48901 9.13741C9.31518 9.05056 9.18185 8.89963 9.11709 8.71642C9.05233 8.53322 9.06121 8.33203 9.14185 8.15523C9.34185 7.75323 9.65485 7.32923 10.1389 7.00723C10.6269 6.68023 11.2439 6.49023 11.9999 6.49023C12.6579 6.49023 13.3689 6.68523 13.9339 7.10923C14.2488 7.3395 14.5043 7.64166 14.6789 7.99058C14.8536 8.3395 14.9423 8.72508 14.9379 9.11523C14.9379 10.1482 14.4249 10.8352 13.9109 11.3302C13.7209 11.5132 13.5119 11.6882 13.3319 11.8382L13.1849 11.9612C13.0306 12.0874 12.8853 12.2241 12.7499 12.3702V13.7402C12.7499 13.9391 12.6708 14.1299 12.5302 14.2706C12.3895 14.4112 12.1988 14.4902 11.9999 14.4902C11.8009 14.4902 11.6102 14.4112 11.4695 14.2706C11.3289 14.1299 11.2499 13.9391 11.2499 13.7402V12.2672C11.2499 12.0302 11.3169 11.7632 11.4969 11.5312C11.7169 11.2512 11.9829 11.0142 12.2149 10.8172L12.3979 10.6642L12.3989 10.6632C12.5709 10.5202 12.7229 10.3932 12.8689 10.2512C13.2369 9.89623 13.4379 9.57523 13.4379 9.11523C13.4423 8.95798 13.4077 8.80208 13.3372 8.66144C13.2667 8.5208 13.1625 8.3998 13.0339 8.30923C12.7659 8.10823 12.3839 7.99023 11.9999 7.99023C11.5059 7.99023 11.1859 8.11123 10.9699 8.25523ZM12.9999 16.9902C12.9999 17.2555 12.8945 17.5098 12.707 17.6973C12.5194 17.8849 12.2651 17.9902 11.9999 17.9902C11.7346 17.9902 11.4803 17.8849 11.2927 17.6973C11.1052 17.5098 10.9999 17.2555 10.9999 16.9902C10.9999 16.725 11.1052 16.4707 11.2927 16.2831C11.4803 16.0956 11.7346 15.9902 11.9999 15.9902C12.2651 15.9902 12.5194 16.0956 12.707 16.2831C12.8945 16.4707 12.9999 16.725 12.9999 16.9902Z"
      fill="#1C1C28"
    />
    <path
      d="M10.9699 8.25523C10.7604 8.39926 10.5924 8.59583 10.4829 8.82523C10.3899 8.99588 10.2344 9.12379 10.049 9.18202C9.86359 9.24025 9.66284 9.22426 9.48901 9.13741C9.31518 9.05056 9.18185 8.89963 9.11709 8.71642C9.05233 8.53322 9.06121 8.33203 9.14185 8.15523C9.34185 7.75323 9.65485 7.32923 10.1389 7.00723C10.6269 6.68023 11.2439 6.49023 11.9999 6.49023C12.6579 6.49023 13.3689 6.68523 13.9339 7.10923C14.2488 7.3395 14.5043 7.64166 14.6789 7.99058C14.8536 8.3395 14.9423 8.72508 14.9379 9.11523C14.9379 10.1482 14.4249 10.8352 13.9109 11.3302C13.7209 11.5132 13.5119 11.6882 13.3319 11.8382L13.1849 11.9612C13.0306 12.0874 12.8853 12.2241 12.7499 12.3702V13.7402C12.7499 13.9391 12.6708 14.1299 12.5302 14.2706C12.3895 14.4112 12.1988 14.4902 11.9999 14.4902C11.8009 14.4902 11.6102 14.4112 11.4695 14.2706C11.3289 14.1299 11.2499 13.9391 11.2499 13.7402V12.2672C11.2499 12.0302 11.3169 11.7632 11.4969 11.5312C11.7169 11.2512 11.9829 11.0142 12.2149 10.8172L12.3979 10.6642L12.3989 10.6632C12.5709 10.5202 12.7229 10.3932 12.8689 10.2512C13.2369 9.89623 13.4379 9.57523 13.4379 9.11523C13.4423 8.95798 13.4077 8.80208 13.3372 8.66144C13.2667 8.5208 13.1625 8.3998 13.0339 8.30923C12.7659 8.10823 12.3839 7.99023 11.9999 7.99023C11.5059 7.99023 11.1859 8.11123 10.9699 8.25523ZM12.9999 16.9902C12.9999 17.2555 12.8945 17.5098 12.707 17.6973C12.5194 17.8849 12.2651 17.9902 11.9999 17.9902C11.7346 17.9902 11.4803 17.8849 11.2927 17.6973C11.1052 17.5098 10.9999 17.2555 10.9999 16.9902C10.9999 16.725 11.1052 16.4707 11.2927 16.2831C11.4803 16.0956 11.7346 15.9902 11.9999 15.9902C12.2651 15.9902 12.5194 16.0956 12.707 16.2831C12.8945 16.4707 12.9999 16.725 12.9999 16.9902Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M10.9699 8.25523C10.7604 8.39926 10.5924 8.59583 10.4829 8.82523C10.3899 8.99588 10.2344 9.12379 10.049 9.18202C9.86359 9.24025 9.66284 9.22426 9.48901 9.13741C9.31518 9.05056 9.18185 8.89963 9.11709 8.71642C9.05233 8.53322 9.06121 8.33203 9.14185 8.15523C9.34185 7.75323 9.65485 7.32923 10.1389 7.00723C10.6269 6.68023 11.2439 6.49023 11.9999 6.49023C12.6579 6.49023 13.3689 6.68523 13.9339 7.10923C14.2488 7.3395 14.5043 7.64166 14.6789 7.99058C14.8536 8.3395 14.9423 8.72508 14.9379 9.11523C14.9379 10.1482 14.4249 10.8352 13.9109 11.3302C13.7209 11.5132 13.5119 11.6882 13.3319 11.8382L13.1849 11.9612C13.0306 12.0874 12.8853 12.2241 12.7499 12.3702V13.7402C12.7499 13.9391 12.6708 14.1299 12.5302 14.2706C12.3895 14.4112 12.1988 14.4902 11.9999 14.4902C11.8009 14.4902 11.6102 14.4112 11.4695 14.2706C11.3289 14.1299 11.2499 13.9391 11.2499 13.7402V12.2672C11.2499 12.0302 11.3169 11.7632 11.4969 11.5312C11.7169 11.2512 11.9829 11.0142 12.2149 10.8172L12.3979 10.6642L12.3989 10.6632C12.5709 10.5202 12.7229 10.3932 12.8689 10.2512C13.2369 9.89623 13.4379 9.57523 13.4379 9.11523C13.4423 8.95798 13.4077 8.80208 13.3372 8.66144C13.2667 8.5208 13.1625 8.3998 13.0339 8.30923C12.7659 8.10823 12.3839 7.99023 11.9999 7.99023C11.5059 7.99023 11.1859 8.11123 10.9699 8.25523ZM12.9999 16.9902C12.9999 17.2555 12.8945 17.5098 12.707 17.6973C12.5194 17.8849 12.2651 17.9902 11.9999 17.9902C11.7346 17.9902 11.4803 17.8849 11.2927 17.6973C11.1052 17.5098 10.9999 17.2555 10.9999 16.9902C10.9999 16.725 11.1052 16.4707 11.2927 16.2831C11.4803 16.0956 11.7346 15.9902 11.9999 15.9902C12.2651 15.9902 12.5194 16.0956 12.707 16.2831C12.8945 16.4707 12.9999 16.725 12.9999 16.9902Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M10.9699 8.25523C10.7604 8.39926 10.5924 8.59583 10.4829 8.82523C10.3899 8.99588 10.2344 9.12379 10.049 9.18202C9.86359 9.24025 9.66284 9.22426 9.48901 9.13741C9.31518 9.05056 9.18185 8.89963 9.11709 8.71642C9.05233 8.53322 9.06121 8.33203 9.14185 8.15523C9.34185 7.75323 9.65485 7.32923 10.1389 7.00723C10.6269 6.68023 11.2439 6.49023 11.9999 6.49023C12.6579 6.49023 13.3689 6.68523 13.9339 7.10923C14.2488 7.3395 14.5043 7.64166 14.6789 7.99058C14.8536 8.3395 14.9423 8.72508 14.9379 9.11523C14.9379 10.1482 14.4249 10.8352 13.9109 11.3302C13.7209 11.5132 13.5119 11.6882 13.3319 11.8382L13.1849 11.9612C13.0306 12.0874 12.8853 12.2241 12.7499 12.3702V13.7402C12.7499 13.9391 12.6708 14.1299 12.5302 14.2706C12.3895 14.4112 12.1988 14.4902 11.9999 14.4902C11.8009 14.4902 11.6102 14.4112 11.4695 14.2706C11.3289 14.1299 11.2499 13.9391 11.2499 13.7402V12.2672C11.2499 12.0302 11.3169 11.7632 11.4969 11.5312C11.7169 11.2512 11.9829 11.0142 12.2149 10.8172L12.3979 10.6642L12.3989 10.6632C12.5709 10.5202 12.7229 10.3932 12.8689 10.2512C13.2369 9.89623 13.4379 9.57523 13.4379 9.11523C13.4423 8.95798 13.4077 8.80208 13.3372 8.66144C13.2667 8.5208 13.1625 8.3998 13.0339 8.30923C12.7659 8.10823 12.3839 7.99023 11.9999 7.99023C11.5059 7.99023 11.1859 8.11123 10.9699 8.25523ZM12.9999 16.9902C12.9999 17.2555 12.8945 17.5098 12.707 17.6973C12.5194 17.8849 12.2651 17.9902 11.9999 17.9902C11.7346 17.9902 11.4803 17.8849 11.2927 17.6973C11.1052 17.5098 10.9999 17.2555 10.9999 16.9902C10.9999 16.725 11.1052 16.4707 11.2927 16.2831C11.4803 16.0956 11.7346 15.9902 11.9999 15.9902C12.2651 15.9902 12.5194 16.0956 12.707 16.2831C12.8945 16.4707 12.9999 16.725 12.9999 16.9902Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M12 0.990234C18.075 0.990234 23 5.91523 23 11.9902C23 18.0652 18.075 22.9902 12 22.9902C5.925 22.9902 1 18.0652 1 11.9902C1 5.91523 5.925 0.990234 12 0.990234ZM2.5 11.9902C2.5 14.5098 3.50089 16.9262 5.28249 18.7077C7.06408 20.4893 9.48044 21.4902 12 21.4902C14.5196 21.4902 16.9359 20.4893 18.7175 18.7077C20.4991 16.9262 21.5 14.5098 21.5 11.9902C21.5 9.47068 20.4991 7.05432 18.7175 5.27272C16.9359 3.49112 14.5196 2.49023 12 2.49023C9.48044 2.49023 7.06408 3.49112 5.28249 5.27272C3.50089 7.05432 2.5 9.47068 2.5 11.9902Z"
      fill="#1C1C28"
    />
    <path
      d="M12 0.990234C18.075 0.990234 23 5.91523 23 11.9902C23 18.0652 18.075 22.9902 12 22.9902C5.925 22.9902 1 18.0652 1 11.9902C1 5.91523 5.925 0.990234 12 0.990234ZM2.5 11.9902C2.5 14.5098 3.50089 16.9262 5.28249 18.7077C7.06408 20.4893 9.48044 21.4902 12 21.4902C14.5196 21.4902 16.9359 20.4893 18.7175 18.7077C20.4991 16.9262 21.5 14.5098 21.5 11.9902C21.5 9.47068 20.4991 7.05432 18.7175 5.27272C16.9359 3.49112 14.5196 2.49023 12 2.49023C9.48044 2.49023 7.06408 3.49112 5.28249 5.27272C3.50089 7.05432 2.5 9.47068 2.5 11.9902Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M12 0.990234C18.075 0.990234 23 5.91523 23 11.9902C23 18.0652 18.075 22.9902 12 22.9902C5.925 22.9902 1 18.0652 1 11.9902C1 5.91523 5.925 0.990234 12 0.990234ZM2.5 11.9902C2.5 14.5098 3.50089 16.9262 5.28249 18.7077C7.06408 20.4893 9.48044 21.4902 12 21.4902C14.5196 21.4902 16.9359 20.4893 18.7175 18.7077C20.4991 16.9262 21.5 14.5098 21.5 11.9902C21.5 9.47068 20.4991 7.05432 18.7175 5.27272C16.9359 3.49112 14.5196 2.49023 12 2.49023C9.48044 2.49023 7.06408 3.49112 5.28249 5.27272C3.50089 7.05432 2.5 9.47068 2.5 11.9902Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M12 0.990234C18.075 0.990234 23 5.91523 23 11.9902C23 18.0652 18.075 22.9902 12 22.9902C5.925 22.9902 1 18.0652 1 11.9902C1 5.91523 5.925 0.990234 12 0.990234ZM2.5 11.9902C2.5 14.5098 3.50089 16.9262 5.28249 18.7077C7.06408 20.4893 9.48044 21.4902 12 21.4902C14.5196 21.4902 16.9359 20.4893 18.7175 18.7077C20.4991 16.9262 21.5 14.5098 21.5 11.9902C21.5 9.47068 20.4991 7.05432 18.7175 5.27272C16.9359 3.49112 14.5196 2.49023 12 2.49023C9.48044 2.49023 7.06408 3.49112 5.28249 5.27272C3.50089 7.05432 2.5 9.47068 2.5 11.9902Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);
