import React from "react";
import MetaTags from "../../shared/components/MetaTags/MetaTags";

const NotFoundMetaTags: React.FunctionComponent = () => {
  const title = 'Gathersight: page not found';
  const canonical = `https://gathersight.com/404`;

  return (
    <MetaTags
      title={title}
      canonical={canonical}
      notFound
    />
  );
};

export default NotFoundMetaTags;