import React from 'react';
import ModalComponent from '../../../../shared/components/Modal/Modal';
import Card from '../../../../shared/components/Card/Card';
import { FlexContainer, Typography } from '../../styled';
import { CreditsCounterContainer } from './styled';
import Button from '../../../../shared/components/Button/Button';

interface ExpertRequestApproveProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  requiredCredits: number;
  credits: number;
}

export const ExpertRequestApproveModal = ({
  isOpen,
  onConfirm,
  onCancel,
  requiredCredits,
  credits
}: ExpertRequestApproveProps) => (
  <ModalComponent kindOverlay="gat-modal-overlay--blur" isOpen={isOpen} onClose={onCancel}>
    <Card type="modalRequest">
      <FlexContainer direction="column" gap="2">
        <Typography size="bodyMedium" weight="400">
          When you confirm below, our matching engine will now send opinion requests to relevant
          experts.
        </Typography>
        <Typography size="bodyMedium" weight="400">
          Your recordings and transcripts will appear on this project page as soon as they have been
          completed.
        </Typography>
        <Typography size="bodyMedium" weight="400">
          Expert opinion = 6 credits
        </Typography>
        <FlexContainer justify="between">
            <FlexContainer gap="0_75">
              <Typography size="bodyMedium" weight="500">
                Credits Available
              </Typography>
              <CreditsCounterContainer>
                <Typography size="bodySmall" weight="500">
                  {credits}
                </Typography>
              </CreditsCounterContainer>
            </FlexContainer>
            <FlexContainer gap="0_75">
              <Typography size="bodyMedium" weight="500">
                Credits required
              </Typography>
              <CreditsCounterContainer>
                <Typography size="bodySmall" weight="500">
                  {requiredCredits}
                </Typography>
              </CreditsCounterContainer>
            </FlexContainer>
          </FlexContainer>
        <FlexContainer gap="2">
          <Button title="Confirm" size="medium" handleClick={onConfirm} />
          <Button
            title="Cancel"
            size="medium"
            kind="outlineFull"
            handleClick={onCancel}
          />
        </FlexContainer>
      </FlexContainer>
    </Card>
  </ModalComponent>
);
