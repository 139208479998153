import React from 'react';
import {
  BreathingComponents,
  WrapperShimmer,
  WrapperShimmerUser,
  WrapperShimmerUserContainer,
} from './styles';
import { WrapperAuthor } from '../Authors/styles';

const AuthorLoad: React.FC<any> = () => (
  <WrapperAuthor className="author-container">
    <WrapperShimmerUserContainer>
      <WrapperShimmer>
        <BreathingComponents className="circle" width={36} height={36} />
        <WrapperShimmerUser>
          <BreathingComponents className="tab" height={15} width={150} />
          <BreathingComponents className="tab" height={10} width={150} />
        </WrapperShimmerUser>
      </WrapperShimmer>
      <BreathingComponents className="tab" height={35} width={100} />
    </WrapperShimmerUserContainer>
  </WrapperAuthor>
);

export default AuthorLoad;
