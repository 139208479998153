import { Field } from 'formik';
import { styled } from '../../config/theme';
import Card from '../../shared/components/Card/Card';

export const ButtonWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'none',
  variants: {
    widthButton: {
      newOn: {
        width: '100%',
      },
      register: {
        width: '100%',
        '@bp1': {
          width: 'unset'
        }
      }
    }
  },
});
export const Container = styled('div', {
  backgroundColor: '$offBlack',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '1rem',
  justifyItems: 'center',
  height: '100vh',
});

export const Title = styled('div', {
  fontFamily: '$poppins',
  fontStyle: 'normal',
  fontWeight: '$weight700',
  fontSize: '$displayXSmall',
  lineHeight: '$lineHeight5',
  marginBottom: '$sizes$2',
  textAlign: 'center',
  letterSpacing: '$letterSpacing3',
  color: '$offWhite',
});

export const TabsContainer = styled('div', {
  backgroundColor: 'none',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FormikInput = styled(Field, {
  alignSelf: 'center',
  background:'transparent',
  width: '100%',
  border: '1px solid $line',
  borderRadius: '10px',
  padding: '$spaceXSmall',
  margin: '$spaceXSmall 0',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing:'$letterSpacing3',
  fontFamily: '$poppins',

  '@bp4': {
    fontSize: '$bodyXSmall',
  },
});

export const AuthenticationCard = styled(Card, {
  gap: '$spaceLarge',
  padding: '1.563rem $spaceLarge',
  background: '$offWhite',
  '@bp1': {
    padding: '3.125rem',
  }
});
