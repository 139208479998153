import { styled } from '../../config/theme';

export const GridContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '@bp2': {
    flexDirection: 'row',
    marginTop: 0,
  },
  '@bp3': {
    flexDirection: 'row',
  },
  variants: {
    isExpanded: {
      true: {},
    },
  },
});

export const EmptyContainer = styled('div', {
  variants: {
    size: {
      2: {
        marginTop: '2rem',
      },
    },
  },
});

export const Separator = styled('div', {
  width: '100%',
  height: 1,
  background: '#D9DBE9',
  opacity: 0.3,
});

export const BodyContainer = styled('div', {
  width: '100%',
  overflowX: 'hidden',
  minHeight: 'calc(100vh - 70px)',
  background: '$monochromeWhite',
  paddingY: '$spaceBody2_38',
  paddingX: '$spaceLarge',
  '@bp3': {
    paddingX: '$spaceMedium1',
  },
  '&::-webkit-scrollbar': {
    height: '0px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  variants: {
    admin: {
      true: {
        paddingBottom: '5rem',
        '@bp2': {
          paddingBottom: '2rem',
        },
        
        '@bp4': {
          paddingLeft: '$spaceMedium1',
          paddingRight: '15rem',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    },
    isButtonBrowserNavigator: {
      true: {
        marginBottom: '$spaceLarge',
      },
    },
  },
});

export const BtnContainer = styled('div', {
  width: '100%',
  marginY: '$spaceXXLarge',

  '@bp2': {
      justifyContent: 'flex-end', 
      display: 'flex',
  }
  
});

export const Typography = styled('div', {
  fontFamily: '$poppins',
  color: '$monochromeBlack',
  fontSize: '$bodyLarge',
  '@bp6': {
    maxWidth: 650,
  },
  variants: {
    position: {
      center: {
        textAlign: 'center',
      },
    },
    weight: {
      700: {
        fontWeight: '$weight700',
      },
      600: {
        fontWeight: '$weight600',
      },
      500: {
        fontWeight: '$weight500',
      },
      400: {
        fontWeight: '$weight400',
      },
    },
    lineHeight: {
      lineHeight12: {
        lineHeight: '$lineHeight12', // 0.625rem
      },
      lineHeight9: {
        lineHeight: '$lineHeight9', // 1.75rem
      },
      lineHeight10: {
        lineHeight: '$lineHeight10', // 1.25rem
      },
      lineHeight11: {
        lineHeight: '$lineHeight11', // 1.3125rem
      },
      lineHeight1: {
        lineHeight: '$lineHeight1', // 1.375rem
      },
      lineHeight3: {
        lineHeight: '$lineHeight3', // 2rem
      },
      lineHeight2: {
        lineHeight: '$lineHeight2', // 1.5rem
      },
      lineHeight13: {
        lineHeight: '$lineHeight13', // 1.875rem
      },
    },
    letterSpacing: {
      letterSpacing2: {
        letterSpacing: '$letterSpacing2', // 0.047rem
      },
      letterSpacing3: {
        letterSpacing: '$letterSpacing3', // 0.063rem
      },
      letterSpacing8: {
        letterSpacing: '$letterSpacing8', // 0.03rem
      },
      letterSpacing6: {
        letterSpacing: '$letterSpacing6', // 0.0325rem
      },
      letterSpacing7: {
        letterSpacing: '$letterSpacing7', // 0.025rem
      },
    },
    font: {
      inter: {
        fontFamily: '$inter',
      },
    },
    size: {
      bodyXXSSmall: {
        fontSize: '$bodyXXSSmall', // 0.5rem
      },
      bodyXSSmall: {
        fontSize: '$bodyXSSmall', // 0.7rem
      },
      bodyTextXSmall: {
        fontSize: '$bodyTextXSmall', // 0.75rem
      },
      bodyXSmall: {
        fontSize: '$bodyXSmall', // 0.813rem
      },
      bodyListItem: {
        fontSize: '$bodyListItem', // 0.875rem
      },
      bodySmall: {
        fontSize: '$bodySmall', // 0.938rem
      },
      displaySmall: {
        fontSize: '$displaySmall', // 2.25rem
      },
      displayXXSmall: {
        fontSize: '$displayXXSmall', // 1rem
      },
      displayXSmall: {
        fontSize: '$displayXSmall', // 1.5rem
      },
      bodyMedium: {
        fontSize: '$bodyMedium', // 1.125rem
      },
      bodyLarge: {
        fontSize: '$bodyLarge', // 1.25rem
      },
      bodyXLarge: {
        fontSize: '$bodyXLarge', // 1.875rem
      },
    },
    color: {
      monochromeBlack400: {
        color: '$monochromeBlack400',
      },
      monochromeBlack300: {
        color: '$monochromeBlack300',
      },
      labelLight: {
        color: '$labelLight',
      },
      blurple: {
        color: '$blurple',
      },
      secondLabel: {
        color: '$secondLabel',
      },
      green: {
        color: '$greenRecording',
      },
      grayscaleLabel: {
        color: '$grayscaleLabel',
      },
    },
    space: {
      marginBottomSpaceLarge: {
        marginBottom: '$spaceLarge',
      },
      marginTopSpaceLarge: {
        marginTop: '$spaceLarge',
      },
      marginYSpaceLarge2: {
        marginY: '$spaceLarge2',
      },
      marginLSpaceSmall: {
        marginL: '$spaceSmall',
      },
    },
    noWrap: {
      true: {
        whiteSpace: 'nowrap',
      },
    },
    align: {
      center: {
        textAlign: 'center',
      },
    },
    truncate: {
      true: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        textAlign: 'center',
      },
    },
  },
});

export const FlexContainer = styled('div', {
  display: 'flex',

  variants: {
    spacing: {
      marginYSpaceLarge: {
        marginY: '$spaceLarge',
      },
      marginBottomSpaceLarge: {
        marginBottom: '$spaceLarge',
      },
      marginPaddingYSpaceLarge: {
        marginY: '$spaceLarge',
        paddingY: '$spaceLarge',
      },
      marginYXLarge: {
        marginY: '$spaceXLarge',
      },
      summaryBox: {
        borderRadius: '$radius05',
        background: '$grayscaleBackground',
        padding: '$spaceLarge',
        '@bp2': {
          padding: '$spaceXLarge',
        },
        '@bp3': {
          paddingY: '$spaceXLarge',
          paddingX: '$spaceMedium2',
        },
      },
      container: {
        marginBottom: '3rem',
        '@bp2': {
          marginBottom: '0',
        },
      }
    },
    justify: {
      start: {
        justifyContent: 'start',
      },
      center: {
        justifyContent: 'center',
      },
      between: {
        justifyContent: 'space-between',
      },
      end: {
        justifyContent: 'end',
      },
    },
    align: {
      center: {
        alignItems: 'center',
      },
      start: {
        alignItems: 'start',
      },
      end: {
        alignItems: 'end',
      },
    },
    direction: {
      column: {
        flexDirection: 'column',
      },
      row: { flexDirection: 'row' },
      onlyMobileColumn: {
        flexDirection: 'column',
        '@bp2': {
          flexDirection: 'row',
        },
      },
      onlyMobileColumnReverse: {
        flexDirection: 'column-reverse',
        '@bp2': {
          flexDirection: 'row',
        },
      },
      btnShowExperts: {
        flexDirection: 'column',
        '@bp1': {
          flexDirection: 'row',
        },
        '@bp4': {
          flexDirection: 'column',
        },
        '@bp5': {
          flexDirection: 'row',
        },
      },
    },
    line: {
      top: {
        borderTop: '1px solid $grayscaleLine',
      },
    },
    gap: {
      '0_25': {
        gap: '$spaceGap0_25',
      },
      '0_5': {
        gap: '$spaceXXSmall',
      },
      '0_75': {
        gap: '$spaceXSmall2',
      },
      '1': { gap: '$spaceSmall' },
      '1_56': {
        gap: '$spaceGap1_56',
      },
      '1_25': {
        gap: '$spaceLarge',
      },
      '2': {
        gap: '$spaceXLarge',
      },
      '2_5': {
        gap: '$spaceXXLarge',
      },
    },
    wrap: {
      true: {
        flexWrap: 'wrap',
      },
      onlyMobile: {
        flexWrap: 'wrap',
        '@bp2': {
          flexWrap: 'nowrap',
        },
      },
    },
    wrapOnlyTable: {
      true: {
        flexWrap: 'wrap',
        '@bp4': {
          flexWrap: 'unset',
        },
      },
    },
    maxWidth: {
      700: {
        maxWidth: '700px',
      },
      max32: {
        maxWidth: '$32',
      },
      paymentInfo: {
        maxWidth: '100%',
        '@bp2': {
          maxWidth: '$32',
        },
        '@bp4': {
          maxWidth: '100%',
        },
      },
      billingInfo: {
        maxWidth: '100%',
        '@bp2': {
          maxWidth: '$32',
        },
        '@bp4': {
          maxWidth: '100%',
        },
      },
      adminInfo: {
        minWidth: '20rem',
      },
      full: {
        width: '100%',
      },
      '90percent': {
        width: '90%',
      },
    },
    width: {
      100: {
        width: '100%',
      },
      inputPaymentInfo: {
        width: '100%',
        '@bp2': {
          width: '$32',
        },
        '@bp4': {
          width: '$20',
        },
      },
      billingInfo: {
        width: '100%',
        '@bp2': {
          width: '$32',
        },
        '@bp4': {
          width: '48%',
        },
      },
      billingInfo25: {
        width: '46%',
        '@bp2': {
          width: '48%',
        },
        '@bp4': {
          width: '22%',
        },
      },
      billingInfoCity: {
        width: '67%',
        '@bp2': {
          width: '48%',
        },
        '@bp4': {
          width: '28%',
        },
      },
      billingInfoZipCode: {
        width: '25%',
        '@bp2': {
          width: '48%',
        },
        '@bp4': {
          width: '9%',
        },
      },
    },
  },
});

export const EmptyLabel = styled('div', {
  background: '$monochromeBlack25',
  borderRadius: '$radius03',
  color: '$monochromeBlack400',
  fontFamily: '$poppins',
  fontWeight: '$weight500',
  fontSize: '$bodyTextXSmall',
  textAlign: 'center',
  padding: '3px 8px 3px 8px',

  variants: {
    bg: {
      50: {
        background: '$monochromeBlack50',
      },
    },
  },
});

export const ContentContainer = styled('div', {
  display: 'grid',
  gap: '$spaceXLarge',
  paddingBottom: '3rem',
  width: '100%',
  maxWidth: '75rem',
  '@bp4': {
    gridTemplateColumns: '1fr 1fr',
    gap: '$spaceMedium',
  },
  '@bp6': {
    gridTemplateColumns: 'auto 1fr',
  },

  variants: {
    isExpert: {
      true: {
        gridTemplateColumns: '1fr ',
      },
    },
    isButtonBrowserNavigator: {
      true: {
        marginBottom: '10rem',
      },
    },
  },
});

export const Column = styled('div', {
  width: '100%',
  variants: {
    columns: {
      1: {
        maxWidth: '34.375rem', // 550px
      },
      2: {
        maxWidth: '38.75rem', // 620px
      },
    },
    gap: {
      1: {
        gap: '1rem',
      },
      2: {
        gap: '2rem',
      },
      3: {
        gap: '3rem',
      },
    },
    flex: {
      column: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
});
