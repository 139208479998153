import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import {
  InfoContainer,
  Section,
  ElipseIllustrator,
  IllustrationContainer,
  MentionsContainer,
  MiddleImg,
} from '../styled';
import { TextDescription } from '../../styled';
import Button from '../../../../shared/components/Button/Button';

import { Mention } from '../Mention';
import middleImage from '../../../../assets/images/experts/expert.jpg';
import ellipse1Image from '../../../../assets/images/experts/EllipsePhoto1.png';
import ellipse2Image from '../../../../assets/images/experts/EllipsePhoto2.jpg';
import ellipse3Image from '../../../../assets/images/experts/EllipsePhoto3.png';
import ellipse4Image from '../../../../assets/images/experts/EllipsePhoto4.jpg';
import AuthenticationContext from '../../../../shared/context/authentication/authenticationContext';

export const Introduction = () => {
  const { setUserType } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const handleSignUp = () => {
    setUserType('expert');
    navigate('/authentication?sign-up=true');
  };
  return (
    <ExpertContainer bg="offWhite">
      <Section>
        <InfoContainer>
          <h1>Share your knowledge, boost your income</h1>
          <TextDescription type="experts">
          Join a network of scientists, researchers and biotech industry specialists sharing
          their insights and opinion in short 15 - 30 minute video recordings.
          Get paid to help people innovate, solve problems and make progress.
          </TextDescription>
          <Button title="Become an Expert" handleClick={handleSignUp} />
        </InfoContainer>
        <IllustrationContainer>
          <ElipseIllustrator />
          <MentionsContainer>
            <Mention image={ellipse1Image} imagePosition="right">
              “What emerging trends are you seeing in 3D bioprinting research?”
            </Mention>
            <Mention image={ellipse2Image} imagePosition="left">
              “What technique do you use to measure the antioxidant capacity of phenolic compounds?”
            </Mention>
          </MentionsContainer>
          <MiddleImg key="PrincipalExpertPhoto1" src={middleImage} alt="" className="img-lazy" />
          <MentionsContainer>
            <Mention image={ellipse3Image} imagePosition="right">
              “Which liposomal formulations have you used in a clinical setting? Describe your
              experience.”
            </Mention>
            <Mention image={ellipse4Image} imagePosition="left">
              “What specific advancements in genetic tools and techniques do you find most exciting
              for manipulating microbial communities?”
            </Mention>
          </MentionsContainer>
        </IllustrationContainer>
      </Section>
    </ExpertContainer>
  );
};
