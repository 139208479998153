import { styled } from '../../../config/theme';

export const Title = styled('h2', {
  fontWeight: '$weight700',
  fontSize: '$displayXSmall',
  lineHeight: '$lineHeight3',
  letterSpacing: '$letterSpacing2',
});

export const TitleSmall = styled('h3', {
  fontWeight: '$weight700',
  fontSize: '$bodyMedium',
  letterSpacing: '$letterSpacing1',
  lineHeight: '$lineHeight3',
  fontFamily: '$poppins',
});

export const Text = styled('h2', {
  fontWeight: '$weight400',
  fontSize: '$bodyListItem',
  lineHeight: '$lineHeight1',
  color: '$lightColorSpan',
  'span': {
    fontWeight: '$weight500',
  }
});

export const TextSmall = styled('p', {
  fontWeight: '$weight400',
  fontSize: '0.75rem!important',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing1',
  color: '$lightColorSpan',
});

export const LinkSmall = styled('p', {
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
});