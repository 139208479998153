import React from 'react';
import { FlexContainer, Typography } from '../styled';

export const HeaderAdmin = () => (
  <FlexContainer direction="column" gap="1" maxWidth="90percent">
    <Typography size="displayXSmall" weight="700" lineHeight="lineHeight3">
      Organization Details
    </Typography>
    <Typography size="bodyXSmall" color="grayscaleLabel">
      Easily update your Organization name and profile picture here.
    </Typography>
  </FlexContainer>
);
