/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext } from 'react';
import { HomeService, dataInterface } from '../../../services/home.service';
import LoadDataSection from '../../../shared/components/LoadDataSection/LoadDataSection';
import CardResourcesList from '../../../shared/components/CardResources/CardResourcesList';
import ResourcesListLoad from '../../../shared/components/ShimmerComponent/ResourcesListLoad';
import AppContext from '../../../shared/context/app/AppContext';
import { BoxShimmer } from '../../../shared/components/ShimmerComponent/styles';
import { Spinner } from '../../../shared/components/Spinner/spinner';
import HeaderAlternative from './HeaderAlternative';
import { WrapAternativeDiv } from './styled';

interface ContentModel {
  isFeedTablet?: boolean;
  limitFeed?: number;
};

const ContentsAlternative: React.FC<ContentModel> = ({ isFeedTablet, limitFeed }) => {
  const { state } = useContext(AppContext);
  const { authenticationToken, filterContent, reload } = state;
  const size = isFeedTablet ? 25 : 10;

  const actionResources = useCallback(async (
    page: number, limit: number, filterBy?: string, filter?: string) => {
    const parameters: dataInterface = { authenticationToken, page, limit, filterBy, filter };

    const result = await HomeService.getContents(parameters);
    return result;
  }, [authenticationToken, filterContent.category]);

  return (
    <WrapAternativeDiv>
      <HeaderAlternative type='desktop' />
      <LoadDataSection
        onAction={actionResources}
        component={CardResourcesList}
        shimmerComponent={<ResourcesListLoad total={3} />}
        shimmerInfinity={(
          <BoxShimmer type='center'>
            <Spinner className='spinner' type='xs' css={{ padding: '$spaceSmall' }} />
          </BoxShimmer>
        )}
        filterByProp={filterContent?.type}
        propsComponent={{ limitFeed }}
        size={size}
        reload={reload}
        saveData
        isInfinite
        typeButton="outline"
      />
    </WrapAternativeDiv>
  );
};

ContentsAlternative.defaultProps = {
  isFeedTablet: false,
  limitFeed: undefined,
};

export default ContentsAlternative;