/* eslint-disable react/no-array-index-key */
import React from 'react';
import _chunk from 'lodash/chunk';
import PlaylistItem from './PlaylistItem';
import { Knowledge } from '../../interfaces/interfaces';
import { keyExtractor } from '../../utils/helper';
import { WrapperListAuthor } from '../Authors/styles';
import { WrapPlaylist } from './styles';

interface Props {
  data: Knowledge[];
  viewModal?: boolean;
}

const PlaylistList: React.FC<Props> = ({ data, viewModal }) => {
  const newArray = _chunk(data, 3);

  return (
    <div>
      {newArray?.map((ele: any[], index) => (
        <WrapPlaylist key={`${index}-list-colum-playlist`}>
          <WrapperListAuthor
            className="authors-list"
            css={{
              width: '100%',
              '@bp2': {
                flexDirection: viewModal ? 'column!important' : 'row!important',
              },
              '@bp3': {
                flexDirection: 'column!important',
              },
              '@bp4': {
                width: 320
              },
              '@bp6': {
                width: 330
              },
            }}
          >
            {Boolean(ele?.length) && ele.map((item: Knowledge) =>
              <PlaylistItem key={keyExtractor()} {...item} viewModal={viewModal} />)}
          </WrapperListAuthor>
        </WrapPlaylist>
      ))}
    </div>
  );
};

PlaylistList.defaultProps = {
  viewModal: false
};

export default PlaylistList;
