import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { googleAuth } from '../../../../constants/authConstants';
import { GoogleAuthService } from '../../../../services/AuthService/AuthService';
import {  ButtonWrapper } from '../../styled';
import ProcessAuthentication from '../ProcessAuthentication/ProcessAuthentication';
import AppContext from '../../../../shared/context/app/AppContext';
import { GoogleButtonContainer } from './styles';
import AuthenticationContext from '../../../../shared/context/authentication/authenticationContext';
import { useAuthenticationError } from '../../../../shared/hooks/Authentications/useAuthenticationError';

interface CredentialResponse {
  credential?: string
  select_by?:
    | "auto"
    | "user"
    | "user_1tap"
    | "user_2tap"
    | "btn"
    | "btn_confirm"
    | "brn_add_session"
    | "btn_confirm_add_session"
  clientId?: string
}

const Google = (props: { newOn?: Boolean }) => {
  const { newOn } = props;
  const { login } = useContext(AuthenticationContext);
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const inputEl = useRef(null);
  const [windowWidth, setWindowWidth] = useState<Number>(window.innerWidth);
  const { redirectAfterError } = useAuthenticationError();

  const findWidth = () => {
    if(Number(windowWidth) >= 490) return 400;
    if(Number(windowWidth) >= 440 && newOn) return 400;

    return (inputEl.current as any).clientWidth;
  };

  const handleGoogleSignIn = async (response: CredentialResponse) => {
    const res = await GoogleAuthService.postTokens(login, response?.credential);
    if(res.error) {
      redirectAfterError(res.error);
    } else {
      ProcessAuthentication({res, dispatch, navigate, type: !login ? 'sign-up' : ''});
    }
  };

  useEffect(() => {
    setGsiScriptLoaded(false);
  }, [login]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setGsiScriptLoaded(false);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    // if (gsiScriptLoaded) return;
    const initializeGsi = () => {
      // Typescript will complain about window.google
      if (!(window as any).google) return;

      setGsiScriptLoaded(true);
      if (inputEl.current) {
        (window as any).google.accounts.id.initialize({
          client_id: googleAuth.CLIENT_ID,
          callback: handleGoogleSignIn
        });
        // customization attributes
        const text = login ? 'signin_with' : 'signup_with';
        (window as any).google.accounts.id.renderButton(
          inputEl.current,
          { theme: "outline", size: "large", width: findWidth(), text }
        );
      }
    };
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGsi;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);
    // eslint-disable-next-line consistent-return
    return () => {
      // Cleanup function that runs when component unmounts
      (window as any).google?.accounts.id.cancel();
      document.getElementById("google-client-script")?.remove();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gsiScriptLoaded, login]);

  return (
    <ButtonWrapper widthButton={newOn ? 'newOn' : 'register'}>
      <GoogleButtonContainer
        ref={inputEl}
        css={{
          width: 'unset',
          '@media (max-width: 490px)': {
            width: '100%',
          }
        }}
      />
    </ButtonWrapper>  
  );
};

Google.defaultProps = {
  newOn: false,
};

export default Google;