import React, { useContext } from 'react';
import { FlexContainer } from '../styled';
import Button from '../../../shared/components/Button/Button';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import { useProjects } from '../../../shared/hooks/useProjects';

interface DoneEditProjectButtonProps {
  handleEditToggle: () => void;
}

const DoneEditProjectButton = ({ handleEditToggle }: DoneEditProjectButtonProps) => {
  const { name, description, setProject,
          projectQuestions, lengthError } = useContext(ProjectsContext);
  const { updateProject } = useProjects();

  const handleDoneEdit = async () => {
    if (lengthError) return;

    const data = {
      name, description, project_questions_attributes: projectQuestions
    };
    handleEditToggle();
    const project = await updateProject(data);
    setProject(project);
  };

  return(
    <FlexContainer gap="1_56" spacing="marginYXLarge" direction="onlyMobileColumn">
      <Button
        title="Done"
        kind="outline"
        size="small"
        width="auto"
        renderProps={{disabled: lengthError, onClick: handleDoneEdit}}
      />
    </FlexContainer>
  );
};

export default DoneEditProjectButton;