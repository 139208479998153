/* eslint-disable react/no-array-index-key */
import React, { useRef, useMemo } from 'react';
import { MenuModel } from '../../interfaces/interfaces';
import Accordion from '../Accordion/Accordion';
import Icon from '../Icon/Icon';
import { ItemList, Box, Text } from './styles';
import { WidgetBody } from '../Widget/styles';

interface ItemSubListModel extends MenuModel {
  path?: string;
  actionEvent?: any;
}

const ItemSubList: React.FC<ItemSubListModel> = ({
  path,
  subItems,
  link,
  activeIcon,
  icon,
  text,
  actionEvent,
}) => {
  const acordionRef = useRef<HTMLButtonElement | null>(null);

  const ItemButton = useMemo(
    () => (
      <ItemList css={{ width: '100%' }} kind="resources">
        <Icon img={path === link ? activeIcon : icon} width="14px" height="14px" alt="Contents" />
        <Text>{text}</Text>
      </ItemList>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Accordion innerRef={acordionRef} header={ItemButton} type="single" kind="menuList">
      <WidgetBody css={{ display: 'flex', flexDirection: 'column' }}>
        <ul>
          {subItems &&
            subItems.map((item: MenuModel, index) => (
              <ItemList
                onClick={() => actionEvent(item)}
                key={`item-list-subitem-${index}`}
                kind={item?.link && path === item?.link ? 'active' : undefined}
                sublist
              >
                <Box css={{ width: '100%' }}>
                  <Icon
                    img={path === item?.link ? item?.activeIcon : item?.icon}
                    width="14px"
                    height="14px"
                    alt={item?.text}
                  />
                  <Text>{item?.text}</Text>
                </Box>
              </ItemList>
            ))}
        </ul>
      </WidgetBody>
    </Accordion>
  );
};

ItemSubList.defaultProps = {
  path: undefined,
  actionEvent: undefined,
};

export default ItemSubList;
