import React, { useMemo, ReactElement, ReactNode, useState } from 'react';
import SelectedTagsContext from './SelectedTagsContext';
import { TagModel } from '../../interfaces/interfaces';

const SelectedTagProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [selectedTags, setSelectedTags] = useState<TagModel[]>([]);

  const value = useMemo(() => ({ selectedTags, setSelectedTags }), [selectedTags]);

  return <SelectedTagsContext.Provider value={value}>{children}</SelectedTagsContext.Provider>;
};

export default SelectedTagProvider;
