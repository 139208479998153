import { useContext } from 'react';
import AppContext from '../context/app/AppContext';
import { UserModel } from '../context/app/types';

export const useUser = () => {
  const { state, dispatch } = useContext(AppContext);
  const { userData } = state;

  const setUser = (user: UserModel) => {
    dispatch({ type: 'SET_USER', payload: user });
  };

  const isSameUser = (slug: string) => slug === userData.slug;
  return {
    userData,
    userId: userData.userId,
    setUser,
    isExpert: userData.userType === 'expert',
    isSameUser,
  };
};
