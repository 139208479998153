import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { SignUpModel } from '../../../../../shared/context/app/types';
import AppContext from '../../../../../shared/context/app/AppContext';
import { SignUpInput, SignUpForm, LayoutColBlock } from './styled';
import Button from '../../../../../shared/components/Button/Button';
import {
  RegisterService,
  RegisterServiceExpert,
} from '../../../../../services/AuthService/AuthService';
import ProcessAuthentication from '../../ProcessAuthentication/ProcessAuthentication';
import { Error, Info } from '../../../../../shared/components/Forms/InputError/styled';
import TermsAndConditionsText from '../../../../../shared/components/TermsAndConditionsText/TermsAndConditionsText';
import PasswordWithValidations from '../../../../../shared/components/passwordWithValidations/passwordWithValidations';
import AuthenticationContext from '../../../../../shared/context/authentication/authenticationContext';

const AuthenticationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { dispatch } = useContext(AppContext);
  const { userType, setUserType } = useContext(AuthenticationContext);

  const isMember = userType === 'seeker';
  const emailLocked = !!params?.email;

  const handleSubmit = async (data: SignUpModel) => {
    let res;
    if (isMember) {
      res = await RegisterServiceExpert.registerMember(data);
    } else {
      res = await RegisterService.register(data);
    }
    ProcessAuthentication({ res, dispatch, navigate });
  };

  useEffect(() => {
    if (params?.email) {
      // TODO: ask in backend if organization email domain exist.
    } else if (isMember) {
      setUserType('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Formik
        initialValues={{
          email: (params?.email as string) ? decodeURIComponent(params?.email as string) : '',
          password: '',
          firstName: '',
          lastName: '',
        }}
        validationSchema={AuthenticationSchema}
        onSubmit={(values: SignUpModel, { setSubmitting }: FormikHelpers<SignUpModel>) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched }) => (
          <SignUpForm>
            <LayoutColBlock>
              <div>
                <SignUpInput id="firstName" name="firstName" placeholder="First Name" />
                {errors.firstName && touched.firstName ? <Error>{errors.firstName}</Error> : null}
              </div>
              <div>
                <SignUpInput id="lastName" name="lastName" placeholder="Last Name" />
                {errors.lastName && touched.lastName ? <Error>{errors.lastName}</Error> : null}
              </div>
            </LayoutColBlock>
            <SignUpInput
              disabled={emailLocked}
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              isMember={emailLocked}
            />
            {errors.email && touched.email ? <Error>{errors.email}</Error> : null}
            {isMember ? <Info>Email can&apos;t be edited</Info> : null}
            <PasswordWithValidations name="password" />
            <Button
              kind="register"
              title="Sign up"
              type="submit"
              css={{ width: '100%', margin: '0', marginTop: '$spaceXSmall' }}
            />
          </SignUpForm>
        )}
      </Formik>
      <TermsAndConditionsText css={{ width: '100%', maxWidth: '400px' }} />
    </>
  );
};

export default SignUp;
