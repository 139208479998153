import React, { useState } from 'react';
import { InputWrapper } from './styled';
import eyeIcon from '../../../../../assets/images/icons/eye.svg';
import eyeCross from '../../../../../assets/images/icons/eyeCrossed.svg';
import Button from '../../../../../shared/components/Button/Button';
import { FormikInput } from '../../../styled';

type PasswordInputProps = {
  validation?: any;
  placeholder?: string;
  name?: string;
  autocomplete?: string;
};

const PasswordInput = (props: PasswordInputProps) => {
  const { validation, placeholder, name, autocomplete } = props;
  const [showPassword, changeShowHidePassword] = useState(false);

  const changeStatus = (e: any) => {
    e.preventDefault();
    changeShowHidePassword(!showPassword);
  };

  return (
    <InputWrapper>
      <FormikInput
        id={name}
        name={name}
        placeholder={placeholder}
        autoComplete={autocomplete}
        type={showPassword ? 'text' : 'password'}
        validate={validation}
      />
      <Button
        title=""
        iconCustom={showPassword ? eyeCross : eyeIcon}
        kind="show-hide-pass"
        handleClick={(event: any) => changeStatus(event)}
        iconWidth="50px"
        iconHeight="50px"
      />
    </InputWrapper>
  );
};

PasswordInput.defaultProps = {
  validation: undefined,
  placeholder: 'Password',
  name: 'password',
  autocomplete: undefined,
};

export default PasswordInput;
