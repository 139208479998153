import { styled } from '../../../../config/theme';

export const UserTypeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '$offWhite',
  gap: 'calc($spaceSmall * 1.3)',
  borderRadius: '0 0 $radius1 $radius1',
  padding: '$spaceLarge',

  '@bp2': {
    padding: 'calc($spaceLarge * 1.5)'
  }

});

export const Options = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$spaceLarge',

  '@bp1': {
    display: 'inline-flex',
    flexDirection: 'row',
    gap: '$spaceLarge',
  },
});

export const OptionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px solid $monochromeBlack50',
  borderRadius: '$radius05',
  padding: 'calc($spaceLarge * 2) 0.75rem',
  gap: '0.75rem',
  width: '100%',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    border: '1px solid $monochromeBlack200',
  },
});

export const OptionTitle = styled('h3', {
  fontWeight: '$weight600',
  fontSize: '$bodySmall',
  lineHeight: '1.375rem',
  letterSpacing: '0.047rem',
  textAlign: 'center',
});

export const OptionText = styled('h3', {
  fontWeight: '$weight400',
  fontSize: '$bodyXXSmall',
  lineHeight: '1rem',
  letterSpacing: '$letterSpacing2',
  textAlign: 'center',
  color: '$lightColorSpan',
  maxWidth: 200,
  lineBreak: 'anywhere',
});
