import { styled } from '../../../../config/theme';

export const ExpertListContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  justifyContent: 'center',
  gap: '.5rem',
  
  '@bp1': {
    gap: '1rem',
    justifyContent: 'space-between',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: '$spaceLarge',
  },

  '@bp4': {
    gap: '1rem',
    justifyContent: 'space-between',
    gridTemplateColumns: '1fr',
    marginBottom: '$spaceLarge',
  },

  '@bp5': {
    gap: '1.5rem',
    justifyContent: 'space-between',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: '$spaceLarge',
  },
});

export const ExpertItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  padding: '$spaceXXSmall',
  minWidth: '14.5rem',
  maxWidth: '16.5rem',
  overflow: 'hidden',
  wordBreak: 'break-word',
  border: '$monochromeBlack50 1px solid',
  '@bp2': {
    width: '$auto',
  },

  variants: {
    hasOpinion: {
      true: {
        borderRadius: '$radius05',
        cursor: 'pointer',
        '&:hover': {
          background: '$grayscaleInput',
        },
      },
    },
  },
});

export const ExpertImg = styled('img', {
  objectFit: 'cover',
  borderRadius: '100%',
  width: '$4-25',
  height: '$4-25',
  marginBottom: '$spaceSmall',
  variants: {
    hasOpinion: {
      true: {
        border: '4px solid $primaryP3',
      },
    },
  },
});

export const ExpertNoImg = styled('div', {
  objectFit: 'cover',
  color: '$blurpleDark',
  background: '$bodyPale',
  borderRadius: '100%',
  width: '$4-25',
  height: '$4-25',
  marginBottom: '$spaceSmall',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '$weight500',
  variants: {
    hasOpinion: {
      true: {
        border: '4px solid $primaryP3',
      },
    },
  },
});
