import React from 'react';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../../../../shared/components/Modal/Modal';
import Card from '../../../../shared/components/Card/Card';
import { FlexContainer, Typography } from '../../styled';
import Button from '../../../../shared/components/Button/Button';
import { CreditsCounterContainer } from './styled';
import { usePayment } from '../../../../shared/hooks/Organization/usePayment';

interface ExpertRequestInsufficientCreditsProps {
  isOpen: boolean;
  onCancel: () => void;
  requiredCredits: number;
}

export const ExpertRequestInsufficientCreditsModal = ({
  isOpen,
  onCancel,
  requiredCredits,
}: ExpertRequestInsufficientCreditsProps) => {
  const { credits } = usePayment();
  const navigate = useNavigate();

  return (
    <ModalComponent kindOverlay="gat-modal-overlay--blur" isOpen={isOpen} onClose={onCancel}>
      <Card type="modalRequest">
        <FlexContainer direction="column" gap="2">
          <Typography size="displayXSmall" weight="600">
            Please Add Credits
          </Typography>
          <Typography size="bodyMedium" weight="400">
            To request Expert Opinions please add credits to your account.
          </Typography>
          <Typography size="bodyMedium" weight="400">
            Expert opinion = 6 credits
          </Typography>
          <FlexContainer justify="between">
            <FlexContainer gap="0_75">
              <Typography size="bodyMedium" weight="500">
                Credits Available
              </Typography>
              <CreditsCounterContainer>
                <Typography size="bodySmall" weight="500">
                  {credits}
                </Typography>
              </CreditsCounterContainer>
            </FlexContainer>
            <FlexContainer gap="0_75">
              <Typography size="bodyMedium" weight="500">
                Credits required
              </Typography>
              <CreditsCounterContainer>
                <Typography size="bodySmall" weight="500">
                  {requiredCredits}
                </Typography>
              </CreditsCounterContainer>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer gap="2">
            <Button
              title="Add Credits"
              size="medium"
              handleClick={() => navigate('/organization/billing/add-credits')}
            />
            <Button title="Cancel" size="medium" kind="outlineFull" handleClick={onCancel} />
          </FlexContainer>
        </FlexContainer>
      </Card>
    </ModalComponent>
  );
};
