import { Field } from 'formik';
import Select, { components } from 'react-select';
import { styled } from '../../../../../config/theme';

export const FormikInputCard = styled(Field, {
  background: 'transparent',
  width: 'auto',
  margin: '0 auto 0 0',
  borderRadius: '5px',
  border: 'none',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  '@bp2': {
    padding: '$spaceXSmall $spaceSmall',
    marginY: '$spaceXSmall',
  },
  variants: {
    kind: {
      language: {
        fontWeight: '$bold',
        color: '$offBlack',
      },
    },
  },
});

export const CardLanguage = styled('div', {
  background: '$offWhite',
  borderRadius: '5px',
  padding: '$spaceXSmall $spaceSmall',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',
  width: '100%',
  height: '42px',
  marginBottom: '$spaceLarge',
  '.edit input': {
    border: '1px solid $line',
  },
  '@bp2': {
    height: 'auto',
  },
});

export const BoxRox = styled('div', {
  width: '100%',
});

export const SelectWrapper = styled(components.MenuList, {
  background: 'transparent',
  position: 'relative',
  borderRadius: '.25rem',
  width: 'auto',
  height: '100%',
  color: '$label',
  padding: '0px !important',
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  minWidth: 200,
});

export const OptionCustom = styled(components.Option, {
  cursor: 'pointer',
  padding: '0.25rem 1rem',
  textDecoration: 'none',
  '&:first-child': {
    paddingTop: '1rem',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
  },
  '&:last-child': {
    paddingBottom: '1rem',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
  },
  '&:hover': {
    backgroundColor: '$input',
  },
  variants: {
    isFocused: {
      true: {
        backgroundColor: '$line',
      },
    },
  },
});

export const SelectContainer = styled(Select, {
  width: '100%',
  fontWeight: '$normal',
  fontSize: '$bodyXSmall',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
});

export const stylesMenu = {
  borderRadius: '1rem',
  width: '100%',
  minWidth: '200px',
  boxShadow: '0 0 1rem 0 hsl(0, 0%, 0%, 0.07)',
  color: 'hsl(241, 37%, 12%, 1)',
  zIndex: 2,
};
