import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled, fadeIn, fadeInUp } from '../../../config/theme';

export const Container = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  opacity: 0,
  variants: {
    isVisible: {
      true: {
        animation: `${fadeIn} 340ms ease-in-out`,
        opacity: 1,
      },
    },
    section: {
      work: {
        paddingX: '$spaceLarge',
        paddingY: '$spaceMBig',
        background: '$monochromeWhite',
      },
    },
    bg: {
      offWhite: {
        background: '$offWhite',
      },
      monochromeBlack: {
        background: '$monochromeBlack',
      },
      monochromeBlack25: {
        background: '$monochromeBlack25',
      },
      monochromeWhite25: {
        background: '$monochromeWhite25',
      },
      monochromeWhite: {
        background: '$monochromeWhite',
      },
    },
    space: {
      section: {
        paddingY: '$spaceMBig',
        gap: '$spaceMedium',
      },
      section_noGap: {
        gap: 0,
      },
      section_fullPadding: {
        padding: '$spaceMBig $spaceSmall',
        gap: '$spaceMedium',
      },
    },
  },
});

export const Section = styled('div', {
  paddingY: '$spaceMedium',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',
  paddingX: '$spaceSmall',
  animation: `${fadeIn} 340ms ease-in-out`,
  width: '100%',
  maxWidth: 1800,
  '@bp0': {
    paddingX: '$spaceSmall',
  },
  '@bp4': {
    flexDirection: 'row',
    paddingX: '$spaceMBig',
    gap: '$spaceSmall',
  },
});

export const InfoContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '$offWhite',
  maxWidth: '90vw',
  paddingBottom: '$spaceMedium',
  flexGrow: 0,
  gap: '$spaceSmall',
  '@bp4': {
    textAlign: 'left',
    alignItems: 'flex-start',
    maxWidth: '35vw',
  },
  h1: {
    color: '$monochromeBlack',
    fontSize: '2rem',
    fontWeight: '$weight700',
    lineHeight: 'normal',
    letterSpacing: '$letterSpacing3',
    animation: `${fadeInUp} 340ms ease-in-out`,
    maxWidth: 600,
    '@bp2': {
      fontSize: '2.8rem',
    },
  },
  h3: {
    color: '$monochromeBlack',
    fontFamily: '$poppins',
    fontSize: '$bodyLarge',
    fontStyle: 'normal',
    fontWeight: '$weight700',
    lineHeight: 'normal',
    letterSpacing: '$letterSpacing3',
    marginBottom: '$spaceLarge',
  },
  p: {
    maxWidth: 600,
  },
});

export const TextDescription = styled('p', {
  color: '$monochromeBlack',
  fontFamily: '$poppins',
  fontSize: 'clamp(1rem, 4vw, 1.1rem)',
  lineHeight: '$lineHeight3',
  marginTop: '$spaceLarge',
  marginBottom: '$spaceXXLarge',
  fontStyle: 'normal',
  fontWeight: '$normal',
  letterSpacing: '0.5px',
  variants: {
    type: {
      small: {
        color: '$monochromeBlack400',
        fontSize: '$displayXXSmall',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing3',
      },
      label: {
        color: '$labelLight',
        fontSize: '$bodyXSSmall',
        fontWeight: '$weight600',
        lineHeight: '$lineHeight1',
        letterSpacing: '0.5px',
      },
      description: {
        fontSize: '$bodyXSmall',
        color: '$monochromeBlack500',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing6',
      },
      contained: {
        fontSize: '$bodyXSmall',
        color: '$monochromeBlack400',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing6',
        margin: 0,
      },
      inviteSubtitle: {
        marginTop: 0,
        textAlign: 'left',
        marginBottom: '$spaceSmall',
        fontWeight: '$weight600',
        color: '$grayscaleLabel',
        lineHeight: '$lineHeight1',
        textTransform: 'uppercase',
        fontSize: '$bodyXSmall',
        textDecoration: 'underline',
      },
    },
  },
});

export const Subtitle = styled('h3', {
  padding: '0 0.3125rem',
  color: '$secondaryP3',
  textAlign: 'center',
  fontFamily: '$poppins',
  fontSize: '$displaySmall',
  fontStyle: 'normal',
  fontWeight: '$normal',
  lineHeight: 'normal',
  letterSpacing: '$letterSpacing3',
  span: {
    fontWeight: '$weight700',
  },
});

export const MentionContainer = styled('div', {
  boxShadow: '0px 6px 14px 0px #0D163624',
  background: '$monochromeWhite25',
  zIndex: 5,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: '$radius1',
  position: 'relative',
  width: 'fit-content',
  minHeight: 80,
  variants: {
    position: {
      left: {
        flexDirection: 'row-reverse',
        paddingRight: '$spaceSmall',
      },
      right: {
        flexDirection: 'row',
        paddingLeft: '$spaceSmall',
        '@bp2': {
          marginTop: '3rem',
        },
      },
    },
  },
});

export const DivTextContainer = styled('div', {
  display: 'flex',
  width: '100%',
  height: 'auto',
  maxWidth: 400,
  variants: {
    position: {
      right: {
        justifyContent: 'center',
        '@bp4': {
          justifyContent: 'flex-end',
        },
      },
      left: {},
    },
  },
});
export const TextContainer = styled('div', {
  padding: '$spaceXXSmall',
  display: 'flex',
  width: 'fit-content',
  animation: `${fadeIn} 1.2s`,
  '@bp4': {
    width: 'calc(100% - 80px)',
  },
  p: {
    fontSize: '$bodyXSmall',
    color: '$monochromeBlack400',
    letterSpacing: '$letterSpacing5',
  },
  variants: {
    position: {
      left: {
        justifyContent: 'flex-start',
      },
      right: {},
    },
  },
});

export const ImgEllipse = styled(LazyLoadImage, {
  height: 60,
  width: 60,
  display: 'flex',
  borderRadius: '100%',
  objectFit: 'cover',
  '@bp5': {
    height: 70,
    width: 70,
    position: 'absolute',
  },
});

export const ElipseIllustrator = styled('div', {
  width: '40.875rem',
  height: '40.875rem',
  position: 'absolute',
  borderRadius: '40.875rem',
  zIndex: 1,
  background:
    'radial-gradient(50% 50% at 50% 50%, #607FEC 0%, rgba(96, 127, 236, 0.83) 51.05%, rgba(215, 240, 101, 0.00) 100%)',
  '@bp4': {
    borderRadius: '46rem',
  },
});

export const IllustrationContainer = styled('div', {
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  maxWidth: '50rem',
  '@bp4': {
    flexGrow: 2,
  },
});

export const MentionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '15px',
  '@bp2': {
    flexDirection: 'row',
    gap: '3rem',
    alignItems: 'flex-start',
  },
});

export const MiddleImg = styled(LazyLoadImage, {
  transition: 'all 0.3s ease-in-out',
  borderRadius: 5,
  maxWidth: '19rem',
  zIndex: 5,
  marginBottom: '1rem',
  '@bp5': {
    maxWidth: '27rem',
  },
  variants: {
    type: {
      options: {
        maxWidth: '320px',
        '@bp2': {
          maxWidth: '674px',
        },
      },
    },
  },
});
