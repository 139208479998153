import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useProjects } from '../../../../shared/hooks/useProjects';
import { BodyContainer, Column, ContentContainer } from '../../styled';
import { Line } from '../styled';
import { ProjectViewContainer } from '../../Expert/styled';
import ProjectInformation from '../../Expert/ProjectInformation';
import OpinionNotes from '../../Expert/OpinionNotes';
import ExpertInformation from './ExpertInformation';
import ExpertVideo from './ExpertVideo';
import useGetToken from '../../../../shared/hooks/useGetToken';
import VideoRecordingContext from '../../../../shared/context/videoRecording/videoRecordingContext';
import { BackButton } from './styled';
import Icon from '../../../../shared/components/Icon/Icon';
import ArrowLeftIcon from '../../../../assets/images/icons/arrow-left.svg';
import { ExpertInformationLoading } from './ExpertInformationLoading';

const ExpertOpinion = () => {
  const { getProject, project } = useProjects();
  const { projectOpinion } = useContext(VideoRecordingContext);
  const { expert_slug: expertSlug, slug } = useParams();
  const token = useGetToken();
  const navigate = useNavigate();

  const backToProject = () => {
    navigate(`/organization/projects/${slug}`);
  };

  const getExpertData = () => {
    const experts = project?.attributes.project_experts.filter(
      (expertProject) => expertProject.attributes.user_slug === expertSlug,
    );
    if (experts?.length) {
      return experts[0];
    }
    return {};
  };

  useEffect(() => {
    const findProject = async () => {
      await getProject();
    };

    if (token) {
      findProject();
    } else {
      toast.error('You need to be logged in to access this page');
      navigate('/authentication');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectOpinion?.attributes.status === 'created') {
      backToProject();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectOpinion]);

  return (
    <BodyContainer>
      <ProjectViewContainer>
        <BackButton onClick={backToProject}>
          <Icon margin0 img={ArrowLeftIcon} height="10" width="10" /> Back to the Project
        </BackButton>
        {project ? <ExpertInformation userData={getExpertData()} /> : <ExpertInformationLoading />}
        <Line />
        <ProjectInformation />
        <Line />
        <ContentContainer isExpert>
          <Column columns={1} flex="column" gap={3}>
            {project && <ExpertVideo userData={getExpertData()} />}
          </Column>
        </ContentContainer>
        <OpinionNotes />
      </ProjectViewContainer>
    </BodyContainer>
  );
};

export default ExpertOpinion;
