import React from 'react';
import Card from '../../../shared/components/Card/Card';
import Icon from '../../../shared/components/Icon/Icon';
import alert from '../../../assets/images/icons/alert-black.svg';
// import TagsAltenative from './TagsAltenative'; // Hide V1
import { Space, WrapAternativeHeader, TitleAternative, TextNotResults } from './styled';
import { TextNotResultsContent } from '../../../shared/utils/globalText';

type HeaderAlternativeProps = React.ComponentProps<typeof WrapAternativeHeader> & {
  type?: string;
};

const HeaderAlternative: React.FC<HeaderAlternativeProps> = ({ type }) => (
  <WrapAternativeHeader type={type}>
    <Card type='simple' css={{ alignItems: 'flex-start', margin: '0 0 1.5rem' }} >
      <TextNotResults>
        <Icon
          alt="Info"
          img={alert}
          width='20px'
          height='20px'
          css={{ display: 'inline-flex', verticalAlign: 'middle' }}
        />
        {TextNotResultsContent}
      </TextNotResults>
    </Card>
    {/* <TagsAltenative /> */}
    <Space />
    <TitleAternative>
      Recommendations for you.
    </TitleAternative>
  </WrapAternativeHeader>
);

HeaderAlternative.defaultProps = {
  type: undefined
};

export default HeaderAlternative;
