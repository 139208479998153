import React, { useMemo } from 'react';
import { LayoutContainer } from '../../../shared/components/Layouts/styles';
import UserHeader from './UserHeader/UserHeader';
// import CardProfile from "../components/CardProfile";
import { BoxInfo, LayoutColProfile } from '../styled';
import { User } from '../../../shared/interfaces/interfaces';
// import CardProfileMeet from "../components/CardProfileMeet";

interface ProfileHeaderProps {
  userData: User | null;
  loading: boolean;
  myProfile: boolean;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  userData,
  loading,
  myProfile,
}) =>
  useMemo(
    () => (
      <BoxInfo kind="header">
        <LayoutContainer noGap type="profile" css={{ height: '100%' }}>
          <LayoutColProfile col="12">
            <UserHeader
              data={userData}
              loading={loading}
              isFollow={!myProfile}
            />
          </LayoutColProfile>
          {/* <LayoutColCard col='4'> */}
          {/* {myProfile ? <CardProfile /> : <CardProfileMeet />} */}
          {/* </LayoutColCard> */}
        </LayoutContainer>
      </BoxInfo>
    ),
    [loading, myProfile, userData],
  );

ProfileHeader.defaultProps = {
  userData: undefined,
};

export default React.memo(ProfileHeader);
