import React from 'react';
import { styled } from '../../../../config/theme';
import { Typography } from '../../../../screens/Organization/styled';

const Container = styled('div', {
  marginTop: '$spaceLarge'
});

const TextContainer = styled('div', {
  marginTop: '$spaceLarge',
  width: 'fit-content',
  height: 'auto',
  background: '$monochromeBlack25',
  padding: '$spaceLarge',
  p: {
    color: '$monochromeBlack400',
    fontWeight: '$weight500',
    fontSize: '$bodyCard',
  },
  a: {
    color: '$blurple',
    fontWeight: '$weight600',
    textDecoration: 'none',
  },
});

export const BillingHistory = () => (
  <Container>
    <Typography
      size="displayXSmall"
      weight="700"
      lineHeight="lineHeight3"
      letterSpacing="letterSpacing3"
    >
      Billing History
    </Typography>
    <TextContainer>
      <p>
        Billing history is coming soon. In the meantime, please email{' '}
        <a href="mailto:customerteam@gathersight.com">customerteam@gathersight.com </a>
        to request any previous invoices.
      </p>
    </TextContainer>
  </Container>
);
