import React, { useContext } from 'react';
import { FlexContainer, Typography } from '../styled';
import { ProjectsIcon } from '../Sidebar/icons/ProjectsIcon';
import { MembersIcon } from '../Sidebar/icons/MembersIcon';
import { ImageOrganizationContainer, ImgOrganizationAvatar } from './styled';
// import { LinkedInIcon } from './icons/LinkedInIcon';
// import { TwitterIcon } from './icons/TwitterIcon';
// import { EmailIcon } from './icons/EmailIcon';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { useOrganization } from '../../../shared/hooks/Organization/useOrganization';
import AppContext from '../../../shared/context/app/AppContext';
import { useProjects } from '../../../shared/hooks/useProjects';
import { useMember } from '../../../shared/hooks/Organization/useMember';
import { useImageVerification } from '../../../shared/hooks/useImageVerification';
import { NoImgOrganizationAvatar } from '../Sidebar/styled';
import { getInitials } from '../../../shared/utils/helper';

export const OrganizationInformation = () => {
  const { organizationInformation } = useOrganization();
  const { projects } = useProjects();
  const { members } = useMember();
  const { state } = useContext(AppContext);
  const { userData } = state as any | undefined;
  const { isValidImage } = useImageVerification(organizationInformation.image as string);
  const { width } = useWindowSize();
  const isTablet = width < 769;
  const isMobile = width < 450;

  const renderAvatar = () => {
    if (isValidImage) {
      return (
        <ImageOrganizationContainer>
          <ImgOrganizationAvatar src={organizationInformation.image} />
        </ImageOrganizationContainer>
      );
    }
    return (
      <NoImgOrganizationAvatar isProfilePage>
        {getInitials(organizationInformation.name as string)}
      </NoImgOrganizationAvatar>
    );
  };

  return (
    <FlexContainer justify="start" direction="column" spacing="marginYSpaceLarge" gap="2_5">
      {userData.userType === 'seeker' && (
        <Typography
          size={isTablet ? 'bodyLarge' : 'displayXSmall'}
          weight="700"
          lineHeight="lineHeight3"
          letterSpacing="letterSpacing3"
        >
          Organization information
        </Typography>
      )}
      <FlexContainer align="center" gap="1_25" wrap>
        <FlexContainer gap="1_25">
          {renderAvatar()}
          {isMobile && (
            <Typography size="displayXSmall" lineHeight="lineHeight2">
              {organizationInformation.name}
            </Typography>
          )}
        </FlexContainer>
        <FlexContainer direction="column" gap="0_75">
          {!isMobile && (
            <Typography size="displayXSmall" lineHeight="lineHeight2">
              {organizationInformation.name}
            </Typography>
          )}
          <FlexContainer gap="1">
            <FlexContainer gap="0_5">
              <ProjectsIcon width={24} height={24} />
              <Typography size="bodySmall" weight="700" lineHeight="lineHeight2">
                {projects.length}
              </Typography>
              <Typography size="bodySmall" lineHeight="lineHeight2">
                Projects
              </Typography>
            </FlexContainer>
            <FlexContainer gap="0_5">
              <MembersIcon width={24} height={24} />
              <Typography size="bodySmall" weight="700" lineHeight="lineHeight2">
                {members.filter((member) => member.attributes.status !== 'sent').length}
              </Typography>
              <Typography size="bodySmall" lineHeight="lineHeight2">
                Members
              </Typography>
            </FlexContainer>
          </FlexContainer>
          {/* <FlexContainer gap="0_75">
            <IconCircleContainer LinkedIn>
              <LinkedInIcon />
            </IconCircleContainer>
            <IconCircleContainer>
              <TwitterIcon />
            </IconCircleContainer>
            <IconCircleContainer>
              <EmailIcon />
            </IconCircleContainer>
          </FlexContainer> */}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
