import { styled } from '../../../../config/theme';

export const RoundedIconContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '4rem',
  width: '4rem',
  borderRadius: '4rem',
  background: '$secondaryP3',
  marginBottom: '$spaceSmall',
  '@bp2': {
    height: '5rem',
    width: '5rem',
    borderRadius: '5rem',
    marginRight: '20px',
  },
  '@bp4': {
    height: '4rem',
    width: '4rem',
    borderRadius: '4rem',
    marginRight: '0',
  },
});

export const ItemsContainers = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '54px',
  '@bp4': {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
});

export const ItemContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '54px',
  maxWidth: '350px',

  '@bp2': {
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: '640px',
    p: {
      textAlign: 'start',
    },
  },
  '@bp4': {
    flexDirection: 'column',
    p: {
      textAlign: 'center',
    },
  },
});

export const TextContainer = styled('p', {
  color: '$monochromeWhite',
  textAlign: 'center',
  fontFamily: '$poppins',
  fontSize: '$bodySmall',
  fontStyle: 'normal',
  fontWeight: '$weight500',
  lineHeight: 'normal',
  letterSpacing: '$letterSpacing2',
  maxWidth: '320px',
  '@bp2': {
    textAlign: 'start',
    maxWidth: '540px',
  },
  '@bp4': {
    textAlign: 'center',
    maxWidth: '331px',
  },
});
