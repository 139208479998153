/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { InputWrapper } from './styled';
import Results from './Results/Results';
import InputSearch from '../InputSearch/InputSearch';

interface SearchModel {
  actionSelect?: (data: string) => Promise<any>;
  placeholder: string;
  customIcon?: string;
  css?: any;
};

const SearchResults = (props: SearchModel) => {
  const [data, setData] = useState<Array<any>>([]);
  const [value, setValue] = useState<string>('');
  const [reset, setReset] = useState<boolean>(false);
  const {actionSelect, placeholder, customIcon, css } = props;
  
  const handleEvent = async (name: string) => {
    setValue(name);
    if (actionSelect) {
      await actionSelect(name);
    }
  };

  useEffect(() => {
    if (reset) {
      setData([]);
    }
  }, [reset]);

  return (
    <InputWrapper css={css}>
      <Results
        data={data}
        value={value}
        actionSelect={actionSelect}
        setData={setData}
        icon={customIcon}
      />
      <InputSearch actionSearch={handleEvent}
                   placeholder={placeholder}
                   setReset={setReset}
                   value={value}
                   setValue={setValue} />
    </InputWrapper>
  );
};

SearchResults.defaultProps = {
  actionSelect: undefined,
  customIcon: undefined,
  css: undefined,
};

export default SearchResults;
