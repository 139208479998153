import * as RadixSelect from '@radix-ui/react-select';

import { styled, keyframes } from '../../../config/theme';

export const Select = styled('select', {
  width: '100%',
  height: '100%',
  background: 'transparent',
  padding: '0 1rem 0 .5rem',
  border: 'none',
  outline: 'none',
  marginRight: '1rem',
  color: '$label',
});

export const SelectItem = styled('option', {
  display: 'flex',
  color: '$label',
});

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

export const SelectRadix = styled(RadixSelect.Root, {
  position: 'relative',
});

export const Trigger = styled(RadixSelect.Trigger, {
  display: 'inline-flex',
  justifyContent: 'space-between',
  minHeight: '3rem',
  height: '3rem',
  borderRadius: '$radius05',
  border: '1px solid $line',
  fontFamily: '$poppins',
  '&:focus': {
    border: '1px $blurple solid',
  },
  background: 'transparent',
  width: '100%',
  minWidth: '5.3rem',
  alignItems: 'center',
  cursor: 'pointer',
  paddingX: '$spaceXSmall',
  outline: 'none',
  transition: '180ms box-shadow ease-in-out',
  color: '$label',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  fontSize: '$bodySmall',

  WebkitAppearance: 'none',
  margin: 0,
  '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '&:hover, &:focus': {
    outline: 'none',
  },
});

export const Value = styled(RadixSelect.Value, {
  flex: 1,
});

export const Icon = styled(RadixSelect.Icon, {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '$spaceXSmall',
});

export const Content = styled(RadixSelect.Content, {
  animation: `${fadeIn} 0.3s ease`,
  background: '$offWhite',
  borderRadius: '$radius03',
  fontSize: '$bodyListItem',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  marginTop: '$spaceXXLarge',
});

export const ScrollButton = styled(RadixSelect.ScrollUpButton, {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
});

export const Viewport = styled(RadixSelect.Viewport, {
  marginTop: 0,
});

export const Group = styled(RadixSelect.Group, {
  maxHeight: '20rem',
});

export const Label = styled(RadixSelect.Label, {
  fontWeight: 'bold',
  marginBottom: '$spaceXSmall',
});

export const Separator = styled(RadixSelect.Separator, {
  backgroundColor: '#ccc',
  marginY: '$spaceXSmall',
});

export const StyledItem = styled(RadixSelect.Item, {
  padding: '$spaceXSmall',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$blurple',
    color: '$offWhite',
  },
  '&[data-state="active"]': {
    backgroundColor: '$blurple',
    color: '$offWhite',
  },
});

export const StyledItemText = styled(RadixSelect.ItemText, {});

export const StyledItemIndicator = styled(RadixSelect.ItemIndicator, {
  marginLeft: '$spaceXSmall',
});

export const ArrowImg = styled('img', {});
