import { Dispatch, SetStateAction, createContext } from 'react';

export interface ContextProps {
  uploadProgress: number;
  setUploadProgress: Dispatch<SetStateAction<number>>;
}

const initialState: ContextProps = {
  uploadProgress: 0,
  setUploadProgress: () => {},
};

const UploadVideoContext = createContext<ContextProps>(initialState as ContextProps);

export default UploadVideoContext;