import React from 'react';
import ModalComponent from '../../../shared/components/Modal/Modal';
import Card from '../../../shared/components/Card/Card';
import { FlexContainer, Typography } from '../styled';
import Button from '../../../shared/components/Button/Button';

interface ConfirmDeleteProjectModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDeleteProjectModal = ({
  isOpen,
  onConfirm,
  onCancel,
}: ConfirmDeleteProjectModalProps) => (
  <ModalComponent kindOverlay="gat-modal-overlay--blur" isOpen={isOpen} onClose={onCancel}>
    <Card type="deleteProject">
      <FlexContainer direction="column" gap="2_5">
        <Typography align="center" weight="600" size="displayXSmall" letterSpacing="letterSpacing3">
          Are you sure you want to delete your project?
        </Typography>
        <FlexContainer justify="between" gap="0_75">
          <Button kind="deleteConfirm" title="Yes, delete" handleClick={onConfirm} />
          <Button kind="deleteConfirmOutline" title="Cancel" handleClick={onCancel} />
        </FlexContainer>
      </FlexContainer>
    </Card>
  </ModalComponent>
);
