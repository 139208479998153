import React, { useContext, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ModalComponent from '../../../../shared/components/Modal/Modal';
import Card from '../../../../shared/components/Card/Card';
import { FlexContainer, Typography } from '../../styled';
import Button from '../../../../shared/components/Button/Button';
import { InviteMemberModel } from '../../../../shared/context/app/types';
import { FormInput } from '../../../../shared/components/Input/FormInput';
import useWindowSize from '../../../../shared/hooks/useWindowSize';
import AppContext from '../../../../shared/context/app/AppContext';
import { CloseIconModal } from '../../../../shared/components/Icon/CloseIconModal';

interface InviteMemberModalProps {
  isOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  onConfirm: (data: any) => void;
  onCancel: () => void;
}
export const InviteMemberModal = ({ isOpen, onCancel, onConfirm }: InviteMemberModalProps) => {
  const { state } = useContext(AppContext);
  const { width } = useWindowSize();
  const isMobile = width < 720;
  const { email } = state.userData;
  const emailDomain = email?.split('@')[1] || '';
  const targetDomain = emailDomain?.split('.')[0] || '';
  const [isDisabled, setIsDisabled] = useState(true);

  const validateTargetDomain = (input: string): boolean => {
    const inputLength = input.length;
    const domainLength = emailDomain.length || 0;

    if (inputLength > domainLength) {
      return false;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < inputLength; i++) {
      if (input[i] !== emailDomain[i]) {
        return false;
      }
    }
    return true;
  };

  const hasError = (emailValue: string) => {
    const mailParts = emailValue.split('@');
    if (mailParts.length === 2) {
      const valueDomainPart = mailParts[1];
      if (emailDomain?.includes(valueDomainPart) && validateTargetDomain(valueDomainPart)) {
        if (emailDomain === valueDomainPart) {
          setIsDisabled(false);
          return false;
        }
      } else {
        setIsDisabled(true);
        return true;
      }
    }
    setIsDisabled(true);
    return false;
  };

  const AuthenticationSchema = Yup.object().shape({
    email: Yup.string()
      .test('Corporate email', `Please use the “${targetDomain}.com” domain.`, (value) => {
        if (value) {
          const mailParts = value.split('@');
          if (mailParts.length === 2) {
            const valueDomainPart = mailParts[1];
            if (emailDomain?.includes(valueDomainPart) && validateTargetDomain(valueDomainPart)) {
              if (emailDomain === valueDomainPart) {
                // able button
                return true;
              }
            } else {
              return false;
            }
          }
        }
        return false;
      })
      .test('Same email', `You are already a member.`, (value) => {
        if (value === email) {
          return false;
        }
        return true;
      }),
  });

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async (data: InviteMemberModel) => {
    onConfirm(data);
  };

  return (
    <ModalComponent kindOverlay="gat-modal-overlay--blur" isOpen={isOpen} onClose={onCancel}>
      <Card type="member">
        <FlexContainer justify="end">
          <CloseIconModal onClick={onCancel} />
        </FlexContainer>
        <FlexContainer direction="column" gap="0_75">
          <FlexContainer justify="between">
            <Typography size="displayXSmall" weight="700">
              Invite new Member
            </Typography>
          </FlexContainer>

          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={AuthenticationSchema}
            validateOnBlur={false}
            onSubmit={(
              values: InviteMemberModel,
              { setSubmitting }: FormikHelpers<InviteMemberModel>,
            ) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ errors, touched, values }) => (
              <FlexContainer
                gap="0_5"
                direction="onlyMobileColumn"
                align={isMobile ? 'center' : 'end'}
              >
                <FlexContainer direction="column" gap="1_25">
                  <Typography size="displayXXSmall" color="grayscaleLabel" weight="400">
                    Invite a new member to join your organization.
                  </Typography>
                  <Form>
                    <FormInput
                      errors={hasError(values.email) ? errors : {}}
                      touched={touched}
                      placeholder="contact@domain.com"
                      name="email"
                      member
                      space="none"
                    />
                  </Form>
                </FlexContainer>
                <Button
                  css={{ marginBottom: '1.8rem' }}
                  title="Send invite"
                  width={isMobile ? 'full' : 'auto'}
                  noTransition
                  renderProps={{
                    onClick: () => handleSubmit(values),
                    disabled: isDisabled,
                  }}
                />
              </FlexContainer>
            )}
          </Formik>
        </FlexContainer>
      </Card>
    </ModalComponent>
  );
};
