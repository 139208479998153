import { styled } from '../../../../config/theme';

export const Wrapper = styled('div', {
  backgroundColor: '$offWhite',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$radius1',
  width: '100%',
  transition: 'all 0.3s ease-in-out',

  '@bp1': {
    maxWidth: '530px',
  }
});
