import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BodyContainer, Column, ContentContainer, FlexContainer, Typography } from '../styled';
import { useProjects } from '../../../shared/hooks/useProjects';
import LoadingComponent from '../../../shared/components/Loading/Loading';
import useGetUserType from '../../../shared/hooks/getUserType';
import HeaderProject from '../default/HeaderProject';
import { Questions } from '../default/Questions';
import Opinion from './Opinions/Opinion';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import SubmitOpinion from './Opinions/SubmitOpinion';
import ProjectDocuments from './ProjectDocuments';

export const ProjectView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { getProject, project } = useProjects();
  const userType = useGetUserType();
  const { projectOpinion } = useContext(VideoRecordingContext);
  const opinionStatus = projectOpinion?.attributes?.status;

  useEffect(() => {
    const findProject = async () => {
      const response = await getProject();
      if (!response) navigate('/404');
      setIsLoading(false);
    };

    findProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userType !== 'expert') {
      let url = window.location.pathname;
      url = url.replace('experts', 'organization');
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <BodyContainer>
      <ContentContainer>
        <Column columns={1} flex="column" gap={3}>
          <HeaderProject isEditing={false} expert />
          <Questions editable={false} />
        </Column>
        <Column columns={2} flex="column" gap={3}>
          <Column columns={1} flex="column" gap={1}>
            <Typography
              size="displayXXSmall"
              weight="700"
              lineHeight="lineHeight3"
              letterSpacing="letterSpacing3"
            >
              Recording Tool
            </Typography>
            <Opinion />
            <Column columns={1} flex="column" gap={1} style={{ marginTop: '.5rem' }}>
              {project?.attributes.expert_status === 'pending' && (
                <Typography
                  size="bodyXSmall"
                  weight="500"
                  lineHeight="lineHeight1"
                  letterSpacing="letterSpacing6"
                  color="monochromeBlack400"
                >
                  * All recordings are saved until you submit the opinions to the organization
                </Typography>
              )}
              {opinionStatus && opinionStatus !== 'sent' && <SubmitOpinion />}
            </Column>
          </Column>
          <FlexContainer direction="column" gap="2">
            <ProjectDocuments />
          </FlexContainer>
        </Column>
      </ContentContainer>
    </BodyContainer>
  );
};

export default ProjectView;
