import React, { useState, useContext, useRef } from 'react';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import MenuList from './MenuList';
import { MenuModel } from '../../interfaces/interfaces';
import gearIcon from '../../../assets/images/icons/gear.svg';
import gearActiveIcon from '../../../assets/images/icons/gear-blue.svg';
import kbIcon from '../../../assets/images/icons/bookmark-light.svg';
import kbActiveIcon from '../../../assets/images/icons/bookmark-blue.svg';
import exitIcon from '../../../assets/images/icons/exit.svg';
import donutIcon from '../../../assets/images/icons/donut.svg';
import menuIcon from '../../../assets/images/icons/hamburger-menu.svg';
import closeIcon from '../../../assets/images/icons/close.svg';
import resourcesIcon from '../../../assets/images/icons/resources.svg';
import privacyIcon from '../../../assets/images/icons/lock-2.svg';
import termsIcon from '../../../assets/images/icons/sheild.svg';
import alertIcon from '../../../assets/images/icons/alert.svg';
import { getUserName } from '../../utils/helper';
import AppContext from '../../context/app/AppContext';
import { Wrapper, BoxHover, WrapperHamMenu, ContentMenu } from './styles';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import TooltipCard from '../TooltipCard';
import { useCloseSession } from '../../hooks/Authentications/useCloseSession';

interface MenuPopoverModel {}

type MenuPopoverProps = MenuPopoverModel & React.ComponentProps<typeof Wrapper> & {};

const MenuPopover: React.FunctionComponent<MenuPopoverProps> = () => {
  const refMenu = useRef<HTMLDivElement | null>(null);
  const [openMobile, setOpenMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dataSublist, setDataSublist] = useState<MenuModel | undefined>();
  const isLogged = useIsLoggedIn();
  const { state } = useContext(AppContext);
  const { closeSession } = useCloseSession();
  const { userData } = state as any | undefined;
  const dataName = {
    username: userData?.username,
    first_name: userData?.firstName,
    last_name: userData?.lastName,
  };
  const finalName = getUserName(dataName);

  const handleClose = () => {
    setOpenMobile(false);
    setDataSublist(undefined);
  };

  useOnClickOutside(refMenu, () => handleClose());

  const optionsDefault = {
    text: 'Resources',
    icon: resourcesIcon,
    activeIcon: resourcesIcon,
    link: '',
    subItems: [
      {
        text: 'About',
        icon: alertIcon,
        activeIcon: alertIcon,
        link: 'about',
      },
      {
        text: 'Help',
        icon: donutIcon,
        activeIcon: donutIcon,
        link: 'https://gathersight.notion.site/Hello-and-thank-you-for-coming-15f8fba060dd4541a5bce70293da8cb0',
        external: true,
      },
      {
        text: 'Terms',
        icon: termsIcon,
        activeIcon: termsIcon,
        link: 'terms',
      },
      {
        text: 'Privacy',
        icon: privacyIcon,
        activeIcon: privacyIcon,
        link: 'privacy',
      },
    ],
  };

  const options: MenuModel[] = isLogged
    ? [
        {
          text: 'Settings',
          icon: gearIcon,
          activeIcon: gearActiveIcon,
          link: 'settings',
        },
        optionsDefault,
        {
          text: 'Log out',
          icon: exitIcon,
          activeIcon: exitIcon,
          link: '',
          action: closeSession,
        },
      ]
    : [optionsDefault];

  const optionsMobile: MenuModel[] = [
    {
      text: 'Knowledge playlists',
      icon: kbIcon,
      activeIcon: kbActiveIcon,
      link: `users/${userData.slug}/playlists`,
    },
  ];

  return (
    <Wrapper ref={refMenu} className="wrapper-menu">
      <BoxHover className="menu-hover">
        <TooltipCard
          isAvatar
          isControlled
          setOpenState={setIsMenuOpen}
          isOpen={isMenuOpen}
          content={
            <MenuList
              css={{ top: 20, right: 20, with: 'auto' }}
              userData={userData}
              data={options}
              open={false}
              clickEvent={(e: any) => e?.toElement?.alt !== 'Menu' && setIsMenuOpen(false)}
            />
          }
        >
          <Wrapper avatarActive={isMenuOpen}>
            <Avatar img={userData?.avatarUrl} name={finalName} type="header" />
          </Wrapper>
        </TooltipCard>
      </BoxHover>
      <WrapperHamMenu>
        <Button
          title=""
          kind="transparent"
          iconWidth="16px"
          iconHeight="16px"
          iconCustom={openMobile ? closeIcon : menuIcon}
          ariaLabel="Menu"
          css={{ img: { margin: 0 } }}
          handleClick={() => {
            setOpenMobile((prev) => !prev);
            setDataSublist(undefined);
          }}
        />
      </WrapperHamMenu>
      <ContentMenu open={openMobile}>
        <MenuList
          userData={userData}
          data={options}
          open={!openMobile}
          subData={optionsMobile}
          dataSublist={dataSublist}
          actionSublist={(e: MenuModel | undefined) => setDataSublist(e)}
          clickEvent={(e: any) => e?.toElement?.alt !== 'Menu' && setOpenMobile(false)}
        />
      </ContentMenu>
    </Wrapper>
  );
};

export default MenuPopover;
