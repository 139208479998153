import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WrapNoData, WrapperText, Circle, TextTitle, TextDescription } from '../../styled';
import Button from '../../../../shared/components/Button/Button';
import editWhite from '../../../../assets/images/icons/edit-white.svg';

const SetupProfileWidget = () => {
  const navigate = useNavigate();
  return (
    <WrapNoData css={{ marginTop: 0, width: '100%' }}>
      <WrapperText css={{ display: 'flex', flexDirection: 'column', width: '95%' }}>
        <Circle css={{ width: '9.688rem', height: '9.688rem' }} />
        <div style={{ minHeight: '8rem', height: 'auto' }}>
          <TextTitle>Set up your profile</TextTitle>
          <TextDescription>
            Please add as much information as you can. It helps other users understand more about
            your knowledge and interests.{' '}
          </TextDescription>
        </div>
        <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
          <Button
            title="Set up profile"
            kind="add"
            iconCustom={editWhite}
            iconWidth="15px"
            iconHeight="15px"
            handleClick={() => navigate('/settings#profile')}
          />
        </div>
      </WrapperText>
    </WrapNoData>
  );
};
export default SetupProfileWidget;
