import React from 'react';
import { ErrorLabel, FormInputContainer, InputForm } from './style';

interface FormInputProps {
  name: string;
  placeholder: string;
  errors: any;
  touched: any;
  css?: any;
  containerCss?: any;
  hideErrorLabel?: boolean;
  member?: boolean;
  space?: 'none' | undefined;
  keepErrorLabelSpace?: boolean;
}

export const FormInput = ({
  name,
  placeholder,
  errors,
  touched,
  css,
  containerCss,
  hideErrorLabel,
  member,
  space,
  keepErrorLabelSpace,
}: FormInputProps) => (
  <FormInputContainer css={containerCss}>
    <InputForm
      css={css}
      id={name}
      name={name}
      placeholder={placeholder}
      error={!!errors[name] && !!touched[name]}
      member={member}
      autoComplete="off"
      space={space}
    />
    {!hideErrorLabel && errors[name] ? (
      <ErrorLabel>{errors[name]}</ErrorLabel>
    ) : (
      keepErrorLabelSpace && <ErrorLabel css={{ color: 'transparent' }}>SpaceError</ErrorLabel>
    )}
  </FormInputContainer>
);

FormInput.defaultProps = {
  css: {},
  containerCss: {},
  hideErrorLabel: false,
  member: false,
  space: undefined,
  keepErrorLabelSpace: false,
};
