import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../shared/components/Modal/Modal';
import Card from '../../shared/components/Card/Card';
import Button from '../../shared/components/Button/Button';
import RecordingVideos from './RecordVideo/RecordingVideos';
import Configuration from './Configuration/Configuration.';
import { ColumnTwo, GridRecording, SpaceBottomButtons } from './styles';
import RecordingOpinions from './RecordingOpinions/RecordingOpinions';
import VideoRecordingContext from '../../shared/context/videoRecording/videoRecordingContext';
import RecordingOpinionsMobile from './RecordingOpinions/RecordingOpinionsMobile';
import SaveOrRecordButtons from './SaveOrRecordButtons/SaveOrRecordButtons';
import Camera1White from '../../assets/images/icons/camera1-white.svg';
import Refresh from '../../assets/images/icons/refresh.svg';
import RefreshWhite from '../../assets/images/icons/refresh-white.svg';
import { OpinionButtonOption } from '../Organization/Expert/Opinions/styled';
import { RefreshIcon } from '../Organization/Expert/Opinions/icons/RefreshIcon';
import { useLandscape } from '../../shared/hooks/useLandscape';

interface RecordVideoModalProps {
  alternative?: boolean;
}

const RecordVideoModal = ({ alternative }: RecordVideoModalProps) => {
  const { isLandscapeMobile } = useLandscape();
  const {
    width,
    status,
    openModal,
    fullscreen,
    setOpenModal,
    setBlob,
    setStatus,
    setError,
    setRecordedChunks,
  } = useContext(VideoRecordingContext);
  const css = alternative
    ? { width: '100%', height: '2.625rem' }
    : { maxWidth: '100%', height: '2.625rem' };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getAlternativeIcon = () => (isHovered ? RefreshWhite : Refresh);

  const handleOpen = () => {
    setOpenModal(!openModal);
  };

  const getCSS = () => {
    if (fullscreen || isLandscapeMobile) {
      return { height: '100vh', width: '100vw', maxWidth: '100vw', padding: 0, borderRadius: 0 };
    }
    return {};
  };

  useEffect(() => {
    if (openModal) {
      setBlob(undefined);
      setStatus('init');
      setError(undefined);
      setRecordedChunks([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      {alternative ? (
        <OpinionButtonOption color="body" onClick={handleOpen}>
          <RefreshIcon />
          Record again
        </OpinionButtonOption>
      ) : (
        <Button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          size={alternative ? undefined : 'small'}
          title={alternative ? 'Record again' : 'Record your opinion'}
          iconCustom={alternative ? getAlternativeIcon() : Camera1White}
          renderProps={{ onClick: handleOpen }}
          css={css}
          kind={alternative ? 'outline' : undefined}
        />
      )}
      <Modal
        kindOverlay="gat-modal-overlay--blur"
        isOpen={openModal}
        onClose={handleOpen}
        shouldCloseOnOverlayClick={(['init', 'countdown', 'error'].includes(status))}
        css={{
          borderRadius: '0',
          overflow: 'hidden',
          '@bp2': { borderRadius: fullscreen || isLandscapeMobile ? 0 : '7px' },
        }}
      >
        <Card id="card-modal" type="questionsModal" css={getCSS()}>
          {isLandscapeMobile && !fullscreen ? (
            <GridRecording fullscreen={fullscreen} isLandscape>
              <RecordingVideos />
              {!fullscreen && (
                <ColumnTwo
                  css={{
                    display: fullscreen ? 'none' : '',
                    maxHeight: 550,
                    alignContent: 'center',
                  }}
                >
                  <RecordingOpinionsMobile isLandscape />
                  {status !== 'init' &&
                  (status === 'capturing' || status === 'paused' || status === 'captured') ? (
                    <SaveOrRecordButtons />
                  ) : (
                    <SpaceBottomButtons />
                  )}
                </ColumnTwo>
              )}
            </GridRecording>
          ) : (
            <GridRecording fullscreen={fullscreen}>
              <RecordingVideos />
              {!fullscreen && (
                <ColumnTwo css={{ display: fullscreen ? 'none' : '', maxHeight: 550 }}>
                  {width && width > 720 && <Configuration />}
                  {width && width > 720 && <RecordingOpinions />}
                  {width && width <= 720 && (
                    <>
                      <RecordingOpinionsMobile />
                      {status !== 'init' &&
                        (status === 'capturing' ||
                          status === 'paused' ||
                          status === 'captured') && <SaveOrRecordButtons />}
                    </>
                  )}
                </ColumnTwo>
              )}
            </GridRecording>
          )}
        </Card>
      </Modal>
    </>
  );
};

RecordVideoModal.defaultProps = {
  alternative: false,
};

export default RecordVideoModal;
