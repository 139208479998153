import React, { useState, useEffect } from 'react';
import { SubWrapperTopics, WrapTopics, Label, WrapTopicsEmpty } from './styles';
import { TagModel } from '../../interfaces/interfaces';
import { keyExtractor } from '../../utils/helper';

interface TagsTopicsPropsModel {
  data: TagModel[];
  // eslint-disable-next-line no-unused-vars
  actionTag(e: string | TagModel): void;
}

type TagsTopicsProps = TagsTopicsPropsModel &
  React.ComponentProps<typeof SubWrapperTopics> & {
    css?: any;
  };

const TagsTopics: React.FC<TagsTopicsProps> = ({ data, actionTag, css }) => {
  const [dataTags, setDataTags] = useState<Array<TagModel>>(data || []);
  const rowSmall = data?.length === 3;
  const rowBig = data?.length === 5;

  const isMultiple = (value: number) => dataTags.length % value === 0;

  const handleTag = (tag: string | TagModel) => {
    if (actionTag) {
      actionTag(tag);
    }
  };

  useEffect(() => {
    setDataTags(data);
  }, [data]);

  return (
    <SubWrapperTopics css={css}>
      {dataTags &&
        dataTags.map((item: any) => (
          <WrapTopics
            key={keyExtractor()}
            onClick={(e: any) => {
              e?.stopPropagation();
              const tagData = item?.attributes?.name || item;

              handleTag(tagData || '');
            }}
          >
            <Label className="label-sub">{item?.attributes?.name || item || ''}</Label>
          </WrapTopics>
        ))}
      <WrapTopicsEmpty
        className="empty-1"
        visible={{
          '@base': rowSmall || isMultiple(3) ? 'hide' : 'show',
          '@media(min-width: 61.24rem)': rowBig || isMultiple(5) ? 'hide' : 'show',
        }}
      />
      <WrapTopicsEmpty
        className={`empty-2-${isMultiple(5)}`}
        visible={{
          '@base': rowSmall || isMultiple(3) || dataTags.length % 3 === 2 ? 'hide' : 'show',
          '@media(min-width: 61.24rem)': rowBig || isMultiple(5) ? 'hide' : 'show',
        }}
      />
      <WrapTopicsEmpty
        className="empty-3"
        visible={{
          '@bp3': rowBig || isMultiple(5) ? 'hide' : 'show',
        }}
      />
      <WrapTopicsEmpty
        className="empty-4"
        visible={{
          '@bp3': rowBig || isMultiple(5) || dataTags.length % 5 === 2 ? 'hide' : 'show',
        }}
      />
    </SubWrapperTopics>
  );
};

TagsTopics.defaultProps = {
  css: undefined,
};

export default TagsTopics;
