import * as TabsPrimitive from '@radix-ui/react-tabs';
import { styled, fadeIn } from '../../config/theme';
import { LayoutCol } from '../../shared/components/Layouts/styles';

export const Text = styled('span', {
  '& span': {
    color: '$body'
  },
});

export const Paraph = styled('p', {
  fontSize: '$bodyXSmall',
  fontWeight: '$normal',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  marginBottom: '$sizes$1',
  '&:last-child': {
    marginBottom: 0
  }
});

export const TextTitleKnowledge = styled('h2', {
  fontSize: '$bodyMedium',
  fontWeight: '$bold',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  marginBottom: '$spaceLarge',
  textTransform: 'capitalize',
  '@bp3': {
    fontSize: '$displayXSmall',
  },
});

export const Circle = styled('div', {
  width: '1rem',
  height: '1rem',
  borderRadius: '50%',
  margin: 'auto',
  backgroundColor: '$placeHold',
  marginBottom: '$spaceLarge'
});

export const TextIcon = styled('span', {
  'img': {
    margin: '0 $spaceXSmall'
  },
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  maxWidth: 650,
  margin: '0 auto',

  '@bp3': {
    fontSize: '$bodySmall',
  },
});

export const DivWidgets = styled('div', {
  width: '100%',
  display: 'gird',
  flexDirection: 'column',
  gap: '$spaceLarge',
  '@bp1': {
    flexDirection: 'row',
  },
  '@bp3': {
    flexDirection: 'column',
  },
});

export const BreadCrumb = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'baseline'
});

export const Box = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  variants: {
    direction: {
      column: {
        flexDirection: 'column'
      },
      row: {
        flexDirection: 'row'
      }
    }
  }
});

export const Tabs = styled(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  justifyContent: 'flex-start'
});

export const TabsList = styled(TabsPrimitive.List, {
  flexShrink: 0,
  display: 'flex',
  marginY: '$spaceLarge',
  width: '100%',
  paddingBottom: '0',
});

export const TabsTrigger = styled(TabsPrimitive.Trigger, {
  all: 'unset',
  padding: '0 5px',
  height: 45,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$bodySmall',
  fontWeight: '$weight600',
  userSelect: 'none',
  cursor: 'pointer',
  boxShadow: 'inset 0 -1px 0 0 $colors$line, 0 1px 0 0 $colors$line',
  letterSpacing: '$letterSpacing3',
  opacity: 0.5,
  '&:first-child': { borderTopLeftRadius: 6 },
  '&:last-child': { borderTopRightRadius: 6 },
  '&[data-state="active"]': {
    boxShadow: 'inset 0 -1px 0 0 $colors$body, 0 1px 0 0 $colors$body',
    opacity: 1,
    color: '$body'
  },
  '&:focus': { position: 'relative' },
});

export const TabsContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  outline: 'none',
  animation: `${fadeIn} 340ms`
});

export const TextWho = styled('span', {
  fontSize: '$bodySmall',
});

export const TextTotal = styled('div', {
  fontWeight: '$normal',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight2',
  color: '$offBlack',
  letterSpacing: '$letterSpacing2',
  '@bp3': {
    fontSize: '$bodySmall',
  }
});

export const LayoutColKB = styled(LayoutCol, {
  flexGrow: 1,
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '$spaceSmall',
  flexWrap: 'wrap',
  '@bp2': {
    gap: '$spaceLarge',
  },
  '@bp3': {
    flexDirection: 'row',
    gap: '20px',
  },
});

export const WrapHeaderOptions = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  padding: '2px 0',
  marginBottom: '$spaceSmall',
});

export const BlockKB = styled('div', {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  backgroundImage: 'linear-gradient(to bottom, rgb(253 253 254 / 0%), 30%, rgb(255,255,255))',
  width: '100%',
  height: 'calc(100% - 1rem)',
  borderRadius: '$radius1',
  margin: '0 auto',
  left: 0,
  right: 0,
  zIndex: 6
});

export const BoxTitle = styled('div', {
  display: 'block',
  'p': {
    display: 'inline-flex',
    alignItems: 'center',
  },
  'img': {
    marginLeft: 0
  },
  variants: {
    view: {
      sidebar: {
        alignSelf: 'baseline',
        'p': {
          marginX: 0
        },
        '@bp2': {
          display: 'none'
        }
      },
      search: {
        alignSelf: 'baseline',
        '@bp2': {
        }
      },
      'search-2': {
        display: 'none',
        '@bp2': {
          display: 'block'
        }
      }
    }
  }
});

export const BoxTextEmpty = styled('div', {
  display: 'block',
  alignSelf: 'baseline',
  marginBottom: '$spaceLarge',
  'p': {
    margin: 0
  },
  '@bp2': {
    display: 'none',
  }
});

export const BoxTextEmptyMid = styled('div', {
  display: 'none',
  '@bp2': {
    display: 'block',
  }
});

export const TextEmpty = styled('span', {
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  color: 'hsl(248, 16%, 35%, 1)'
});

export const BoxRelative = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%'
});

export const BoxAbsolute = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  '@bp2': {
    position: 'absolute'
  }
});

export const TextDiscover = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$displayXSmall',
  lineHeight: '$lineHeight3',
});

export const CompilingWrapper = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  paddingBottom: '$spaceMedium',
  variants: {
    noContents: {
      noPadding: {
        paddingTop: '0',
        marginTop: `-$spaceMedium`,
      },
      padding: {
        paddingTop: '$spaceMedium',
      },
      smallPadding: {
        paddingTop: '$spaceMedium',
        '@bp3': {
          paddingTop: '$spaceLarge',
        }
      },
    }
  }
});