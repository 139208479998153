import React, { useContext, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import { keyExtractor } from '../../../shared/utils/helper';
import Card from '../../../shared/components/Card/Card';
import { CarouselContainer, FlexOpinion } from '../styles';
import { Typography, TypographyH3 } from '../../Expert/styled';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import Indicator from './Indicator';

const RecordingOpinionsMobile = ({ isLandscape }: { isLandscape?: boolean }) => {
  const { projectQuestions } = useContext(ProjectsContext);
  const { width } = useContext(VideoRecordingContext);
  const [selected, setSelected] = useState(0);
  return (
    <FlexOpinion>
      {width && width > 720 && !isLandscape && (
        <>
          <Typography
            type={width && width < 1440 ? 'displayXSmall2' : 'displayXSmall'}
            weight="700"
            lineHeight="lineHeight3"
            letterSpacing="letterSpacing3"
          >
            Recording Opinion
          </Typography>
          <TypographyH3
            size="displayXXSmall"
            weight="700"
            lineHeight="lineHeight3"
            letterSpacing="letterSpacing3"
          >
            Questions
          </TypographyH3>
        </>
      )}
      <CarouselContainer>
        <Carousel
          showThumbs={false}
          showArrows={false}
          showIndicators={false}
          onChange={(e) => {
            setSelected(e);
          }}
          showStatus={false}
        >
          {projectQuestions.map((question) => (
            <Card type="questions" key={keyExtractor()}>
              {question.text}
            </Card>
          ))}
        </Carousel>
        <Indicator count={projectQuestions.length} selected={selected} />
      </CarouselContainer>
    </FlexOpinion>
  );
};
RecordingOpinionsMobile.defaultProps = {
  isLandscape: false,
};
export default RecordingOpinionsMobile;
