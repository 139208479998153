import React from "react";
import { Helmet } from "react-helmet-async";


interface MetaTagsProprs {
  title: string;
  description?: string;
  canonical: string;
  main?: boolean;
  name?: string;
  image?: string;
  notFound?: boolean;
};

const MetaTags: React.FunctionComponent<MetaTagsProprs> =
  ({ title, description, canonical, main, name , image, notFound}) => (
    /* @ts-ignore */
    <Helmet>
      {main && <meta charSet="utf-8" />}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />
      <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      {name && <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Person",
                  "name": "${name}"
                }
              `}</script>}
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.gathersight.com/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://www.gathersight.com/" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />

      {notFound && <meta name="prerender-status-code" content="404" />}
    </Helmet>
  );

MetaTags.defaultProps = {
  main: false,
  description: `The home of independent experts, thinkers and creators.
    Find and connect with people sharing opinions and ideas
    on the Gathersight knowledge network.`,
  name: undefined,
  image: 'https://twitter.com/gathersight/photo',
  notFound: false,
};

export default MetaTags;


