import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../shared/components/Button/Button';
import { User } from '../../../shared/interfaces/interfaces';
import BookmarkBlue from '../../../assets/images/icons/bookmark-blue.svg';
import { LayoutColPeople } from '../../Profile/styled';
import { LayoutContainer } from '../../../shared/components/Layouts/styles';

type BackButtonProps = {
  userData?: User,
  myProfile: boolean,
};

const BackButton: React.FC<BackButtonProps> = ({ userData, myProfile }) => {
  const navigate = useNavigate();
  const attributes = userData ? userData.attributes : undefined;

  const playlistOwner = () =>
    myProfile ? 'my'
              : `${attributes?.first_name} ${attributes?.last_name}`;
  
  const back = () => {
    const pathBack = `/users/${attributes?.slug}/playlists`;
    navigate(pathBack);
  };

  return (
    <LayoutContainer type='breadcrumbs' css={{ minHeight: 50 }}>
      <LayoutColPeople col="12" css={{ alignItems: 'baseline' }} >
        <Button
          title={`All ${playlistOwner()} playlists`}
          kind='back'
          iconCustom={BookmarkBlue}
          handleClick={() => back()}
        />
      </LayoutColPeople>
    </LayoutContainer>
  );
};

BackButton.defaultProps = {
  userData: undefined,
};

export default BackButton;
