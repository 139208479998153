import React, { useState, useCallback, useContext, useEffect } from 'react';
import { getLinkPreview } from 'link-preview-js';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';
import Icon from '../Icon/Icon';
import Select from '../Select/Select';
import Checkbox from '../Checkbox/Checkbox';
import TagsInput from '../TagsInput/TagsInput';
import { UserService } from '../../../services/user.service';
import AppContext from '../../context/app/AppContext';
import {
  BoxText,
  TextTitle,
  BoxContent,
  InputWrapper,
  Input,
  IconWrapper,
  BoxFooter,
  Textarea,
  ErrorText,
  TextLabel,
} from './styled';
import closeIcon from '../../../assets/images/icons/close.svg';
import linkIcon from '../../../assets/images/icons/link.svg';
import helperIcon from '../../../assets/images/icons/helper.svg';
import { Resources, TagModel } from '../../interfaces/interfaces';
import dataContent from './data';
import Tooltip from '../Tooltip/Tooltip';

interface ModalContentModel {
  isEdit?: boolean;
  isOpen?: boolean;
  dataResource?: Resources;
  actionClose?: () => void;
  actionContinue?: () => void;
}

const TittleCreate = 'Your new content';
const TittleEdit = 'Edit Content';

const ModalContent: React.FC<ModalContentModel> = ({
  actionClose,
  actionContinue,
  isEdit,
  isOpen,
  dataResource,
}) => {
  const [accept, setAccept] = useState<boolean>(false);
  const [contentType, setContentType] = useState<string>('article');
  const [date, setDate] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [titleContent, setTitleContent] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [tagIds, setTagIds] = useState<Array<any>>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { state } = useContext(AppContext);
  const { authenticationToken } = state as any;
  const isDisabled = !contentType || !accept || !url;

  const handleClose = useCallback(async () => {
    if (actionClose) {
      actionClose();
    }
  }, [actionClose]);

  const actionClear = () => {
    setContentType('article');
    setTitleContent('');
    setErrorMessage('');
    setTagIds([]);
    setDate('');
    setText('');
    setUrl('');
  };

  const loadLink = async (link: string) => {
    if (link && Boolean(link?.length)) {
      await getLinkPreview(link).then((data: any) => {
        if (data?.description && Boolean(data?.description?.length)) {
          setText(data?.description);
        }

        if (data?.title && Boolean(data?.title?.length)) {
          setTitleContent(data?.title);
        }
      });
    }
  };

  const actionCreate = useCallback(async () => {
    const params = {
      authenticationToken,
      contentType,
      date,
      text,
      title: titleContent,
      url,
      tagIds,
    };

    const results = await UserService.createContent(params);
    if (results?.attributes) {
      handleClose();
    } else {
      setErrorMessage(results?.message);
    }
  }, [authenticationToken, contentType, date, text, titleContent, url, tagIds, handleClose]);

  const actionEdit = useCallback(async () => {
    const params = {
      authenticationToken,
      id: dataResource?.id,
      contentType,
      date,
      text,
      title: titleContent,
      url,
      tagIds,
    };

    const results = await UserService.editContent(params);
    if (results?.attributes) {
      handleClose();
    } else {
      setErrorMessage(results?.message);
    }
  }, [
    authenticationToken,
    contentType,
    dataResource?.id,
    date,
    text,
    titleContent,
    url,
    tagIds,
    handleClose,
  ]);

  const handleContinue = () => {
    if (!isEdit) {
      actionCreate();
      if (actionContinue) {
        actionContinue();
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (isEdit && dataResource?.attributes) {
        setContentType(dataResource?.attributes?.content_type || '');
        setTitleContent(dataResource?.attributes?.title || '');
        setTagIds(dataResource?.attributes?.tags as [TagModel]);
        setDate(dataResource?.attributes?.date || '');
        setText(dataResource?.attributes?.text || '');
        setUrl(dataResource?.attributes?.url || '');
        const dataTags =
          dataResource.attributes.tags?.data.map((item: any) => item?.attributes?.name) || [];
        setTagIds(dataTags);
      } else {
        actionClear();
      }
    }

    return () => {
      actionClear();
    };
  }, [isOpen, isEdit, dataResource?.attributes]);

  useEffect(
    () => () => {
      actionClear();
    },
    [],
  );

  return isOpen ? (
    <Modal
      kindOverlay="gat-modal-overlay--light"
      isOpen={isOpen || false}
      onClose={() => handleClose()}
      kind="resources"
    >
      <Card type="modalResources">
        <Button
          title=""
          iconCustom={closeIcon}
          iconWidth="16px"
          iconHeight="16px"
          kind="transparent"
          handleClick={() => handleClose()}
          css={{
            position: 'absolute',
            padding: '1.5rem 1.5rem 0 0',
            top: '0',
            right: '0',
            '& img': {
              margin: '0',
            },
          }}
        />
        <BoxText>
          <TextTitle>
            {isEdit ? TittleEdit : TittleCreate}
            <Button
              title=""
              kind="save"
              iconWidth="16px"
              iconHeight="18px"
              iconSave
              renderProps={{ disabled: true }}
            />
          </TextTitle>
        </BoxText>
        <BoxContent>
          <InputWrapper>
            <IconWrapper>
              <Icon img={linkIcon} width="24" height="22" alt="Link" />
            </IconWrapper>
            <Input
              type="text"
              value={url}
              placeholder="Link"
              onChange={(e: any) => setUrl(e?.target?.value)}
              onBlur={(e: any) => loadLink(e?.target?.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="text"
              value={titleContent}
              placeholder="Title automatic (but editable)"
              onChange={(e: any) => setTitleContent(e?.target?.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Textarea
              rows={4}
              value={text}
              placeholder="Description automatic (but editable)"
              onChange={(e: any) => setText(e?.target?.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Select
              options={dataContent}
              placeholder="Type"
              value={contentType}
              onChange={(e: any) => setContentType(e?.label)}
            />
          </InputWrapper>
          <TagsInput dataTags={tagIds} onChange={(e: any) => setTagIds(e)} />
          <Checkbox
            text={
              <TextLabel css={{ alignItems: 'center' }}>
                I confirm this content is authored by myself
                <Tooltip
                  content="All content uploaded to Gathersight should be authored (or in collaboration) by you."
                  direction="top"
                  type="info"
                >
                  <Icon img={helperIcon} width="18px" height="18px" alt="Info" />
                </Tooltip>
              </TextLabel>
            }
            kind="circle"
            onChange={(e: any) => setAccept(e)}
          />
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </BoxContent>
        <BoxFooter>
          <Button
            title={isEdit ? 'Edit' : 'Create'}
            css={{ width: '211px', margin: 'auto', padding: '.5rem 0', borderRadius: '$radius05' }}
            renderProps={{ disabled: isDisabled, onClick: isEdit ? actionEdit : handleContinue }}
          />
        </BoxFooter>
      </Card>
    </Modal>
  ) : (
    <div />
  );
};

ModalContent.defaultProps = {
  isEdit: false,
  isOpen: false,
  actionClose: undefined,
  dataResource: undefined,
  actionContinue: undefined,
};

export default ModalContent;
