import React from 'react';
import { Column, Typography } from '../../styled';
import Opinion from '../../Expert/Opinions/Opinion';

interface Props {
  userData: any;
}

const ExpertVideo = ({ userData }: Props) => (
  <Column columns="1" flex="column" gap={1}>
    <Typography
      size="displayXXSmall"
      weight="700"
      lineHeight="lineHeight3"
      letterSpacing="letterSpacing3"
      css={{ marginBottom: '$spaceLarge' }}
    >
      Expert Opinion
    </Typography>
    <Opinion userId={userData?.attributes?.user_id} />
  </Column>
);

export default ExpertVideo;
