import React from 'react';
import { Information, InformationContainer } from '../../Expert/styled';
import { Typography } from '../../styled';
import Avatar from '../../../../shared/components/Avatar/Avatar';
import { User } from '../../../../shared/interfaces/interfaces';

interface Props {
  userData: any;
}

const ExpertInformation = ({ userData }: Props) => (
  <InformationContainer>
    <Avatar
      img={userData?.attributes?.avatar_url}
      name={`${userData?.attributes?.user_name}`}
      type="mid"
    />
    <Information>
      <Typography size="bodyXLarge" weight="400" lineHeight="lineHeight13">
        {`${userData?.attributes?.user_name}`}
      </Typography>
      <Typography
        size="bodySmall"
        weight="400"
        lineHeight="lineHeight2"
        letterSpacing="letterSpacing2"
      >
        {userData?.attributes?.user_experiences.length
          ? userData?.attributes?.user_experiences[0].title
          : 'Expert'}
      </Typography>
    </Information>
  </InformationContainer>
);

export default ExpertInformation;
