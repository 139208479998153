import React, { useCallback, useEffect, useState } from 'react';
import { InputWrapper } from '../styles';
import blackSearchIcon from '../../../../assets/images/icons/black-search.svg';
import searchIcon from '../../../../assets/images/icons/search.svg';
import Icon from '../../Icon/Icon';
import InputSearch from '../../InputSearch/InputSearch';
import { TagModel } from '../../../interfaces/interfaces';
import { useTag } from '../../../hooks/useTag';
import { TitleSmall } from '../../Typography/styled';
import TagItems from './TagItems';
import { TagSelectorContentContainer } from './styled';

interface TagSelectorContentModel {
  handleClosePopover: () => void;
}

const TagSelectorContent = ({ handleClosePopover }: TagSelectorContentModel) => {
  const [tagSearchValue, setTagSearchValue] = useState<string>('');
  const [filterTopics, setFilterTopics] = useState<TagModel[]>([]);
  const { tags, fetchTag, getTagByName } = useTag();

  useEffect(() => {
    fetchTag();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setReset = () => {
    setTagSearchValue('');
    setFilterTopics(tags);
  };

  const handleSearch = useCallback(async(value: string) => {
    setTagSearchValue(value);
    if (!value) setReset();
    else {
      const term = tagSearchValue.toLowerCase();
      const searchTags = async () => {
        const tagsFound = await getTagByName(term);
        return tagsFound;
      };

      const filteredTags = await searchTags();
      setFilterTopics(filteredTags);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSearchValue]);

  const isSearching = () => filterTopics.length > 0 || tagSearchValue.length > 0;
  const getList = () => (isSearching() ? filterTopics : tags);

  return (
    <TagSelectorContentContainer>
      <TitleSmall css={{ paddingBottom: '0.75rem' }}>Search Topics</TitleSmall>
      <InputWrapper activeWrite={Boolean(tagSearchValue)} css={{ marginBottom: '0.75rem' }}>
        {tagSearchValue ? (
          <Icon
            img={blackSearchIcon}
            width="18"
            height="16"
            alt="Search"
            margin0
            css={{ paddingLeft: 15 }}
          />
        ) : (
          <Icon
            img={searchIcon}
            width="18"
            height="16"
            alt="Search"
            margin0
            css={{ paddingLeft: 15 }}
          />
        )}

        <InputSearch
          actionSearch={handleSearch}
          placeholder="Search"
          value={tagSearchValue}
          setValue={setTagSearchValue}
          setReset={setReset}
          kind="kpModal"
        />
      </InputWrapper>
      <TagItems tags={getList()} handleClosePopover={handleClosePopover} />
    </TagSelectorContentContainer>
  );
};

export default TagSelectorContent;
