import { styled } from '../../../config/theme';

export const CustomButton = styled('button', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$body',
  color: '$offWhite',
  fontFamily: 'Poppins',
  letterSpacing: '$letterSpacing2',
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  fontWeight: '$weight600',
  width: '100%',
  borderRadius: '0.35rem',
  cursor: 'pointer',
  border: 'none',
  maxWidth: '350px',
  height: 46,
  paddingX: '$spaceXSmall',

  '& .spinner': {
    marginRight: '.3rem',
  },
  '& span': {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  '&[disabled]': {
    cursor: 'auto',
    opacity: '0.7',
    '&:hover': {
      backgroundColor: '$followingBtn',
      color: '$label',
      border: '1px solid $followingBtn',
    },
  },
  '&:hover': {
    background: '$hoverBtn',
  },
  '@bp3': {
    height: 56,
  },
  '@bp4': {
    fontSize: '$bodySmall',
  },

  variants: {
    noTransition: {
      true: {
        transition: 'none',
      },
    },
    isHidden: {
      true: {
        display: 'none',
      },
    },
    width: {
      auto: {
        paddingX: '$spaceLarge',
        width: 'auto',
      },
      full: {
        width: '100%',
        minWidth: '100%',
      },
      max170: {
        width: '170px',
      },
    },
    color: {
      body: {
        backgroundColor: '$body',
      },
      blue: {
        backgroundColor: '$blue',
      },
      red: {
        backgroundColor: '$red',
      },
      label: {
        backgroundColor: '$label',
      },
      blurple: {
        backgroundColor: '$blurple',
      },
    },
    kind: {
      delete: {
        background: '$redDelete',
        width: 32,
        height: 32,
        padding: 0,
        '&:hover': {
          backgroundColor: '$red',
        },
      },
      blocked: {
        background: '$grayscaleLine',
        width: 32,
        height: 32,
        padding: 0,
        '&:hover': {
          background: '$grayscaleLine',
        },
        '&[disabled]': {
          cursor: 'auto',
          opacity: '0.7',
          '&:hover': {
            background: '$grayscaleLine',
          },
        },
      },
      deleteConfirm: {
        maxWidth: '15rem',
        maxHeight: 45,
      },
      deleteConfirmOutline: {
        maxWidth: '15rem',
        border: '0.125rem solid $body',
        backgroundColor: 'transparent',
        maxHeight: 45,
        color: '$body',
        fontWeight: '$weight600',

        '&:hover': {
          color: 'white',
        },
      },
      file: {
        backgroundColor: 'hsl(233deg 26% 88% / 36%);',
        padding: '$spaceXSmall $spaceSmall',
        color: 'hsla(248, 16%, 35%, 1)',
        fontWeight: '$weight500',
        fontSize: '$bodyXSmall',
        height: '2.625rem',
        '& span': {
          margin: 0,
          padding: 0,
          '& div': {
            '& img': {
              marginLeft: 0,
            },
          },
        },
        '&:hover': {
          color: '$grayscaleBody',
          backgroundColor: '$grayscaleLine',
        },
      },
      organization: {
        fontSize: '$bodyXSmall',
        fontWeight: '$weight500',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
      },
      login: {
        backgroundColor: '$body',
        color: '$offWhite',
        borderRadius: '$radius075',
        marginY: '$spaceXSmall',
        width: '100%',
      },
      register: {
        backgroundColor: '$body',
        color: '$offWhite',
        borderRadius: '$radius075',
        marginY: '$spaceXSmall',
        width: '100%',
        maxWidth: '100%',
      },
      outline: {
        border: '0.125rem solid $body',
        backgroundColor: 'transparent',
        width: 'auto',
        maxHeight: 45,
        fontWeight: '$weight600',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$body',
        borderRadius: '0.35rem',
        '@bp0': {
          fontSize: '$bodyXSmall',
        },
        '&:hover': {
          color: 'white',
        },
      },
      edit: {
        marginTop: 4,
        border: '1px solid $body',
        backgroundColor: 'transparent',
        width: 20,
        height: 20,
        padding: 0,
        borderRadius: '0.3rem',
        '&:hover': {
          backgroundColor: '$followingBtn',
        },
      },
      outlineFull: {
        border: '0.125rem solid $body',
        backgroundColor: 'transparent',
        maxHeight: 45,
        fontWeight: '$weight600',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$body',
        borderRadius: '0.35rem',

        '&:hover': {
          color: 'white',
        },
      },
      cancelOutline: {
        border: '0.125rem solid $redGoogle',
        backgroundColor: 'transparent',
        width: 'auto',
        maxHeight: 45,
        fontWeight: '$weight600',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$redGoogle',
        borderRadius: '0.35rem',
        '@bp0': {
          fontSize: '$bodyXSmall',
        },
        '&:hover': {
          color: 'white',
          backgroundColor: '$redGoogle',
          stroke: 'White',
        },
      },
      link: {
        backgroundColor: 'transparent',
        width: 'auto',
        maxHeight: 45,
        fontWeight: '$weight600',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$blurple',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      addTopicOutline: {
        border: '0.125rem solid $body',
        width: '70px',
        maxHeight: 31,
        fontWeight: '$weight600',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        borderRadius: '0.35rem',
        '@bp0': {
          fontSize: '$bodyXSmall',
        },
        '@bp2': {
          marginLeft: '$spaceSmall',
        },
      },
      cancelTopicOutline: {
        border: '0.125rem solid $blurple',
        backgroundColor: 'transparent',
        width: '70px',
        maxHeight: 31,
        marginX: '$spaceXSmall',
        fontWeight: '$weight600',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$blurple',
        borderRadius: '0.35rem',
        '&:hover': {
          border: '0.125rem solid $hoverBtn',
          backgroundColor: '$blurple',
          color: 'white',
        },
        '@bp0': {
          fontSize: '$bodyXSmall',
        },
      },
      follow: {
        border: '0.125rem solid $body',
        backgroundColor: 'transparent',
        fontWeight: '$weight600',
        fontSize: '$bodyXSSmall',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
        color: '$body',
        padding: '0.25rem 0.813rem',
        borderRadius: '$radius03',
        '&:hover:not([disabled])': {
          border: '0.125rem solid $input',
          color: '$label',
          borderRadius: '$radius03',
          backgroundColor: '$bg',
        },
      },
      following: {
        border: '1px solid $followingBtn',
        backgroundColor: '$bg',
        width: '83px',
        maxWidth: 150,
        fontWeight: '$weight600',
        fontSize: '$bodyXSSmall',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
        color: '$label',
        padding: '0.25rem 0.813rem',
        borderRadius: '$radius03',
        '&:hover:not([disabled])': {
          border: '0.125rem solid hsl(332, 100%, 93%, 1)',
          backgroundColor: 'hsl(332, 100%, 93%, 1)',
        },
        '&:hover:not([disabled]) span': {
          opacity: '0',
          position: 'absolute',
        },
        '& span': {
          opacity: '1',
          position: 'relative',
        },
        '&::before': {
          opacity: 0,
          position: 'absolute',
          content: 'Unfollow',
        },
        '&:hover:not([disabled])::before': {
          opacity: 1,
          position: 'relative',
          color: 'hsl(356, 91%, 54%, 1)',
        },
      },
      'follow-profile': {
        border: 'none',
        backgroundColor: '$body',
        width: '90px',
        maxWidth: 150,
        fontWeight: '$weight600',
        fontSize: '$bodyXSmall',
        lineHeight: '$lineHeightP4',
        letterSpacing: '$letterSpacing2',
        color: '$offWhite',
        padding: '0.25rem 0.813rem',
        borderRadius: '$radius05',
        '&[disabled]': {
          opacity: '1',
          backgroundColor: 'hsl(240, 45%, 98%, 1)',
          border: '0.063rem solid hsl(233, 27%, 88%, 1)',
          color: '$line',
        },
        '@bp3': {
          fontSize: '$bodySmall',
          width: '8.563rem',
        },
      },
      'following-profile': {
        border: '1px solid rgba(255, 255, 255, 0.2)',
        backgroundColor: ' rgba(255, 255, 255, 0.05)',
        maxWidth: 150,
        fontWeight: '$weight600',
        fontSize: '$bodyXSmall',
        lineHeight: '$lineHeightP4',
        letterSpacing: '$letterSpacing2',
        color: '$offWhite',
        padding: '0.25rem 0.813rem',
        borderRadius: '$radius05',
        '&[disabled]': {
          opacity: '0.7',
          backgroundColor: '$body',
          border: 'none',
        },
        '&:hover': {
          background: '$hoverBtn',
        },
        '&:hover span': {
          opacity: '0',
          position: 'absolute',
        },
        '& span': {
          opacity: '1',
          position: 'relative',
        },
        '&::before': {
          opacity: 0,
          position: 'absolute',
          content: 'Unfollow',
          padding: '0.25rem 0.19rem',
        },
        '&:hover::before': {
          opacity: 1,
          position: 'relative',
          color: 'hsl(356, 91%, 54%, 1)',
        },
        '@bp2': {
          width: '5.63rem',
        },
        '@bp3': {
          fontSize: '$bodySmall',
          width: '8.563rem',
        },
      },
      transparent: {
        backgroundColor: 'transparent',
        width: 'auto',
        height: 'auto',
        fontWeight: '$weight600',
        fontSize: '$bodySmall',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$body',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      loadMoreText: {
        backgroundColor: 'transparent',
        width: 'auto',
        height: 'auto',
        fontWeight: '$weight600',
        fontSize: '$bodySmall',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$body',
        '&:hover': {
          color: '$hoverBtn',
          backgroundColor: 'transparent',
        },
      },
      top: {
        zIndex: 2,
        marginY: '$spaceSmall',
      },
      save: {
        backgroundColor: '$body',
        width: 28,
        height: 28,
        color: '$offBlack',
        borderRadius: '0.313rem',
        paddingX: '$spaceXSmall',
        '& .gat-icon': {
          justifyContent: 'center',
          alignItems: 'center',
        },
        img: {
          margin: 0,
          height: 16,
          width: 14,
        },
      },
      'user-profile': {
        backgroundColor: '$body',
        width: '11.5rem',
        height: '2.5rem',
        color: '$offWhite',
        borderRadius: '0.5rem',
        paddingX: '$spaceXSmall',
        maxWidth: 100,
        fontSize: '$bodyXSmall',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
        '& img': {
          margin: '0 0.5rem 0 0',
        },
      },
      'user-profile-outline': {
        backgroundColor: 'transparent',
        width: '11.5rem',
        height: '2.5rem',
        color: '$body',
        border: '1px solid $colors$body',
        borderRadius: '0.5rem',
        paddingX: '$spaceXSmall',
        maxWidth: 100,
        fontSize: '$bodyXSmall',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
        '& img': {
          margin: '0 0.5rem 0 0',
        },
        '&:hover': {
          color: '$offWhite',
        },
      },
      meet: {
        background: '$offBlack',
        width: '100%',
        height: '4.5rem',
        color: '$offWhite',
        borderRadius: '1rem',
        padding: '1rem',
        fontSize: '$bodySmall',
        lineHeight: '$lineHeightP4',
        letterSpacing: '$letterSpacing2',
        '& img': {
          margin: '0 0.8rem 0 0',
        },
        '&:hover': {
          backgroundColor: '$offBlack',
        },
      },
      'close-search': {
        position: 'absolute',
        backgroundColor: 'transparent',
        width: 'auto',
        height: '100%',
        padding: 0,
        right: 1,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      'show-hide-pass': {
        position: 'absolute',
        backgroundColor: 'transparent',
        width: 'auto',
        height: '100%',
        padding: 0,
        right: 1,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      continue: {
        border: '0.125rem solid $body',
        backgroundColor: '$body',
        fontWeight: '$weight600',
        fontSize: '$bodyXSmall',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
        height: '44px',
        color: '$offWhite',
        padding: '0.25rem 0.813rem',
        borderRadius: '$radius05',
        '&:hover': {
          border: '0.125rem solid $hoverBtn',
          backgroundColor: '$hoverBtn',
          color: 'white',
        },
      },
      cancel: {
        border: '0.125rem solid $label',
        backgroundColor: '$label',
        fontWeight: '$weight600',
        fontSize: '$bodyXSmall',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
        height: '44px',
        color: '$offWhite',
        padding: '0.25rem 0.813rem',
        borderRadius: '$radius05',
        '&:hover': {
          opacity: '0.8',
        },
      },
      add: {
        width: '100%',
        padding: '$spaceXSmall $spaceLarge',
        letterSpacing: '$letterSpacing1',
        borderRadius: '$radius05',
        minWidth: 154,
        margin: '$spaceLarge auto 0',
        fontSize: '$bodyXSmall',
        height: '40px',
        '& img': {
          margin: '0 8px 0 0',
        },
        '@bp2': {
          width: 'auto',
        },
      },
      'primary-simple': {
        width: 'auto',
        backgroundColor: '$body',
        padding: '$spaceXSmall $spaceLarge',
        letterSpacing: '$letterSpacing1',
        borderRadius: '$radius05',
      },
      back: {
        backgroundColor: 'transparent',
        width: 'auto',
        color: '$body',
        paddingLeft: 0,
        fontWeight: '$weight700',
        fontFamily: '$poppins',
        '& img': {
          marginLeft: 0,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          opacity: 0.8,
        },
      },
      newKB: {
        backgroundColor: 'transparent',
        color: '$offBlack',
        padding: '2rem',
        '&:hover': {
          backgroundColor: 'transparent',
          opacity: 0.8,
        },
      },
      circle: {
        border: '1px solid $body',
        borderRadius: '50%',
        '&:hover': {
          opacity: '0.8',
        },
      },
      'circle-social': {
        height: '46px',
        width: '46px',
        border: '1px solid $body',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        '& img': { padding: '2px' },
        '&:hover': {
          opacity: '0.8',
          backgroundColor: '$bodyPale',
        },
      },
      modal: {
        margin: 'auto',
        padding: '.5rem',
        borderRadius: '$radius05',
      },
      onBoardingModal: {
        height: 40,
        maxWidth: '150px',
        borderRadius: '8px',
        '& span': {
          flexDirection: 'row-reverse',
          '.spinner': { marginLeft: '0.3rem' },
        },
        '& img': {
          marginRight: 0,
        },
        '@bp1': {
          height: 56,
        },
      },
      onBoardingModalTransparent: {
        height: 40,
        maxWidth: '150px',
        backgroundColor: 'transparent',
        fontWeight: '$weight600',
        fontSize: '$bodySmall',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$body',
        '&:hover': {
          backgroundColor: 'transparent',
          opacity: 0.8,
        },
        '@bp1': {
          height: 56,
        },
      },
      onBoardingModalSkip: {
        height: 40,
        maxWidth: '150px',
        fontWeight: '$weight600',
        fontSize: '$bodySmall',
        lineHeight: '$lineHeight2',
        letterSpacing: '$letterSpacing2',
        color: '$label',
        borderRadius: '8px',
        backgroundColor: '$input',
        '&:hover': {
          border: '0.125rem solid $hoverBtn',
          backgroundColor: '$hoverBtn',
          color: 'white',
        },
        '@bp1': {
          height: 56,
          maxWidth: '270px',
        },
      },
      'button-search-large': {
        marginTop: '$spaceLarge',
        alignSelf: 'center',
        borderRadius: '6px',
        maxWidth: '100%',
        '@bp3': {
          maxWidth: '300px',
        },
      },
      goBack: {
        width: '11.938rem',
        margin: ' $spaceLarge',
      },
      'topic-tag': {
        height: '28px',
        width: '28px',
        borderRadius: '0.313rem',
        marginTop: '4px',
        '@bp1': {
          height: '30px',
          width: '30px',
        },
        '@bp4': {
          padding: '$spaceNone',
        },
        '&:hover': {
          background: '$hoverBtn',
        },
      },
      'add-dashboards': {
        backgroundColor: '$secondaryP3',
        color: '$ash',
        fontSize: '$bodyXSmall',
        fontWeight: '$weight500',
        lineHeight: '$lineHeight1',
        letterSpacing: '$letterSpacing1',
        maxHeight: 40,
        maxWidth: 170,
        borderRadius: '$radius05',
        img: {
          marginLeft: 0,
        },
        '&:hover': {
          backgroundColor: 'rgb(164, 212, 27)',
        },
      },
      'submitOpinion': {
        width: '227px',
        height: '2.625rem',
        padding: '10px',
        span: { fontSize: '16px' },
        '@bp4': {
          height: 50,
        }
      },
      'add-dashboards-blue': {
        backgroundColor: '$body',
        margin: 'auto',
        fontSize: '$bodyXSmall',
        '&:hover': {
          backgroundColor: '$hoverBtn',
        },
      },
    },
    size: {
      small: {
        height: '2rem',
      },
      medium: {
        height: '2.3rem',
        '@bp3': {
          height: '2.625rem',
        },
      },
      mediumFixed: {
        height: '2.625rem',
      },
      big: {
        height: '2rem',
        '@bp1': {
          height: '40px',
        },
        '@bp3': {
          height: '3.5rem',
        },
      },
    },
    margin: {
      0: {
        margin: 0,
      },
    },
  },
});
