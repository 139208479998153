import React, { useState, useEffect } from 'react';
import { WrapperStats, Stat, StatText, StatTextLittle, LinkStat } from './styles';
import Icon from '../Icon/Icon';
import star from '../../../assets/images/icons/star.svg';
import call from '../../../assets/images/icons/call.svg';
import article from '../../../assets/images/icons/book-bold.svg';
import bookmark from '../../../assets/images/icons/bookmark-black.svg';

import dataTemp from './data';

interface StatsProps {
  data?: any[];
  type?: string;
}

const KeyStats: React.FC<StatsProps> = ({ data, type }) => {
  const [dataKeyStats, setDataKesyStats] = useState<Array<any>>(data || dataTemp);
  const isWidget = type === 'widget';

  const checkStat = (value?: string) => {
    switch (value) {
      case 'star':
        return star;
      case 'call':
        return call;
      case 'bookmark':
        return bookmark;
      default:
        return article;
    }
  };

  useEffect(() => {
    (async () => {
      if (data) {
        setDataKesyStats(data);
      }
    })();
  }, [data]);

  return (
    <WrapperStats isWidget={isWidget}>
      {dataKeyStats && dataKeyStats?.map((item) => (
        <LinkStat key={item.id || item.icon} to={item?.link}>
          <Stat isWidget={isWidget}>
            <Icon img={checkStat(item?.icon)} width='20px' height='18px' alt="Stats" />
            <span style={{ color: 'hsla(227, 78%, 60%, 1)' }}>{item?.rate}</span>
            {!isWidget && item?.text && <StatTextLittle>{item?.text}</StatTextLittle>}
            {isWidget && <StatText >{item?.text}</StatText>}
          </Stat>
        </LinkStat>)
      )}
    </WrapperStats>
  );
};

KeyStats.defaultProps = {
  type: undefined,
  data: [],
};

export default KeyStats;
