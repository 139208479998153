import React, { useState, useContext } from 'react';
import _get from 'lodash/get';
import { Knowledge, WhoAccess } from '../../interfaces/interfaces';
import { WrapperCard, CardHeader, CardContent } from '../CardResources/styles';
import { Options, Box, TypeResource, TextTitleKnowledge, StrongText } from './styles';
// import Tags from '../Tags/Tags'; // Hide V1
import ShareKB from '../Share/ShareKB';
import Icon from '../Icon/Icon';
import OptionsPopover from '../Options/OptionsPopover';
import AvatarMultiple from '../Avatar/AvatarMultiple';
import audio from '../../../assets/images/icons/audio-gray.svg';
import book from '../../../assets/images/icons/book-gray.svg';
import video from '../../../assets/images/icons/video-gray.svg';
import user from '../../../assets/images/icons/user-gray.svg';
import file from '../../../assets/images/icons/file-gray.svg';
import lock from '../../../assets/images/icons/lock-gray.svg';
import AppContext from '../../context/app/AppContext';
import { getUserName } from '../../utils/helper';

interface KnowledgeProps extends Knowledge {
  // eslint-disable-next-line no-unused-vars
  actionClick?: (id: string, createdBySlug: string) => void;
}

const CardBooks: React.FC<KnowledgeProps> = ({ id, attributes, actionClick }) => {
  const [disable, setDisabled] = useState<boolean>(true);
  let collaborators = _get(attributes, 'who_access.data');
  collaborators = collaborators.length ? collaborators : [];
  collaborators = collaborators.filter((collaborator: WhoAccess) =>
    collaborator.attributes.status === 'accepted'
  );
  // const dataTags = _get(attributes, 'tags'); // Hide V1
  // const RenTag = useMemo(() => Tags, []); // Hide V1
  // const existTag = dataTags?.length > 0; // Hide V1
  const { state } = useContext(AppContext);
  const { userId } = state.userData;
  const owner = attributes.created_by_id.toString() === userId;
  const dataName = {
    username: attributes?.created_by_username,
    name: attributes?.created_by_name
  };
  const finalName = getUserName(dataName);
  const ownerData = {
    attributes: { user_name: finalName, user_avatar: attributes.created_by_avatar }
  };
  collaborators.unshift(ownerData);

  const onClick = (event: any) => {
    event?.stopPropagation();

    if (actionClick && disable) {
      actionClick(attributes.slug, attributes?.created_by_slug);
    }
  };

  return (
    <WrapperCard middle key={id} onClick={onClick}>
      <CardHeader>
        <TextTitleKnowledge css={{ display: 'flex', alignItems: 'center' }}>
          {attributes?.public === false && (
            <Icon img={lock} width="14px" height="14px" alt="Title" />
          )}
          <StrongText>{attributes?.title || ''}</StrongText>
        </TextTitleKnowledge>
        {owner && (
          <Options>
            <OptionsPopover
              actionOptions={() => setDisabled(false)}
              actionModalClose={() => setDisabled(true)}
              dataKnowledge={{ id, attributes }}
            />
            <ShareKB
              dataKnowledge={{ id, attributes }}
              actionShare={() => setDisabled(false)}
              actionModalClose={() => setDisabled(true)}
            />
          </Options>
        )}
      </CardHeader>
      <CardContent css={{ width: '100%' }}>
        <AvatarMultiple data={collaborators} type="little" />
        <Box css={{ margin: '$spaceLarge 0' }}>
          <Box>
            <TypeResource>
              <Icon img={audio} width="14px" height="14px" alt="Podcast" />
            </TypeResource>
            <span>{attributes?.podcast_content_amount || 0}</span>
          </Box>
          <Box>
            <TypeResource>
              <Icon img={book} width="14px" height="14px" alt="Article" />
            </TypeResource>
            <span>{attributes?.article_content_amount || 0}</span>
          </Box>
          <Box>
            <TypeResource>
              <Icon img={video} width="14px" height="14px" alt="Video" />
            </TypeResource>
            <span>{attributes?.video_content_amount || 0}</span>
          </Box>
          <Box>
            <TypeResource>
              <Icon img={file} width="14px" height="14px" alt="Article" />
            </TypeResource>
            <span>0</span>
          </Box>
          <Box>
            <TypeResource>
              <Icon img={user} width="14px" height="14px" alt="Author contents" />
            </TypeResource>
            <span>{attributes?.author_items_amount || 0}</span>
          </Box>
        </Box>
        {/* <RowTags empty={!existTag} css={{  width: '100%' }} >
          <RenTag
            data={dataTags}
            actionTag={() => { }}
            kind='one-line'
          />
        </RowTags> */}
      </CardContent>
    </WrapperCard>
  );
};

CardBooks.defaultProps = {
  actionClick: undefined,
};

export default CardBooks;
