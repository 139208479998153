import { styled } from '../../../../config/theme';

export const CreditsCounterContainer = styled('div', {
  display: 'flex',
  paddingX: '$spaceXXSmall',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$radius03',
  border: '1px solid $blurpleLight',
});
