import { styled } from '../../../config/theme';


export const Typography = styled('div', {
    fontFamily: '$poppins',
    color: '$monochromeBlack',
    fontSize: '$bodyLarge',
    variants: {
      weight: {
        700: {
          fontWeight: '$weight700',
        },
        500: {
          fontWeight: '$weight500',
        },
      },
      lineHeight: {
        lineHeight12: {
          lineHeight: '$lineHeight12', // 0.625rem
        },
        lineHeight9: {
          lineHeight: '$lineHeight9', // 1.75rem
        },
        lineHeight10: {
          lineHeight: '$lineHeight10', // 1.25rem
        },
        lineHeight11: {
          lineHeight: '$lineHeight11', // 1.3125rem
        },
        lineHeight1: {
          lineHeight: '$lineHeight1', // 1.375rem
        },
        lineHeight3: {
          lineHeight: '$lineHeight3', // 2rem
        },
        lineHeight2: {
          lineHeight: '$lineHeight2', // 1.5rem
        },
      },
      letterSpacing: {
        letterSpacing2: {
          letterSpacing: '$letterSpacing2', // 0.047rem
        },
        letterSpacing3: {
          letterSpacing: '$letterSpacing3', // 0.063rem
        },
        letterSpacing8: {
          letterSpacing: '$letterSpacing8', // 0.03rem
        },
        letterSpacing6: {
          letterSpacing: '$letterSpacing6', // 0.0325rem
        },
        letterSpacing7: {
          letterSpacing: '$letterSpacing7', // 0.025rem
        },
      },
      size: {
        bodyXXSSmall: {
          fontSize: '$bodyXXSSmall', // 0.5rem
        },
        bodyTextXSmall: {
          fontSize: '$bodyTextXSmall',
        },
        bodyXSmall: {
          fontSize: '$bodyXSmall', // 0.813rem
        },
        bodyListItem: {
          fontSize: '$bodyListItem', // 0.875rem
        },
        bodySmall: {
          fontSize: '$bodySmall', // 0.938rem
        },
        displayXXSmall: {
          fontSize: '$displayXXSmall',
        },
        displayXSmall: {
          fontSize: '$displayXSmall', // 1.5rem
        },
      },
      space: {
        marginBottomSpaceLarge: {
          marginBottom: '$spaceLarge',
        },
        marginTopSpaceLarge: {
          marginTop: '$spaceLarge',
        },
        marginYSpaceLarge2: {
          marginY: '$spaceLarge2',
        },
      },
    },
  });