/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import queryString from 'query-string';
import axios from 'axios';
import { apiUrl, ProdOrDevUrl, twitterAuth } from '../../constants/authConstants';
import { LoginModel, OrganizationSignUpModel, SignUpModel } from '../../shared/context/app/types';

export const axiosInstance = axios.create({
  baseURL: `${apiUrl.BASE_API}`,
});

export const GoogleAuthService = {
  postTokens: async (login: boolean, tokenID?: string) => {
    try {
      const res = await axiosInstance.post(
        `/auth/google`,
        {},
        { params: { token_id: tokenID, login } },
      );
      return res.data;
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
};

export const TwitterAuthService = {
  redirectToTwitterApi: async (login: boolean) => {
    try {
      const {
        data: { token, token_secret },
      } = await axiosInstance.post(
        'auth/twitter',
        {},
        {
          params: {
            callback_url: `${ProdOrDevUrl().BASE_API}/auth/twitter/callback?login=${login}`,
          },
        },
      );
      localStorage.setItem('token_secret', token_secret);
      window.location.href = `${twitterAuth.TWITTER_API}/authenticate?oauth_token=${token}`;
    } catch {
      return { error: 'There was a problem logging in. Please try again' };
    }
  },

  postTokens: async () => {
    const { oauth_token, oauth_verifier, login } = queryString.parse(window.location.search);
    const oauth_token_secret = localStorage.getItem('token_secret');
    try {
      if (oauth_token && oauth_verifier) {
        const res = await axiosInstance.post(
          'auth/twitter/callback',
          {},
          { params: { oauth_token, oauth_token_secret, oauth_verifier, login } },
        );
        localStorage.removeItem('token_secret');
        const result = { ...res.data };
        return result;
      }
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
};

export const LoginService = {
  login: async (credentials: LoginModel) => {
    const { email, password } = credentials;
    try {
      const res = await axiosInstance.post('auth/login', {}, { params: { email, password } });

      return res.data;
    } catch (error: any) {
      return { error: 'Wrong email or password' };
    }
  },
};

export const RegisterService = {
  register: async (credentials: SignUpModel) => {
    const { email, password, firstName, lastName } = credentials;
    try {
      const res = await axiosInstance.post(
        'users',
        {},
        { params: { email, password, first_name: firstName, last_name: lastName } },
      );

      return res.data;
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
};

export const RegisterServiceExpert = {
  register: async (credentials: OrganizationSignUpModel, extraData: any) => {
    const data = {
      email: credentials.email,
      password: credentials.password,
      first_name: credentials.firstName,
      last_name: credentials.lastName,
      user_type: 'seeker',
      projects_attributes: [
        {
          name: extraData.projectName,
          description: extraData.description,
          project_locations_attributes: extraData.locations.map((location: any) => ({
            location_id: location.id,
          })),
          project_occupations_attributes: extraData.roles.map((role: any) => ({
            occupation_id: role.id,
          })),
        },
      ],
    };

    try {
      const res = await axiosInstance.post('users', data);
      return res.data;
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
  registerMember: async (credentials: SignUpModel) => {
    const data = {
      email: credentials.email,
      password: credentials.password,
      first_name: credentials.firstName,
      last_name: credentials.lastName,
      user_type: 'seeker',
    };

    try {
      const res = await axiosInstance.post('users', data);
      return res.data;
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
};

export const ForgotPasswordService = {
  sendEmail: async (email: string) => {
    try {
      const res = await axiosInstance.post('users/reset_password', {}, { params: { email } });

      return res.data;
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
  updatePassword: async (password: string) => {
    const { reset_token: reset_password_token } = queryString.parse(window.location.search);
    try {
      const res = await axiosInstance.put(
        'users/update_password',
        {},
        { params: { reset_password_token, password, password_confirmation: password } },
      );

      return res.data;
    } catch (error: any) {
      return { error: error.response.data.message };
    }
  },
};
