import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../Icon/Icon';
import LogoIcon from '../../../../assets/images/icons/logo.svg';
import { DivLink, LogoLink, Title } from '../styled';
import AppContext from '../../../context/app/AppContext';

const Logo = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { filterContent } = state as any;

  const actionNavigate = () => {
    dispatch({
      type: 'SET_FILTER',
      payload: {
        ...filterContent, filter: undefined, page: 1, category: 'feed', type: 'content'
      }
    });
  };

  const actionHome = () => {
    actionNavigate();
    navigate('/');
  };

  return (
    <> 
      <LogoLink onClick={actionHome} aria-label='Gatheright Home'>
        <Icon img={LogoIcon} width="24" height="24" alt='Gathersight' />
      </LogoLink>
      <DivLink onClick={actionHome}>
        <Title>Gathersight</Title>
      </DivLink>
    </>
  );
};

export default Logo;
