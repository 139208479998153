import React from 'react';

export const OrcidLogoIcon = () => (
  <svg width="21" height="21" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11C22 17.0758 17.0758 22 11 22C4.92422 22 0 17.0758 0 11C0 4.92422 4.92422 0 11 0C17.0758 0 22 4.92422 22 11Z"
      fill="#A6CE39"
    />
    <path d="M7.41621 16.0027H6.09277V6.79883H7.41621V16.0027Z" fill="white" />
    <path
      d="M9.3584 6.79883H12.9334C16.3365 6.79883 17.8318 9.23086 17.8318 11.4051C17.8318 13.7684 15.9842 16.0113 12.9506 16.0113H9.3584V6.79883ZM10.6818 14.8168H12.7873C15.7865 14.8168 16.474 12.5395 16.474 11.4051C16.474 9.55742 15.2967 7.99336 12.7186 7.99336H10.6818V14.8168Z"
      fill="white"
    />
    <path
      d="M7.62266 4.88164C7.62266 5.3543 7.23594 5.74961 6.75469 5.74961C6.27344 5.74961 5.88672 5.3543 5.88672 4.88164C5.88672 4.40898 6.27344 4.01367 6.75469 4.01367C7.23594 4.01367 7.62266 4.40898 7.62266 4.88164Z"
      fill="white"
    />
  </svg>
);
