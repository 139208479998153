import { useContext } from 'react';
import OrganizationContext from '../../context/organization/OrganizationContext';
import { keyExtractor } from '../../utils/helper';

export const usePayment = () => {
  const {
    billingInformation,
    setBillingInformation,
    paymentInformation,
    setPaymentInformation,
    credits,
    setCredits,
    paymentsInformation,
    setPaymentsInformation,
  } = useContext(OrganizationContext);
  const hasPayment = paymentsInformation.length > 0;

  const savePaymentInformation = () => {
    const newPaymentInfo = { ...paymentInformation };
    newPaymentInfo.isDefault = paymentInformation.isDefault || !hasPayment;
    newPaymentInfo.id = keyExtractor();
    setPaymentsInformation([...paymentsInformation, newPaymentInfo]);
    setPaymentInformation({
      expiry: '',
      cardNumber: '',
      cardHolderName: '',
      cvv: '',
      isDefault: false,
    });
  };

  const setDefaultPaymentMethod = (paymentId: string) => {
    const updatedPaymentsInformation = paymentsInformation.map((payment) => ({
      ...payment,
      isDefault: payment.id === paymentId,
    }));

    setPaymentsInformation(updatedPaymentsInformation);
  };

  const updatePaymentInformation = () => {
    const index = paymentsInformation.findIndex((card) => card.id === paymentInformation.id);

    if (index !== -1) {
      let updatedPaymentsInformation = [...paymentsInformation];
      updatedPaymentsInformation[index] = { ...paymentInformation };
      if (paymentInformation.isDefault) {
        updatedPaymentsInformation = paymentsInformation.map((payment) => ({
          ...payment,
          isDefault: payment.id === paymentInformation.id,
        }));
      }
      setPaymentsInformation(updatedPaymentsInformation);
    }

    setPaymentInformation({
      expiry: '',
      cardNumber: '',
      cardHolderName: '',
      cvv: '',
      isDefault: false,
    });
  };

  return {
    billingInformation,
    paymentInformation,
    paymentsInformation,
    credits,
    hasPayment,
    hasBilling: !!billingInformation,
    savePaymentInformation,
    updatePaymentInformation,
    setBillingInformation,
    setPaymentInformation,
    setDefaultPaymentMethod,
    setCredits,
  };
};
