import React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { styled } from '@stitches/react';
import { violet, blackA } from '@radix-ui/colors';
import { getInitials } from '../../../../utils/helper';

const AvatarRoot = styled(AvatarPrimitive.Root, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  userSelect: 'none',
  width: 35,
  height: 35,
  borderRadius: '100%',
  backgroundColor: blackA.blackA1,
});

const AvatarImage = styled(AvatarPrimitive.Image, {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
});

const AvatarFallback = styled(AvatarPrimitive.Fallback, {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  color: violet.violet11,
  fontSize: 15,
  lineHeight: 1,
  fontWeight: 500,
});

const MemberName = styled('p', {
  fontWeight: '$weight500',
});

const Flex = styled('div', { display: 'flex', gap: 10, alignItems: 'center' });

interface AvatarProps {
  avatar: string;
  alt: string;
  name: string;
}

const Avatar: React.FC<AvatarProps> = ({ avatar, alt, name }) => (
  <Flex>
    <AvatarRoot>
      <AvatarImage src={avatar} alt={alt} />
      <AvatarFallback delayMs={600}>{getInitials(name)}</AvatarFallback>
    </AvatarRoot>
    <MemberName>{name}</MemberName>
  </Flex>
);

export default Avatar;
