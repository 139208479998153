import React, { useState, useEffect } from 'react';
import { StylesConfig } from 'react-select';
import { SelectContainer, stylesMenu } from './styled';
import CustomOption from './CustomOption';
import MenuListCustom from './MenuListCustom';

interface SelectItemModel {
  options: Array<any>;
  placeholder?: string;
  name?: string | undefined;
  onChange?: any;
  value?: any | undefined;
  customStyles?: any;
  customTheme?: any;
  menuPortalTarget?: HTMLElement;
};

const SelectItem: React.FC<SelectItemModel> = ({ name, options, placeholder, onChange, value,
                                                 customStyles, customTheme, menuPortalTarget }) => {
  const [valueData, setValue] = useState<any>(null);
  const colorStyles: StylesConfig<any> = {
    control: (styles) => ({ ...styles, backgroundColor: 'transparent', border: 0, boxShadow: 'none' }),
    input: (styles) => ({ ...styles, minWidth: 100 }),
    dropdownIndicator: (styles) => ({ ...styles, padding: '0 4px 0 0' }),
    menu: (styles) => ({ ...styles, ...stylesMenu }),
  };

  const handleChange = (e:any) => {
    setValue(e);
    if (onChange) onChange(e.value);
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  const defaultTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: 'hsla(227, 78%, 60%, 1)',
    },
  });

  return (
    <SelectContainer
      components={{ IndicatorSeparator: null, Option: CustomOption, MenuList: MenuListCustom }}
      name={name}
      placeholder={placeholder}
      className='gat-select'
      value={valueData}
      onChange={handleChange}
      options={options}
      styles={customStyles || colorStyles}
      theme={customTheme || defaultTheme}
      menuPortalTarget={menuPortalTarget}
    />
  );
};

SelectItem.defaultProps = {
  placeholder: undefined,
  value: undefined,
  name: undefined,
  onChange: undefined,
  customStyles: undefined,
  customTheme: undefined,
  menuPortalTarget: undefined,
};

export default SelectItem;
