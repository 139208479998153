import React from 'react';
import { XIconWrapper } from './XIcon';
import { IconContainer } from './styled';

interface ClaseIconModalProps {
  onClick: () => void;
  isFeed?: boolean;
  css?: any;
}

export const CloseIconModal = ({ onClick, isFeed, css }: ClaseIconModalProps) => (
  <IconContainer onClick={onClick} css={css}>
    <XIconWrapper isFeed={isFeed} />
  </IconContainer>
);

CloseIconModal.defaultProps = {
  isFeed: false,
  css: undefined,
};
