import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PageContainer from '../../shared/components/Container/PageContainer';
import { GridContainer } from './styled';
import { SideNavbar } from './Sidebar';
import SelectedTagProvider from '../../shared/context/tag/SelectedTagsProvider';

export const Organization = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <SelectedTagProvider>
      <PageContainer layout="home">
        <GridContainer isExpanded={expanded}>
          <SideNavbar onUpdateExpanded={(isExpanded) => setExpanded(isExpanded)} />
          <Outlet />
        </GridContainer>
      </PageContainer>
    </SelectedTagProvider>
  );
};
