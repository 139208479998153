/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Field, FormikProvider, useFormik, FieldProps } from 'formik';
import * as Yup from 'yup';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { Error } from '../Forms/InputError/styled';
import { BoxContent, InputWrapper, ErrorText, FormStyled, FormikInput,
  BoxText, TextTitle, Textarea, TextAreaWrapper } from './styled';
import { TextLabel } from '../ModalContent/styled';

interface PlaylistFormModel {
  isEdit?: boolean;
  publicKB?: boolean;
  loading?: boolean;
  title?: string;
  description?: string;
  errorMessage?: string;
  actionEdit?: (e: any) => void;
  actionContinue?: (e: any) => void;
}

const palylistSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  description: Yup.string(),
});

const PlaylistForm: React.FC<PlaylistFormModel> = ({
  actionContinue, isEdit, title, description, loading, errorMessage, publicKB, actionEdit
}) => {
  const [isPublic, setIsPublic] = useState<boolean>(publicKB || true);

  const handleSubmit = (values: any) => {
    const formValues = { ...values, isPublic };

    if (isEdit) {
      if (actionEdit) {
        actionEdit(formValues);
      }
    } else if (actionContinue) {
      actionContinue(formValues);
    }
  };

  const playlistForm = useFormik({
    initialValues: {
      title: title || '',
      description: description || '',
    },
    validationSchema: palylistSchema,
    validateOnMount: true,
    onSubmit: (values: any) => {
      handleSubmit(values);
    }
  });

  return (
    <>
      <BoxText>
        <TextTitle css={{ fontSize: '$displayXSmall' }} >
          {`${isEdit ? 'Edit' : 'New'} Knowledge Playlist`}
        </TextTitle>
      </BoxText>
      <BoxContent css={{ textAlign: 'center', marginBottom: '2rem' }}>
        <FormikProvider value={playlistForm}>
          <FormStyled onSubmit={playlistForm.handleSubmit}>
            <InputWrapper>
              <FormikInput id='title' name='title' placeholder='Title' />
              {playlistForm.errors.title && playlistForm.touched.title ? (
                <Error>{playlistForm.errors.title}</Error>
              ) : null}
            </InputWrapper>
            <TextAreaWrapper>
              <Field
                name='description'
                id='description'
              >
                {(props: FieldProps) => <Textarea {...props.field} rows={4} placeholder='description' />}
              </Field>
            </TextAreaWrapper>
            <Checkbox
              text={(
                <TextLabel css={{ alignItems: 'center' }}>
                  Make this playlist public
                </TextLabel>
              )}
              defaultChecked
              checked={isPublic}
              onChange={(e: any) => setIsPublic(e)}
            />
          </FormStyled>
        </FormikProvider>
        {errorMessage && (<ErrorText>
          {errorMessage}
        </ErrorText>)}
      </BoxContent>
      <Button
        title={isEdit ? 'Edit' : 'Create'}
        loading={loading}
        css={{ width: 211, borderRadius: '$radius05' }}
        renderProps={{
          disabled: !playlistForm?.isValid || loading,
          onClick: () => handleSubmit(playlistForm?.values)
        }}
      />
    </>
  );
};

PlaylistForm.defaultProps = {
  title: undefined,
  description: undefined,
  errorMessage: undefined,
  isEdit: false,
  loading: false,
  publicKB: false,
  actionEdit: undefined,
  actionContinue: undefined,
};

export default PlaylistForm;
