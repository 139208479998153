import React from 'react';
import {
  WrapperShimmer,
  CardContentShimmer,
  WrapperShimmerUser,
  BreathingComponents,
  TitleShimmer,
} from './styles';

const style = {
  maxWidth: '85%',
  flexDirection: 'column',
  padding: '1rem 0',
  gap: '1rem',
  '& .circle': { display: 'none' },
  '& .circle.small': { display: 'block' },
  '@bp3': {
    padding: '1.594rem 0',
    flexDirection: 'row',
    gap: '0.5rem',
    '& .circle': { display: 'block' },
    '& .circle.small': { display: 'none' },
  },
};

const UserLoad: React.FC<any> = () => (
  <WrapperShimmer css={style}>
    <BreathingComponents className="circle" width={150} height={150} />
    <BreathingComponents className="circle small" width={100} height={100} />
    <WrapperShimmerUser>
      <CardContentShimmer>
        <TitleShimmer css={{ maxWidth: 400 }}>
          <BreathingComponents className="tab" height={24} />
        </TitleShimmer>
        <BreathingComponents className="tab" height={18} />
      </CardContentShimmer>
    </WrapperShimmerUser>
  </WrapperShimmer>
);

export default UserLoad;
