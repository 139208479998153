/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import checkPrimaryIcon from '../../../assets/images/icons/check-primary.svg';
import { Checkbox, WrapperCheckBox, Label, CheckboxIndicator } from './styles';
import Icon from '../Icon/Icon';

interface CheckBoxModel {
  name?: string;
  text?: JSX.Element | JSX.Element[] | any;
  onChange?: (event: any) => void;
  checked?: boolean | undefined;
  defaultChecked?: boolean;
  isCredits?: boolean;
  css?: any;
}

type CheckBoxProps = CheckBoxModel &
  React.ComponentProps<typeof Checkbox> & {
    kind?: string;
    key?: string;
    size?: 'default';
  };

const CheckboxComponent = ({
  text,
  name,
  onChange,
  checked,
  kind,
  defaultChecked,
  isCredits,
  key,
  css,
  size,
}: CheckBoxProps) => {
  const [checkedValue, setCheckedValue] = useState<any>(checked || false);
  const handlerCheck = (e: boolean) => {
    setCheckedValue(e);

    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    setCheckedValue(checked);
  }, [checked]);

  return (
    <WrapperCheckBox credits={isCredits}>
      <Checkbox
        id={key}
        checked={checkedValue}
        onCheckedChange={handlerCheck}
        defaultChecked={defaultChecked}
        credits={isCredits}
        css={css}
        size={size}
      >
        <CheckboxIndicator>
          <Icon
            img={checkPrimaryIcon}
            width={size ? '10px' : '14px'}
            height={size ? '10px' : '14px'}
            alt="Check"
          />
        </CheckboxIndicator>
      </Checkbox>
      {text && (
        <Label htmlFor={key} className="checkbox-label">
          {text}
        </Label>
      )}
    </WrapperCheckBox>
  );
};

CheckboxComponent.defaultProps = {
  checked: undefined,
  onChange: undefined,
  kind: undefined,
  defaultChecked: false,
  text: '',
  name: 'check-default',
  isCredits: false,
  key: undefined,
  css: {},
  size: undefined,
};

export default CheckboxComponent;
