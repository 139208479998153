import React, { useEffect, useState } from 'react';
import { AvatarImage, AvatarNoImage } from './styles';
import { getInitials, keyExtractor } from '../../../../shared/utils/helper';

interface AvatarProps {
  avatar: {
    name: string;
    link: string;
  };
}

export const Avatar = ({ avatar }: AvatarProps) => {
  const [validImage, setValidImage] = useState(true);

  const verifyImage = () => {
    const img = new Image();
    img.src = avatar.link as string;

    img.onload = () => {
      setValidImage(true);
    };

    img.onerror = () => {
      setValidImage(false);
    };
  };

  useEffect(() => {
    verifyImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar.link]);
  return validImage ? (
    <AvatarImage key={keyExtractor()} src={avatar.link} alt={`Avatar ${avatar.name}`} />
  ) : (
    <AvatarNoImage>{getInitials(avatar.name)}</AvatarNoImage>
  );
};
