import { styled } from '../../../config/theme';

export const ErrorTitleBox = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$spaceXSmall',
  flexWrap: 'wrap',
  maxWidth: '630px',
  variants: {
    type: {
      step: {
        justifyContent: 'start',
        marginBottom: '$spaceSmall',
      },
    },
  },
});
