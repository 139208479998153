import * as HoverCard from '@radix-ui/react-hover-card';
import { styled } from '../../../config/theme';

export const TooltipContent = styled(HoverCard.Content, {
  backgroundColor: '#FCFCFC',
  borderRadius: '12px',
  padding: '22px',
  filter: 'drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.07))',
  width: ' 100%',
  maxWidth: '95vw',
  margin: 0,
  zIndex: 10,
  height: 'fit-content',
  '@bp2': {
    maxWidth: 375,
    minWidth: 375,
  },
  variants: {
    tag: {
      true: {
        width: '23rem',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        paddingLeft: '2.5rem',
        marginY: '$spaceSmall',
        p: {
          fontFamily: '$poppins',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 18,
          color: '$offBlack',
          marginBottom: 20,
          width: 'fit-content',
        },
        '@bp2': {
          maxWidth: 'fit-content',
          minWidth: 'fit-content',
        },
      },
    },
    share: {
      true: {
        paddingX: '$spaceNone',
        paddingBottom: '0.5rem',
        height: 'auto',
      },
    },
    avatar: {
      true: {
        filter: 'none',
        bottom: '100px',
        backgroundColor: 'transparent',
        top: '2px',
        width: 'auto',
      },
    },
  },
});

export const HoverCardTrigger = styled(HoverCard.Trigger, {
  '&.mention': {
    cursor: 'pointer',
    lineHeight: '20px',
    display: 'inline-flex',
  },
});
