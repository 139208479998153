import { styled } from '../../../config/theme';

export const Wrapper = styled('div', {
  display: 'flex',
  '& img': {
    margin: 0
  }
});

export const BoxHover = styled('div', {
  position: 'relative',
  cursor: 'pointer',
});

export const ListWrapper = styled('div', {
  position: 'absolute',
  width: '120px',
  right: '0'
});

export const List = styled('ul', {
  position: 'absolute',
  listStyle: 'none',
  margin: 0,
  paddingLeft: 0,
  backgroundColor: 'white',
  boxShadow: '$shadowBase',
  color: '$offBlack',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: '4px',
  marginTop: '.5rem',
  transition: 'all 0.3s ease-in-out',
  opacity: 1,
  zIndex: 1,

  variants: {
    show: {
      true: {
        zIndex: -1,
        opacity: 0,
      }
    }
  }
});

export const ItemList = styled('li', {
  display: 'block',
  cursor: 'pointer',
  float: 'left',
  fontSize: '$bodySmall',
  padding: '0.5rem 1rem',
  position: 'relative',
  textDecoration: 'none',
  letterSpacing: '$letterSpacing3',
  transitionDuration: '0.5s',
  '&:hover': {
    backgroundColor: '$input',
  }
});

export const Box = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '&:first-child::before': {
    content: '',
    position: 'absolute',
    width: '4.875rem',
    backgroundColor: '$input',
    height: '1px',
    bottom: '0',
    left: '0',
    right: '0',
    margin: '0 auto'
  },
  '& img': {
    marginRight: '12px !important'
  }
});

export const Text = styled('p', {
  fontWeight: '$normal',
});
