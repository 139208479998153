import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexContainer, Typography } from '../../../../screens/Organization/styled';
import { CreditsContainer, CreditsCounterContainer } from './styles';
import Button from '../../Button/Button';
import Icon from '../../../../assets/images/icons/Actions Plus.svg';
import { usePayment } from '../../../hooks/Organization/usePayment';

export const AddCredits = () => {
  const navigate = useNavigate();
  const { credits } = usePayment();
  return (
    <FlexContainer justify="start" direction="column" spacing="marginYSpaceLarge">
      <Typography
        size="displayXSmall"
        weight="700"
        lineHeight="lineHeight3"
        letterSpacing="letterSpacing3"
      >
        Your Credits
      </Typography>
      <CreditsContainer>
        <Typography size="bodyLarge" weight="500">
          Credits Available
        </Typography>
        <CreditsCounterContainer>
          <Typography size="bodySmall" weight="500">
            {credits}
          </Typography>
        </CreditsCounterContainer>
      </CreditsContainer>
      <Button
        iconCustom={Icon}
        title="Add Credits"
        kind="add-dashboards"
        handleClick={() => navigate('/organization/billing/add-credits')}
      />
    </FlexContainer>
  );
};
