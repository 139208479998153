import React from 'react';
import PageContainer from '../../shared/components/Container/PageContainer';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
import MetaTags from '../../shared/components/MetaTags/MetaTags';
import { Title, SubTitle, Text } from './styled';

const ExpertTerms = () => (
  <PageContainer layout="statics">
    <MetaTags
      title="Gathersight: Terms of Use"
      description="These are Gathersight's terms of use. It's probably not the most interesting long-form content on the site."
      canonical="https://www.gathersight.com/terms/"
    />
    <LayoutContainer type="statics" css={{ flexWrap: 'wrap' }}>
      <LayoutCol col="12" css={{ flexDirection: 'column', alignItems: 'baseline' }}>
        <Title>Terms and Conditions for Experts</Title>
        <Text>
          Gathersight facilitates recorded video and written expert opinions (“Opinions”) between
          its Clients and industry professionals (“Experts”) who provide insight into topics,
          companies, sectors and products to help Clients make informed decisions. These terms and
          conditions between you and Gathersight Limited (“Gathersight”) set out the legal and
          compliance obligations applicable to Experts. Please read them carefully. These terms and
          conditions and all other documents referred to in them, together with Gathersight’s{' '}
          <a href="https://www.gathersight.com/privacy" rel="noreferrer" target="_blank">
            Privacy Policy
          </a>{' '}
          are referred to as “the Agreement”. In this Agreement “Client” means a party who engages
          Gathersight to acquire an Opinion from an Expert (for these purposes “Client” includes any
          party appointed by the Client to engage on its behalf).
        </Text>

        <SubTitle>1. Gathersight Expert Opinions</SubTitle>
        <Text>
          <b>1.1</b> By agreeing to provide an Opinion you confirm that you understand and are
          knowledgeable about the proposed subject matter and that you will undertake the Opinion
          diligently, professionally and to the best of your ability.
        </Text>

        <Text>
          <b>1.2</b> While providing an Opinion you will be acting in your personal capacity, as an
          independent contractor, and not as an employee or representative of Gathersight or any
          other party.
        </Text>
        <Text>
          <b>1.3</b> If at any time before, during or after an Opinion you become aware of any
          professional, regulatory or ethical conflict or other reason why you should not
          participate/have provided an Opinion, you should terminate the Opinion and notify
          Gathersight immediately by emailing customerteam@gathersight.com.
        </Text>

        <SubTitle>2. Confidential Information</SubTitle>
        <Text>Information you hold:</Text>
        <Text>
          <b>2.1</b> You will not disclose any (i) <b>confidential information</b> or (ii) any{' '}
          <b>material non-public information</b> concerning a quoted company, security or instrument
          or any trade secret <b>– please note that:</b>
        </Text>
        <Text>
          <b>“confidential information”</b> includes, but is not limited to: (i) confidential or
          proprietary information about your current (or a former) employer; (ii) trade secrets;
          (iii) financial information, such as margins or revenues, not publicly released; (iv) any
          other information which you are under a duty to keep confidential; and (v) identities of
          Gathersight clients or the subject matter of any Opinion;
        </Text>
        <Text>
          <b> “material non-public information”</b> (“MNPI”), sometimes referred to as “inside
          information”, is a specific type of confidential information. MNPI is information that is
          not generally available and there is a substantial likelihood that a reasonable investor
          would consider it important to an investment decision. If this information was generally
          available, it could significantly affect the price of an investment or significantly alter
          the total mix of information available to investors. MNPI includes, but is not limited to:
          (i) financial information or results before they are made public; (ii) non-public
          information regarding a merger, divestiture, or material contract; and (iii) information
          relating to unpublished clinical trials.
        </Text>
        <Text>Information you receive from Gathersight or our Clients:</Text>
        <Text>
          <b>2.2</b> You will keep confidential, not disclose to any third party or use for any
          purpose, any confidential information disclosed to you before, during or after providing
          Opinion (including without limitation the identity of a Client, the subject matter of any
          Opinion or the questions asked for an Opinion and the rates agreed by you with Gathersight
          in respect of any Opinion).
        </Text>
        <Text>General:</Text>
        <Text>
          <b>2.3</b> You agree that a breach of this clause 2 may cause irreparable harm to
          Gathersight and/or its Client and that damages would not be a sufficient remedy in respect
          of such breach. Without prejudice to any other rights which it may have, Gathersight
          and/or the relevant Client shall be entitled to seek injunctive relief and other
          applicable equitable remedies in respect of any such breach.
        </Text>

        <SubTitle>3. Your obligations</SubTitle>
        <Text>
          <b>3.1</b> You represent, warrant and undertake that:
        </Text>
        <Text>
          <b>(a)</b> the biographical information provided by you to Gathersight is up to date, true
          and not misleading and you will update it in respect of any material changes (including
          any change to your employment);
        </Text>
        <Text>
          <b>(b)</b> you will not provide an Opinion which principally relates to any company of
          which you are an employee, officer, director, contractor, agent, legal representative,
          partner, or joint venturer, nor will you provide information to any Client if a company of
          which you are an employee, officer, director, contractor, agent, legal representative,
          partner, or joint venturer could reasonably consider such information to be proprietary,
          confidential, or constitute a trade secret, or if the disclosure of such information could
          reasonably be expected to cause damage to the company;
        </Text>
        <Text>
          <b>(c)</b> you will not knowingly provide an any Opinion for a Client that is (or is
          acting on behalf of) a competitor of your current employer or a company for which you
          serve as an officer or director;
        </Text>
        <Text>
          <b>(d)</b> you are not restricted from and have the necessary permissions to provide each
          Opinion. For example, if you are employed, your contract of employment or employer’s
          policies may require you to obtain your employer’s prior consent to provide Opinions;
        </Text>
        <Text>
          <b>(e)</b> you will inform Gathersight if you are (or have been during the past 2 years)
          an employee or advisor or consultant to any government, government department, government
          agency or to any state entity;
        </Text>
        <Text>
          <b>(f)</b> if you are a government official or employee, you will not discuss government
          legislation, regulation, policy, contracts or any other business that you, as a government
          official or employee, would be in a position to vote upon or otherwise influence;
        </Text>
        <Text>
          <b>(g)</b> you will inform Gathersight if you are currently involved with any clinical
          trial or test or have been so involved where the results of such trial or test have not
          been publicly disclosed;
        </Text>
        <Text>
          <b>(h)</b> if you are a medical professional, you will not discuss unpublished clinical
          trial results, patient experience information or any other information regarding trials
          which is not yet public;
        </Text>
        <Text>
          <b>(i)</b> you have not been found guilty of insider dealing, market abuse, money
          laundering, fraud or any offence involving dishonesty or any felony (or similar offences
          in any jurisdiction) and have not been subject to any order, judgment, action or
          investigation by any regulatory body; further you will immediately inform Gathersight
          should you become aware of any investigation involving, or any charge brought against you
          in respect of, any offence referred to in this clause 3.1(i);
        </Text>
        <Text>
          <b>(j)</b> you will not provide any investment, legal, financial, accountancy, or medical
          advice to any Client including, without limitation, recommending, rating or valuing any
          security or providing advice regarding the investment in, purchase or sale of any
          securities;
        </Text>
        <Text>
          <b>(k)</b> you will not provide any Opinion or provide any information that would result
          in: (i) a breach of applicable laws or regulation, (ii) a breach of any agreement or any
          obligation to a third party (including to your employer), (iii) a professional, regulatory
          or ethical conflict, or (iv) if there is any other reason why you should not provide in
          such Opinion or provide such information;
        </Text>
        <Text>
          <b>(l)</b> you will in connection with Opinions: (i) comply with all applicable laws,
          statutes, regulations and codes relating to anti-bribery and anti-corruption
        </Text>

        <SubTitle>4. Intellectual Property</SubTitle>
        <Text>
          <b>4.1</b> In this clause 4 “Intellectual Property” means all patents, rights to
          inventions, utility models, copyright and related rights, trade marks, service marks,
          trade, business and domain names, rights in goodwill or to sue for passing off, rights in
          designs, rights in computer software, database rights, topography rights, rights in
          confidential information and any other intellectual property rights, whether registered or
          unregistered, and including all applications and renewals in any part of the world.
        </Text>
        <Text>
          <b>4.2</b> All Intellectual Property rights in any material provided to you by Gathersight
          or contained on the Gathersight website belong to Gathersight and may not be reproduced or
          redistributed.
        </Text>
        <Text>
          <b>4.3</b> You will not provide any information or materials in or after any Opinion that
          may infringe, misappropriate or conflict with the Intellectual Property rights of any
          third party.
        </Text>
        <Text>
          <b>4.4</b> Unless otherwise agreed with a Client in writing, you warrant that any
          documentation or any other material (including without limitation reports, studies, data,
          diagrams, charts, specifications, and programs) (“Work Product”) provided by you to a
          Client are original works and do not infringe any third party’s Intellectual Property
          rights and you hereby agree to grant to the Client (and/or Client’s client) to which you
          supply such Work Products a non-exclusive, royalty-free, perpetual licence to use any and
          all Intellectual Property rights subsisting in such Work Products in the course of its
          business.
        </Text>
        <Text>
          <b>4.5</b> You will indemnify Gathersight against all liabilities it may incur in
          connection with any claims or proceedings brought against it based on a claim that the
          Work Product provided to a Client infringes any Intellectual Property rights or other
          proprietary rights of any third party.
        </Text>
        <Text>
          <b>4.6</b> You must not use the name “Gathersight” or any other trademark, symbol or logo
          of Gathersight without Gathersight’s prior written consent.
        </Text>

        <SubTitle>5. Payment</SubTitle>
        <Text>
          <b>
            <b>5.1</b>
          </b>{' '}
          Unless a Client rejects your Opinion, Gathersight will compensate you at the agreed upon
          rate in respect of each completed Opinion, in accordance with the payment information
          provided by you. If you are receiving payment for an Opinion, you should enter your bank
          information on the Gathersight’s platform or by emailing{' '}
          <a href="mailto:customerteam@gathersight.com">customerteam@gathersight.com</a>.
        </Text>
        <Text>
          <b>5.2</b> Payment will be made within 5 banking days from the submission of your Opinion.
          If you do not receive payment, you should inform Gathersight by email stating that payment
          has not been received. Gathersight will deduct from the payment of fees any withholding
          taxes or other amounts required to be deducted by applicable local law or regulation.
        </Text>
        <Text>
          <b>5.3</b> Gathersight will investigate all queries of non-payment and will endeavour to
          ensure the relevant fees are paid as soon as possible.
        </Text>
        <Text>
          <b>5.4</b> Gathersight reserves the right not to pay fees if a Client rejects the Opinion
          and the reason for rejection is upheld by Gathersight
        </Text>
        <Text>
          <b>5.5</b> Gathersight agrees to issue self-billed invoices for services provided by you
          until 12 months from the date of this Agreement;
        </Text>
        <Text>
          <b>5.6</b> You agree:
        </Text>
        <Text>
          <b>(a)</b> to accept invoices raised by Gathersight on your behalf until 12 months from
          the date of this Agreement;
        </Text>
        <Text>
          <b>(b)</b> not to raise invoices for services provided in respect of this Agreement;{' '}
        </Text>
        <SubTitle>6. Liability</SubTitle>
        <Text>
          <b>6.1</b> Gathersight’s total liability in contract, tort, misrepresentation or otherwise
          arising in connection with this Agreement shall be limited to an amount equal to the fees
          paid to you.
        </Text>
        <Text>
          <b>6.2</b> Gathersight shall not be liable for any loss or damage, or any costs, expenses
          or other claims including without limitation:(i) loss of profit: (ii) loss of business;
          (iii) loss of revenue; (iv) loss of goodwill; (v) loss of anticipated savings; (vi) loss
          of any data or information and/or (vii) special or indirect loss or consequential loss or
          otherwise which arise out of any Opinion or in connection with this Agreement.
        </Text>
        <Text>
          <b>6.3</b> Nothing in the Agreement excludes or limits Gathersight’s liability for death
          or personal injury caused by its negligence or for fraudulent misrepresentation.
        </Text>

        <SubTitle>7. Third Parties</SubTitle>
        <Text>
          <b>7.1</b> Subject to clause 9.2, the parties confirm their intent not to confer any
          rights on any third parties by virtue of the Agreement. The Contracts (Rights of Third
          Parties) Act 1999 (“Third Party Rights Act”) shall not apply.
        </Text>
        <Text>
          <b>7.2</b> Subject to clauses 9.3 and 9.4, Clients may rely upon and enforce your
          compliance with the terms of clauses 2.1, 2.2 and 2.3 (Confidential Information), 3 (Your
          Obligations), 4 (Intellectual Property) and 6 (Data Protection and Telephone Recording).
        </Text>
        <Text>
          <b>7.3</b> The third party rights referred to in clause 9.2 may only be enforced by the
          relevant third party with the prior written consent of Gathersight and subject to and in
          accordance with the provisions of the Third Party Rights Act and all other relevant terms
          of this Agreement.
        </Text>
        <Text>
          <b>7.4</b> Notwithstanding any other provision of the Agreement, Gathersight may vary any
          of the provisions of the Agreement in any way without the consent of any third party.
          Section 2(1) of the Third Party Rights Act shall not apply.
        </Text>

        <SubTitle>8. Miscellaneous</SubTitle>
        <Text>
          <b>8.1</b> No delay or failure in exercising any right under this Agreement, or any
          partial or single exercise of any right, will constitute a waiver of that right or any
          other rights under this Agreement. No consent to a breach of any express or implied term
          of this Agreement constitutes consent to any subsequent breach.
        </Text>

        <Text>
          <b>8.2</b> In the event that any part or provision of this Agreement is determined by any
          court or other competent authority to be invalid, unlawful, or unenforceable to any
          extent, it shall to that extent be severed from the remainder of this Agreement which
          shall continue to be valid and enforceable to the fullest extent permitted by law.
        </Text>
        <Text>
          <b>8.3</b> This Agreement is binding upon and will inure to the benefit of the parties’
          respective successors and assigns. You may not assign or sub-contract your rights or
          obligations under this Agreement to any third party.
        </Text>
        <Text>
          <b>8.4</b> Clauses 2.2, 2.3, 3.1(p), 6.1, 7, 8, 9 and 10 shall survive the expiry or
          termination of this Agreement. Termination of this Agreement shall be without prejudice to
          the rights and obligations accrued by either party prior to termination.
        </Text>
        <Text>
          <b>8.5</b> If you are an interpreter engaged by Gathersight to interpret on an Opinion you
          are bound by these Terms and Conditions (except for clause 7) and you will be paid as
          agreed between Gathersight and you or, if applicable, your employer.
        </Text>
        <Text>
          <b>8.6</b> This Agreement, and any dispute or claim arising out of or in connection with
          it or its subject matter or formation (including non-contractual disputes or claims),
          shall be governed by English law and without prejudice to Gathersight’s right to take
          proceedings before any other court of competent jurisdiction, the parties hereby
          irrevocably submit to the exclusive jurisdiction of the English courts.
        </Text>
        <Text>Last revised 19.03.24</Text>
      </LayoutCol>
    </LayoutContainer>
  </PageContainer>
);

export default ExpertTerms;
