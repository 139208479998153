import React, { FC, useState, useEffect, useContext, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import AppContext from '../../context/app/AppContext';
import { KnowledgeService } from '../../../services/knowledge.service';
import { success } from '../ToastAlerts/alerts';
import ModalOnboarding from '../../../screens/OnBoarding/ModalOnboarding/ModalOnboarding';
import { WrapperSaveButton, TextSave, MoreText } from './styled';
import { capitalizeFirstLetter } from '../../utils/helper';
import { Resources, PlaylistItem } from '../../interfaces/interfaces';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import ModalCreatePlaylist from './ModalsSaveButton/ModalCreatePlaylist';
import ModalSavePlaylist from './ModalsSaveButton/ModalSavePlaylist';

interface SaveButtonModel {
  text?: string;
  idKb?: number;
  save?: boolean;
  disabled?: boolean;
  idResource?: string;
  allKb?: Array<any>;
  type?: string;
  modalOpened?: boolean;
  updatePlaylist?: boolean;
  actionSave?: () => Promise<Array<any>>;
}
interface positionModel {
  x?: number;
  y?: number;
}

const ShowAlert = (kind: string, KBtitle: string, id: string, userSlug: string) => {
  const type = capitalizeFirstLetter(kind);
  const title = capitalizeFirstLetter(KBtitle);
  const link = (
    <div>
      {type} saved in <Link to={`/users/${userSlug}/playlists/${id}`}>{title}</Link>
    </div>
  );
  success(link);
};

const MoreKB: FC<{ total: number | undefined }> = ({ total }) => {
  const textTotalKB = Number(total) < 2 ? '' : `+${Number(total) - 1}`;

  return <MoreText>{textTotalKB}</MoreText>;
};

const SaveButton: FC<SaveButtonModel> = ({
  text,
  idKb,
  actionSave,
  save,
  disabled,
  type,
  idResource,
  allKb,
  modalOpened,
  updatePlaylist,
}) => {
  const userLogged = useIsLoggedIn();
  const [showOnboarding, setShowOnboarding] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(save || false);
  const [itemSelect, setItemSelect] = useState<any>({ id: '', title: '' });
  const [open, setOpen] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDel, setLoadingDel] = useState<any>(undefined);
  const [position, setPosition] = useState<positionModel>({ x: 0, y: 0 });
  const [data, setData] = useState<Array<any>>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [totalKbItem, setTotalKbItem] = useState<number>(allKb?.length || 0);
  const { state, dispatch } = useContext(AppContext);
  const { authenticationToken, userData, newPlaylistItems } = state as any;
  const { userId } = userData;
  const navigate = useNavigate();

  const actionClose = () => {
    setOpen(false);
    setErrorMessage('');
  };

  const userCollaborate = (item: any) => {
    const collaborators = item?.attributes?.who_access?.data;
    return (
      collaborators.filter((user: any) => user?.attributes?.user_id.toString() === userId).length >
      0
    );
  };

  const validateOwner = (item: any) => {
    if (item?.created_by_id?.toString() === userId || userCollaborate(item)) {
      return true;
    }

    return false;
  };

  const isSelected = (item: any, ele: any) =>
    Number(item?.id) === Number(ele?.id) || item?.selected;

  const loadKnowledge = useCallback(
    async (filterBy?: string) => {
      if (open) {
        const params = {
          authenticationToken,
          page: 1,
          limit: 10,
          filterBy,
        };

        const results = await KnowledgeService.getIndex(params);

        setData(
          results.map((i: any) => ({
            ...i,
            ...i.attributes,
          })),
        );
      }
    },
    [authenticationToken, open],
  );

  const createKnowledgeItem = useCallback(
    async (idK: string, contentKind: string, idResourceKind: any, textKb: string) => {
      const params = {
        authenticationToken,
        id: idK,
        filter: contentKind === 'content_reduced' ? 'content' : contentKind,
        contentId: idResourceKind,
        userId: idResourceKind,
      };
      if (contentKind === 'content' || contentKind === 'content_reduced') {
        delete params.userId;
      } else {
        delete params.contentId;
      }

      const results = await KnowledgeService.createKnowledgeItem(params, textKb);
      if (results?.id) {
        await setItemSelect({ id: idK, title: textKb });
        await dispatch({ type: 'SET_RELOAD', payload: true });
        await setTotalKbItem((prev) => prev + 1);
        await setOpenCreate(false);
        await actionClose();
        await setLoading(false);
        ShowAlert(results.attributes.kind, textKb, idK, userData.slug);
      } else {
        await setErrorMessage(results?.message);
        await setLoading(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticationToken, dispatch],
  );

  const deleteKnowledgeItem = useCallback(
    async (idKB: any, idItem: any) => {
      await setLoadingDel(idKB);
      const params = {
        authenticationToken,
        id: idKB,
      };

      const results = await KnowledgeService.deleteKnowledgeItem(params, idItem);

      if (!results?.fail) {
        if (!updatePlaylist) await dispatch({ type: 'SET_RELOAD', payload: true });
        if (updatePlaylist) {
          const dataPlaylist = newPlaylistItems.filter((elem: Resources) => elem.id !== idResource);
          await dispatch({ type: 'SET_PLAYLIST_ITEMS', payload: dataPlaylist });
        }
        await setTotalKbItem((prev) => prev - 1);
        await actionClose();
        await setLoadingDel(undefined);
        success(results?.message);
      } else {
        await setErrorMessage(results?.message);
        await setLoadingDel(undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticationToken, dispatch, newPlaylistItems],
  );

  const createKnowledge = useCallback(
    async (titleData: string, descriptionData: string, publicKb: any) => {
      await setLoading(true);
      const params = {
        authenticationToken,
        title: titleData,
        description: descriptionData,
        isPublic: publicKb,
      };
      const results = await KnowledgeService.createKnowledge(params);
      if (results?.id) {
        await createKnowledgeItem(
          results?.attributes.slug,
          type || '',
          idResource,
          results?.attributes?.title,
        );
      } else {
        await setErrorMessage(`Fail to create`);
        await setLoading(false);
      }
    },
    [authenticationToken, createKnowledgeItem, idResource, type],
  );

  const handleClick = (item: any, elemSelect: any) => {
    if (isSelected(item, elemSelect)) {
      if (validateOwner(item)) {
        deleteKnowledgeItem(item?.id, item?.kb_item_id);
      }
    } else {
      createKnowledgeItem(item?.attributes.slug, type || '', idResource, item?.title);
    }
  };

  const handleSave = (e: any) => {
    if (userLogged) {
      if (updatePlaylist) {
        const findKB = allKb?.find((k: PlaylistItem) => k.id === idKb);
        if (findKB) {
          deleteKnowledgeItem(idKb, findKB.kb_item_id);
        }
        return null;
      }
      if (e?.clientX && e?.clientY) {
        setPosition({ x: e?.clientX, y: e?.clientY });
      }
      if (actionSave) {
        actionSave();
      } else {
        setOpen((prevProp) => !prevProp);
      }
    } else {
      navigate('/authentication#sign-in');
    }
    return null;
  };

  const actionSearch = async (item: string) => {
    await loadKnowledge(item);
  };

  const formatData = (items: Array<any>) => {
    const newData = items.map((ele) => {
      let selected = false;
      const findKB = allKb?.find((e) => Number(e?.id) === Number(ele?.id));
      if (findKB) {
        selected = true;
      }

      return { ...ele, kb_item_id: findKB?.kb_item_id, selected };
    });

    if (newData.length && itemSelect?.id) {
      newData.forEach((d: any, index: number) => {
        if (Number(d?.id) === Number(itemSelect?.id)) {
          newData.splice(index, 1);
          newData.unshift(d);
        }
      });
    }

    return newData || [];
  };

  const dataList: any[] = formatData(data);

  const actionNewKB = () => {
    setOpen(false);
    setOpenCreate(true);
  };

  useEffect(() => {
    if (open) {
      loadKnowledge();
    }
  }, [open, loadKnowledge]);

  useEffect(() => {
    if (idResource || text === '') {
      setItemSelect({ id: idKb, title: text });
      setIsSave(false);
    }

    return () => {
      setIsSave(false);
      setItemSelect({ id: '', title: '' });
    };
  }, [idResource, idKb, text]);

  useEffect(() => {
    (async () => {
      if (!isSave && itemSelect?.id && itemSelect?.title) {
        setIsSave(true);
      }
    })();
  }, [itemSelect, isSave]);

  useEffect(() => {
    const openModal = () => {
      setOpen(true);
      dispatch({ type: 'SET_CONTENT_TO_PLAYLIST', payload: false });
    };
    if (modalOpened) {
      openModal();
    }

    return () => {
      setShowOnboarding(false);
      setIsSave(false);
      setItemSelect({ id: '', title: '' });
      setOpenCreate(false);
      setLoadingDel(undefined);
      setPosition({ x: 0, y: 0 });
      setOpen(false);
      setData([]);
      setTotalKbItem(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperSaveButton>
      {isSave && (
        <TextSave>
          {`${itemSelect?.title} `}
          <MoreKB total={totalKbItem} />
        </TextSave>
      )}
      <Tooltip content="Add to Knowledge Playlist" type="save-resource">
        <Button
          title=""
          kind="save"
          iconWidth="16px"
          iconHeight="18px"
          iconSave={!isSave}
          iconSaved={isSave}
          ariaLabel="Add to Knowledge Playlist"
          renderProps={{ disabled, onClick: handleSave }}
        />
      </Tooltip>
      {open && (
        <ModalSavePlaylist
          open={open}
          dataList={dataList}
          isSave={isSave}
          loading={loadingDel}
          positionY={position.y}
          itemSelect={itemSelect}
          errorMessage={errorMessage}
          onClose={actionClose}
          actionClick={handleClick}
          actionSelected={isSelected}
          actionSearch={actionSearch}
          actionForm={actionNewKB}
        />
      )}
      {openCreate && (
        <ModalCreatePlaylist
          open={openCreate}
          loading={loading}
          errorMessage={errorMessage}
          onClose={() => {
            setOpenCreate(false);
            setErrorMessage('');
          }}
          actionForm={(e: any) => createKnowledge(e?.title, e?.description, e?.isPublic)}
        />
      )}
      {!userLogged && showOnboarding && (
        <ModalOnboarding
          userProps={{ name: 'K P', actionClose: () => setShowOnboarding(false), isKb: true }}
        />
      )}
    </WrapperSaveButton>
  );
};

SaveButton.defaultProps = {
  text: undefined,
  idKb: undefined,
  save: false,
  disabled: false,
  actionSave: undefined,
  idResource: undefined,
  type: undefined,
  allKb: [],
  modalOpened: false,
  updatePlaylist: false,
};

export default SaveButton;
