import React from 'react';
import Button from '../../../shared/components/Button/Button';

interface ButtonDefaultModel {
  actionClick?: () => void;
  title?: string;
  loadingBtn?: boolean;
  disabled?: boolean;
}

const ButtonDefault: React.FunctionComponent<ButtonDefaultModel> = ({
  actionClick, title, loadingBtn, disabled
}) => (
  <Button
    title={title || ''}
    loading={loadingBtn}
    css={{ maxWidth: '100%', height: '56px' }}
    kind='modal'
    renderProps={{
      onClick: () => actionClick && actionClick(), disabled
    }}
  />
);

ButtonDefault.defaultProps = {
  title: undefined,
  actionClick: undefined,
  loadingBtn: false,
  disabled: false,
};

export default ButtonDefault;
