import React, { useEffect, useState } from 'react';
import { UnsubscribeService } from '../../services/UnsubscribeService/UnsubscribeService';
import PageContainer from '../../shared/components/Container/PageContainer';
import { LayoutCol, LayoutContainer } from '../../shared/components/Layouts/styles';
import { Wrapper } from './styled';

const Unsubscribe = () => {
  const params = new URLSearchParams(window.location.search);
  const unsubscribeToken = params.get('unsubscribe_token');
  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState<boolean>(false);
  const [unsubscribeError, setUnsubscribeError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if(unsubscribeToken) {
        const response = await UnsubscribeService.unsubscribe({ unsubscribeToken });
        if (response === 'error') { setUnsubscribeError(true); }
        else { setUnsubscribeSuccess(true); }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer layout="unsubscribe">
      <LayoutContainer type="statics">
        <LayoutCol col="12">
          { unsubscribeSuccess && (
            <Wrapper>
              <h2>Your email has been successfully unsubscribed from this list!</h2>
              <p>We&apos;re sorry to see you go.</p>
            </Wrapper>
            )
          }
          { unsubscribeError && (<h2>Something went wrong, please try again</h2>) }
        </LayoutCol>
      </LayoutContainer>
    </PageContainer>
  );
};

export default Unsubscribe;