import React, { useState, useEffect, ReactElement } from 'react';

interface Props {
  children: null | React.ReactNode | JSX.Element | JSX.Element[] | ReactElement;
  waitBeforeShow?: number;
  disable?: boolean;
};

/* @ts-ignore */
const Delayed: React.FC<Props> = ({ children, waitBeforeShow = 500, disable }) => {
  const [isShown, setIsShown] = useState(false);
  const time = disable ? 0 : waitBeforeShow;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, time);

    return () => clearTimeout(timer);
  }, [time]);

  return disable || isShown ? children : null;
};

Delayed.defaultProps = {
  waitBeforeShow: undefined,
  disable: false
};

export default Delayed;
