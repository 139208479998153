import React, { useContext } from 'react';
import { TwitterAuthService } from '../../../../services/AuthService/AuthService';
import Button from '../../../../shared/components/Button/Button';
import { ButtonWrapper } from '../../styled';
import AuthenticationContext from '../../../../shared/context/authentication/authenticationContext';

const Twitter = () => {
  const { login } = useContext(AuthenticationContext);

  const title = login ? 'Sign in with Twitter' : 'Sign up with Twitter';

  const handleClick = () => {
    TwitterAuthService.redirectToTwitterApi(login);
  };

  return (
    <ButtonWrapper widthButton="register" css={{width: '100%'}}>
      <Button
        title={title}
        twitterIcon
        iconWidth="24px"
        iconHeight="24px"
        size="small"
        handleClick={handleClick}
        css={{
          backgroundColor: '#35AEE2',
          height: '40px',
          maxWidth: '400px',
          // align twitter and orcid icons
          'span img': {
            marginLeft: '30px'
          },
        }}
      />
    </ButtonWrapper>
  );
};

export default Twitter;
