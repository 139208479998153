import React from 'react';

export const CameraIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M4.9375 3.90637V10.0939M8.1 3.90637H3.32188C2.7903 3.90637 2.35938 4.30212 2.35938 4.7903V9.20994C2.35938 9.69812 2.7903 10.0939 3.32188 10.0939H8.1C8.63157 10.0939 9.0625 9.69812 9.0625 9.20994V7.92825L11.6406 9.57825V4.422L9.0625 6.072V4.7903C9.0625 4.30212 8.63157 3.90637 8.1 3.90637Z"
      stroke="#A0A3BD"
      strokeLinejoin="round"
    />
  </svg>
);
