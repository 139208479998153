/* eslint-disable no-console */
import React, { useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetUserSetting from '../../../shared/hooks/getUserSetting';
import AppContext from '../../../shared/context/app/AppContext';
import Modal from '../../../shared/components/Modal/Modal';
import Card from '../../../shared/components/Card/Card';
import { levelsOrder, levelsSteps } from '../levelsOnboarding';
import { Container } from '../styles';

interface ModalOnboardingModel {
  userProps?: any;
}

const ModalOnboarding: React.FunctionComponent<ModalOnboardingModel> = ({ userProps }) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [loadingPrevBtn, setLoadingPrevBtn] = useState<boolean>(false);
  const [stepName, setStepName] = useState<string>(useGetUserSetting());
  const [currentStep, setCurrentStep] = useState<any>(levelsSteps[stepName]);

  const updateStep = useCallback(async () => {
    setLoadingBtn(true);
    if (currentStep.step === undefined) {
      console.error('Max level reached');
      return;
    }
    const nextStep = levelsOrder[levelsOrder.indexOf(stepName) + 1];
    dispatch({ type: 'SET_USER_SETTINGS', payload: nextStep });
    setCurrentStep(levelsSteps[nextStep]);
    setLoadingBtn(false);
    setStepName(nextStep);
  }, [currentStep.step, dispatch, stepName]);

  const actionPrev = useCallback(async () => {
    if (currentStep.step[0] === '1') {
      console.error('Min level reached');
      return;
    }
    setLoadingPrevBtn(true);
    const prevStep = levelsOrder[levelsOrder.indexOf(stepName) - 1];
    dispatch({ type: 'SET_USER_SETTINGS', payload: prevStep });
    setCurrentStep(levelsSteps[prevStep]);
    setStepName(prevStep);
    setLoadingPrevBtn(false);
  }, [currentStep.step, stepName, dispatch]);

  const propsDefault = {
    dataStep: currentStep,
    skip: updateStep,
    next: updateStep,
    back: actionPrev,
    userProps,
    loadingPrevBtn,
    loadingBtn,
  };

  if (!currentStep?.component) {
    navigate('/');
  }

  return (
    <Modal kindOverlay="gat-modal-overlay--blur" isOpen onClose={userProps?.actionClose}>
      <Card type="onboarding">
        <Container noPadding={currentStep?.noPadding}>
          {currentStep?.component && currentStep?.component(propsDefault)}
        </Container>
      </Card>
    </Modal>
  );
};

ModalOnboarding.defaultProps = {
  userProps: undefined,
};

export default ModalOnboarding;
