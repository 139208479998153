import React, { useContext, useEffect, useState } from "react";
import { TypographyP } from "../../Expert/styled";
import { ChangeDeviceWrapper } from "./styles";
import ChangeDeviceSelect from "./ChangeDeviceSelect";
import VideoRecordingContext from "../../../shared/context/videoRecording/videoRecordingContext";

const VideoInput = () => {
  const { setNewVideoTrack, videoInputDevices, videoTrack } = useContext(VideoRecordingContext);
  const [options, setOptions] = useState<any[]>(videoInputDevices);

  useEffect(() => {
    const devices = videoInputDevices.map((device) => ({
      value: device.deviceId,
      label: device.label || 'no name'
    }));
    setOptions(devices);
  }, [videoInputDevices]);

  const handleChange = (value: string) => {
    const track = videoInputDevices.find((device: any) => device.deviceId === value);
    if(track) setNewVideoTrack({ label: track.label, value: track.deviceId });
  };

  return(
    <ChangeDeviceWrapper>
      <TypographyP
        weight="600"
        lineHeight="lineHeight2"
        letterSpacing="letterSpacing2"
        color="hsla(241, 37%, 12%, 1)"
        css={{ marginBottom: '8px', textAlign: 'left', width: '100%' }}
      >
        Camera
      </TypographyP>
      <ChangeDeviceSelect options={options} onChange={handleChange} value={videoTrack} />
    </ChangeDeviceWrapper>
  );
};

export default VideoInput;