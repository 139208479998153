import { Link } from 'react-router-dom';
import { styled } from '../../../config/theme';


export const WrapperTypeResource = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'hsl(232, 33%, 95%, 1)',
  width: '2rem',
  height: '2rem',
  color: '$offBlack',
  cursor: 'pointer',
  borderRadius: '$radius05',
  transition: 'background 0.3s ease-in-out',
  '& img': {
    margin: 0
  },
  '&:hover': {
    backgroundColor: '$offBlack'
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$offBlack'
      },
    },
  }
});

export const Options = styled('div', {
  display: 'inline-flex',
  marginTop: '-0.5rem',
  '& img': {
    margin: 0,
  },
});

export const Box = styled('div', {
  display: 'flex',
  '&:not(:last-child)': {
    marginRight: '1rem',
  },
  '& span': {
    margin: '0 4px',
    color: '$offBlack',
    fontWeight: '$weight500',
    lineHeight: '$lineHeight1'
  },
  '& .text-ellipsis': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    fontSize:'$bodyXSmall',
    margin: 0, 
    color: '$lightColorSpan',
  }
});

export const TypeResource = styled('div', {
  display: 'flex',
  width: '22px',
  height: '22px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$input',
  borderRadius: '4px',
  '& img': {
    margin: 0,
  },
});

export const TextTitleKnowledge = styled('div', {
  margin: '0 0 0.25rem 0',
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  color: '$offBlack',
  maxWidth: '82vw',
  'p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@bp1': {
      whiteSpace: 'nowrap',
    },
    '@bp2': {
      maxWidth: '20vw',
    },
  },

  'img': {
    margin: 0
  },
  '.gat-icon': {
    paddingRight: '.75rem',
    display: 'inline-flex',
  },
  variants: {
    isSmall: {
      true: {
        '@bp2': {
          maxWidth: '20vw',
        },
        '@bp6': {
          maxWidth: '20vw',
        }
      }
    }
  }
});

export const StrongText = styled('p', {
  fontWeight: '$weight600',
});

export const WrapperCardPlaylist = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '$spaceXSmall',
  flexWrap: 'wrap',
  position: 'relative',
  '&:last-child': {
    '&:after': {
      display: 'none'
    }
  },

  '@bp2': {
    '&:after': {
      content: '',
      backgroundColor: '$line',
      height: '95%',
      position: 'absolute',
      right: '14px',
      top: '5%',
      width: 1,
      '@bp3': {
        display: 'none'
      }
    }
  },
  variants: {
    viewModal: {
      true: {
        '@bp2': {
          '&:after': {
            display: 'none'
          }
        }
      }
    }
  }
});

export const TitleLink = styled(Link, {
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline'
  },
});

export const WrapPlaylist = styled('div', {
  display: 'flex',
  flexDirection: 'column'
});