import { styled } from '../../config/theme';

export const Layout = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflowY: 'scroll',
  height: '100vh',

  '@bp2': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '$spaceXXLarge',
    position: 'absolute',
    maxHeight: '84vh',
  },
  '@bp3': {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: '20px',
    overflow: 'hidden',
    justifyContent: 'center',
    padding: '3rem 3.438rem',
    maxHeight: '100%',
  },
});

export const GridRecording = styled('div', {
  display: 'grid',
  gap: '$spaceLarge3',
  overflowY: 'auto',
  width: '100%',
  gridTemplateColumns: '1fr ',
  '@bp2': {
    padding: '$spaceXLarge',
    height: '100%',
    overflow: 'hidden',
  },

  '@tabletLandscape': {
    gridTemplateColumns: '1fr 0.7fr',
  },
  '@bp4': {
    gridTemplateColumns: '1fr 0.7fr',
    padding: 'calc($spaceXLarge * 1.2)',
  },
  variants: {
    fullscreen: {
      true: {
        gridTemplateColumns: '1fr',
        padding: 0,
        width: 'auto',
        height: '100%',
        justifyItems: 'center',
        alignContent: 'center',
      },
    },
    isLandscape: {
      true: {
        gridTemplateColumns: '1fr 1fr',
        height: '100vh',
        overflow: 'hidden',
        gap: 0,
        '@bp2': {
          overflow: 'hidden',
          padding: 0,
        },

        '@tabletLandscape': {
          padding: 0,
        },
        '@bp4': {
          padding: 0,
        },
      },
    },
  },
});

export const FlexRecoding = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: '1rem',
  '@landscape': {
    flexDirection: 'row',
  },
  '@bp2': {
    padding: '2rem',
  },
});

export const FlexOpinion = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  marginTop: '1rem',
});

export const ColumnOne = styled('div', {
  flex: 1,
  maxWidth: '100%',
});

export const ColumnTwo = styled('div', {
  flex: 0.5,
  height: '100%',
});

export const LinkText = styled('a', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textDecoration: 'none',
  cursor: 'pointer',
  fontFamily: 'Poppins',
  fontSize: 'bodyXSmall',
  fontWeight: '700',
  lineHeight: 'lineHeight1',
  letterApacing: '0.04em',
  textAlign: 'left',
  color: 'hsla(227, 78%, 60%, 1)',
  '&:hover': {
    textDecoration: 'underline',
  },
  '@bp5': {
    paddingTop: '0',
  },
});

export const QuestionContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: '20px',
});

export const VideoRecorderContainer = styled('div', {
  position: 'relative',
  height: 550,
  width: '100%',
  '@bp2': {
    width: '100%',
  },
  video: {
    backgroundColor: '$input',
    objectFit: 'cover',
  },
  variants: {
    isTablet: {
      true: {
        height: 400,
      },
      fullscreen: {
        transformOrigin: 'center ',
        transform: 'rotate(90deg)',
        width: '100vh !important',
        height: '100vw !important',
      },
    },
    isMobile: {
      true: {
        height: 350,
      },
      fullscreen: {
        transform: 'rotate(90deg)',
        width: '100vh !important',
        height: '100vw !important',
      },
    },
    fullscreen: {
      true: {
        width: '100vw',
        height: '100vh',
      },
    },
    isLandscape: {
      true: {
        height: '100vh',
      },
    },
  },
});

export const UpperBannerContainer = styled('div', {
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem 0',
  gap: '20px',
  background: 'rgba(73, 108, 233, 1)',
  top: '0px',
  left: '0',
  width: '100%',
  height: '10px',
  textAlign: 'center',
  color: '$monochromeWhite',
  zIndex: 100,
  p: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0.04em',
  },
});

export const CarouselContainer = styled('div', {
  paddingBottom: '2rem',
  maxWidth: '300px',
  '.carousel .slider-wrapper.axis-horizontal .slider': {
    alignItems: 'center',
  },

  '.carousel-root .carousel .control-dots': {
    bottom: '-0.98rem',
  },
  '.carousel.carousel-slider': {
    height: '100%',
  },
});

export const StartVideoButton = styled('button', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '7px 10px',
  borderRadius: '8px',
  color: '$monochromeWhite',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  textAlign: 'left',
  backgroundColor: '$redGoogle',
  position: 'absolute',
  bottom: '23px',
  left: '50%',
  transform: 'translateX(-50%)',
  minWidth: '150px',
  zIndex: '100',

  span: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: '$weight500',
    lineHeight: '$lineHeight2',
    letterSpacing: '0',
  },
  '&:hover': {
    backgroundColor: '$red',
  },
});

export const VideoActionsMobileContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  gap: '$spaceGap0_25',
  top: '45px',
  right: '1.25rem',
});

export const SoundIndicatorContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '10px',
  borderRadius: '100%',
  backgroundColor: 'hsla(0, 0%, 0%, 0.3)',
  position: 'absolute',
  bottom: '28px',
  right: '20px',
  zIndex: '100',
  variants: {
    isBlock: {
      true: {
        height: '35px',
        width: '35px',
        bottom: 'unset',
        right: 'unset',
        position: 'unset',
      },
    },
  },
});

export const RecordingActionsContainer = styled('div', {
  display: 'inline-flex',
  justifyContent: 'center',
  position: 'absolute',
  alignItems: 'center',
  gap: '12px',
  bottom: '23px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: '100',
});

export const PauseRecordingContainer = styled('button', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '7px 10px',
  borderRadius: '8px',
  color: '$monochromeWhite',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  fontFamily: 'Poppins',
  fontWeight: '$weight500',
  lineHeight: '1.875rem',
  letterSpacing: '0',
  textAlign: 'left',
  backgroundColor: 'hsla(0, 0%, 0%, 0.3)',

  span: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
});

export const StopRecordingButton = styled('button', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px',
  borderRadius: '8px',
  color: '$monochromeWhite',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  fontFamily: 'Poppins',
  fontSize: '$bodyLarge',
  fontWeight: '$weight500',
  lineHeight: '1.875rem',
  letterSpacing: '0',
  textAlign: 'left',
  backgroundColor: 'hsla(0, 29%, 84%, 1)',
  '& svg': {
    borderRadius: '$radius03',
    ':hover': {
      fill: '$redGoogle',
    },
  },
  variants: {
    isPaused: {
      true: {
        backgroundColor: 'hsla(0, 0%, 0%, 0.3)',
        '& svg': {
          fill: 'wite',
        },
        '&:hover svg': {
          fill: '$redGoogle',
        },
      },
      false: {
        '& svg': {
          fill: '$redGoogle',
        },
      },
    },
  },
});

export const SaveOrRecordContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  fontFamily: '$poppins',
  fontSize: '$bodyXSmall',
  fontWeight: '$weight600',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  textAlign: 'center',
  '@bp2': {
    flexDirection: 'row',
  },

  marginTop: '$spaceLarge',
  marginBottom: '$spaceLarge',
  '@bp4': {
    marginBottom: 0,
  },
  variants: {
    isModal: {
      true: {
        flexDirection: 'column',
        '@bp2': {
          flexDirection: 'row',
        },
      },
    },
    isLandscapeMobile: {
      true: {
        flexDirection: 'row',
      },
    },
  },
});

export const CloseIconContainer = styled('div', {
  cursor: 'pointer',
  padding: '1.5rem 1.5rem 0 0',
});

export const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'end',
});

export const ErrorContainer = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  backgroundColor: '$grayscaleLine',
  '@bp2': {
    borderRadius: '8px',
  },
  '@bp3': {
    paddingX: '$spaceXXXSmall',
  },
});

export const NoDeviceErrorContainer = styled('div', {
  borderRadius: '12px',
  backgroundColor: '$input',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.625rem',
  padding: '1rem',
  a: {
    color: '$blurple',
    fontFamily: '$poppins',
    fontWeight: '$weight700',
    fontSize: '$bodyTextXSmall',
    marginTop: '1rem',
  },
  '@bp2': {
    width: '80%',
  },
  '@bp5': {
    padding: '2rem',
  },
});

export const NoDeviceErrorText = styled('p', {
  fontFamily: 'Poppins',
  fontSize: 'clamp(0.90rem, 2.5vw, 1rem)',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.75px',
  textAlign: 'center',
  maxWidth: '20rem',
  '@bp3': {
    fontSize: '0.938rem !important',
  },
});

export const SpaceBottomButtons = styled('div', {
  height: '40px',
  width: '2px',
  marginBottom: '$spaceLarge',
  marginTop: '$spaceLarge',
});
