import React, { useContext, useEffect, useState } from 'react';
import { FlexContainer, Typography } from '../styled';
import Button from '../../../shared/components/Button/Button';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import { ExpertList } from './ExpertList';
import { usePayment } from '../../../shared/hooks/Organization/usePayment';
import { useProjects } from '../../../shared/hooks/useProjects';
import { ExpertRequestApproveModal } from './ExpertRequestModals/ExpertRequestApproveModal';
import { ExpertRequestInsufficientCreditsModal } from './ExpertRequestModals/ExpertRequestInsufficientCreditsModal';
import { LoadingExpert } from './ExpertList/LoadingExpert';
import { User } from '../../../shared/interfaces/interfaces';
import { useExpert } from '../../../shared/hooks/useExpert';

const ProjectExperts = () => {
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasCredits, setHasCredits] = useState<boolean>(true);
  const [loadingExpert, setLoadingExpert] = useState<boolean>(true);
  const [experts, setExperts] = useState<User[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [hasExperts, setHasExperts] = useState<boolean>(false);
  const { credits } = usePayment();
  const { inviteExpertsToProjects } = useProjects();
  const { projectQuestions, opinions, projectExperts, project,
          setProjectExperts } = useContext(ProjectsContext);
  const { listExperts } = useExpert();
  const requiredCredits = opinions ? Number(opinions) * 6 : 0;

  useEffect(() => {
    setHasExperts(projectExperts.length > 0);
  }, [projectExperts]);

  useEffect(() => {
    const getExperts = async () => {
      const expertListResponse = await listExperts(project?.id as string, 1, 10);
      setExperts(expertListResponse);
    };

    if(!projectExperts.length) getExperts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectExperts]);

  useEffect(() => {
    if (opinions && projectQuestions.length > 0) {
      if (parseInt(opinions, 10) > 0 && parseInt(opinions, 10) < 9) {
        setDisabled(false);
        return;
      }
    }
    setDisabled(true);
  }, [opinions, projectQuestions]);

  const handleSendRequest = () => {
    if (credits >= parseInt(opinions, 10) * 6) {
      setHasCredits(true);
    } else {
      setHasCredits(false);
    }
    setIsOpen(true);
  };

  const handleConfirmSendRequest = async () => {
    setIsOpen(false);
    setIsSendingRequest(true);
    const response = await inviteExpertsToProjects(experts);
    if (response) {
      setHasExperts(true);
      setProjectExperts(response);
    }
    setIsSendingRequest(false);
  };

  useEffect(() => {
    if (experts.length > 0 || projectExperts.length > 0) {
      setLoadingExpert(false);
    }
  }, [experts, projectExperts]);

  return (
    <>
      <FlexContainer direction="column" gap="2">
        <FlexContainer justify="between" direction="btnShowExperts" gap="0_75">
          <FlexContainer direction="column">
            <Typography
              size="displayXSmall"
              weight="700"
              lineHeight="lineHeight3"
              letterSpacing="letterSpacing3"
            >
              {hasExperts ? 'Your experts' : 'Your potential experts'}
            </Typography>
            <Typography
              size="bodyXSmall"
              weight="500"
              lineHeight="lineHeight1"
              letterSpacing="letterSpacing8"
              color="monochromeBlack300"
            >
              {hasExperts ? 'Crafting a tailored opinion for your project.' : 'These experts match your project information.' }
            </Typography>
          </FlexContainer>
          {!hasExperts && (
            <Button
              title="Send Requests"
              size="medium"
              width="max170"
              loading={isSendingRequest}
              renderProps={{
                disabled,
                onClick: handleSendRequest,
              }}
            />
          )}
        </FlexContainer>

        {hasExperts && <ExpertList experts={projectExperts} />}
        {!hasExperts && (loadingExpert ? <LoadingExpert /> : <ExpertList experts={experts} />)}
      </FlexContainer>
      <ExpertRequestApproveModal
        isOpen={isOpen && hasCredits}
        onConfirm={handleConfirmSendRequest}
        onCancel={() => setIsOpen(false)}
        requiredCredits={requiredCredits}
        credits={credits}
      />
      <ExpertRequestInsufficientCreditsModal
        isOpen={isOpen && !hasCredits}
        onCancel={() => setIsOpen(false)}
        requiredCredits={requiredCredits}
      />
    </>
  );
};

export default ProjectExperts;
