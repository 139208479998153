import React from 'react';
import _ from 'lodash';
import { BreathingComponents } from '../ShimmerComponent/styles';
import { keyExtractor } from '../../utils/helper';

export const LoadingTopics = () => (
  <>
    {_.range(1, 4).map(() => (
      <BreathingComponents
        key={keyExtractor()}
        className="tab"
        height={30}
        width={75}
        css={{ margin: '0.25rem 0.5rem 0 0' }}
      />
    ))}
  </>
);
