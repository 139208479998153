import React from 'react';
import { WidgetTitle, WidgetText } from '../styled';

const EmailSent = () => (
  <>
    <WidgetTitle>Check your email</WidgetTitle>
    <WidgetText>
      If your email address exists in our database, you will receive a password recovery
      link at your email address.
    </WidgetText>
  </>
);

export default EmailSent;