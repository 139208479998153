import { useContext } from 'react';
import AppContext from '../context/app/AppContext';

const useGetCurrentUserSlug = (): string => {
  const { state } = useContext(AppContext);
  const { userData } = state as any | undefined;

  if (userData?.slug) return userData?.slug;

  return '';
};

export default useGetCurrentUserSlug;
