import React from 'react';
import HeaderProject from '../default/HeaderProject';
import { Column, ContentContainer, FlexContainer, Typography } from '../styled';
import Opinion from './Opinions/Opinion';
import SubmitOpinion from './Opinions/SubmitOpinion';
import { useProjects } from '../../../shared/hooks/useProjects';
import ProjectDocuments from './ProjectDocuments';
import { useUser } from '../../../shared/hooks/useUser';

const ProjectInformation = () => {
  const { project } = useProjects();
  const { isExpert } = useUser();

  return (
    <ContentContainer>
      <Column columns="1">
        <HeaderProject isEditing={false} expert />
      </Column>
      <Column columns="2">
        {isExpert ? (
          <>
            <Typography
              size="displayXXSmall"
              weight="700"
              lineHeight="lineHeight3"
              letterSpacing="letterSpacing3"
            >
              Recording Tool
            </Typography>
            <Opinion />
            {project?.attributes.expert_status === 'pending' && (
              <Typography
                size="bodyXSmall"
                weight="500"
                lineHeight="lineHeight1"
                letterSpacing="letterSpacing6"
                color="monochromeBlack400"
              >
                * All recordings are saved until you submit the opinions to the organization
              </Typography>
            )}
            <SubmitOpinion />
          </>
        ) : (
          <FlexContainer direction="column" gap="2">
            <ProjectDocuments />
          </FlexContainer>
        )}
      </Column>
    </ContentContainer>
  );
};

export default ProjectInformation;
