import React from "react";
import { TextGray, Box, Text, TextSimple, NamesWrapper, ItemResultWrapper } from "./styled";
import Avatar from "../../../Avatar/Avatar";

interface ItemResultModel {
  value: string;
  avatar?: string;
  name: string;
  username: string;
  // eslint-disable-next-line no-unused-vars
  actionSelect?: (value: string) => Promise<any> | undefined;
  setData?: React.Dispatch<React.SetStateAction<Array<any>>>;
};
const ItemResult: React.FC<ItemResultModel> = ({
  value, avatar, name, actionSelect, setData, username,
}) => {
  const handleSelect = async () => {
    if(setData){setData([]);}
    if (actionSelect) {actionSelect(name);}
  };

  const showFirstText = (nameSplitted: string) => nameSplitted?.slice(0, value?.length) || '';
  const showLastText = (nameSplitted: string) => nameSplitted?.slice(value?.length) || '';

  return(
    <ItemResultWrapper>
      <Box onClick={() => handleSelect()} >
        <Avatar img={avatar} name={name} />
        <NamesWrapper>
          <Text>
            {showFirstText(name)}
            <TextSimple>
              {value && showLastText(name)}
            </TextSimple>
          </Text>
          <TextGray className='item-sub-text'>{username}</TextGray>
        </NamesWrapper>
      </Box>
    </ItemResultWrapper>
  );
};

ItemResult.defaultProps = {
  actionSelect: undefined,
  avatar: undefined,
  setData: undefined,
};

export default ItemResult;