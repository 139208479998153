import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../shared/components/Spinner/spinner';
import ButtonDefault from '../ModalOnboarding/ButtonDefault';
import checkIcon from '../../../assets/images/icons/check-black.svg';
import Icon from '../../../shared/components/Icon/Icon';
import { BoxContent, SubWrapper, Label } from '../styles';

interface DoneModel {
  loadingBtn?: boolean;
}
const LABEL_CREATE = 'Creating your profile';
const LABEL_DONE = "You're all set!";

const Done: React.FC<DoneModel> = () => {
  const [isDone, setIsDone] = useState<boolean>(false);
  const navigate = useNavigate();

  const actionDone = async () => {
    const path = sessionStorage.getItem('redirectAfterOnboarding');
    if (path) {
      navigate(path as string);
      sessionStorage.removeItem('redirectAfterOnboarding');
    } else navigate('/experts/projects');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isDone) {
        setIsDone(true);
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [isDone]);

  return (
    <BoxContent css={{ alignItems: 'center' }}>
      <Spinner className="spinner" type="lg" complete={isDone}>
        {isDone && <Icon img={checkIcon} width="26px" height="26px" alt="Loading" />}
      </Spinner>
      <Label sub css={{ marginTop: '$spaceMedium', fontSize: '$displayXSmall' }}>
        {isDone ? LABEL_DONE : LABEL_CREATE}
      </Label>
      {isDone && (
        <SubWrapper css={{ marginTop: '$spaceMedium' }}>
          <ButtonDefault
            title="Go to my dashboard"
            actionClick={() => actionDone()}
            loadingBtn={false}
          />
        </SubWrapper>
      )}
    </BoxContent>
  );
};

Done.defaultProps = {
  loadingBtn: undefined,
};

export default Done;
