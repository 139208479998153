import React, { createContext } from 'react';
import { TagModel } from '../../interfaces/interfaces';

export interface ContextProps {
  tags: TagModel[];
  setTags: React.Dispatch<React.SetStateAction<TagModel[]>>;
}

const TagContext = createContext<ContextProps>({} as ContextProps);

export default TagContext;
