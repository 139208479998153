import React, { useState, useEffect, useContext } from "react";
import { TimerContainer } from "./styled";
import VideoRecordingContext from "../../../../shared/context/videoRecording/videoRecordingContext";

interface Props {
  isRunning: boolean;
}

const Timer = (props: Props) => {
  const { isRunning } = props;
  const [time, setTime] = useState(0);
  const { setStatus, setError } = useContext(VideoRecordingContext);

  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      intervalId = setInterval(() => setTime(time + 1), 1000);
      if (time >= 2400) { // 2400 seconds = 40 minutes
        setStatus('timeLimit');
        setError(undefined);
      } else if (time >= 3600) { // 3600 seconds = 60 minutes
        setError('timeLimit');
      }
    }
    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, time]);


  // Minutes calculation
  const minutes = Math.floor(time / 60);

  // Seconds calculation
  const seconds = time % 60;


  return (
    <TimerContainer>
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </TimerContainer>
  );
};

export default Timer;