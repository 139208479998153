import React from 'react';
import PageContainer from '../../shared/components/Container/PageContainer';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
import MetaTags from '../../shared/components/MetaTags/MetaTags';
import { Title, SubTitle, Text } from './styled';

const PrivacyPolicy = () => (
  <PageContainer layout="statics">
    <MetaTags
      title="Gathersight: Privacy Policy"
      description="This is Gathersight's privacy policy. It's boring but important stuff."
      canonical="https://www.gathersight.com/privacy-policy/"
    />
    <LayoutContainer type="statics" css={{ flexWrap: 'wrap' }}>
      <LayoutCol col="12" css={{ flexDirection: 'column', alignItems: 'baseline' }}>
        <Title>Privacy Policy</Title>
        <Text>
          Gathersight recognizes your privacy is very important, and we take it seriously. This
          Privacy Policy outlines how we collect, use, and share your personally identifiable
          information through our website (www.gathersight.com) and via our services. Please read it
          carefully.
        </Text>
        <Text>
          {`Gathersight Ltd is the data controller for your personal information collected on the
          Gathersight platform. This Privacy Policy applies to activities by Gathersight Ltd and
          its affiliates and subsidiaries (collectively "Gathersight", "we" or "us"). Your use of
          Gathersight is at all times subject to the Terms of Use, which incorporates this Privacy
          Policy. Any terms we use in this Privacy Policy without defining them have the
          definitions given to them in the Terms of Use.`}
        </Text>
        <Text>
          For clarity, personal information or personal data means information relating to an
          identified or identifiable natural person.
        </Text>
        <Text>
          {`From time to time, Gathersight may revise this Privacy Policy. We will give you notice by
          posting the revised Privacy Policy on our website and updating the "Last Updated and
          Effective" date at the bottom of this Privacy Policy; we may also notify you in other
          ways, such as through the contact information you have provided or via a pop-up
          notification on our website. Any changes to this Privacy Policy will be effective
          immediately on posting the updated Privacy Policy unless otherwise stated or as required
          by applicable law (for example, a different form of notice and your opt-in or opt-out
          consent, etc.). By continuing to use our Site, you agree to the revised Privacy Policy
          to the fullest extent permitted by applicable law.`}
        </Text>

        <SubTitle>Information we Collect</SubTitle>
        <Text>
          {`We collect information from you directly, automatically when you visit the Gathersight
          website, and sometimes from third parties. Some of this information may be considered
          "personal information" under various applicable laws. We consider information that
          identifies you as a specific, identified individual to be personal information
          (such as your name, telephone number, and e-mail address), as well as additional
          information, including IP addresses and cookie identifiers, where required by
          applicable law. And, if we combine information that we consider to be non-personal
          with personal information, we will treat the combined information as personal
          information under this Privacy Policy. Note that we may de-identify personal
          information so that it is non-personal, such as by aggregating it or converting it to a
          code, sometimes using a function known as a "hash." We will treat de-identified
          information as non-personal to the fullest extent permitted by applicable law.`}
        </Text>

        <SubTitle>Account and profile information</SubTitle>
        <Text>
          When you create an account and profile on Gathersight, we collect your name, contact
          information, knowledge credentials, demographic information, and other information you
          provide, such as topics that you know about or find interesting. Your name, photo, and any
          other information that you choose to add to your public-facing profile will be available
          for viewing by the public and other Gathersight users. Once you create a profile, other
          users will be able to see in your profile certain information about your activity on
          Gathersight, such as your followers and who you follow, topics of interest to you, the
          information you list as credentials, and your ratings.
        </Text>

        <SubTitle>Your content</SubTitle>
        <Text>
          We collect information when you submit content to us, such as links to your content,
          reviews, ratings, photos, and comments. These are publicly viewable on Gathersight, along
          with your name. This also may be indexed by search engines and be republished elsewhere on
          the Internet in accordance with our Terms of Use.
        </Text>

        <SubTitle>Information from other sources</SubTitle>
        <Text>
          We also receive information from other sources. The categories of sources from which we
          collect and have collected information, including in the last 12 months, include:
        </Text>
        <Text>
          Social networks and public sources, including data in the public domain. Gathersight
          indexes content made publicly available by social networks (e.g. Twitter) and content
          available on the public Internet to return search results requested by our users.
        </Text>

        <SubTitle>Communications</SubTitle>
        <Text>
          When you communicate with us (via email, through the Gathersight website or otherwise), we
          may maintain a record of your communication. We collect the information that you provide
          in connection with us providing you customer service.
        </Text>

        <SubTitle>Social network data you share with us</SubTitle>
        <Text>
          We allow you to use social networks (such as Twitter, LinkedIn) or other people of single
          sign-on services (such as Google) with whom you already have an account to create a
          Gathersight account or to link your Gathersight account to such single sign-on services.
          You can determine the personal data that we can access when authorising the connection
          with the single sign-on service.
        </Text>
        <Text>
          We allow you to share personal data with social networks (such as Twitter) or to link your
          Gathersight account to a social network. These social networks may automatically provide
          us with access to certain personal data they have stored about you (e.g. content you have
          viewed or enjoyed, information about the advertisements you have been shown or clicked on,
          etc.). You can determine the personal data that we can access through the privacy settings
          of each social network.
        </Text>
        <Text>
          We may also use plug-ins or other technologies from various social networks. If you click
          on a link displayed through a social network plug-in, you voluntarily connect to that
          social network.
        </Text>

        <SubTitle>Cookies and Tracking Technologies</SubTitle>
        <Text>
          When you visit our website or otherwise interact with us or our content, we and
          third-party advertising and analytics companies and other third party business partners
          may use a variety of tracking and other technologies that automatically or passively
          collect certain information.
        </Text>

        <SubTitle>Cookies</SubTitle>
        <Text>
          A cookie is a data file placed on a device when it is used to visit the website or
          otherwise access our content. They allow us to remember users who are logged in, to
          understand how users navigate through and use Gathersight, and to display personalized
          content and targeted ads (including on third-party sites and applications).
        </Text>

        <SubTitle>Pixels and web beacons</SubTitle>
        <Text>
          {`Small graphic images or other web programming code called web beacons (also known as
          "pixels", "1x1 GIFs" or "clear GIFs") may be included in our web pages and email
          messages. Web beacons may not be visible to you. Web beacons or similar technologies
          may be used for a number of purposes, including to count visitors to the website, to
          monitor how users navigate the website, to count how many email messages we sent were
          opened, and to count whether a link in an email message we sent was clicked.`}
        </Text>

        <SubTitle>Analytics tools</SubTitle>
        <Text>
          We may use internal and third-party analytics tools, including Google Analytics. The
          third-party analytics companies we work with may combine the information collected with
          other information they have independently collected from other websites and/or other
          online products and services. Their collection and use of information is subject to their
          own privacy policies.
        </Text>

        <SubTitle>How we use Information</SubTitle>
        <Text>
          Gathersight uses the information we collect for our legitimate interests which include the
          following purposes:
        </Text>
        <Text>
          To provide you the Gathersight services and make the Gathersight website available to the
          public, communicate with you about your use of the website, respond to your inquiries,
          provide troubleshooting, and for other customer service purposes.
        </Text>
        <Text>
          To establish and fulfil a contract with you, for example when you pay for a service on
          Gathersight. This may include verifying your identity, taking payments, communicating with
          you, providing customer service;
        </Text>
        <Text>
          To provide you and others with customer service, to assist in resolving issues, and to
          evaluate and improve our customer service and processes;
        </Text>
        <Text>
          To evaluate and develop new features, technologies, and improvements to the Gathersight
          Service.
        </Text>
        <Text>
          To send you marketing and promotional messages, such as to send you news and newsletters,
          special offers, and promotions, or to otherwise contact you about products or information
          we think may interest you, including information about third-party products and services.
        </Text>
        <Text>
          To monitor any user accounts to prevent, investigate and/or report fraud,
          misrepresentation, terrorism, security incidents or crime in accordance with applicable
          law;
        </Text>
        <Text>
          To better understand your personal preferences and to enable us to provide you with
          improved and personalized communications and services;
        </Text>
        <Text>
          To take appropriate action with reports of intellectual property infringement and
          inappropriate content.
        </Text>

        <SubTitle>How we Share your Information</SubTitle>
        <Text>
          We share non-personal information, such as aggregated statistics and de-identified
          information, with third parties to the extent permitted by applicable law. We also share
          personal information as provided below in more detail. We do not share personal
          information with any third party to use for its own marketing or promotional purposes
          without your consent.
        </Text>

        <SubTitle>Third parties providing services on our behalf</SubTitle>
        <Text>
          We may share your information with third-party service people who use this information to
          perform services for us, such as hosting the website or website features, providing
          website usage analytics, fulfilling orders, processing payments, sending email or other
          communications, providing marketing assistance and data analysis or enhancement, running
          promotions, or performing other administrative services. We may share information about
          you with these third parties to allow them to execute, deliver, or improve the services
          that they provide for you and for Gathersight.
        </Text>

        <SubTitle>Affiliates</SubTitle>
        <Text>
          The information collected about you may be accessed by or shared with subsidiaries and
          affiliates of Gathersight Ltd, and whose use and disclosure of your personal information
          is subject to this Privacy Policy.
        </Text>

        <SubTitle>Business transfers</SubTitle>
        <Text>
          We may disclose or transfer information, including personal information, as part of any
          merger, sale, and transfer of our assets, acquisition or restructuring of all or part of
          our business, bankruptcy, or similar event.
        </Text>

        <SubTitle>Government authorities and/or law enforcement officials</SubTitle>
        <Text>
          If required for the purposes as described in this Privacy Policy, if mandated by law or if
          required for the legal protection of our legitimate interests in compliance with
          applicable laws we may share personal information with competent regulatory, prosecuting,
          tax or governmental authorities, courts or other tribunals in any jurisdiction or markets,
          domestic or foreign.
        </Text>

        <SubTitle>Your content and public information</SubTitle>
        <Text>
          Your content, including your name, profile picture, profile information, and certain
          associated activity information is available to other Gathersight users and may also be
          viewed publicly. Public viewing includes availability to non-registered visitors and can
          occur when users share your content across other sites or services. In addition, your
          content may be indexed by search engines.
        </Text>

        <SubTitle>Children and Parents</SubTitle>
        <Text>
          {`We do not knowingly collect or solicit personal information from anyone, under the age of
          13 or under the age of majority in their respective jurisdiction (or under the age of 14
          for anyone living in Spain or South Korea), or knowingly allow such persons to register.
          If you are a parent or legal guardian who believes that we have collected or used your
          child's personal information without your consent, please `}
          <a href="https://gathersight.notion.site/Love-to-hear-from-you-9cbb740b9509472398483d1edd31b2cb">
            contact us.
          </a>
        </Text>

        <SubTitle>Your Choices</SubTitle>
        <SubTitle>‍Cookie and marketing preferences</SubTitle>
        <Text>
          {`To stop receiving promotional email communications from Gathersight, click onClick
          the "unsubscribe" link in the footer of the promotional email. As noted above, we may
          send you email communications relating to your account or use of Gathersight services
          even if you have unsubscribed. To manage your cookie preferences, click here.`}
        </Text>

        <SubTitle>Your account and content</SubTitle>
        <Text>
          {`Through your account settings, you may access, and, in some cases, edit or delete the
          information you've provided to us, such as name and password, email address, user
          profile information, including images you may have uploaded to the site.`}
        </Text>
        <Text>
          You may edit or delete the links that you post at any time. We have no control over
          deletions or changes to your content if it has been shared manually by others.
        </Text>
        <Text>
          If you have any questions about viewing or updating information we have on file about you,
          please send a message via this{' '}
          <a href="https://gathersight.notion.site/Love-to-hear-from-you-9cbb740b9509472398483d1edd31b2cb">
            contact page.
          </a>
        </Text>

        <SubTitle>Notifications</SubTitle>
        <Text>
          {`When you join Gathersight by signing up for an account or creating a profile you can
          select, as part of the Service, to receive notifications when certain content becomes
          accessible on the website. You may also receive notices about other content that may be
          of interest to you based on accounts that you follow or content that match your
          interests. You can manage all your email and notice preferences in your account profile
          setting under the 'Notifications' settings. If you opt-out of receiving emails or other
          notifications about recommendations or other information we think may interest you, we
          may still send you transactional emails about your account or any services you have
          requested or received from us.`}
        </Text>

        <SubTitle>Credentials</SubTitle>
        <Text>
          You can edit and delete credentials that are displayed in your profile in the account
          settings section.
        </Text>
      </LayoutCol>
    </LayoutContainer>
  </PageContainer>
);

export default PrivacyPolicy;
