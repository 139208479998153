import { styled } from '../../../../config/theme';

export const ExpertCard = styled('div', {
  display: 'flex',
  background: '$monochromeWhite',
  alignItems: 'center',
  borderRadius: '$radius075',
  width: '$20',
  padding: '$spaceLarge $spaceXXSmall $spaceLarge  $spaceLarge2',
  zIndex: 5,
  '@bp3': {
    width: '$24',
  },
  variants: {
    bodyType: {
      intro: {
        boxShadow: '$shadowBase',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: '$spaceLarge',
        paddingX: '$spaceSmall',
        marginTop: '-$spaceMedium',
        width: '85%',
        '@bp2': {
          bottom: 0,
          position: 'absolute',
          flexDirection: 'row',
          width: '$24',
        },
        '@bp3': {
          left: '-3rem',
        },
        '@bp5': {
          bottom: '0',
          left: '-1rem',
        },
      },
    },
  },
});

export const ExpertCardContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '$spaceLarge',
  flexWrap: 'wrap',
  paddingX: '$spaceXLarge',
  marginY: '$spaceMedium',
  maxWidth: 1400,
});

export const ExpertCardImage = styled('img', {
  objectFit: 'cover',
  objectPosition: 'top center',
  width: '$4-25',
  height: '$4-25',
  borderRadius: '$radius4',
});

export const ExpertCardTextInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginLeft: '$spaceSmall',
  justifyContent: 'center',
  variants: {
    bodyType: {
      intro: {
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '$spaceNone',
        '@bp2': {
          marginLeft: '$spaceSmall',
          alignItems: 'start',
        },
      },
    },
  },
});

export const ExpertCardDescriptionContainer = styled('div', {
  maxWidth: '$47',
  paddingX: '$spaceLarge',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
});

export const Line = styled('div', {
  maxWidth: '$20',
  width: '100%',
  height: '$0-06',
  marginY: '$spaceMedium',
  background: '$monochromeBlack25',
  '@bp2': {
    maxWidth: '$36-5',
  },
});
