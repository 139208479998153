import React from 'react';
import { BreathingComponents, CardContentShimmer, TitleShimmer } from './styles';
import { Widget } from '../Widget/styles';

const WidgetLoad: React.FC<any> = ({ width }) => {
  const isDesktop = width > 980;

  return (
    <Widget className="gat-widget-shimer">
      <TitleShimmer>
        <BreathingComponents className="tab" height={20} />
      </TitleShimmer>
      {isDesktop && (
        <CardContentShimmer>
          <BreathingComponents className="tab" height={20} />
          <BreathingComponents className="tab" height={20} />
          <BreathingComponents className="tab" height={20} />
          <BreathingComponents className="tab" height={20} />
        </CardContentShimmer>
      )}
    </Widget>
  );
};

export default WidgetLoad;
