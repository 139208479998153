import React from 'react';
import { SimpleText } from './styled';
import { ExpertContainer } from '../../components/ExpertContainer/ExpertContainer';
import { TypographyH2 } from '../../styled';

export const OtherQuestion = () => (
  <ExpertContainer bg="monochromeBlack" section="work">
    <TypographyH2 type="displaySmall" color="secondaryP3" align="center" weight="700">
      Any other questions?
    </TypographyH2>
    <SimpleText>
      Please email <br />
      <a href="mailto:customerteam@gathersight.com">
        <span>customerteam@gathersight.com</span>
      </a>
    </SimpleText>
  </ExpertContainer>
);
