import React from 'react';
import Avatar from '../Avatar/Avatar';
import { Wrapper } from './styles';
import PeopleDataContent from './PeopleDataContent';

interface PeopleContentModel {
  name: string;
  text: string;
  img: string;
  slug?: string;
  typePeople?: string;
  authorsMentions?: any;
  actionClose?: () => void;
  actionFollow?: () => void;
  follow?: number | null;
  id?: string;
  twitterName?: string;
}

type PeopleContentProps = PeopleContentModel &
  React.ComponentProps<typeof Avatar> & {
    type?: string;
    kind?: string;
  };

const PeopleContent: React.FC<PeopleContentProps> = ({
  text, name, img, type, slug, kind, actionClose, typePeople, authorsMentions,
  follow, twitterName, id, actionFollow
}) => {
  const isResponsive = Boolean(kind?.includes('responsive'));
  const isAuthorMentions = typePeople === 'author-mention';
  const styleSubIcon = {
    ' .sub-icon': {
      position: 'absolute',
      bottom: '0',
      right: '-5px',
      'img': {
        width: '20px',
        height: '20px'
      }
    }
};
  const styleAvatarSubIcon = isAuthorMentions ? styleSubIcon : {};
  const styleAvatar = isResponsive ?
  { marginRight: '$spaceSmall', '@bp2': { marginRight: 0 }} : {};

  return (
    <Wrapper>
      <Avatar img={img}
              type={type}
              name={name}
              css={{ ...styleAvatar, ...styleAvatarSubIcon}}
              subIcon={isAuthorMentions} />
      <PeopleDataContent
          name={name}
          text={text}
          slug={slug}
          type={type}
          typePeople={typePeople}
          actionClose={actionClose}
          authorsMentions={authorsMentions}
          css={isResponsive ? { display: 'none', '@bp2': { display: 'flex' } } : {}}
          img={img}
          follow={follow}
          id={id}
          actionFollow={actionFollow}
          twitterName={twitterName}
        />
    </Wrapper>
  );
};

PeopleContent.defaultProps = {
  slug: undefined,
  type: undefined,
  kind: undefined,
  authorsMentions: undefined,
  actionClose: () => {},
  typePeople: '',
  actionFollow: undefined,
  follow: undefined,
  id: undefined,
  twitterName: undefined
};

export default PeopleContent;
