import React from 'react';
import { FlexContainer, Separator } from '../styled';
import Button from '../../../shared/components/Button/Button';

interface FooterButtonsProps {
  onSave: () => void;
}

export const FooterButtons = ({ onSave }: FooterButtonsProps) => (
  <FlexContainer gap="1_56" direction="column" align="end">
    <Separator />
    <Button title="Save" width="auto" size="mediumFixed" handleClick={onSave} />
  </FlexContainer>
);
