
const dataContent = [
  {
    value: 'article',
    label: 'Article',
  },
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'audio',
    label: 'Audio',
  },
  {
    value: 'podcast',
    label: 'Podcast',
  },
];

export default dataContent;