import { styled } from '../../../../config/theme';

export const Container = styled('div', {
    width: '100%',

    '@bp2': {
        justifyContent: 'flex-end', 
        display: 'flex',
    }
    
});