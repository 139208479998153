import { styled } from '../../../config/theme';

export const Wrapper = styled('div', {
  display: 'flex',
  border: '1px solid $offWhite',
  variants: {
    avatarActive: {
      true: {
        borderRadius: '100%',
        border: '1px solid $blurple',
      },
    },
  },
});

export const HeaderList = styled('li', {
  padding: '12px 12px 0px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '$input',
  },
});
export const HeaderListContent = styled('li', {
  padding: '12px 12px 10px',
  borderRadius: '$radius075 $radius075 0 0',
});

export const ListMobile = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  borderTop: '1px solid $input',
  marginX: '$spaceXSmall',

  '& .ul-list:hover': {
    backgroundColor: '$input',
  },
  '& .ul-list:first-child': {
    marginTop: '$spaceXSmall',
  },
  '& .ul-list:last-child': {
    marginBottom: 0,
    marginTop: '$spaceXSmall',
    borderTop: '1px solid $input',
    '@bp3': {
      borderBottomLeftRadius: '1rem',
      borderBottomRightRadius: '1rem',
      marginBottom: '$spaceXSmall',
    },
    li: {
      marginTop: '$spaceXSmall',
      padding: '10px 20px 20px 20px',
    },
  },
  '& button.btn-event': {
    marginY: '$spaceXSmall',
  },
});

export const BoxHover = styled('div', {
  position: 'relative',
  cursor: 'pointer',
  display: 'none',
  '@bp2': {
    display: 'block',
  },
});

export const ListWrapper = styled('div', {
  position: 'absolute',
  width: '100%',
  right: '0',
  height: 'calc(100vh - 50px)',
  background: '$overlay',
  '@bp2': {
    height: 'auto',
    width: '300px',
    background: 'transparent',
    top: 55,
  },
});

export const List = styled('ul', {
  position: 'absolute',
  listStyle: 'none',
  margin: 0,
  paddingLeft: 0,
  backgroundColor: 'white',
  color: '$ash',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  transition: 'all 0.3s ease-in-out',
  boxShadow: 'inset 0 2rem 2rem -2rem hsla(0, 0%, 0%, 0.07)',
  opacity: 1,
  zIndex: 1,
  '@bp2': {
    boxShadow: '$shadowBase',
    borderRadius: '1rem',
    marginTop: '.5rem',
  },

  variants: {
    show: {
      true: {
        zIndex: -1,
        opacity: 0,
        display: 'none',
      },
    },
  },
});

export const ItemList = styled('li', {
  display: 'block',
  cursor: 'pointer',
  float: 'left',
  fontSize: '$bodySmall',
  padding: '0.7rem 1.5rem',
  position: 'relative',
  textDecoration: 'none',
  letterSpacing: '$letterSpacing3',
  transitionDuration: '0.5s',
  color: '$ash',
  width: '100%',
  '& img': {
    margin: 0,
  },
  '&ul:last-child': {
    marginBottom: '$spaceXSmall',
  },
  variants: {
    kind: {
      active: {
        color: '$body',
      },
      resources: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        img: {
          marginRight: '12px',
        },
      },
      'resource-mobile': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@bp2': { display: 'none' },
      },
    },
    sublist: {
      true: {
        paddingLeft: '3rem',
      },
    },
  },
});

export const Box = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '& img': {
    marginRight: '12px !important',
  },
});

export const Text = styled('p', {
  fontWeight: '$weight500',
  '@bp3': {
    fontWeight: '$normal',
  },
});

export const WrapperHamMenu = styled('div', {
  display: 'block',
  '& button': {
    paddingX: 0,
  },
  '@bp2': {
    display: 'none',
  },
});

export const ContentMenu = styled('div', {
  position: 'absolute',
  display: 'block',
  zIndex: -1,
  opacity: 0,
  '@bp2': {
    display: 'none',
  },
  variants: {
    open: {
      true: {
        width: '100%',
        top: '56px',
        left: '0',
        opacity: 1,
        zIndex: '2',
      },
    },
  },
});

export const FeedContainer = styled('li', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginX: '$spaceXSmall',
  paddingY: '$spaceLarge',
  borderBottom: '1px solid $input',
  '& a': {
    textDecoration: 'none',
  },
  '@bp2': {
    display: 'none',
  },
});

export const FeedButton = styled('button', {
  borderRadius: '.25rem',
  cursor: 'pointer',
  fontFamily: '$Poppins',
  fontWeight: '$weight600',
  fontSize: '$bodyXSmall',
  padding: '$spaceXSmall $spaceSmall $spaceXSmall 0',
  marginX: '$spaceXSmall',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  backgroundColor: '$bg',
  width: '149px',
  height: '40px',
  textDecoration: 'none',

  variants: {
    color: {
      active: {
        backgroundColor: '$bodyPale',
        color: '$body',
        opacity: 1,
      },
      noActive: {
        color: '$offBlack',
        '&:hover': {
          opacity: 0.7,
        },
      },
    },
    kind: {
      feed: {
        '&::after': {
          content: 'Feed',
          '@bp0': {
            content: 'For You',
          },
        },
      },
    },
  },
});

export const BoxSubList = styled('div', {
  width: '100%',
});
