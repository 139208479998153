import { styled } from '../../config/theme';

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  'p': {
    paddingTop: '$spaceXSmall',
  }
});