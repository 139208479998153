import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  FooterContainer,
  TextContainer,
  Text,
  LinkFooter
} from './styled';
import Icon from '../Icon/Icon';
import LinksFooter from './LinksFooter';
import Logo from '../../../assets/images/icons/logo-white.svg';
import { AboutPageTextFooter, TextFooter } from '../../utils/globalText';

const TEXT_FOOTER = TextFooter;

interface FooterLinkModel { }

const Footer: React.FunctionComponent<FooterLinkModel> = () => {
  const { pathname } = useLocation();

  const isAbout = pathname === '/about';
  const footerText = isAbout ? AboutPageTextFooter : TEXT_FOOTER;

  return (
    <FooterContainer>
      <div className='TitleContainer'>
        <LinkFooter to='/'>
          <Icon img={Logo} width="24" height="24" alt="Footer Link" />
        </LinkFooter>
        <Text css={{ '@bp2': { display: 'none' } }}>
          {TEXT_FOOTER}
        </Text>
      </div>

      <TextContainer>
        <Text css={{ display: 'none', '@bp2': { display: 'block' } }}>
          {footerText}
        </Text>
      </TextContainer>

      <LinksFooter />
    </FooterContainer>
  );
};

export default Footer;
