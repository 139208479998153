import React, { useState } from 'react';
import PasswordInput from '../../../screens/Authentication/components/Email/PasswordInput/PasswordInput';
import { ErrorTitleBox } from './styled';
import ErrorBox from '../Forms/InputError/ErrorBox';

type PasswordInputProps = {
  name?: string;
};

const PasswordWithValidations = (props: PasswordInputProps) => {
  const { name } = props;
  const [lengthError, setLengthError] = useState<boolean>(true);
  const [capitalError, setCapitalError] = useState<boolean>(true);
  const [numberError, setNumberError] = useState<boolean>(true);

  const checkValidation = (errors?: string) => {
    setLengthError(errors?.includes('Minimun') || false);
    setCapitalError(errors?.includes('Uppercase') || false);
    setNumberError(errors?.includes('Number') || false);
  };

  const validatePassword = (value: string) => {
    let errors = '';
    if(!value || value?.length < 6) { errors += 'Minimun';}
    if(!(value?.match(/^(?=.*[A-Z])/))) { errors += 'Uppercase';}
    if(!(value?.match(/(?=.*[0-9])/))) { errors += 'Number';}

    checkValidation(errors);
    return(lengthError || capitalError || numberError);
  };

  return (
    <>
      <PasswordInput validation={validatePassword} name={name} placeholder='Password' autocomplete='new-password'/>
      <ErrorTitleBox>
        <ErrorBox title="6 characters"
                  valid={!lengthError} />
        <ErrorBox title="1 capital letter"
                  valid={!capitalError} />
        <ErrorBox title="1 number"
                  valid={!numberError} />
      </ErrorTitleBox>
    </>
  );
};

PasswordWithValidations.defaultProps = {
  name: 'newPassword',
};

export default PasswordWithValidations;