import axiosInstance from '../utils/axiosInstance';

interface dataInterface {
  id?: string;
  authenticationToken: string;
  title: string;
  company?: string;
}

export const UserExperiencesService = {
  create: async (data: dataInterface) => {
    const { authenticationToken } = data;
    try {
      const results = await axiosInstance(authenticationToken).post('/user_experiences', {
        user_experience: data,
      });
      return results?.data.data || [];
    } catch (error) {
      return 'error';
    }
  },

  update: async (data: dataInterface) => {
    const { authenticationToken, id } = data;
    try {
      const results = await axiosInstance(authenticationToken).put(`/user_experiences/${id}`, {
        user_experience: data,
      });
      return results?.data.data || [];
    } catch (error) {
      return 'error';
    }
  },
};
