import React, { useState } from 'react';
import {
  ArrowImg,
  ExpandableContainer,
  ExpandableContent,
  ExpandableHeader,
  ExpandableTitle,
} from './styled';
import ArrowIcon from '../../../../assets/images/experts/Arrow.svg';

interface ExpandableProps {
  title: string;
  children: React.ReactNode;
}

export const Expandable = ({ title, children }: ExpandableProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <ExpandableContainer>
      <ExpandableHeader onClick={toggleExpand}>
        <ExpandableTitle>{title}</ExpandableTitle>
        <ArrowImg src={ArrowIcon} alt="" css={{ transform: isExpanded ? '' : 'rotate(180deg)' }} />
      </ExpandableHeader>
      <ExpandableContent style={{ display: isExpanded ? 'block' : 'none' }}>
        {children}
      </ExpandableContent>
    </ExpandableContainer>
  );
};
