import { styled } from '../../../config/theme';

export const OrderByWrapper = styled('div', {
  width: '100%',
  alignItems: 'center',
  flexShrink: '2',
  "@bp2": {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '$spaceXXSmall',
  },
  "@bp3": {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '$spaceXXXSmall',
  },
});

export const FilterWrapper = styled('div', {
  display: 'inline-flex',
});

export const OrderByFilter = styled('div', {
  border: '1px solid #D9DBE9',
  borderRadius: '8px',
  minHeight: '2rem',
  margin: '0px 0.5rem',
  marginLeft: '$spaceLarge'
});

export const EmptyDiv = styled('div', {
  width: '$sizes$12',
});
