import React from 'react';
import Playlist from '../Playlist/Playlist';
import WhoToFollow from '../../../shared/components/WhoToFollow/WhoToFollow';
// import WidgetNewProvider from '../../../shared/components/Widget/WidgetNewProvider';
import { Widget, DivWidgets } from '../../../shared/components/Widget/styles';
import { TagsKnowledge } from '../../../shared/components/Tags/TagsKnowledge';
import { TagModel } from '../../../shared/interfaces/interfaces';

interface WidgetsBaseModel {
  userDataTag?: TagModel[];
  sameUser?: Boolean;
  userId?: number | string;
}

type WidgetsBaseProps = WidgetsBaseModel &
  React.ComponentProps<typeof DivWidgets> & {
    css?: any;
    kind?: string;
    showPlaylist?: boolean;
    typePeople?: boolean;
    hideSuggestions?: boolean;
  };

const WidgetsBase: React.FC<WidgetsBaseProps> = ({
  css,
  kind,
  showPlaylist,
  typePeople,
  hideSuggestions,
  userDataTag,
  sameUser,
  userId,
}) => {
  const isKb = hideSuggestions ? 'mobile-kb' : kind;
  const typeScreen = kind === 'mobile' ? 'feed-home' : 'feed-desktop';

  return (
    <DivWidgets className="widgets-wrap" kind={isKb} css={css} showPlaylist={showPlaylist}>
      {!hideSuggestions && (
        <>
          {sameUser || (userDataTag && userDataTag.length > 0) ? (
            <TagsKnowledge
              data={userDataTag as TagModel[]}
              sameUser={sameUser as Boolean}
              userId={userId as string}
            />
          ) : (
            <Widget className="who-to-follow">
              <WhoToFollow />
            </Widget>
          )}
          {/* <WidgetNewProvider /> */}
        </>
      )}
      {showPlaylist && <Playlist kind={typePeople ? 'feed-kb-margin' : typeScreen} />}
    </DivWidgets>
  );
};

WidgetsBase.defaultProps = {
  css: undefined,
  kind: 'desktop',
  showPlaylist: false,
  typePeople: undefined,
  hideSuggestions: undefined,
  userDataTag: [],
  sameUser: false,
  userId: undefined,
};

export default WidgetsBase;
