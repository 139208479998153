import React, { useState } from 'react';
import PageContainer from '../../../shared/components/Container/PageContainer';
import {
  ExpertStepperContainer,
  ExpertStepperImageContainer,
  TextOverlay,
  Image,
  BigText,
  SmallText,
} from '../styled';
import useWindowSize from '../../../shared/hooks/useWindowSize';

import mobileImage from '../../../assets/images/experts/mobile.jpg';
import tabletImage from '../../../assets/images/experts/test.jpg';
import deskImage from '../../../assets/images/experts/desk.jpg';
import { Stepper } from './Stepper/Stepper';
import { Step1 } from './Stepper/Step1';
// import { Step2 } from './Stepper/Step2';
import { Step3 } from './Stepper/Step3';
import useIsLoggedIn from '../../../shared/hooks/useIsLoggedIn';
import { useProjects } from '../../../shared/hooks/useProjects';

export const ExpertSeeker = () => {
  const { width } = useWindowSize();
  const { addProject } = useProjects();
  const [currentStep, setCurrentStep] = useState(0);
  const userLogged = useIsLoggedIn();

  const nextStep = async () => {
    if (userLogged) {
      await addProject();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const getImage = () => {
    if (width >= 1440) {
      return deskImage;
    }
    if (width >= 720) {
      return tabletImage;
    }

    return mobileImage;
  };
  return (
    <PageContainer layout="expert">
      <ExpertStepperContainer>
        {userLogged ? (
          <Stepper currentStep={currentStep}>
            <Step1 nextStep={nextStep} />
          </Stepper>
        ) : (
          <Stepper currentStep={currentStep}>
            <Step1 nextStep={nextStep} />
            <Step3 />
          </Stepper>
        )}
      </ExpertStepperContainer>
      <ExpertStepperImageContainer>
        <Image src={getImage()} alt="" />
        <TextOverlay>
          <BigText type="new-project">
            <BigText type="new-project" css={{ fontWeight: '$weight700' }}>
              Get unique,
            </BigText>{' '}
            bespoke insight <br /> and opinion
          </BigText>
          <SmallText landing="steps">
            On-demand video recordings from biotech experts answering your specific research questions.
          </SmallText>
        </TextOverlay>
      </ExpertStepperImageContainer>
    </PageContainer>
  );
};
