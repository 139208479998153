import React from "react";
import { CountdownContainer, CountdownValue } from "./styles";

const Countdown = () => {
  const [time, setTime] = React.useState(3);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <CountdownContainer>
      <CountdownValue>
        {time}
      </CountdownValue>
    </CountdownContainer>
  );
};

export default Countdown;