import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button/Button';
import { Container } from './styled';

interface MiddleSectionProps {
  iconCustom: string ; 
  title: string;
  navigateTo: string;
}

export const MiddleSection: React.FC<MiddleSectionProps> = ({ iconCustom, title, navigateTo }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Button
        iconCustom={iconCustom}
        title={title}
        kind="add-dashboards"
        handleClick={() => navigate(navigateTo)}
      />
    </Container>
  );
};
