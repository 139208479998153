import React, { FocusEventHandler, Ref } from 'react';
import googleLogo from '../../../assets/images/icons/google.svg';
import twitterLogo from '../../../assets/images/icons/x-icon.svg';
import orcidLogo from '../../../assets/images/icons/orcid.svg';
import saveLogo from '../../../assets/images/icons/bookmark.svg';
import savedLogo from '../../../assets/images/icons/bookmark-saved.svg';
import shareLogo from '../../../assets/images/icons/share.svg';
import Icon from '../Icon/Icon';
import { Spinner } from '../Spinner/spinner';
import { CustomButton } from './styled';

interface ButtonModel {
  title: string;
  kind?: string;
  twitterIcon?: boolean;
  googleIcon?: boolean;
  orcidIcon?: boolean;
  iconSave?: boolean;
  iconSaved?: boolean;
  iconShare?: boolean;
  iconWidth?: string;
  iconHeight?: string;
  iconCustom?: string;
  iconMargin0?: boolean;
  iconPosition?: string;
  renderProps?: any;
  handleClick?: any;
  css?: any;
  loading?: boolean;
  loadingIconRotate?: boolean;
  ariaLabel?: string;
  width?: string;
  marginL?: boolean;
  marginR?: boolean;
  isHidden?: boolean;
  margin?: string | number;
}

type ButtonProps = ButtonModel &
  React.ComponentProps<typeof CustomButton> & {
    kind?: string;
    size?: string;
    css?: any;
    type?: string;
    noTransition?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
  };

const Button = React.forwardRef(
  (
    {
      title,
      kind,
      twitterIcon = false,
      googleIcon = false,
      orcidIcon = false,
      iconSave,
      iconSaved,
      iconShare,
      iconCustom,
      iconWidth,
      iconHeight,
      iconMargin0,
      iconPosition,
      handleClick,
      renderProps,
      css,
      loading,
      size,
      type,
      width,
      ariaLabel,
      children,
      marginL,
      marginR,
      loadingIconRotate,
      noTransition,
      onBlur,
      isHidden,
      margin,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <CustomButton
      {...props}
      ref={ref}
      onClick={renderProps ? renderProps.onClick : handleClick}
      disabled={renderProps.disabled}
      color="body"
      kind={kind}
      size={size}
      css={css}
      type={type}
      aria-label={ariaLabel}
      onBlur={onBlur}
      width={width}
      noTransition={noTransition}
      isHidden={isHidden}
      margin={margin}
    >
      <span>
        {iconPosition === 'left' ? (
          <>
            {twitterIcon && !loading && (
              <Icon img={twitterLogo} width={iconWidth} height={iconHeight} alt="Twitter" />
            )}
            {googleIcon && !loading && (
              <Icon img={googleLogo} width={iconWidth} height={iconHeight} alt="Google" />
            )}
            {orcidIcon && !loading && (
              <Icon img={orcidLogo} width={iconWidth} height={iconHeight} alt="orcid" />
            )}
            {iconSave && !loading && (
              <Icon img={saveLogo} width={iconWidth} height={iconHeight} alt="Save" />
            )}
            {iconSaved && !loading && (
              <Icon img={savedLogo} width={iconWidth} height={iconHeight} alt="Saved" />
            )}
            {iconShare && !loading && (
              <Icon img={shareLogo} width={iconWidth} height={iconHeight} alt="Share" />
            )}
            {iconCustom && !loading && (
              <Icon
                img={iconCustom}
                width={iconWidth}
                height={iconHeight}
                alt={ariaLabel}
                margin0={iconMargin0}
                marginR={marginR}
                loadingIconRotate={loadingIconRotate}
              />
            )}
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}

        {loading && <Spinner className="spinner" type="xxs" />}
        {title}
        {children}
        {iconPosition === 'right' ? (
          <>
            {twitterIcon && !loading && (
              <Icon img={twitterLogo} width={iconWidth} height={iconHeight} alt="Twitter" />
            )}
            {googleIcon && !loading && (
              <Icon img={googleLogo} width={iconWidth} height={iconHeight} alt="Google" />
            )}
            {orcidIcon && !loading && (
              <Icon img={orcidLogo} width={iconWidth} height={iconHeight} alt="Orcid" />
            )}
            {iconSave && !loading && (
              <Icon img={saveLogo} width={iconWidth} height={iconHeight} alt="Save" />
            )}
            {iconSaved && !loading && (
              <Icon img={savedLogo} width={iconWidth} height={iconHeight} alt="Saved" />
            )}
            {iconShare && !loading && (
              <Icon img={shareLogo} width={iconWidth} height={iconHeight} alt="Share" />
            )}
            {iconCustom && !loading && (
              <Icon
                img={iconCustom}
                width={iconWidth}
                height={iconHeight}
                alt={ariaLabel}
                margin0={iconMargin0}
                marginL={marginL}
              />
            )}
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}
      </span>
    </CustomButton>
  ),
);
Button.defaultProps = {
  renderProps: false,
  handleClick: null,
  twitterIcon: false,
  googleIcon: false,
  orcidIcon: false,
  iconSave: false,
  iconSaved: false,
  iconShare: false,
  iconCustom: undefined,
  iconWidth: undefined,
  iconHeight: undefined,
  css: undefined,
  kind: undefined,
  size: undefined,
  loading: false,
  ariaLabel: undefined,
  iconPosition: 'left',
  type: 'button',
  marginL: false,
  marginR: false,
  loadingIconRotate: false,
  iconMargin0: false,
  onBlur: undefined,
  width: undefined,
  noTransition: false,
  isHidden: false,
  margin: undefined,
};

export default Button;
