import useGetUserType from '../shared/hooks/getUserType';

const isUserAuthorized = (roles: string[], userType: string): boolean => {
  if (roles.length === 0) return true;
  return roles.includes(userType);
};

const isUserExcluded = (excludes: string[], userType: string): boolean => {
  if (excludes.length === 0) return false;
  return excludes.includes(userType);
};

export const RouteGuard = (roles: string[], excludes: string[]) => {
  const userType = useGetUserType();
  if (isUserAuthorized(roles, userType) && !isUserExcluded(excludes, userType)) {
    return true;
  }
  return false;
};
