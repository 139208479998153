import React, { SVGProps } from 'react';

export const FullScreenIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.31934 10.5749V7.6582C3.31934 4.7532 5.66416 2.4082 8.56894 2.4082H11.4854"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4863 2.4082H21.4028C24.3076 2.4082 26.6524 4.7532 26.6524 7.6582V10.5749"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6518 18.7402V20.4902C26.6518 23.3952 24.307 25.7402 21.4022 25.7402H19.6523"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4854 25.7409H8.56894C5.66416 25.7409 3.31934 23.3959 3.31934 20.4909V17.5742"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
