import React, { useContext } from 'react';
import { TagModel } from '../../../interfaces/interfaces';
import { TagItemLink, TagItemsContainer } from './styled';
import { capitalizeFirstLetter, keyExtractor } from '../../../utils/helper';
import { LinkSmall } from '../../Typography/styled';
import AppContext from '../../../context/app/AppContext';
import { useTag } from '../../../hooks/useTag';

interface TagItemsModel {
  tags: TagModel[];
  handleClosePopover: () => void;
}

const TagItems = ({ tags, handleClosePopover }: TagItemsModel) => {
  const { state } = useContext(AppContext);
  const { saveExpertise, deleteExpertise } = useTag();
  const { expertise, userId } = state.userData;

  const isSelected = (item: TagModel) => {
    const selected = expertise || [];
    return (
      selected.filter((selectedItem) => item.id === selectedItem.attributes.tag.data.id).length > 0
    );
  };
  const onClickTag = async (item: TagModel) => {
    const selected = expertise || [];
    const ItemSelected = selected.filter(
      (selectedItem) => item.id === selectedItem.attributes.tag.data.id,
    );

    if (userId) {
      if (ItemSelected.length > 0) {
        await deleteExpertise(ItemSelected[0], parseInt(userId, 10));
      } else if (selected.length < 3) {
        await saveExpertise(item, parseInt(userId, 10));
      }
    }

    handleClosePopover();
  };

  return (
    <TagItemsContainer>
      {tags.map((item) => (
        <TagItemLink
          key={keyExtractor()}
          onClick={() => onClickTag(item)}
          selected={isSelected(item)}
        >
          <LinkSmall>{capitalizeFirstLetter(item.attributes.name)}</LinkSmall>
        </TagItemLink>
      ))}
    </TagItemsContainer>
  );
};

export default TagItems;
