import React, { useContext } from "react";
import { VideoCaptionContainer } from "./styles";
import VideoRecordingContext from "../../../../shared/context/videoRecording/videoRecordingContext";
import Countdown from "./Countdown";
import TextCaption from "./TextCaption";

const VideoCaption = () => {
  const { status } = useContext(VideoRecordingContext);
  const countdown = status === 'countdown';
  const text = status === 'timeLimit' ? 'Your time is up' : undefined;

  if (!countdown && !text) return null;

  return (
    <VideoCaptionContainer>
      { countdown && <Countdown /> }
      { text && <TextCaption text={text} /> }
    </VideoCaptionContainer>
  );
};

export default VideoCaption;