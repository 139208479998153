const authorsList = [
  {
    "id": null,
    "type": "author",
    "attributes": {
      "name": "User onboarding 1",
      "aliases": [],
      "twitter_handle": "jeffers4431",
      "created_at": "2022-01-15T17:12:36.586Z",
      "avatar": "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
      "user_id": null,
      "relationship_id": null
    }
  },
  {
    "id": null,
    "type": "author",
    "attributes": {
      "name": "User onboarding 2",
      "aliases": [],
      "twitter_handle": "test",
      "created_at": "2022-01-14T17:16:48.677Z",
      "avatar": "https://fakeimg.pl/250x100/",
      "user_id": null,
      "relationship_id": null
    }
  },
  {
    "id": null,
    "type": "author",
    "attributes": {
      "name": "User onboarding 3",
      "aliases": [
        "alias 1",
        "alias 2"
      ],
      "twitter_handle": "lillia",
      "created_at": "2022-01-14T17:16:48.495Z",
      "avatar": "https://robohash.org/doloremaliquamsunt.png?size=300x300&set=set1",
      "user_id": null,
      "relationship_id": null
    }
  }
];

export default authorsList;