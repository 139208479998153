import { styled, fadeIn } from '../../../../config/theme';

export const StepperContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  paddingTop: '$spaceSmall',
  paddingBottom: '$spaceSmall',
});

export const StepContainer = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '$spaceXXLarge',
  animation: `${fadeIn} 540ms`,
});

export const StepperTitle = styled('div', {
  fontFamily: '$poppins',
  fontSize: '$displayXSmall',
  fontWeight: '$weight700',
});

export const StepperIndicatorContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  alignSelf: 'stretch',
  marginTop: '16px',
  animation: `${fadeIn} 540ms`,
});

export const StepperIndicator = styled('div', {
  height: '4px',
  flex: '1 0 0',
  borderRadius: '27px',
  backgroundColor: '$blurpleLight',
  variants: {
    onStep: {
      true: {
        backgroundColor: '$blurple',
      },
    },
  },
});

export const SubTitles = styled('div', {
  fontFamily: '$poppins',
  fontSize: '$displayXXSmall',
  fontWeight: '$weight700',
});

export const InfoLabel = styled('span', {
  fontFamily: '$poppins',
  fontSize: '$displayXXSmall',
});

export const Step3NameLastName = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@bp2': {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const WrapperTags = styled('div', {
  marginY: '$spaceLarge'
});

export const InfoLabelLink = styled('a', {
  color: '$offBlack',
  fontFamily: '$poppins',
  fontSize: '$bodyXSmall',
  fontWeight: '$weight600',
  textDecorationLine: 'underline',
});