import React, { useContext, useState } from 'react';
import Pencil from '../../../assets/images/icons/pencil.svg';
import { EditableContainer, ReadMoreButton } from './styled';
import { FlexContainer, Typography } from '../styled';
import Button from '../../../shared/components/Button/Button';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import useAutoSave from '../../../shared/hooks/useAutoSave';
import { EditProject, Project } from '../../../shared/interfaces/projectInterfaces';
import { useProjects } from '../../../shared/hooks/useProjects';
import { ResizableTextArea } from './ResizableTextArea';
import { Error } from '../../../shared/components/Forms/InputError/styled';
import { ReadMoreIcon } from './icons/ReadMoreIcon';

interface HeaderProjectProps {
  isEditing: boolean;
  handleEdit?: () => void;
  setIsShowSaved?: () => void;
  isDescriptionChanged?: boolean;
  expert?: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsDescriptionChanged?: (isChanged: boolean) => void;
}

export const HeaderProject = ({
  isEditing,
  handleEdit,
  setIsShowSaved,
  isDescriptionChanged,
  setIsDescriptionChanged,
  expert,
}: HeaderProjectProps) => {
  const { setName, setDescription, name, description, setLengthError, lengthError } =
    useContext(ProjectsContext);
  const { updateProject, getProjectStatus, project } = useProjects();
  const status = getProjectStatus(project as Project);
  const css = expert ? { gridColumn: '1/2', gridRow: '1' } : {};
  const [expanded, setExpanded] = useState(false);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleSaveChanges = async () => {
    const projectChanges: EditProject = {
      name,
      description,
    };
    const response = await updateProject(projectChanges, false);
    if (response && setIsShowSaved) setIsShowSaved();
  };

  const { setHasChanges } = useAutoSave(isEditing, handleSaveChanges, 2000);

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
    setLengthError(event.target.value.length < 300);
    if (event.target.value.length > 300) setHasChanges(true);
    if (!isDescriptionChanged && setIsDescriptionChanged) setIsDescriptionChanged(true);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setName(event.target.value);
    setHasChanges(true);
  };

  return isEditing ? (
    <>
      <EditableContainer>
        <ResizableTextArea isTitle value={name} onChange={handleTitleChange} />

        <ResizableTextArea value={description} onChange={handleDescriptionChange} />
      </EditableContainer>
      {lengthError && (
        <Error css={{ marginTop: '-2rem' }}>Description must be at least 300 characters</Error>
      )}
    </>
  ) : (
    <FlexContainer direction="column" gap="1_25" css={css}>
      <FlexContainer align="start" gap="0_75" direction="onlyMobileColumnReverse">
        <Typography
          size="displayXSmall"
          weight="700"
          lineHeight="lineHeight3"
          letterSpacing="letterSpacing3"
          space="marginLSpaceSmall"
          css={expert ? { maxWidth: 'unset', padding: '0 1.125rem 0 0' } : {}}
        >
          {name}
        </Typography>
        {handleEdit && status === 'draft' && (
          <Button kind="edit" title="" width="auto" handleClick={handleEdit} iconCustom={Pencil} />
        )}
      </FlexContainer>
      <Typography
        size="bodyTextXSmall"
        lineHeight="lineHeight11"
        letterSpacing="letterSpacing8"
        css={
          expert
            ? {
                maxWidth: 'unset',
                padding: '0 1.125rem 0 0',
              }
            : {}
        }
      >
        {!expanded && description.length >= 1000 ? `${description.slice(0, 900)}` : description}
      </Typography>
      {description.length >= 1000 && (
        <ReadMoreButton onClick={handleExpanded} isExpanded={expanded}>
          <ReadMoreIcon />
          {expanded ? 'Read Less' : 'Read More'}
        </ReadMoreButton>
      )}
    </FlexContainer>
  );
};

HeaderProject.defaultProps = {
  handleEdit: undefined,
  setIsShowSaved: undefined,
  isDescriptionChanged: false,
  setIsDescriptionChanged: undefined,
  expert: false,
};

export default HeaderProject;
