import React, { useContext, useCallback, useMemo } from 'react';
import { HomeService } from '../../../services/home.service';
import LoadDataSection from '../LoadDataSection/LoadDataSection';
import AuthorsListLoad from '../ShimmerComponent/AuthorsListLoad';
import AuthorsList from '../Authors/AuthorsList';
import AppContext from '../../context/app/AppContext';
import useWindowSize from '../../hooks/useWindowSize';
import { BoxShimmer } from '../ShimmerComponent/styles';
import { Spinner } from '../Spinner/spinner';

const WhoToFollow = () => {
  const { width } = useWindowSize();
  const { state } = useContext(AppContext);
  const { authenticationToken } = state;
  const isReplace = !!(width && (width));

  const actionProviders = useCallback(async (page: number, total: number) => {
    const data = { authenticationToken, page, limit: total, topAuthors: true };
    const result = await HomeService.getAuthors(data);
    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken]);

  const actionProvidersModal = useCallback(async (page: number, total: number) => {
    const data = { authenticationToken, page, limit: total, withContents: '' };
    const randomSeed = sessionStorage.getItem('paginationSeed') || Math.random().toString();
    sessionStorage.setItem('paginationSeed', randomSeed);
    data.withContents = randomSeed;
    const result = await HomeService.getAuthors(data);
    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken]);

  return useMemo(() => (
    <LoadDataSection
      type="outline"
      replace={isReplace}
      onAction={actionProviders}
      onActionModal={actionProvidersModal}
      component={AuthorsList}
      shimmerComponent={<AuthorsListLoad total={3} />}
      shimmerInfinity={(
        <BoxShimmer type='center' css={{ height: 60 }}>
          <Spinner className='spinner' type='xs' css={{padding: '$spaceSmall' }} />
        </BoxShimmer>
      )}
      textProp="See more"
      size={3}
      withModal
      title='Suggested for you'
      description='Love digging for knowledge? Try following some of these opinion makers to expand your feed.'
    />
  ), [actionProviders, actionProvidersModal, isReplace]
  );
};

export default React.memo(WhoToFollow);