import { useEffect, useState } from 'react';
import useWindowSize from './useWindowSize';
import { isLandscapeMobile } from '../utils/helper';

export const useLandscape = () => {
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia('(orientation: landscape)').matches,
  );
  const { width, height } = useWindowSize();

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.matchMedia('(orientation: landscape)').matches);
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);
  return { isLandscape, isLandscapeMobile: isLandscapeMobile(width, height) };
};
