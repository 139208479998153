import { styled } from '../../../config/theme';

export const InformationContainer = styled('div', {
  display: 'flex',
  gap: '$spaceLarge',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',

  bp0: {
    flexDirection: 'column',
  },
});

export const Information = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$spaceXSmall2',
  alignItems: 'flex-start',
});

export const OrganizationDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$spaceSmall',
  alignItems: 'flex-start',
});

export const Details = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$spaceXXSmall',
  alignItems: 'Center',
});

export const DocumentContainer = styled('a', {
  height: '6.563rem',
  width: '9.375rem',
  padding: '$spaceSmall $spaceXSmall2 $spaceSmall $spaceXSmall2',
  borderRadius: '$radius05',
  gap: '0.625rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '$monochromeBlack25',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '$grayscaleInput',
  },
});

export const ProjectViewContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: '$spaceLarge',
  '@bp0': {
    gap: '$spaceXXLarge',
  },
});

export const ProjectInformationContainer = styled('div', {
  display: 'grid',
  gap: '$spaceXLarge 0.813rem',
  width: '100%',

  '@bp4': {
    gap: '$spaceGap1_56',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto',
  },
  '@bp6': {
    gridTemplateColumns: 'auto 1fr',
  },
});

export const ProjectInformationGrid = styled('div', {
  display: 'grid',
  gap: '$spaceXLarge 0.813rem',
  width: '100%',

  '@bp4': {
    gap: '$spaceGap1_56',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto',
  },
  '@bp6': {
    gridTemplateColumns: 'auto 1fr',
  },
});

export const ProjectTagsContainer = styled('div', {
  gridColumn: '1/1',
  '@bp3': {
    gridColumn: '1/2',
    gridRow: '2',
  },
});
