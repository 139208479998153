import React, { useState } from 'react';

const defaultColor = '#6E7191';

interface XIconProps {
  color?: string;
}

const XIcon: React.FC<XIconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M4 5.00002L12.5161 13.5161M4 13.5161L12.5161 5"
      stroke={color}
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
XIcon.defaultProps = {
  color: defaultColor,
};

const XIconFeed: React.FC<XIconProps> = ({ color }) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="#496CE9" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1.50002L13.2097 13.2097M1.5 13.2097L13.2097 1.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

XIconFeed.defaultProps = {
  color: defaultColor,
};

interface XIconWrapperProps {
  isFeed?: boolean;
}

export const XIconWrapper = ({ isFeed }: XIconWrapperProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ cursor: 'pointer' }}
    >
      {isFeed ? (
        <XIconFeed color={isHovered ? 'rgb(68, 102, 228)' : defaultColor} />
      ) : (
        <XIcon color={isHovered ? 'rgb(215, 240, 101)' : defaultColor} />
      )}
    </div>
  );
};

XIconWrapper.defaultProps = {
  isFeed: false,
};
