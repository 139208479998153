import { useEffect, useState } from 'react';

export const useRecordingVideos = () => {
  const [mimeType, setMimeType] = useState<string>();

  useEffect(() => {
    const getSupportedMimeTypes = () => {
      const VIDEO_TYPES = ['mp4', 'webm', 'ogg', 'x-matroska'];
      const AUDIO_CODECS = [
        'aac', // AAC-LC audio codec
        'mp4a.40.2', // AAC audio codec
        'mp4a.40.5', // HE-AACv1 (AAC+)
        'mp4a.40.29', // HE-AACv2 (enhanced AAC+)
        'opus', // Opus audio codec
        'vorbis', // Vorbis audio codec
        'pcm', // PCM audio codec
        'mp3', // MP3 audio codec
      ];
      const VIDEO_CODECS = [
        'vp9',
        'vp8',
        'vp9',
        'vp9.0',
        'vp8',
        'vp8.0',
        'h264',
        'h.264',
        'avc1',
        'av1',
        'h265',
        'h.265',
      ];

      const supportedTypes: string[] = [];
      VIDEO_TYPES.forEach((videoType) => {
        const type = `video/${videoType}`;
        VIDEO_CODECS.forEach((videoCodec) => {
          AUDIO_CODECS.forEach((audioCodec) => {
            const variation = `${type};codecs="${videoCodec}, ${audioCodec}"`;
            if (MediaRecorder.isTypeSupported(variation)) {
              supportedTypes.push(variation);
            }
          });
        });
      });
      if (supportedTypes.length === 0) {
        throw new Error('No compatible supported video mime type');
      }
      return supportedTypes;
    };

    setMimeType(getSupportedMimeTypes()[0]);
  }, []);

  return {
    mimeType,
  };
};
