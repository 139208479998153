/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Resources, User, TagModel } from '../../interfaces/interfaces';
import {
  getUserName,
  formatTooltipText,
  capitalizeFirstLetter,
  validateAlternativeKey as validateImage,
  formatDate,
} from '../../utils/helper';
import {
  WrapperCard,
  CardHeader,
  CardContent,
  CardFooter,
  WrapperSave,
  Content,
  ContentLeft,
} from './styles';
import TextLimiterLink from '../TextLimiterLink/TextLimiterLink';
import PeopleTooltip from '../People/PeopleTooltip';
import OptionsPopover from '../Options/OptionsPopover';
// import Tags from '../Tags/Tags'; // Hide V1
import SaveButton from '../SaveButton/SaveButton';
import TypeResource from '../TypeResource/TypeResource';
import Tooltip from '../Tooltip/Tooltip';
import LinkPreview from '../LinkPreview/LinkPreview';
import AddToPlaylistButton from './AddToPlaylistButton/AddToPlaylistButton';
import ImageResource from './ImageResource';
import AppContext from '../../context/app/AppContext';
import Tags from '../Tags/Tags';

interface ResourcesProps extends Resources {
  hideFollow?: boolean;
  hideOptions?: boolean;
  isSlider?: boolean;
  isLazy?: boolean;
  className?: string;
  playlistEmpty?: boolean;
  modalOpened?: boolean;
  updatePlaylist?: boolean;
  profile?: boolean;
}

const CardResources: React.FC<ResourcesProps> = ({
  id,
  attributes,
  type,
  hideFollow,
  isSlider,
  hideOptions,
  className,
  playlistEmpty,
  modalOpened,
  isLazy,
  updatePlaylist,
  // eslint-disable-next-line no-unused-vars
  profile,
}) => {
  const [tooltipText, setTooltipText] = useState('');
  const [dataTags, setDataTags] = useState<TagModel[]>([]);
  const [saveResource, setResource] = useState<string | undefined>(undefined);
  const [load, setLoad] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const { images, userData } = state;
  // const RenTag = useMemo(() => Tags, []);
  // const existTag = attributes?.tags?.data?.length > 0;
  const allAuthorsData = useMemo(
    () => attributes?.authors?.data || [],
    [attributes?.authors?.data],
  );
  const urlResource = attributes?.url;
  const authorsMentions = allAuthorsData.filter(
    (i: User) => i?.attributes?.relation_type === 'MENTIONED',
  );
  const authorsData = allAuthorsData.filter(
    (i: User) => i?.attributes?.relation_type === 'AUTHOR',
  );
  const authorUnrelated = allAuthorsData.filter(
    (i: User) => i?.attributes?.relation_type === 'UNRELATED',
  );
  const totalAuthors = authorsData?.length || 0;
  const totalMentions = authorsMentions?.length || 0;
  const totalUnrelated = authorUnrelated?.length || 0;
  const principalAuthor = totalAuthors
    ? authorsData[0]
    : totalMentions
    ? authorsMentions[0]
    : totalUnrelated
    ? authorUnrelated[0]
    : null;
  const name = getUserName({ name: principalAuthor?.attributes?.name });
  const defaultImage = totalAuthors
    ? principalAuthor?.attributes?.avatar_url
    : totalMentions
    ? 'mentions'
    : '';
  const profileId = principalAuthor?.attributes?.user_id;
  const profileSlug = principalAuthor?.attributes?.user_slug;
  const relation = principalAuthor?.attributes?.relation_type;
  const contentType = attributes?.content_type;
  const kbLength = attributes?.knowledge_books?.length;
  const dataKb = kbLength ? attributes?.knowledge_books[kbLength - 1] : {};
  const allKb = attributes?.knowledge_books || [];
  const typeResource = 'normal';

  const saveImage = (img: string | undefined) => {
    dispatch({ type: 'SET_LOCAL_IMAGE', payload: { [id]: img } });
    setResource(img);
  };

  const getTypePeople = () => {
    if (totalAuthors >= 1 && totalMentions === 0) {
      return 'author';
    }
    if (totalMentions === 1 && totalAuthors === 0) {
      return 'mention';
    }
    if (totalMentions > 1 && totalAuthors === 0) {
      return 'mentions';
    }

    if (totalAuthors >= 1 && totalMentions === 1) {
      return 'author-mention';
    }

    if (totalUnrelated >= 0 && (totalMentions === 0 || totalAuthors === 0)) {
      return 'author-unrelated';
    }

    return 'authors-mentions';
  };

  useMemo(() => {
    if (!load && images && id && validateImage(id, images)) {
      setResource(images[id] || '');
      setLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    (async () => {
      const formatText = await formatTooltipText(
        allAuthorsData || [],
        contentType || '',
        name,
        relation,
      );
      const textCapitalize = capitalizeFirstLetter(formatText);
      setTooltipText(textCapitalize);
    })();
  }, [allAuthorsData, contentType, name, relation]);

  useEffect(
    () => () => {
      setTooltipText('');
      setResource(undefined);
    },
    [],
  );

  useEffect(() => {
    (async () => {
      if (attributes && attributes?.contenttaglinks.data.length) {
        const newTags = await attributes?.contenttaglinks?.data.map((item: any) => ({
          ...item?.attributes?.tag?.data,
          contenttaglinks_id: item?.id,
        }));

        await setDataTags(newTags);
      }
    })();
  }, [attributes]);

  return (
    <WrapperCard
      key={id}
      isLazy={isLazy}
      isSlider={isSlider}
      className={`resources-container ${className || ''}`}
    >
      <CardHeader className="resources-header">
        <div>
          <PeopleTooltip
            name={name}
            type="content"
            text={formatDate(attributes?.date || '')}
            img={defaultImage}
            follow={principalAuthor?.attributes?.relationship_id}
            hideFollow={hideFollow}
            id={profileId}
            twitterName={principalAuthor?.attributes?.twitter_handle}
            slug={profileSlug}
            isContent
            author={principalAuthor}
            typePeople={getTypePeople()}
            authorsMentions={authorsMentions}
          />
        </div>
        <div className="icon-read">
          <Tooltip content={tooltipText} type="type-resource">
            <TypeResource type={contentType} active={false} url={urlResource} />
          </Tooltip>
          {!hideOptions && <OptionsPopover dataResource={{ id, attributes }} />}
        </div>
      </CardHeader>
      <CardContent className="resources-content">
        <Content showPlaylist={false}>
          <ContentLeft full={!saveResource} type={typeResource}>
            <LinkPreview
              text={attributes?.title}
              link={attributes?.url}
              actionResource={(e) => (saveResource ? {} : saveImage(e))}
            />
            <TextLimiterLink
              text={attributes?.text}
              link={attributes?.url}
              title={attributes?.title}
            />
          </ContentLeft>
          {saveResource && <ImageResource url={saveResource} type={typeResource} load={load} />}
        </Content>
      </CardContent>
      <CardFooter>
        {/* <RowTags empty={!existTag}>
          <RenTag
            data={attributes?.tags?.data}
            cssTag={{ background: '$bodyPale' }}
            actionTag={() => { }}
          />
        </RowTags> */}
        {/* <WrapperSave> full={!existTag} // Hide V1 */}
        <WrapperSave full>
          {dataTags ? (
            <Tags
              data={dataTags}
              // eslint-disable-next-line no-console
              actionTag={() => console.log('test')}
              limit={2}
              sameUser={profileId === Number(userData?.userId)}
              contentId={id}
            >
              {playlistEmpty ? (
                <AddToPlaylistButton resourceID={id} title={dataKb?.title || ''} />
              ) : (
                <SaveButton
                  type={type}
                  idResource={id}
                  idKb={dataKb?.id || ''}
                  text={dataKb?.title || ''}
                  allKb={allKb}
                  modalOpened={modalOpened}
                  updatePlaylist={updatePlaylist}
                />
              )}
            </Tags>
          ) : playlistEmpty ? (
            <AddToPlaylistButton resourceID={id} title={dataKb?.title || ''} />
          ) : (
            <SaveButton
              type={type}
              idResource={id}
              idKb={dataKb?.id || ''}
              text={dataKb?.title || ''}
              allKb={allKb}
              modalOpened={modalOpened}
              updatePlaylist={updatePlaylist}
            />
          )}
        </WrapperSave>
      </CardFooter>
    </WrapperCard>
  );
};

CardResources.defaultProps = {
  hideOptions: true,
  hideFollow: false,
  isSlider: false,
  isLazy: false,
  className: '',
  playlistEmpty: false,
  updatePlaylist: false,
  modalOpened: false,
  profile: false,
};

export default CardResources;
