import React from 'react';
import Avatar from '../Avatar/Avatar';
import TooltipCard from '../TooltipCard';
import People from './People';
import PeopleName from './PeopleName';
import PeopleDescription from './PeopleDescription';
import { Wrapper } from './styles';

interface PeopleDataModel {
  name: string;
  text: string;
  img: string;
  type?: string;
  slug?: string;
  status?: string;
  actionClose?: () => void;
  actionFollow?: () => void;
  follow?: number | null;
  id?: string;
  twitterName?: string;
  tooltip?: boolean;
}

type PeopleDataProps = PeopleDataModel &
  React.ComponentProps<typeof Avatar> & {
    type?: string;
    kind?: string;
  };

const PeopleData: React.FunctionComponent<PeopleDataProps> = ({
  text, name, img, type, slug, kind, actionClose, status, follow, id,
  tooltip, twitterName, actionFollow
}) => {
  const isResponsive = Boolean(kind?.includes('responsive'));
  const styleAvatar = isResponsive ?
    { paddingRight: '$spaceXSmall', '@bp2': { paddingRight: 0 } } : {};


  // eslint-disable-next-line react/no-unstable-nested-components
  const PeopleDataComponent = () => (
    <Wrapper>
      <Avatar img={img} type={type} name={name} css={{ ...styleAvatar }} />
      <PeopleName
        css={isResponsive ? { display: 'none', '@bp2': { display: 'flex' } } : {}}
        actionClose={actionClose}
        slug={slug}
        text={text}
        name={name}
        status={status}
      />
    </Wrapper>
  );

  return tooltip ? (
    <TooltipCard content={(
      <>
        <People
          img={img}
          name={name}
          text=''
          follow={follow}
          id={id}
          type='content'
          twitterName={twitterName}
          actionFollow={actionFollow}
          slug={slug}
        />
        <PeopleDescription userId={id} />
      </>
    )}>
      <PeopleDataComponent />
    </TooltipCard>
  )
    :
    <PeopleDataComponent />;
};

PeopleData.defaultProps = {
  slug: undefined,
  type: undefined,
  kind: undefined,
  actionClose: () => { },
  actionFollow: () => { },
  status: '',
  tooltip: false,
  follow: undefined,
  id: undefined,
  twitterName: undefined
};

export default PeopleData;
