import React, { useEffect, useState } from 'react';
import { ProfileService } from '../../../services/ProfileService/ProfileService';
import { WrapDescription } from './styles';

interface PeopleDescriptionModel {
  userId?: string;
}

const PeopleDescription: React.FC<PeopleDescriptionModel> = ({ userId }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    (async () => {
      if (userId && !text) {
        const params = { id: userId };
        const user = await ProfileService.getUser(params);

        await setText(user?.attributes?.biography || '');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return text ? (
    <WrapDescription>
      <p>{text}</p>
    </WrapDescription>
  ) : (
    <WrapDescription>
      <p>Find my long-form content.</p>
    </WrapDescription>
  );
};

PeopleDescription.defaultProps = {
  userId: undefined,
};

export default PeopleDescription;
