import { styled } from '../../../config/theme';

const patternNames = ['rhombus', 'zigzag', 'zigzag3D', 'rectangles', 'diagonal', 'diagonalV2', 'diagonalV3', 'polka', 'line', 'cross'];

const patternName = patternNames[Math.floor(Math.random() * patternNames.length)];

export const Pattern = styled('div', {
  minWidth: '30px',
  minHeight: '30px',
  backgroundPosition: '10px 0, 10px 0, 0 0, 0 0',
  backgroundSize: '90px 90px',
  backgroundRepeat: 'repeat',

  variants: {
    name: {
      rhombus: {
        backgroundImage: 'linear-gradient(135deg, $$gradientColor 25%, transparent 25%), linear-gradient(225deg, $$gradientColor 25%, transparent 25%), linear-gradient(45deg, $$gradientColor 25%, transparent 25%), linear-gradient(315deg, $$gradientColor 25%, $$bgColor 25%)',
      },
      zigzag: {
        background: 'linear-gradient(135deg, $$gradientColor 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(225deg, $$gradientColor 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(315deg, $$gradientColor 25%, transparent 25%) 0px 0/ 20px 20px, linear-gradient(45deg, $$gradientColor 25%, $$bgColor 25%) 0px 0/ 20px 20px',
        backgroundPosition: '45px 0, 45px 0, 0 0, 0 0',
        backgroundSize: '90px 90px',
      },
      zigzag3D: {
        background: 'linear-gradient(135deg, $$colorOpacity 25%, transparent 25%) -40px 0/ 80px 80px, linear-gradient(225deg, $$gradientColor 25%, transparent 25%) -40px 0/ 80px 80px, linear-gradient(315deg, $$colorOpacity 25%, transparent 25%) 0px 0/ 80px 80px, linear-gradient(45deg, $$gradientColor 25%, $$bgColor 25%) 0px 0/ 80px 80px',
        backgroundPosition: '45px 0, 45px 0, 0 0, 0 0',
        backgroundSize: '90px 90px',
      },
      rectangles: {
        backgroundImage:  'repeating-linear-gradient(45deg, $$gradientColor 25%, transparent 25%, transparent 75%, $$gradientColor 75%, $$gradientColor), repeating-linear-gradient(45deg, $$gradientColor 25%, $$bgColor 25%, $$bgColor 75%, $$gradientColor 75%, $$gradientColor)',
        backgroundPosition: '0 0, 45px 45px',
        backgroundSize: '90px 90px'
      },
      diagonal: {
        background: 'repeating-linear-gradient(45deg, $$gradientColor, $$gradientColor 40px, $$bgColor 40px, $$bgColor 80px)'
      },
      diagonalV2: {
        backgroundImage: 'repeating-linear-gradient(-45deg, $$gradientColor 0, $$gradientColor 40px, $$bgColor 0, $$bgColor 50%)'
      },
      diagonalV3: {
        backgroundImage: 'repeating-linear-gradient(45deg, $$gradientColor 0, $$gradientColor 40px, $$bgColor 0, $$bgColor 50%)'
      },
      polka: {
        backgroundImage: 'radial-gradient($$gradientColor 20px, $$bgColor 20px)',
        backgroundSize: '80px 80px',
      },
      line: {
        backgroundImage: 'linear-gradient(0deg, $$bgColor 50%, $$gradientColor 50%)',
        backgroundSize: '80px 80px',
      },
      cross: {
        background: 'radial-gradient(circle, transparent 20%, $$bgColor 20%, $$bgColor 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, $$bgColor 20%, $$bgColor 80%, transparent 80%, transparent) 100px 100px, linear-gradient($$gradientColor 8px, transparent 8px) 0 -4px, linear-gradient(90deg, $$gradientColor 8px, $$bgColor 8px) -4px 0',
        backgroundSize: '200px 200px, 200px 200px, 100px 100px, 100px 100px'
      }
    },
    color: {
      body: {
        $$gradientColor: '$colors$body',
        $$colorOpacity: 'hsl(227, 78%, 60%, .33)',
      },
      green: {
        $$gradientColor: '$colors$green',
        $$colorOpacity: 'hsl(148,60%,60%, .33)',
      },
      red: {
        $$gradientColor: '$colors$red',
        $$colorOpacity: 'hsl(7,100%,45%, .33)',
      },
      blue: {
        $$gradientColor: '$colors$blue',
        $$colorOpacity: 'hsl(202,89%,53%, .33)',
      },
      purple: {
        $$gradientColor: '$colors$purple',
        $$colorOpacity: 'hsl(263, 91%, 49%, .33)',
      }
    },
    background: {
      transparent: {
        $$bgColor: 'transparent',
      },
      body: {
        $$bgColor: '$colors$body',
      },
      bg: {
        $$bgColor: '$colors$bg',
      },
      blue: {
        $$bgColor: '$colors$blue',
      },
      offBlack: {
        $$bgColor: '$colors$offBlack',
      },
      placeHold: {
        $$bgColor: '$colors$placeHold',
      },
      default: {
        $$bgColor: 'hsl(234deg 96% 89%)',
      }
    },
    isCircle: {
      true: {
        borderRadius: '50%'
      }
    }
  },
  defaultVariants: {
    background: 'default',
    name: patternName,
    color: 'body'
  }
});
