import React, { useContext } from 'react';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';
import FullScreen from './FullScreen';
import SoundIndicator from './SoundIndicator';
import StartRecording from './StartRecording';
import StopRecording from './StopRecording';
import { VideoActionsMobile } from './VideoActionsMobile';

interface VideoActionsProps {
  handleStartCaptureClick: () => void;
  handleStopCaptureClick: () => void;
  handlePauseCaptureClick: () => void;
  handleResumeCaptureClick: () => void;
  isLandscape?: boolean;
}

const VideoActions = (props: VideoActionsProps) => {
  const { status, width } = useContext(VideoRecordingContext);

  const {
    handleStartCaptureClick,
    handleStopCaptureClick,
    handlePauseCaptureClick,
    handleResumeCaptureClick,
    isLandscape,
  } = props;

  return (
    <>
      {status === 'capturing' || status === 'paused' ? (
        <StopRecording
          handleStopCaptureClick={handleStopCaptureClick}
          handlePauseCaptureClick={handlePauseCaptureClick}
          handleResumeCaptureClick={handleResumeCaptureClick}
        />
      ) : (
        <StartRecording handleStartCaptureClick={handleStartCaptureClick} />
      )}
      {width && width > 720 && !isLandscape ? <SoundIndicator /> : <VideoActionsMobile />}

      {status === 'init' && width && width > 720 && !isLandscape && <FullScreen />}
    </>
  );
};

VideoActions.defaultProps = {
  isLandscape: false,
};

export default VideoActions;
