import { styled } from '../../config/theme';
import { LayoutCol } from '../../shared/components/Layouts/styles';

export const TextTotal = styled('div', {
  fontWeight: '$normal',
  fontSize: '$bodyXSmall',
  lineHeight: '$lineHeight2',
  color: '$offBlack',
  letterSpacing: '$letterSpacing2',
  '@bp3': {
    fontSize: '$bodySmall',
  }
});

export const LayoutColFollowers = styled(LayoutCol, {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '$spaceLarge'
});