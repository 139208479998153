import { styled } from '../../../../config/theme';

export const Error = styled('div', {
  color: '$error',
  fontWeight: '$weight500',
  fontSize: '$bodyXXSmall',
});

export const Info = styled('div', {
  color: '$burplePass',
  fontWeight: '$weight500',
  fontSize: '$bodyXXSmall',
});

export const StatusDot = styled('div', {
  width: '8px',
  height: '8px',
  backgroundColor: '$green',
  borderRadius: '50%',
  marginRight: '8px',
});

export const ErrorTitle = styled('p', {
  color: '$grey',
  fontWeight: '$weight500',
  fontSize: '12px !important',
});

export const SuccessTitle = styled(ErrorTitle, {
  color: '$ash',
});

export const ErrorBoxWrapper = styled('div', {
  alignItems: 'center',
  marginBottom: '$spaceSmall',
  display: 'flex',
});
