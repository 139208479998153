import React, { createContext } from 'react';
import { StateInterface } from './types';

export interface ContextProps {
  state: StateInterface;
  dispatch: React.Dispatch<any>;
}

const AppContext = createContext<ContextProps>({} as ContextProps);

export default AppContext;
