// import React from "react";
// import { WrapNoData, WrapperText, Circle, TextTitle, TextIcon } from "../../styled";
// import plusPrimary from '../../../../assets/images/icons/plus-primary.svg';
// // import AddButton from '../../../../shared/components/AddButton/AddButton'; Hide in v1
// import Icon from '../../../../shared/components/Icon/Icon';

const StartSharingWidgetProfile = () =>
  // <WrapNoData css={{  height: '100%', flex: '1' }}>
  //   <WrapperText css={{ width: '95%' }}>
  //     <Circle css={{ width: '9.688rem', height: '9.688rem' }} />
  //     <div style={{ minHeight: '8rem', height: 'auto'}}>
  //       <TextTitle>Please share your knowledge content</TextTitle>
  //       <p>Gathersight aims to make it easy for you to share knowledge
  //         content with our community of knowledge seekers.</p>
  //       <TextIcon>Please click the sticky
  //         <Icon
  //           img={plusPrimary}
  //           width='20px'
  //           height='20px'
  //           css={{
  //             display: 'inline-flex',
  //             verticalAlign: 'middle',
  //             '& img': { margin: '0 8px 0' }
  //           }}
  //         />
  //         button to add links to your existing online content or to create
  //         your first piece of knowledge content on Gathersight.
  //       </TextIcon>
  //     </div>
  //     {/* <AddButton title='Add content' kind='add'/> */}
  //   </WrapperText>
  // </WrapNoData>
  null;

export default StartSharingWidgetProfile;
