import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectsContext from '../context/projects/ProjectsContext';
import useGetToken from './useGetToken';
import { ProjectsService } from '../../services/ProjectsService/ProjectsService';
import { EditProject, Project, ProjectQuestion } from '../interfaces/projectInterfaces';
import { formatExpertData } from '../../screens/Expert/utils';
import { useExpert } from './useExpert';
import { MemberService } from '../../services/OrganizationService/MemberService';
import { ProjectInvitationsService } from '../../services/ProjectInvitationsService/ProjectInvitationsService';
import { useUser } from './useUser';
import AppContext from '../context/app/AppContext';

const setResponseAlert = (response: Project | []) => {
  if (Array.isArray(response)) {
    toast.error('Error updating project. Please try again');
  } else {
    toast.success('Project updated successfully');
  }
};

export const useProjects = () => {
  const { expert, setExpert } = useExpert();
  const { isExpert } = useUser();
  const { projects, setProjects, projectQuestions, setProjectQuestions, project, setProject } =
    useContext(ProjectsContext);
  const { dispatch } = useContext(AppContext);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const authenticationToken = useGetToken();
  const { slug } = useParams();
  const [isChangingQuestion, setIsChangingQuestion] = useState<boolean>(false);
  const navigate = useNavigate();

  const updateUserCredits = (credits: number) => {
    dispatch({ type: 'SET_CREDITS', payload: credits });
  };

  const updateProjectInProjects = (projectResponse: Project) => {
    if(projects.length === 0) return;

    const projectToUpdate = projects.find((proj: Project) => proj.id === projectResponse.id);
    if (projectToUpdate) {
      const updatedProjects = projects.map((proj: Project) => {
        if (proj.id === projectResponse.id) {
          return projectResponse;
        }
        return proj;
      });
      setProjects(updatedProjects);
    } else {
      setProjects([...projects, projectResponse]);
    }
  };

  const removeProjectFromProjects = (projectId: string) => {
    const updatedProjects = projects.filter((proj: Project) => proj.id !== projectId);
    setProjects(updatedProjects);
  };

  const getUserProjects = async () => {
    const projectsData = await ProjectsService.getProjects(authenticationToken);
    setProjects(projectsData.data);
    setLoadingProjects(false);
    updateUserCredits(projectsData?.meta?.credits);
  };

  const getProject = async (force?: boolean) => {
    if (!slug) return null;

    let response;
    if (project?.attributes?.slug === slug && !force)
      response = project;
    else {
      response = projects.find((proj: Project) => proj.attributes.slug === slug);
      if (!response || force) {
        response = await ProjectsService.getProject(authenticationToken, slug);
        updateUserCredits(response?.meta?.credits);
      }
    }
    setProject(response?.data ? response.data : response);
    updateProjectInProjects(response?.data ? response.data : response);
    return response;
  };

  const getProjectFromSlug = async () => {
    if (!slug) return null;

    const projectData = await ProjectsService.getProject(authenticationToken, slug);
    return projectData;
  };

  const getProjectByToken = async (id: string, token: string) => {
    const projectData = await ProjectsService.getProjectByToken(id, token);
    return projectData;
  };

  const updateProject = async (projectData: EditProject, withAlert: boolean = true) => {
    if (!slug) return null;

    const response = await ProjectsService.updateProject(authenticationToken, slug, projectData);
    updateProjectInProjects(response);
    if (withAlert) setResponseAlert(response);

    return response;
  };

  const updateProjectDocument = async (projectData: FormData) => {
    if (!slug) return null;

    const response = await ProjectsService.addProjectDocuments(
      authenticationToken,
      slug,
      projectData,
    );
    updateProjectInProjects(response);
    return response;
  };

  const removeProjectDocument = async (documentId: string) => {
    if (!slug) return null;

    const response = await ProjectsService.removeProjectDocument(
      authenticationToken,
      slug,
      documentId,
    );
    updateProjectInProjects(response);
    return response;
  };

  const removeProject = async (projectId: string) => {
    const response = await ProjectsService.removeProject(authenticationToken, projectId);
    removeProjectFromProjects(projectId);
    return response;
  };

  const recreateProjectQuestions = async () => {
    if (!slug) return null;

    const response = await ProjectsService.recreateProjectQuestions(authenticationToken, slug);
    setIsChangingQuestion(true);
    getUserProjects();
    return response;
  };

  // eslint-disable-next-line consistent-return
  const getQuestions = async () => {
    if (project) {
      const projectQuestionsRes = await ProjectsService.getProjectQuestions(
        authenticationToken,
        project.id,
      );
      if (projectQuestionsRes.length) {
        const hasCoincidence = projectQuestions.every((projectQuestion) =>
          projectQuestionsRes.every(
            (projectQuestionRes: any) => projectQuestion.id !== projectQuestionRes.id,
          ),
        );
        if (hasCoincidence) {
          setProjectQuestions(
            projectQuestionsRes.map((projectQuestionRes: ProjectQuestion) => ({
              id: projectQuestionRes.id,
              text: projectQuestionRes.attributes.text,
            })),
          );
        }
        return hasCoincidence;
      }
    }
    return false;
  };

  const inviteExpertsToProjects = async (experts: any) =>
    ProjectsService.inviteExperts(authenticationToken, project?.id as string, experts);

  const addProject = async () => {
    const data = formatExpertData(expert);
    const projectRes = await ProjectsService.createProject(authenticationToken, data);
    if (projectRes) {
      setExpert({
        projectName: '',
        description: '',
        locations: [],
        roles: [],
      });
      await getUserProjects();
      navigate(`/organization/projects/${projectRes.attributes.slug}`);
    } else {
      toast.error('Error creating project. Please try again');
    }
  };

  const acceptInvitation = async (
    prevAuthToken: string,
    invitationToken: string,
    organizationId?: string,
    projectId?: string,
  ) => {
    let response;
    if (organizationId) {
      response = await MemberService.acceptMemberInvitation(
        prevAuthToken,
        invitationToken,
        organizationId,
      );
      navigate('/');
    } else if (projectId) {
      response = await ProjectInvitationsService.acceptInvitation(
        prevAuthToken,
        invitationToken,
        projectId,
      );
      if (response.error) {
        navigate('/');
      } else {
        navigate(`/experts/projects/${response.attributes.project_slug}`);
      }
    }
    localStorage.removeItem('tokenInvitation');
    localStorage.removeItem('organizationId');
    localStorage.removeItem('projectId');
    if (response.error) toast.error(response.error);
  };

  const getProjectStatus = (projectItem: Project) => {
    if (isExpert) {
      return projectItem?.attributes?.expert_status || 'in_progress';
    }
    return projectItem?.attributes?.status || 'draft';
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const fetchData = async () => {
      try {
        const isNewQuestions = await getQuestions();

        if (isNewQuestions) {
          clearInterval(intervalId);
          setIsChangingQuestion(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error al hacer la solicitud:', error);
      }
    };

    if (isChangingQuestion) {
      fetchData(); // Llamada inicial
      intervalId = setInterval(fetchData, 3000);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangingQuestion]);

  return {
    getProject,
    updateProject,
    updateProjectDocument,
    removeProjectDocument,
    getUserProjects,
    recreateProjectQuestions,
    projects,
    isChangingQuestion,
    getProjectFromSlug,
    inviteExpertsToProjects,
    addProject,
    setProjects,
    acceptInvitation,
    getProjectByToken,
    removeProject,
    loadingProjects,
    setLoadingProjects,
    project,
    getProjectStatus,
  };
};
