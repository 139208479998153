import React, { useContext, useCallback, useMemo, useState } from 'react';
import LoadDataSection from '../../../shared/components/LoadDataSection/LoadDataSection';
import AppContext from '../../../shared/context/app/AppContext';
import { KnowledgeService } from '../../../services/knowledge.service';
import PlaylistList from '../../../shared/components/CardBooks/PlaylistList';
import { Widget, WidgetText, WidgetTitle } from '../../../shared/components/Widget/styles';
import PlaylistsSidebar from '../../../shared/components/ShimmerComponent/PlaylistsSidebar';

interface PlaylistModel {}

type PlaylistProps = PlaylistModel &
  React.ComponentProps<typeof Widget> & {
    kind?: string;
  };

const Playlist: React.FC<PlaylistProps> = ({ kind }) => {
  const { state } = useContext(AppContext);
  const { authenticationToken, filterContent } = state;
  const [subtitle, setSubtitle] = useState<string>('');

  const actionPlaylist = useCallback(async (
    page: number, total: number, filterBy?: string) => {
    let result = [];
    let resultWithFilter = [];
    result = await KnowledgeService.showPlaylistTop(
      { authenticationToken, page, limit: total }
    );

    if (filterBy && page === 1) {
      resultWithFilter = await KnowledgeService.getIndex(
        { authenticationToken, page, limit: total, filterBy, publicPlaylist: true }
      );
      if(resultWithFilter.length === 0) {
        const message = `Bah, we couldn’t find any playlists matching your search results.
         But here are some other knowledge wormholes you might enjoy.`;
        setSubtitle(message);
      } else { setSubtitle(''); }
    }

    if (filterBy === '') { setSubtitle(''); }

    return(resultWithFilter.length > 0 ? resultWithFilter : result);
  }, [authenticationToken]);

  return useMemo(() => (
    <Widget className='wrap-kp' kind={kind}>
      <WidgetTitle>
        Knowledge Playlists
      </WidgetTitle>
      <WidgetText css={{ display: subtitle ? 'block' : 'none' }}>
        {subtitle}
      </WidgetText>
      <LoadDataSection
        type="outline"
        onAction={actionPlaylist}
        component={PlaylistList}
        shimmerComponent={<PlaylistsSidebar total={2} />}
        filterByProp={filterContent?.filter}
        textProp="See more"
        size={6}
        replace
        withModal
        title='Knowledge Playlists'
        description='What fascinating knowledge are other people discovering?'
      />
    </Widget>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [filterContent?.filter, subtitle, kind]
  );
};

export default React.memo(Playlist);
