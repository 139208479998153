import React from 'react';
import { IconWrapper, ListWrapper, ListAutocomplete } from '../styled';
import Icon from '../../Icon/Icon';
import searchIcon from '../../../../assets/images/icons/search.svg';
import ItemResult from './ItemResult/ItemResult';

interface ResultsModel {
  value: string;
  data?: Array<any>;
  // eslint-disable-next-line no-unused-vars
  actionSelect?: (value: string) => Promise<any>;
  setData?: React.Dispatch<React.SetStateAction<Array<any>>>;
  icon?: string;
};

const Results = (props: ResultsModel) => {
  const { actionSelect, data, value, setData, icon } = props;
  return(
    <IconWrapper>
      <Icon img={icon || searchIcon} width='18' height='16' alt="Results" />
      <ListWrapper hideResults>
        <ListAutocomplete empty={data?.length === 0}>
          {value && data?.map(item => (
            <ItemResult key={item.id}
                        value={value}
                        name={item.attributes.name}
                        avatar={item.attributes.avatar_url}
                        username={item.attributes.username}
                        actionSelect={actionSelect}
                        setData={setData} />
          ))}
        </ListAutocomplete>
      </ListWrapper>
    </IconWrapper>
  );
};

Results.defaultProps = {
  actionSelect: undefined,
  setData: undefined,
  data: undefined,
  icon: undefined,
};

export default Results;