import React, { useMemo, ReactElement, ReactNode, useState } from 'react';
import OrganizationContext from './OrganizationContext';
import {
  BillingData,
  OrganizationInfo,
  PaymentData,
  MemberData,
} from '../../interfaces/interfaces';
import { StateInterface } from '../app/types';

const initialPaymentData: PaymentData = {
  expiry: '',
  cardNumber: '',
  cardHolderName: '',
  cvv: '',
  isDefault: false,
};

const initialBillingData: BillingData = {
  companyName: '',
  email: '',
  address: '',
  address2: '',
  zipCode: '',
  city: '',
  country: '',
  vatNum: '',
  saveData: false,
};

const initialOrganizationData: OrganizationInfo = {
  name: 'Global Wellness Foundation',
  image: '',
};

const initializeState = () => {
  const fromLocalStorage: StateInterface = JSON.parse(localStorage.getItem('state') as string);
  if (fromLocalStorage) {
    const initialData: OrganizationInfo = {
      name: fromLocalStorage.userData.organizationName as string,
      image: fromLocalStorage.userData.organizationAvatarUrl as string,
    };
    return initialData;
  }
  return initialOrganizationData;
};

const initializeCredits = () => {
  const fromLocalStorage: StateInterface = JSON.parse(localStorage.getItem('state') as string);
  return fromLocalStorage ? fromLocalStorage.userData.credits : undefined;
};

const OrganizationProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [paymentsInformation, setPaymentsInformation] = useState<PaymentData[]>([]);
  const [members, setMembers] = useState<MemberData[]>([]);
  const [paymentInformation, setPaymentInformation] = useState<PaymentData>(initialPaymentData);
  const [billingInformation, setBillingInformation] = useState<BillingData>(initialBillingData);
  const [organizationInformation, setOrganizationInformation] = useState<OrganizationInfo>(
    initializeState(),
  );
  const [credits, setCredits] = useState<number>(initializeCredits() as number);

  const value = useMemo(
    () => ({
      paymentsInformation,
      setPaymentsInformation,
      paymentInformation,
      setPaymentInformation,
      billingInformation,
      setBillingInformation,
      credits,
      setCredits,
      organizationInformation,
      setOrganizationInformation,
      members,
      setMembers,
    }),
    [
      paymentsInformation,
      paymentInformation,
      billingInformation,
      credits,
      organizationInformation,
      members,
    ],
  );

  return <OrganizationContext.Provider value={value}>{children}</OrganizationContext.Provider>;
};

export default OrganizationProvider;
