import React from 'react';
import Table from '../../Dashboard/Table/table';
import { styled } from '../../../../config/theme';
import { organizationColumns } from '../../Dashboard/Table/columns/organizationColumns';
import { Typography } from '../../../../screens/Organization/styled';
import { useProjectList } from '../../../hooks/Organization/useProjectList';

const Container = styled('div', {
  marginTop: 'calc($spaceSmall * 2.5)',
  gap: '$spaceBody2_38',
  display: 'flex',
  flexDirection: 'column',
});

const customColumns = organizationColumns.filter(
  (column) =>
    typeof column.accessor === 'string' &&
    ['name', 'status', 'credits'].includes(column.accessor),
);

export const TableContainer = () => {
  const { getListMapped, loadingProjects, getMockList } = useProjectList();
  return (
    <Container>
      <Typography
        size="displayXSmall"
        weight="700"
        lineHeight="lineHeight3"
        letterSpacing="letterSpacing3"
      >
        Credit History
      </Typography>
      <Table
        type="Organization"
        data={getListMapped().filter(
          (project) => project.status === 'completed' || project.status === 'inProgress',
        )}
        loadingData={getMockList()}
        buttonTitle="View"
        customColumns={customColumns}
        emptyMessage="There are no projects yet."
        isLoading={loadingProjects}
      />
    </Container>
  );
};
