import React from 'react';
import { Options, UserTypeContainer } from './styled';
import WantAnExpert from './Types/WantAnExpert';
import Expert from './Types/Expert';
import { Title, Text } from '../../../../shared/components/Typography/styled';

const UserType = () => (
  <UserTypeContainer>  
    <Title>Choose Your User Type</Title>
    <Text>
      To personalize your experience, we need to know that type of user you are.
      <span> Please choose one of the following options:</span>
    </Text>
    <Options>
      <WantAnExpert />
      <Expert />
    </Options>
  </UserTypeContainer>
);

export default UserType;
