import React, { useEffect } from 'react';
import { StyledResizableTextArea } from './styled';

interface ResizableTextAreaProps {
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onKeyDown?: (event: any) => void;
  isTitle?: boolean;
}

export const ResizableTextArea = ({
  value,
  onChange,
  onKeyDown,
  isTitle,
}: ResizableTextAreaProps) => {
  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = value ? `${textAreaRef.current.scrollHeight}px` : '1.2rem';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event);
  };

  useEffect(() => {
    adjustTextAreaHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <StyledResizableTextArea
      ref={textAreaRef}
      value={value}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      isTitle={isTitle}
    />
  );
};

ResizableTextArea.defaultProps = {
  onKeyDown: undefined,
  isTitle: false,
};
