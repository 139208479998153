import React from 'react';
import { Wrapper } from './styled';

interface TabsWrapperModel {
  children: React.ReactNode;
}

const TabsWrapper: React.FunctionComponent<TabsWrapperModel> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default TabsWrapper;
