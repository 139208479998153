export const formatExpertData = (expert: any) => (
{
  name: expert.projectName,
  description: expert.description,
  project_locations_attributes: expert.locations.map((location: any) => ({
    location_id: location.id,
  })),
  project_occupations_attributes: expert.roles.map((role: any) => ({
    occupation_id: role.id,
  })),
});
