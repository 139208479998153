import { styled } from '../../config/theme';
import { LayoutCol } from '../../shared/components/Layouts/styles';

export const BoxInfo = styled('div', {
  color: '$offWhite',
  display: 'flex',
  backgroundColor: '$offBlack',
  height: '100%',
  width: '100%',
  '@bp3': {
    height: '17.313rem',
  },

  variants: {
    kind: {
      header: {
        height: '100%',
      },
    },
  },
});

export const WrapperText = styled('div', {
  fontSize: '$bodyXSmall',
  fontWeight: '400',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  width: '85%',
  textAlign: 'center',
  padding: '4rem 0',
  '@bp2': {
    width: '50%',
  },
  '@bp4': {
    padding: '4rem',
  },
});

export const WrapNoData = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(217, 219, 233, 0.5)',
  borderRadius: '1rem',
  width: '100%',
  marginTop: '1.25rem',
  '@bp3': {
    marginTop: '2rem',
  },
  '@bp4': {
    marginTop: '3rem',
  },
  '@bp6': {
    marginTop: '2.5rem',
  },
});

export const BodyWrapper = styled('div', {
  minHeight: '8rem',
  height: 'auto',
  '@bp3': {
    minHeight: '12rem',
  },
});

export const LayoutColProfile = styled(LayoutCol, {
  flexGrow: 1,
  justifyContent: 'space-between',
  padding: '0 4px',
  '@bp2': {
    justifyContent: 'flex-start',
    padding: '0',
  },
});

export const LayoutColPeople = styled(LayoutCol, {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  variants: {
    isEmpty: {
      true: {
        paddingTop: 0,
      },
    },
  },
});

export const LayoutColCard = styled(LayoutCol, {
  flexGrow: 1,
  justifyContent: 'center',
  '@bp3': {
    justifyContent: 'flex-end',
  },
});

export const LayoutColEmptyProfile = styled(LayoutCol, {
  flexGrow: 1,
  alignItems: 'flex-start',
  flexDirection: 'column',
  flexWrap: 'wrap',
  '@bp2': {},
  '@bp3': {
    flexDirection: 'row',
  },
});

export const TextCard = styled('p', {
  fontSize: '$bodySmall',
  fontWeight: '$weight600',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  marginBottom: '0.5rem',
});

export const ParaphCard = styled('p', {
  fontSize: '$bodyXSmall',
  fontWeight: '$normal',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  marginBottom: '1.5rem',
  '& img': {
    margin: '0 0.45rem 0 0',
  },
});

export const SpanCard = styled('span', {});

export const Box = styled('div', {});

export const BoxCard = styled('div', {
  height: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'initial',
  flexBasis: '100%',
  '@bp3': {
    flexBasis: '550px',
  },
  '@bp4': {
    flexBasis: '550px',
  },
});

export const BoxContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '2.5rem',
});

export const TextDiscover = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$displayXSmall',
  lineHeight: '$lineHeight3',
});

export const BoxText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '1rem',
});

export const Circle = styled('div', {
  borderRadius: '50%',
  margin: 'auto',
  backgroundColor: '$placeHold',
  marginBottom: '$spaceLarge',
  width: '9.688rem',
  height: '9.688rem',
});

export const TextIcon = styled('span', {
  img: {
    margin: '0 $spaceLarge',
  },
});

export const TextTitle = styled('p', {
  fontSize: '$bodyMedium',
  fontWeight: '$bold',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  marginBottom: '$spaceLarge',

  '@bp3': {
    fontSize: '$displayXSmall',
  },
});

export const TextDescription = styled('p', {
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing3',
  maxWidth: 650,
  margin: '0 auto',

  '@bp3': {
    fontSize: '$bodySmall',
  },
});

export const Text = styled('span', {
  fontSize: '$bodyXSmall',

  '@bp3': {
    fontSize: '$bodySmall',
  },
  '& span': {
    color: '$body',
    fontWeight: '$weight600',
  },
});

export const Paraph = styled('p', {
  fontSize: '$bodyXSmall',
  fontWeight: '$normal',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  marginBottom: '$sizes$1',
  '&:last-child': {
    marginBottom: 0,
  },
});

export const BlockText = styled('div', {
  minHeight: '6rem',
  maxWidth: '550px',
  height: 'auto',
  left: 0,
  right: 0,
  margin: 'auto',
});

export const BlockInvitation = styled('div', {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  backgroundImage:
    'linear-gradient(0deg, hsl(233deg 45% 98% / 100%) 10%, hsl(233deg 45% 98% / 80%) 20%, rgba(255,255,255,0.2) 50%)',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  left: 0,
  right: 0,
});

export const TimeOutBanner = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem 0',
  gap: '20px',
  background: '$blurple',
  top: '0px',
  left: '0',
  width: '100%',
  height: '10px',
  textAlign: 'center',
  color: '$monochromeWhite',
  zIndex: 100,
  p: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0.04em',
  },
});
