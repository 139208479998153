import React, { SVGProps } from 'react';

export const ReadMoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="12"
    viewBox="0 0 9 12"
    fill="#496CE9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.87534 9.27687L1.40659 6.80813C1.34893 6.74843 1.27997 6.70082 1.20371 6.66806C1.12746 6.63531 1.04545 6.61806 0.962462 6.61734C0.879475 6.61662 0.797175 6.63244 0.720365 6.66386C0.643555 6.69529 0.573773 6.7417 0.51509 6.80038C0.456407 6.85906 0.409999 6.92884 0.378573 7.00565C0.347147 7.08246 0.331334 7.16476 0.332055 7.24775C0.332776 7.33074 0.350017 7.41275 0.382773 7.489C0.415529 7.56526 0.463143 7.63422 0.522837 7.69187L4.05846 11.2275C4.17567 11.3447 4.33461 11.4105 4.50034 11.4105C4.66606 11.4105 4.82501 11.3447 4.94221 11.2275L8.47784 7.69187C8.59169 7.574 8.65468 7.41612 8.65326 7.25225C8.65183 7.08838 8.5861 6.93162 8.47022 6.81574C8.35434 6.69986 8.19759 6.63413 8.03371 6.6327C7.86984 6.63128 7.71196 6.69428 7.59409 6.80813L5.12534 9.27687V1C5.12534 0.83424 5.05949 0.675268 4.94228 0.558058C4.82507 0.440848 4.6661 0.375 4.50034 0.375C4.33458 0.375 4.17561 0.440848 4.05839 0.558058C3.94118 0.675268 3.87534 0.83424 3.87534 1V9.27687Z" />
  </svg>
);
