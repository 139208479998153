import { useContext } from 'react';
import AppContext from '../context/app/AppContext';

const useGetToken = (): string => {
  const { state } = useContext(AppContext);
  const { authenticationToken } = state as any | undefined;

  return authenticationToken;
};

export default useGetToken;
