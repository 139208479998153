import React, { useState, useEffect, useMemo } from 'react';
import { ContentRight, VideoContent, revealBackground, BgImage } from './styles';
import { validateResource } from '../LinkPreview/FindMetadata';
import { BoxImgShimmer } from '../ShimmerComponent/styles';

const videoFormats = ['.avi', '.wmv', '.mov', '.ram', '.rm', '.flv', '.ogg', '.webm', '.mp4'];
interface ImageResourceModel {
  url?: any;
  load: React.SetStateAction<boolean>;
};

type ImageResourceProps = ImageResourceModel &
  React.ComponentProps<typeof ContentRight> & {
    type?: string;
  };

const ImageResource: React.FC<ImageResourceProps> = ({ url, type, load }) => {
  const [urlResource, setUrlResource] = useState<any>(undefined);

  const isVideo = urlResource ? videoFormats.includes(urlResource.slice(-4)) : false;
  const style = isVideo && urlResource ? {} : {
    background: `url(${urlResource}) center / cover no-repeat $colors$offWhite`,
    animation: `${revealBackground} 2s 3s forwards`
  };
  const styleNormal = isVideo && urlResource ? {} : {
    opacity: 1,
    background: `url(${urlResource}) center / cover no-repeat $colors$offWhite`
  };
  const readyImg = Boolean(urlResource);
  const defaulStyle = load ? styleNormal : style;

  useEffect(() => {
    (async () => {
      if (url) {
        const valueRes = await validateResource(url);
        await setUrlResource(valueRes);
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => () => {
    setUrlResource(undefined);
  }, []);

  return useMemo(() => (
    <ContentRight visible={Boolean(url || urlResource)} type={type}>
      {(!load && !urlResource) && <BoxImgShimmer className='img-shimer block' />}
      <BgImage css={readyImg ? defaulStyle : {}} />
      {isVideo && urlResource && <VideoContent controls src={urlResource} />}
    </ContentRight>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [readyImg]);
};

ImageResource.defaultProps = {
  url: '',
  type: 'normal'
};

export default React.memo(ImageResource);
