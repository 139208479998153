/* eslint-disable no-console */
import React, { useContext } from 'react';
import { SoundIndicatorContainer } from '../styles';
import { FullScreenIcon } from './icons/FullScreenIcon';
import VideoRecordingContext from '../../../shared/context/videoRecording/videoRecordingContext';

interface CustomHTMLElement extends HTMLElement {
  webkitRequestFullscreen?(): Promise<void>;
  mozRequestFullScreen?(): Promise<void>;
  msRequestFullscreen?(): Promise<void>;
}
const FullScreen = ({ isBlock }: { isBlock?: boolean }) => {
  const { setFullscreen } = useContext(VideoRecordingContext);

  const toggleFullScreen = () => {
    const documentElement = document.documentElement as CustomHTMLElement;
    if (documentElement) {
      setFullscreen(true);
      if (document.fullscreenElement) {
        setFullscreen(false);
        document.exitFullscreen();
      } else if (documentElement.requestFullscreen) {
        documentElement.requestFullscreen().catch((err: any) => {
          console.error(` ${err.message}`);
        });
      } else if (documentElement.webkitRequestFullscreen) {
        documentElement.webkitRequestFullscreen().catch((err: any) => {
          console.error(` ${err.message}`);
        });
      } else if (documentElement.mozRequestFullScreen) {
        documentElement.mozRequestFullScreen().catch((err: any) => {
          console.error(` ${err.message}`);
        });
      } else if (documentElement.msRequestFullscreen) {
        documentElement.msRequestFullscreen().catch((err: any) => {
          console.error(` ${err.message}`);
        });
      } else {
        console.error('Error trying to use fullscreen mode');
      }
    }
  };

  return (
    <SoundIndicatorContainer
      isBlock={isBlock}
      css={{ top: isBlock ? 'unset' : '50px', bottom: 'unset', svg: { paddingRight: '1.5px' } }}
      onClick={toggleFullScreen}
    >
      <FullScreenIcon height={16} width={16} />
    </SoundIndicatorContainer>
  );
};

FullScreen.defaultProps = {
  isBlock: false,
};

export default FullScreen;
