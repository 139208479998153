import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import App from './App';
import AppProvider from './shared/context/app/AppProvider';
import SwitchRoutes from './routes/SwitchRoutes';
import TagProvider from './shared/context/tag/TagProvider';
import ExpertProvider from './shared/context/expert/ExpertProvider';
import AuthenticationProvider from './shared/context/authentication/authenticationProvider';
import ProjectsProvider from './shared/context/projects/ProjectsProvider';
import VideoRecordingProvider from './shared/context/videoRecording/videoRecordingProvider';
import UploadVideoProvider from './shared/context/uploadVideoRecording/uploadVideoProvider';
import OrganizationProvider from './shared/context/organization/OrganizationProvider';

const root = document.getElementById('root');
let renderMethod;
if (root && root.innerHTML !== '') {
  renderMethod = ReactDOM.hydrate;
} else {
  renderMethod = ReactDOM.render;
}

renderMethod(
  <React.StrictMode>
    <AppProvider>
      <TagProvider>
        <ExpertProvider>
          <OrganizationProvider>
            <AuthenticationProvider>
              <ProjectsProvider>
                <VideoRecordingProvider>
                  <UploadVideoProvider>
                    {/* @ts-ignore */}
                    <HelmetProvider>
                      <App />
                      <SwitchRoutes />
                    </HelmetProvider>
                  </UploadVideoProvider>
                </VideoRecordingProvider>
              </ProjectsProvider>
            </AuthenticationProvider>
          </OrganizationProvider>
        </ExpertProvider>
      </TagProvider>
    </AppProvider>
  </React.StrictMode>,
  root,
);
