import { styled } from '../../../config/theme';

export const WrapperSection = styled('div', {
  width: '100%',
  height: '100%',
  '& .load-more': {
    marginTop: '$sizes$2',
    '& button': {
      margin: 'auto'
    }
  },
  '& .infinite-scroll-component': {
    overflow: 'initial !important'
  },

  variants: {
    type: {
      outline: {
        height: 'auto',
        '& .load-more': {
          '& button': {
            margin: 0,
            padding: 0
          }
        }
      }
    },
    empty: {
      true: {
        flex: 'inherit',
        width: 0,
        height: 0,
      }
    },
    isProviders: {
      true: {
      }
    }
  }
});

export const BoxContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'auto',
  textAlign: 'start',
  paddingRight: 10,
  '&::-webkit-scrollbar': {
    width: 3,
    height: 7,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '5rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$line',
    borderRadius: '5rem',
    border: '5px line solid',
  }
});

export const TextTitle = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodyMedium',
  lineHeight: '$lineHeight3',
  alignSelf: 'baseline',
  alignItems: 'flex-start',
  marginBottom: '$spaceSmall',
  width: '100%',
  '& img': {
    margin: 0
  },
  '@bp2': {
    fontSize: '$bodyLarge',
  },
  '@bp3': {
    fontSize: '$displayXSmall',
  }
});

export const BoxText = styled('div', {
  fontSize: '$bodyXSmall', 
  flexDirection: 'column',
  width: '100%',
  marginBottom: '1rem'
});

export const TextDescription = styled('p', {
  fontSize: '$bodyXSmall',
  width: '100%',
  marginY: '$spaceLarge' 
});
