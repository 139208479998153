/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { FC, ComponentType, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _isEqual from 'lodash/isEqual';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { ModalDiv } from '../Modal/styled';
import Card from '../Card/Card';
import { removeDuplicateContents } from '../../utils/removeDuplicated';
import { WrapperSection, BoxText, TextTitle, BoxContent, TextDescription } from './styles';
import closeIcon from '../../../assets/images/icons/close.svg';
import { CloseIconModal } from '../Icon/CloseIconModal';

const DEFAULT_SIZE = 16;

interface LoadDataModalModel {
  onAction: (
    index: number,
    defaultTotal: number,
    filterBy?: string,
    filter?: string,
  ) => Promise<Array<any>>;
  actionClose?: () => void;
  component: ComponentType<any>;
  shimmerComponent?: JSX.Element | JSX.Element[];
  shimmerInfinity?: JSX.Element | JSX.Element[];
  filterByProp?: string;
  size?: number;
  propsComponent?: any;
  title?: string;
  description?: string;
  removeDuplicated?: boolean;
}

type LoadDataModalProps = LoadDataModalModel &
  React.ComponentProps<typeof WrapperSection> &
  React.ComponentProps<typeof ModalDiv> & {
    kind?: string;
  };

const LoadDataModal: FC<LoadDataModalProps> = ({
  onAction,
  component: Component,
  filterByProp,
  size,
  propsComponent,
  shimmerComponent,
  shimmerInfinity,
  title,
  description,
  actionClose,
  kind,
  removeDuplicated,
}) => {
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<number>(2);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [emptyContent, setEmptyContent] = useState<boolean>(false);
  const [totalRepeatData, setTotalRepeatData] = useState<number>(0);
  const totalSize = data.length + totalRepeatData;
  const resultsSize = size && size > DEFAULT_SIZE - 1 ? size * 2 : DEFAULT_SIZE;

  useEffect(() => {
    const removeDuplicatedResponse = () => {
      const contents = removeDuplicateContents(data) || [];
      if (!_isEqual(contents, data)) {
        setData(contents);
        setTotalRepeatData(data.length - contents.length);
      }
    };
    if (removeDuplicated && data?.length > 0) {
      removeDuplicatedResponse();
    }
  }, [data]);

  useEffect(() => {
    const loadData = async (currentPage: number, limit: number, filterBy?: string) => {
      try {
        setIsLoading(true);
        const response = await onAction(currentPage, limit, filterBy);
        if (page === 1 && nextPage === 2) {
          setData(response);
        } else {
          const newData = [...data, ...response];
          setData(newData);
          if (currentPage === nextPage) {
            setNextPage(nextPage + 1);
          }
        }
        if (!Number(response?.length)) {
          setEmptyContent(true);
        }

        setErrorMsg('');
      } catch (error) {
        setErrorMsg('Error while loading data. Try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    if (nextPage === page + 1) {
      loadData(page, resultsSize, filterByProp);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, nextPage, onAction, filterByProp, resultsSize]);

  const loadMore = () => {
    setPage((lastPage) => lastPage + 1);
    setNextPage((lastPageNext) => lastPageNext + 1);
  };

  const actionInfinite = () => {
    setTimeout(() => {
      if (data.length > 0 && totalSize >= resultsSize) {
        loadMore();
      }
    }, 800);
  };

  const handleClose = () => {
    if (actionClose) {
      actionClose();
    }
  };

  useEffect(
    () => () => {
      setData([]);
      setPage(1);
      setNextPage(2);
      setEmptyContent(false);
      setTotalRepeatData(0);
    },
    [],
  );

  const finalPropsComponent = { data, ...propsComponent, actionClose: handleClose };

  return (
    <Modal kindOverlay="gat-modal-overlay--light" isOpen onClose={handleClose} kind={kind}>
      <Card
        id="modal-card-infinity"
        className="card-infinity"
        css={{ padding: '2rem 1em 0', '@bp2': { padding: '2.5rem 2.5rem 0' } }}
      >
        <div style={{ display: 'flex', alignItems: 'self-start', width: '100%' }}>
          <BoxText>
            <TextTitle>{title || ''}</TextTitle>
            {description && <TextDescription>{description || ''}</TextDescription>}
          </BoxText>
          <CloseIconModal isFeed onClick={handleClose} />
        </div>
        <BoxContent id="scrollableDiv">
          <InfiniteScroll
            dataLength={data.length}
            next={actionInfinite}
            scrollableTarget="scrollableDiv"
            hasMore
            style={{ height: '100vh', overflow: 'initial' }}
            loader={(!emptyContent && shimmerInfinity) || ''}
          >
            {/* @ts-ignore */}
            <Component {...finalPropsComponent} />
            {isLoading && !data.length && !emptyContent && shimmerComponent}
            {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          </InfiniteScroll>
        </BoxContent>
      </Card>
    </Modal>
  );
};

LoadDataModal.defaultProps = {
  filterByProp: undefined,
  size: undefined,
  shimmerComponent: undefined,
  shimmerInfinity: undefined,
  propsComponent: {},
  actionClose: () => {},
  title: undefined,
  description: undefined,
  removeDuplicated: false,
  kind: 'infinity',
};

export default LoadDataModal;
