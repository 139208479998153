/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTransition, animated, config } from 'react-spring';
import InputSearch from '../../../../shared/components/InputSearch/InputSearch';
import LoadDataSection from '../../../../shared/components/LoadDataSection/LoadDataSection';
import { InputWrapper } from '../../../../shared/components/SearchResults/styled';
import { dataInterface, HomeService } from '../../../../services/home.service';
import useGetToken from '../../../../shared/hooks/useGetToken';
import ResourcesListLoad from '../../../../shared/components/ShimmerComponent/ResourcesListLoad';
import AppContext from '../../../../shared/context/app/AppContext';
import ItemsAdded from './ItemsAdded/ItemsAdded';
import searchIcon from '../../../../assets/images/icons/search.svg';
import Icon from '../../../../shared/components/Icon/Icon';
import { Subtitle, TextSearch } from './styled';
import ResultCard from './ResultCard';

const SearchItems = () => {
  const [value, setValue] = useState<string>('');
  const [resultValue, setResultValue] = useState<string>('');
  const [totalPL, setTotalPL] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const authenticationToken = useGetToken();
  const { state, dispatch } = useContext(AppContext);
  const { newPlaylistItems } = state;
  const existNewPlaylistItems = newPlaylistItems?.length > 0;
  const open = Boolean(resultValue);

  const transitionsSave = useTransition(existNewPlaylistItems, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: config.stiff,
  });

  // eslint-disable-next-line no-unused-vars
  const handleEvent = async (_inputValue: string) => {
    await setResultValue(_inputValue);
  };

  useEffect(() => {
    setResultValue('');
    setReset(false);
    dispatch({ type: 'SET_PLAYLIST_ITEMS', payload: [] });
    if (existNewPlaylistItems) {
      dispatch({ type: 'SET_RELOAD', payload: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    dispatch({ type: 'SET_PLAYLIST_ITEMS', payload: [] });
    return () => {
      dispatch({ type: 'SET_PLAYLIST_ITEMS', payload: [] });
      setResultValue('');
      setValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const actionResources = useCallback(
    async (page: number, limit: number, filterBy?: string, filter?: string) => {
      const parameters: dataInterface = { authenticationToken, page, limit, filterBy, filter };
      const result = await HomeService.getContents(parameters);
      return result;
    },
    [authenticationToken],
  );

  return (
    <>
      <Subtitle>{`Let's find some knowledge for your playlist`}</Subtitle>
      <InputWrapper type="playlist">
        <Icon img={searchIcon} width="18" height="16" css={{ margin: 0 }} alt="Search" />
        <InputSearch
          actionSearch={handleEvent}
          placeholder="Search contents"
          value={value}
          setValue={setValue}
          setReset={setReset}
          kind="noPaddings"
        />
      </InputWrapper>
      {transitionsSave((styles, item) =>
        item ? (
          <animated.div style={{ width: '100%', opacity: styles.opacity }}>
            <ItemsAdded />
          </animated.div>
        ) : null,
      )}

      {open && (
        <>
          <TextSearch>Search results.</TextSearch>
          <LoadDataSection
            typeButton="outline"
            onAction={actionResources}
            shimmerComponent={<ResourcesListLoad total={2} />}
            component={ResultCard}
            size={10}
            afterLoad={(e) => setTotalPL(e)}
            filterByProp="content"
            filterProp={resultValue}
            propsComponent={{ playlistEmpty: true, open: totalPL > 0 }}
          />
        </>
      )}
    </>
  );
};

export default SearchItems;
