import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { LoginModel } from '../../../../../shared/context/app/types';
import AppContext from '../../../../../shared/context/app/AppContext';
import { FormikInput } from '../../../styled';
import { ForgotPasswordLink, FormikForm } from '../../Tabs/styled';
import Button from '../../../../../shared/components/Button/Button';
import { LoginService } from '../../../../../services/AuthService/AuthService';
import ProcessAuthentication from '../../ProcessAuthentication/ProcessAuthentication';
import { Error } from '../../../../../shared/components/Forms/InputError/styled';
import { LayoutContainer } from '../../../../../shared/components/Layouts/styles';
import PasswordInput from '../PasswordInput/PasswordInput';
import ExpertContext from '../../../../../shared/context/expert/ExpertContext';

const AuthenticationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Password is required'),
});

const Login = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const { expert } = useContext(ExpertContext);
  // eslint-disable-next-line no-restricted-globals
  const params = queryString.parse(location.search);

  const handleSubmit = async (data: LoginModel) => {
    const res = await LoginService.login(data);
    ProcessAuthentication({res, dispatch, navigate, expert});
  };

  return (
    <Formik
      initialValues={{
        email: (params?.email as string) ? decodeURIComponent(params?.email as string) : '',
        password: '',
      }}
      validationSchema={AuthenticationSchema}
      onSubmit={(values: LoginModel, { setSubmitting }: FormikHelpers<LoginModel>) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ errors, touched }) => (
        <FormikForm css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <LayoutContainer type="login" css={{ padding: 0 }}>
            <FormikInput
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              autoComplete="email"
            />
            {errors.email && touched.email ? (
              <Error>{errors.email}</Error>
            ) : (
              <Error
                css={{
                  color: 'transparent',
                }}
              >
                space
              </Error>
            )}
            <PasswordInput autocomplete="current-password" />
            {errors.password && touched.password ? (
              <Error>{errors.password}</Error>
            ) : (
              <Error
                css={{
                  color: 'transparent',
                }}
              >
                space
              </Error>
            )}
          </LayoutContainer>
          <ForgotPasswordLink to="/forgot-password">Forgot your password?</ForgotPasswordLink>
          <Button
            kind="login"
            title="Sign in"
            type="submit"
            css={{ marginTop: '1.625rem', marginBottom: 0, width: '100%', maxWidth: '400px' }}
          />
        </FormikForm>
      )}
    </Formik>
  );
};

export default Login;
