import { styled } from '../../../config/theme';

export const IndicatorContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
});

export const IndicatorDot = styled('div', {
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  margin: '0 5px',
  backgroundColor: '$blurpleLight',
  transition: 'all .3s ease-in-out',
  variants: {
    selected: {
      true: {
        backgroundColor: '$blurple',
      },
    },
  },
});
