import React from "react";
import { TextWrapper, Text } from "./styles";

const TermsAndConditionsText = (props: { css?: any }) => {
  const { css } = props;

  return(
    <TextWrapper css={css}>
      By registering you accept the
      <Text className='link-item' to='/terms'><strong> Terms of use</strong></Text> and
      <Text className='link-item' to='/privacy'><strong> Privacy Policy</strong></Text>
    </TextWrapper>
  );
};

TermsAndConditionsText.defaultProps = {
  css: {},
};

export default TermsAndConditionsText;