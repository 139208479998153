import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import useGetToken from '../../../shared/hooks/useGetToken';
import useGetUser from '../../../shared/hooks/useGetUser';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { TagModel } from '../../../shared/interfaces/interfaces';
import { EmptyLabel, FlexContainer, Typography, EmptyContainer } from '../styled';
import { Tag, TextTag, TopicsContainer } from '../../../shared/components/Tags/styles';
import { keyExtractor } from '../../../shared/utils/helper';
import Icon from '../../../shared/components/Icon/Icon';
import { IconContainer } from './styled';
import { TagPlus } from './icons/TagPlus';
import TagSelector from '../../../shared/components/Tags/TagSelector';
import deleteTagIcon from '../../../assets/images/icons/delete-tag.svg';
import ProjectsContext from '../../../shared/context/projects/ProjectsContext';
import { useProjects } from '../../../shared/hooks/useProjects';
import { EditProject, Project, ProjectTag } from '../../../shared/interfaces/projectInterfaces';

export const ProjectTags = () => {
  const [dataTags, setDataTags] = useState<Array<TagModel>>([]);
  const token = useGetToken();
  const { userData } = useGetUser({ authenticationToken: token });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { width } = useWindowSize();
  const isResponsive = width < 980;
  const { projectTags, setProjectTags } = useContext(ProjectsContext);
  const userId: number | string = userData?.id as string;
  const { project, getProjectStatus, updateProject } = useProjects();
  const status = getProjectStatus(project as Project);

  useEffect(() => {
    const tags = projectTags.map((item: ProjectTag) => item?.attributes?.tag);
    setDataTags(tags);
  }, [projectTags]);

  const handleTagAction = async (items: TagModel[]) => {
    let data: EditProject;
    let response: Project;
    if (items.length > projectTags.length) {
      const tagToAdd = _.difference(items, dataTags)[0];
      data = { project_tags_attributes: [{ tag_id: tagToAdd.id }] };
      response = await updateProject(data, false);
    } else {
      const tagToRemove = _.difference(dataTags, items)[0];
      const projectTagId = projectTags.find(
        (tag: ProjectTag) => tag?.attributes?.tag?.id === tagToRemove.id,
      );
      data = { project_tags_attributes: [{ id: projectTagId?.id, _destroy: true }] };
      response = await updateProject(data, false);
    }
    setProjectTags(response.attributes.project_tags);
  };

  const handleDeleteTagAction = async (item: TagModel) => {
    const tagToRemove = projectTags.find((tag: ProjectTag) => tag?.attributes?.tag?.id === item.id);
    if (!tagToRemove) return;

    const data = { project_tags_attributes: [{ id: tagToRemove.id, _destroy: true }] };
    const response = await updateProject(data, false);
    setProjectTags(response.attributes.project_tags);
  };

  return (
    <>
      {projectTags.length > 0 ? (
        <FlexContainer justify="center" gap="2" direction="column">
          <Typography
            size="displayXXSmall"
            weight="700"
            lineHeight="lineHeight3"
            letterSpacing="letterSpacing3"
          >
            Add topics
          </Typography>
          <FlexContainer gap="0_75" align="start">
            <TopicsContainer>
              {projectTags.map((item: ProjectTag) => (
                <Tag key={keyExtractor()} approved>
                  <TextTag>{item?.attributes?.tag?.attributes?.name || ''}</TextTag>
                  {status === 'draft' && (
                    <Icon
                      closeToast={() => handleDeleteTagAction(item?.attributes?.tag)}
                      img={deleteTagIcon}
                      margin0
                      css={{ marginLeft: 4 }}
                    />
                  )}
                </Tag>
              ))}
              {status === 'draft' && (
                <IconContainer
                  kind="topic"
                  onClick={() => setIsOpenModal(!isOpenModal)}
                  css={{ marginTop: '0.25rem' }}
                >
                  <TagPlus />
                </IconContainer>
              )}
            </TopicsContainer>
          </FlexContainer>
        </FlexContainer>
      ) : (
        <FlexContainer
          align={status === 'draft' ? 'center' : 'start'}
          direction={status === 'draft' ? 'row' : 'column'}
          gap="2"
        >
          <Typography
            size="displayXXSmall"
            weight="700"
            lineHeight="lineHeight3"
            letterSpacing="letterSpacing3"
          >
            Add topics
          </Typography>
          {status === 'draft' ? (
            <IconContainer kind="topic" onClick={() => setIsOpenModal(!isOpenModal)}>
              <TagPlus />
            </IconContainer>
          ) : (
            <EmptyContainer>
              <EmptyLabel>No related topics available.</EmptyLabel>
            </EmptyContainer>
          )}
        </FlexContainer>
      )}
      <TagSelector
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        topics={dataTags}
        size={isResponsive ? 'mobile' : 'desk'}
        userId={userId}
        onUpDatedTopics={handleTagAction}
        sameUser
      />
    </>
  );
};
