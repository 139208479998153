import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '../context/app/AppContext';

const AuthExpirationVerify = () => {
  const navigation = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { authenticationToken, tokenExpiration } = state;

  useEffect(() => {
    if (authenticationToken && tokenExpiration) {
      const expiredDate = new Date(tokenExpiration * 1000);
      const todayDate = new Date();
      if (todayDate > expiredDate) {
        dispatch({ type: 'RESET_STATE' });
        navigation('/authentication#sign-in');
        toast.info('Your sesion has expired, please sign in again');
      }
    }
  }, [authenticationToken, dispatch, navigation, tokenExpiration]);

  return <div />;
};

export default AuthExpirationVerify;
