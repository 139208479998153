import { styled } from '../../../config/theme';

export const menuStyles = {
  borderRadius: '1rem',
  width: '100%',
  minWidth: '200px',
  boxShadow: '0 0 1rem 0 hsl(0, 0%, 0%, 0.07)',
  color: 'hsl(241, 37%, 12%, 1)',
  zIndex: 2,
};

export const ChangeDeviceWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});
