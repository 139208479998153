/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Icon from '../../Icon/Icon';
import Card from '../../Card/Card';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { Spinner } from '../../Spinner/spinner';
import SearchResults from '../../SearchResults/SearchResults';
import iconPlus from '../../../../assets/images/icons/plus-circle.svg';
import bookmarkIcon from '../../../../assets/images/icons/bookmark-saved.svg';
import { calculateYModal } from '../../../utils/helper';
import closeIcon from '../../../../assets/images/icons/close.svg';
import {
  BoxText,
  TextTitle,
  BoxContent,
  BoxFooter,
  List,
  ListItem,
  ErrorText,
  TextItem,
} from '../styled';
import { CloseIconModal } from '../../Icon/CloseIconModal';

interface ModalSavePlaylistProps {
  open: boolean;
  isSave: boolean;
  loading: boolean;
  positionY: number | undefined;
  itemSelect: string;
  errorMessage: string;
  dataList: any[];
  onClose: () => void;
  actionForm: (event?: any) => void;
  actionSearch: (data: string) => Promise<any>;
  actionClick: (event: any, ele: any) => void;
  actionSelected: (event: any, ele: any) => boolean;
}

const ModalSavePlaylist: React.FC<ModalSavePlaylistProps> = ({
  open,
  loading,
  errorMessage,
  dataList,
  itemSelect,
  positionY,
  isSave,
  onClose,
  actionForm,
  actionSearch,
  actionSelected,
  actionClick,
}) => {
  const handleSelected = (i: any, ele: any) => actionSelected(i, ele);

  return (
    <Modal kind="knowledge" kindOverlay="gat-modal-overlay--light" isOpen={open} onClose={onClose}>
      <Card css={{ textAlign: 'center', height: 'auto' }}>
        <CloseIconModal
          isFeed
          onClick={onClose}
          css={{
            position: 'absolute',
            padding: '1.5rem 1.5rem 0 0',
            top: '0',
            right: '0',
            '& img': {
              margin: '0',
            },
          }}
        />
        <BoxText css={{ padding: '2rem 2rem 0' }}>
          <TextTitle>Save to Knowledge Playlist</TextTitle>
        </BoxText>
        <BoxContent
          css={{ padding: '0 2rem', marginBottom: `${dataList.length ? '2rem' : '0rem'}` }}
        >
          <SearchResults
            css={{ backgroundColor: '$input' }}
            placeholder="Search"
            actionSelect={actionSearch}
          />
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          <List>
            {dataList.map((i, index) => (
              <ListItem
                key={`list-item-sb-${index}`}
                className={`${isSave && handleSelected(i, itemSelect) ? 'selected' : ''}`}
                onClick={() => actionClick(i, itemSelect)}
              >
                <TextItem>{i?.title || ''}</TextItem>
                {handleSelected(i, itemSelect) && loading !== i?.id && (
                  <Icon img={bookmarkIcon} width="14px" height="14px" alt="Bookmark" />
                )}
                {handleSelected(i, itemSelect) && Number(loading) === Number(i?.id) && (
                  <Spinner
                    className="spinner"
                    css={{ alignSelf: 'center', margin: 'auto 0' }}
                    type="xs"
                  />
                )}
              </ListItem>
            ))}
          </List>
        </BoxContent>
        <BoxFooter css={{ textAlign: 'center', borderTop: '1px solid $input', paddingRight: 20 }}>
          <Button
            kind="newKB"
            title="New Knowledge playlist"
            iconCustom={iconPlus}
            handleClick={actionForm}
          />
        </BoxFooter>
      </Card>
    </Modal>
  );
};

export default ModalSavePlaylist;
