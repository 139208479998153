import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { styled } from '../../../config/theme';

export const Checkbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  backgroundColor: 'transparent',
  width: 25,
  minWidth: 25,
  height: 25,
  minHeight: 25,
  borderRadius: '50%',
  border: '1px solid $colors$input',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease-in-out 0s',
  cursor: 'pointer',
  '&:hover': { backgroundColor: '$input', borderColor: '$colors$body' },
  '&[data-state="checked"]': {
    backgroundColor: '$body',
    borderColor: '$colors$body',
    '&:hover': { opacity: '0.8' },
  },
  variants: {
    credits: {
      true: {
        backgroundColor: '$grayscaleLine',
        border: '1px solid $grayscaleLine',
      },
    },
    size: {
      default: {
        height: '1.25rem',
        minHeight: '1.25rem',
        width: '1.25rem',
        minWidth: '1.25rem',
      },
    },
  },
});

export const CheckboxIndicator = styled(CheckboxPrimitive.Indicator, {});

export const Label = styled('label', {
  color: '$label',
  display: 'flex',
  alignItems: 'center',
  marginLeft: '12px',
});

export const WrapperCheckBox = styled('div', {
  display: 'flex',
  width: '100%',
  variants: {
    credits: {
      true: {
        width: 'auto',
      },
    },
  },
});

export const CircleCheck = styled('div', {
  display: 'flex',
  width: '100%',
});
