import React from "react";
import { Tag, TextTag } from "../styles";
import { ExpertiseModel } from "../../../interfaces/interfaces";
import { keyExtractor } from "../../../utils/helper";
import deleteTagIcon from '../../../../assets/images/icons/delete-tag.svg';
import Icon from "../../Icon/Icon";

interface TagPropsModel {
  item: ExpertiseModel;
  handleDeleteTagAction: () => void;
}

const TagLabel = ({ item, handleDeleteTagAction }: TagPropsModel) => {
  const { attributes: { tag: { data: { attributes: { name } } } } } = item;

  return(
    <Tag
        css={{width: 'fit-content'}}
        key={keyExtractor()}
        approved
      >
        <TextTag css={{fontSize: '13px'}}>
          {name}
        </TextTag>
        
        <Icon
          closeToast={() => handleDeleteTagAction()}
          img={deleteTagIcon}
          margin0
          css={{ marginLeft: 4 }}
        />
      </Tag>
  );
};

export default TagLabel;