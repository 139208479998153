import { Link } from 'react-router-dom';
import { styled } from '../../../config/theme';

export const Box = styled('div', {
  color: '$offWhite',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  '@bp2': {
    flexDirection: 'row',
    flexWrap: 'initial'
  },
  variants: {
    column: {
      true: {
        flexDirection: 'column'
      }
    },
    row: {
      true: {
        flexDirection: 'row'
      }
    },
    kind: {
      'wrapper-user': {
        width: '100%',
        paddingLeft: '20px',
        '@bp2': { flexDirection: 'row' }
      },
      'follow-share': {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '@b2': {
          justifyContent: 'space-between'
        }
      },
      'name-share': {
        justifyContent: 'space-between',
        '@bp3': {
          justifyContent: 'initial'
        }
      },
      'box-following': {
        justifyContent: 'space-between',
        '@bp2': {
          minWidth: 280,
          justifyContent: 'initial',
          '&>span:first-child': { marginRight: 60 }
        }
      },
      'btn-profile-desktop': {
        display: 'none',
        '@bp2': {
          display: 'flex'
        }
      },
      'btn-profile': {
        display: 'flex',
        '@bp2': { display: 'none' }
      },

      'btn-follow': {
        display: 'flex',
        '@bp1': { display: 'none' }
      }
    }
  }
});

export const BoxFollow = styled('div', {
  display: 'flex',
  '@bp1': { display: 'none' },
  variants: {
    kind: {
      'btn-follow-desktop': {
        display: 'none',
        '@bp2': { display: 'flex' }
      }
    }
  }
});

export const BoxIcon = styled('div', {
  display: 'flex',
  marginTop: '$spaceLarge',
  '& a:first-child': {
    '& img': {
      marginLeft: '0'
    }
  },
});

export const BoxAvatar = styled('div', {
  color: '$offWhite',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  '& img': {
    margin: '0 0.25rem'
  },
  '@bp2': {
    alignItems: 'center',
    flexDirection: 'column',
  },
  '@bp1': {
    justifyContent: 'center',
  }
});

export const UserInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: '0.5rem 0 0 0',
  minHeight: 'auto',
  maxWidth: 850,


  '@bp0': {
    padding: '0.5rem',
  },

  '@bp2': {
    padding: '0 1rem',
    justifyContent: 'center',
    minHeight: '12.563rem',
  },
  '@bp3': {
    padding: '0 2rem',
  }
});

export const UserName = styled('h1', {
  fontWeight: '$normal',
  fontSize: '$displayXSmall',
  lineHeight: '$lineHeight5',
  letterSpacing: '$letterSpacing3',
  
  '@bp3': {
    fontSize: 'calc($displayXSmall * 1.3)',
  },
});

export const UserData = styled('span', {
  fontSize: '$bodyXSmall',
  fontWeight: '$normal',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2',
  padding: '0',

  '@b2': {
    padding: '0.25rem 0',
  },
  '@bp3': {
     fontSize: '$bodySmall',
  },
});

export const TextFollow = styled('span', {
  fontSize: '$bodyXSmall',
  fontWeight: '$bold',
  lineHeight: '$lineHeight3',
  letterSpacing: 'letterSpacing2',
  cursor: 'pointer',

  '@bp3': {
    fontSize: '$bodySmall',
 },
});

export const LinkIcon = styled('a', {
  cursor: 'pointer',
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    opacity: '0.8'
  }
});

export const ButtonLink = styled(Link, {
  textDecoration: 'none'
});

export const BoxShare = styled('div', {
  variants: {
    kind: {
      mobile: {
        display: 'flex',
        '@bp2': {
          display: 'none',
        }
      },
      desktop: {
        display: 'none',
        '@bp1': {
          display: 'flex',
        }
      }
    }
  }
});

