import React from 'react';
import PageContainer from '../../shared/components/Container/PageContainer';
import { LayoutContainer, LayoutCol } from '../../shared/components/Layouts/styles';
import MetaTags from '../../shared/components/MetaTags/MetaTags';
import { Title, SubTitle, Text } from './styled';

const Terms = () => (
  <PageContainer layout="statics">
    <MetaTags
      title="Gathersight: Terms of Use"
      description="These are Gathersight's terms of use. It's probably not the most interesting long-form content on the site."
      canonical="https://www.gathersight.com/terms/"
    />
    <LayoutContainer type="statics" css={{ flexWrap: 'wrap' }}>
      <LayoutCol col="12" css={{ flexDirection: 'column', alignItems: 'baseline' }}>
        <Title>Terms of Use</Title>
        <SubTitle>Who May Use the Service</SubTitle>
        <Text>
          {`These Terms of Use (“Terms”) are a binding contract between you and Gathersight Ltd.
          (“we”, “us”, “our”) and govern your use of Gathersight’s products and services (“Services”).`}
        </Text>
        <Text>
          You must be at least 13 years old to use the Services. You must not be prohibited from
          receiving any aspect of our Service under applicable laws or engaging in payments-related
          Services if you are on an applicable denied party listing. We must not have previously
          disabled your account for violation of law or any of our policies.
        </Text>
        <Text>
          {`If you’re agreeing to these Terms on behalf of an organization or other entity, you
          represent and warrant that you are authorized to agree to these Terms on that
          organization or entity’s behalf and bind such entity to these Terms (in which case the
          words “you” and “your” as used in these Terms shall refer to that organization or
          entity).`}
        </Text>

        <SubTitle>Your Account</SubTitle>
        <Text>
          You may be required to create an account and password to use some of our Services. You are
          responsible for safeguarding your account, so use a strong password and limit its use to
          this account. We cannot and will not be liable for any loss or damage arising from your
          failure to comply with the above.
        </Text>
        <Text>
          You agree that you will only use Gatheright for your own personal or organizational use,
          and not on behalf of or for the benefit of any third party.
        </Text>
        <Text>Gathersight reserves the right to delete or remove an account at any time.</Text>
        <Text>
          To help you manage your account more effectively, we may need to send communications e.g.
          service announcements and administrative messages. These communications are considered
          part of the Services and your account, and you may not be able to opt-out from receiving
          them. It is your responsibility to keep your contact information up to date.
        </Text>

        <SubTitle>Privacy</SubTitle>
        <Text>
          Our Privacy Policy{' '}
          <a href="https://www.gathersight.com/privacy" rel="noreferrer" target="_blank">
            (https://www.gathersight.com/privacy)
          </a>
          {` describes how we handle the information you provide to us when you use our Services.
          You understand that through your use of the Services you consent to the collection
          and use (as set forth in the Privacy Policy) of this information, including
          the transfer of this information to other countries for storage, processing
          and use by Gathersight and its affiliates.`}
        </Text>

        <SubTitle>Content on Gathersight</SubTitle>
        <Text>
          {`Gathersight allows you to post, share and upload content including links, text,
          playlists and other materials. Anything that you post or otherwise make available on
          Gathersight is referred to as "User Content" in these Terms. Anything posted, uploaded,
          shared, stored, or otherwise provided through Gathersight is referred to as a “Post”.`}

          <p>
            Any original User Content you post, upload, share, store, or otherwise provide to
            Gathersight remains yours and is protected by copyright and any other applicable
            intellectual property laws.
          </p>
        </Text>
        <Text>
          Any use or reliance on any User Content or materials posted via the Services or obtained
          by you through the Services is at your own risk. We do not endorse, support, represent or
          guarantee the completeness, truthfulness, accuracy, or reliability of any User Content or
          communications posted via the Services or endorse any opinions expressed via the Services.
        </Text>
        <Text>
          If you Post on Gathersight you agree to grant us a worldwide, non-exclusive, royalty-free
          license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify,
          publish, transmit, display and distribute such User Content in any and all media or
          distribution methods now known or later developed. This license authorizes us to make your
          User Content available to the rest of the world and to let other users of Gathersight
          access the Post, and to use and exercise all rights in it, as permitted by the
          functionality of Gathersight.
        </Text>
        <Text>
          You agree your Posts will not violate someone else’s rights. You agree not to copy,
          reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload,
          display, license, sell, commercialize or otherwise exploit for any purpose any User
          Content not owned by you unless you have prior consent from the owner of that User
          Content.
        </Text>
        <Text>
          Gathersight may, but has no obligation to, monitor or review User Content. We reserve the
          right to remove or disable access to any User Content for any or no reason and may take
          these actions without prior notification to you. We reserve the right to remove User
          Content that violates these Terms, including for example, copyright or trademark
          violations or other intellectual property misappropriation, impersonation, unlawful
          conduct, or harassment.
        </Text>
        <Text>
          If you believe that your User Content has been copied in a way that constitutes copyright
          infringement, please report this by sending a message via this{' '}
          <a href="https://gathersight.notion.site/Love-to-hear-from-you-9cbb740b9509472398483d1edd31b2cb">
            contact page.
          </a>
        </Text>
        <Text>
          You understand that by using the Services, you may be exposed to User Content that might
          be offensive, harmful, inaccurate or otherwise inappropriate, or in some cases, postings
          that have been mislabeled or are otherwise deceptive. All User Content is the sole
          responsibility of the person who originated such User Content.
        </Text>

        <SubTitle>Using the Services</SubTitle>
        <Text>
          Subject to your compliance with these Terms (including any other applicable Terms and
          Conditions), we grant you a personal, limited, non-exclusive license to use and access our
          Services and User Content. This license shall remain in effect unless and until terminated
          by you or Gathersight. You agree that you will not redistribute or transfer the
          Gathersight Service or the User Content.
        </Text>
        <Text>
          We may stop (permanently or temporarily) providing the Services or any features within the
          Services to you or to users generally. We also retain the right to create limits on use
          and storage at our sole discretion at any time. We may also remove or refuse to distribute
          any User Content on the Services, limit distribution or visibility of any User Content on
          the service, suspend or terminate users, and reclaim usernames without liability to you.
        </Text>
        <Text>
          The Gathersight software applications and the User Content are licensed, not sold or
          transferred to you, and Gathersight and its licensors retain ownership of all copies of
          the Gathersight software applications and User Content even after installation on your
          Devices.
        </Text>
        <Text>
          {`By using the Services you agree not to misuse our Services, for example, by interfering
          with them or accessing them using a method other than the interface and the instructions
          that we provide. You agree that you will not work around any technical limitations in the
          software provided to you as part of the Services, or reverse engineer, decompile or
          disassemble the software, except and only to the extent that applicable law expressly
          permits. You may not do any of the following while accessing or using the Services:
          (i) access, tamper with, or use non-public areas of the Services, Gathersight’s computer
          systems, (ii) probe, scan, or test the vulnerability of any system or network or breach
          or circumvent any security or authentication measures; (iii) access or search or attempt
          to access or search the Services by any means (automated or otherwise) (iv) “crawl,”
          “scrape,” or “spider” any page, data, or portion of Gathersight (through use of manual
          or automated means); (v) copy or store any significant portion of the content on
          Gathersight; (vi) decompile, reverse engineer, or otherwise attempts to obtain the
          source code or underlying ideas or information of or relating to Gathersigh software
          application, (vii) forge any TCP/IP packet header or any part of the header information
          in any email or posting, or in any way use the Services to send altered, deceptive or
          false source-identifying information; or (viii) interfere with, or disrupt,
          (or attempt to do so), the access of any user, host or network, including, without
          limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Services,
          or by scripting the creation of User Content in such a manner as to interfere with or
          create an undue burden on the Services.`}
        </Text>
        <Text>
          We may terminate our license to you at any time for any reason. We have the right but not
          the obligation to refuse to distribute any User Content or to remove User Content. Except
          for the rights and license granted in these Terms, we reserve all other rights and grant
          no other rights or licenses, implied or otherwise.
          <p>{`We reserve the right to access, read, preserve, and disclose any information as we
          reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal
          process or governmental request, (ii) enforce the Terms, including investigation of
          potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security
          or technical issues, (iv) respond to user support requests, or (v) protect the rights,
          property or safety of Gathersight, its users and the public.`}</p>
        </Text>
        <Text>
          You acknowledge and agree that the Services and User Content remain the property of
          Gathersight’s users or Gathersight. The content, information and services made available
          to you on Gathersight are protected by U.S. and international copyright, trademark, and
          other laws, and you acknowledge that these rights are valid and enforceable.
        </Text>
        <Text>
          All Gathersight trademarks, service marks, trade names, logos, domain names, and any other
          features of the Gathersight brand are the sole property of Gathersight. These Terms do not
          grant you any rights to use the Gathersight brand for commercial or non-commercial use.
        </Text>

        <SubTitle>Content Posted on Third Party websites</SubTitle>
        <Text>
          Gathersight contains links and connections to third party websites or services that are
          not owned or controlled by us. Gathersight has no control over, and assumes no
          responsibility for, the content, accuracy, privacy policies, or practices of or opinions
          expressed in any third-party websites. By linking to a third party website or webpage,
          Gathersight does not represent, warrant, guarantee, or imply that it endorses such website
          or webpage. You are responsible for taking precautions as necessary to protect yourself
          and your computer systems from viruses, worms, Trojan horses, and other harmful or
          destructive content. Gathersight disclaims any responsibility for any harm resulting from
          your access or use of third party websites and webpages. You release and hold us harmless
          from any and all liability arising from your use of any third-party website or service.
        </Text>
        <Text>
          In the event that you have a dispute with a third party, you release us, our officers
          employees, agents, and successors from claims, demands, and damages of every kind or
          nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out
          of or in any way related to such disputes and/or Gathersight.
        </Text>

        <SubTitle>Other Users</SubTitle>
        <Text>
          User Content published on Gathersight is the sole responsibility of the person or
          organization posting such content. You access all such content at your own risk. We aren’t
          liable for any errors or omissions in any User Content and you hereby release us from any
          damages or loss you might suffer in connection with User Content.
        </Text>
        <Text>
          Your interactions with organizations and individuals found on or through Gathersight and
          any other terms, conditions, warranties or representations associated with such dealings,
          are solely between you and such organizations and individuals. You agree that Gathersight
          shall not be responsible or liable for any loss or damage of any sort incurred as the
          result of any such dealings. We can’t guarantee the identity of any users with access to
          Gathersight and are not responsible for which users gain access to our products and
          services.
        </Text>

        <SubTitle>LIMITATION OF LIABILITY</SubTitle>
        <Text>
          Subject to applicable law, you agree that your sole and exclusive remedy for any problems
          or dissatisfaction with the Gathersight Services is to stop using the Service. In no event
          will Gathersight, its officers, shareholders, employees, agents, directors, subsidiaries,
          affiliates, successors, assigns, suppliers, or licensors be liable for (1) any indirect,
          special, incidental, punitive, exemplary, or consequential damages; (2) any loss of use,
          data, business, or profits (whether direct or indirect), in all cases arising out of the
          use of or inability to use the Gathersight Service, devices, third-party applications, or
          third-party application content; or (3) aggregate liability for all claims relating to the
          Gathersight Service, third-party applications, or third-party application content more
          than the greater of (a) the amounts paid by you to Gathersight during the twelve months
          prior to the first claim; or (b) $100.00. Any liability we do have for losses you suffer
          is strictly limited to losses that were reasonably foreseeable.
        </Text>

        <SubTitle>Indemnification</SubTitle>
        <Text>
          You agree to release, indemnify, and defend Gathersight from all third-party claims and
          costs (including reasonable attorneys’ fees) arising out of or related to: i) your use of
          Gathersight Services, ii) your User Content, iii) your conduct or interactions with other
          users on Gathersight, or iv) your breach of any part of this agreement. We will promptly
          notify you of any such claim and will provide you (at your expense) with reasonable
          assistance in defending the claim. You will allow us to participate in the defense and
          will not settle any such claim without our prior written consent. We reserve the right, at
          our own expense, to assume the exclusive defense of any matter otherwise subject to
          indemnification by you. In that event, you will have no further obligation to defend us in
          that matter.
        </Text>

        <SubTitle>No Warranties</SubTitle>
        <Text>
          {`Gathersight will provide the Services using reasonable care and skill and in accordance
          with any specification of the Service provided by Gathersight, however, subject to that,
          the Service is provided "as is" and "as available," without any warranties of any kind,
          whether express, implied, or statutory. Further, Gathersight and all owners of the User
          Content disclaim any express, implied, and statutory warranties regarding the content,
          including warranties of satisfactory quality, merchantability, fitness for a particular
          purpose, or non-infringement. Neither Gathersight nor any owner of User Content warrants
          that the Service or User Content is free of malware or other harmful components.
          No advice or information whether oral or in writing obtained by you from Gathersight or
          its users shall create any warranty on behalf of Gathersight.`}
        </Text>

        <SubTitle>Terminating Your Account</SubTitle>
        <Text>
          You may delete your account at any time from your profile page or by sending a message via
          this{' '}
          <a href="https://gathersight.notion.site/Love-to-hear-from-you-9cbb740b9509472398483d1edd31b2cb">
            contact page
          </a>
          . You agree that some of the obligations in these Terms will be in force even after you
          terminate your account. All of the following terms will survive termination: any
          obligation you have to pay us or indemnify us, any limitations on our liability, any terms
          regarding ownership of intellectual property rights, terms regarding disputes between us,
          and any other terms that, by their nature, should survive termination of these Terms.
        </Text>

        <SubTitle>CLASS ACTION WAIVER</SubTitle>
        <Text>
          {`WHERE PERMITTED UNDER THE APPLICABLE LAW, YOU AND GATHERSIGHT AGREE THAT EACH MAY BRING
          CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF
          OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless both you and
          Gathersight agree, no arbitrator or judge may consolidate more than one person's
          claims or otherwise preside over any form of a representative or class proceeding.`}
        </Text>

        <SubTitle>Dispute resolution and arbitration</SubTitle>
        <Text>
          You and Gathersight agree that any dispute, claim, or controversy between you and
          Gathersight arising in connection with or relating in any way to these agreements or to
          your relationship with Gathersight as a user of the Service (whether based in contract,
          tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims
          arise during or after the termination of the agreements) will be determined by mandatory
          binding individual arbitration. Arbitration is more informal than a lawsuit in court.
          THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS
          LIMITED. There may be more limited discovery than in court. The arbitrator must follow
          this agreement and can award the same damages and relief as a court (including attorney
          fees), except that the arbitrator may not award declaratory or injunctive relief
          benefiting anyone but the parties to the arbitration. This arbitration provision will
          survive termination of the agreements.
        </Text>

        <SubTitle>Arbitration rules</SubTitle>
        <Text>
          {`Either you or Gathersight may start arbitration proceedings. Any arbitration between you
          and Gathersight will be finally settled under the Rules of Arbitration of the
          International Chamber of Commerce (the "ICC") then in force (the "ICC Rules") by one or
          more arbitrators appointed in accordance with the ICC Rules, as modified by these
          Agreements, and will be administered by the International Court of Arbitration of the
          ICC.`}
        </Text>
        <Text>
          Any arbitration will be conducted in the English language and unless otherwise required by
          a mandatory law of a member state of the European Union or any other jurisdiction, the law
          to be applied in any arbitration shall be the law of [the relevant state or country
          described in clause 6.1], without regard to choice or conflicts of law principles.
        </Text>

        <SubTitle>About these Terms</SubTitle>
        <Text>
          {`Under applicable law, you may have certain rights that can't be limited by a contract.
          These terms are in no way intended to restrict those rights.`}
        </Text>

        <SubTitle>Changes</SubTitle>
        <Text>
          Gathersight reserves the right, at its sole discretion, to modify or replace any part of
          these Terms. It is your responsibility to check the Terms periodically for changes. Your
          continued use of the Services following the posting of any changes to these Terms
          constitutes acceptance of those changes. Gathersight may also, in the future, offer new
          services and/or features (including, the release of new tools and resources). Such new
          features and/or services shall be subject to the Terms and Conditions of these Terms.
        </Text>

        <SubTitle>Jurisdiction</SubTitle>
        <Text>
          {`Unless otherwise required by mandatory laws in your country of residence, the Terms
          (and any non-contractual disputes/claims arising out of or in connection with them) are
          subject to the laws of England and Wales and the parties agree to submit to the exclusive
          jurisdiction of the Courts of England and Wales in respect of any dispute which arises
          out of or under these Terms. The prevailing party in any action or proceeding to enforce
          these Terms shall be entitled to costs and attorneys' fees. If any part of these Terms
          are held invalid, illegal, or unenforceable, the remaining terms and provisions shall not
          be affected by such invalidity, illegality, or unenforceability, and shall remain in full
          force and effect, and such invalid, illegal, or unenforceable term or provision shall be
          deemed not to be part of these terms. The failure of either party to enforce at any time
          a term or condition of these Terms shall in no way be interpreted as a waiver of such
          term or condition, or any subsequent breach thereof.`}
        </Text>

        <SubTitle>Entire Agreement</SubTitle>
        <Text>
          Other than as stated in this section or as explicitly agreed upon in writing between you
          and Gathersight, these Terms constitute all the Terms and Conditions agreed upon between
          you and Gathersight and supersede any prior agreements in relation to the subject matter
          of these Terms, whether written or oral. As noted above, other Terms and Conditions
          governing use of the Gathersight Service are incorporated herein.
        </Text>

        <SubTitle>Severability and Waiver</SubTitle>
        <Text>
          Unless as otherwise stated in these Terms, should any provision of these Terms be held
          invalid or unenforceable for any reason or to any extent, the remaining provisions of
          these Terms will not be affected, and the application of that provision shall be enforced
          to the extent permitted by law.
        </Text>
        <Text>
          {`Any failure by Gathersight or any third-party beneficiary to enforce these Terms or any
          provision thereof shall not waive Gathersight’s or the applicable third-party
          beneficiary's right to do so.`}
        </Text>

        <SubTitle>Assignment</SubTitle>
        <Text>
          Gathersight may assign any or all of these Terms, and may assign or delegate, in whole or
          in part, any of its rights or obligations under these Terms. You may not assign these
          Terms, in whole or in part, nor transfer or sub-license your rights under these Terms, to
          any third party.
        </Text>
        <Text>
          These Terms are an agreement between you and Gathersight Ltd., 71-75 Shelton Street London
          WC2H 9JQ. If you have any questions about these Terms, please{' '}
          <a href="https://gathersight.notion.site/Love-to-hear-from-you-9cbb740b9509472398483d1edd31b2cb">
            contact us
          </a>
          .
        </Text>
        <Text>Effective: June 13, 2022</Text>
      </LayoutCol>
    </LayoutContainer>
  </PageContainer>
);

export default Terms;
