import React from 'react';
import { WrapperCard } from '../CardResources/styles';
import { BreathingComponents, CardContentShimmer } from './styles';

const CardPlaylistSidebar: React.FC<any> = () => (
  <WrapperCard
    css={{
      flexWrap: 'inherit',
      flexDirection: 'row',
      marginBottom: '$spaceSmall',
      padding: '1rem',
    }}
  >
    <CardContentShimmer css={{ minHeight: '2rem', margin: 0 }}>
      <BreathingComponents className="tab" height={20} />
      <BreathingComponents className="tab" height={15} width={120} />
    </CardContentShimmer>
  </WrapperCard>
);

export default CardPlaylistSidebar;
