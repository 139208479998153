import React from 'react';
import './App.css';
import MetaTags from './shared/components/MetaTags/MetaTags';

const App: React.FC = () => (
  <MetaTags
    title='Gathersight: a Place for People, Opinions and Ideas'
    description="The home of independent experts, thinkers and creators.
      Find and connect with people sharing opinions and ideas on the Gathersight knowledge
      network."
    canonical='https://www.gathersight.com/'
    main />
);

export default App;
