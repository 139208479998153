import { styled } from '../../../../config/theme';

export const Subtitle = styled('p', {
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '22px',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.75px',
  color: '#4E4B66',
  marginY: '$spaceXSmall',
});

export const TextSearch = styled('h2', {
  fontWeight: '$bold',
  fontSize: '$bodySmall',
  lineHeight: '$lineHeight2',
  letterSpacing: '$letterSpacing2 ',
  paddingBottom: '$spaceLarge'
});
