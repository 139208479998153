import React from 'react';
import { ExpertFooterContainer, ExpertFooterSubContainer } from './styled';
import { xGatherSightProfile } from '../../../../shared/utils/globalText';
import LinksFooter from '../../../../shared/components/Footer/LinksFooter';
import Icon from '../../../../shared/components/Icon/Icon';
import xLogo from '../../../../assets/images/icons/x-icon.svg';

export const ExpertFooter = () => (
  <ExpertFooterContainer>
    <ExpertFooterSubContainer>
      <LinksFooter />
      <a href={xGatherSightProfile} target="_blank" rel="noopener noreferrer">
        <Icon img={xLogo} width='16' height='16' alt='Footer Link' />
      </a>
    </ExpertFooterSubContainer>
  </ExpertFooterContainer>
);
