import React from "react";
import _get from 'lodash/get';
import AvatarMultiple from '../../../../shared/components/Avatar/AvatarMultiple';
import ShareKB from '../../../../shared/components/Share/ShareKB';
import WidgetAccordion from "../../../../shared/components/Widget/WidgetAccordion";
import { Knowledge, KnowledgeAttributes, WhoAccess } from "../../../../shared/interfaces/interfaces";
import { Box, TextWho } from "../../styles";

interface SharePlaylistProps {
  loading: React.SetStateAction<boolean>;
  playlistData: Knowledge;
};

const SharePlaylist: React.FC<SharePlaylistProps> = ({loading, playlistData}) => {
  const collaborators = _get(playlistData.attributes, 'who_access.data');
  const collaboratorsAccepted = collaborators.filter((user: WhoAccess) =>
    user.attributes.status === 'accepted'
  );

  const isEmptyShared = Boolean(!collaboratorsAccepted?.length);

  return(
    <WidgetAccordion
      title='Invite collaborators'
      loading={loading}
      body={
        <Box css={{
          img: { marginRight: 0 },
          justifyContent: 'flex-start',
          '@bp4': {
            justifyContent: 'space-between',
          }
        }}>
          {isEmptyShared && (
            <TextWho>Share your knowledge discoveries</TextWho>
          )}
          {!isEmptyShared && (
            <AvatarMultiple data={collaboratorsAccepted} type='little' limit={4} />
          )}
          <ShareKB
            isInvite
            dataKnowledge={{
              id: playlistData.id || '',
              attributes: playlistData.attributes as KnowledgeAttributes
            }}
          />
        </Box>
      }
    />
  );
};

export default SharePlaylist;