import React, { useContext } from "react";
import { OptionTitle, OptionText, OptionContainer } from "../styled";
import expert from "../../../../../assets/images/icons/expert-black-big.svg";
import Icon from "../../../../../shared/components/Icon/Icon";
import AuthenticationContext from "../../../../../shared/context/authentication/authenticationContext";

const Expert = () => {
  const { setUserType } = useContext(AuthenticationContext);

  const handleOptionClick = () => {
    setUserType('expert');
  };

  return (
    <OptionContainer onClick={handleOptionClick}>
      <Icon img={expert} alt="find an expert" width="37" height="40" />
      <OptionTitle>
        I want to offer my expertise
      </OptionTitle>
      <OptionText>
        Share your knowledge & boost your income.
      </OptionText>
    </OptionContainer>
  );
};

export default Expert;