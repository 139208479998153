/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
// import _get from 'lodash/get';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';
// import Icon from '../Icon/Icon';
// import { Spinner } from '../Spinner/spinner';
import AppContext from '../../context/app/AppContext';
// import { success } from '../ToastAlerts/alerts';
// import { getUserName } from '../../utils/helper';
// import { UserService } from '../../../services/user.service';
import { KnowledgeService } from '../../../services/knowledge.service';
import { Knowledge } from '../../interfaces/interfaces';
import shareLogo from '../../../assets/images/icons/share.svg';
import closeIcon from '../../../assets/images/icons/close.svg';
// import searchIcon from '../../../assets/images/icons/search.svg';
import copyIcon from '../../../assets/images/icons/copy.svg';
// import deleteIcon from '../../../assets/images/icons/delete.svg';
import plus from '../../../assets/images/icons/plus-circle.svg';
// import InputSearch from '../InputSearch/InputSearch';
// import PeopleData from '../People/PeopleData';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ErrorText } from '../ModalKB/styled';
import Tooltip from '../Tooltip/Tooltip';
import FormInvitation from './FormInvitation';
import { WrapperShare, BoxText, TextTitle, BoxContent,
  SocialWrapper, ButtonContainer
} from './styles';

interface ShareKBModel {
  isInvite?: Boolean;
  actionShare?: () => void;
  actionModalClose?: () => void;
  dataKnowledge?: Knowledge;
}

const TEXT_KB_PUBLIC = 'Anyone with this link can edit (no sign in required)';
const TEXT_KB_PRIVATE = 'Anyone with this link can edit (sign in required)';

const ShareKB: React.FunctionComponent<ShareKBModel> = ({
  actionShare, dataKnowledge, isInvite, actionModalClose
}) => {
  // const authors = _get(dataKnowledge?.attributes, 'who_access.data');
  const [open, setOpen] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [copy, setCopy] = useState<boolean>(false);
  const [, setUsersResults] = useState<Array<any>>([]);
  // const [users, setUsers] = useState<Array<any>>([]);
  // const [value, setValue] = useState<string>('');
  // const [loading, setLoading] = useState<boolean>(false);
  const [loadingDel, setLoadingDel] = useState<any>(undefined);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { state, dispatch } = useContext(AppContext);
  const { authenticationToken } = state as any;
  const refList = useRef<HTMLDivElement | null>(null);
  const urlShare = window?.location?.href || '';

  const handleReset = (val: any) => {
    setReset(val);
    setUsersResults([]);
  };

  // const removeKbCollaborator = useCallback(async (idKB: any, idItem: any) => {
  //   await setLoadingDel(idItem);
  //   const params = {
  //     authenticationToken, id: idKB
  //   };

  //   const results = await KnowledgeService.deleteKBCollaborators(params, idItem);

  //   if (!results?.fail) {
  //     await setUsers(prevItems => prevItems.filter((i: any) => i.id !== idItem));
  //     await success(results?.message);
  //   } else {
  //     await setErrorMsg(results?.message);
  //     await setLoadingDel(undefined);
  //   }

  // }, [authenticationToken]);

  // const deleteItem = (item: any) => {
  //   if (item?.type === 'user') {
  //     setUsers(prevItems => prevItems.filter((i: any) => i.id !== item?.id));
  //   } else {
  //     removeKbCollaborator(dataKnowledge?.id, item?.id);
  //   }
  // };

  const handleShare = (event: any) => {
    event?.stopPropagation();
    setOpen(true);
    setCopy(false);
    setUsersResults([]);

    if (actionShare) {
      actionShare();
    }
  };

  const handleCopy = ((event?: any) => {
    event?.stopPropagation();

    if (urlShare) {
      let newLink = urlShare;
      const splitData = urlShare.split('/') || [];
      const id = splitData[splitData.length - 1];

      if (!Number(id)) {
        newLink = `${newLink}/${dataKnowledge?.id}`;
      }

      navigator.clipboard.writeText(`${newLink}`);
      setCopy(true);
    }
  });

  const actionClose = useCallback(async (event?: any) => {
    event?.stopPropagation();
    setOpen(false);
    handleReset(true);
    if (actionModalClose) {
      actionModalClose();
    }

    if (loadingDel) {
      await dispatch({ type: 'SET_RELOAD', payload: true });
      await setLoadingDel(undefined);
    }
  }, [loadingDel, dispatch, actionModalClose]);

  // const handleItem = (i: any) => {
  //   const itemIndex = users.findIndex(item => item?.id === i?.id ||
  //     (i.type === 'user' && item?.user_name === getUserName(i)));

  //   if (itemIndex < 0) {
  //     setUsers(prevData => [...prevData, i]);
  //   }

  //   handleReset(true);
  // };

  useOnClickOutside(refList, () => handleReset(true));

  // const loadUsers = useCallback(async (data?: string) => {
  //   try {
  //     if (open && data) {
  //       setLoading(true);
  //       const params = {
  //         authenticationToken, page: 1, limit: 5, name: data
  //       };
  //       const results = await UserService.searchUser(params);

  //       await setUsersResults(results.map((i: any) => ({
  //         ...i,
  //         ...i.attributes
  //       })));
  //     }
  //   } catch (error) {
  //     setErrorMsg('Error request. Try again later.');
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [authenticationToken, open]);

  // const actionShareUsers = useCallback(async (dataKB: any) => {
  //   try {
  //     setLoadingShare(true);
  //     const usersId = users.filter(item => item?.type === 'user').map(i => i?.id);
  //     const params = {
  //       authenticationToken, id: dataKB?.id
  //     };

  //     const results = await KnowledgeService.createKBCollaborators(params, usersId);
  //     if (results.length) {
  //       await dispatch({ type: 'SET_RELOAD', payload: true });
  //       await actionClose();
  //     } else {
  //       await setErrorMsg(results?.message);
  //     }
  //   } catch (error) {
  //     setErrorMsg('Error request. Try again later.');
  //   } finally {
  //     setLoadingShare(false);
  //   }

  // }, [authenticationToken, users, actionClose, dispatch]);

  const actionSendInvitation = useCallback(async (email: string) => {
    try {
      const params = { authenticationToken, id: dataKnowledge?.id };

      const results = await KnowledgeService.createKBCollaborators(params, email);
      if (results?.fail) {
        await setErrorMsg(results?.message);
      } else {
        await dispatch({ type: 'SET_RELOAD', payload: true });
        await actionClose();
      }
    } catch (error) {
      setErrorMsg('Error request. Try again later.');
    }

  }, [authenticationToken, dataKnowledge, actionClose, dispatch]);

  // const actionSearch = async (item: string) => {
  //   await loadUsers(item);
  // };

  // const isLoadingItem = (item: any) => Number(item?.id) === Number(loadingDel);

  useEffect(() => {
    if (reset) {
      handleReset(true);
    }
  }, [reset]);

  // useEffect(() => {
  //   if (open) {
  //     setUsers(authors.map((i: any) => ({ ...i, ...i.attributes })) || []);
  //   }
  // }, [open, authors]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copy) {
        setCopy(false);
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [copy]);


  return (
    <>
      <WrapperShare isInvite={Boolean(isInvite)}>
        <Button
          title=""
          iconCustom={isInvite ? plus : shareLogo}
          kind="transparent"
          iconWidth={isInvite ? '30px' : undefined}
          iconHeight={isInvite ? '30px' : undefined}
          renderProps={{ onClick: (event: any) => handleShare(event) }}
        />
      </WrapperShare>
      {open && <Modal
        kindOverlay="gat-modal-overlay--light"
        isOpen={open}
        onClose={(event: any) => actionClose(event)}>
        <Card type='modal'>
          <ButtonContainer >
            <Button
              title=""
              css={{ paddingTop: '1rem'}}              iconCustom={closeIcon}
              kind="transparent"
              handleClick={(event: any) => actionClose(event)}
            />
          </ButtonContainer>
          <BoxText css={{ padding: '0 1.25rem', '@bp2': { padding: '0 3.5rem' } }}>
            <TextTitle>Sharing great knowledge with</TextTitle>
          </BoxText>
          <BoxContent
            css={{
              padding: '0 1.25rem', marginBottom: '1.25rem', '@bp2': {
                padding: '0 3.5rem', marginBottom: '2rem'
              }
            }}
          >
            <FormInvitation  handleSubmit={actionSendInvitation} />
            {/* <InputWrapper>
              <IconWrapper>
                <Icon img={searchIcon} width="24" height="22" alt="Search" />
              </IconWrapper>
              <InputSearch
                actionSearch={actionSearch}
                placeholder="Search by Name"
                setReset={handleReset}
                value={value}
                setValue={setValue}
              />
              <List ref={refList} results empty={!usersResults.length} >
                {loading && (<Spinner
                  className='spinner'
                  css={{ alignSelf: 'center', margin: 'auto 0' }}
                  type='xs'
                />)}
                {!loading && value && usersResults.map((i, index: number) => (
                  <ListItem key={`list-item-search${index}`} results onClick={() => handleItem(i)}>
                    <PeopleData
                      img={i?.avatar_url}
                      name={getUserName(i)}
                      text=''
                      status={i?.attributes.status}
                    />
                  </ListItem>
                ))}
              </List>
            </InputWrapper> */}
            {/* <List>
              {users.map((i, index: number) => (
                <ListItem key={`list-item-${index}`}>
                  <PeopleData
                    img={i?.user_avatar || i?.avatar_url}
                    name={i?.user_name || getUserName(i) || ''}
                    text=''
                    status={i?.attributes.status}
                  />
                  <Button
                    title=''
                    kind='transparent'
                    iconCustom={deleteIcon}
                    loading={isLoadingItem(i)}
                    css={{ padding: 0, '& img': { margin: 0 } }}
                    handleClick={() => deleteItem(i)}
                  />
                </ListItem>
              ))}
            </List> */}
            {errorMsg && (<ErrorText>
              {errorMsg}
            </ErrorText>)}
            {/* <SocialWrapper css={{ marginTop: '$spaceSmall' }}>
              <Button
                title="Save"
                css={{ width: '211px', height: '56px' }}
                kind="modal"
                loading={loadingShare}
                renderProps={{
                  disabled: !users.filter(item => item?.type === 'user').length,
                  onClick: () => actionShareUsers(dataKnowledge)
                }}
              />
            </SocialWrapper> */}
          </BoxContent>
        </Card>
        <Card css={{ textAlign: 'center', height: 'auto' }}>
          <BoxText
            css={{
              padding: '1.25rem 1.25rem 0',
              marginBottom: '0',
              '@bp2': { padding: '2rem 2rem 0' }
            }}
          >
            <TextTitle>Get link</TextTitle>
          </BoxText>
          <BoxContent
            css={{
              padding: '0 1.25rem', marginBottom: '1.25rem', '@bp2':
                { padding: '0 2rem', marginBottom: '2rem' }
            }}
          >
            <Tooltip
              open={copy}
              content='Link Copied to Clipboard'
              direction='top'
              type='copied'
            />
            <SocialWrapper css={{ fontSize: '$bodyXSmall', textAlign: 'initial' }}>
              {dataKnowledge?.attributes?.public === true ? TEXT_KB_PUBLIC : TEXT_KB_PRIVATE}
              <Button
                title=""
                css={{ padding: '.5rem 0' }}
                iconCustom={copyIcon}
                kind="transparent"
                handleClick={() => handleCopy()}
              />
            </SocialWrapper>
          </BoxContent>
        </Card>
      </Modal>}
    </>
  );
};

ShareKB.defaultProps = {
  isInvite: false,
  actionShare: undefined,
  actionModalClose: undefined,
  dataKnowledge: undefined,
};

export default ShareKB;
