import React from 'react';
import Password from './Password/Password';
import { LayoutContainer, LayoutCol } from '../../../../shared/components/Layouts/styles';

const AccountSettings: React.FC = () => (
  <LayoutContainer
    css={{
      gridTemplateColumns: '1fr',
      '@bp2': {
        flexDirection: 'column',
      },
      '@bp3': {
        flexDirection: 'row',
      },
    }}
  >
    <LayoutCol
      col="6"
      css={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '$spaceLarge',
        alignItems: 'start',
      }}
    >
      <Password />
    </LayoutCol>

    <LayoutCol
      col="6"
      css={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '$spaceLarge',
        alignItems: 'start',
      }}
    >
      {/* <CloseAccount /> */}
    </LayoutCol>
  </LayoutContainer>
);

export default AccountSettings;
