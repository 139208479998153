import { styled } from '../../../config/theme';

export const CreditsContainer = styled('div', {
  display: 'flex',
  width: '100%',
  paddingX: '$spaceXXSmall',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '$spaceSmall',
});

export const CreditsCounterContainer = styled('div', {
  display: 'flex',
  paddingX: '$spaceXXSmall',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$radius03',
  border: '1px solid $error',
});

export const Sidebar = styled('div', {
  transition: 'width 0.3s ease-in-out',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100%',
  padding: ' $spaceLarge',
  '@bp2': {
    maxWidth: '$18',
    height: '100%',
  },
  '@bp3': {
    width: '$16-5',
  },
  variants: {
    isTablet: {
      true: {
        alignItems: 'center',
      },
    },
    isExpanded: {
      true: {},
      false: {},
    },
    isMobile: {
      true: {
        width: '100%',
      },
    },
  },
  compoundVariants: [
    {
      isTablet: true,
      isExpanded: true,
      css: {
        width: '18rem',
      },
    },
    {
      isTablet: true,
      isExpanded: false,
      css: {
        width: '5rem',
      },
    },
  ],
});

export const MobileNavImg = styled('img', {
  '@bp2': {
    display: 'none',
  },
});

export const ExpandImg = styled('img', {
  height: '1rem',
  width: '1rem',
  cursor: 'pointer',
});

export const ExpandableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  variants: {
    isExpanded: {
      false: {
        display: 'none',
        '@bp2': {
          display: 'flex',
        },
      },
    },
  },
});

export const ImgOrganizationAvatar = styled('img', {
  borderRadius: '100%',
  height: '2.5rem',
  width: '2.5rem',
  minHeight: '2.5rem',
  minWidth: '2.5rem',
});
export const NoImgOrganizationAvatar = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '$weight500',
  fontSize: '$bodySmall',
  background: '$grayscaleInput',
  borderRadius: '100%',
  height: '2.5rem',
  width: '2.5rem',
  minHeight: '2.5rem',
  minWidth: '2.5rem',
  variants: {
    isAdminPage: {
      true: {
        minHeight: '6rem',
        minWidth: '6rem',
        fontSize: '$displaySmall',
      },
    },
    isProfilePage: {
      true: {
        fontSize: '$displayXSmall',
        height: '5rem',
        width: '5rem',
        minHeight: '5rem',
        minWidth: '5rem',
      },
    },
  },
});
