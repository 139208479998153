import React from 'react';
import { AvatarsWrapper, AvatarCircle, WrapperList, TextPlus } from './styles';
import Avatar from './Avatar';
import { keyExtractor } from '../../utils/helper';

interface Model {
  data: any[];
  type?: string;
  limit?: number;
};

type AvatarCircleProps = Model &
  React.ComponentProps<typeof AvatarCircle> & {
    type?: string;
  };

const AvatarMultiple: React.FunctionComponent<AvatarCircleProps> = ({ data, type, limit }) => (
  <WrapperList>
    <AvatarsWrapper>
      {data && data.map((item: any, index) => {
        const props = { ...item, type };

        if (index < Number(limit)) {
          return (
            <AvatarCircle
              key={keyExtractor()} type={type}
              style={item?.attributes?.status === 'sent' ? {filter: 'grayscale(100%) opacity(0.5)'} : {}}
            >
              <Avatar
                width="36px"
                heigth="36px"
                {...props}
                name={item?.attributes?.user_name}
                img={item?.attributes?.user_avatar}
              />
            </AvatarCircle>
          );
        }
        return null;
      })}
    </AvatarsWrapper>
    {data?.length > Number(limit) && (
      <TextPlus>{`+${data.length - Number(limit)}`}</TextPlus>
    )}
  </WrapperList>
);

AvatarMultiple.defaultProps = {
  type: undefined,
  limit: 5
};

export default AvatarMultiple;
