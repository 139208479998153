import React, { useState,  useCallback } from 'react';
import FooterButtons from '../ModalOnboarding/FooterButtons';
import { StepOnboarding } from '../../../shared/interfaces/interfaces';
import {
  BoxText,
  TextTitle,
} from '../styles';
import ChangeAvatar from '../../../shared/components/Avatar/ChangeAvatar/ChangeAvatar';

interface PictureModel {
  skip: () => void;
  back: () => void;
  next: () => void;
  loadingPrevBtn?: boolean;
  dataStep: StepOnboarding;
}

const Picture: React.FC<PictureModel> = ({
  skip, back, next, dataStep, loadingPrevBtn
}) => {
  const [saveImage, setSaveImage] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(false);

  const saveImageAction = useCallback(() => {
    setLoading(true);
    setSaveImage(true);
    if (next) {next();}
  }, [setLoading, setSaveImage, next]);

  return (
    <>
      <BoxText>
        <TextTitle>Add your profile picture</TextTitle>
      </BoxText>

      <ChangeAvatar type='onBoarding' loading={loading} save={saveImage} />
      <FooterButtons
        actionBack={back}
        actionSkip={skip}
        actionNext={() => { }}
        dataStep={dataStep}
        loadingPrevBtn={loadingPrevBtn}
        saveAction={saveImageAction}
        saveButton />
    </>
  );
};

Picture.defaultProps = {
  loadingPrevBtn: false
};

export default Picture;
