import React from 'react';
import { ListItemContainer } from './styled';
import { Item } from './Item';

interface ListItemProps {
  items: any[];
  isExpanded: boolean;
  onSelect: () => void; 
}

export const ListItem = ({ items, isExpanded, onSelect }: ListItemProps) => (
  <ListItemContainer>
    {items.map((item) => (
      <Item
        key={item.label}
        icon={item.icon}
        label={item.label}
        route={item.route}
        isExternal={item.isExternal}
        isExpanded={isExpanded}
        onSelect={onSelect} 
      />
    ))}
  </ListItemContainer>
);
