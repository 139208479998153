import * as TabsPrimitive from '@radix-ui/react-tabs';
import { styled, fadeIn } from '../../../../config/theme';

export const Tabs = styled(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxShadow: `0 2px 10px '$blue'`,
  maxWidth: 1700,
  minHeight: '100vh',
  margin: 'auto',
  // padding: '$spaceXSmall $spaceXSmall $spaceMedium $spaceXSmall',
});

export const TabsList = styled(TabsPrimitive.List, {
  flexShrink: 0,
  display: 'flex',
  borderBottom: `1px solid '$blue'`,
  marginY: '$spaceLarge',
  width: '100%',
  paddingBottom: '0',
  flexDirection: 'column',
  '@bp2': {
    flexDirection: 'row'
  }
});

export const TabsTrigger = styled(TabsPrimitive.Trigger, {
  all: 'unset',
  color: '$label',
  padding: '0 5px',
  height: 45,
  flex: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$bodyCard',
  letterSpacing: '0.25px',
  fontWeight: '$weight500',
  userSelect: 'none',
  borderBottom: '1px solid $colors$line',
  cursor: 'pointer',
  '&:first-child': { borderTopLeftRadius: 6, },
  '&:last-child': { borderTopRightRadius: 6, borderBottom: 0, },
  '&[data-state="active"]': {
    color: '$body',
    boxShadow: '$active',
    display: 'none',
  },
  '&:focus': { position: 'relative' },
  '@bp2': {
    whiteSpace: 'nowrap',
    fontWeight: '$weight600',
    boxShadow: '$noActive',
    borderBottom: 'none',
    '&[data-state="active"]': {
      display: 'flex',
    }
  },
  '@bp3': {
    flex: 1,
    fontSize: '$bodySmall',
    letterSpacing: '$letterSpacing3',
  }
});

export const TabsContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  outline: 'none',
  animation: `${fadeIn} 340ms`,
});

export const BoxTabs = styled('div', {
  width: '100%',
  display: 'none',
  '@bp2': {
    display: 'flex'
  }
});
export const BoxFloat = styled('div', {
  width: '100%',
  position: 'relative',
  minHeight: '48px',
  '@bp2': { display: 'none' }
});