import React, { useContext, useEffect, useState } from "react";
import { TypographyP } from "../../Expert/styled";
import ChangeDeviceSelect from "./ChangeDeviceSelect";
import { ChangeDeviceWrapper } from "./styles";
import VideoRecordingContext from "../../../shared/context/videoRecording/videoRecordingContext";

const AudioInput = () => {
  const { audioInputDevices, audioTrack, setNewAudioTrack } = useContext(VideoRecordingContext);
  const [options, setOptions] = useState<any[]>(audioInputDevices);
  
  useEffect(() => {
    const devices = audioInputDevices.map((device: any) => ({
      value: device.deviceId,
      label: device.label
    }));
    setOptions(devices);
  }, [audioInputDevices]);

  const handleChange = (value: string) => {
    const track = audioInputDevices.find((device: any) => device.deviceId === value);
    if(track) setNewAudioTrack({ label: track.label, value: track.deviceId });
  };

  return(
    <ChangeDeviceWrapper>
      <TypographyP
        weight="600"
        lineHeight="lineHeight2"
        letterSpacing="letterSpacing2"
        color="hsla(241, 37%, 12%, 1)"
        css={{marginBottom: '8px', textAlign: 'left', width: '100%'}}
      >
        Microphone
      </TypographyP>
      <ChangeDeviceSelect options={options} onChange={handleChange} value={audioTrack} />
    </ChangeDeviceWrapper>
  );
};

export default AudioInput;