/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import Authors from './Authors';
import { User } from '../../interfaces/interfaces';
import { WrapperListAuthor, TittleList } from './styles';

interface Props {
  data: User[];
  actionClose?: () => void;
  viewModal?: boolean;
}

const AuthorsList: React.FC<Props> = ({ data, actionClose, viewModal }) => useMemo(() => (
  <WrapperListAuthor
    className="authors-list"
    css={{
      width: '100%',
      '@bp0': {
        maxWidth: 350
      },
      '@bp3': {
        width: 320
      },
      '@bp6': {
        width: 330
      },
    }}
  >
    <TittleList>Suggested for you</TittleList>
    {data && data?.map((ele: User, i) => (
      <Authors
        key={`autor-${i}-${ele?.id}`}
        {...ele}
        actionClose={actionClose}
        viewModal={viewModal}
      />)
    )}
  </WrapperListAuthor>
), [data, actionClose, viewModal]);

AuthorsList.defaultProps = {
  actionClose: () => {}
};

export default React.memo(AuthorsList);
