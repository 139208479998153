import React from 'react';
import { WhyUseExpertOpinions } from './WhyUseExpertOptions';
import { HowToGet } from './HowToGet';
import { WhoAreOurExperts } from './WhoAreOurExperts';
import { UsedThroughout } from './UsedThroughout';
import { FAQOpinions } from './FAQ';
import { OtherQuestionOpinion } from './OtherQuestion';
import { Introduction } from './Introduction';
import { ExpertFooter } from '../components/ExpertFooter/ExpertFooter';
import PageContainer from '../../../shared/components/Container/PageContainer';

export const ExpertOpinions = () => (
  <PageContainer layout="approvedExpert">
    <Introduction />
    <WhyUseExpertOpinions />
    <HowToGet />
    <WhoAreOurExperts />
    <UsedThroughout />
    <FAQOpinions />
    <OtherQuestionOpinion />
    <ExpertFooter />
  </PageContainer>
);
